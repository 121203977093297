/* eslint-disable no-restricted-imports */
import { environment } from '../../../environment'
import { WukongCLog as WukongCLogClass } from './clog'

let instance_: WukongCLogClass | null = null
let isEnable_ = false

function checkLogInstance() {
    if (!isEnable_) {
        return false
    }
    if (instance_ === null) {
        console.error('请先调用 installWukongCLog 初始化')
        return false
    }
    return true
}

export class WkCLog {
    public static preLogList: Parameters<WukongCLogClass['log']>[] = []

    /**
     * 在初始化后发送
     * @param args
     */
    public static preLog(...args: Parameters<WukongCLogClass['log']>) {
        this.preLogList.push(args)
    }

    public static log(...args: Parameters<WukongCLogClass['log']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.log(...args)
    }
    public static throttleLog(...args: Parameters<WukongCLogClass['throttleLog']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.throttleLog(...args)
    }
    public static flushThrottledLog(...args: Parameters<WukongCLogClass['flushThrottledLog']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.flushThrottledLog(...args)
    }
    public static setUser(...args: Parameters<WukongCLogClass['setUser']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setUser(...args)
    }
    public static setDocumentId(...args: Parameters<WukongCLogClass['setDocumentId']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setDocumentId(...args)
    }
    public static setSessionId(...args: Parameters<WukongCLogClass['setSessionId']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setSessionId(...args)
    }

    public static setClientId(...args: Parameters<WukongCLogClass['setClientId']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setClientId(...args)
    }

    public static setRelease(...args: Parameters<WukongCLogClass['setRelease']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setRelease(...args)
    }

    public static setVendor(...args: Parameters<WukongCLogClass['setVendor']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setVendor(...args)
    }

    public static setRenderer(...args: Parameters<WukongCLogClass['setRenderer']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setRenderer(...args)
    }

    public static setAdapter(...args: Parameters<WukongCLogClass['setAdapter']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setAdapter(...args)
    }

    public static setDeviceMemory(...args: Parameters<WukongCLogClass['setDeviceMemory']>) {
        if (!checkLogInstance()) {
            return
        }
        return instance_?.setDeviceMemory(...args)
    }
}

export function tryInitCLog() {
    isEnable_ = true
    instance_ = new WukongCLogClass(environment.clogConfig)

    if (WkCLog.preLogList.length) {
        WkCLog.preLogList.forEach((args) => {
            WkCLog.log(...args)
        })
        WkCLog.preLogList.length = 0
    }
}
