import classNames from 'classnames'
import React, { useMemo } from 'react'
import { MonoIconCommonEnterDown16, MonoIconCommonEnterRight16 } from '../../../icons-v2'
import { Tooltip, TooltipProps, useEllipsisTooltip } from '../../tooltip'
import { useCollapseContext } from './use-collapse'

export interface WKCollapseItemProps {
    value: string
    title: React.ReactNode
    children?: React.ReactNode
    startSlot?: React.ReactNode
    endSlot?: React.ReactNode
    onHoverTitleEnter?: () => void
    onHoverTitleLeave?: () => void
    itemRadius?: 'none' | 'default'
    itemIndent?: 0 | 12
    titleWeight?: 'semibold' | 'regular'
    titleColor?: 'gray-13' | 'gray-11'
    disableHeaderHover?: boolean
    tooltipProps?: TooltipProps
    className?: string
    headerClassName?: string
    dataTestId?: string
}

export const WKCollapseItem = ({
    value,
    title,
    children,
    startSlot,
    endSlot,
    onHoverTitleEnter,
    onHoverTitleLeave,
    itemRadius = 'none',
    itemIndent = 0,
    titleWeight = 'semibold',
    titleColor = 'gray-13',
    disableHeaderHover = false,
    tooltipProps,
    className,
    headerClassName,
    dataTestId,
}: WKCollapseItemProps) => {
    const { getIsOpen, onItemOpen, onItemClose } = useCollapseContext()
    const isOpened = useMemo(() => getIsOpen(value), [getIsOpen, value])
    const { inactivation, ellipsisRef, onmouseenter, onmouseleave } = useEllipsisTooltip<HTMLDivElement>()

    const handleHeaderClick = (e: React.MouseEvent) => {
        if (isOpened) {
            onItemClose(value)
        } else {
            onItemOpen(value)
        }
        e.stopPropagation()
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            if (isOpened) {
                onItemClose(value)
            } else {
                onItemOpen(value)
            }
            e.preventDefault()
            e.stopPropagation()
        }
    }

    const getHeaderClassNames = () => {
        return classNames(
            'group flex flex-row items-center box-border overflow-hidden h-32px color-$wk-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset--2 focus-visible:outline-$wk-brand-7',
            {
                'rounded-none': itemRadius === 'none',
                'rounded-$wk-radius-default': itemRadius === 'default',
                'pr-16px': !endSlot,
                'pr-12px': endSlot,
                'pl-12px': itemIndent === 12,
                'hover:bg-$wk-gray-13-4': !disableHeaderHover,
            },
            headerClassName
        )
    }

    const getIconClassNames = () => {
        return classNames('flex-none flex color-$wk-v2-label-color-gray-6')
    }

    const getTitleClassNames = () => {
        return classNames('grow wk-text-12 overflow-hidden truncate', {
            'wk-font-regular': titleWeight === 'regular',
            'wk-font-semibold': titleWeight === 'semibold',
            'color-$wk-v2-label-color-gray-13': titleColor === 'gray-13',
            'color-$wk-v2-label-color-gray-11': titleColor === 'gray-11',
        })
    }

    return (
        <div className={classNames('flex flex-col bg-$wk-white', className)}>
            <div
                className={getHeaderClassNames()}
                onClick={handleHeaderClick}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                data-testid={dataTestId}
            >
                <div className={getIconClassNames()}>
                    {isOpened ? <MonoIconCommonEnterDown16 /> : <MonoIconCommonEnterRight16 />}
                </div>
                {startSlot ? <div className="flex items-center flex-none">{startSlot}</div> : null}
                <Tooltip
                    {...tooltipProps}
                    inactivation={inactivation || !tooltipProps ? true : tooltipProps.inactivation}
                >
                    <div
                        ref={ellipsisRef}
                        className={getTitleClassNames()}
                        onMouseEnter={(e) => {
                            onmouseenter(e)
                            onHoverTitleEnter?.()
                        }}
                        onMouseLeave={() => {
                            onmouseleave()
                            onHoverTitleLeave?.()
                        }}
                    >
                        {title}
                    </div>
                </Tooltip>
                {endSlot ? <div className="flex-none wk-text-12 color-$wk-v2-label-color-gray-8">{endSlot}</div> : null}
            </div>
            <div>{isOpened ? children : null}</div>
        </div>
    )
}
