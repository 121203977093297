import {
    MonoIconCanvasLink16,
    MonoIconCommonRemove16,
    MonoIconCommonWarningLine16,
    MonoIconPanelBack16,
    MonoIconPanelCloseOverlay16,
    MonoIconPanelNavigateTo16,
    MonoIconPanelNone16,
    MonoIconPanelOverlay16,
    MonoIconPanelScrollTo16,
    MonoIconPanelSwapOverlay16,
    Tooltip,
} from '../../../../../../../ui-lib/src'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { IconButton } from '../../../atom/button/icon-button'
import { SingleGrid } from '../../../atom/grid/single-grid'
import {
    InteractionActionType,
    InteractionActionType2Label,
    InteractionType2DestopShortLabel,
    InteractionType2MobileShortLabel,
    InteractionsWithEventType,
    MIXED_TYPE,
    MIXED_TYPE_LABEL,
    PrototypeInteractionTargetNode,
    type InteractionActionTypeWithMixed,
} from '../constants'
import { getActionData } from '../utils'
import classes from './index.module.less'
import { translation } from './index.translation'

interface InteractionItemProps {
    index: number
    data: InteractionsWithEventType
    selected: boolean
    dragSelected: boolean
    allTargetNodes: PrototypeInteractionTargetNode[]
    isPresetMobile?: boolean
    isConflict?: boolean
    onClickRemove: (e: React.MouseEvent, index: number) => void
}

export const InteractionActionIcon = ({ type }: { type: InteractionActionTypeWithMixed }) => {
    switch (type) {
        case InteractionActionType.None:
        case MIXED_TYPE:
            return <MonoIconPanelNone16 />
        case InteractionActionType.NavigateTo:
            return <MonoIconPanelNavigateTo16 />
        case InteractionActionType.Back:
            return <MonoIconPanelBack16 />
        case InteractionActionType.ScrollTo:
            return <MonoIconPanelScrollTo16 />
        case InteractionActionType.OpenLink:
            return <MonoIconCanvasLink16 />
        case InteractionActionType.OpenOverlay:
            return <MonoIconPanelOverlay16 />
        case InteractionActionType.SwapOverlay:
            return <MonoIconPanelSwapOverlay16 />
        case InteractionActionType.CloseOverlay:
            return <MonoIconPanelCloseOverlay16 />
    }
}

const InteractionActionItem = ({ type, label }: { type: InteractionActionTypeWithMixed; label: string }) => {
    return (
        <div
            className={`${classes['action-item']} pl-6px pr-2 py-1 flex items-center w-full rounded-$wk-radius-default bg-$wk-v2-fill-color-gray-1 box-border`}
        >
            <div className="color-$wk-v2-label-color-gray-6 w-4 h-4">
                <InteractionActionIcon type={type} />
            </div>
            <div className="pl-6px box-border truncate">{label}</div>
        </div>
    )
}

export const InteractionItem = ({
    index,
    data,
    allTargetNodes,
    selected,
    dragSelected,
    isPresetMobile,
    isConflict,
    onClickRemove,
}: InteractionItemProps) => {
    const itemRef = React.useRef<HTMLDivElement>(null)
    const [isHover, setIsHover] = useState(false)
    const type2Label = isPresetMobile ? InteractionType2MobileShortLabel : InteractionType2DestopShortLabel
    const actionData = useMemo(() => {
        return getActionData({
            interactions: data.interactions,
        })
    }, [data])
    const targetLabel = useMemo(() => {
        const noneLabel = InteractionActionType2Label.None
        switch (actionData.type) {
            case MIXED_TYPE:
                return MIXED_TYPE_LABEL
            case InteractionActionType.NavigateTo:
            case InteractionActionType.OpenOverlay:
            case InteractionActionType.SwapOverlay:
            case InteractionActionType.ScrollTo:
                return actionData.transitionNodeId === MIXED_TYPE
                    ? MIXED_TYPE_LABEL
                    : actionData.transitionNodeId
                    ? allTargetNodes.find((v) => v.id === actionData.transitionNodeId)?.name ?? noneLabel
                    : noneLabel
            case InteractionActionType.OpenLink:
                return actionData.connectionUrls.length
                    ? actionData.connectionUrls.length > 1
                        ? MIXED_TYPE_LABEL
                        : actionData.connectionUrls[0]
                    : noneLabel
            case InteractionActionType.CloseOverlay:
            case InteractionActionType.Back:
                return InteractionActionType2Label[actionData.type]
            case InteractionActionType.None:
            default:
                return noneLabel
        }
    }, [allTargetNodes, actionData])

    const _onMouseEnter = useCallback(() => {
        setIsHover(true)
    }, [])

    const _onMouseLeave = useCallback(() => {
        setIsHover(false)
    }, [])

    return (
        <SingleGrid
            className={classNames(
                dragSelected
                    ? 'bg-$wk-v2-fill-color-brand-2'
                    : selected
                    ? 'bg-$wk-gray-13-8'
                    : isHover
                    ? 'bg-$wk-gray-13-4'
                    : ''
            )}
            testId={`prototype-interaction-item-${index}`}
            ref={itemRef}
        >
            <SingleGrid.Item
                start={5}
                span={46}
                data-testid="interaction-item-content"
                onMouseEnter={_onMouseEnter}
                onMouseLeave={_onMouseLeave}
            >
                <SingleGrid style={{ gridTemplateColumns: 'repeat(46, 4px)' }}>
                    <SingleGrid.Item start={1} span={15}>
                        <div className="flex w-full items-center justify-between">
                            <span className="truncate">{type2Label[data.event.interactionType]}</span>
                            {isConflict ? (
                                <Tooltip title={translation('triggerConflict')}>
                                    <div
                                        className="w-4 h-4 shrink-0 color-$wk-v2-label-color-gray-8"
                                        data-testid="interaction-conflict-hint"
                                    >
                                        <MonoIconCommonWarningLine16 />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </div>
                    </SingleGrid.Item>
                    <SingleGrid.Item start={17} span={30} className="interaction-action">
                        <InteractionActionItem
                            type={actionData.type ?? InteractionActionType.None}
                            label={targetLabel}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            </SingleGrid.Item>
            <SingleGrid.Item start={51} span={12} horizontalCenter>
                <IconButton
                    icon={<MonoIconCommonRemove16 />}
                    onClick={(e) => onClickRemove(e, index)}
                    onMouseDown={(e) => e.stopPropagation()}
                    data-testid="interaction-item-remove"
                    selected={false}
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}
