/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { FC } from 'react'
import { useViewState } from '../../../view-state-bridge'
import { LayerPanelLayout } from '../layer-panel/layout'
import { PagePanel } from '../page-panel'
import styles from './index.module.less'
import VPageItem = Wukong.DocumentProto.VPageItem

export interface PagePanelContainerProps {
    dataTestId?: string
}
export const PagePanelContainer: FC<PagePanelContainerProps> = ({ dataTestId }) => {
    const { pages } = useViewState('documentPageList', { pages: [] })
    return pages.length ? (
        <div
            className={styles['page-panel-container']}
            data-testid={dataTestId ?? 'page-panel-container'}
            style={{ display: 'flex' }}
        >
            <PagePanel pages={pages as VPageItem[]} />
            <LayerPanelLayout />
        </div>
    ) : null
}
