import { GlobalCanvas } from './global-canvas'

export interface GraphicCardInfo {
    vendor: string
    renderer: string
}

export function getVideoCardInfo(): GraphicCardInfo | null {
    try {
        const webgl = GlobalCanvas.webgl()
        if (!webgl) {
            return null
        }
        const debugInfo = webgl.context.getExtension('WEBGL_debug_renderer_info')
        if (!debugInfo) {
            return null
        }
        return {
            vendor: webgl.context.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
            renderer: webgl.context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL),
        }
    } catch (e) {
        return null
    }
}

export async function getWebGPUVideoCardInfo(): Promise<string | null> {
    try {
        const adapter = await navigator.gpu.requestAdapter()
        if (!adapter) {
            return null
        }

        const { vendor, description, device, architecture } = adapter.info
        return JSON.stringify({
            device,
            architecture,
            vendor,
            description,
        })
    } catch (e) {
        return null
    }
}

export function getDeviceMemory(): string | null {
    try {
        // A floating point number; one of 0.25, 0.5, 1, 2, 4, 8.
        const mem = (navigator as any).deviceMemory
        return `${mem}GiB`
    } catch (_e) {
        return null
    }
}
