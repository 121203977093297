import { useEffect } from 'react'
import style from './auto-mobile-ui-samples.module.less'
import { translation } from './auto-mobile-ui-samples.translation'
import { generateColorConfig, generateCornerSize, ThemeProps } from './util'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'

export function AutoUISampleLeft({
    primaryColor = '#0088FF',
    onPrimaryColor = 'white',
    backgroundColor = 'white',
    surfaceColor = 'white',
    textPrimaryColor = '#1A1A1A',
    textSecondaryColor = '#666666',
    dividerColor = '#f3f3f3',
}: ThemeProps = {}) {
    const theme = {
        primary: primaryColor,
        onPrimary: onPrimaryColor,
        background: backgroundColor,
        surface: surfaceColor,
        textPrimary: textPrimaryColor,
        textSecondary: textSecondaryColor,
        divider: dividerColor,
    }

    return (
        <>
            <div
                id="root"
                className={style['auto-mobile-ui-sample']}
                style={{
                    pointerEvents: 'none',
                    borderRadius: '24px',
                    border: '4px solid rgba(26, 29, 37, 0.08)',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                }}
            >
                <div className="w-[390px] min-h-[844px]" style={{ backgroundColor: theme.background }}>
                    <div className="h-11 px-5 flex items-center justify-between">
                        <span className="font-regular text-sm" style={{ color: theme.textPrimary }}>
                            9:41
                        </span>
                        <div className="flex items-center gap-2" style={{ color: theme.textPrimary }}>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M472 432h-48a24 24 0 01-24-24V104a24 24 0 0124-24h48a24 24 0 0124 24v304a24 24 0 01-24 24zm-128 0h-48a24 24 0 01-24-24V184a24 24 0 0124-24h48a24 24 0 0124 24v224a24 24 0 01-24 24zm-128 0h-48a24 24 0 01-24-24V248a24 24 0 0124-24h48a24 24 0 0124 24v160a24 24 0 01-24 24zm-128 0H40a24 24 0 01-24-24v-96a24 24 0 0124-24h48a24 24 0 0124 24v96a24 24 0 01-24 24z"></path>
                            </svg>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M256 96c-81.5 0-163 33.6-221.5 88.3-3.3 3-3.4 8.1-.3 11.4l26.7 27.9c3.1 3.3 8.3 3.4 11.6.3 23.3-21.6 49.9-38.8 79.3-51 33-13.8 68.1-20.7 104.3-20.7s71.3 7 104.3 20.7c29.4 12.3 56 29.4 79.3 51 3.3 3.1 8.5 3 11.6-.3l26.7-27.9c3.1-3.2 3-8.3-.3-11.4C419 129.6 337.5 96 256 96z"></path>
                                <path d="M113.2 277.5l28.6 28.3c3.1 3 8 3.2 11.2.3 28.3-25.1 64.6-38.9 102.9-38.9s74.6 13.7 102.9 38.9c3.2 2.9 8.1 2.7 11.2-.3l28.6-28.3c3.3-3.3 3.2-8.6-.3-11.7-37.5-33.9-87.6-54.6-142.5-54.6s-105 20.7-142.5 54.6c-3.3 3.1-3.4 8.4-.1 11.7zM256 324.2c-23.4 0-44.6 9.8-59.4 25.5-3 3.2-2.9 8.1.2 11.2l53.4 52.7c3.2 3.2 8.4 3.2 11.6 0l53.4-52.7c3.1-3.1 3.2-8 .2-11.2-14.8-15.6-36-25.5-59.4-25.5z"></path>
                            </svg>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                height="1em"
                                stroke="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M396 144H64c-26.4 0-48 21.6-48 48v128c0 26.4 21.6 48 48 48h332c26.4 0 48-21.6 48-48V192c0-26.4-21.6-48-48-48zm20 176c0 11-9 20-20 20H64c-11 0-20-9-20-20V192c0-11 9-20 20-20h332c11 0 20 9 20 20v128zM464 204.6v102.8c16 0 32-27.7 32-51.4s-16-51.4-32-51.4z"></path>
                                <path d="M384 192H76c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h308c6.6 0 12-5.4 12-12V204c0-6.6-5.4-12-12-12z"></path>
                            </svg>
                        </div>
                    </div>
                    <div style={{ height: '771px', overflow: 'hidden' }}>
                        <div className="px-5 mt-4">
                            <h1 className="font-bold text-[32px]" style={{ color: theme.textPrimary }}>
                                {translation('Good Morning')}
                            </h1>
                            <div className="flex items-center gap-2 mt-1">
                                <svg
                                    className="w-5 h-5"
                                    style={{ color: theme.primary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M256 387c-8.5 0-15.4 6.9-15.4 15.4v46.2c0 8.5 6.9 15.4 15.4 15.4s15.4-6.9 15.4-15.4v-46.2c0-8.5-6.9-15.4-15.4-15.4zM256 48c-8.5 0-15.4 6.9-15.4 15.4v46.2c0 8.5 6.9 15.4 15.4 15.4s15.4-6.9 15.4-15.4V63.4c0-8.5-6.9-15.4-15.4-15.4zM125 256c0-8.5-6.9-15.4-15.4-15.4H63.4c-8.5 0-15.4 6.9-15.4 15.4s6.9 15.4 15.4 15.4h46.2c8.5 0 15.4-6.9 15.4-15.4zM448.6 240.6h-46.2c-8.5 0-15.4 6.9-15.4 15.4s6.9 15.4 15.4 15.4h46.2c8.5 0 15.4-6.9 15.4-15.4s-6.9-15.4-15.4-15.4zM152.5 344.1c-4.1 0-8 1.6-10.9 4.5l-32.7 32.7c-2.9 2.9-4.5 6.8-4.5 10.9s1.6 8 4.5 10.9c2.9 2.9 6.8 4.5 10.9 4.5 4.1 0 8-1.6 10.9-4.5l32.7-32.7c6-6 6-15.8 0-21.8-2.9-2.9-6.8-4.5-10.9-4.5zM359.5 167.9c4.1 0 8-1.6 10.9-4.5l32.7-32.7c2.9-2.9 4.5-6.8 4.5-10.9s-1.6-8-4.5-10.9c-2.9-2.9-6.8-4.5-10.9-4.5-4.1 0-8 1.6-10.9 4.5l-32.7 32.7c-2.9 2.9-4.5 6.8-4.5 10.9s1.6 8 4.5 10.9c2.9 2.9 6.8 4.5 10.9 4.5zM130.7 108.9c-2.9-2.9-6.8-4.5-10.9-4.5-4.1 0-8 1.6-10.9 4.5-2.9 2.9-4.5 6.8-4.5 10.9 0 4.1 1.6 8 4.5 10.9l32.7 32.7c2.9 2.9 6.8 4.5 10.9 4.5 4.1 0 8-1.6 10.9-4.5 2.9-2.9 4.5-6.8 4.5-10.9s-1.6-8-4.5-10.9l-32.7-32.7zM370.4 348.6c-2.9-2.9-6.8-4.5-10.9-4.5-4.1 0-8 1.6-10.9 4.5-6 6-6 15.8 0 21.8l32.7 32.7c2.9 2.9 6.8 4.5 10.9 4.5 4.1 0 8-1.6 10.9-4.5 2.9-2.9 4.5-6.8 4.5-10.9s-1.6-8-4.5-10.9l-32.7-32.7zM256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96z"></path>
                                </svg>
                                <span className="font-regular" style={{ color: theme.primary }}>
                                    {translation('Sunny, 68°F')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="mx-5 mt-6 rounded-[var(--wk-auto-mobile-ui-corner-size-2xl)] p-5 flex justify-between"
                            style={{ backgroundColor: `${theme.primary}0D` }}
                        >
                            <div>
                                <h2 className="m-0 font-bold text-2xl" style={{ color: theme.textPrimary }}>
                                    {translation('Your Morning Mix')}
                                </h2>
                                <p className="m-0 font-regular text-base mt-1" style={{ color: theme.textSecondary }}>
                                    {translation('Energetic tunes to start your day')}
                                </p>
                                <button
                                    className="mt-4 px-6 py-3 rounded-[var(--wk-auto-mobile-ui-corner-size-full)] font-medium border-0"
                                    style={{ backgroundColor: theme.primary, color: theme.onPrimary }}
                                >
                                    {translation('Play Now')}
                                </button>
                            </div>
                            <img
                                alt="Concert"
                                className="w-20 h-20 rounded-[var(--wk-auto-mobile-ui-corner-size-xl)] object-cover shadow-lg"
                                src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?w=150&amp;h=150&amp;fit=crop&amp;dpr=3"
                            />
                        </div>
                        <div className="mt-8 px-5">
                            <h2 className="font-bold text-2xl mb-4" style={{ color: theme.textPrimary }}>
                                {translation('Recently Played')}
                            </h2>
                            <div className="flex gap-4 overflow-hidden no-scrollbar">
                                <div className="min-w-[140px]">
                                    <img
                                        alt="Morning..."
                                        className="w-[140px] h-[140px] rounded-[var(--wk-auto-mobile-ui-corner-size-xl)] object-cover shadow-lg"
                                        src="https://images.unsplash.com/photo-1511735111819-9a3f7709049c?w=400&amp;h=400&amp;fit=crop&amp;dpr=3"
                                    />
                                    <h3
                                        className="font-medium text-base mt-2 mb-0"
                                        style={{ color: theme.textPrimary }}
                                    >
                                        {translation('Morning Vibes')}
                                    </h3>
                                    <p className="font-regular text-sm m-0" style={{ color: theme.textSecondary }}>
                                        {translation('Various Artists')}
                                    </p>
                                </div>
                                <div className="min-w-[140px]">
                                    <img
                                        alt="Sunrise..."
                                        className="w-[140px] h-[140px] rounded-[var(--wk-auto-mobile-ui-corner-size-xl)] object-cover shadow-lg"
                                        src="https://images.unsplash.com/photo-1506157786151-b8491531f063?w=400&amp;h=400&amp;fit=crop&amp;dpr=3"
                                    />
                                    <h3
                                        className="font-medium text-base mt-2 mb-0"
                                        style={{ color: theme.textPrimary }}
                                    >
                                        {translation('Sunrise Session')}
                                    </h3>
                                    <p className="font-regular text-sm m-0" style={{ color: theme.textSecondary }}>
                                        {translation('Chill Mood')}
                                    </p>
                                </div>
                                <div className="min-w-[140px]">
                                    <img
                                        alt="Wake Up..."
                                        className="w-[140px] h-[140px] rounded-[var(--wk-auto-mobile-ui-corner-size-xl)] object-cover shadow-lg"
                                        src="https://images.unsplash.com/photo-1501386761578-eac5c94b800a?w=400&amp;h=400&amp;fit=crop&amp;dpr=3"
                                    />
                                    <h3
                                        className="font-medium text-base mt-2 mb-0"
                                        style={{ color: theme.textPrimary }}
                                    >
                                        {translation('Wake Up...')}
                                    </h3>
                                    <p className="font-regular text-sm m-0" style={{ color: theme.textSecondary }}>
                                        {translation('Energy Mix')}
                                    </p>
                                </div>
                                <div className="min-w-[140px]">
                                    <img
                                        alt="Morning..."
                                        className="w-[140px] h-[140px] rounded-[var(--wk-auto-mobile-ui-corner-size-xl)] object-cover shadow-lg"
                                        src="https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?w=400&amp;h=400&amp;fit=crop&amp;dpr=3"
                                    />
                                    <h3
                                        className="font-medium text-base mt-2 mb-0"
                                        style={{ color: theme.textPrimary }}
                                    >
                                        {translation('Morning...')}
                                    </h3>
                                    <p className="font-regular text-sm m-0" style={{ color: theme.textSecondary }}>
                                        {translation('Acoustic Vibes')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="px-5 mt-8">
                            <h2 className="font-bold text-2xl mb-4" style={{ color: theme.textPrimary }}>
                                {translation('Recommended for Morning')}
                            </h2>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <img
                                            alt="Morning Energy Boost"
                                            className="w-14 h-14 rounded-[var(--wk-auto-mobile-ui-corner-size-lg)] object-cover shadow-lg"
                                            src="https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=200&amp;h=200&amp;fit=crop&amp;dpr=3"
                                        />
                                        <div className="flex flex-col">
                                            <h3
                                                className="font-medium text-base m-0"
                                                style={{ color: theme.textPrimary }}
                                            >
                                                {translation('Morning Energy Boost')}
                                            </h3>
                                            <p
                                                className="font-regular text-sm m-0"
                                                style={{ color: theme.textSecondary }}
                                            >
                                                {translation('Workout Mix')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span className="font-regular text-sm" style={{ color: theme.textSecondary }}>
                                            3:45
                                        </span>
                                        <svg
                                            className="w-6 h-6"
                                            style={{ color: theme.primary }}
                                            fill="currentColor"
                                            height="1em"
                                            stroke="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 512 512"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
                                                fill="none"
                                                strokeMiterlimit="10"
                                                strokeWidth="32"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <img
                                            alt="Peaceful Awakening"
                                            className="w-14 h-14 rounded-[var(--wk-auto-mobile-ui-corner-size-lg)] object-cover shadow-lg"
                                            src="https://images.unsplash.com/photo-1483412033650-1015ddeb83d1?w=200&amp;h=200&amp;fit=crop&amp;dpr=3"
                                        />
                                        <div className="flex flex-col">
                                            <h3
                                                className="font-medium text-base m-0"
                                                style={{ color: theme.textPrimary }}
                                            >
                                                {translation('Peaceful Awakening')}
                                            </h3>
                                            <p
                                                className="font-regular text-sm m-0"
                                                style={{ color: theme.textSecondary }}
                                            >
                                                {translation('Meditation Sounds')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span className="font-regular text-sm" style={{ color: theme.textSecondary }}>
                                            4:20
                                        </span>
                                        <svg
                                            className="w-6 h-6"
                                            style={{ color: theme.primary }}
                                            fill="currentColor"
                                            height="1em"
                                            stroke="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 512 512"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
                                                fill="none"
                                                strokeMiterlimit="10"
                                                strokeWidth="32"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <img
                                            alt="Sunrise Acoustic"
                                            className="w-14 h-14 rounded-[var(--wk-auto-mobile-ui-corner-size-lg)] object-cover shadow-lg"
                                            src="https://images.unsplash.com/photo-1516280440614-37939bbacd81?w=200&amp;h=200&amp;fit=crop&amp;dpr=3"
                                        />
                                        <div className="flex flex-col">
                                            <h3
                                                className="font-medium text-base m-0"
                                                style={{ color: theme.textPrimary }}
                                            >
                                                {translation('Sunrise Acoustic')}
                                            </h3>
                                            <p
                                                className="font-regular text-sm m-0"
                                                style={{ color: theme.textSecondary }}
                                            >
                                                {translation('Morning Playlist')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span className="font-regular text-sm" style={{ color: theme.textSecondary }}>
                                            3:55
                                        </span>
                                        <svg
                                            className="w-6 h-6"
                                            style={{ color: theme.primary }}
                                            fill="currentColor"
                                            height="1em"
                                            stroke="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 512 512"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
                                                fill="none"
                                                strokeMiterlimit="10"
                                                strokeWidth="32"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-0 border-t" style={{ borderColor: theme.divider }}>
                        <div className="h-[50px] flex items-center justify-around px-6">
                            <div className="flex flex-col items-center">
                                <svg
                                    className="w-6 h-6"
                                    style={{ color: theme.primary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                    <path
                                        d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256m368-77V64h-48v69"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                </svg>
                                <span className="text-xs mt-1 font-medium" style={{ color: theme.primary }}>
                                    {translation('Home')}
                                </span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg
                                    className="w-6 h-6"
                                    style={{ color: theme.textSecondary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                                        fill="none"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                    ></path>
                                    <path
                                        d="M338.29 338.29L448 448"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                    ></path>
                                </svg>
                                <span className="text-xs mt-1 font-regular" style={{ color: theme.textSecondary }}>
                                    {translation('Search')}
                                </span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg
                                    className="w-6 h-6"
                                    style={{ color: theme.textSecondary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        fill="none"
                                        height="368"
                                        rx="16"
                                        ry="16"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        width="64"
                                        x="32"
                                        y="96"
                                    ></rect>
                                    <path
                                        d="M112 224h128M112 400h128"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                    <rect
                                        fill="none"
                                        height="304"
                                        rx="16"
                                        ry="16"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        width="128"
                                        x="112"
                                        y="160"
                                    ></rect>
                                    <rect
                                        fill="none"
                                        height="416"
                                        rx="16"
                                        ry="16"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        width="96"
                                        x="256"
                                        y="48"
                                    ></rect>
                                    <path
                                        d="M422.46 96.11l-40.4 4.25c-11.12 1.17-19.18 11.57-17.93 23.1l34.92 321.59c1.26 11.53 11.37 20 22.49 18.84l40.4-4.25c11.12-1.17 19.18-11.57 17.93-23.1L445 115c-1.31-11.58-11.42-20.06-22.54-18.89z"
                                        fill="none"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                </svg>
                                <span className="text-xs mt-1 font-regular" style={{ color: theme.textSecondary }}>
                                    {translation('Library')}
                                </span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg
                                    className="w-6 h-6"
                                    style={{ color: theme.textSecondary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                    <path
                                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                        fill="none"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                    ></path>
                                </svg>
                                <span className="text-xs mt-1 font-regular" style={{ color: theme.textSecondary }}>
                                    {translation('Profile')}
                                </span>
                            </div>
                            <div className="flex flex-col items-center">
                                <svg
                                    className="w-6 h-6"
                                    style={{ color: theme.textSecondary }}
                                    fill="currentColor"
                                    height="1em"
                                    stroke="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></path>
                                </svg>
                                <span className="text-xs mt-1 font-regular" style={{ color: theme.textSecondary }}>
                                    {translation('Settings')}
                                </span>
                            </div>
                        </div>
                        <div className="h-[34px] w-full flex items-center justify-center">
                            <div
                                className="w-[134px] h-[5px] rounded-[var(--wk-auto-mobile-ui-corner-size-full)]"
                                style={{ backgroundColor: theme.textPrimary }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function AutoMobileUISample() {
    const { tempRemixConfig, getFontFace } = usePlatformAndDesignSystemInContext()

    const primaryColor = tempRemixConfig?.get('primaryColor') ?? '#0088FF'
    const mode = tempRemixConfig?.get('mode') as 'light' | 'dark' | undefined
    const themeConfig = generateColorConfig(primaryColor, mode)

    const font = tempRemixConfig?.get('font') ?? 'Roboto'
    const cornerSize = tempRemixConfig?.get('cornerSize') ?? '4px'
    const cornerSizeConfig = generateCornerSize(cornerSize)

    useEffect(() => {
        getFontFace()
            .then((fontFace) => {
                if (fontFace) {
                    document.fonts.add(fontFace)
                }
            })
            .catch(() => {
                // Ignore Font Load Error
            })
    }, [getFontFace])

    return (
        <div className="flex justify-center items-center">
            <div className="w-full h-full flex justify-center items-center">
                <div
                    style={
                        {
                            transform: 'scale(0.46)',
                            '--wk-auto-mobile-ui-font-family': font,
                            '--wk-auto-mobile-ui-corner-size-full': `${cornerSizeConfig.full}px`,
                            '--wk-auto-mobile-ui-corner-size-2xl': `${cornerSizeConfig['2xl']}px`,
                            '--wk-auto-mobile-ui-corner-size-xl': `${cornerSizeConfig.xl}px`,
                            '--wk-auto-mobile-ui-corner-size-lg': `${cornerSizeConfig.lg}px`,
                            '--wk-auto-mobile-ui-corner-size-md': `${cornerSizeConfig.md}px`,
                            '--wk-auto-mobile-ui-corner-size-sm': `${cornerSizeConfig.sm}px`,
                            '--wk-auto-mobile-ui-corner-size-xs': `${cornerSizeConfig.xs}px`,
                        } as any
                    }
                >
                    <AutoUISampleLeft {...themeConfig} />
                </div>
            </div>
        </div>
    )
}
