import classNames from 'classnames'
import { forwardRef, ReactNode, useMemo } from 'react'
import { MonoIconCommonClose16 } from '../../../icons-v2'
import { WKButton } from '../../wk-button/button'
import { WKIconButton } from '../../wk-button/icon-button'
import { WKTextButton } from '../../wk-button/text-button'
import classes from './step-content.module.less'
import { translation } from './step-content.translation'
import { WKTourButtonProps, WKTourPlacement, WKTourTheme } from './type'

const _StepContent = (
    props: {
        current: number
        length: number
        draggable?: boolean
        placement?: WKTourPlacement
        showArrow?: boolean
        theme?: WKTourTheme
        arrowColor?: string
        image?: string
        video?: string
        title?: string | ReactNode
        hideProgress?: boolean
        description: string | ReactNode
        prevButton?: WKTourButtonProps
        nextButton?: WKTourButtonProps
        moreButton?: WKTourButtonProps
        onClose: () => void
        onClickPrev: () => void
        onClickNext: () => void
    },
    ref: React.Ref<HTMLDivElement>
) => {
    const {
        current,
        length,
        draggable,
        placement,
        showArrow,
        theme = 'white',
        arrowColor,
        image,
        video,
        title,
        hideProgress,
        description,
        prevButton = { show: false } as WKTourButtonProps,
        nextButton = { show: true } as WKTourButtonProps,
        moreButton = { show: false } as WKTourButtonProps,
        onClose,
        onClickPrev,
        onClickNext,
    } = props
    const isWhiteTheme = theme === 'white'
    const position = placement?.split('-')[0]
    const arrowStyle = useMemo(() => {
        if (position === 'bottom') {
            return { borderBottomColor: arrowColor }
        } else if (position === 'top') {
            return { borderTopColor: arrowColor }
        } else if (position === 'left') {
            return { borderLeftColor: arrowColor }
        } else if (position === 'right') {
            return { borderRightColor: arrowColor }
        }
    }, [position, arrowColor])
    const { show: prevButtonShow, text: prevButtonText, onClick: _onClickPrev, ...prevButtonProps } = prevButton
    const { show: nextButtonShow, text: nextButtonText, onClick: _onClickNext, ...nextButtonProps } = nextButton
    const { show: moreButtonShow, text: moreButtonText, onClick: _onClickMore, ...moreButtonProps } = moreButton
    const showPrevButton = prevButtonShow && current > 0
    const showFooter = !hideProgress || showPrevButton || nextButtonShow || moreButtonShow

    return (
        <div
            ref={ref}
            data-testid="wk-tour-step-content"
            className={classNames(classes['step-content'], classes[theme])}
        >
            {showArrow ? (
                <span
                    data-testid="wk-tour-step-arrow"
                    className={classNames(classes.arrow, placement && classes[placement])}
                    style={arrowStyle}
                />
            ) : null}
            <WKIconButton
                className={classNames('absolute z-10', draggable ? 'top-10px right-10px' : ' top-14px right-12px')}
                type="white"
                icon={<MonoIconCommonClose16 className={classes['close-icon']} />}
                onClick={onClose}
                data-testid="wk-tour-step-close"
            />
            <div className={classNames('overflow-hidden rounded-1 relative', classes['content-container'])}>
                {image ? <img className={classes.image} src={image} /> : null}
                {video ? (
                    <video
                        src={video}
                        poster={image}
                        className={classNames(classes.video, image ? 'absolute top-0 left-0' : '')}
                        muted
                        autoPlay
                        loop
                        onCanPlay={(e: any) => e.target?.play?.()}
                        playsInline
                        onError={() => {
                            console.error('[step-content] fail to play video: ' + video)
                        }}
                    />
                ) : null}
                {title ? (
                    <div
                        data-testid="wk-tour-step-title"
                        className={classNames('pt-4 px-4 wk-text-14 wk-font-semibold', classes.title)}
                    >
                        {title}
                    </div>
                ) : null}
                <div
                    data-testid="wk-tour-step-description"
                    className={classNames(
                        'px-4 wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-11',
                        title ? 'pt-2' : 'pt-4',
                        !showFooter && 'pb-4',
                        classes.description
                    )}
                >
                    {description}
                </div>
                {showFooter ? (
                    <div className="flex px-4 pt-3 pb-4 border-0 items-center">
                        <div className={classNames('flex-1 wk-text-12 wk-font-regular', classes.progress)}>
                            {!hideProgress ? `${current + 1}/${length}` : ''}
                        </div>
                        {moreButtonShow ? (
                            <WKTextButton
                                {...moreButtonProps}
                                data-testid="wk-tour-step-more"
                                type="primary"
                                className={classes['more-button']}
                                size={12}
                                onClick={_onClickMore}
                            >
                                {moreButtonText ?? translation('LearnMore')}
                            </WKTextButton>
                        ) : null}
                        {showPrevButton ? (
                            <WKButton
                                {...prevButtonProps}
                                data-testid={`wk-tour-step-prev-${current + 1}`}
                                white={!isWhiteTheme}
                                type="secondary"
                                size="small"
                                onClick={(e) => {
                                    onClickPrev()
                                    _onClickPrev?.(e)
                                }}
                            >
                                {prevButtonText ?? translation('Prev')}
                            </WKButton>
                        ) : null}
                        {nextButtonShow ? (
                            <WKButton
                                {...nextButtonProps}
                                data-testid={`wk-tour-step-next-${current + 1}`}
                                white={!isWhiteTheme}
                                type="primary"
                                size="small"
                                onClick={(e) => {
                                    onClickNext()
                                    _onClickNext?.(e)
                                }}
                            >
                                {nextButtonText ?? (current === length - 1 ? translation('Done') : translation('Next'))}
                            </WKButton>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export const StepContent = forwardRef(_StepContent)
