interface ChatbotFunctionEntryButtonProps {
    icon: React.ReactNode
    title: string
    description: string
    onClick: () => void
}

export const ChatbotFunctionEntryButton = ({
    icon,
    title,
    description,
    onClick,
    ...props
}: ChatbotFunctionEntryButtonProps) => {
    return (
        <div
            {...props}
            className="box-border px-4 py-3 w-200px h-68px flex flex-row justify-start items-start gap-2
                        rounded-4px bg-$wk-v2-fill-color-gray-1 hover:bg-$wk-v2-gray-color-2 active:bg-$wk-gray-3"
            onPointerDown={(e) => {
                e.stopPropagation()
            }}
            onClick={onClick}
        >
            <div className="color-$wk-brand-7 py-3px h-4 w-4">{icon}</div>
            <div className="flex flex-col justify-start items-start gap-2px">
                <div className="text-13px font-500 lh-22px color-$wk-v2-label-color-gray-13">{title}</div>
                <div className="wk-text-12 font-400 color-$wk-v2-label-color-gray-8">{description}</div>
            </div>
        </div>
    )
}
