export const MotiffGroup = [
    '83d894b6e504d771fef8bc2adc7dd6950aef31ae',
    'b02d0d9ae7de3736f97b6f1c5b162ac7dde63a1a',
    '6f2a586ce90b01e986c4b79e89fc78fe796229e4',
    '07de847f05c0a1ab68862dd89660254ba39d540c',
    'f60e805d8ac53117369ef63077dbd96b097f6159',
    '22f9c521b91cec11b3c4c8e5681d4d2fda4b8f1e',
    '744f848c90cfc7f61fc3e354ec3be2b6d7c02215',
    '08ac0f16bed1e112f441fbbe775ff11a1957821a',
    '0ec51799b1b14e549a41d5922e57a2f58623e7df',
    'a276a0a764a2156e196a98d8ab0bd2efa7688881',
    'bb106601a0fcca27f379ca7e6b4864378c73988b',
    '47823c45f1ad325a48805c384aa354a8f71090b2',
    'a33872d486c5180a931f00bfe0e7f5315f7db58c',
    '216a0a529993e408ddd1a7b86fb0865297b9f9fd',
    '15ec0e98b04aa6e2234692ab566876347627f567',
    'a8e97c16b04065434a8b4ea8100bac4f03b0cc88',
    'df146fbccee8970fab1229a57ea63667c6df28f3',
    'f3b753f74ce18157ea2bae31903974f5a7656c3e',
    '226eab88e52b7ad5824135a1447a083557cb1782',
    'a69638c86bd3c86adb564c713006ee0d7d9b6ce4',
    'ef0783d98dc23b86402b3eb9437dd9c86e101ba5',
    '6e0d717d148ad555a886889dd29521beb6c4f86c',
    '93b2d50b70abd736fe8799361dc94170a0598567',
    'd7bd1df2675e57005a7596c0197a4bd31ecbc950',
    '815c1bc60a12c15d19e320526962b4a6281d3342',
    '2ce4665f28bf253877c3c2193651c43cab63066a',
    '9d6bf699ed20fc7bfe83d4b392dae8722b702339',
    '20dff2aaca522ac44bf55db0acda5212222716a4',
    'c25a7c619bd3d207ef342fb9328434439de70bd6',
    'a016fbbfa2e137ebffd52005dccbad8d7e6df177',
    '940909b82fdc5bbd708e5fe2c403e9ab9355c267',
    '413067bae9fad3ea29bcb5221f0eb4b0c87f95dd',
    '8924f0f3c2562122c6dce85e95a7e585a82ba76c',
    'a3714adb3b70e3ee45824a673ee27d0aca406118',
    '69362cdfa622a5d3e0312ca637fbfeb03e4fc0aa',
    '137d0ec285db6de87bbb9bcb1f7c12e83a34b179',
    'f5789553a28d25cf9e3a29f1183d92ec1d0c678c',
    'fa51e2763257cb6982b3bcaefb688583edb088e1',
    '98a64953c2f72dcc99fa13393e388ffb5c0f5f1c',
    '32e0168e82e551a6413c5d3c2fef2492855021ae',
    '809899a8ffe30d9875dde1ddbedd545a29f27a89',
    'eab30a3344fa87504e2ecd62d84cd4bc81bcbbe7',
    'e56926dedc5d02235dc4a2809172e5f8364aa094',
    '1f27bf29091571293e49ec182cfcc291440b9802',
    '6f0f879149864bd781853fc27f97999549fe6bd1',
    'f0825cc0e18a05764f27a3ab0f9cae0291cec0c3',
    '354b7762289268d1d33a9d364bd6c1bfdd0534ca',
    'c2520912ef296753265cdcdcf7564f496c39c41a',
    '5fbc10511ed3350efb551d03ac523a05f77dfb22',
    '7e0a41831dcc0d46b6bc3965590112752b6e62cd',
    '572e8113bc837e3d897438f0c7bb1f4fd64b3abe',
    'b90d3727f903bc29e7785c6fa6def25a62f45b3b',
    '225eb66e04c4fbf5474dc54c786bcd347796f116',
    '070b8ebb3062d72c936483aad3c89f7067f11507',
    '4ec1663a76757c645d04b63c612a84e2a6c4e38b',
    '7a97a942c20b4bf74c901e9f34772c5a9b699f44',
    'fa41bf23ad54135e8310a19dcc012fbfb70d29f2',
    'ba359f8f7cabb59bea6a1c75fceeffdef1f40827',
    '187f4103b95a657d2e5cd7845bbbc84b4f577a62',
    'cff50cb5a26acb11aea4dac603e98ca5f6d6a213',
    '4d0c1e32c00069c6a39541d2dcf2a0d7f7322487',
    '37b4208d9301d2607fcb9a638988a7b569fa92f5',
    '14f4742c842da9c4df500abf7791e8edf6a7345d',
    'a2c0199b5d34e5b9fd73c148b260dbe0f2a671be',
    '67001c05d873b3128d9f09cb7faa91c12c9d4aef',
    '05cc727e3c9837ac3b846dccbb3be8066073adc9',
    '96a0470e759201f48ceb2d2f9f134df24a508b59',
    '905875fc0919c2dae30154a89c9722e9de6cb2fe',
    '42dfaf3c6e424285078d88146c7e8083e4f41f67',
    'a1b344b4a0f1c530a29911aebc0a4c922d6b6aec',
    '47615744535cfdba139a5e1f3726e14b717df803',
    '6ff56587307eabf40879536e598e1747ac1cf148',
    'e23500d2c84db4ef691a237a0174e20a7fe00694',
    '50f3cdc8de49baa916742f550ff3300508b9f29f',
    'cf9ed4c7af20cce84627c98ec2934d8b0799c268',
    '59cadb269ba54c39e7071e449ef550d8bb0c2149',
    '5ecb801b4f56f024c6c8f9cfcfa8b2315945855c',
    '87cf974487e0f1adfbd6eb8a83048cbd37a1a413',
    '03cf144a5fc43569d984e3824816c4dcea51b0ee',
    'ce9985f8aebcfd21b6b7efc0bd42d7bcb4303cf7',
    '944ddb9c548d3fd2ff6daaa7f1fe7efc262f1648',
    'b3bbe75472760aa4f051ebc09f037f190effb4fe',
    '8fce0d37b3e046ee745287a80a4e6dbb7ab24112',
    '37df47d2c1f38380843fa7b94b5765558742e64e',
    '5b158efe0cb101d035e4adc2e8568c264bb80c96',
    '7ae203ca28527d09881dbcd553fc356312d116c9',
    '348d3f6cf37b474164b15762e128a129aa5b4391',
    '53d167e961294a6e042f10ea872011f85021b11c',
    '4b5caade05e9baa4c90b8a92b3484c018b6b1f3a',
    '8fcabf2461b28e12adafc83a08bce6c4ef2f01d8',
    '4eb70816066c7bb3f825ea1fa689e2f21cc0a07e',
    '58d265607c2283d28ec440fc3c372232f94ec836',
    '1c62ca9a8c93fc6704c0dc0b985919c6b21a83ef',
    '5098b54580b4c1cd24567de4e7348ba138ab87d3',
    '5824b8fb66a97c2d2882ddf266fbb4e907c414d1',
    '28360ef741b23870bef65fed4bb12db4638a8824',
    'b0fd9eeccfc9a42200ceadb74ccbdc7092288076',
    '414812dc17b993dd5c3576d9f6287166fb993f11',
    '0bcf110ef721fef765e6d3a90e396fe0b81ff0ff',
    'f223b3668e57744e7aaf1e9ae3b6352e247b9799',
    'b7a41271570e64375f679d3934d7945a98968e4c',
    'a8bcd96405277f635067a7fc75e11a58be09943b',
    'fbd4b04bfec4858d4508c447b1cca578a9ba5eae',
    '9612127f5185b393298b0552f5a6c148cc677202',
    '2dc4a7dcfb76e9fd291a7a626e4f5d8ad267b68b',
    '785c6082538367ddc8166391c0486a5a7400822b',
    'f539551df4043ef63503ecdd6ccedadc98ca8787',
    '4681b6ae7bd6d904b13696c5ea3b6cbd6e1b65a0',
    '6373cae2f5c73638ea66e08dd2eb66a853f57003',
    'b618cc6aead92d8d7e4fed7486e7335e1355350c',
    '9605e1c7e9ccfdafdd8cf4a9fa8fd936f63f4f71',
    'bf6ecfe07621f6c201020d2320e56adcebc00cd8',
    '0eef9730e975db219482461c43ec67cb0f4e3e0c',
]
