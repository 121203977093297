import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    变量不能被循环引用: '变量不能循环引用',
    变量不能引用自身: '变量不能引用自身',
} as const

export const enTranslation = {
    变量不能被循环引用: 'Variables cannot have circular references.',
    变量不能引用自身: 'Variables cannot reference themselves.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
