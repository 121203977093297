import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    Design: '设计',
    Prototype: '原型',
    Inspect: '标注',
    Properties: '属性',
    Comments: '评论',
    Export: '导出',
    AI: 'AI',
} as const

export const enTranslation = {
    Design: 'Design',
    Prototype: 'Prototype',
    Inspect: 'Inspect',
    Properties: 'Properties',
    Comments: 'Comments',
    Export: 'Export',
    AI: 'AI',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
