import { MonoIconCanvasLightning16, WKTextButton } from '../../../../../../ui-lib/src'
import { useAppContext } from '../../../../main/app-context'

export const SuggestMessage = ({
    message,
    suggestTexts,
    dataTestId,
}: {
    message?: string
    suggestTexts: string[]
    dataTestId?: string
}) => {
    const chatbotService = useAppContext().chatbotService

    const handleClickSuggestText = (text: string) => {
        chatbotService.handleClickSend([{ text }])
    }

    if (suggestTexts.length === 0) {
        return null
    }

    return (
        <div
            className={`flex-self-start color-$wk-v2-label-color-gray-13 w-full flex flex-col gap-20px`}
            data-testid={dataTestId}
        >
            {message && <div className="text-13px lh-22px font-400">{message}</div>}

            <div className="p-4 flex flex-col gap-3 b-$wk-gray-3 b-solid b-1px rounded-1">
                <div className="flex flex-row justify-start items-center gap-2 text-13px lh-22px font-400">
                    <MonoIconCanvasLightning16 className="color-$wk-v2-label-color-gray-11" />
                    <div className="text-13px lh-22px font-400 select-text">你可以试着这样对我说：</div>
                </div>
                <div className="flex flex-col gap-2">
                    {suggestTexts.map((text, index) => {
                        return (
                            <div
                                className="py-10px px-4 text-13px lh-22px font-400 bg-$wk-v2-fill-color-gray-1 hover:bg-$wk-v2-gray-color-2 rounded-1 w-fit"
                                key={index}
                                onClick={() => handleClickSuggestText(text)}
                            >
                                {text}
                            </div>
                        )
                    })}
                    <WKTextButton
                        className="w-fit"
                        type="primary"
                        size={12}
                        onClick={() => {
                            window.open(`https://shimo.zhenguanyu.com/docs/AzbPvpZyObLrIxgB/`, '_blank')
                        }}
                    >
                        了解更多
                    </WKTextButton>
                </div>
            </div>
        </div>
    )
}

export const EditInitSuggestMessage = () => {
    return (
        <SuggestMessage
            message={'Hi！我可以帮助你快速完成一些批量编辑的操作。'}
            suggestTexts={['选中使用 Inter 字体的文本，并修改成 Roboto。', '将界面中文本翻译成英文。']}
            dataTestId="edit-init-suggest-message"
        />
    )
}
