import { createContext, useContext, ReactNode } from 'react'
import { usePlatformAndDesignSystem } from './use-ai-gen-platform-and-design-system'

const PlatformAndDesignSystemContext = createContext<ReturnType<typeof usePlatformAndDesignSystem> | null>(null)

export function PlatformAndDesignSystemProvider({
    children,
    ...props
}: { children: ReactNode } & Parameters<typeof usePlatformAndDesignSystem>[0]) {
    const value = usePlatformAndDesignSystem(props)
    if (value === null) {
        return null
    }
    return <PlatformAndDesignSystemContext.Provider value={value}>{children}</PlatformAndDesignSystemContext.Provider>
}

export function usePlatformAndDesignSystemInContext() {
    const context = useContext(PlatformAndDesignSystemContext)
    if (context === null) {
        throw new Error('Unreachable code')
    }
    return context
}
