import { IsThisNameInvalidForCreatingVariable, Wukong } from '@wukong/bridge-proto'
import { useCallback, useState } from 'react'
import { Tooltip } from '../../../../../../../ui-lib/src'
import { DraggablePopupV2 } from '../../../../../../../ui-lib/src/components/draggable-popup/popup-v2'
import { Position } from '../../../../../../../ui-lib/src/components/draggable-popup/util'
import { InputV2 } from '../../../../../../../ui-lib/src/components/inputs'
import { Select } from '../../../../../../../ui-lib/src/components/selects'
import { WKButton } from '../../../../../../../ui-lib/src/components/wk-button/button'
import { WKIconButton } from '../../../../../../../ui-lib/src/components/wk-button/icon-button'
import { MonoIconCommonClose16 } from '../../../../../../../ui-lib/src/icons-v2'
import { CommitType } from '../../../../../document/command/commit-type'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { useAppContext } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { useViewState } from '../../../../../view-state-bridge'
import { LocalStorageKey } from '../../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../../web-storage/local-storage/storage'
import { useCommand } from '../../../../context/document-context'
import { ScrubbableInputInteger } from '../../../atom/inputs/scrubbable-input-integer'
import { CreateFloatVariableData } from '../common'
import { useRequiredVariable } from '../use-primitive-variable'
import { translation } from './float-variable-creator.translation'
import { FloatVariablePreview } from './float-variable-preview'
import { PrimitiveVariablePanelBase } from './primitive-variable-panel-base'

interface FloatVariableCreatorProps {
    position?: {
        top: number
        left: number
    }
    initialData?: CreateFloatVariableData
    onCancel: () => void
    onClickCreateFloatVariable: ({ varId, name }: { varId: string; name: string }) => void
}

function getLibraryPreselect(collections: Wukong.DocumentProto.IVariableCreateLocalVisibleCollections[]) {
    const collectionPreselected = enhancedLocalStorage.getSerializedItem(LocalStorageKey.CollectionPreselected)
    if (collectionPreselected && collections.some((v) => v.id === collectionPreselected)) {
        return collectionPreselected
    }
    return collections[collections.length - 1]?.id
}

export const FloatVariableCreator = (props: FloatVariableCreatorProps) => {
    const variableCreate = useViewState('variableCreate')
    const service = useAppContext().variableService
    const command = useCommand()

    const [collectionId, setCollectionId] = useState(getLibraryPreselect(variableCreate?.localVisibleCollections ?? []))
    const [variableName, setVariableName] = useState(() => {
        return service.localVariableEditorService.getNewVariableNameInCollectionAndType(
            collectionId,
            Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT
        )
    })

    const [variableNameInvalid, setVariableNameInvalid] = useState(false)
    const [variableDescription, setVariableDescription] = useState('')
    const [variableData, setVariableData] = useState<CreateFloatVariableData>(
        props.initialData || { type: 'value', value: 0 }
    )

    const checkAndSetVariableNameRepeatState = useCallback(
        (_variableSetId: string, _variableName: string) => {
            const res = command.invokeBridge(CommitType.Noop, IsThisNameInvalidForCreatingVariable, {
                variableSetId: _variableSetId,
                variableName: _variableName,
            })
            setVariableNameInvalid(!!res.value)
            return !!res.value
        },
        [command]
    )

    const onChangeCollectionId = useCallback(
        (v: string) => {
            enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollectionPreselected, v)
            setCollectionId(v)
            checkAndSetVariableNameRepeatState(v, variableName)
        },
        [checkAndSetVariableNameRepeatState, variableName]
    )

    const onChangeVariableName = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            setVariableName(value)
            if (collectionId) {
                checkAndSetVariableNameRepeatState(collectionId, value)
            }
        },
        [checkAndSetVariableNameRepeatState, collectionId]
    )

    const onClickCreateVariable = () => {
        const targetCollectionId = collectionId ? collectionId : service.createCollection('数值变量').value
        if (targetCollectionId) {
            if (checkAndSetVariableNameRepeatState(targetCollectionId, variableName)) {
                return
            }

            const params: Wukong.DocumentProto.IArg_CreateFloatVariable = {
                variableSetId: targetCollectionId,
                name: variableName,
                description: variableDescription,
            }

            if (variableData.type === 'alias') {
                params.alias = variableData.alias
            } else {
                params.floatValue = variableData.value
            }

            const varId = service.createFloatVariable(params)
            if (varId.value) {
                props.onClickCreateFloatVariable({ varId: varId.value, name: variableName })
            }
        }
    }

    const { hasRequiredVariable } = useRequiredVariable({
        requiredScopes: [Wukong.DocumentProto.VariableScope.ALL_SCOPES],
        requiredTypes: [Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT],
    })
    const [varPickerInfo, setVarPickerInfo] = useState<{ show: false } | { show: true; position: Position }>({
        show: false,
    })

    const openVarPicker = useCallback((p: { rootRect: DOMRect }) => {
        setVarPickerInfo({
            position: { top: p.rootRect.bottom + 8, left: p.rootRect.left },
            show: true,
        })
    }, [])

    const onVariableSelected = (id: string, name: string, _: boolean) => {
        setVarPickerInfo({ show: false })
        setVariableData({ type: 'alias', alias: id, name })
    }

    return (
        <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (props.onCancel(), false)}>
            <DraggablePopupV2
                position={props.position}
                positionRightBase
                width={240}
                visible={true}
                closable={false}
                mask
                header={
                    <div className="flex items-center justify-between pl-16px pr-10px h-40px">
                        <div className="flex-1 wk-font-semibold text-black wk-text-12">{translation('Title')}</div>
                        <WKIconButton icon={<MonoIconCommonClose16 />} onClick={props.onCancel} />
                    </div>
                }
                footer={null}
                onCancel={props.onCancel}
                testId="float-variable-creator"
                closeTestId="primitive-variable-panel-close"
                bodyClassName="p-0!"
            >
                <div className="p-16px grid grid-cols-[auto_1fr] items-center gap-x-16px gap-y-8px">
                    {variableCreate?.localVisibleCollections?.length ? (
                        <>
                            <div>{translation('Collection')}</div>
                            <Select.NormalSingleLevel
                                value={collectionId}
                                label={variableCreate?.localVisibleCollections.find((v) => v.id === collectionId)?.name}
                                onChange={onChangeCollectionId}
                                height="32px"
                                className="overflow-hidden"
                            >
                                {variableCreate?.localVisibleCollections.map((v) => (
                                    <Select.NormalSingleLevel.Option
                                        key={v.id}
                                        value={v.id}
                                        data-testid="collection-name"
                                    >
                                        {v.name}
                                    </Select.NormalSingleLevel.Option>
                                ))}
                            </Select.NormalSingleLevel>
                        </>
                    ) : null}
                    <div>{translation('Name')}</div>
                    <InputV2 value={variableName} autoFocus onChange={(e) => onChangeVariableName(e)}></InputV2>
                    <div>{translation('Description')}</div>
                    <InputV2.Textarea
                        value={variableDescription}
                        onChange={(e) => setVariableDescription((e.target as unknown as HTMLTextAreaElement).value)}
                        placeholder={translation('DescriptionPlaceholder')}
                        autoHeight
                    ></InputV2.Textarea>
                    <div className="col-span-2 border-b border-$wk-v2-stroke-color-gray-2 w-[calc(100%+32px)] -mx-4 pt-2"></div>
                    <div>{translation('Value')}</div>
                    {variableData.type === 'value' ? (
                        <div className="h-28px">
                            <ScrubbableInputInteger
                                onChange={(value) => setVariableData({ type: 'value', value: Number(value) })}
                                value={variableData.value}
                                testId={'float-variable-creator-value'}
                                scrubbingDisabled
                                useVariable={
                                    hasRequiredVariable
                                        ? {
                                              open: varPickerInfo.show,
                                              notAvailable: false,
                                              variable: null,
                                              onUnbind: () => {},
                                              iconBindUnbind: {
                                                  onBind: openVarPicker,
                                              },
                                          }
                                        : undefined
                                }
                            />
                            {varPickerInfo.show && varPickerInfo.position && (
                                <PrimitiveVariablePanelBase
                                    position={varPickerInfo.position}
                                    onCancel={() => setVarPickerInfo({ show: false })}
                                    onVariableSelected={onVariableSelected}
                                    requiredTypes={[
                                        Wukong.DocumentProto.PrimitiveVariableType.PRIMITIVE_VARIABLE_TYPE_FLOAT,
                                    ]}
                                    requiredScopes={[]}
                                />
                            )}
                        </div>
                    ) : (
                        <FloatVariablePreview
                            variableName={variableData.name}
                            onDetach={() => setVariableData({ type: 'value', value: 0 })}
                        />
                    )}
                </div>
                <div className="flex flex-row items-center justify-end px-16px pb-16px">
                    <Tooltip
                        title={translation('InvalidVariableName')}
                        inactivation={!(!variableName || variableNameInvalid)}
                    >
                        <div>
                            <WKButton
                                type="primary"
                                onClick={onClickCreateVariable}
                                disabled={!variableName || variableNameInvalid}
                                dataTestId="float-variable-create-button"
                            >
                                {translation('Create')}
                            </WKButton>
                        </div>
                    </Tooltip>
                </div>
            </DraggablePopupV2>
        </KeyboardReceiver>
    )
}
