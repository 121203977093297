/* eslint-disable import/no-deprecated */
import { Wukong } from '@wukong/bridge-proto'
import { difference, differenceWith, intersectionWith, isEqual, uniq } from 'lodash-es'
import { delay } from 'signal-timers'
import { ConcurrentScheduler } from '../../../../../../util/src'
import { createSwitchTask, signalDebounce } from '../../../../../../util/src/abort-controller/signal-task'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { createCombineLatestEventHandler } from '../../../../../../util/src/combine-latest-event-handler'
import { distinctFn } from '../../../../../../util/src/distinct-fn'
import { BehaviorEventEmitter } from '../../../../../../util/src/event-emitter/behavior-event-emitter'
import { ReplayEventEmitter } from '../../../../../../util/src/event-emitter/replay-event-emitter'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import type {
    LibraryContentMetaInfoVO,
    LibraryContentVO,
    LibraryIdAndNameVO,
    LibrarySubscriptionVO,
} from '../../../../kernel/interface/library'
import { MessageContentType } from '../../../../kernel/interface/notify'
import { DocID, OrgID } from '../../../../kernel/interface/type'
import { WsPropertyChangeMessage, WsRelationChangeMessage } from '../../../../kernel/notify/notify-message'
import { ConnectInfo, NotifyService } from '../../../../kernel/notify/notify-service'
import { GetDocRequest } from '../../../../kernel/request/document'
import {
    GetLibraryContentBaseInfoMap,
    GetLibraryIdAndNameByDocIdRequest,
    GetLibraryIdAndNameMapRequest,
} from '../../../../kernel/request/library'
import { GetLibrarySubscription } from '../../../../kernel/request/library-subscription'
import { OssError } from '../../../../kernel/request/oss'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { transformLibraryContentVO } from '../../../../kernel/util/transform-library-data-struct'
import { createFileManager } from '../../../../main/create-file-manager'
import {
    LibraryResourceOssClientType,
    type LibraryResourceDownloader,
} from '../../../../share/component-style-library/service/library-resource-downloader'
import { isLibraryContentMapEqual } from '../util/equal'

enum SubjectUpdateSourceType {
    Init,
    Reset,
    Fetch,
}

// 处理 notify 上下行消息生成数据源
export class LibraryNotifySyncService extends ServiceClass {
    // 当前文档所在 orgId（用于 notify 上行消息）
    private readonly currentOrgId$ = new ReplayEventEmitter<OrgID>(1)

    // 当前 notify 初次连接/断线重连 后通知
    private readonly notifyConnectRefresher$ = new ReplayEventEmitter<ConnectInfo>(1)

    // 所有远端组件库内容
    private allRemoteLibraryContentMap$ = new ReplayEventEmitter<Map<LibraryId, LibraryContentVO>>(1)
    private readonly allRemoteLibraryContentMap$$ = new BehaviorEventEmitter<{
        updateSourceType: SubjectUpdateSourceType
        value: Map<LibraryId, LibraryContentVO>
    }>({ updateSourceType: SubjectUpdateSourceType.Init, value: new Map<LibraryId, LibraryContentVO>() })

    public async getAllRemoteLibraryContentPromise(
        check: (params: Map<string, LibraryContentVO>) => boolean = () => true
    ) {
        return new Promise<Map<LibraryId, LibraryContentVO>>((resolve) => {
            this.allRemoteLibraryContentMap$
                .getFirstValueWithFilter(this.signal, check)
                .then((value) => {
                    resolve(value)
                })
                .catch(() => {
                    resolve(new Map<LibraryId, LibraryContentVO>())
                })
        })
    }
    public onAllRemoteLibraryContentChanged = (
        onChange: (newValue: Map<LibraryId, LibraryContentVO>) => void,
        signal: TraceableAbortSignal
    ) => {
        this.allRemoteLibraryContentMap$.onWithSignal(signal, onChange)
    }

    // 当前文档的远端组件库内容(不包括订阅了别的组件库的)
    private currentRemoteLibraryContent$ = new ReplayEventEmitter<LibraryContentVO | null>(1)
    public onCurrentRemoteLibraryContentChanged = (
        onChange: (newValue: LibraryContentVO | null) => void,
        signal: TraceableAbortSignal
    ) => {
        return this.currentRemoteLibraryContent$.onWithSignal(signal, onChange)
    }

    public getCurrentRemoteLibraryContentPromise = () => {
        return this.currentRemoteLibraryContent$.getFirstValue(this.signal)
    }

    // 所有订阅同步的 docIds
    private readonly allSyncDocumentIds$ = new BehaviorEventEmitter(new Array<DocID>())

    // 订阅同步的 docIds 关联的移动目标组件库 docIds（例：当前文档引用文档 A 的组件 C1，组件 C1 被移动到文档 B 中，则需要将文档 B 的 docId 加入此列表）
    private readonly movedRelatedDocumentIds$ = new BehaviorEventEmitter(new Set<DocID>())

    // 当前订阅引用的组件库列表
    private readonly subscribedLibraryInfoList$ = new BehaviorEventEmitter<LibrarySubscriptionVO[]>([])
    public readonly onSubscribedLibraryInfoListChanged = (
        onChange: (newValue: LibrarySubscriptionVO[]) => void,
        signal: TraceableAbortSignal
    ) => {
        this.subscribedLibraryInfoList$.onWithSignal(signal, onChange)
    }
    public getLatestSubscribedLibraryInfoList = () => {
        return this.subscribedLibraryInfoList$.getValue()
    }
    // 当前文档 wasm 侧引用同步的 docIds（包括 inUsed、替换弹窗、远端详情页等涉及到的 docIds）
    private readonly wasmNeedSyncDocumentIds$ = new BehaviorEventEmitter(new Array<DocID>())

    // 当前缓存的所有 docId-> 文档&组件库基本信息 的 map
    private readonly cachedDocumentId2LibraryIdInfoMap$ = new BehaviorEventEmitter(new Map<DocID, LibraryIdAndNameVO>())
    public readonly onCachedDocumentId2LibraryIdInfoMapChanged = (
        onChange: (newValue: Map<DocID, LibraryIdAndNameVO>) => void,
        signal: TraceableAbortSignal
    ) => {
        this.cachedDocumentId2LibraryIdInfoMap$.onWithSignal(signal, onChange)
    }
    public getLatestCachedDocumentId2LibraryIdInfoMap = () => {
        return this.cachedDocumentId2LibraryIdInfoMap$.getValue()
    }
    // ws 下发某个组件库内容变更
    private singleLibraryContentChange$ = new ReplayEventEmitter<LibraryId>(1)

    // ws 下发其他文档变更（关注文档名称变更）
    private otherDocNameChange$ = new ReplayEventEmitter<DocID>(1)

    // ws 下发当前文档订阅引用组件库变更
    private currentDocLibrarySubscriptionChange$ =
        new ReplayEventEmitter<Wukong.NotifyProto.IBusinessEntityRelationChange>(1)
    // ws 下发当前文档权限变更
    private currentDocAuthorizationChange$ = new ReplayEventEmitter<Wukong.NotifyProto.IBusinessEntityRelationChange>(1)
    // ws 下发当前文档所在组织任意团队/草稿箱订阅默认组件库变更
    private orgDefaultLibrarySubscriptionChange$ =
        new ReplayEventEmitter<Wukong.NotifyProto.IBusinessEntityRelationChange>(1)

    constructor(
        private readonly docId: DocID,
        private readonly libraryResourceDownloader: LibraryResourceDownloader,
        private readonly notifyService: NotifyService,
        private readonly signal: TraceableAbortSignal
    ) {
        super()
        notifyService.onConnectChangeWithSignal(this.signal, (connectInfo) => {
            console.info('Running notify session id', connectInfo.sessionId)
            if (connectInfo.sessionId) {
                this.notifyConnectRefresher$.next(connectInfo)
            }
        })

        this.injectCreateFileCallBack(this.initCurrentOrgId$.bind(this))
        this.initNotifyChangeListeners()
        this.syncCurrentDoc()
        this.initRemoteLibraryContentMap()
        this.initMovedRelatedDocIds()
        this.injectCreateFileCallBack(this.initCurrentDocLibrarySubscription.bind(this))
    }

    private injectCreateFileCallBack(fn: () => void) {
        if (createFileManager.isCreatingFile()) {
            createFileManager.injectCreateFileCallBack(fn)
        } else {
            fn()
        }
    }

    private async getDocOrgIdWithRetry() {
        const maxRetryAttempts = 2
        const scalingDuration = 1000
        for (let i = 0; i < maxRetryAttempts; i++) {
            try {
                return (await new GetDocRequest(this.docId).startWithSignal(this.signal)).orgId
            } catch (e) {
                if (this.signal.aborted || i === maxRetryAttempts - 1) {
                    throw e
                } else {
                    await delay(scalingDuration * i, { signal: this.signal })
                }
            }
        }
    }
    private initCurrentOrgId$ = async () => {
        try {
            const orgId = await this.getDocOrgIdWithRetry()
            if (orgId) {
                this.currentOrgId$.next(orgId)
            } else {
                throw new Error('[LibraryWsService] 获取当前文档 orgId 失败')
            }
        } catch (e) {
            if (this.isDestroy) {
                return
            }
            throw e
        }
    }

    // 设置监听 ws 下行消息
    private initNotifyChangeListeners = () => {
        const propertyChange$ = new ReplayEventEmitter<Wukong.NotifyProto.BusinessEntityPropertiesChange>(1)
        const relationChange$ = new ReplayEventEmitter<Wukong.NotifyProto.BusinessEntityRelationChange>(1)
        this.notifyService.onBusinessMessageChangeWithSignal(this.signal, (proto) => {
            if (proto.businessCode == WsPropertyChangeMessage.code) {
                propertyChange$.next(WsPropertyChangeMessage.bodyType.decode(proto.payload))
            }
            if (proto.businessCode == WsRelationChangeMessage.code) {
                relationChange$.next(WsRelationChangeMessage.bodyType.decode(proto.payload))
            }
        })
        // singleLibraryContentChange
        propertyChange$.onWithSignal(this.signal, (body) => {
            if (
                body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.LIBRARY &&
                !!body.businessEntity?.entityId
            ) {
                const libraryId = body.businessEntity!.entityId!
                const docId = body.businessEntity?.ancestors?.find(
                    (ancestor) => ancestor.entityType == Wukong.NotifyProto.EntityType.DOC
                )?.entityId
                const allSyncDocumentIds = this.allSyncDocumentIds$.getValue()
                if (!!docId && allSyncDocumentIds.includes(docId)) {
                    this.singleLibraryContentChange$.next(libraryId)
                }
            }
        })
        // otherDocNameChange
        propertyChange$.onWithSignal(this.signal, (body) => {
            if (!body.changedProperties.name) {
                return
            }
            if (
                !(
                    body.businessEntity?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                    !!body.businessEntity?.entityId
                )
            ) {
                return
            }
            if (
                !(
                    body.businessEntity?.entityId !== this.docId &&
                    this.allSyncDocumentIds$.getValue().includes(body.businessEntity?.entityId!)
                )
            ) {
                return
            }
            this.otherDocNameChange$.next(body.businessEntity?.entityId!)
        })
        // currentDocLibrarySubscriptionChange
        relationChange$.onWithSignal(this.signal, (body) => {
            if (body.relation?.relation != Wukong.NotifyProto.Relation.LIBRARY_SUBSCRIPTION) {
                return
            }
            if (
                !(
                    body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                    body.relation.one.entityId === this.docId
                )
            ) {
                return
            }
            if (
                !(
                    body.relation?.another?.entityType === Wukong.NotifyProto.EntityType.LIBRARY &&
                    !!body.relation!.another.entityId
                )
            ) {
                return
            }
            this.currentDocLibrarySubscriptionChange$.next(body)
        })
        // orgDefaultLibrarySubscriptionChange
        relationChange$.onWithSignal(this.signal, (body) => {
            if (body.relation?.relation != Wukong.NotifyProto.Relation.DEFAULT_LIBRARY_SUBSCRIPTION) {
                return
            }
            if (
                !(
                    body.relation?.another?.entityType === Wukong.NotifyProto.EntityType.LIBRARY &&
                    !!body.relation!.another.entityId &&
                    !!body.relation?.another?.ancestors?.find(
                        (entity) => entity.entityType === Wukong.NotifyProto.EntityType.ORG
                    )
                )
            ) {
                return
            }
            this.orgDefaultLibrarySubscriptionChange$.next(body)
        })
        // currentDocAuthorizationChange
        relationChange$.onWithSignal(this.signal, (body) => {
            if (body.changeType !== Wukong.NotifyProto.RelationChangeType.UPDATE) {
                return
            }
            if (body.relation?.relation != Wukong.NotifyProto.Relation.AUTHORIZATION) {
                return
            }
            if (
                !(
                    body.relation?.one?.entityType == Wukong.NotifyProto.EntityType.DOC &&
                    body.relation.one.entityId === this.docId
                )
            ) {
                return
            }
            this.currentDocAuthorizationChange$.next(body)
        })
    }

    // 订阅同步当前文档
    private syncCurrentDoc = () => {
        const callback1 = (orgId: OrgID) => {
            const filters = [
                {
                    filterParameters: [
                        {
                            name: 'entityType',
                            value: Wukong.NotifyProto.EntityType.ORG - 1 + '',
                        },
                        {
                            name: 'entityId',
                            value: orgId,
                        },
                    ],
                },
            ]
            this.notifyService.sendSubscribeProto(orgId, {
                [MessageContentType.RelationChange]: {
                    filters,
                },
                [MessageContentType.PropertyChange]: {
                    filters,
                },
            })
        }

        const combineLatestEventHandler = createCombineLatestEventHandler(
            {
                currentOrgId: { init: false },
                notifyConnectRefresher: { init: false },
            },
            ({ currentOrgId }: { currentOrgId: string; notifyConnectRefresher: ConnectInfo }) => {
                callback1(currentOrgId)
            }
        )
        this.currentOrgId$.onWithSignal(this.signal, (currentOrgId) => {
            combineLatestEventHandler.handleEvent('currentOrgId', currentOrgId)
        })
        this.notifyConnectRefresher$.onWithSignal(this.signal, (notifyConnectRefresher) => {
            combineLatestEventHandler.handleEvent('notifyConnectRefresher', notifyConnectRefresher)
        })

        const callback2 = () => {
            const allSyncDocumentIds = [...this.allSyncDocumentIds$.getValue()]
            // allSyncDocumentIds 中含有当前 docId，说明为断线重连，需要重新订阅同步所有 docIds
            if (allSyncDocumentIds.includes(this.docId)) {
                this.allSyncDocumentIds$.next([])
                this.allRemoteLibraryContentMap$$.next({
                    updateSourceType: SubjectUpdateSourceType.Reset,
                    value: new Map(),
                })
                this.addSyncDocIds(allSyncDocumentIds)
            } else {
                this.addSyncDocIds([this.docId])
            }
        }

        this.notifyConnectRefresher$.onWithSignal(this.signal, callback2)
        this.currentDocAuthorizationChange$.onWithSignal(this.signal, callback2)
    }

    // 添加需要订阅同步 文档权限、libraryContent 变化的 docIds
    public addSyncDocIds = (docIds: DocID[]) => {
        if (!docIds.length) {
            return
        }

        const addDocIds = this.addItemsInTargetArray$(this.allSyncDocumentIds$, docIds)
        if (!addDocIds.length) {
            return
        }

        const filters = this.getSendNotifyFilterParamByDocIds(addDocIds)

        this.currentOrgId$.onWithSignal(this.signal, (orgId) => {
            this.notifyService.sendSubscribeProto(orgId, {
                [MessageContentType.RelationChange]: {
                    filters,
                },
                [MessageContentType.PropertyChange]: {
                    filters,
                },
            })
        })
    }

    // 移除无需订阅同步 文档权限、libraryContent 变化的 docIds
    private removeSyncDocIds = (docIds: DocID[]) => {
        if (!docIds.length) {
            return
        }

        const removedDocIds = this.removeItemsInTargetArray$(
            this.allSyncDocumentIds$,
            this.filterNoNeedRemovedDocIds(docIds)
        )
        if (!removedDocIds.length) {
            return
        }

        const filters = this.getSendNotifyFilterParamByDocIds(removedDocIds)
        this.currentOrgId$.onWithSignal(this.signal, (orgId) => {
            this.notifyService.sendUnSubscribeProto(orgId, {
                [MessageContentType.RelationChange]: {
                    filters,
                },
                [MessageContentType.PropertyChange]: {
                    filters,
                },
            })
        })
    }

    // 过滤不能被 removeSync 的 docIds
    private filterNoNeedRemovedDocIds = (removeDocIds: DocID[]): DocID[] => {
        const noNeedRemovedDocIds = [
            ...this.movedRelatedDocumentIds$.getValue(),
            ...this.subscribedLibraryInfoList$.getValue().map(({ libraryDocId }) => libraryDocId),
            ...this.wasmNeedSyncDocumentIds$.getValue(),
        ]
        return removeDocIds.filter((docId) => !noNeedRemovedDocIds.includes(docId))
    }

    private getSendNotifyFilterParamByDocIds = (docIds: string[]) =>
        uniq(docIds).map((id) => ({
            filterParameters: [
                {
                    name: 'entityType',
                    value: Wukong.NotifyProto.EntityType.DOC - 1 + '',
                },
                {
                    name: 'entityId',
                    value: id,
                },
            ],
        }))

    private fetchAllLibraryIds = async (signal: TraceableAbortSignal, allSyncDocumentIds: string[]) => {
        return new Promise<LibraryId[]>((resolve) => {
            const unCachedDocumentIds = allSyncDocumentIds.filter(
                (id) => !this.cachedDocumentId2LibraryIdInfoMap$.getValue().has(id)
            )

            const combineLibraryIds = (documentId2LibraryInfoMap: Map<DocID, LibraryIdAndNameVO>) =>
                allSyncDocumentIds.reduce((ret, docId) => {
                    const libraryInfo = documentId2LibraryInfoMap.get(docId)
                    if (libraryInfo) {
                        return [...ret, libraryInfo.id]
                    }
                    return ret
                }, [] as LibraryId[])

            if (!unCachedDocumentIds.length) {
                resolve(combineLibraryIds(this.cachedDocumentId2LibraryIdInfoMap$.getValue()))
                return
            }
            new GetLibraryIdAndNameMapRequest(unCachedDocumentIds, this.docId)
                .startWithSignal(signal)
                .then((voMap) => {
                    this.updateCachedDocumentId2LibraryInfoMap$(voMap)
                    resolve(combineLibraryIds(this.cachedDocumentId2LibraryIdInfoMap$.getValue()))
                })
                .catch(() => {})
        })
    }

    private fetchAllRemoteLibraryContentMap = async (
        signal: TraceableAbortSignal,
        allLibraryIds: LibraryId[],
        voMap: Map<LibraryId, LibraryContentVO>
    ) => {
        return new Promise<Map<LibraryId, LibraryContentVO>>((resolve) => {
            const unFetchedLibraryIds = allLibraryIds.filter((libraryId) => !voMap.has(libraryId))

            const combineLibraryContentMap = (remoteLibraryContentMap: Map<LibraryId, LibraryContentVO>) =>
                allLibraryIds.reduce((ret, libraryId) => {
                    const vo = remoteLibraryContentMap.get(libraryId)
                    if (vo) {
                        ret.set(libraryId, vo)
                    }
                    return ret
                }, new Map<LibraryId, LibraryContentVO>())

            if (!unFetchedLibraryIds.length) {
                resolve(combineLibraryContentMap(voMap))
                return
            }
            new GetLibraryContentBaseInfoMap(unFetchedLibraryIds, this.docId)
                .startWithSignal(signal)
                .then((baseInfoVOMap) => {
                    const concurrentScheduler = ConcurrentScheduler({ delayTime: 0, limitCount: 20 })
                    const allTaks = Object.values(baseInfoVOMap).map((baseInfoVO) => {
                        return concurrentScheduler.add(async () =>
                            this.libraryResourceDownloader
                                .fetchFile(LibraryResourceOssClientType.Library, baseInfoVO.resourceId)
                                .then((fetchedResponse) => fetchedResponse.json())
                                .catch((e: OssError) => {
                                    if (e.code === 'NoSuchKey') {
                                        WkCLog.log('RemoteLibrary', {
                                            traceEventName: 'INFO_SCHEMA_VERSION_NOT_MATCH',
                                            traceEventKey: 1386768,
                                            scenario: 'RemoteLibrary',
                                            libraryId: baseInfoVO.library.id,
                                            resourceId: baseInfoVO.resourceId,
                                            requestId: e.requestId,
                                        })
                                    }
                                })
                                .then((metaInfo: LibraryContentMetaInfoVO | null) =>
                                    metaInfo ? transformLibraryContentVO(baseInfoVO, metaInfo) : null
                                )
                        )
                    })
                    Promise.all(allTaks).then((ret) => {
                        const newVoMap = ret.reduce(
                            (res, cur) => (cur ? { ...res, [cur.library.id]: cur } : res),
                            {} as Record<LibraryId, LibraryContentVO>
                        )
                        resolve(combineLibraryContentMap(new Map([...voMap.entries(), ...Object.entries(newVoMap)])))
                    })
                })
                .catch(() => {})
        })
    }

    private initRemoteLibraryContentMap = () => {
        const updateAllRemoteLibraryContentMap = distinctFn(
            (value) => {
                this.allRemoteLibraryContentMap$.next(value)
            },
            (prev: Map<string, LibraryContentVO>, curr: Map<string, LibraryContentVO>) =>
                isLibraryContentMapEqual(prev, curr)
        )

        this.allRemoteLibraryContentMap$$.onWithSignal(this.signal, ({ updateSourceType, value }) => {
            if (updateSourceType !== SubjectUpdateSourceType.Fetch) {
                return
            }
            updateAllRemoteLibraryContentMap(value)
        })

        this.allRemoteLibraryContentMap$.onWithSignal(this.signal, (allRemoteLibraryContentMap) => {
            const convert = () => {
                const optCurrentLibraryInfo = this.cachedDocumentId2LibraryIdInfoMap$.getValue().get(this.docId)
                if (!optCurrentLibraryInfo) return null
                return allRemoteLibraryContentMap.get(optCurrentLibraryInfo.id) ?? null
            }
            const value = convert()
            this.currentRemoteLibraryContent$.next(value)
        })

        const getLibraryIdAndNameByDocIdRequest = createSwitchTask(
            this.signal,
            async (
                signal: TraceableAbortSignal,
                docId: DocID,
                callback: (ret: LibraryIdAndNameVO | undefined) => void
            ) => {
                new GetLibraryIdAndNameByDocIdRequest(docId, this.docId).startWithSignal(signal).then((ret) => {
                    callback(ret)
                })
            }
        )
        this.otherDocNameChange$.onWithSignal(this.signal, (docId) => {
            getLibraryIdAndNameByDocIdRequest(docId, (ret) => {
                if (!ret?.id) {
                    return
                }
                uppdateLibraryContentBaseInfoMap(ret.id as LibraryId)
            })
        })
        this.singleLibraryContentChange$.onWithSignal(this.signal, (libraryId) => {
            uppdateLibraryContentBaseInfoMap(libraryId)
        })

        const fetchLibraryContentBaseInfoMap = async (libraryId: string) => {
            return new Promise<[LibraryId, LibraryContentVO | undefined]>((resolve) => {
                new GetLibraryContentBaseInfoMap([libraryId], this.docId)
                    .startWithSignal(this.signal)
                    .then((baseInfoVOMap) => {
                        return baseInfoVOMap[libraryId]
                    })
                    .then((baseInfoVO) => {
                        if (!baseInfoVO) {
                            resolve([libraryId, undefined])
                            return
                        }
                        this.libraryResourceDownloader
                            .fetchFile(LibraryResourceOssClientType.Library, baseInfoVO.resourceId)
                            .then((fetchedResponse) => fetchedResponse.json())
                            .catch((e: OssError) => {
                                if (e.code === 'NoSuchKey') {
                                    WkCLog.log('RemoteLibrary', {
                                        traceEventName: 'INFO_SCHEMA_VERSION_NOT_MATCH',
                                        traceEventKey: 1386768,
                                        scenario: 'RemoteLibrary',
                                        docId: this.docId,
                                        libraryId: baseInfoVO.library.id,
                                        resourceId: baseInfoVO.resourceId,
                                        requestId: e.requestId,
                                    })
                                }
                            })
                            .then((metaInfo) => (metaInfo ? transformLibraryContentVO(baseInfoVO, metaInfo) : null))
                            .then((libraryContent) => {
                                resolve([libraryId, libraryContent ?? undefined])
                            })
                    })
                    .catch(() => {})
            })
        }

        const buildNewVoMapAndUpdate = (
            libraryId: LibraryId,
            optVO: LibraryContentVO | undefined,
            value: Map<LibraryId, LibraryContentVO>
        ) => {
            const newVoMap = new Map(value)
            if (optVO) {
                newVoMap.set(libraryId, optVO)
                this.updateCachedDocumentId2LibraryInfoMap$({
                    [optVO.library.document!.id]: {
                        id: libraryId,
                        documentName: optVO.library.document!.name,
                        shared: !!optVO.library.shared,
                        docId: optVO.library.document!.id,
                    },
                })
            } else {
                newVoMap.delete(libraryId)
            }
            this.allRemoteLibraryContentMap$$.next({
                updateSourceType: SubjectUpdateSourceType.Fetch,
                value: newVoMap,
            })
        }

        const uppdateLibraryContentBaseInfoMap = (currentLibraryId: LibraryId) => {
            fetchLibraryContentBaseInfoMap(currentLibraryId).then(([libraryId, optVO]) => {
                buildNewVoMapAndUpdate(libraryId, optVO, this.allRemoteLibraryContentMap$$.getValue().value)
            })
        }

        const fetchLatestAllRemoteLibraryContentMap = createSwitchTask(
            this.signal,
            async (
                signal: TraceableAbortSignal,
                allLibraryIds: LibraryId[],
                voMap: Map<LibraryId, LibraryContentVO>
            ) => {
                await this.fetchAllRemoteLibraryContentMap(signal, allLibraryIds, voMap).then((newVoMap) => {
                    this.allRemoteLibraryContentMap$$.next({
                        updateSourceType: SubjectUpdateSourceType.Fetch,
                        value: newVoMap,
                    })
                })
            }
        )
        const fetchLatestAllLibraryIds = createSwitchTask(
            this.signal,
            async (signal: TraceableAbortSignal, allSyncDocumentIds: string[]) => {
                await this.fetchAllLibraryIds(signal, allSyncDocumentIds).then((allLibraryIds) => {
                    fetchLatestAllRemoteLibraryContentMap(
                        allLibraryIds,
                        this.allRemoteLibraryContentMap$$.getValue().value
                    )
                })
            }
        )

        const onallSyncDocumentIds$Changed = signalDebounce(
            this.signal,
            (allSyncDocumentIds: string[]) => {
                if (!allSyncDocumentIds.length) {
                    return
                }
                fetchLatestAllLibraryIds(allSyncDocumentIds)
            },
            300
        )
        this.allSyncDocumentIds$.onWithSignal(this.signal, (allSyncDocumentIds) => {
            onallSyncDocumentIds$Changed(allSyncDocumentIds)
        })
    }
    // sync 的组件库中有移动操作时，需要同步移动相关的 docIds
    private initMovedRelatedDocIds = () => {
        // sync 移动相关的 docIds
        this.allRemoteLibraryContentMap$.onWithSignal(this.signal, (allRemoteLibraryContentMap) => {
            const unSyncDocIds: DocID[] = []
            allRemoteLibraryContentMap.forEach((vo) => {
                const libraryInfoMap = vo.libraryMovedInfos.libraryId2LibraryInfo
                this.updateCachedDocumentId2LibraryInfoMap$(libraryInfoMap)
                Object.values(libraryInfoMap).forEach((libraryInfo) => {
                    if (!allRemoteLibraryContentMap.has(libraryInfo.id)) {
                        unSyncDocIds.push(libraryInfo.docId)
                    }
                })
            })
            this.addSyncDocIds(unSyncDocIds)
        })

        // 收集移动相关的 docIds
        this.allRemoteLibraryContentMap$.onWithSignal(this.signal, (allRemoteLibraryContentMap) => {
            this.movedRelatedDocumentIds$.next(
                new Set(
                    [...allRemoteLibraryContentMap.values()]
                        .map((vo) =>
                            Object.values(vo.libraryMovedInfos.libraryId2LibraryInfo).map(
                                (libraryInfo) => libraryInfo.docId
                            )
                        )
                        .flat()
                )
            )
        })
    }

    private updateCachedDocumentId2LibraryInfoMap$ = (
        newMapValue: Map<DocID, LibraryIdAndNameVO> | Record<DocID, LibraryIdAndNameVO>
    ) => {
        this.cachedDocumentId2LibraryIdInfoMap$.next(
            new Map([...this.cachedDocumentId2LibraryIdInfoMap$.getValue().entries(), ...Object.entries(newMapValue)])
        )
    }

    // 处理当前文档打开引用开关的组件库列表变化
    private initCurrentDocLibrarySubscription = () => {
        const updateSubscribedLibraryInfoList = createSwitchTask(this.signal, async () => {
            new GetLibrarySubscription(this.docId)
                .startWithSignal(this.signal)
                .then((list) => {
                    this.subscribedLibraryInfoList$.next(list)
                })
                .catch(() => {})
        })
        this.notifyConnectRefresher$.onWithSignal(this.signal, updateSubscribedLibraryInfoList)
        this.currentDocAuthorizationChange$.onWithSignal(this.signal, updateSubscribedLibraryInfoList)
        this.orgDefaultLibrarySubscriptionChange$.onWithSignal(this.signal, updateSubscribedLibraryInfoList)

        this.currentDocLibrarySubscriptionChange$.onWithSignal(
            this.signal,
            (body: Wukong.NotifyProto.IBusinessEntityRelationChange) => {
                const libraryDocId = body.relation?.another?.ancestors?.find(
                    (ancestor) => ancestor.entityType === Wukong.NotifyProto.EntityType.DOC && !!ancestor.entityId
                )?.entityId

                if (!libraryDocId) {
                    return
                }

                const item: LibrarySubscriptionVO = {
                    docId: this.docId,
                    libraryId: body.relation?.another?.entityId!,
                    libraryDocId,
                }

                if (body.changeType == Wukong.NotifyProto.RelationChangeType.ADD) {
                    this.addItemsInTargetArray$(this.subscribedLibraryInfoList$, [item])
                } else if (body.changeType == Wukong.NotifyProto.RelationChangeType.REMOVE) {
                    this.removeItemsInTargetArray$(this.subscribedLibraryInfoList$, [item])
                }
            }
        )
        // 添加同步订阅的组件库的文档
        this.subscribedLibraryInfoList$.onWithSignal(this.signal, (subscribedLibraryInfoList) => {
            this.addSyncDocIds(subscribedLibraryInfoList.map((vo) => vo.libraryDocId))
        })

        // 取消同步不再订阅的组件库的文档
        let prev: LibrarySubscriptionVO[] = []
        this.subscribedLibraryInfoList$.onWithSignal(this.signal, (current) => {
            const prevSet = new Set(prev.map((vo) => vo.libraryDocId))
            const currentSet = new Set(current.map((vo) => vo.libraryDocId))
            const removeIds = difference([...prevSet], [...currentSet])
            if (removeIds.length) {
                this.removeSyncDocIds(removeIds)
            }
            prev = current
        })
    }

    // 添加一些项到指定的 BehaviorEventEmitter 对象中，并返回添加的项
    private addItemsInTargetArray$ = <T>(targetArray$: BehaviorEventEmitter<T[]>, addItems: T[]): T[] => {
        if (!addItems.length) {
            return []
        }

        const currentTargetArrayValue = [...targetArray$.getValue()]
        const changedItems = differenceWith(addItems, currentTargetArrayValue, isEqual)

        if (changedItems.length) {
            targetArray$.next([...currentTargetArrayValue, ...changedItems])
        }

        return changedItems
    }

    // 从指定的 BehaviorEventEmitter 对象中删除一些项，并返回被移除的项
    private removeItemsInTargetArray$ = <T>(targetArray$: BehaviorEventEmitter<T[]>, removeItems: T[]): T[] => {
        if (!removeItems.length) {
            return []
        }

        const currentTargetArrayValue = [...targetArray$.getValue()]
        const changedItems = intersectionWith(removeItems, currentTargetArrayValue, isEqual)
        if (changedItems.length) {
            targetArray$.next(currentTargetArrayValue.filter((item) => !changedItems.find((vo) => isEqual(vo, item))))
        }

        return changedItems
    }
    // 维护当前文档中使用组件/样式所在的母版文档 id
    public handleWasmNeedSyncDocumentIds = (addDocIds: DocID[], removeDocIds: DocID[]) => {
        this.addItemsInTargetArray$(this.wasmNeedSyncDocumentIds$, addDocIds)
        this.removeItemsInTargetArray$(this.wasmNeedSyncDocumentIds$, removeDocIds)
        this.removeSyncDocIds(removeDocIds)
        this.addSyncDocIds(addDocIds)
    }
}
