import { forwardRef, useCallback } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { ScrubbableInput, ScrubbableInputProps, ScrubbableInputRef } from './components/scrubbable-input'
import { clampMinMax } from './utils/clamp'
import { formateToFixed2 } from './utils/format'
import { Value } from './utils/type'
import { valueFilter10, valueFilterUserConfig } from './utils/value-filter'

export type ScrubbableInputNumberRef = ScrubbableInputRef
/**
 * @param props
 * @returns
 * @description 一般数值，保留两位
 */
export const ScrubbableInputNumber = forwardRef(function Component(
    props: ScrubbableInputProps,
    ref: React.Ref<ScrubbableInputNumberRef>
) {
    // 最小值是0.01的在拖拽的时候最小值是1
    const onValueChange = useCallback(
        (value: Value) => {
            if (Array.isArray(value)) {
                value = value.map((v) => Math.max(v, 1))
            } else {
                value = Math.max(value, 1)
            }
            props.onChange(value, { commitType: CommitType.Noop })
        },
        [props]
    )

    const clamp = useCallback(
        (value: Value, isIncrement?: boolean): Value => {
            // 最小值是0.01的在上下键修改时候最小值是1
            const min = isIncrement && props.min === 0.01 ? 1 : props.min
            const max = props.max
            if (Array.isArray(value)) {
                return value.map((v) => clampMinMax(v, { min, max }))
            } else {
                return clampMinMax(value, { min, max })
            }
        },
        [props.max, props.min]
    )

    return (
        <ScrubbableInput
            ref={ref}
            formatter={{ format: formateToFixed2, clamp }}
            valueFilter={!props.notUseUserConfig ? valueFilterUserConfig : valueFilter10}
            resolution={1}
            {...props}
            onValueChange={props.min === 0.01 ? onValueChange : undefined}
        />
    )
})
