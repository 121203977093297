import { translation } from './scrubbable-input.translation'
/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import {
    forwardRef,
    HTMLAttributes,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import { MonoIconPanelLink16, MonoIconPanelVariable16, Tag, TagRef, Tooltip } from '../../../../../../../ui-lib/src'
import { isKey, KeyboardCode } from '../../../../../kernel/keyboard/keyboard-event-handler'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { Formatter, ScrubInfo, Value } from '../utils/type'
import { useBaseFormatterTool } from '../utils/use-base-formatter-tool'
import { FormattedInput, FormattedInputProps, FormattedInputRef } from './formatted-input'
import { ScrubbableControl, ScrubbableControlCustomProps } from './scrubbable-control'
import classes from './scrubbable-input.module.less'

export interface ScrubbableInputProps
    extends Omit<
            FormattedInputProps<Value>,
            'mixedMathHandler' | 'value' | 'formatter' | 'onClick' | 'divertKeyboardEvent'
        >,
        Omit<ScrubbableControlCustomProps, 'mixedMathHandler' | 'value' | 'onValueChange'>,
        Pick<HTMLAttributes<HTMLLabelElement>, 'onClick'> {
    inputClassName?: string
    inputContentClassName?: string
    mixedMathHandler?: FormattedInputProps<Value>['mixedMathHandler'] & ScrubbableControlCustomProps['mixedMathHandler']
    icon?: JSX.Element | string
    formatter?: Partial<Formatter<Value>>
    value?: Value
    allowedUnits?: string
    testId?: string
    onValueChange?: ScrubbableControlCustomProps['onValueChange']
    leftScrubbable?: boolean
    rightScrubbable?: boolean
    min?: number
    max?: number
    inputStyle?: 'small-indent' // 在默认ui基础上，左侧缩进少4px
    useVariable?: {
        notAvailable: boolean // 是否有可用的变量
        // 如果绑定了变量。需要给绑定的信息
        variable: null | {
            // 超限使用上面的 min/max 判断
            name: string
            value: number
            remove: boolean // 变量被移除了
            onClick: (params: { rootRect: DOMRect }) => void // 点击变量tag(enter也触发)
        }
        open: boolean // 是否打开变量选择器
        onUnbind: () => void // 解绑变量(鼠标键盘)
        // 不传 iconBindUnbind 这个字段没有绑定解绑按钮
        iconBindUnbind?: {
            iconClassName?: string
            onBind: (params: { rootRect: DOMRect }) => void // 点击绑定变量的图标按钮
        }
    }
}

export interface ScrubbableInputRef {
    getRootElement: () => HTMLElement
    getInputElement: () => HTMLInputElement
}

function _ScrubbableInput(props: ScrubbableInputProps, ref: React.Ref<ScrubbableInputRef>) {
    return featureSwitchManager.isEnabled('float-variable') ? (
        <ScrubbableInput2 {...props} ref={ref} />
    ) : (
        <ScrubbableInput1 {...props} ref={ref} />
    )
}

export const ScrubbableInput = forwardRef(_ScrubbableInput)

function _ScrubbableInput1(props: ScrubbableInputProps, ref: React.Ref<ScrubbableInputRef>) {
    const baseFormatter = useBaseFormatterTool(props)
    const scrubbleControlRef = useRef<HTMLLabelElement>(null)
    const formattedInputRef = useRef<FormattedInputRef>(null)
    const scrubNeedToSelectAll = useRef<boolean>(false)

    const _onScrubStart = useCallback(
        (scrubInfo: ScrubInfo) => {
            props.onScrubBegin?.(scrubInfo)
            const input = formattedInputRef.current?.getInputElement()
            const isSelectAll = input === document.activeElement && window.getSelection()?.toString() === input?.value
            scrubNeedToSelectAll.current = isSelectAll
        },
        [props]
    )

    const _onScrubChange = useCallback(
        (value: Value, scrubInfo: ScrubInfo) => {
            props.onScrubChange?.(value, scrubInfo)
            if (scrubNeedToSelectAll.current) {
                formattedInputRef.current?.getInputElement()?.select()
            }
        },
        [props]
    )

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLLabelElement>) => {
            e.stopPropagation()
            props.onClick?.(e)
        },
        [props]
    )

    useImperativeHandle(ref, () => ({
        getRootElement: () => scrubbleControlRef.current!,
        getInputElement: () => formattedInputRef.current?.getInputElement()!,
    }))

    return (
        <ScrubbableControl
            ref={scrubbleControlRef}
            scrubbingDisabled={props.scrubbingDisabled}
            disabled={props.disabled}
            value={props.value ?? 0}
            isMixed={props.isMixed}
            resolution={props?.resolution}
            onValueChange={props.onValueChange ?? props.onChange}
            valueFilter={props?.valueFilter}
            mixedMathHandler={props?.mixedMathHandler}
            min={props?.min}
            max={props?.max}
            onScrubBegin={_onScrubStart}
            onScrubChange={_onScrubChange}
            onScrubEnd={props.onScrubEnd}
            className={props.className}
            leftScrubbable={props.leftScrubbable}
            rightScrubbable={props.rightScrubbable}
            notUseUserConfig={props.notUseUserConfig}
            scrubMultiplier={props.scrubMultiplier}
            shiftScrubMultiplier={props.shiftScrubMultiplier}
            onClick={_onClick}
            labelTestId={props.labelTestId}
        >
            {isNil(props.icon) ? null : <span className={classnames(classes.spanIcon)}>{props.icon}</span>}
            <FormattedInput
                ref={formattedInputRef}
                className={props.inputClassName}
                noRightPadding={props.noRightPadding}
                formatter={Object.assign(baseFormatter, props.formatter)}
                value={props.value ?? 0}
                isMixed={props.isMixed}
                onChange={props.onChange}
                mixedMathHandler={props.mixedMathHandler}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                defaultShowEmpty={props.defaultShowEmpty}
                focusWithoutBorder
                testId={props.testId}
                onKeyDown={props.onKeyDown}
                notUseUserConfig={props.notUseUserConfig}
                bigNudgeAmount={props.bigNudgeAmount}
                smallNudgeAmount={props.smallNudgeAmount}
            />
        </ScrubbableControl>
    )
}
const ScrubbableInput1 = forwardRef(_ScrubbableInput1)

function _ScrubbableInput2(props: ScrubbableInputProps, ref: React.Ref<ScrubbableInputRef>) {
    const baseFormatter = useBaseFormatterTool(props)
    const formattedInputRef = useRef<FormattedInputRef>(null)
    const tagRef = useRef<TagRef>(null)
    const scrubbleControlRef = useRef<HTMLLabelElement>(null)
    const scrubNeedToSelectAll = useRef<boolean>(false)
    const [editingRemoveVariable, setEditingRemoveVariable] = useState<boolean>(false)
    const [inputFocus, setInputFocus] = useState<boolean>(false)

    const _onScrubStart = useCallback(
        (scrubInfo: ScrubInfo) => {
            props.onScrubBegin?.(scrubInfo)
            const input = formattedInputRef.current?.getInputElement()
            const isSelectAll = input === document.activeElement && window.getSelection()?.toString() === input?.value
            scrubNeedToSelectAll.current = isSelectAll
        },
        [props]
    )

    const _onScrubChange = useCallback(
        (value: Value, scrubInfo: ScrubInfo) => {
            props.onScrubChange?.(value, scrubInfo)
            if (scrubNeedToSelectAll.current) {
                formattedInputRef.current?.getInputElement()?.select()
            }
        },
        [props]
    )

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLLabelElement>) => {
            e.stopPropagation()
            props.onClick?.(e)
        },
        [props]
    )

    const isOverLimit = useMemo(() => {
        if (props.useVariable) {
            const variableValue = props.useVariable.variable?.value
            if (variableValue === undefined) {
                return false
            }
            return variableValue < (props.min ?? variableValue) || variableValue > (props.max ?? variableValue)
        }
        return false
    }, [props.useVariable, props.min, props.max])

    const autoAdjustTagWidth = useCallback(() => {
        const htmlInput = formattedInputRef.current?.getInputElement()
        const htmlTag = tagRef.current?.getRootElement()
        if (!htmlInput || !htmlTag) {
            return
        }
        if (htmlInput === document.activeElement) {
            htmlTag.style.maxWidth = ''
            const inputWidth = htmlInput.getBoundingClientRect().width
            const tagWidth = htmlTag.getBoundingClientRect().width
            const sumWidth = inputWidth + tagWidth
            htmlTag.style.maxWidth = `${Math.max(sumWidth - htmlInput.scrollWidth, 0)}px`
        } else {
            htmlTag.style.maxWidth = ''
        }
    }, [])

    const onKeyDownInput = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (props.useVariable?.variable) {
                if (e.code === 'Enter') {
                    if ((e.target as HTMLInputElement).value === '') {
                        props.useVariable.variable.onClick({
                            rootRect: scrubbleControlRef.current?.getBoundingClientRect()!,
                        })
                    }
                } else if (
                    !editingRemoveVariable &&
                    isKey(e.nativeEvent, [KeyboardCode.BACKSPACE, KeyboardCode.DELETE])
                ) {
                    const input = e.target as HTMLInputElement
                    const isAtCaretStart = input.selectionStart === 0 && input.selectionEnd === 0
                    if (isAtCaretStart) {
                        const currentVariableValue = String(props.useVariable.variable.value)
                        const currentInputValue = String(input.value)
                        const nextInputValue = currentVariableValue + currentInputValue
                        formattedInputRef.current?.updateValue(nextInputValue, {
                            selection: { start: 0, end: currentVariableValue.length },
                        })
                        // 阻止这次的删除事件的删除行为，以防止把刚设置好的选区给删除了
                        e.preventDefault()
                        setEditingRemoveVariable(true)
                    }
                }
            }
            props.onKeyDown?.(e)
        },
        [editingRemoveVariable, props]
    )
    const onKeyDownTag = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.code === 'Enter') {
                props.useVariable?.variable?.onClick({
                    rootRect: scrubbleControlRef.current?.getBoundingClientRect()!,
                })
            } else if (isKey(e.nativeEvent, [KeyboardCode.BACKSPACE, KeyboardCode.DELETE])) {
                e.stopPropagation()
                props.useVariable?.onUnbind()
            }
        },
        [props]
    )
    const onClickTag = useCallback(() => {
        const input = formattedInputRef.current?.getInputElement()
        if (input === document.activeElement) {
            formattedInputRef.current?.resetEditingValue()
        }
        props.useVariable?.variable?.onClick({
            rootRect: scrubbleControlRef.current?.getBoundingClientRect()!,
        })
    }, [props])
    const onFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setInputFocus(true)
            props.onFocus?.(e)
        },
        [props]
    )
    const onBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setEditingRemoveVariable(false)
            setInputFocus(false)
            props.onBlur?.(e)
        },
        [props]
    )

    useEffect(() => {
        const htmlInput = formattedInputRef.current?.getInputElement()
        const observer = new MutationObserver(autoAdjustTagWidth)
        if (htmlInput) {
            observer.observe(htmlInput, {
                attributes: true,
                attributeFilter: ['value'],
            })
        }
        return () => observer.disconnect()
    }, [autoAdjustTagWidth])

    useImperativeHandle(ref, () => ({
        getRootElement: () => scrubbleControlRef.current!,
        getInputElement: () => formattedInputRef.current?.getInputElement()!,
    }))

    return (
        <ScrubbableControl
            ref={scrubbleControlRef}
            scrubbingDisabled={props.scrubbingDisabled}
            disabled={props.disabled}
            value={props.value ?? 0}
            isMixed={props.isMixed}
            resolution={props?.resolution}
            onValueChange={props.onValueChange ?? props.onChange}
            valueFilter={props?.valueFilter}
            mixedMathHandler={props?.mixedMathHandler}
            min={props?.min}
            max={props?.max}
            onScrubBegin={_onScrubStart}
            onScrubChange={_onScrubChange}
            onScrubEnd={props.onScrubEnd}
            className={classnames(
                classes.scrubbable,
                props.className,
                {
                    [classes.tipUseVariable]:
                        (props.useVariable &&
                            !props.useVariable.variable &&
                            !props.useVariable.notAvailable &&
                            inputFocus) ||
                        (props.useVariable?.open && !props.useVariable.variable),
                },
                props.inputStyle === 'small-indent' && classes.smallIndent
            )}
            contentClassName={props.inputContentClassName}
            leftScrubbable={props.leftScrubbable}
            rightScrubbable={props.rightScrubbable}
            notUseUserConfig={props.notUseUserConfig}
            scrubMultiplier={props.scrubMultiplier}
            shiftScrubMultiplier={props.shiftScrubMultiplier}
            onClick={_onClick}
            labelTestId={props.labelTestId}
            uiFocusWithin={props.useVariable?.open}
            focusWithoutBorder={props.focusWithoutBorder}
            tool={
                props.useVariable?.iconBindUnbind &&
                !(!props.useVariable.variable && props.useVariable.notAvailable) &&
                !props.disabled ? (
                    <Tooltip
                        title={
                            props.useVariable?.variable ? translation('UnbindVariable') : translation('ApplyVariable')
                        }
                    >
                        <div
                            data-scrubbable-control-forbid-scrubbing
                            className={classnames(
                                classes.iconBindUnbind,
                                props.useVariable?.iconBindUnbind.iconClassName
                            )}
                            onClick={
                                props.useVariable?.variable
                                    ? props.useVariable.onUnbind
                                    : () =>
                                          props.useVariable?.iconBindUnbind?.onBind({
                                              rootRect: scrubbleControlRef.current?.getBoundingClientRect()!,
                                          })
                            }
                        >
                            {props.useVariable?.variable ? (
                                <MonoIconPanelLink16 data-testid="unbind-variable-icon" />
                            ) : (
                                <MonoIconPanelVariable16 data-testid="bind-variable-icon" />
                            )}
                        </div>
                    </Tooltip>
                ) : undefined
            }
        >
            {isNil(props.icon) ? null : <span className={classes.spanIcon}>{props.icon}</span>}
            {props.useVariable?.variable && !editingRemoveVariable ? (
                <Tooltip
                    overlay={
                        <div data-testid="variable-tag-tooltip" className={classes.tagOverlay}>
                            <div>
                                {props.useVariable.variable.name}: {props.useVariable.variable.value}
                            </div>
                            {isOverLimit ? <div>{translation('VariableOverLimit')}</div> : null}
                        </div>
                    }
                    triggerRefKey="getRootElement"
                >
                    <Tag
                        ref={tagRef}
                        data-scrubbable-control-forbid-scrubbing
                        data-scrubbable-control-not-reset-background
                        dataTestIds={{ root: 'variable-tag' }}
                        disabled={props.disabled}
                        name={props.useVariable.variable.value}
                        structure="line-surface"
                        size="small"
                        className={classnames(classes.tag)}
                        onClick={onClickTag}
                        data-forbid-scrubbing
                        tabIndex={0}
                        onMouseDown={(e) => e.preventDefault()}
                        onKeyDown={onKeyDownTag}
                        hoverable
                        checked={false}
                        deleteLine={isOverLimit}
                        prevChild={
                            props.useVariable.variable.remove ? <div className={classes.missingVariable}>?</div> : null
                        }
                    />
                </Tooltip>
            ) : null}
            <FormattedInput
                ref={formattedInputRef}
                className={classnames(props.inputClassName, classes.input)}
                noRightPadding={props.noRightPadding}
                formatter={Object.assign(baseFormatter, props.formatter)}
                value={props.value ?? 0}
                isMixed={props.isMixed}
                onChange={props.onChange}
                mixedMathHandler={props.mixedMathHandler}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={props.disabled}
                placeholder={props.useVariable?.variable ? undefined : props.placeholder}
                autoFocus={props.autoFocus}
                defaultShowEmpty={props.useVariable?.variable ? true : props.defaultShowEmpty}
                focusWithoutBorder
                testId={props.testId}
                onKeyDown={onKeyDownInput}
                notUseUserConfig={props.notUseUserConfig}
                bigNudgeAmount={props.bigNudgeAmount}
                smallNudgeAmount={props.smallNudgeAmount}
                tabIndex={props.useVariable?.variable ? -1 : undefined}
                neverEqual={!!props.useVariable?.variable}
            />
        </ScrubbableControl>
    )
}
const ScrubbableInput2 = forwardRef(_ScrubbableInput2)
