import { getTranslationValue } from '../../../../../../util/src/i18n'

// [how_to_add_new_variable_scope] 新增 scope 时新增
export const zhTranslation = {
    AllForColor: '全部可用属性',
    AllFill: '填充',
    FrameFill: '容器',
    ShapeFill: '形状',
    TextFill: '文本',
    StrokeFill: '描边',
    EffectFill: '效果',
    AllForFloat: '全部可用属性',
    CornerRadius: '圆角',
    Size: '宽高',
    AutoLayout: '间距与边距（自动布局）',
    StrokeForFloat: '描边',
    EffectForFloat: '效果',
} as const

export const enTranslation = {
    AllForColor: 'All supported properties',
    AllFill: 'Fill',
    FrameFill: 'Frame',
    ShapeFill: 'Shape',
    TextFill: 'Text',
    StrokeFill: 'Stroke',
    EffectFill: 'Effects',
    AllForFloat: 'All supported properties',
    CornerRadius: 'Corner radius',
    Size: 'Width and height',
    AutoLayout: 'Gap and padding',
    StrokeForFloat: 'Stroke',
    EffectForFloat: 'Effects',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
