import classNames from 'classnames'
import { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ResizeObserver from 'resize-observer-polyfill'
import { usePopoverContext } from '../../lib-config-provider'
import { Scrollbar } from '../../scrollbars'
import { TooltipManager } from '../../tooltip/tooltip'
import styles from './index.module.less'
import {
    POPOVER_CONTAINER_CLASSNAME,
    POPOVER_MIN_SCROLL_HEIGHT,
    WKPopoverPlacement,
    WKPopoverPosition,
    WKPopoverProps,
    WKPopoverRef,
    calculatePopoverRect,
} from './util'

/**
 * Popover组件：https://motiff.com/file/X2IivEo4v6LnEKukTXiMz6E?nodeId=581%3A3261&type=dev
 */
const _WKPopover = (props: WKPopoverProps, ref: Ref<WKPopoverRef>) => {
    const {
        children,
        className,
        contents,
        showDelayTime = 100,
        hideDelayTime = 300,
        trigger = ['hover'],
        visible,
        placement = 'bottom-center',
        testId,
        arrowTestId,
        triggerTestId,
        contentClassName,
        triggerRect,
        worldRect,
        onOpen,
        ...restProps
    } = props

    const { popoverState, openPopover, closePopover } = usePopoverContext()
    const [popoverContainer, setPopoverContainer] = useState<HTMLDivElement>()
    const [popoverElement, setPopoverElement] = useState<HTMLDivElement>()

    const isHoverTrigger = useMemo(() => trigger.includes('hover'), [trigger])

    const timerRef = useRef<NodeJS.Timeout>()
    const contentRef = useRef<HTMLDivElement>(null)
    const triggerRef = useRef<HTMLDivElement>(null)

    const [popoverId, setPopoverId] = useState<string>('')
    const contentVisible = useMemo(() => {
        if (visible !== undefined) {
            return visible
        }
        return popoverState.includes(popoverId)
    }, [visible, popoverState, popoverId])
    const [innerPlacement, setInnerPlacement] = useState<WKPopoverPlacement>(placement)
    const [popoverPosition, setPopoverPosition] = useState<WKPopoverPosition>({ top: 0, left: 0 })
    const [popoverMaxHeight, setPopoverMaxHeight] = useState<number>(POPOVER_MIN_SCROLL_HEIGHT)

    const getTriggerRect = useCallback(() => {
        if (triggerRect) {
            return typeof triggerRect === 'function' ? triggerRect() : triggerRect
        } else if (triggerRef.current) {
            return triggerRef.current.getBoundingClientRect()
        }
    }, [triggerRect])

    const getWorldRect = useCallback(() => {
        if (worldRect) {
            return typeof worldRect === 'function' ? worldRect() : worldRect
        }
        return {
            left: 0,
            top: 0,
            right: window.innerWidth,
            bottom: window.innerHeight,
        }
    }, [worldRect])

    const getContentContainer = useCallback(() => {
        if (!popoverContainer) {
            const popover = document.querySelector(`.${POPOVER_CONTAINER_CLASSNAME}`) as HTMLDivElement
            if (popover) {
                setPopoverContainer(popover)
                return popover
            } else {
                const div = document.createElement('div')
                div.classList.add(POPOVER_CONTAINER_CLASSNAME)
                document.body.appendChild(div)
                setPopoverContainer(div)
                return div
            }
        }
        return popoverContainer
    }, [popoverContainer])

    const calculatePopoverPosition = useCallback(
        (popover: HTMLDivElement | null) => {
            if (!popover) {
                return
            }
            setPopoverElement(popover)
            const newTriggerRect = getTriggerRect()
            const contentRect = contentRef.current?.getBoundingClientRect?.()
            const newWorldRect = getWorldRect()
            if (newTriggerRect && contentRect) {
                const { newPlacement, newPopoverPosition, newMaxHeight } = calculatePopoverRect({
                    placement,
                    triggerRect: newTriggerRect,
                    contentRect,
                    worldRect: newWorldRect,
                })
                setInnerPlacement(newPlacement)
                setPopoverPosition(newPopoverPosition)
                setPopoverMaxHeight(newMaxHeight)
            }
        },
        [placement, getTriggerRect, getWorldRect]
    )

    const _openPopover = useCallback(() => {
        const id = openPopover()
        setPopoverId(id)
        if (onOpen) {
            onOpen()
        }
    }, [openPopover, onOpen])

    const _closePopover = useCallback(() => {
        closePopover(popoverId)
    }, [popoverId, closePopover])

    const handleShow = useCallback(() => {
        clearTimeout(timerRef.current)
        if (popoverState.length > 0) {
            _openPopover()
        } else {
            timerRef.current = setTimeout(() => {
                _openPopover()
            }, showDelayTime)
        }
    }, [showDelayTime, popoverState, _openPopover])

    const handleHide = useCallback(() => {
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            _closePopover()
        }, hideDelayTime)
    }, [hideDelayTime, _closePopover])

    const onMouseEnter = useCallback(() => {
        if (isHoverTrigger) {
            handleShow()
        }
    }, [isHoverTrigger, handleShow])

    const onMouseLeave = useCallback(() => {
        if (isHoverTrigger) {
            handleHide()
        }
    }, [isHoverTrigger, handleHide])

    useEffect(() => {
        if (!popoverElement || !contentRef.current) {
            return
        }
        const observer = new ResizeObserver((entries) => {
            if (entries.length === 0) {
                return
            }
            calculatePopoverPosition(popoverElement)
        })
        observer.observe(contentRef.current)
        return () => {
            observer.disconnect()
        }
    }, [popoverElement, calculatePopoverPosition])

    useImperativeHandle(ref, () => ({
        show: _openPopover,
        hide: _closePopover,
    }))

    const renderPopoverContent = () => {
        const cont = typeof contents === 'function' ? contents() : contents || null
        const contentWrap = (
            <div
                className={classNames(styles.popover, styles[innerPlacement])}
                ref={calculatePopoverPosition}
                style={{ ...popoverPosition }}
                onMouseEnter={() => clearTimeout(timerRef.current)}
                onMouseLeave={() => isHoverTrigger && handleHide()}
                data-testid={testId}
            >
                <span data-testid={arrowTestId} className={classNames(styles.arrow, styles[innerPlacement])}></span>
                <Scrollbar autoHeight autoHeightMax={popoverMaxHeight}>
                    <div className={styles['content-container']}>
                        <div ref={contentRef} className={classNames(styles.content, contentClassName)}>
                            {cont}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        )
        // 产品期望，popover打开的时候，关闭tooltip
        return contentVisible ? (TooltipManager.close(), createPortal(contentWrap, getContentContainer())) : null
    }

    return (
        <>
            <div
                ref={triggerRef}
                className={classNames('lh-0', className)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid={triggerTestId}
                {...restProps}
            >
                {children}
            </div>
            {renderPopoverContent()}
        </>
    )
}

export const WKPopover = forwardRef(_WKPopover)
