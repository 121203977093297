import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Hint: '勾选「全部可用属性」将支持在全部颜色属性内展示该变量，也包括未来发布的颜色属性。',
    HintV2: '勾选「全部可用属性」将支持在全部可用属性内展示该变量，也包括未来发布的属性。',
    ColorScopeLabel: '颜色适用范围',
    NumberScopeLabel: '数值适用范围',
} as const

export const enTranslation = {
    Hint: 'Checking "All supported properties" will enable this variable to be displayed in all color properties, including those released in the future.',
    HintV2: 'Checking "All supported properties" will allow the variable to be displayed across all supported properties, including those released in the future.',
    ColorScopeLabel: 'Color scope',
    NumberScopeLabel: 'Number scope',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
