import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { FC, useMemo } from 'react'
import { Checkbox, Select } from '../../../../../../../ui-lib/src'
import { useViewState } from '../../../../../view-state-bridge'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import {
    AndroidUnit2lable,
    AndroidUnitOptions,
    CssUnit2lable,
    CssUnitOptions,
    DevModeCodeConfigRatioLabels,
    DevModeCodeConfigRatioOptions,
    DevModeCodeTypeLabelsV2,
    DevModeCodeTypeOptionsV2,
    IOSUnit2lable,
    IOSUnitOptions,
    useChangeDevModeInspectCodeType,
} from '../common/code-data'
import { translation } from './index.translation'

export const CodeConfigComp: FC = () => {
    const devModeInspectCodeTypeViewState = useViewState('devModeInspectCodeTypeViewState')
    const codeType =
        devModeInspectCodeTypeViewState?.codeType ||
        Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS
    const { codeConfig } = useViewState('devModeCodeConfig')!
    const { changeCodeConfig } = useChangeDevModeInspectCodeType()

    const unit2lables = useMemo(() => {
        switch (codeType) {
            case Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS:
                return CssUnit2lable
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit:
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI:
                return IOSUnit2lable
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_XML:
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose:
                return AndroidUnit2lable
        }
    }, [codeType])

    const unitOptions = useMemo(() => {
        switch (codeType) {
            case Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS:
                return CssUnitOptions
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI:
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit:
                return IOSUnitOptions
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose:
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_XML:
                return AndroidUnitOptions
        }
    }, [codeType])
    const isCssRem =
        codeType === Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS &&
        codeConfig.css.unit === Wukong.DocumentProto.CssUnit.CSS_UNITREM

    const _ratioValue = useMemo(() => {
        switch (codeType) {
            case Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS:
                return codeConfig.css.ratio
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI: {
                switch (codeConfig.swift.unit) {
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT:
                        return codeConfig.swift.ptRatio
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX:
                        return codeConfig.swift.pxRatio
                }
                break
            }
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit:
                switch (codeConfig.ios.unit) {
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT:
                        return codeConfig.ios.ptRatio
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX:
                        return codeConfig.ios.pxRatio
                }
                return
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_XML: {
                switch (codeConfig.android.unit) {
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP:
                        return codeConfig.android.dpRatio
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX:
                        return codeConfig.android.pxRatio
                }
                break
            }
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose:
                switch (codeConfig.compose.unit) {
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP:
                        return codeConfig.compose.dpRatio
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX:
                        return codeConfig.compose.pxRatio
                }
        }
    }, [codeConfig, codeType])
    const ratioValue = _ratioValue ?? Wukong.DocumentProto.CodeRatio.CODE_RATIO_ONE
    const ratiokey = useMemo(() => {
        switch (codeType) {
            case Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS:
                return 'ratio'
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI: {
                switch (codeConfig.swift.unit) {
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT:
                        return 'ptRatio'
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX:
                        return 'pxRatio'
                }
                break
            }
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit:
                switch (codeConfig.ios.unit) {
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPT:
                        return 'ptRatio'
                    case Wukong.DocumentProto.IOSUnit.I_O_S_UNITPX:
                        return 'pxRatio'
                }
                return
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose: {
                switch (codeConfig.compose.unit) {
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP:
                        return 'dpRatio'
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX:
                        return 'pxRatio'
                }
                break
            }
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_XML:
                switch (codeConfig.android.unit) {
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITDP:
                        return 'dpRatio'
                    case Wukong.DocumentProto.AndroidUnit.ANDROID_UNITPX:
                        return 'pxRatio'
                }
        }
    }, [codeType, codeConfig])

    const unitValue = useMemo(() => {
        switch (codeType) {
            case Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS:
                return codeConfig.css.unit
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI:
                return codeConfig.swift.unit
            case Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit:
                return codeConfig.ios.unit
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose:
                return codeConfig.compose.unit
            case Wukong.DocumentProto.DevModeInspectCodeType.Android_XML:
                return codeConfig.android.unit
        }
    }, [codeConfig, codeType])

    return (
        <>
            <div className="px-4 py-1 flex justify-between items-center">
                <span>{translation('FGmEgR')}</span>
                <div className="w-120px">
                    <Select.NormalSingleLevel
                        onChange={(unit) => {
                            changeCodeConfig(codeType, { unit })
                        }}
                        value={unitValue}
                        label={unit2lables[unitValue!]}
                    >
                        {unitOptions.map((option) => {
                            return (
                                <Select.NormalSingleLevel.Option key={option} value={option} backwardIcon={''}>
                                    {unit2lables[option]}
                                </Select.NormalSingleLevel.Option>
                            )
                        })}
                    </Select.NormalSingleLevel>
                </div>
            </div>
            {/* 倍率切换，只有 css 单位 rem 的时候不展示 */}
            {!isCssRem && (
                <div className="px-4 py-1 flex justify-between items-center">
                    <span>{translation('fWUuqn')}</span>
                    <div className="w-120px">
                        <Select.NormalSingleLevel
                            value={ratioValue}
                            onChange={(ratio) => {
                                changeCodeConfig(codeType, { [ratiokey!]: ratio })
                            }}
                            label={DevModeCodeConfigRatioLabels[ratioValue]}
                            dataTestIds={{ triggerFocus: 'dev-mode-unit' }}
                        >
                            {DevModeCodeConfigRatioOptions.map((option) => {
                                return (
                                    <Select.NormalSingleLevel.Option key={option} value={option} backwardIcon={''}>
                                        {DevModeCodeConfigRatioLabels[option]}
                                    </Select.NormalSingleLevel.Option>
                                )
                            })}
                        </Select.NormalSingleLevel>
                    </div>
                </div>
            )}
            {/* css rem 展示跟元素字号设置 */}
            {isCssRem && (
                <>
                    <div className="px-4 py-1 flex justify-between items-center">
                        <span>{translation('NtLxAi')}</span>
                        <div className="w-120px">
                            <ScrubbableInputNumber
                                value={codeConfig.css.rem}
                                scrubbingDisabled
                                testId="rem-input"
                                className="!h-7"
                                onChange={(rem) => {
                                    typeof rem == 'number' && changeCodeConfig(codeType, { rem })
                                }}
                                min={0.1}
                                max={999999}
                            />
                        </div>
                    </div>
                    <div className="px-4 py-2">
                        <Checkbox
                            testid="rem-enable-for-text"
                            onChange={(remEnableForText) => {
                                changeCodeConfig(codeType, { remEnableForText })
                            }}
                            checked={codeConfig.css.remEnableForText}
                            label={`${translation('nquVve')}`}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export function DevModeInspectCodeConfig() {
    const devModeInspectCodeTypeViewState = useViewState('devModeInspectCodeTypeViewState')
    const codeType =
        devModeInspectCodeTypeViewState?.codeType ||
        Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS
    const { changeType } = useChangeDevModeInspectCodeType()

    return (
        <div
            className="b-b border-color-$wk-v2-stroke-color-gray-3 py-2 wk-text-12"
            data-testid="dev-mode-inspect-code-config"
        >
            <div className="px-4 py-1.5 wk-font-semibold">{translation('QjKJps')}</div>
            <div className="px-4 py-1 flex justify-between items-center">
                <span>{translation('pcBcgJ')}</span>
                <div className="w-120px">
                    <Select.NormalMultiLevel
                        label={DevModeCodeTypeLabelsV2[codeType]}
                        dataTestIds={{
                            triggerFocus: 'code-type-select',
                        }}
                        onChange={(v) => {
                            const type = v.customNode?.value
                            !isNil(type) && changeType(type)
                        }}
                        items={DevModeCodeTypeOptionsV2}
                    />
                </div>
            </div>
            <CodeConfigComp />
        </div>
    )
}
