import { Wukong } from '@wukong/bridge-proto'

export enum DownloadPriority {
    SET_FONT = 0,
    PREVIEW = 1,
    DEFAULT = 2,
}

export interface DownloadTask {
    fontPath: string
    font: Wukong.DocumentProto.IFontName
    args: Wukong.DocumentProto.IArg_downloadFont
    priority: DownloadPriority
}

type TaskCallback = (task: DownloadTask, data?: Uint8Array) => void

// 字体下载调度器，需求文档：
// https://beta.motiff.com/file/kiOc2Ckgys2iE67qCtaiB3w?nodeId=0%3A1&type=design
// 核心逻辑：
// 1. 本地字体不受并发限制
// 2. 远程字体并发限制为 6
// 3. 等待队列有三种， 用户设置的（FIFO）> 预览的（LIFO）> 默认的（FIFO）
// 4. 比三个队列优先级更高的是最后一次预览
// 5. 预览队列只保留最近的三个
export class FontDownloadScheduler {
    // 队列管理，所有未开始的 task
    private currPreviewTask: DownloadTask | null = null
    private queues = {
        [DownloadPriority.SET_FONT]: [] as DownloadTask[],
        [DownloadPriority.PREVIEW]: [] as DownloadTask[],
        [DownloadPriority.DEFAULT]: [] as DownloadTask[],
    }

    // 所有正在进行的 remote task
    private activeRemoteTasks = new Set<string>()
    // 所有未完成任务的注册表
    private taskRegistry = new Map<
        string,
        {
            mainTask: DownloadTask
            pendingTasks: DownloadTask[]
            status: 'queued' | 'downloading'
        }
    >()

    private fontCache = new Map<string, Uint8Array>()
    private MAX_CONCURRENT = 6
    private callbacks = {
        onStart: [] as TaskCallback[],
        onSuccess: [] as TaskCallback[],
        onError: [] as TaskCallback[],
    }

    constructor(
        private readonly loadFontFile: (task: DownloadTask) => Promise<{ data: Uint8Array; url: string }>,
        callbacks?: {
            onStart?: (task: DownloadTask) => void
            onSuccess?: (task: DownloadTask, data: Uint8Array) => void
            onError?: (task: DownloadTask) => void
        }
    ) {
        if (callbacks?.onStart) {
            this.on('start', callbacks.onStart)
        }
        if (callbacks?.onSuccess) {
            this.on('success', callbacks.onSuccess as TaskCallback)
        }
        if (callbacks?.onError) {
            this.on('error', callbacks.onError)
        }
    }

    addTask(task: DownloadTask) {
        // 缓存命中检查
        const isLocal =
            task.fontPath.startsWith('http://127.0.0.1') || task.fontPath.startsWith('https://daemon.motiff.com')
        const cachedData = this.fontCache.get(task.fontPath)
        if (cachedData) {
            this.triggerEvent('success', task, cachedData)
            return
        }

        // 任务合并逻辑
        const record = this.taskRegistry.get(task.fontPath)
        if (record) {
            record.pendingTasks.push(task)

            if (record.status === 'queued') {
                if (task.priority < record.mainTask.priority || task.priority === DownloadPriority.PREVIEW) {
                    // 移除旧任务，添加新任务，任务都在 pendingTasks 中，回调不会丢失
                    this.removeFromQueue(record.mainTask)
                    record.mainTask = task
                    this.addTaskToProperQueue(task)
                }
            }
            return
        }

        // 新任务注册
        this.taskRegistry.set(task.fontPath, {
            mainTask: task,
            pendingTasks: [task],
            status: 'queued',
        })

        // 本地任务直接处理
        if (isLocal) {
            this.startTaskProcessing(task, true)
        } else {
            this.addTaskToProperQueue(task)
            this.processQueue()
        }
    }

    on(event: 'start' | 'success' | 'error', callback: TaskCallback) {
        const eventMap = {
            start: 'onStart',
            success: 'onSuccess',
            error: 'onError',
        } as const
        this.callbacks[eventMap[event]].push(callback)
    }

    getFontData(fontPath: string) {
        return this.fontCache.get(fontPath)
    }

    insertFontData(fontPath: string, data: Uint8Array) {
        this.fontCache.set(fontPath, data)
    }

    reset() {
        this.clearPendingTasks()
        this.fontCache.clear()
    }

    clearPendingTasks() {
        this.queues = {
            [DownloadPriority.SET_FONT]: [] as DownloadTask[],
            [DownloadPriority.PREVIEW]: [] as DownloadTask[],
            [DownloadPriority.DEFAULT]: [] as DownloadTask[],
        }
        this.activeRemoteTasks.clear()
        this.taskRegistry.clear()
    }

    private async processQueue() {
        while (this.activeRemoteTasks.size < this.MAX_CONCURRENT) {
            const task = this.getNextRemoteTask()
            if (!task) {
                break
            }

            this.startTaskProcessing(task)
        }
    }

    private startTaskProcessing(task: DownloadTask, isLocal = false) {
        const record = this.taskRegistry.get(task.fontPath)!
        record.status = 'downloading'
        if (!isLocal) {
            this.activeRemoteTasks.add(task.fontPath)
        }
        this.triggerEvent('start', task)

        this.processTask(task).finally(() => {
            if (!isLocal) {
                this.activeRemoteTasks.delete(task.fontPath)
            }
            this.taskRegistry.delete(task.fontPath)
            this.processQueue()
        })
    }

    private async processTask(task: DownloadTask) {
        try {
            const promise = this.loadFontFile(task).then((result) => result.data)
            const data = await promise

            this.fontCache.set(task.fontPath, data)
            this.triggerSuccessForAllTasks(task.fontPath, data)
        } catch (error) {
            this.triggerErrorForAllTasks(task.fontPath)
        }
    }

    private getNextRemoteTask(): DownloadTask | undefined {
        if (this.currPreviewTask) {
            const task = this.currPreviewTask
            this.currPreviewTask = null
            return task
        }

        // 合并遍历和查找逻辑
        for (const priority of [DownloadPriority.SET_FONT, DownloadPriority.PREVIEW, DownloadPriority.DEFAULT]) {
            const queue = this.queues[priority]
            if (queue.length > 0) {
                return queue.shift()
            }
        }
    }

    private removeFromQueue(task: DownloadTask) {
        if (this.currPreviewTask?.fontPath === task.fontPath) {
            this.currPreviewTask = null
            return
        }

        const queue = this.queues[task.priority]
        const index = queue.findIndex((t) => t.fontPath === task.fontPath)
        if (index !== -1) {
            queue.splice(index, 1)
        }
    }

    private addTaskToProperQueue(task: DownloadTask) {
        if (task.priority === DownloadPriority.PREVIEW || task.priority === DownloadPriority.SET_FONT) {
            if (this.currPreviewTask) {
                // preview queue 是先进后出
                const queue = this.queues[DownloadPriority.PREVIEW]
                while (queue.length >= 3) {
                    queue.pop()
                }
                queue.unshift(this.currPreviewTask)

                this.currPreviewTask = null
            }
        }

        if (task.priority === DownloadPriority.PREVIEW) {
            this.currPreviewTask = task
        } else {
            this.queues[task.priority].push(task)
        }
    }

    private triggerSuccessForAllTasks(fontPath: string, data: Uint8Array) {
        const record = this.taskRegistry.get(fontPath)
        if (!record) {
            return
        }

        record.pendingTasks.forEach((task) => {
            this.triggerEvent('success', task, data)
        })
    }

    private triggerErrorForAllTasks(fontPath: string) {
        const record = this.taskRegistry.get(fontPath)
        if (!record) {
            return
        }

        record.pendingTasks.forEach((task) => {
            this.triggerEvent('error', task)
        })
    }

    private triggerEvent(type: 'start' | 'success' | 'error', task: DownloadTask, data?: Uint8Array) {
        const eventMap = {
            start: 'onStart',
            success: 'onSuccess',
            error: 'onError',
        } as const

        const callbacks = this.callbacks[eventMap[type]]
        callbacks.forEach((cb) => {
            if (type === 'success') {
                cb(task, data!)
            } else {
                cb(task)
            }
        })
    }
}
