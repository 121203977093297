import { translation } from './row-col-layout-grid-content.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo, useRef, useState } from 'react'
import { MonoIconPanelLink16, MonoIconPanelVariable16, Position, Select } from '../../../../../../../ui-lib/src'
import { RGB, SolidPaint } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { ScrubbableInputInteger } from '../../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputLayoutGrid } from '../../../atom/inputs/scrubbable-input-layout-grid'
import { ScrubbableInputPercent } from '../../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../../atom/inputs/utils/type'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../../../design/color-interaction/color-interaction'
import { ColorInteractionFrom } from '../../../design/color-interaction/type'
import { useFloatVariablePanel } from '../../../design/primitive-variable/use-float-variable-panel'
import { PaintIconColor } from '../../../paint-icon-color/paint-icon-color'
import style from './row-col-layout-grid-content.module.less'

export interface RowsLayoutContentProps {
    layoutGrid: Wukong.DocumentProto.ILayoutGrid
    variables: Wukong.DocumentProto.ISingleFloatVariable[]
    colorSpace: ColorSpace
    onChangeCount: (count: Value, options?: InputOptionsForUndoSquash) => void
    onChangeCountVar: (value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeAlign: (align: Wukong.DocumentProto.RowsColsLayoutGridAlign) => void
    onChangeSectionSize: (sectionSize: Value, options?: InputOptionsForUndoSquash) => void
    onChangeSectionSizeVar: (value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeGutterSize: (gutterSize: Value, options?: InputOptionsForUndoSquash) => void
    onChangeGutterSizeVar: (value: string | null, options?: InputOptionsForUndoSquash) => void
    onChangeOffset: (offset: Value, options?: InputOptionsForUndoSquash) => void
    onChangeOffsetVar: (value: string | null, options?: InputOptionsForUndoSquash) => void
    isMixed?: boolean
    onChangeColor: (value: RGB, options?: InputOptionsForUndoSquash) => void
    onChangeOpacityValue: (value: Value, options?: InputOptionsForUndoSquash) => void
    onChangeOpacityNumber: (value: Value, options?: InputOptionsForUndoSquash) => void
}

const countToShow = new Map<string, string>([
    [translation('Auto'), translation('Auto')],
    ['1', '1'],
    ['2', '2'],
    ['3', '3'],
    ['4', '4'],
    ['5', '5'],
    ['6', '6'],
    ['7', '7'],
    ['8', '8'],
    ['9', '9'],
    ['10', '10'],
    ['11', '11'],
    ['12', '12'],
])

const rowsAlignToShow = new Map<Wukong.DocumentProto.RowsColsLayoutGridAlign, string>([
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MIN, translation('Top')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MAX, translation('Bottom')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER, translation('Center')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH, translation('Stretch')],
])

const colsAlignToShow = new Map<Wukong.DocumentProto.RowsColsLayoutGridAlign, string>([
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MIN, translation('Left')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MAX, translation('Right')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER, translation('Center')],
    [Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH, translation('Stretch')],
])
export function RowColLayoutContent(prop: RowsLayoutContentProps) {
    if (featureSwitchManager.isEnabled('float-variable')) {
        return <RowColLayoutContentV2 {...prop} />
    } else {
        return <RowColLayoutContentV1 {...prop} />
    }
}

function RowColLayoutContentV1(prop: RowsLayoutContentProps) {
    const {
        layoutGrid,
        onChangeCount,
        onChangeAlign,
        isMixed,
        colorSpace,
        onChangeSectionSize,
        onChangeGutterSize,
        onChangeOffset,
        onChangeColor,
        onChangeOpacityNumber,
        onChangeOpacityValue,
    } = prop

    const paint = useMemo(() => {
        return layoutGrid.paints as SolidPaint
    }, [layoutGrid.paints])
    const align = useMemo(() => {
        return layoutGrid.align
    }, [layoutGrid.align])

    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<Position>()
    const ref = useRef<HTMLDivElement>(null)

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    const handleCountUpdate = (value: any, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'string') {
            if (value === translation('Auto')) {
                onChangeCount(-1)
            } else {
                onChangeCount(Number(value))
            }
        } else {
            onChangeCount(value, options)
        }
    }

    const onClickOpenModal = () => {
        setIsOpen(!isOpen)
        setPosition(ref.current?.getBoundingClientRect())
    }

    const count = useMemo(() => {
        return layoutGrid.count === -1 ? translation('Auto') : String(layoutGrid.count)
    }, [layoutGrid.count])

    return (
        <>
            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Count')}
                </SingleGrid.Item>
                <SingleGrid.Item start={22} span={35} className={style.font}>
                    {translation('Color')}
                </SingleGrid.Item>

                <SingleGrid.Item start={1} span={60}>
                    <SingleGrid ref={ref}>
                        <SingleGrid.Item start={5} span={15}>
                            <Select.ArrowSingleLevel
                                isSmallArrow
                                value={count}
                                onChange={(v) => handleCountUpdate(v)}
                                label={
                                    <ScrubbableInputLayoutGrid
                                        value={layoutGrid.count!}
                                        onChange={handleCountUpdate}
                                        disabled={false}
                                        showWhendisabled=""
                                        showAutoWhenValueEqual={-1}
                                        min={0}
                                        max={999999}
                                        noRightPadding={true}
                                        autoFocus={
                                            align ==
                                            Wukong.DocumentProto.RowsColsLayoutGridAlign
                                                .ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                                        }
                                        key={layoutGrid.pattern}
                                        testId="rowcol-content-count"
                                    />
                                }
                                minWidth={88}
                            >
                                {!countToShow.get(String(count)) ? (
                                    <Select.ArrowSingleLevel.Option value={count} key={count} backwardIcon={''}>
                                        {count}
                                    </Select.ArrowSingleLevel.Option>
                                ) : null}
                                {[...countToShow.keys()].map((key, index) => {
                                    return (
                                        <Select.ArrowSingleLevel.Option value={key} key={index} backwardIcon={''}>
                                            {countToShow.get(key)}
                                        </Select.ArrowSingleLevel.Option>
                                    )
                                })}
                            </Select.ArrowSingleLevel>
                        </SingleGrid.Item>

                        <SingleGrid.Item start={22} span={23} className={style.backgroundGrid}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                                isWidth={92}
                                disabled={false}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={45} span={12}>
                            <ScrubbableInputPercent
                                disabled={false}
                                value={paint.opacity}
                                onChange={onChangeOpacityNumber}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible! ? '' : style.hidden}`}
                                testId={'rowcol-content-opacity'}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>

            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Type')}
                </SingleGrid.Item>
                <SingleGrid.Item start={5} span={34}>
                    <Select.NormalSingleLevel
                        isMixed={isMixed}
                        value={align}
                        minWidth={140}
                        label={
                            layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                                ? rowsAlignToShow.get(align!)
                                : colsAlignToShow.get(align!)
                        }
                        onChange={onChangeAlign}
                    >
                        {layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                            ? [...rowsAlignToShow.entries()].map(([key, value]) => (
                                  <Select.NormalSingleLevel.Option value={key} key={key}>
                                      {value}
                                  </Select.NormalSingleLevel.Option>
                              ))
                            : [...colsAlignToShow.entries()].map(([key, value]) => (
                                  <Select.NormalSingleLevel.Option value={key} key={key}>
                                      {value}
                                  </Select.NormalSingleLevel.Option>
                              ))}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
            </SingleGrid>

            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={16} className={style.font}>
                    {layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                        ? translation('Height')
                        : translation('Width')}
                </SingleGrid.Item>
                <SingleGrid.Item start={23} span={16} className={style.font}>
                    {align == Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        ? translation('Margin')
                        : translation('Offset')}
                </SingleGrid.Item>
                <SingleGrid.Item start={41} span={16} className={style.font}>
                    {translation('Gutter')}
                </SingleGrid.Item>

                <SingleGrid.Item start={5} span={16}>
                    <ScrubbableInputLayoutGrid
                        onChange={onChangeSectionSize}
                        min={0}
                        max={999999}
                        className={style.input}
                        disabled={
                            align === Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        }
                        value={layoutGrid.sectionSize!}
                        autoFocus={
                            align != Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        }
                        showWhendisabled={translation('Auto')}
                        testId="rowcol-content-sectionSize"
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={23} span={16}>
                    <ScrubbableInputLayoutGrid
                        onChange={onChangeOffset}
                        min={0}
                        max={999999}
                        className={style.input}
                        disabled={
                            align === Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER
                        }
                        value={layoutGrid.offset!}
                        showWhendisabled="0"
                        testId="rowcol-content-offset"
                    />
                </SingleGrid.Item>

                <SingleGrid.Item start={41} span={16}>
                    <ScrubbableInputInteger
                        onChange={onChangeGutterSize}
                        value={layoutGrid.gutterSize!}
                        min={0}
                        max={999999}
                        className={style.input}
                        testId="rowcol-content-guttersize"
                    />
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClose(), false)}>
                    <ColorInteraction
                        from={ColorInteractionFrom.GRID}
                        position={position}
                        paint={paint}
                        onCancel={onClose}
                        onChangeColor={onChangeColor}
                        onChangeOpacity={onChangeOpacityValue}
                        enterClose={onClose}
                        styleId={undefined}
                        styleKey={undefined}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        onChangeStyle={() => {}}
                        onChangeColorVar={() => {}}
                        onChangePaints={() => {}}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}
        </>
    )
}

function RowColLayoutContentV2(prop: RowsLayoutContentProps) {
    const {
        layoutGrid,
        variables,
        onChangeCount,
        onChangeCountVar,
        onChangeAlign,
        isMixed,
        colorSpace,
        onChangeSectionSize,
        onChangeSectionSizeVar,
        onChangeGutterSize,
        onChangeGutterSizeVar,
        onChangeOffset,
        onChangeOffsetVar,
        onChangeColor,
        onChangeOpacityNumber,
        onChangeOpacityValue,
    } = prop

    const paint = useMemo(() => {
        return layoutGrid.paints as SolidPaint
    }, [layoutGrid.paints])
    const align = useMemo(() => {
        return layoutGrid.align
    }, [layoutGrid.align])

    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState<Position>()
    const ref = useRef<HTMLDivElement>(null)

    const onClose = useCallback(() => {
        setIsOpen(false)
    }, [])

    const onClickOpenModal = () => {
        setIsOpen(!isOpen)
        setPosition(ref.current?.getBoundingClientRect())
    }

    const count = useMemo(() => {
        return layoutGrid.count === -1 ? translation('Auto') : String(layoutGrid.count)
    }, [layoutGrid.count])

    const countVariablePicker = useFloatVariablePanel({
        requiredScopes: [],
        selectedVariable: variables.find((v) => v.id === layoutGrid.countVar?.value.alias) ?? null,
        selectedVariableFallbackFloatValue: layoutGrid.count,
        createEnable: true,
        hideIconBindUnbind: true,
        defaultCreateValue: layoutGrid.count ?? 0,
        onVariableSelected: (id: string) => {
            onChangeCountVar(id)
        },
        onVariableDetach: () => {
            onChangeCountVar(null)
        },
    })

    const countSelectRef = useRef<HTMLDivElement>(null)
    const handleCountUpdate = (value: any, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'string') {
            if (value === 'apply-variable') {
                if (countSelectRef.current) {
                    const rect = countSelectRef.current.getBoundingClientRect()
                    countVariablePicker.openPicker({
                        top: rect.bottom + 8,
                        left: rect.left,
                    })
                }
            } else if (value === 'detach-varible') {
                onChangeCountVar(null)
            } else if (value === translation('Auto')) {
                onChangeCount(-1)
            } else {
                onChangeCount(Number(value))
            }
        } else {
            onChangeCount(value, options)
        }
    }

    const gutterSizeVariablePicker = useFloatVariablePanel({
        requiredScopes: [],
        selectedVariable: variables.find((v) => v.id === layoutGrid.gutterSizeVar?.value.alias) ?? null,
        selectedVariableFallbackFloatValue: layoutGrid.gutterSize,
        createEnable: true,
        defaultCreateValue: layoutGrid.gutterSize ?? 0,
        onVariableSelected: (id: string) => {
            onChangeGutterSizeVar(id)
        },
        onVariableDetach: () => {
            onChangeGutterSizeVar(null)
        },
    })

    const offsetVariablePicker = useFloatVariablePanel({
        requiredScopes: [],
        selectedVariable: variables.find((v) => v.id === layoutGrid.offsetVar?.value.alias) ?? null,
        selectedVariableFallbackFloatValue: layoutGrid.offset,
        createEnable: true,
        defaultCreateValue: layoutGrid.offset ?? 0,
        onVariableSelected: (id: string) => {
            onChangeOffsetVar(id)
        },
        onVariableDetach: () => {
            onChangeOffsetVar(null)
        },
    })

    const sectionSizeVariablePicker = useFloatVariablePanel({
        requiredScopes: [],
        selectedVariable: variables.find((v) => v.id === layoutGrid.sectionSizeVar?.value.alias) ?? null,
        selectedVariableFallbackFloatValue: layoutGrid.sectionSize,
        createEnable: true,
        defaultCreateValue: layoutGrid.sectionSize ?? 0,
        onVariableSelected: (id: string) => {
            onChangeSectionSizeVar(id)
        },
        onVariableDetach: () => {
            onChangeSectionSizeVar(null)
        },
    })

    return (
        <>
            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Count')}
                </SingleGrid.Item>
                <SingleGrid.Item start={22} span={35} className={style.font}>
                    {translation('Color')}
                </SingleGrid.Item>

                <SingleGrid.Item start={1} span={60}>
                    <SingleGrid ref={ref}>
                        <SingleGrid.Item start={5} span={15} ref={countSelectRef}>
                            <Select.ArrowSingleLevel
                                isSmallArrow
                                value={count}
                                onChange={(v) => handleCountUpdate(v)}
                                label={
                                    <ScrubbableInputLayoutGrid
                                        value={layoutGrid.count!}
                                        onChange={handleCountUpdate}
                                        disabled={false}
                                        showWhendisabled=""
                                        showAutoWhenValueEqual={-1}
                                        min={0}
                                        max={999999}
                                        noRightPadding={true}
                                        autoFocus={
                                            align ==
                                            Wukong.DocumentProto.RowsColsLayoutGridAlign
                                                .ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                                        }
                                        key={layoutGrid.pattern}
                                        testId="rowcol-content-count"
                                        useVariable={countVariablePicker.useVariable}
                                    />
                                }
                                dataTestIds={{
                                    triggerFocus: 'rowcol-content-count-trigger-focus',
                                }}
                                minWidth={88}
                            >
                                {!countToShow.get(String(count)) ? (
                                    <Select.ArrowSingleLevel.Option value={count} key={count} backwardIcon={''}>
                                        {count}
                                    </Select.ArrowSingleLevel.Option>
                                ) : null}
                                {[...countToShow.keys()].map((key, index) => {
                                    return (
                                        <Select.ArrowSingleLevel.Option value={key} key={index} backwardIcon={''}>
                                            {countToShow.get(key)}
                                        </Select.ArrowSingleLevel.Option>
                                    )
                                })}
                                {countVariablePicker.hasRequiredVariable ? (
                                    <Select.ArrowSingleLevel.Option
                                        value={'apply-variable'}
                                        key={'apply-variable'}
                                        forwardIcon={<MonoIconPanelVariable16 />}
                                        backwardIcon={''}
                                        splitLineTop
                                        dataTestId="rowcol-content-count-apply-variable"
                                    >
                                        {translation('ApplyVariable')}
                                    </Select.ArrowSingleLevel.Option>
                                ) : null}
                                {countVariablePicker.hasSelectedVariable ? (
                                    <Select.ArrowSingleLevel.Option
                                        value={'detach-variable'}
                                        key={'detach-variable'}
                                        forwardIcon={<MonoIconPanelLink16 />}
                                        backwardIcon={''}
                                        splitLineTop={!countVariablePicker.hasRequiredVariable}
                                        dataTestId="rowcol-content-count-detach-variable"
                                    >
                                        {translation('DetachVariable')}
                                    </Select.ArrowSingleLevel.Option>
                                ) : null}
                            </Select.ArrowSingleLevel>
                        </SingleGrid.Item>

                        <SingleGrid.Item start={22} span={23} className={style.backgroundGrid}>
                            <PaintIconColor
                                paint={paint}
                                colorSpace={colorSpace}
                                onChangeColor={onChangeColor}
                                onMouseDownIcon={onClickOpenModal}
                                onClickInput={onClose}
                                isWidth={92}
                                disabled={false}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={45} span={12}>
                            <ScrubbableInputPercent
                                disabled={false}
                                value={paint.opacity}
                                onChange={onChangeOpacityNumber}
                                onClick={onClose}
                                min={0}
                                max={100}
                                className={`${paint.visible! ? '' : style.hidden}`}
                                testId={'rowcol-content-opacity'}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </SingleGrid.Item>
            </SingleGrid>

            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={15} className={style.font}>
                    {translation('Type')}
                </SingleGrid.Item>
                <SingleGrid.Item start={5} span={34}>
                    <Select.NormalSingleLevel
                        isMixed={isMixed}
                        value={align}
                        minWidth={140}
                        label={
                            layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                                ? rowsAlignToShow.get(align!)
                                : colsAlignToShow.get(align!)
                        }
                        onChange={onChangeAlign}
                        dataTestIds={{
                            triggerFocus: 'rowcol-content-type-trigger-focus',
                        }}
                    >
                        {layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                            ? [...rowsAlignToShow.entries()].map(([key, value]) => (
                                  <Select.NormalSingleLevel.Option value={key} key={key}>
                                      {value}
                                  </Select.NormalSingleLevel.Option>
                              ))
                            : [...colsAlignToShow.entries()].map(([key, value]) => (
                                  <Select.NormalSingleLevel.Option value={key} key={key}>
                                      {value}
                                  </Select.NormalSingleLevel.Option>
                              ))}
                    </Select.NormalSingleLevel>
                </SingleGrid.Item>
            </SingleGrid>

            <SingleGrid className={style.container}>
                <SingleGrid.Item start={5} span={16} className={style.font}>
                    {layoutGrid.pattern === Wukong.DocumentProto.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                        ? translation('Height')
                        : translation('Width')}
                </SingleGrid.Item>
                <SingleGrid.Item start={23} span={16} className={style.font}>
                    {align == Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        ? translation('Margin')
                        : translation('Offset')}
                </SingleGrid.Item>
                <SingleGrid.Item start={41} span={16} className={style.font}>
                    {translation('Gutter')}
                </SingleGrid.Item>

                <SingleGrid.Item start={5} span={16} data-testid="rowcol-content-sectionSize-item">
                    <ScrubbableInputLayoutGrid
                        onChange={onChangeSectionSize}
                        min={0}
                        max={999999}
                        className={style.input}
                        disabled={
                            align === Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        }
                        value={layoutGrid.sectionSize!}
                        autoFocus={
                            align != Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH
                        }
                        showWhendisabled={translation('Auto')}
                        testId="rowcol-content-sectionSize"
                        useVariable={sectionSizeVariablePicker.useVariable}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={23} span={16} data-testid="rowcol-content-offset-item">
                    <ScrubbableInputLayoutGrid
                        onChange={onChangeOffset}
                        min={0}
                        max={999999}
                        className={style.input}
                        disabled={
                            align === Wukong.DocumentProto.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER
                        }
                        value={layoutGrid.offset!}
                        showWhendisabled="0"
                        testId="rowcol-content-offset"
                        useVariable={offsetVariablePicker.useVariable}
                    />
                </SingleGrid.Item>

                <SingleGrid.Item start={41} span={16} data-testid="rowcol-content-guttersize-item">
                    <ScrubbableInputInteger
                        onChange={onChangeGutterSize}
                        value={layoutGrid.gutterSize!}
                        min={0}
                        max={999999}
                        className={style.input}
                        testId="rowcol-content-guttersize"
                        useVariable={gutterSizeVariablePicker.useVariable}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            {isOpen && position && (
                <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClose(), false)}>
                    <ColorInteraction
                        from={ColorInteractionFrom.GRID}
                        position={position}
                        paint={paint}
                        onCancel={onClose}
                        onChangeColor={onChangeColor}
                        onChangeOpacity={onChangeOpacityValue}
                        enterClose={onClose}
                        styleId={undefined}
                        styleKey={undefined}
                        onChangePaintType={() => {}}
                        onChangeBlendMode={() => {}}
                        onChangeImagePaint={() => {}}
                        onChangeColorStops={() => {}}
                        onChangeTransform={() => {}}
                        onChangeStyle={() => {}}
                        onChangeColorVar={() => {}}
                        onChangePaints={() => {}}
                        onClickCreateStyleButton={undefined}
                    />
                </KeyboardReceiver>
            )}

            {countVariablePicker.renderPanel()}
            {gutterSizeVariablePicker.renderPanel()}
            {offsetVariablePicker.renderPanel()}
            {sectionSizeVariablePicker.renderPanel()}
        </>
    )
}
