import { Wukong } from '@wukong/bridge-proto'
import { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { EditorDataTestId } from '../../../../../window'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { ScrubbableInputNumber } from '../../../atom/inputs/scrubbable-input-number'
import { StrokeIndependentOptionMap } from '../stroke-independent-type/stroke-independent-type-icon'
import { StrokeFourIndependentWeightRef } from './stroke-four-independent-weight'
import classes from './stroke-four-independent-weight.module.less'
import { useStrokeFourIndependentWeightV2 } from './use-stroke-four-independent-weight'

// 四边单边线重输入框
const _StrokeFourIndependentWeight = (_: unknown, ref: ForwardedRef<StrokeFourIndependentWeightRef>) => {
    const {
        leftBorderState,
        rightBorderState,
        topBorderState,
        bottomBorderState,
        onChangeTopWeight,
        onChangeLeftWeight,
        onChangeBottomWeight,
        onChangeRightWeight,
        showInputGroup,
        setShowInputGroup,
        topBorderVariablePicker,
        rightBorderVariablePicker,
        bottomBorderVariablePicker,
        leftBorderVariablePicker,
    } = useStrokeFourIndependentWeightV2()

    useImperativeHandle(
        ref,
        () => ({
            openFourIndependentWeight() {
                setShowInputGroup(true)
            },
            closeFourIndependentWeight() {
                setShowInputGroup(false)
            },
        }),
        [setShowInputGroup]
    )

    return showInputGroup ? (
        <div className={classes.containerNewSvg}>
            <SingleGrid style={{ gridTemplateColumns: 'repeat(124, 2px)' }}>
                <SingleGrid.Item start={9} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT
                            )?.icon
                        }
                        isMixed={
                            leftBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={leftBorderState?.value}
                        onChange={onChangeLeftWeight}
                        min={0}
                        scrubbingDisabled={
                            leftBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        useVariable={leftBorderVariablePicker.useVariable}
                        labelTestId={EditorDataTestId.StrokeAttr.LeftBorderInputLabel}
                        inputStyle="small-indent"
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={48} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP
                            )?.icon
                        }
                        isMixed={
                            topBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={topBorderState?.value}
                        onChange={onChangeTopWeight}
                        min={0}
                        scrubbingDisabled={
                            topBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        useVariable={topBorderVariablePicker.useVariable}
                        labelTestId={EditorDataTestId.StrokeAttr.TopBorderInputLabel}
                        inputStyle="small-indent"
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid style={{ gridTemplateColumns: 'repeat(124, 2px)' }}>
                <SingleGrid.Item start={9} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT
                            )?.icon
                        }
                        isMixed={
                            rightBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={rightBorderState?.value}
                        onChange={onChangeRightWeight}
                        min={0}
                        scrubbingDisabled={
                            rightBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        useVariable={rightBorderVariablePicker.useVariable}
                        labelTestId={EditorDataTestId.StrokeAttr.RightBorderInputLabel}
                        inputStyle="small-indent"
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={48} span={35}>
                    <ScrubbableInputNumber
                        icon={
                            StrokeIndependentOptionMap.get(
                                Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM
                            )?.icon
                        }
                        isMixed={
                            bottomBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        value={bottomBorderState?.value}
                        onChange={onChangeBottomWeight}
                        min={0}
                        scrubbingDisabled={
                            bottomBorderState?.type ===
                            Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                        }
                        useVariable={bottomBorderVariablePicker.useVariable}
                        labelTestId={EditorDataTestId.StrokeAttr.BottomBorderInputLabel}
                        inputStyle="small-indent"
                    />
                </SingleGrid.Item>
            </SingleGrid>
            {leftBorderVariablePicker.renderPanel()}
            {topBorderVariablePicker.renderPanel()}
            {rightBorderVariablePicker.renderPanel()}
            {bottomBorderVariablePicker.renderPanel()}
        </div>
    ) : (
        <></>
    )
}

export const StrokeFourIndependentWeight = forwardRef(_StrokeFourIndependentWeight)
