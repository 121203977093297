import { getTransformMatrix, hasPseudoElement, isRotate } from './utils'

export function isFlex(node: Element) {
    const computedStyle = window.getComputedStyle(node, null)

    const isDisplayFlex =
        (computedStyle.display === 'flex' ||
            computedStyle.display === 'inline-flex' ||
            computedStyle.display === 'grid') &&
        !computedStyle.flexDirection.includes('reverse')

    if (!isDisplayFlex) {
        return false
    }

    const children = Array.prototype.slice.call(node.children)
    const hasAbsoultChild = children.some((item: Element) => getComputedStyle(item).position === 'absolute')
    const hasRelativeChild = children.some((item: Element) => getComputedStyle(item).position === 'relative')
    const hasZindexChild = children.some((item: Element) => getComputedStyle(item).zIndex !== 'auto')
    // 有旋转的子元素时不能自动布局, 因为我们旋转后会使用实际的bounds, 而html是原bounds, 除非多套一层容器: 89079
    const hasNoRotateChild = children.every((child) => {
        const transform = getComputedStyle(child).transform
        const matrix = getTransformMatrix(transform)
        return !matrix || !isRotate(matrix)
    })

    return !(hasAbsoultChild && hasRelativeChild) && !hasZindexChild && hasNoRotateChild
}

export function getJustifyContent(justifyContent: string) {
    switch (justifyContent) {
        case 'center':
            return 'center'
        case 'end':
        case 'flex-end':
        case 'right':
            return 'end'
        case 'space-between':
            return 'space-between'
        case 'space-around':
        case 'space-evenly':
            return 'space-evenly'
        case 'stretch':
            return 'stretch'
        default:
            return 'start'
    }
}

export function isExtractFlex(node: Element) {
    if (node.children.length !== 2 || node.childNodes.length !== 2) {
        return false
    }
    if (node.children[0] !== node.childNodes[0] || node.children[1] !== node.childNodes[1]) {
        return false
    }
    if (hasPseudoElement(node, 'after') || hasPseudoElement(node, 'before')) {
        // 有伪元素不能添加自动布局, 99249
        return false
    }
    const childrenArray = Array.prototype.slice.call(node.children)
    const isAllBlock = childrenArray.every((child) => getComputedStyle(child).display === 'block')
    const isNoAbsoulte = childrenArray.every((child) => getComputedStyle(child).position !== 'absolute')
    // 同isFlex里的判断, 这里先预防, 还没有实际case遇到
    const hasNoRotate = childrenArray.every((child) => {
        const transform = getComputedStyle(child).transform
        const matrix = getTransformMatrix(transform)
        return !matrix || !isRotate(matrix)
    })
    return isAllBlock && isNoAbsoulte && hasNoRotate
}
