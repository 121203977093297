import { useCallback } from 'react'
import { useParams } from 'react-router'
import { WKFrog } from '../../../../kernel/frog'

function frogEventAiGenUIPromptEnter(prompt: string, docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/event/AiGenUI/promptEnter',
        eventId: 27701,
        customExtend: {
            prompt_content: prompt,
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'event',
        eventName: 'promptEnter',
    })
}

function frogEventAiGenUIGenerationFail(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/event/AiGenUI/generationFail',
        eventId: 27700,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'event',
        eventName: 'generationFail',
    })
}

function frogEventAiGenUIGenerationSuccess(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/event/AiGenUI/generationSuccess',
        eventId: 27699,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'event',
        eventName: 'generationSuccess',
    })
}

function frogEventAiGenUIGenerationTaskSuccess(docId: string) {
    WKFrog.addFrogRecord({
        url: '/event/AiGenUI/generationSuccessTask',
        eventId: 28202,
        customExtend: {
            docId,
        },
        currentPage: 'AiGenUI',
        eventAction: 'event',
        eventName: 'generationSuccessTask',
    })
}

function frogClickAiGenUIRetryButton(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/retryButton',
        eventId: 27698,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'retryButton',
    })
}

function frogClickAiGenUIGenerateButton(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/generateButton',
        eventId: 27697,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'generateButton',
    })
}

function frogClickAiGenUITryExampleButton(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/tryExampleButton',
        eventId: 27696,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'tryExampleButton',
    })
}

function frogExposeAiGenUIAiGenUiDialog(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/expose/AiGenUI/aiGenUiDialog',
        eventId: 27695,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'expose',
        eventName: 'aiGenUiDialog',
    })
}

function frogCloseAiGenUIAiGenUiDialog(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/closeAiGenUiDialog',
        eventId: 27705,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'closeAiGenUiDialog',
    })
}

function frogClickAiGenUiToolbarEntry(docId: string, keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/aiGenUiToolbarEntry',
        eventId: 27694,
        customExtend: {
            docId,
        },
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'aiGenUiToolbarEntry',
    })
}

function frogClickAiGenUISlackIconClick(keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/click/AiGenUI/slackIconClick',
        eventId: 32663,
        customExtend: {},
        keyFrom,
        refPage: 'AiGenUI',
        currentPage: 'AiGenUI',
        eventAction: 'click',
        eventName: 'slackIconClick',
    })
}

function frogClickAiGenUISlackPopupShow(keyFrom?: string) {
    WKFrog.addFrogRecord({
        url: '/expose/AiGenUI/popoverShow',
        eventId: 32664,
        customExtend: {},
        keyFrom,
        currentPage: 'AiGenUI',
        eventAction: 'expose',
        eventName: 'popoverShow',
    })
}

export function useAIGenUIFrog() {
    const params = useParams()
    const keyFrom = params.keyFrom
    const docId = params.docId ?? ''

    const eventAiGenUIPromptEnter = useCallback(
        (prompt: string) => {
            frogEventAiGenUIPromptEnter(prompt, docId, keyFrom)
        },
        [docId, keyFrom]
    )
    const eventAiGenUIGenerationFail = useCallback(() => {
        frogEventAiGenUIGenerationFail(docId, keyFrom)
    }, [docId, keyFrom])
    const eventAiGenUIGenerationSuccess = useCallback(() => {
        frogEventAiGenUIGenerationSuccess(docId, keyFrom)
    }, [docId, keyFrom])
    const clickAiGenUIRetryButton = useCallback(() => {
        frogClickAiGenUIRetryButton(docId, keyFrom)
    }, [docId, keyFrom])
    const clickAiGenUIGenerateButton = useCallback(() => {
        frogClickAiGenUIGenerateButton(docId, keyFrom)
    }, [docId, keyFrom])
    const clickAiGenUITryExampleButton = useCallback(() => {
        frogClickAiGenUITryExampleButton(docId, keyFrom)
    }, [docId, keyFrom])
    const exposeAiGenUIAiGenUiDialog = useCallback(() => {
        frogExposeAiGenUIAiGenUiDialog(docId, keyFrom)
    }, [docId, keyFrom])
    const closeAiGenUIAiGenUiDialog = useCallback(() => {
        frogCloseAiGenUIAiGenUiDialog(docId, keyFrom)
    }, [docId, keyFrom])
    const clickAiGenUiToolbarEntry = useCallback(() => {
        frogClickAiGenUiToolbarEntry(docId, keyFrom)
    }, [docId, keyFrom])
    const eventAiGenUIGenerationTaskSuccess = useCallback(() => {
        frogEventAiGenUIGenerationTaskSuccess(docId)
    }, [docId])
    const eventAiGenUISlackIconClick = useCallback(() => {
        frogClickAiGenUISlackIconClick(keyFrom)
    }, [keyFrom])
    const eventAiGenUISlackPopupShow = useCallback(() => {
        frogClickAiGenUISlackPopupShow(keyFrom)
    }, [keyFrom])

    return {
        eventAiGenUIPromptEnter,
        eventAiGenUIGenerationFail,
        eventAiGenUIGenerationSuccess,
        clickAiGenUIRetryButton,
        clickAiGenUIGenerateButton,
        clickAiGenUITryExampleButton,
        exposeAiGenUIAiGenUiDialog,
        closeAiGenUIAiGenUiDialog,
        clickAiGenUiToolbarEntry,
        eventAiGenUIGenerationTaskSuccess,
        eventAiGenUISlackIconClick,
        eventAiGenUISlackPopupShow,
    } as const
}
