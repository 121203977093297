import { useEffect, useMemo, useRef } from 'react'
import { DraggablePopupRef, DraggablePopupV2 } from '../../../../ui-lib/src'
import { useAppContext } from '../../main/app-context'
import { useLeftMargin, useTopMargin } from '../../main/layout/layout-context'
import { ReactComponent as CustomPluginIcon } from '../../ui/assets/plugins/custom-plugin.svg'
import { useViewState } from '../../view-state-bridge'
import { Matrix } from '../util/matrix'
import styles from './plugin-ui-container.module.less'

export function PluginUIContainer() {
    const pluginHostService = useAppContext().pluginHostService
    const position = pluginHostService.useZustandStore.use.position()
    const iconUrl = pluginHostService.useZustandStore.use.iconUrl()
    const visible = pluginHostService.useZustandStore.use.visible()
    const isOpen = pluginHostService.useZustandStore.use.isOpen()
    const currentViewport = useViewState('currentViewport')
    const topMargin = useTopMargin()
    const leftMargin = useLeftMargin()

    const ref = useRef<DraggablePopupRef>(null)

    const initialPosition = useMemo(() => {
        if (!currentViewport || !position || !isOpen) return undefined
        const cameraTransform = new Matrix()
        cameraTransform.postConcat(new Matrix().translateX(-currentViewport.x).translateY(-currentViewport.y))
        cameraTransform.postConcat(
            new Matrix({
                scaleX: currentViewport!.zoom,
                scaleY: currentViewport!.zoom,
                skewX: 0,
                skewY: 0,
                translateX: 0,
                translateY: 0,
            })
        )
        const worldTransform = new Matrix({
            translateX: position.x,
            translateY: position.y,
            scaleX: 1,
            scaleY: 1,
            skewX: 0,
            skewY: 0,
        })
        const ret = worldTransform.postConcat(cameraTransform)
        return {
            x: ret.getTranslateX() + leftMargin,
            y: ret.getTranslateY() + topMargin,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position, isOpen])

    useEffect(() => {
        if (!initialPosition) return
        ref.current?.setPosition({
            left: initialPosition.x,
            top: initialPosition.y,
        })
    }, [initialPosition])

    return (
        <DraggablePopupV2
            ref={ref}
            style={{
                display: visible ? 'block' : 'none',
            }}
            onCancel={() => motiff.closePlugin()}
            visible={isOpen}
            footer={null}
            bodyClassName="p-0"
            position={isOpen && initialPosition ? { left: initialPosition.x, top: initialPosition.y } : undefined}
            header={
                <div className={styles.modalHeaderTitle}>
                    {iconUrl ? (
                        <img src={iconUrl} className={styles.icon} />
                    ) : (
                        <CustomPluginIcon className={styles.icon} />
                    )}
                    <span className="py-2px ml-10px mr-36px wk-text-14 font-600 truncate">
                        {pluginHostService.useZustandStore.use.title()}
                    </span>
                </div>
            }
            width={pluginHostService.useZustandStore.use.width()}
            height={pluginHostService.useZustandStore.use.height() + 55}
            closeClassName={styles.closeButton}
            testId="custom-plugin-ui-container"
        >
            <div ref={pluginHostService.onContainerDivMount}></div>
        </DraggablePopupV2>
    )
}
