import {
    AIDesignLintUpdatePaintStyleIdCommand,
    AIDesignLintUpdateTextStyleIdCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { Position, WKToast } from '../../../../../../ui-lib/src'
import { compareString, createSelectors, createStore } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { NodeId } from '../../../../document/node/node'
import { environment } from '../../../../environment'
import { WKFrog } from '../../../../kernel/frog'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import type { LibraryQueryResponse, LibraryVO } from '../../../../kernel/interface/library'
import { DocID } from '../../../../kernel/interface/type'
import {
    GetLibraryContentMap,
    GetLibraryIdAndNameMapRequest,
    GetLibraryQuery,
} from '../../../../kernel/request/library'
import { GetLibrarySubscription } from '../../../../kernel/request/library-subscription'
import { ServiceClass } from '../../../../kernel/util/service-class'
import type { CustomLibraryTeamInfo } from '../../../../share/component-style-library/service/library-remote-search-service'
import {
    AIComponentItem,
    AiDesignInspectionConfigVO,
    QueryAIDesignLintConfig,
    QueryAIDsignLintTask,
    type AiDesignInspectionRuleEnum,
    type AiDesignInspectionTaskResultVO,
} from './ai-design-lint-request'
import { translation } from './ai-design-lint-service.translation'
import { ColorSingleCardInfo } from './common/color-card-list'
import { TextSingleCardInfo } from './common/text-card-list'

export enum View {
    SettingRule,
    SettingLibrary,
    Pending,
    Home,
    Result,
}

interface AIDesignLintServiceState {
    isOpen: boolean
    width: number
    height: number
    popup: boolean
}

export enum NetworkStatus {
    Error,
    SettingLibraryError,
    SettingRuleError,
    Normal,
}

export class AIDesignLintService extends ServiceClass {
    public states = createSelectors(
        createStore<{
            currentViewTypeState: View
            subscribedLibraryIdsAndPublishedLocalLibraryIdsState: LibraryId[] | undefined
            libraryInfoListState: LibraryVO[] | undefined
            libraryTeamInfoListState: CustomLibraryTeamInfo[] | undefined
            latestLibraryConfigState: { value: LibraryId[] | undefined; loading: boolean }
            libConfigState:
                | {
                      localLib: LibraryVO[]
                      remoteLibs: LibraryVO[]
                      allLibs: LibraryVO[]
                  }
                | undefined
            latestRuleConfigState: AiDesignInspectionRuleEnum[] | undefined
            finishedTaskStatusResponseState: AiDesignInspectionTaskResultVO | undefined
            hasUnfinishedTaskState: boolean
            latestTaskIDState: string | undefined
            colorCardInfoListState: {
                sameStyleCardInfos: ColorSingleCardInfo[]
                similarStyleCardInfos: ColorSingleCardInfo[]
            }
            textCardInfoListState: {
                sameStyleCardInfos: TextSingleCardInfo[]
                similarStyleCardInfos: TextSingleCardInfo[]
            }
            componentCardInfoListState: AIComponentItem[] | undefined

            aiDesignLintServiceState: AIDesignLintServiceState
            updateAIDesignLintServiceState: (state: AIDesignLintServiceState) => void
            positionState: { top: number; left: number } | undefined
            updatePositionState: (state: { top: number; left: number }) => void
            configHasInitializedState: boolean | undefined
            publishedLocalLibraryState: LibraryVO | null | undefined
        }>(
            (set) => ({
                currentViewTypeState: View.Home,
                subscribedLibraryIdsAndPublishedLocalLibraryIdsState: undefined,
                libraryInfoListState: undefined,
                libraryTeamInfoListState: undefined,
                latestLibraryConfigState: { value: undefined, loading: true },
                libConfigState: undefined,
                latestRuleConfigState: undefined,
                finishedTaskStatusResponseState: undefined,
                hasUnfinishedTaskState: false,
                latestTaskIDState: undefined,
                colorCardInfoListState: {
                    sameStyleCardInfos: [],
                    similarStyleCardInfos: [],
                },
                textCardInfoListState: {
                    sameStyleCardInfos: [],
                    similarStyleCardInfos: [],
                },
                componentCardInfoListState: undefined,

                aiDesignLintServiceState: {
                    isOpen: false,
                    width: 464,
                    height: 600,
                    popup: true,
                },
                updateAIDesignLintServiceState: (state: AIDesignLintServiceState) => {
                    set({ aiDesignLintServiceState: state })
                },
                positionState: undefined,
                updatePositionState: (position: Position) => {
                    set({ positionState: position })
                },
                configHasInitializedState: undefined,
                publishedLocalLibraryState: undefined,
            }),
            environment.isDev
        )
    )

    // 即时记录当前的窗口位置, 待窗口关闭时使用当前的位置作为下次窗口打开的位置
    private currentPosition: { top: number; left: number } = { top: 0, left: 0 }

    public isOpen = false

    private docID$ = this.buildReplaySubject<DocID>()

    constructor(public readonly docID: DocID) {
        super()
        this.init()
    }

    public showModal = () => {
        WKFrog.addFrogRecord({
            url: '/expose/AIConsistencyChecker/enterConsistencyCheck',
            eventId: 26796,
            eventAction: 'expose',
            eventName: 'enterConsistencyCheck',
        })

        this.states.getState().updateAIDesignLintServiceState({ isOpen: true, width: 464, height: 600, popup: true })
    }

    public beforeCloseCallback = async () => {
        return new Promise((resolve) => {
            resolve(true)
        })
    }

    public updateBeforeCloseCallback = (handleCloseFunc: () => Promise<boolean>) => {
        this.beforeCloseCallback = handleCloseFunc
    }

    public resetBeforeCloseCallback = () => {
        this.updateBeforeCloseCallback(async () => true)
    }

    public updateCurrentPosition = (position: { top: number; left: number }) => {
        this.currentPosition = position
    }

    public closeModal = () => {
        this.beforeCloseCallback().then((shouldClose) => {
            if (shouldClose) {
                this.resetBeforeCloseCallback()

                this.states
                    .getState()
                    .updateAIDesignLintServiceState({ isOpen: false, width: 464, height: 600, popup: true })

                // 清理状态
                this.updateHasUnfinishedTask(false)
                this.states.setState({ configHasInitializedState: undefined })

                // position for next launch
                this.states.setState({ positionState: this.currentPosition })

                this.states.setState({ latestTaskIDState: undefined })
                this.states.setState({ finishedTaskStatusResponseState: undefined })
            }
        })
    }

    public updateTextStyle = (
        command: CommandInvoker,
        ranges: Wukong.DocumentProto.ITextRange[],
        textStyleId: NodeId
    ) => {
        command.DEPRECATED_invokeBridge(AIDesignLintUpdateTextStyleIdCommand, {
            ranges: ranges,
            textStyleId: textStyleId,
        })

        command.commitUndo()
    }

    public batchUpdateTextStyle = (
        command: CommandInvoker,
        batch: { ranges: Wukong.DocumentProto.ITextRange[]; textStyleId: NodeId }[]
    ) => {
        batch.forEach(({ ranges, textStyleId }) => {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateTextStyleIdCommand, {
                ranges: ranges,
                textStyleId: textStyleId,
            })
        })

        command.commitUndo()
    }

    public updatePaintStyle = (
        command: CommandInvoker,
        nodeIds: NodeId[],
        paintStyleId: NodeId,
        type: Wukong.DocumentProto.PaintStyleMatchType
    ) => {
        command.DEPRECATED_invokeBridge(AIDesignLintUpdatePaintStyleIdCommand, {
            type,
            nodeIds,
            paintStyleId,
        })
        command.commitUndo()
    }

    public batchUpdatePaintStyle = (
        command: CommandInvoker,
        batch: { nodeIds: NodeId[]; paintStyleId: NodeId; type: Wukong.DocumentProto.PaintStyleMatchType }[]
    ) => {
        batch.forEach(({ nodeIds, paintStyleId, type }) => {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdatePaintStyleIdCommand, {
                type,
                nodeIds,
                paintStyleId,
            })
        })
        command.commitUndo()
    }

    private updatePublishedLocalLibraryState: ((docId: DocID) => void) | undefined
    private updateSubscribedLibraryIdsState: ((docId: DocID) => void) | undefined
    private updateLibraryQueryResponseState: ((docId: DocID) => void) | undefined
    private updateLatestDesignLintConfigResponseState: ((docId: DocID) => void) | undefined

    public refresh = () => {
        this.docID$.next(this.docID)

        // update downstream state
        this.updatePublishedLocalLibraryState?.(this.docID)
        this.updateSubscribedLibraryIdsState?.(this.docID)
        this.updateLibraryQueryResponseState?.(this.docID)
        this.updateLatestDesignLintConfigResponseState?.(this.docID)
    }

    private init = () => {
        // 当前文档自身的组件库信息 (已发布)
        this.updatePublishedLocalLibraryState = (docId: DocID) => {
            // todo: debounce
            let libraryId: LibraryId | undefined

            new GetLibraryIdAndNameMapRequest([docId], docId)
                .start()
                .then((response) => {
                    libraryId = response[docId]?.shared ? response[docId]?.id : undefined

                    if (libraryId) {
                        return new GetLibraryContentMap([libraryId], docId).start()
                    }
                    return null
                })
                .then((response) => {
                    let publishedLocalLibrary: LibraryVO | null = null

                    if (response) {
                        publishedLocalLibrary = response[libraryId!]?.library
                    }

                    this.states.setState({ publishedLocalLibraryState: publishedLocalLibrary })

                    // update downstream state
                    updateSubscribedLibraryAndPublishedLocalLibraryIdsState({ publishedLocalLibrary })
                    updateAvailableLibraryIdSetState({ publishedLocalLibrary })
                    updateLibraryTeamInfoListState({ publishedLocalLibrary })
                })
                .catch(() => {
                    this.updateNetworkStatus(NetworkStatus.Error)
                })
        }

        // 当前文档订阅的组件库
        this.updateSubscribedLibraryIdsState = (docID: DocID) => {
            // todo: debounce
            new GetLibrarySubscription(docID)
                .start()
                .then((response) => {
                    return response.map((item) => item.libraryId)
                })
                .then((libraryIds) => {
                    // update downstream state
                    updateSubscribedLibraryAndPublishedLocalLibraryIdsState({
                        subscribedLibraryIds: libraryIds,
                    })
                })
                .catch(() => {
                    this.updateNetworkStatus(NetworkStatus.Error)
                })
        }

        // 当前文档订阅的组件库 + 已发布本地组件库
        const updateSubscribedLibraryAndPublishedLocalLibraryIdsState = (() => {
            const store: {
                subscribedLibraryIds?: LibraryId[]
                publishedLocalLibrary?: LibraryVO | null
            } = {
                subscribedLibraryIds: undefined,
                publishedLocalLibrary: undefined,
            }

            return ({
                subscribedLibraryIds,
                publishedLocalLibrary,
            }: {
                subscribedLibraryIds?: LibraryId[]
                publishedLocalLibrary?: LibraryVO | null
            }) => {
                if (subscribedLibraryIds !== undefined) {
                    store.subscribedLibraryIds = subscribedLibraryIds
                }

                if (publishedLocalLibrary !== undefined) {
                    store.publishedLocalLibrary = publishedLocalLibrary
                }

                if (store.subscribedLibraryIds !== undefined && store.publishedLocalLibrary !== undefined) {
                    this.states.setState({
                        subscribedLibraryIdsAndPublishedLocalLibraryIdsState: [
                            ...new Set(store.subscribedLibraryIds.concat(store.publishedLocalLibrary?.id || [])),
                        ],
                    })

                    store.subscribedLibraryIds = undefined
                    store.publishedLocalLibrary = undefined
                }
            }
        })()

        // 检索可用组件库, 可能不包含当前文档的组件库
        this.updateLibraryQueryResponseState = (docID: DocID) => {
            // todo: debounce
            new GetLibraryQuery(docID, undefined, true)
                .start()
                .then((response) => {
                    // update downstream state
                    updateLibraryInfoListState(response)
                    updateAvailableLibraryIdSetState({ libraryQueryResponse: response })
                })
                .catch(() => {
                    this.updateNetworkStatus(NetworkStatus.Error)
                })
        }

        // 组件库列表 ( 远程 + 本地)
        const updateLibraryInfoListState = (libraryQueryResponse: LibraryQueryResponse) => {
            const publishedLocalLibrary = this.states.getState().publishedLocalLibraryState

            const libraryInfoList = Object.entries(libraryQueryResponse.teamId2LibraryList)
                .map(([_, libraryList]) => libraryList)
                .flat()
                .filter((library) => library.id !== publishedLocalLibrary?.id)
                .concat(publishedLocalLibrary ? [publishedLocalLibrary] : [])
                .sort((lhs, rhs) => compareString(lhs!.document!.name, rhs!.document!.name))

            this.states.setState({ libraryInfoListState: libraryInfoList })
            updateLibConfigState(libraryInfoList, this.states.getState().latestLibraryConfigState, this.docID)
            updateLibraryTeamInfoListState({
                libraryQueryResponse,
            })
        }

        // 按团队归类组件库信息 (仅远程)
        const updateLibraryTeamInfoListState = (() => {
            const store: {
                publishedLocalLibrary?: LibraryVO | null
                libraryQueryResponse?: LibraryQueryResponse
            } = {
                publishedLocalLibrary: undefined,
                libraryQueryResponse: undefined,
            }

            return ({
                publishedLocalLibrary,
                libraryQueryResponse,
            }: {
                publishedLocalLibrary?: LibraryVO | null
                libraryQueryResponse?: LibraryQueryResponse
            }) => {
                if (publishedLocalLibrary !== undefined) {
                    store.publishedLocalLibrary = publishedLocalLibrary
                }

                if (libraryQueryResponse !== undefined) {
                    store.libraryQueryResponse = libraryQueryResponse
                }

                if (store.publishedLocalLibrary !== undefined && store.libraryQueryResponse !== undefined) {
                    // 默认状态，将 团队 按 名称自然排序
                    const libraryTeamInfoList = Object.entries(store.libraryQueryResponse.teamId2TeamName)
                        .map(([id, name]) => ({
                            id,
                            name,
                            libraryList: store.libraryQueryResponse!.teamId2LibraryList[id].filter(
                                // 过滤掉当前文档的组件库
                                (library) => library.id !== store.publishedLocalLibrary?.id
                            ),
                        }))
                        .filter(({ libraryList }) => libraryList.length > 0)
                        .sort((t1, t2) => compareString(t1.name, t2.name))

                    this.states.setState({ libraryTeamInfoListState: libraryTeamInfoList })

                    store.publishedLocalLibrary = undefined
                    store.libraryQueryResponse = undefined
                }
            }
        })()

        // 当前文档可用的组件库 (远程 + 本地)
        const updateAvailableLibraryIdSetState = (() => {
            const store: {
                publishedLocalLibrary?: LibraryVO | null
                libraryQueryResponse?: LibraryQueryResponse
            } = {
                publishedLocalLibrary: undefined,
                libraryQueryResponse: undefined,
            }

            return ({
                publishedLocalLibrary,
                libraryQueryResponse,
            }: {
                publishedLocalLibrary?: LibraryVO | null
                libraryQueryResponse?: LibraryQueryResponse
            }) => {
                if (publishedLocalLibrary !== undefined) {
                    store.publishedLocalLibrary = publishedLocalLibrary
                }

                if (libraryQueryResponse !== undefined) {
                    store.libraryQueryResponse = libraryQueryResponse
                }

                if (store.publishedLocalLibrary !== undefined && store.libraryQueryResponse !== undefined) {
                    const availableLibraryIdSet = new Set(
                        Object.entries(store.libraryQueryResponse.teamId2LibraryList)
                            .map(([_, libraryList]) => libraryList.map((library) => library.id))
                            .flat()
                            // 合并当前文档的组件库
                            .filter((libraryId) => libraryId !== store.publishedLocalLibrary?.id)
                            .concat(store.publishedLocalLibrary ? [store.publishedLocalLibrary.id] : [])
                    )

                    // update downstream state
                    updateLatestLibraryConfigState({
                        availableLibraryIdSet,
                    })

                    store.publishedLocalLibrary = undefined
                    store.libraryQueryResponse = undefined
                }
            }
        })()

        this.updateLatestDesignLintConfigResponseState = (docID: DocID) => {
            // todo: debounce
            new QueryAIDesignLintConfig(docID)
                .start()
                .then((response) => {
                    // update downstream state
                    updateConfigHasInitializedState(response.configured)
                    updateLatestLibraryConfigState({
                        latestDesignLintConfigResponse: response,
                    })
                })
                .catch(() => {
                    this.updateNetworkStatus(NetworkStatus.Error)
                })
        }

        // 该文档是否为初次使用本工具
        const updateConfigHasInitializedState = (hasConfigured: boolean) => {
            this.states.setState({ configHasInitializedState: hasConfigured })
        }

        // 查询被记录的检查配置

        // 组件库配置
        const updateLatestLibraryConfigState = (() => {
            const store: {
                availableLibraryIdSet?: Set<string>
                latestDesignLintConfigResponse?: AiDesignInspectionConfigVO
            } = {
                availableLibraryIdSet: undefined,
                latestDesignLintConfigResponse: undefined,
            }

            return ({
                availableLibraryIdSet,
                latestDesignLintConfigResponse,
            }: {
                availableLibraryIdSet?: Set<string>
                latestDesignLintConfigResponse?: AiDesignInspectionConfigVO
            }) => {
                if (availableLibraryIdSet) {
                    store.availableLibraryIdSet = availableLibraryIdSet
                }

                if (latestDesignLintConfigResponse) {
                    store.latestDesignLintConfigResponse = latestDesignLintConfigResponse
                }

                // both availableLibraryIdSet and latestDesignLintConfigResponse are ready
                if (store.availableLibraryIdSet && store.latestDesignLintConfigResponse) {
                    const config = {
                        ...store.latestDesignLintConfigResponse,
                        libraryIds: store.latestDesignLintConfigResponse.libraryIds.filter((id) =>
                            store.availableLibraryIdSet!.has(id)
                        ),
                    }

                    this.states.setState({
                        latestLibraryConfigState: { value: config.libraryIds, loading: false },
                    })

                    updateLibConfigState(
                        this.states.getState().libraryInfoListState,
                        { value: config.libraryIds, loading: false },
                        this.docID
                    )

                    updateLatestRuleConfigState({ rules: config.rules })
                }
            }
        })()

        const updateLibConfigState = (
            libraryInfoList: LibraryVO[] | undefined,
            latestLibraryConfig: { value: string[] | undefined; loading: boolean },
            docId: DocID
        ) => {
            if (libraryInfoList === undefined) {
                return
            }

            if (latestLibraryConfig.value === undefined) {
                return
            }

            const libConfig: { localLib: LibraryVO[]; remoteLibs: LibraryVO[]; allLibs: LibraryVO[] } = {
                localLib: [],
                remoteLibs: [],
                allLibs: [],
            }

            libConfig.allLibs = libraryInfoList.filter((item) => latestLibraryConfig.value!.includes(item!.id))

            libConfig.localLib = libConfig.allLibs.filter((item) => item?.document?.id === docId)
            libConfig.remoteLibs = libConfig.allLibs.filter((item) => item?.document?.id !== docId)

            this.states.setState({ libConfigState: libConfig })
        }

        // 规则配置
        const updateLatestRuleConfigState = ({ rules }: { rules: AiDesignInspectionRuleEnum[] }) => {
            this.states.setState({ latestRuleConfigState: rules })
        }
    }

    private pollingTask?: NodeJS.Timeout = undefined

    // 开启轮询任务状态
    public startPollingQueryLintTask = () => {
        if (!this.pollingTask) {
            const pollTask = async () => {
                const latestTaskID = this.states.getState().latestTaskIDState

                if (!latestTaskID) {
                    // No task ID, continue polling
                    this.pollingTask = setTimeout(pollTask, 2000)
                    return
                }

                try {
                    const response = await new QueryAIDsignLintTask(latestTaskID).start()

                    if (response.finished) {
                        // Task finished, update state and stop polling
                        this.states.setState({ finishedTaskStatusResponseState: response })
                        this.pollingTask = undefined
                    } else {
                        // Task not finished, continue polling
                        this.pollingTask = setTimeout(pollTask, 2000)
                    }
                } catch (error) {
                    this.updateNetworkStatus(NetworkStatus.Error)
                    // Continue polling despite error
                    this.pollingTask = setTimeout(pollTask, 2000)
                }
            }

            // Start polling immediately
            this.pollingTask = setTimeout(pollTask, 0)
        }
    }

    // 关闭轮询任务状态
    public stopPollingQueryLintTask = () => {
        if (this.pollingTask) {
            clearTimeout(this.pollingTask)
            this.pollingTask = undefined
            this.states.setState({ finishedTaskStatusResponseState: undefined })
        }
    }

    public updateTaskID = (taskID: string | undefined) => {
        this.states.setState({ latestTaskIDState: taskID })
    }

    // 仍有任务在执行
    public updateHasUnfinishedTask = (hasUnfinishedTask: boolean) => {
        this.states.setState({ hasUnfinishedTaskState: hasUnfinishedTask })
    }

    public setCurrentView = (view: View) => {
        this.states.setState({ currentViewTypeState: view })
    }

    // 网络状态
    public updateNetworkStatus(networkStatus: NetworkStatus) {
        switch (networkStatus) {
            case NetworkStatus.Error:
                WKToast.error(translation('ErrorMsg'))
                this.setCurrentView(View.Home)
                break
            case NetworkStatus.SettingLibraryError:
                WKToast.error(translation('SettingLibraryErrorMsg'))
                break
            case NetworkStatus.SettingRuleError:
                WKToast.error(translation('SettingRuleErrorMsg'))
                break
            default:
                break
        }
    }

    public updateTextCardInfoList = (
        sameStyleCardInfos: TextSingleCardInfo[] | null,
        similarStyleCardInfos: TextSingleCardInfo[] | null
    ) => {
        this.states.setState({
            textCardInfoListState: {
                sameStyleCardInfos: sameStyleCardInfos ?? [],
                similarStyleCardInfos: similarStyleCardInfos ?? [],
            },
        })
    }

    public updateColorCardInfoList = (
        sameStyleCardInfos: ColorSingleCardInfo[] | null,
        similarStyleCardInfos: ColorSingleCardInfo[] | null
    ) => {
        this.states.setState({
            colorCardInfoListState: {
                sameStyleCardInfos: sameStyleCardInfos ?? [],
                similarStyleCardInfos: similarStyleCardInfos ?? [],
            },
        })
    }

    public updateComponentCardInfoList = (componentCardInfoList: AIComponentItem[]) => {
        this.states.setState({
            componentCardInfoListState: componentCardInfoList,
        })
    }
}
