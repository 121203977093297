import { Wukong } from '@wukong/bridge-proto'
import { type WebGLRenderingContextCompat } from '../../../../util/src'

import { isMacOs, isMobile } from 'react-device-detect'
import { WkCLog } from '../../kernel/clog/wukong/instance'

export enum DetectResult {
    HasBug = 'HasBug',
    Pass = 'Pass',
    Error = 'Error',
}

function scopeDetector(version: number, f: (ctx: WebGLRenderingContextCompat) => boolean): DetectResult {
    // 这个地方会修改 canvas 属性，故不使用 GlobalCanvas
    let canvas: HTMLCanvasElement | null = null

    try {
        canvas = document.createElement('canvas')
        const ctx = version == 2 ? canvas.getContext('webgl2') : canvas.getContext('webgl')
        if (!ctx) {
            throw Error('cannot get context')
        }

        const detectedResult = f(ctx)
        canvas.remove()
        return detectedResult ? DetectResult.HasBug : DetectResult.Pass
    } catch (e) {
        if (canvas) {
            canvas.remove()
        }
        return DetectResult.Error
    }
}

function unwrapOptional<T>(x: T | null | undefined): T {
    if (x === null || x === undefined) {
        throw Error('nil')
    }
    return x
}

function buildWebGLProgram(
    ctx: WebGLRenderingContextCompat,
    vertexShaderSource: string,
    fragmentShaderSource: string,
    attributes: Array<[number, string]>
): WebGLProgram {
    const program = unwrapOptional(ctx.createProgram())
    const vertexShader = unwrapOptional(ctx.createShader(ctx.VERTEX_SHADER))
    ctx.shaderSource(vertexShader, vertexShaderSource)
    ctx.compileShader(vertexShader)
    ctx.attachShader(program, vertexShader)
    const fragmentShader = unwrapOptional(ctx.createShader(ctx.FRAGMENT_SHADER))
    ctx.shaderSource(fragmentShader, fragmentShaderSource)
    ctx.compileShader(fragmentShader)
    ctx.attachShader(program, fragmentShader)

    attributes.forEach(([loc, name]) => {
        ctx.bindAttribLocation(program, loc, name)
    })
    ctx.linkProgram(program)
    return program
}

function bindAndCreateTexture(ctx: WebGLRenderingContextCompat, width: number, height: number, minMag: number) {
    const texture = unwrapOptional(ctx.createTexture())
    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MAG_FILTER, minMag)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_MIN_FILTER, minMag)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_S, ctx.CLAMP_TO_EDGE)
    ctx.texParameteri(ctx.TEXTURE_2D, ctx.TEXTURE_WRAP_T, ctx.CLAMP_TO_EDGE)
    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    ctx.texImage2D(ctx.TEXTURE_2D, 0, ctx.RGBA, width, height, 0, ctx.RGBA, ctx.UNSIGNED_BYTE, null)
    return texture
}

function detectViewportBugImpl(ctx: WebGLRenderingContextCompat) {
    const solidBlackProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    void main(){
    gl_Position=vec4(pos*2.-1.,0.,1.);
    }
    `,
        `
    precision highp float;
    void main(){
    gl_FragColor=vec4(0., 0., 0., 1.);
    }
    `,
        [[0, 'pos']]
    )

    const glBuffer = ctx.createBuffer()
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)
    // uniform vertexs
    const vertexData = new Float32Array([0, 0, 1, 0, 0, 1, 1, 1])
    ctx.bufferData(ctx.ARRAY_BUFFER, vertexData.length * 4, ctx.DYNAMIC_DRAW)
    ctx.bufferSubData(ctx.ARRAY_BUFFER, 0, vertexData)
    ctx.enableVertexAttribArray(0)
    ctx.vertexAttribPointer(0, 2, ctx.FLOAT, false, 8, 0)

    ctx.canvas.width = 10
    ctx.canvas.height = 1

    const frameBuffer = ctx.createFramebuffer()
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, frameBuffer)
    const frameBufferTexture = bindAndCreateTexture(ctx, ctx.canvas.width, ctx.canvas.height, ctx.LINEAR)

    ctx.framebufferTexture2D(ctx.FRAMEBUFFER, ctx.COLOR_ATTACHMENT0, ctx.TEXTURE_2D, frameBufferTexture, 0)
    ctx.useProgram(solidBlackProgram)

    // viewport 1
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, frameBuffer)
    ctx.viewport(0, 0, (ctx.canvas.width / 2) | 0, ctx.canvas.height)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    // viewport 2
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.viewport(0, 0, (ctx.canvas.width / 2) | 0, ctx.canvas.height)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    const buf = new Uint8Array(4)
    ctx.readPixels(((ctx.canvas.width / 2) | 0) + 2, 0, 1, 1, ctx.RGBA, ctx.UNSIGNED_BYTE, buf)
    return buf[3] !== 0
}

function detectDrawClearReorderingBugImpl(ctx: WebGLRenderingContextCompat) {
    ctx.canvas.width = 1
    ctx.canvas.height = 1

    const texture = bindAndCreateTexture(ctx, ctx.canvas.width, ctx.canvas.height, ctx.NEAREST)

    ctx.bindTexture(ctx.TEXTURE_2D, texture)
    const framebuffer = ctx.createFramebuffer()
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.framebufferTexture2D(ctx.FRAMEBUFFER, ctx.COLOR_ATTACHMENT0, ctx.TEXTURE_2D, texture, 0)

    const glBuffer = ctx.createBuffer()
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)
    ctx.bufferData(ctx.ARRAY_BUFFER, 32, ctx.DYNAMIC_DRAW)
    ctx.bufferSubData(ctx.ARRAY_BUFFER, 0, new Float32Array([1, 1, 1, -1, -1, 1, -1, -1]))
    ctx.vertexAttribPointer(0, 2, ctx.FLOAT, false, 8, 0)
    ctx.enableVertexAttribArray(0)
    ctx.bindBuffer(ctx.ARRAY_BUFFER, glBuffer)

    const fromTextureProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    varying vec2 uv;
    void main(){
      uv = (pos + vec2(1.0)) / 2.0;
      gl_Position=vec4(pos*2.-1.,0.,1.);
    }
    `,
        `
    precision highp float;
    uniform sampler2D texture;
    varying vec2 uv;
    void main(){
      gl_FragColor=texture2D(texture, uv);
    }
    `,
        [[0, 'pos']]
    )
    const solidBlueProgram = buildWebGLProgram(
        ctx,
        `
    precision highp float;
    attribute vec2 pos;
    void main(){
      gl_Position=vec4(pos,0.,1.);
    }
    `,
        `
    precision highp float;
    void main(){
      gl_FragColor=vec4(0.,0.,1.,1.);
    }
    `,
        [[0, 'pos']]
    )

    ctx.clearColor(1, 0, 0, 1)
    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.useProgram(solidBlueProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.useProgram(fromTextureProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, framebuffer)
    ctx.clear(ctx.COLOR_BUFFER_BIT)

    ctx.bindFramebuffer(ctx.FRAMEBUFFER, null)
    ctx.useProgram(fromTextureProgram)
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, 4)

    const buf = new Uint8Array(4)
    ctx.readPixels(0, 0, 1, 1, ctx.RGBA, ctx.UNSIGNED_BYTE, buf)
    return buf[0] !== 255
}

function hasDrawClearReorderingBug(version: number) {
    const userAgent = navigator.userAgent
    const hasBug = ['Version/15.4 Safari/605.1.15'].some((spec) => userAgent.indexOf(spec) >= 0)
    if (hasBug) {
        return DetectResult.HasBug
    }
    return scopeDetector(version, detectDrawClearReorderingBugImpl)
}

function hasUnexpectedCompositingBug() {
    const userAgent = navigator.userAgent
    const isApple14 = ['iPhone OS 14_0', 'iPhone OS 14_1', 'iPad; CPU OS 14_0', 'iPad; CPU OS 14_1', 'Version/14'].some(
        (spec) => userAgent.indexOf(spec) >= 0
    )
    const isTouch = navigator.maxTouchPoints > 0
    return isApple14 && isTouch
}

function shouldApplyMobileOptimizations() {
    return isMobile || (isMacOs && navigator.maxTouchPoints > 0)
}

export function findWebGLWorkaround(version: number): Wukong.DocumentProto.IGPUWorkaround {
    const viewportBug = scopeDetector(version, detectViewportBugImpl)
    const drawClearReorderingBug = hasDrawClearReorderingBug(version)
    const unexpectedCompositingBug = hasUnexpectedCompositingBug() ? DetectResult.HasBug : DetectResult.Pass
    const applyMobileOptimizations = shouldApplyMobileOptimizations()

    WkCLog.throttleLog(
        'GL_GRAPHIC_BUGS',
        JSON.parse(
            JSON.stringify({ viewportBug, drawClearReorderingBug, unexpectedCompositingBug, applyMobileOptimizations })
        )
    )

    return {
        glViewportBug: viewportBug !== DetectResult.Pass,
        glDrawClearReorderingBug: drawClearReorderingBug !== DetectResult.Pass,
        glUnexpectedCompositingBug: unexpectedCompositingBug !== DetectResult.Pass,
        mobileOptimizations: applyMobileOptimizations,
    }
}

export function findWebGPUWorkaround(): Wukong.DocumentProto.IGPUWorkaround {
    return {
        glViewportBug: false,
        glDrawClearReorderingBug: false,
        glUnexpectedCompositingBug: false,
        mobileOptimizations: shouldApplyMobileOptimizations(),
    }
}
