import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import hljs from 'highlight.js'
import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { MonoIconCommonMore16, Scrollbar, WKTextButton, WKToast } from '../../../../../../../ui-lib/src'
import { execCommandCopy } from '../../../../../../../util/src'
import styles from './code-block.module.less'
import { translation } from './code-block.translation'
import { isNil } from 'lodash-es'
const CODE_BLOCK_CONFIG: Record<
    Wukong.DocumentProto.DevModeInspectCodeType,
    {
        testId: string
    }
> = {
    [Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS]: {
        testId: 'css-code-block',
    },
    [Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit]: {
        testId: 'ui-kit-code-block',
    },
    [Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI]: {
        testId: 'swift-ui-code-block',
    },
    [Wukong.DocumentProto.DevModeInspectCodeType.Android_XML]: {
        testId: 'xml-code-block',
    },
    [Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose]: {
        testId: 'compose-code-block',
    },
}

const MAX_LINES = 15

export const CodeBlock: FC<{
    codeType: Wukong.DocumentProto.DevModeInspectCodeType
    codeBlock: Wukong.DocumentProto.ICodeBlock
}> = ({ codeBlock, codeType }) => {
    const [showAll, setShowAll] = useState(false)
    const codeString = codeBlock.code ?? ''
    useEffect(() => {
        // 当选区改变需要重置
        setShowAll(false)
    }, [codeString, codeType])
    const lines = useMemo(() => codeString.match(/\n/g)?.length ?? 0, [codeString])
    const showLines = useMemo(() => {
        if (showAll) {
            return lines
        }
        return Math.min(MAX_LINES, lines)
    }, [lines, showAll])
    const overLength = lines > showLines
    const __html = useMemo(() => {
        const splitedCodeString = codeString.split(/\n/).slice(0, showLines).join('\n')
        const str = hljs.highlight(splitedCodeString, { language: codeBlock.lang! }).value
        if (codeBlock.lang == 'css') {
            // css 代码需要再处理一下
            // 获取所有的颜色 包裹 hex 三位到六位 和 rgab
            const colors =
                splitedCodeString.match(/#([0-9A-Fa-f]{3,6})|rgba\(\d+\.?\d*, \d+\.?\d*, \d+\.?\d*, \d+\.?\d*\)/g) ?? []
            let i = 0
            return str
                .replace(/: ([A-Za-z]+);/g, ': <span class="hljs-css-value">$1</span>;')
                .replace(/\(--(.*?),\s/g, '(<span class="hljs-css-value">--$1</span>, ')
                .replace(
                    /(#[0-9A-Fa-f]{3,6}|rgba)/g,
                    (string) =>
                        `<span data-testid="hljs-css-color-block" style="background: ${
                            colors[i++]
                        }" class="hljs-css-color-block"></span>${string}`
                )
        } else if (codeType == Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose) {
            // 首先提取compose 中 的 十六进制 hex 颜色 0xFF34C614
            const reg = /0x[0-9A-Fa-f]{8}/g
            const colors = splitedCodeString.match(reg) ?? []
            // 16进制hex 转 rgba
            const rgbaColors = colors.map((color) => {
                return `rgba(${parseInt(color.slice(4, 6), 16)}, ${parseInt(color.slice(6, 8), 16)}, ${parseInt(
                    color.slice(8, 10),
                    16
                )}, ${parseInt(color.slice(2, 4), 16) / 255})`
            })
            let i = 0
            return str.replace(reg, (string) => {
                return `<span data-testid="hljs-css-color-block" style="background: ${
                    rgbaColors[i++]
                }" class="hljs-css-color-block"></span>${string}`
            })
        } else if (codeType == Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI) {
            // swift 中颜色表示为 Color(red: 0.87, green: 0.1, blue: 0.1).opacity(0.2) 或者 Color(red: 0.87, green: 0.1, blue: 0.1)
            const reg =
                /Color\(red:\s*(0\.\d+),\s*green:\s*(0\.\d+),\s*blue:\s*(0\.\d+)\)(?:\.opacity\((0\.\d+|\d)\))?/g
            const colors = splitedCodeString.match(reg) ?? []
            const rgbaColors = colors.map((color) => {
                const list = color.match(/0\.\d+|\d/g) ?? []
                return `rgba(${+(list[0] ?? 0) * 255}, ${+(list[1] ?? 0) * 255}, ${+(list[2] ?? 0) * 255}, ${
                    isNil(list[3]) ? 1 : +list[3]
                })`
            })
            let i = 0
            return str.replace(/<span class="hljs-title class_">Color<\/span>\(/g, (string) => {
                return `<span data-testid="hljs-css-color-block" style="background: ${
                    rgbaColors[i++]
                }" class="hljs-css-color-block"></span>${string}`
            })
        } else {
            return str
        }
    }, [codeBlock.lang, codeString, codeType, showLines])
    const onEsc = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            window.getSelection()?.removeAllRanges?.()
        }
    }, [])
    const codeStyle = useMemo(() => {
        if (codeType === Wukong.DocumentProto.DevModeInspectCodeType.Android_XML) {
            return styles.xml
        }
        if (codeType === Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit) {
            return styles.ui_kit
        }
        if (codeType === Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS) {
            if (codeBlock.lang == 'xml') {
                return styles.xml
            }
            return styles.css
        }
        if (
            codeType === Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI ||
            codeType === Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose
        ) {
            return styles.swift_ui_compose
        }
    }, [codeBlock.lang, codeType])
    const [isHover, setIsHover] = useState(false)
    if (!codeString) {
        return <></>
    }
    return (
        <div
            className={classNames('mt-10px bg-$wk-v2-fill-color-gray-1 group', styles.code_text)}
            data-testid={CODE_BLOCK_CONFIG[codeType].testId}
            tabIndex={-1}
            onKeyDown={onEsc}
        >
            {/* title */}
            <div
                className={classNames(
                    'py-2 wk-text-12 px-3 flex gap-2 justify-between items-center bg-$wk-v2-gray-color-2 b-b border-color-$wk-v2-stroke-color-gray-3',
                    codeBlock.isTextSegment ? 'wk-font-medium' : 'wk-font-semibold'
                )}
            >
                <span className="truncate flex">
                    {codeBlock.isTextSegment ? (
                        <>
                            <span>&quot;</span>
                            <span className="truncate">{codeBlock.title}</span>
                            <span>&quot;</span>
                        </>
                    ) : (
                        codeBlock.title
                    )}
                </span>
                <WKTextButton
                    onClick={() => {
                        execCommandCopy(codeString)
                        WKToast.show(translation('CopiedToClipboard'))
                    }}
                    data-testid="copy-code-button"
                    className={'!hidden group-hover:!inline-flex shrink-0'}
                    onMouseOver={() => {
                        setIsHover(true)
                    }}
                    onMouseLeave={() => {
                        setIsHover(false)
                    }}
                    type="primary"
                    size={12}
                >
                    {translation('Copy')}
                </WKTextButton>
            </div>
            <div className={classNames('flex', isHover && styles['hover-but'])}>
                {/* 行数 */}
                <div
                    className={classNames(
                        'w-7 box-border shrink-0 py-2 text-center b-r border-color-$wk-v2-stroke-color-gray-3',
                        isHover ? 'bg-#F1F9F2' : 'bg-$wk-v2-gray-color-2'
                    )}
                >
                    {new Array(showLines).fill(0).map((_, i) => (
                        <div key={i}>{i + 1}</div>
                    ))}
                </div>
                {/* 代码 */}
                <Scrollbar autoHeight autoHeightMax={99999}>
                    <div
                        dangerouslySetInnerHTML={{ __html }}
                        data-testid="code-area"
                        className={classNames(styles['code-area'], codeStyle)}
                    />
                </Scrollbar>
            </div>
            {overLength && (
                <div
                    data-testid="code-block-show-more"
                    onClick={() => setShowAll(true)}
                    className="p-2 bg-$wk-v2-gray-color-2 b-t border-color-$wk-v2-stroke-color-gray-3 flex items-center color-$wk-v2-label-color-gray-8
                        hover:color-$wk-v2-label-color-gray-13"
                >
                    <MonoIconCommonMore16 className="mr-2" />
                    <span>{translation('Show', { count: lines - showLines })}</span>
                </div>
            )}
        </div>
    )
}
