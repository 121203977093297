import { GET, POST, PUT } from '@tutor/network-core'
import { Wukong } from '@wukong/bridge-proto'
import { assign, chunk } from 'lodash-es'
import pako from 'pako'
import { generateUniqString } from '../../../../util/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { LibraryId } from '../interface/component-style'
import type {
    CheckComponentMovementRequest,
    CheckComponentMovementResponse,
    CreateLibraryRequest,
    LibraryContentBaseInfoVO,
    LibraryContentVO,
    LibraryIdAndNameVO,
    LibraryPublishScope,
    LibraryQueryResponseWithoutDocId,
    LibraryVO,
    UpdateLibraryContentRequestV4,
    UpdateLibraryRequest,
} from '../interface/library'
import { AILibraryCredentialsVO, LibraryCredentialsVO } from '../interface/oss'
import { DocID, FolderID, ResourceType, TeamID } from '../interface/type'
import { featureSwitchManager } from '../switch'
import { omitByValue } from '../util/omit-by-value'
import { transformLibraryContentVO, transformLibraryQueryResponse } from '../util/transform-library-data-struct'
import { BaseCommonRequest } from './base-request'
import { CloudAuthClient } from './cloud-auth-client'
export interface ImportLibraryInfo {
    teamId: string
    importLibraryRelations: {
        id: number
        teamId: string
        publishFile: string
        docId: string
        docName: string
        docExist: boolean
        styleKeys: string[]
        componentKeys: string[]
        componentSetKeys: string[]
        teamName?: string
        folderName?: string
    }[]
}

export interface OrganizationImportLibraryRelationVO {
    id: number
    publishFile: string
    styleKeys: string[]
    componentKeys: string[]
    componentSetKeys: string[]
    docId: string
    docName: string
    docExist: boolean
    teamName?: string
    folderName?: string
}
export interface UserLatestImportLibraryVO {
    latestImportedLibraryTeamId: string
    latestImportedLibraryTeamName: string
    latestImportedLibraryFolderId: string
    latestImportedLibraryFolderName: string
}
export interface OrganizationImportLibraryVO {
    orgId: string
    importLibraryRelations: OrganizationImportLibraryRelationVO[]
    userLatestImportLibrary: UserLatestImportLibraryVO
}

/**
 * 批量获取组件库内容，包括组件库中的组件信息和样式信息
 */
@POST
class _GetLibraryContentBaseInfoMap extends BaseCommonRequest<Record<LibraryId, LibraryContentBaseInfoVO>> {
    constructor(private readonly ids: LibraryId[], private readonly thisDocId: DocID) {
        super()
    }

    public override requestBody() {
        return this.ids
    }

    public override requestArgument() {
        return { thisDocId: this.thisDocId }
    }

    public override requestUrl() {
        return `libraries/content/by-ids/v2`
    }
}

export class GetLibraryContentBaseInfoMap {
    constructor(private readonly ids: LibraryId[], private readonly thisDocId: DocID) {}

    public async start(): Promise<Record<LibraryId, LibraryContentBaseInfoVO>> {
        return assign(
            {},
            ...(await Promise.all(
                chunk(this.ids, 100).map((ids) => new _GetLibraryContentBaseInfoMap(ids, this.thisDocId).start())
            ))
        )
    }

    public async startWithSignal(signal: TraceableAbortSignal): Promise<Record<LibraryId, LibraryContentBaseInfoVO>> {
        return assign(
            {},
            ...(await Promise.all(
                chunk(this.ids, 100).map((ids) =>
                    new _GetLibraryContentBaseInfoMap(ids, this.thisDocId).startWithSignal(signal)
                )
            ))
        )
    }
}

export class GetLibraryContentMap {
    constructor(private readonly ids: LibraryId[], private readonly thisDocId: DocID) {}

    public async start() {
        const { libraryPath, libraryCredentials, bucket, region } = await new GetLibraryCredentialsRequest().start()

        const ossClient = new CloudAuthClient({
            dirPath: libraryPath,
            bucket,
            region,
            credentials: libraryCredentials,
        })

        const baseInfoMap = await new GetLibraryContentBaseInfoMap(this.ids, this.thisDocId).start()

        const libraryContentMap: Record<LibraryId, LibraryContentVO> = {}

        for (const entries of chunk(Object.entries(baseInfoMap), 20)) {
            await Promise.all(
                entries.map(async ([libraryId, baseInfo]) => {
                    const metaInfo = await (await ossClient.fetchFileByPath(baseInfo.resourceId)).json()
                    if (metaInfo) {
                        libraryContentMap[libraryId] = transformLibraryContentVO(baseInfo, metaInfo)
                    }
                })
            )
        }

        return libraryContentMap
    }
}

/**
 * 工作台批量获取组件库内容，包括组件库中的组件信息和样式信息
 */
@POST
class _GetDefaultLibraryContentBaseInfoMap extends BaseCommonRequest<Record<LibraryId, LibraryContentBaseInfoVO>> {
    constructor(
        private readonly resourceId: TeamID | FolderID,
        private readonly resourceType: ResourceType,
        private readonly ids: LibraryId[]
    ) {
        super()
    }

    public override requestBody() {
        return this.ids
    }

    public override requestArgument() {
        return { resourceId: this.resourceId, resourceType: this.resourceType }
    }

    public override requestUrl() {
        return `libraries/default/content/by-ids/v2`
    }
}

export class GetDefaultLibraryContentBaseInfoMap {
    constructor(
        private readonly resourceId: TeamID | FolderID,
        private readonly resourceType: ResourceType,
        private readonly ids: LibraryId[]
    ) {}
    public async start(): Promise<Record<LibraryId, LibraryContentBaseInfoVO>> {
        return assign(
            {},
            ...(await Promise.all(
                chunk(this.ids, 100).map((ids) =>
                    new _GetDefaultLibraryContentBaseInfoMap(this.resourceId, this.resourceType, ids).start()
                )
            ))
        )
    }
}

export class GetDefaultLibraryContentMap {
    constructor(
        private readonly resourceId: TeamID | FolderID,
        private readonly resourceType: ResourceType,
        private readonly ids: LibraryId[]
    ) {}

    public async start() {
        const ossClient = await new GetLibraryCredentialsRequest().start().then(
            ({ libraryPath, libraryCredentials, bucket, region }) =>
                new CloudAuthClient({
                    dirPath: libraryPath,
                    bucket,
                    region,
                    credentials: libraryCredentials,
                })
        )

        const baseInfoMap = await new GetDefaultLibraryContentBaseInfoMap(
            this.resourceId,
            this.resourceType,
            this.ids
        ).start()

        const libraryContentMap: Record<LibraryId, LibraryContentVO> = {}

        for (const entries of chunk(Object.entries(baseInfoMap), 20)) {
            await Promise.all(
                entries.map(async ([libraryId, baseInfo]) => {
                    const metaInfo = await (await ossClient.fetchFileByPath(baseInfo.resourceId)).json()

                    if (metaInfo) {
                        libraryContentMap[libraryId] = transformLibraryContentVO(baseInfo, metaInfo)
                    }
                })
            )
        }

        return libraryContentMap
    }
}

/**
 * 查询组件库信息，条件非正交。根据组件库的共享范围进行了筛选
 */
@GET
class _GetLibraryQuery extends BaseCommonRequest<LibraryQueryResponseWithoutDocId> {
    constructor(
        private readonly thisDocId: DocID,
        private readonly keyword?: string,
        private readonly isAiInspection = false // AI 设计检查 playground 文件内查询组件库时服务端需区别查询来源
    ) {
        super()
    }

    public override requestArgument() {
        return {
            thisDocId: this.thisDocId,
            ...(this.keyword ? { keyword: this.keyword } : {}),
            isAiInspection: this.isAiInspection.toString(),
        }
    }

    public override requestUrl() {
        return `libraries/query`
    }
}

export class GetLibraryQuery {
    constructor(
        private readonly thisDocId: DocID,
        private readonly keyword?: string,
        private readonly isAiInspection = false
    ) {}

    public async start() {
        const libraryQueryResponse = await new _GetLibraryQuery(
            this.thisDocId,
            this.keyword,
            this.isAiInspection
        ).start()
        return transformLibraryQueryResponse(libraryQueryResponse)
    }

    public async startWithSignal(single: TraceableAbortSignal) {
        const libraryQueryResponse = await new _GetLibraryQuery(
            this.thisDocId,
            this.keyword,
            this.isAiInspection
        ).startWithSignal(single)
        return transformLibraryQueryResponse(libraryQueryResponse)
    }
}

/**
 * 工作台查询团队/草稿箱可订阅的组件库信息，条件非正交。根据组件库的共享范围进行了筛选
 */
@GET
class _GetDefaultLibraryQuery extends BaseCommonRequest<LibraryQueryResponseWithoutDocId> {
    constructor(
        private readonly resourceId: TeamID | FolderID,
        private readonly resourceType: ResourceType,
        private readonly keyword?: string
    ) {
        super()
    }

    public override requestArgument() {
        return {
            resourceId: this.resourceId,
            resourceType: this.resourceType,
            ...(this.keyword ? { keyword: this.keyword } : {}),
        }
    }

    public override requestUrl() {
        return `libraries/default/query`
    }
}

export class GetDefaultLibraryQuery {
    constructor(
        private readonly resourceId: TeamID | FolderID,
        private readonly resourceType: ResourceType,
        private readonly keyword?: string
    ) {}

    public async start() {
        const libraryQueryResponse = await new _GetDefaultLibraryQuery(
            this.resourceId,
            this.resourceType,
            this.keyword
        ).start()
        return transformLibraryQueryResponse(libraryQueryResponse)
    }

    public async startWithSignal(single: TraceableAbortSignal) {
        const libraryQueryResponse = await new _GetDefaultLibraryQuery(
            this.resourceId,
            this.resourceType,
            this.keyword
        ).startWithSignal(single)
        return transformLibraryQueryResponse(libraryQueryResponse)
    }
}

/**
 * 更新组件库信息, 只能更新是否共享以及共享的范围
 */
@PUT
export class PutLibrary extends BaseCommonRequest<boolean> {
    constructor(private readonly id: LibraryId, private readonly content: UpdateLibraryRequest) {
        super()
    }

    public override requestBody() {
        return this.content
    }

    public override requestUrl() {
        return `libraries/${this.id}`
    }
}

/**
 * 创建组件库
 */
@POST
export class PostLibrary extends BaseCommonRequest<LibraryVO> {
    constructor(private readonly content: CreateLibraryRequest) {
        super()
    }

    public override requestBody() {
        return this.content
    }

    public override requestUrl() {
        return `libraries`
    }
}

// 获取组件库 oss 目录临时权限
@GET
export class GetLibraryCredentialsRequest extends BaseCommonRequest<LibraryCredentialsVO> {
    public override requestUrl() {
        return `libraries/auth/credentials/oss`
    }
}

@POST
export class GetAILibraryCredentialsRequest extends BaseCommonRequest<AILibraryCredentialsVO> {
    public override requestUrl() {
        return `ai/library-substitute/auth/credentials/oss`
    }
}

@GET
export class GetOrgImportLibraryInfoRequest extends BaseCommonRequest<OrganizationImportLibraryVO> {
    constructor(private readonly orgId: string) {
        super()
    }

    public override requestUrl(): string {
        return `figma-library-migrate/org/library/${this.orgId}`
    }
}

@GET
export class GetImportLibraryInfoRequest extends BaseCommonRequest<ImportLibraryInfo> {
    constructor(private readonly teamId: string) {
        super()
    }

    public override requestUrl(): string {
        return `figma-library-migrate/library/${this.teamId}`
    }
}

// 获取 docId 对应 组件库 id & 文档名称
@POST
class _GetLibraryIdAndNameMapRequest extends BaseCommonRequest<Record<DocID, LibraryIdAndNameVO>> {
    constructor(private readonly docIds: DocID[], private readonly thisDocId: DocID | undefined) {
        super()
    }

    public override requestArgument() {
        return this.thisDocId ? ({ thisDocId: this.thisDocId } as Record<string, string>) : {}
    }

    public override requestBody() {
        return this.docIds
    }

    public override requestUrl() {
        return `libraries/by-doc-ids`
    }
}

export class GetLibraryIdAndNameMapRequest {
    constructor(private readonly docIds: DocID[], private readonly thisDocId: DocID | undefined) {}

    public async start(): Promise<Record<DocID, LibraryIdAndNameVO>> {
        return assign(
            {},
            ...(await Promise.all(
                chunk(this.docIds, 100).map((docIds) =>
                    new _GetLibraryIdAndNameMapRequest(docIds, this.thisDocId).start()
                )
            ))
        )
    }

    public async startWithSignal(signal: TraceableAbortSignal) {
        return assign(
            {},
            ...(await Promise.all(
                chunk(this.docIds, 100).map((docIds) =>
                    new _GetLibraryIdAndNameMapRequest(docIds, this.thisDocId).startWithSignal(signal)
                )
            ))
        )
    }
}

@GET
export class GetLibraryIdAndNameByDocIdRequest {
    constructor(private readonly docId: DocID, private readonly thisDocId: DocID) {}

    public start = async (): Promise<undefined | LibraryIdAndNameVO> => {
        if (!this.docId) {
            return undefined
        }
        return (await new GetLibraryIdAndNameMapRequest([this.docId], this.thisDocId).start())[this.docId]
    }

    public startWithSignal = async (signal: TraceableAbortSignal) => {
        if (!this.docId) {
            return undefined
        }
        return (await new GetLibraryIdAndNameMapRequest([this.docId], this.thisDocId).startWithSignal(signal))[
            this.docId
        ]
    }
}

// 检查组件移动，范围是文档中的剪切过来的组件（在剪贴后和打开发布面板时各请求一次）
@POST
export class CheckComponentMovement extends BaseCommonRequest<CheckComponentMovementResponse> {
    constructor(private readonly body: CheckComponentMovementRequest, private thisDocId: DocID) {
        super()
    }

    public override requestBody() {
        return this.body
    }

    public override requestArgument() {
        return { thisDocId: this.thisDocId }
    }

    public override requestUrl() {
        return `libraries/check-movement`
    }
}

/**
 * @deprecated 开关 library-publish 下线后请使用 UpdateLibraryContent 代替
 * 更新组件库内容的变化
 */
@PUT
export class PutLibraryContentV4 extends BaseCommonRequest<void> {
    constructor(private readonly content: UpdateLibraryContentRequestV4) {
        super()
    }

    public override requestBody() {
        const { requestContent, manifest } = this.content
        const formData = new FormData()
        formData.append('requestContent', new Blob([pako.deflate(JSON.stringify(requestContent))]))
        const publishedVariableCollections = manifest.publishedVariableCollections.map((vo) => {
            return {
                ...vo,
                modes: JSON.stringify(vo.modes),
            }
        })
        const updatedVariableCollections = manifest.updatedVariableCollections.map((vo) => {
            return {
                ...vo,
                modes: JSON.stringify(vo.modes),
            }
        })
        const publishedVariables = manifest.publishedVariables.map((vo) => {
            const stringifiedValue: Record<string, any> = {}
            Object.keys(vo.value).forEach((key) => {
                const value = vo.value[key as keyof Wukong.DocumentProto.IVariableDataLite]
                switch (value.resolvedDataType) {
                    case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                        stringifiedValue[key] = {
                            colorValue: value.colorValue,
                            resolvedDataType: value.resolvedDataType,
                        }
                        break
                    case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                        if (featureSwitchManager.isEnabled('float-variable')) {
                            stringifiedValue[key] = {
                                floatValue: value.floatValue,
                                resolvedDataType: value.resolvedDataType,
                            }
                        }
                        break
                    // [how_to_support_new_type_of_variable]: 新增可发布的变量数据
                    default:
                        break
                }
            })
            return {
                ...vo,
                value: JSON.stringify(stringifiedValue),
                scope: JSON.stringify(vo.scope),
            }
        })
        const updatedVariables = manifest.updatedVariables.map((vo) => {
            const stringifiedValue: Record<string, any> = {}
            Object.keys(vo.value).forEach((key) => {
                const value = vo.value[key as keyof Wukong.DocumentProto.IVariableDataLite]
                switch (value.resolvedDataType) {
                    case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                        stringifiedValue[key] = {
                            colorValue: value.colorValue,
                            resolvedDataType: value.resolvedDataType,
                        }
                        break
                    case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                        if (featureSwitchManager.isEnabled('float-variable')) {
                            stringifiedValue[key] = {
                                floatValue: value.floatValue,
                                resolvedDataType: value.resolvedDataType,
                            }
                        }
                        break
                    // [how_to_support_new_type_of_variable]: 新增可发布的变量数据
                    default:
                        break
                }
            })
            return {
                ...vo,
                value: JSON.stringify(stringifiedValue),
                scope: JSON.stringify(vo.scope),
            }
        })
        formData.append(
            'manifest',
            JSON.stringify({
                ...manifest,
                publishedVariableCollections,
                publishedVariables,
                updatedVariableCollections,
                updatedVariables,
            })
        )
        return formData
    }

    public override requestArgument() {
        return {
            compressTypeEnum: 'ZLIB',
        }
    }

    public override requestUrl() {
        return `libraries/${this.content.manifest.libraryId!}/content-changes-v4`
    }
}

/**
 * 更新组件库元数据内容
 */
@PUT
export class UpdateLibraryContent extends BaseCommonRequest<void> {
    constructor(
        private readonly libraryId: LibraryId,
        private readonly manifest: Wukong.DocumentProto.IArg_publishLibraryManifest
    ) {
        super()
    }

    public override requestBody() {
        return {
            ...this.manifest,
            // TODO(@lisw) 2025.6.1 后考虑下掉 uniqKey（新流程发布无分片，无需此字段，libraryOperaion.id 就可满足）
            uniqKey: generateUniqString(),
        }
    }

    public override requestUrl() {
        return `libraries/${this.libraryId}/content-changes-v5`
    }
}

@GET
export class GetLibraryShareScope extends BaseCommonRequest<LibraryPublishScope> {
    constructor(private readonly docId: DocID) {
        super()
    }

    public override requestUrl() {
        return '/libraries/publish-scope'
    }

    public override requestArgument() {
        return omitByValue({
            thisDocId: this.docId,
        })
    }
}
