import { translation } from './text.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { useUnmount, useUpdateEffect } from 'react-use'
import {
    DraggablePopupV2,
    IconAdd16,
    IconAlignBottom,
    IconAlignTop,
    IconAlignVerticalCenter,
    IconAutoWidthHorizontal,
    IconLineheight2,
    IconLoading16,
    IconMote,
    IconStyle,
    IconTextAlignCenter,
    IconTextAlignLeft,
    IconTextAlignRight,
    IconTextResizeFixed,
    IconTextResizeHeight,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { PopupStateType, TextAlignHorizontal, TextAlignVertical, TextAutoResize } from '../../../../document/node/node'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'
import { WKFrogTask } from '../../../../share/wk-frog-task'
import { useViewState } from '../../../../view-state-bridge'
import { usePosition } from '../../../utils/use-position'
import { IconButton } from '../../atom/button/icon-button'
import { SelectIconGroup } from '../../atom/button/select-button-group'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputLetterSpacing } from '../../atom/inputs/scrubbable-input-letter-spacing'
import { ScrubbableInputLineHeight } from '../../atom/inputs/scrubbable-input-line-height'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { Title } from '../../atom/title/title'
import { TextComponentPropPill } from '../../design-panel-v2/component-instance-panel/components/component-prop-pill'
import { ComponentPropDragPopup } from '../../design-panel-v2/component-instance-panel/components/component-prop-popup'
import styleFills from '../fills/fills.module.less'
import { StylePaint } from '../styles/style-paint/style-paint'
import { FontSizeSelect } from '../styles/style-panel/text-style-panel/font-size-select'
import { StyleText } from '../styles/style-text/style-text'
import { createITextStyle } from '../styles/style-text/style-text-utils'
import { TextStyleThumbnail } from '../styles/style-text/style-thumbnail'
import { FontSelectInput } from './font/font-select-input'
import { FontStyleSelect } from './font/font-style-select/font-style-select'
import { useFontLoading } from './hooks/use-font-loading'
import { useStyleNodeFontLoading } from './hooks/use-style-node-font-loading'
import { TextPropArea } from './text-prop-area'
import { TextSetting } from './text-setting'
import styles from './text.module.less'
import { CustomAxesStyleInfo, useFontStyleOptionList } from './use-font-style-option-list'
import { useText } from './use-text'
import { useTextStyle } from './use-text-style'

export function Text() {
    const {
        onClickMote,
        isOpen,

        onChangeLetterSpacing,
        onChangeLineHeight,
        onChangeParagraphSpacing,
        onChangeFontStyle,
        onChangeFontStyleV2,
        onPresetFontStyle,
        onChangeFontFamily,
        onPresetFontFamily,
        onChangeFontSize,
        onPresetFontSize,
        onChangeAutoResize,
        onChangeTextAlignHorizontal,
        onChangeTextAlignVertical,
        onSelectVariableSetting,

        selectionTextState,
        hasTextInSelection,
        paragraphSpacing,
        textAutoResize,
        textAlignHorizontal,
        textAlignVertical,

        fontSize,
        lineHeight,
        lineHeightAutoValue,
        letterSpacing,

        selectedFamily,
        selectedLocalizedFamily,
        selectedFontCustomAxes,
        selectedFontName,
        isFamilyMixed,
        isFontNameMixed,
        variationInfo,
        customStyleState,

        recentFonts,
        availableFontInfo,
        missFontFamily,
        missFontStyle,
    } = useText()

    const { position, reactRef } = usePosition({ isOpen })

    const {
        openStyle,
        stylePosition,
        selectTextStyleNodeInfo,
        isMissStyleFamily,
        onClickStyle,
        onChangeStyle,
        onClickCutLink,
        onClickAdd,
    } = useTextStyle()

    const popupType = useViewState('popupState')
    const textAreaPopupVisible =
        popupType?.type === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA
    const { position: propPopupPosition, reactRef: containerRef } = usePosition({ isOpen: textAreaPopupVisible })

    const onClickTextAlignVerticalJsxIcon = useCallback(
        (value: TextAlignVertical) => {
            onChangeTextAlignVertical(value)
        },
        [onChangeTextAlignVertical]
    )

    const textAlignOptionValue = useMemo(() => {
        return [TextAlignVertical.Top, TextAlignVertical.Center, TextAlignVertical.Bottom]
    }, [])

    const fontLoading = useFontLoading({
        isStyleNodeOrMixed: !!selectTextStyleNodeInfo.mixed || !!selectTextStyleNodeInfo.textStyleNode,
        isFamilyMixed,
        isFontNameMixed,
        missFontFamily,
        missFontStyle,
        selectedFamily,
        selectedFontName,
    })

    const styleNodeFontLoading = useStyleNodeFontLoading(
        selectTextStyleNodeInfo.mixed || isMissStyleFamily
            ? null
            : selectTextStyleNodeInfo.textStyleNode?.fontName ?? null
    )

    const textAlignVerticalJsx = useCallback(() => {
        return (
            <SelectIconGroup
                disabled={missFontStyle}
                optionValue={textAlignOptionValue}
                onClickIcon={onClickTextAlignVerticalJsxIcon}
            >
                <Tooltip title={translation('AlignTop')}>
                    <SelectIconGroup.Item
                        value={TextAlignVertical.Top}
                        icon={<IconAlignTop />}
                        selected={
                            textAlignVertical.value === Wukong.DocumentProto.TextAlignVertical.TEXT_ALIGN_VERTICAL_TOP
                        }
                        dataTestId="alignV-top"
                    />
                </Tooltip>
                <Tooltip title={translation('AlignMiddle')}>
                    <SelectIconGroup.Item
                        value={TextAlignVertical.Center}
                        icon={<IconAlignVerticalCenter />}
                        selected={
                            textAlignVertical.value ===
                            Wukong.DocumentProto.TextAlignVertical.TEXT_ALIGN_VERTICAL_CENTER
                        }
                        dataTestId="alignV-center"
                    />
                </Tooltip>
                <Tooltip title={translation('AlignBottom')}>
                    <SelectIconGroup.Item
                        value={TextAlignVertical.Bottom}
                        icon={<IconAlignBottom />}
                        selected={
                            textAlignVertical.value ===
                            Wukong.DocumentProto.TextAlignVertical.TEXT_ALIGN_VERTICAL_BOTTOM
                        }
                        dataTestId="alignV-bottom"
                    />
                </Tooltip>
            </SelectIconGroup>
        )
    }, [missFontStyle, textAlignVertical.value, textAlignOptionValue, onClickTextAlignVerticalJsxIcon])

    const onClickTextAutoResizeJsxIcon = useCallback(
        (value: TextAutoResize) => {
            onChangeAutoResize(value)
        },
        [onChangeAutoResize]
    )

    const textAutoResizeOptionValue = useMemo(() => {
        return [TextAutoResize.WidthAndHeight, TextAutoResize.Height, TextAutoResize.None]
    }, [])

    const textAutoResizeJsx = useCallback(() => {
        return (
            <SelectIconGroup
                disabled={missFontStyle}
                optionValue={textAutoResizeOptionValue}
                onClickIcon={onClickTextAutoResizeJsxIcon}
            >
                <Tooltip title={translation('AutoWidth')}>
                    <SelectIconGroup.Item
                        value={TextAutoResize.WidthAndHeight}
                        icon={<IconAutoWidthHorizontal />}
                        selected={
                            textAutoResize.value ===
                            Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_WIDTH_AND_HEIGHT
                        }
                        dataTestId="autoResize-widthAndHeight"
                    />
                </Tooltip>
                <Tooltip title={translation('AutoHeight')}>
                    <SelectIconGroup.Item
                        value={TextAutoResize.Height}
                        icon={<IconTextResizeHeight />}
                        selected={textAutoResize.value === Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_HEIGHT}
                        dataTestId="autoResize-height"
                    />
                </Tooltip>
                <Tooltip title={translation('FixSize')}>
                    <SelectIconGroup.Item
                        value={TextAutoResize.None}
                        icon={<IconTextResizeFixed />}
                        selected={textAutoResize.value === Wukong.DocumentProto.TextAutoResize.TEXT_AUTO_RESIZE_NONE}
                        dataTestId="autoResize-none"
                    />
                </Tooltip>
            </SelectIconGroup>
        )
    }, [missFontStyle, textAutoResize.value, textAutoResizeOptionValue, onClickTextAutoResizeJsxIcon])

    const onClickTextAlignHorizontalJsxIcon = useCallback(
        (value: TextAlignHorizontal) => {
            onChangeTextAlignHorizontal(value)
        },
        [onChangeTextAlignHorizontal]
    )
    const textAlignHorizontalOptionValue = useMemo(() => {
        return [TextAlignHorizontal.Left, TextAlignHorizontal.Center, TextAlignHorizontal.Right]
    }, [])

    const useUnmountLog = useCallback(() => {
        if (openStyle) {
            WKFrogTask.textStyle.textStylePanel(true)
        }
    }, [openStyle])

    useUpdateEffect(() => {
        if (openStyle) {
            WKFrogTask.textStyle.exposeTextStylePanel()
        } else {
            WKFrogTask.textStyle.textStylePanel()
        }
    }, [openStyle])

    useUnmount(useUnmountLog)

    const fontStyleOptionList = useFontStyleOptionList({
        fontInfo: availableFontInfo,
        selectedFontName,
        customAxesStyle: selectedFontCustomAxes?.customAxesStyle ?? '',
        showVariantsSetting: variationInfo.hasValue,
        stylesInfo: customStyleState,
    })

    const styleValue: CustomAxesStyleInfo = useMemo(() => {
        return {
            style: selectedFontName?.style ?? '',
            customAxesStyle: { name: selectedFontCustomAxes?.customAxesStyle ?? '' },
            isMissFontCustomStyle: missFontStyle,
        }
    }, [missFontStyle, selectedFontCustomAxes?.customAxesStyle, selectedFontName?.style])

    const styleLabel = useMemo(() => {
        return selectedFontCustomAxes?.customAxesStyleDisplay.length
            ? selectedFontCustomAxes?.customAxesStyleDisplay
            : selectedFontName?.localizedStyle
    }, [selectedFontCustomAxes?.customAxesStyleDisplay, selectedFontName?.localizedStyle])

    return hasTextInSelection ? (
        <div className={'pt-8px pb-12px'} data-testid="design-panel-text" ref={containerRef}>
            <Title>
                <Title.Left>{translation('Text')}</Title.Left>
                <Title.Right>
                    {!selectTextStyleNodeInfo.mixed && selectTextStyleNodeInfo.textStyleNode ? null : (
                        <Tooltip title={translation('Style')}>
                            <IconButton
                                icon={<IconStyle />}
                                selected={openStyle}
                                deepColor
                                onClick={onClickStyle}
                                data-testid={'add-text-style'}
                            />
                        </Tooltip>
                    )}
                    {selectTextStyleNodeInfo.mixed ? (
                        <IconButton icon={<IconAdd16 />} selected={false} onClick={onClickAdd} />
                    ) : null}
                </Title.Right>
            </Title>
            {selectTextStyleNodeInfo.mixed ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={55} className={styleFills.mixedTipText}>
                        {translation('Click')} + {translation('ToReplaceMixed')}
                    </SingleGrid.Item>
                </SingleGrid>
            ) : selectTextStyleNodeInfo.textStyleNode ? (
                <StylePaint
                    type="text"
                    name={selectTextStyleNodeInfo.textStyleNode.name}
                    description={selectTextStyleNodeInfo.textStyleNode.description}
                    isLoading={styleNodeFontLoading}
                    thumbnail={
                        styleNodeFontLoading ? (
                            <IconLoading16 className={`animate-spin ${styles.loadingIcon}`}></IconLoading16>
                        ) : (
                            <TextStyleThumbnail
                                styleData={createITextStyle(selectTextStyleNodeInfo.textStyleNode)}
                                width={16}
                                height={16}
                                textStyleId={selectTextStyleNodeInfo.textStyleNode.id}
                            />
                        )
                    }
                    isOpen={openStyle}
                    onClickThumbnailName={onClickStyle}
                    onClickCutLink={() => onClickCutLink(selectTextStyleNodeInfo.textStyleNode?.id ?? '')}
                />
            ) : (
                <>
                    <FontSelectInput
                        selectedFamily={selectedFamily}
                        selectedLocalizedFamily={selectedLocalizedFamily}
                        missIcon={missFontFamily}
                        loadingIcon={!missFontFamily && fontLoading.type == 'font-family'}
                        recentFonts={recentFonts}
                        onChange={onChangeFontFamily}
                        onPreselectFont={onPresetFontFamily}
                        searchStringBelongTo={'attribute-panel-font-family'}
                    ></FontSelectInput>
                    <SingleGrid>
                        <SingleGrid.Item start={5} span={22}>
                            <FontStyleSelect
                                availableFontInfo={availableFontInfo}
                                isFontNameMixed={isFontNameMixed}
                                styleValue={styleValue}
                                disabled={isFamilyMixed || missFontFamily}
                                missFontStyle={missFontStyle}
                                fontStyleOptionList={fontStyleOptionList}
                                styleLabel={styleLabel}
                                fontStyleLoading={fontLoading.type == 'font-style'}
                                onChangeFontStyle={onChangeFontStyleV2}
                                onSelectVariableSetting={onSelectVariableSetting}
                                onPreselectFontStyle={onPresetFontStyle}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={29} span={22}>
                            <FontSizeSelect
                                isMixed={fontSize.mixed}
                                disabled={missFontStyle}
                                value={fontSize.value}
                                optionWidth={88}
                                onChange={onChangeFontSize}
                                onPreselectFontSize={onPresetFontSize}
                                dataTestIds={{
                                    fontSizeInput: 'font-size-input',
                                    fontSizeSelect: 'font-size-select',
                                    fontSizeSelectAddOption: 'font-size-select-add-option',
                                }}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                    <SingleGrid>
                        <Tooltip title={translation('LineHeight')}>
                            <SingleGrid.Item start={5} span={22}>
                                <ScrubbableInputLineHeight
                                    disabled={missFontStyle}
                                    isMixed={lineHeight.mixed}
                                    value={lineHeight.value}
                                    autoValue={lineHeightAutoValue}
                                    onChange={onChangeLineHeight}
                                    testId="lineHeight-input"
                                />
                            </SingleGrid.Item>
                        </Tooltip>
                        <Tooltip title={translation('LetterSpacing')}>
                            <SingleGrid.Item start={29} span={22}>
                                <ScrubbableInputLetterSpacing
                                    disabled={missFontStyle}
                                    isMixed={letterSpacing.mixed}
                                    value={letterSpacing.value}
                                    onChange={onChangeLetterSpacing}
                                    testId="letterSpacing-input"
                                />
                            </SingleGrid.Item>
                        </Tooltip>
                    </SingleGrid>
                    <SingleGrid>
                        <Tooltip title={translation('ParagraphSpacing')}>
                            <SingleGrid.Item start={5} span={22}>
                                <ScrubbableInputNumber
                                    disabled={missFontStyle}
                                    icon={<IconLineheight2 />}
                                    isMixed={paragraphSpacing?.mixed}
                                    value={
                                        typeof paragraphSpacing?.value === 'number' ? paragraphSpacing.value : undefined
                                    }
                                    onChange={onChangeParagraphSpacing}
                                    scrubbingDisabled={paragraphSpacing?.mixed}
                                    min={0}
                                    testId="paragraphSpacing-input"
                                />
                            </SingleGrid.Item>
                        </Tooltip>
                        <SingleGrid.Item start={29} span={22}>
                            {textAutoResizeJsx()}
                        </SingleGrid.Item>
                    </SingleGrid>
                </>
            )}
            <div>
                <SingleGrid ref={reactRef}>
                    <SingleGrid.Item start={5} span={22}>
                        <SelectIconGroup
                            disabled={missFontStyle}
                            optionValue={textAlignHorizontalOptionValue}
                            onClickIcon={onClickTextAlignHorizontalJsxIcon}
                        >
                            <Tooltip
                                title={translation('AlignLeft')}
                                shortcut={shortcutLabelMap[ShortcutKey.TextAlignLeft]}
                            >
                                <SelectIconGroup.Item
                                    value={TextAlignHorizontal.Left}
                                    icon={<IconTextAlignLeft />}
                                    selected={
                                        textAlignHorizontal.value ===
                                        Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT
                                    }
                                    onClick={() => onChangeTextAlignHorizontal(TextAlignHorizontal.Left)}
                                    dataTestId="alignH-left"
                                />
                            </Tooltip>
                            <Tooltip
                                title={translation('AlignCenter')}
                                shortcut={shortcutLabelMap[ShortcutKey.TextAlignCenter]}
                            >
                                <SelectIconGroup.Item
                                    value={TextAlignHorizontal.Center}
                                    icon={<IconTextAlignCenter />}
                                    selected={
                                        textAlignHorizontal.value ===
                                        Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER
                                    }
                                    onClick={() => onChangeTextAlignHorizontal(TextAlignHorizontal.Center)}
                                    dataTestId="alignH-center"
                                />
                            </Tooltip>
                            <Tooltip
                                title={translation('AlignRight')}
                                shortcut={shortcutLabelMap[ShortcutKey.TextAlignRight]}
                            >
                                <SelectIconGroup.Item
                                    value={TextAlignHorizontal.Right}
                                    icon={<IconTextAlignRight />}
                                    selected={
                                        textAlignHorizontal.value ===
                                        Wukong.DocumentProto.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT
                                    }
                                    onClick={() => onChangeTextAlignHorizontal(TextAlignHorizontal.Right)}
                                    dataTestId="alignH-right"
                                />
                            </Tooltip>
                        </SelectIconGroup>
                    </SingleGrid.Item>
                    <SingleGrid.Item start={29} span={22}>
                        {textAlignVerticalJsx()}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={54} span={6}>
                        <Tooltip title={translation('MoreSettings')}>
                            <IconButton
                                icon={<IconMote />}
                                selected={!!isOpen}
                                deepColor
                                onClick={onClickMote}
                                dataTestId="more-setting"
                            />
                        </Tooltip>
                    </SingleGrid.Item>
                </SingleGrid>
                <DraggablePopupV2
                    visible={isOpen}
                    position={position}
                    onCancel={onClickMote}
                    styleType="editor"
                    footer={null}
                    bodyClassName="p-0"
                    positionRightBase
                    header={translation('TypeSettings')}
                    enableScrollBar={true}
                >
                    <TextSetting
                        disabled={missFontStyle}
                        isStyleMode={selectTextStyleNodeInfo.mixed || !!selectTextStyleNodeInfo.textStyleNode}
                        selectionTextState={selectionTextState}
                    />
                </DraggablePopupV2>
            </div>
            {openStyle ? (
                <StyleText
                    open={true}
                    position={stylePosition}
                    selectStyleId={
                        !selectTextStyleNodeInfo.mixed && selectTextStyleNodeInfo.textStyleNode
                            ? selectTextStyleNodeInfo.textStyleNode.id
                            : undefined
                    }
                    selectTextStyleNode={
                        !selectTextStyleNodeInfo.mixed && selectTextStyleNodeInfo.textStyleNode
                            ? selectTextStyleNodeInfo.textStyleNode
                            : undefined
                    }
                    onChangeStyle={onChangeStyle}
                    isMissFamily={selectTextStyleNodeInfo.textStyleNode ? isMissStyleFamily : missFontStyle}
                />
            ) : null}

            {textAreaPopupVisible && <ComponentPropDragPopup position={propPopupPosition} />}
            <TextPropArea />
            <TextComponentPropPill />
        </div>
    ) : null
}
