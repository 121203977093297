import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { uniq } from 'lodash-es'
import { useCallback, useMemo, useRef, useState } from 'react'
import { InputV2, Scrollbar, Tag, WKButton, wkDialogConfirm } from '../../../../../../ui-lib/src'
import { TagInputRef, TagItem } from '../../../../../../ui-lib/src/components/inputs/tag-input/tag-input'
import { useViewState } from '../../../../view-state-bridge'
import { LocalStorageKey } from '../../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../../web-storage/local-storage/storage'
import { getCommonPropsByNodeType } from './node-common-props'

export const SettingPanel = ({ height }: { height: number }) => {
    const motiffScope = useViewState('motiffScope')
    const tagInputRef = useRef<TagInputRef<PropItem>>(null)

    const [activeType, setActiveType] = useState<Wukong.DocumentProto.NodeType>(
        Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME
    )

    const [query, setQuery] = useState<string>('')
    const optionProps = useMemo(() => {
        if (query === '') {
            return []
        }
        return motiffScope?.allPropNames.filter((name) =>
            name.toLocaleLowerCase().startsWith(query.toLocaleLowerCase())
        )
    }, [motiffScope?.allPropNames, query])

    const allCustomPropsSetting = enhancedLocalStorage.getSerializedItem(LocalStorageKey.MotiffScopePropsSetting)
    const [activeCustomPropsSetting, setActiveCustomPropsSetting] = useState(
        allCustomPropsSetting?.[activeType] ?? getCommonPropsByNodeType(activeType)
    )

    const updateLocalStorage = useCallback(
        (props: string[]) => {
            if (allCustomPropsSetting) {
                allCustomPropsSetting[activeType] = props
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopePropsSetting, allCustomPropsSetting)
            } else {
                enhancedLocalStorage.setSerializedItem(LocalStorageKey.MotiffScopePropsSetting, {
                    [activeType]: props,
                })
            }
        },
        [activeType, allCustomPropsSetting]
    )

    const onActiveTypeChanged = useCallback(
        (type: Wukong.DocumentProto.NodeType) => {
            setActiveType(type)
            setActiveCustomPropsSetting(allCustomPropsSetting?.[type] ?? getCommonPropsByNodeType(type))
        },
        [allCustomPropsSetting]
    )

    const onTagChanged = useCallback(
        (v: TagItem<PropItem>[]) => {
            if (v.length !== 0) {
                const props = uniq([...v.map((item) => item.name as string), ...activeCustomPropsSetting])
                setActiveCustomPropsSetting(props)
                updateLocalStorage(props)
                tagInputRef.current?.deleteAllTags()
            }
        },
        [activeCustomPropsSetting, updateLocalStorage]
    )

    const onTagDelete = useCallback(
        (v: string) => {
            const props = activeCustomPropsSetting.filter((value) => value !== v)
            setActiveCustomPropsSetting(props)
            updateLocalStorage(props)
        },
        [activeCustomPropsSetting, updateLocalStorage]
    )

    const clearAllProps = () => {
        wkDialogConfirm.warning({
            closable: true,
            okText: '确定',
            onOk: () => {
                setActiveCustomPropsSetting([])
                updateLocalStorage([])
            },
            title: '清空该节点类型的常用属性',
            content: '此操作不可撤销，确认清空？',
        })
    }

    return (
        <div className="flex items-center " style={{ height: `${height}px` }}>
            <div className="flex flex-col py-2 h-full box-border">
                <div className="wk-font-medium w-full pl-5 h-8 color-$wk-v2-label-color-gray-11 flex items-center">
                    常用属性
                </div>
                <Scrollbar style={{ width: 128 }}>
                    {Object.entries(NodeTypeRecord).map(([name, type]) => {
                        return (
                            <div key={type} className="pl-2 pr-3">
                                <WKButton
                                    type="text"
                                    className={classNames('w-full justify-start color-$wk-v2-label-color-gray-11', {
                                        'bg-$wk-gray-3 !color-$wk-v2-label-color-gray-13': activeType === type,
                                    })}
                                    onClick={() => onActiveTypeChanged(type as Wukong.DocumentProto.NodeType)}
                                >
                                    {name}
                                </WKButton>
                            </div>
                        )
                    })}
                </Scrollbar>
            </div>

            <div className="w-px h-9/10 bg-$wk-gray-3 " />

            <div className="flex flex-col gap-2 w-full h-full px-2">
                <div className="flex items-center pt-2 gap-2">
                    <InputV2.Tag
                        autoHeight
                        placeholder="添加常用属性"
                        className="flex-1"
                        listProps={{
                            items: optionProps,
                            renderItem: (v) => <OptionPropItem name={v.item} />,
                        }}
                        onChangeValue={setQuery}
                        onChangeTag={onTagChanged}
                        generateTag={(name) => {
                            return {
                                name,
                                color: 'black',
                            }
                        }}
                        ref={tagInputRef}
                    />
                    <WKButton type="secondary" size="medium" onClick={clearAllProps}>
                        一键清理
                    </WKButton>
                </div>

                <Scrollbar>
                    <div className="flex flex-wrap gap-2 pt-1 pb-2">
                        {activeCustomPropsSetting.map((value) => (
                            <Tag
                                key={value}
                                name={value}
                                closable
                                onDelete={() => onTagDelete(value)}
                                className="!ml-0"
                            />
                        ))}
                    </div>
                </Scrollbar>
            </div>
        </div>
    )
}

const NodeTypeRecord: Record<string, Wukong.DocumentProto.NodeType> = {
    Frame: Wukong.DocumentProto.NodeType.NODE_TYPE_FRAME,
    Group: Wukong.DocumentProto.NodeType.NODE_TYPE_GROUP,
    Component: Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT,
    ComponentSet: Wukong.DocumentProto.NodeType.NODE_TYPE_COMPONENT_SET,
    Instance: Wukong.DocumentProto.NodeType.NODE_TYPE_INSTANCE,
    Text: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT,
    Vector: Wukong.DocumentProto.NodeType.NODE_TYPE_VECTOR,
    Bool: Wukong.DocumentProto.NodeType.NODE_TYPE_BOOL_OPERATION,
    Rectangle: Wukong.DocumentProto.NodeType.NODE_TYPE_RECTANGLE,
    Star: Wukong.DocumentProto.NodeType.NODE_TYPE_STAR,
    Polygon: Wukong.DocumentProto.NodeType.NODE_TYPE_POLYGON,
    Line: Wukong.DocumentProto.NodeType.NODE_TYPE_LINE,
    Ellipse: Wukong.DocumentProto.NodeType.NODE_TYPE_ELLIPSE,
    Comment: Wukong.DocumentProto.NodeType.NODE_TYPE_COMMENT,
    Slice: Wukong.DocumentProto.NodeType.NODE_TYPE_SLICE,
    StyleContainer: Wukong.DocumentProto.NodeType.NODE_TYPE_STYLE_CONTAINER,
    PaintStyle: Wukong.DocumentProto.NodeType.NODE_TYPE_PAINT_STYLE,
    TextStyle: Wukong.DocumentProto.NodeType.NODE_TYPE_TEXT_STYLE,
    EffectStyleNode: Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE,
    LayoutGridStyle: Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE,
    Variable: Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE,
    VariableSet: Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET,
    Page: Wukong.DocumentProto.NodeType.NODE_TYPE_PAGE,
    Document: Wukong.DocumentProto.NodeType.NODE_TYPE_DOCUMENT,
}

export const OptionPropItem = ({ name }: { name: string }) => {
    return <div style={{ padding: '4px 8px' }}>{name}</div>
}

export interface PropItem {
    name: string
    color: 'black' | 'red'
}
