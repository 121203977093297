import { MotiffApi } from '@motiffcom/plugin-api-types'
import {
    CurrentPageSetSelectionCommandWasmCall,
    GetDocumentColorProfileCommand,
    PluginApiAddComponentProperty,
    PluginApiAppendChild,
    PluginApiClone,
    PluginApiComponentPropertyDefinitions,
    PluginApiCreateInstance,
    PluginApiDefaultVariant,
    PluginApiDeleteCharacters,
    PluginApiDeleteComponentProperty,
    PluginApiDetachInstance,
    PluginApiEditComponentProperty,
    PluginApiExportAsync,
    PluginApiFindAll,
    PluginApiFindAllCriteria,
    PluginApiFindChildren,
    PluginApiGetChildrenCommand,
    PluginApiGetClipsContent,
    PluginApiGetComponentPropertiesOfInstance,
    PluginApiGetComponentPropertyReferences,
    PluginApiGetConstraintsCommand,
    PluginApiGetDimensionAndPositionCommand,
    PluginApiGetExposedInstances,
    PluginApiGetHandleMirroring,
    PluginApiGetInstancesAsync,
    PluginApiGetIsExposedInstance,
    PluginApiGetName,
    PluginApiGetOpacityCommand,
    PluginApiGetParentCommand,
    PluginApiGetPluginData,
    PluginApiGetPluginDataKeys,
    PluginApiGetProps,
    PluginApiGetPublishStatusCommand,
    PluginApiGetRangeAllFontNames,
    PluginApiGetRangeFills,
    PluginApiGetRangeFillStyleId,
    PluginApiGetRangeFontName,
    PluginApiGetRangeFontSize,
    PluginApiGetRangeFontWeight,
    PluginApiGetRangeHyperlink,
    PluginApiGetRangeIndentation,
    PluginApiGetRangeLetterSpacing,
    PluginApiGetRangeLineHeight,
    PluginApiGetRangeLineType,
    PluginApiGetRangeTextCase,
    PluginApiGetRangeTextDecoration,
    PluginApiGetRangeTextStyleId,
    PluginApiGetRelativeTransformCommand,
    PluginApiGetSelectionCommand,
    PluginApiGetSharedPluginData,
    PluginApiGetSharedPluginDataKeys,
    PluginApiGetStyledTextSegments,
    PluginApiGetTypeCommand,
    PluginApiGetVisibleCommand,
    PluginApiInsertCharacters,
    PluginApiIsRemoteLibraryNode,
    PluginApiRectToFrame,
    PluginApiRemoveNode,
    PluginApiRescaleCommand,
    PluginApiResetSymbolOverrides,
    PluginApiResizeNode,
    PluginApiResizeWithoutConstraints,
    PluginApiSetClipsContent,
    PluginApiSetComponentPropertiesOfInstance,
    PluginApiSetComponentPropertyReferences,
    PluginApiSetConstraintsCommand,
    PluginApiSetDimensionAndPositionCommand,
    PluginApiSetHandleMirroring,
    PluginApiSetIsExposedInstance,
    PluginApiSetName,
    PluginApiSetNameAutoRename,
    PluginApiSetOpacityCommand,
    PluginApiSetPluginData,
    PluginApiSetProps,
    PluginApiSetRangeFills,
    PluginApiSetRangeFillStyleId,
    PluginApiSetRangeFontName,
    PluginApiSetRangeFontSize,
    PluginApiSetRangeHyperlink,
    PluginApiSetRangeIndentation,
    PluginApiSetRangeLetterSpacing,
    PluginApiSetRangeLineHeight,
    PluginApiSetRangeLineType,
    PluginApiSetRangeTextCase,
    PluginApiSetRangeTextDecoration,
    PluginApiSetRangeTextStyleId,
    PluginApiSetRelativeTransformCommand,
    PluginApiSetSharedPluginData,
    PluginApiSetVisibleCommand,
    PluginApiSwapMainComponent,
    PluginApiSymbolOverrides,
    UpdateExportSettingsByIdsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { z } from 'zod'
import { featureSwitchManager } from '../../kernel/switch'
import {
    getComponentPropertiesV2,
    getComponentPropertyDefinitionsV2,
    getComponentPropertyReferencesV2,
} from '../component-prop/api'
import { DeepRequired, Motiff, PrototypePropMapper } from '../mapper'
import { NodePropDescriptor } from '../plugin-api-context-interface'
import { property2String } from '../plugin-event-utils'
import { geSegmentsByFields } from '../utils'
import { Handle } from '../vm-interface'
import { ZodTypes } from '../zod-type'
import { BaseNodePrototype } from './base-node-prototype'

type EnumPrototypePropMapperKey<K extends keyof typeof PrototypePropMapper> = typeof PrototypePropMapper[K] extends {
    figmaEnum: Record<any, any>
    motiffEnum: Record<any, any>
}
    ? K
    : never

export class SceneNodePrototype extends BaseNodePrototype {
    rectanglePrototype!: Handle
    documentPrototype!: Handle
    pagePrototype!: Handle
    framePrototype!: Handle
    vectorPrototype!: Handle
    starPrototype!: Handle
    polygonPrototype!: Handle
    linePrototype!: Handle
    ellipsePrototype!: Handle
    groupPrototype!: Handle
    booleanOperationPrototype!: Handle
    textPrototype!: Handle
    componentPrototype!: Handle
    componentSetPrototype!: Handle
    instancePrototype!: Handle
    slicePrototype!: Handle

    createNodeHandle(id: string): Handle {
        if (this.nodeCache.has(id)) {
            return this.nodeCache.get(id)!
        }

        const nodeType = this.ctx.callBridge(PluginApiGetTypeCommand, { nodeId: id }).type!
        const type = PrototypePropMapper.NodeType.fromMotiff(nodeType)
        let ret: Handle
        switch (type) {
            case 'DOCUMENT':
                ret = this.ctx.vm.newObject(this.documentPrototype)
                break
            case 'PAGE':
                ret = this.ctx.vm.newObject(this.pagePrototype)
                break
            case 'FRAME':
                ret = this.ctx.vm.newObject(this.framePrototype)
                break
            case 'VECTOR':
                ret = this.ctx.vm.newObject(this.vectorPrototype)
                break
            case 'RECTANGLE':
                ret = this.ctx.vm.newObject(this.rectanglePrototype)
                break
            case 'STAR':
                ret = this.ctx.vm.newObject(this.starPrototype)
                break
            case 'POLYGON':
                ret = this.ctx.vm.newObject(this.polygonPrototype)
                break
            case 'LINE':
                ret = this.ctx.vm.newObject(this.linePrototype)
                break
            case 'ELLIPSE':
                ret = this.ctx.vm.newObject(this.ellipsePrototype)
                break
            case 'GROUP':
                ret = this.ctx.vm.newObject(this.groupPrototype)
                break
            case 'BOOLEAN_OPERATION':
                ret = this.ctx.vm.newObject(this.booleanOperationPrototype)
                break
            case 'TEXT':
                ret = this.ctx.vm.newObject(this.textPrototype)
                break
            case 'COMPONENT_SET':
                ret = this.ctx.vm.newObject(this.componentSetPrototype)
                break
            case 'COMPONENT':
                ret = this.ctx.vm.newObject(this.componentPrototype)
                break
            case 'INSTANCE':
                ret = this.ctx.vm.newObject(this.instancePrototype)
                break
            case 'SLICE':
                ret = this.ctx.vm.newObject(this.slicePrototype)
                break
            default:
                throw new Error('暂不支持的 nodeType')
        }

        this.ctx.vm.defineProp(ret, 'id', {
            value: this.ctx.vm.newString(id),
            writable: false,
            enumerable: true,
        })

        this.nodeCache.set(id, ret)
        this.ctx.vm.retainHandle(ret)
        return ret
    }

    createNodePrototypes() {
        const _ = this.createSceneNodePropDescriptor()
        for (const key in _) {
            // @ts-expect-error
            _[key].key = key
        }

        const pluginDataMixin = [
            _.setPluginData,
            _.getPluginData,
            _.getPluginDataKeys,
            _.getSharedPluginData,
            _.getSharedPluginDataKeys,
            _.setSharedPluginData,
        ]
        const publishableMixin = [_.description, _.key, _.getPublishStatusAsync, _.remote]
        const baseNodeMixin = [
            _.name,
            _.toString,
            _.parent,
            _.remove,
            _.removed,
            _.setRelaunchData,
            ...pluginDataMixin,
        ] as NodePropDescriptor[]
        const exportMixin = [_.exportSettings, _.exportAsync]
        const reactionMixin = [_.reactions, _.setReactionsAsync]
        const dimensionAndPositionMixin = [
            _.x,
            _.y,
            _.width,
            _.height,
            _.relativeTransform,
            _.absoluteTransform,
            _.absoluteBoundingBox,
            _.minWidth,
            _.maxWidth,
            _.minHeight,
            _.maxHeight,
        ] as NodePropDescriptor[]
        const autolayoutChildrenMixin = [_.layoutAlign, _.layoutGrow, _.layoutPositioning]
        const layoutMixin = [
            ...dimensionAndPositionMixin,
            ...autolayoutChildrenMixin,
            _.resize,
            _.resizeWithoutConstraints,
            _.absoluteRenderBounds,
            _.rotation,
            _.constrainProportions,
            _.rescale,
        ]
        const childrenMixin = [
            _.children,
            _.appendChild,
            _.insertChild,
            _.findChildren,
            _.findChild,
            _.findAll,
            _.findOne,
            _.findAllWithCriteria,
        ]
        const individualStrokesMixin = [
            _.strokeLeftWeight,
            _.strokeTopWeight,
            _.strokeRightWeight,
            _.strokeBottomWeight,
        ]
        const minimalStrokesMixin = [
            _.dashPattern,
            _.strokeWeight,
            _.strokeAlign,
            _.strokeJoin,
            _.strokes,
            _.strokeStyleId,
            _.setStrokeStyleIdAsync,
        ]
        const minimalFillsMixin = [_.fillStyleId, _.setFillStyleIdAsync, _.fills]
        const geometryMixin = [...minimalStrokesMixin, _.strokeCap, ...minimalFillsMixin]
        const nonResizableTextMixin = [
            _.fontName,
            _.fontWeight,
            _.fontSize,
            _.hasMissingFont,
            _.characters,
            _.insertCharacters,
            _.deleteCharacters,
            _.getStyledTextSegments,
            _.getRangeFontSize,
            _.setRangeFontSize,
            _.getRangeFontName,
            _.setRangeFontName,
            _.getRangeAllFontNames,
            _.getRangeFontWeight,
            _.setRangeTextCase,
            _.getRangeTextCase,
            _.getRangeTextDecoration,
            _.setRangeTextDecoration,
            _.getRangeLetterSpacing,
            _.setRangeLetterSpacing,
            _.getRangeLineHeight,
            _.setRangeLineHeight,
            _.getRangeFills,
            _.setRangeFills,
            _.getRangeHyperlink,
            _.setRangeHyperlink,
            _.getRangeFillStyleId,
            _.setRangeFillStyleIdAsync,
            _.getRangeTextStyleId,
            _.setRangeTextStyleIdAsync,
            _.getRangeListOptions,
            _.setRangeListOptions,
            _.getRangeIndentation,
            _.setRangeIndentation,
        ]
        const minimalBlendMixin = [_.blendMode, _.opacity]
        const cornerMixin = [_.cornerRadius, _.cornerSmoothing]
        const rectangleCornerMixin = [_.topLeftRadius, _.topRightRadius, _.bottomLeftRadius, _.bottomRightRadius]
        const blendMixin = [...minimalBlendMixin, _.isMask, _.effects, _.effectStyleId, _.setEffectStyleIdAsync]
        const autoLayoutMixin = [
            _.layoutMode,
            _.layoutWrap,
            _.primaryAxisSizingMode,
            _.counterAxisSizingMode,
            _.primaryAxisAlignItems,
            _.counterAxisAlignItems,
            _.counterAxisAlignContent,
            _.itemSpacing,
            _.counterAxisSpacing,
            _.itemReverseZIndex,
            _.horizontalPadding,
            _.verticalPadding,
            _.paddingLeft,
            _.paddingRight,
            _.paddingTop,
            _.paddingBottom,
        ]
        const sceneNodeMixin = [_.visible, _.setNameAutoRename, _.locked, _.componentPropertyReferences]
        const defaultShapeMixin = [
            ...baseNodeMixin,
            ...blendMixin,
            ...layoutMixin,
            _.aiPoweredReason,
            ...sceneNodeMixin,
            ...geometryMixin,
            ...exportMixin,
            ...reactionMixin,
        ]
        const baseFrameMixin = [
            ...baseNodeMixin,
            ...childrenMixin,
            ...blendMixin,
            ...layoutMixin,
            ...autoLayoutMixin,
            ...sceneNodeMixin,
            _.clipsContent,
            _.constraints,
            _.guides,
            ...geometryMixin,
            ...individualStrokesMixin,
            _.layoutGrids,
            _.gridStyleId,
            _.expanded,
            _.setGridStyleIdAsync,
            ...cornerMixin,
            ...rectangleCornerMixin,
            ...exportMixin,
        ]
        const framePrototypingMixin = [_.overflowDirection]
        const defaultFrameMixin = [...baseFrameMixin, _.aiPoweredReason, ...framePrototypingMixin, ...reactionMixin]

        this.documentPrototype = this.createNodePrototype('DocumentNode', 'DOCUMENT', [
            ...baseNodeMixin,
            _.documentColorProfile,
            _.aiPoweredNodeId,
            _.children,
            _.appendChild,
            _.insertChild,
            _.findChildren,
            _.findChild,
            _.findAll,
            _.findOne,
            _.findAllWithCriteria,
        ])

        this.pagePrototype = this.createNodePrototype('PageNode', 'PAGE', [
            ...baseNodeMixin,
            ...childrenMixin,
            ...exportMixin,
            _.selection,
            _.guides,
            _.clone,
            _.selectedTextRange,
            _.backgrounds,
        ])

        this.ctx.addEventHandlersTo(this.pagePrototype, ['nodechange'])

        this.framePrototype = this.createNodePrototype('FrameNode', 'FRAME', [...defaultFrameMixin, _.clone])

        this.rectanglePrototype = this.createNodePrototype('RectangleNode', 'RECTANGLE', [
            ...defaultShapeMixin,
            ...individualStrokesMixin,
            ...cornerMixin,
            ...rectangleCornerMixin,
            _.rectToFrame,
            _.constraints,
            _.clone,
        ])

        this.vectorPrototype = this.createNodePrototype('VectorNode', 'VECTOR', [
            ...defaultShapeMixin,
            ...cornerMixin,
            _.rectToFrame,
            _.clone,
            _.constraints,
            _.vectorNetwork,
            _.vectorPaths,
            _.handleMirroring,
            _.setVectorNetworkAsync,
        ])

        this.starPrototype = this.createNodePrototype('StarNode', 'STAR', [
            ...defaultShapeMixin,
            ...cornerMixin,
            _.clone,
            _.constraints,
            _.pointCount,
            _.innerRadius,
        ])

        this.polygonPrototype = this.createNodePrototype('PolygonNode', 'POLYGON', [
            ...defaultShapeMixin,
            ...cornerMixin,
            _.clone,
            _.constraints,
            _.pointCount,
        ])

        this.linePrototype = this.createNodePrototype('LineNode', 'LINE', [
            ...defaultShapeMixin,
            _.clone,
            _.constraints,
        ])

        this.ellipsePrototype = this.createNodePrototype('EllipseNode', 'ELLIPSE', [
            ...defaultShapeMixin,
            ...cornerMixin,
            _.clone,
            _.constraints,
            _.arcData,
        ])

        this.groupPrototype = this.createNodePrototype('GroupNode', 'GROUP', [
            ...baseNodeMixin,
            ...sceneNodeMixin,
            ...childrenMixin,
            ...blendMixin,
            ...layoutMixin,
            _.aiPoweredReason,
            _.clone,
            _.expanded,
            ...exportMixin,
        ])

        this.booleanOperationPrototype = this.createNodePrototype('BooleanOperationNode', 'BOOLEAN_OPERATION', [
            ...defaultShapeMixin,
            ...childrenMixin,
            ...cornerMixin,
            _.clone,
            _.booleanOperation,
            _.expanded,
        ])

        this.textPrototype = this.createNodePrototype('TextNode', 'TEXT', [
            ...defaultShapeMixin,
            ...nonResizableTextMixin,
            _.autoRename,
            _.textAutoResize,
            _.textAlignHorizontal,
            _.textAlignVertical,
            _.textTruncation,
            _.textCase,
            _.textDecoration,
            _.letterSpacing,
            _.lineHeight,
            _.hyperlink,
            _.maxLines,
            _.paragraphSpacing,
            _.textStyleId,
            _.setTextStyleIdAsync,
            _.clone,
            _.constraints,
        ])

        this.componentSetPrototype = this.createNodePrototype('ComponentSetNode', 'COMPONENT_SET', [
            ...baseFrameMixin,
            ...publishableMixin,
            _.clone,
            _.defaultVariant,
            _.componentPropertyDefinitions,
            _.addComponentProperty,
            _.editComponentProperty,
            _.deleteComponentProperty,
        ])

        this.componentPrototype = this.createNodePrototype('ComponentNode', 'COMPONENT', [
            ...defaultFrameMixin,
            ...publishableMixin,
            _.createInstance,
            _.clone,
            _.getInstancesAsync,
            _.instances,
            _.componentPropertyDefinitions,
            _.addComponentProperty,
            _.editComponentProperty,
        ])

        this.instancePrototype = this.createNodePrototype('InstanceNode', 'INSTANCE', [
            ...defaultFrameMixin,
            _.clone,
            _.mainComponent,
            _.swapComponent,
            _.detachInstance,
            _.overrides,
            _.resetOverrides,
            _.getMainComponentAsync,
            _.isExposedInstance,
            _.exposedInstances,
            _.scaleFactor,
            _.componentProperties,
            _.setProperties,
        ])

        this.slicePrototype = this.createNodePrototype('SliceNode', 'SLICE', [
            ...baseNodeMixin,
            ...sceneNodeMixin,
            ...layoutMixin,
            _.clone,
            ...exportMixin,
        ])
    }

    createSceneNodePropDescriptor() {
        const self = this
        const ctx = this.ctx
        const vm = ctx.vm

        // just for type check
        const r = (descriptors: NodePropDescriptor) => {
            return descriptors
        }

        const createMappedEnumProp = <
            K extends keyof Wukong.DocumentProto.IApiProps,
            Y extends keyof typeof PrototypePropMapper
        >(
            name: K,
            mapperPropType: EnumPrototypePropMapperKey<Y>,
            zodType: z.ZodType
        ) => {
            return r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper[mapperPropType]
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: [name],
                    }).value?.[name]
                    // @ts-expect-error
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (valueHandle: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper[mapperPropType]
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: zodType,
                        key: name,
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            // @ts-expect-error
                            [name]: mapper.fromFigma(value),
                        }),
                        targetPropName: name,
                    })
                },
            })
        }

        const createSimpleProp = <K extends keyof Wukong.DocumentProto.IApiProps>(name: K, zodType: z.ZodType) => {
            return r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: [name],
                    }).value?.[name]
                    return vm.deepWrapHandle(result)
                },
                set: function (valueHandle: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: zodType,
                        key: name,
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            [name]: value,
                        }),
                        targetPropName: name,
                    })
                },
            })
        }

        const getNodeId = (node: Handle) => {
            if (!vm.isObject(node)) {
                throw Error(`Expected node, got ${vm.isNull(node) ? 'null' : vm.typeof(node)}`)
            }
            const id = vm.getProp(node, 'id')
            if (!vm.isString(id)) {
                throw Error(`Expected node id to be a string, got ${vm.typeof(id)}`)
            }
            return vm.getString(id)
        }

        const getNodeType = (node: Handle) => {
            if (!vm.isObject(node)) {
                throw Error(`Expected node, got ${vm.isNull(node) ? 'null' : vm.typeof(node)}`)
            }
            const type = vm.getProp(node, 'type')
            if (!vm.isString(type)) {
                throw Error(`Expected node type to be a string, got ${vm.typeof(type)}`)
            }
            return vm.getString(type)
        }

        return {
            name: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const name = ctx.callBridge(PluginApiGetName, { nodeId }).value!
                    return vm.newString(name)
                },
                set: function (valueHandle: Handle) {
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: ZodTypes.String,
                        key: 'name',
                    })
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetName, { nodeId, name: value })
                },
            }),
            toString: r({
                func: function () {
                    return vm.newString(`[Node ${vm.getStringValue(this, 'id')}]`)
                },
            }),
            relativeTransform: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const transform = ctx.callBridge(PluginApiGetRelativeTransformCommand, {
                        value: nodeId,
                    }) as Motiff.Transform
                    return vm.deepWrapHandle(PrototypePropMapper.Transform.fromMotiff(transform))
                },
                set: function (valueHandle: Handle) {
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: ZodTypes.Matrix,
                        key: 'relativeTransform',
                    })
                    const t = PrototypePropMapper.Transform.fromFigma(value as MotiffApi.Transform)
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetRelativeTransformCommand, { nodeId, value: t })
                },
            }),
            x: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const x = ctx.callBridge(PluginApiGetDimensionAndPositionCommand, { nodeId }).x!
                    return vm.newNumber(x)
                },
                set: function (valueHandle: Handle) {
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: ZodTypes.Float,
                        key: 'x',
                    })
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetDimensionAndPositionCommand, { nodeId, x: value })
                },
            }),
            y: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const y = ctx.callBridge(PluginApiGetDimensionAndPositionCommand, { nodeId }).y!
                    return vm.newNumber(y)
                },
                set: function (valueHandle: Handle) {
                    const value = ctx.unwrapAndValidate({
                        handle: valueHandle,
                        type: ZodTypes.Float,
                        key: 'y',
                    })
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetDimensionAndPositionCommand, { nodeId, y: value })
                },
            }),
            width: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const width = ctx.callBridge(PluginApiGetDimensionAndPositionCommand, { nodeId }).width!
                    return vm.newNumber(width)
                },
            }),
            height: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const height = ctx.callBridge(PluginApiGetDimensionAndPositionCommand, { nodeId }).height!
                    return vm.newNumber(height)
                },
            }),
            resize: r({
                // TODO(huangling:) 参数校验
                func: function (width: Handle, height: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiResizeNode, {
                        nodeId,
                        width: vm.getNumber(width),
                        height: vm.getNumber(height),
                    })
                },
            }),
            resizeWithoutConstraints: r({
                func: function (width: Handle, height: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiResizeWithoutConstraints, {
                        nodeId,
                        width: vm.getNumber(width),
                        height: vm.getNumber(height),
                    })
                },
            }),
            setPluginData: r({
                func: function (key: Handle, value: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetPluginData, {
                        nodeId,
                        key: ctx.unwrapAndValidate({
                            handle: key,
                            type: ZodTypes.String,
                            key: 'key',
                        }),
                        value: ctx.unwrapAndValidate({
                            handle: value,
                            type: ZodTypes.String,
                            key: 'value',
                        }),
                        pluginId: ctx.hostService.getPluginId(),
                    })
                },
            }),
            getPluginData: r({
                func: function (key: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    return vm.deepWrapHandle(
                        ctx.unwrapCallBridge(PluginApiGetPluginData, {
                            nodeId,
                            key: ctx.unwrapAndValidate({
                                handle: key,
                                type: ZodTypes.String,
                                key: 'key',
                            }),
                            pluginId: ctx.hostService.getPluginId(),
                        }).value!
                    )
                },
            }),
            getPluginDataKeys: r({
                func: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    return vm.deepWrapHandle(
                        ctx.unwrapCallBridge(PluginApiGetPluginDataKeys, {
                            nodeId,
                            pluginId: ctx.hostService.getPluginId(),
                        }).keys
                    )
                },
            }),
            setSharedPluginData: r({
                func: function (namespace: Handle, key: Handle, value: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    ctx.unwrapCallBridge(PluginApiSetSharedPluginData, {
                        nodeId,
                        nameSpace: ctx.unwrapAndValidate({
                            handle: namespace,
                            type: ZodTypes.String,
                            key: 'namespace',
                        }),
                        key: ctx.unwrapAndValidate({
                            handle: key,
                            type: ZodTypes.String,
                            key: 'key',
                        }),
                        value: ctx.unwrapAndValidate({
                            handle: value,
                            type: ZodTypes.String,
                            key: 'value',
                        }),
                    })
                },
            }),
            getSharedPluginData: r({
                func: function (namespace: Handle, key: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    return vm.deepWrapHandle(
                        ctx.unwrapCallBridge(PluginApiGetSharedPluginData, {
                            nodeId,
                            nameSpace: ctx.unwrapAndValidate({
                                handle: namespace,
                                type: ZodTypes.String,
                                key: 'namespace',
                            }),
                            key: ctx.unwrapAndValidate({
                                handle: key,
                                type: ZodTypes.String,
                                key: 'key',
                            }),
                        }).value
                    )
                },
            }),
            getSharedPluginDataKeys: r({
                func: function (namespace: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    return vm.deepWrapHandle(
                        ctx.unwrapCallBridge(PluginApiGetSharedPluginDataKeys, {
                            nodeId,
                            nameSpace: ctx.unwrapAndValidate({
                                handle: namespace,
                                type: ZodTypes.String,
                                key: 'namespace',
                            }),
                        }).keys
                    )
                },
            }),
            children: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const childrenIds = ctx.callBridge(PluginApiGetChildrenCommand, { nodeId }).value ?? []
                    const ret = vm.newArray()
                    for (let i = 0; i < childrenIds.length; i++) {
                        vm.setProp(ret, i.toString(), self.createNodeHandle(childrenIds[i]))
                    }
                    return ret
                },
            }),
            selection: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const childrenIds = ctx.callBridge(PluginApiGetSelectionCommand, { nodeId }).value ?? []
                    const ret = vm.newArray()
                    for (let i = 0; i < childrenIds.length; i++) {
                        vm.setProp(ret, i.toString(), self.createNodeHandle(childrenIds[i]))
                    }
                    return ret
                },
                set: function (value_: Handle) {
                    if (!vm.isArray(value_)) {
                        throw Error('The selection must be an array')
                    }
                    const ids: string[] = []
                    for (let i = 0; i < vm.getNumberValue(value_, 'length'); i++) {
                        const id = vm.getStringValue(vm.getProp(value_, i.toString()), 'id')
                        ids.push(id)
                    }
                    ctx.callBridge(CurrentPageSetSelectionCommandWasmCall, {
                        selection: ids,
                    })
                },
                canUseInReadonly: true,
            }),
            parent: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const parentId = ctx.callBridge(PluginApiGetParentCommand, { nodeId }).value
                    if (parentId) {
                        return self.createNodeHandle(parentId)
                    }
                    return vm.null
                },
            }),
            documentColorProfile: r({
                get: function () {
                    const mapper = PrototypePropMapper.DocumentColorProfile
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['documentColorProfile'],
                    }).value?.documentColorProfile
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
            }),
            fontName: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fontName'],
                    }).value?.fontName

                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    return vm.deepWrapHandle(result.value)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.FontName,
                        key: 'fontName',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fontName: { mixed: false, value },
                        }),
                        targetPropName: 'fontName',
                    })
                },
            }),
            fontWeight: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fontWeight'],
                    }).value?.fontWeight

                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    return vm.deepWrapHandle(result.value)
                },
            }),
            fontSize: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fontSize'],
                    }).value?.fontSize

                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    return vm.deepWrapHandle(result.value)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.number().min(1).finite(),
                        key: 'fontSize',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fontSize: { mixed: false, value },
                        }),
                        targetPropName: 'fontSize',
                    })
                },
            }),
            autoRename: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['autoRename'],
                    }).value?.autoRename

                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.boolean(),
                        key: 'autoRename',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            autoRename: value,
                        }),
                        targetPropName: 'autoRename',
                    })
                },
            }),
            hasMissingFont: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['hasMissingFont'],
                    }).value?.hasMissingFont

                    return vm.deepWrapHandle(result)
                },
            }),
            characters: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['characters'],
                    }).value?.characters

                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'characters',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            characters: value,
                        }),
                        targetPropName: 'characters',
                    })
                },
            }),
            textAutoResize: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextAutoResize
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textAutoResize'],
                    }).value?.textAutoResize
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextAutoResize
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextAutoResize,
                        key: 'textAutoResize',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textAutoResize: motiffValue,
                        }),
                        targetPropName: 'textAutoResize',
                    })
                },
            }),
            textAlignHorizontal: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextAlignHorizontal
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textAlignHorizontal'],
                    }).value?.textAlignHorizontal
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextAlignHorizontal
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextAlignHorizontal,
                        key: 'textAlignHorizontal',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textAlignHorizontal: motiffValue,
                        }),
                        targetPropName: 'textAlignHorizontal',
                    })
                },
            }),
            textAlignVertical: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextAlignVertical
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textAlignVertical'],
                    }).value?.textAlignVertical
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextAlignVertical
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextAlignVertical,
                        key: 'textAlignVertical',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textAlignVertical: motiffValue,
                        }),
                        targetPropName: 'textAlignVertical',
                    })
                },
            }),
            textTruncation: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextTruncation
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textTruncation'],
                    }).value?.textTruncation
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextTruncation
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextTruncation,
                        key: 'textTruncation',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textTruncation: motiffValue,
                        }),
                        targetPropName: 'textTruncation',
                    })
                },
            }),
            blendMode: r({
                get: function () {
                    const mapper = PrototypePropMapper.BlendMode
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['blendMode'],
                    }).value?.blendMode
                    return vm.deepWrapHandle(mapper.fromMotiff(result))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.BlendMode
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.BlendMode,
                        key: 'blendMode',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            blendMode: motiffValue,
                        }),
                        targetPropName: 'blendMode',
                    })
                },
            }),
            isMask: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['isMask'],
                    }).value?.isMask
                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.boolean(),
                        key: 'isMask',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            isMask: value,
                        }),
                        targetPropName: 'isMask',
                    })
                },
            }),
            textCase: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextCase
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textCase'],
                    }).value?.textCase
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextCase
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextCase,
                        key: 'textCase',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textCase: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'textCase',
                    })
                },
            }),
            textDecoration: r({
                get: function () {
                    const mapper = PrototypePropMapper.TextDecoration
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textDecoration'],
                    }).value?.textDecoration
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.TextDecoration
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextDecoration,
                        key: 'textDecoration',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textDecoration: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'textDecoration',
                    })
                },
            }),
            letterSpacing: r({
                get: function () {
                    const mapper = PrototypePropMapper.LetterSpacing
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['letterSpacing'],
                    }).value?.letterSpacing
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }

                    type ResultType = DeepRequired<typeof result.value>
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value as ResultType))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.LetterSpacing
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LetterSpacing,
                        key: 'letterSpacing',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            letterSpacing: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'letterSpacing',
                    })
                },
            }),
            lineHeight: r({
                get: function () {
                    const mapper = PrototypePropMapper.LineHeight
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['lineHeight'],
                    }).value?.lineHeight
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    type ResultType = DeepRequired<typeof result.value>
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value as ResultType))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.LineHeight
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LineHeight,
                        key: 'lineHeight',
                    })
                    const motiffValue = mapper.fromFigma(value)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            lineHeight: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'lineHeight',
                    })
                },
            }),
            hyperlink: r({
                get: function () {
                    const mapper = PrototypePropMapper.HyperlinkTarget
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['hyperlink'],
                    }).value?.hyperlink
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    if (!result.value) {
                        return vm.null
                    }
                    type ResultType = DeepRequired<typeof result.value>
                    return vm.deepWrapHandle(mapper.fromMotiff(result.value as ResultType))
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const mapper = PrototypePropMapper.HyperlinkTarget
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextHyperlinkOptions,
                        key: 'hyperlink',
                    })
                    const motiffValue = value ? mapper.fromFigma(value) : undefined
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            hyperlink: { mixed: false, value: motiffValue },
                        }),
                        targetPropName: 'hyperlink',
                    })
                },
            }),
            maxLines: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['maxLines'],
                    }).value?.maxLines
                    if (result === 0) {
                        return vm.null
                    }
                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.MaxLines,
                        key: 'maxLines',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            maxLines: value === null ? 0 : value,
                        }),
                        targetPropName: 'maxLines',
                    })
                },
            }),
            paragraphSpacing: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['paragraphSpacing'],
                    }).value?.paragraphSpacing
                    return vm.deepWrapHandle(result)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.ParagraphSpacing,
                        key: 'paragraphSpacing',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            paragraphSpacing: value === null ? undefined : value,
                        }),
                        targetPropName: 'paragraphSpacing',
                    })
                },
            }),
            textStyleId: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['textStyleId'],
                    }).value?.textStyleId
                    if (result.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(result.styleId)
                },
                set: function (value_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.String,
                        key: 'textStyleId',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textStyleId: { mixed: false, styleId: value },
                        }),
                        targetPropName: 'textStyleId',
                    })
                },
            }),
            setTextStyleIdAsync: r({
                func: function (styleId_: Handle) {
                    const { promise, resolve } = vm.newPromise()

                    const nodeId = vm.getStringValue(this, 'id')
                    const styleId = ctx.unwrapAndValidate({
                        handle: styleId_,
                        type: ZodTypes.String,
                        key: 'textStyleId',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            textStyleId: { mixed: false, styleId },
                        }),
                        targetPropName: 'textStyleId',
                    })

                    resolve(vm.undefined)
                    return promise
                },
            }),
            insertCharacters: r({
                func: function (start_: Handle, characters_: Handle, useStyle_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const characters = ctx.unwrapAndValidate({
                        handle: characters_,
                        type: ZodTypes.String,
                        key: 'characters',
                    })
                    const useStyle = ctx.unwrapAndValidate({
                        handle: useStyle_,
                        type: z.enum(['BEFORE', 'AFTER']).optional(),
                        key: 'useStyle',
                    })

                    ctx.unwrapCallBridge(PluginApiInsertCharacters, {
                        nodeId,
                        start,
                        characters,
                        posType: useStyle
                            ? PrototypePropMapper.InsertCharactersUseStyleType.fromFigma(useStyle)
                            : undefined,
                    })
                },
            }),
            deleteCharacters: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = vm.getStringValue(this, 'id')
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    ctx.unwrapCallBridge(PluginApiDeleteCharacters, {
                        nodeId,
                        start,
                        end,
                    })
                },
            }),
            // text range functions
            getStyledTextSegments: r({
                func: function (fields_: Handle, start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger.optional(),
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger.optional(),
                        key: 'end',
                    })
                    const fullSegs = ctx.unwrapCallBridge(PluginApiGetStyledTextSegments, {
                        nodeId,
                        start: start ?? 0,
                        end: end ?? 0,
                        value: start !== undefined && end !== undefined,
                    }).value
                    const fields = ctx.unwrapAndValidate({
                        handle: fields_,
                        type: ZodTypes.StyledTextSegmentFields,
                        key: 'fields',
                    })

                    const figSegs = fullSegs.map((seg) =>
                        PrototypePropMapper.StyledTextSegmentExt.fromMotiff(seg as Motiff.StyledTextSegment)
                    )
                    return vm.deepWrapHandle(geSegmentsByFields(figSegs, fields))
                },
            }),
            getRangeFontSize: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeFontSize, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            setRangeFontSize: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeFontSize, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                },
            }),
            getRangeFontName: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeFontName, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            getRangeAllFontNames: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeAllFontNames, {
                        nodeId,
                        start,
                        end,
                    }).value
                    return vm.deepWrapHandle(ret)
                },
            }),
            getRangeFontWeight: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeFontWeight, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            setRangeFontName: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.FontName,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeFontName, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                },
            }),
            getRangeTextCase: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeTextCase, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(PrototypePropMapper.TextCase.fromMotiff(ret.value!))
                },
            }),
            setRangeTextCase: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextCase,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeTextCase, {
                        nodeId,
                        start,
                        end,
                        value: PrototypePropMapper.TextCase.fromFigma(value),
                    })
                },
            }),
            getRangeTextDecoration: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeTextDecoration, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(PrototypePropMapper.TextDecoration.fromMotiff(ret.value!))
                },
            }),
            setRangeTextDecoration: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextDecoration,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeTextDecoration, {
                        nodeId,
                        start,
                        end,
                        value: PrototypePropMapper.TextDecoration.fromFigma(value),
                    })
                },
            }),
            getRangeLetterSpacing: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeLetterSpacing, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(
                        PrototypePropMapper.LetterSpacing.fromMotiff(ret.value as Wukong.DocumentProto.LetterSpacing)
                    )
                },
            }),
            setRangeLetterSpacing: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LetterSpacing,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeLetterSpacing, {
                        nodeId,
                        start,
                        end,
                        value: PrototypePropMapper.LetterSpacing.fromFigma(value),
                    })
                },
            }),
            getRangeLineHeight: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeLineHeight, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(
                        PrototypePropMapper.LineHeight.fromMotiff(ret.value as Wukong.DocumentProto.LineHeight)
                    )
                },
            }),
            setRangeLineHeight: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LineHeight,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeLineHeight, {
                        nodeId,
                        start,
                        end,
                        value: PrototypePropMapper.LineHeight.fromFigma(value),
                    })
                },
            }),
            getRangeFills: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeFills, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(
                        ret.value!.map((fill) => PrototypePropMapper.Paint.fromMotiff(fill as Motiff.Paint))
                    )
                },
            }),
            setRangeFills: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Paints,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeFills, {
                        nodeId,
                        start,
                        end,
                        value: value.map((fill) => PrototypePropMapper.Paint.fromFigma(fill as any)),
                    })
                },
            }),
            getRangeHyperlink: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeHyperlink, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    if (ret.value == null) {
                        return vm.null
                    }
                    return vm.deepWrapHandle(PrototypePropMapper.HyperlinkTarget.fromMotiff(ret.value!))
                },
            }),
            setRangeHyperlink: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextHyperlinkOptions,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeHyperlink, {
                        nodeId,
                        start,
                        end,
                        value:
                            value == null
                                ? null
                                : PrototypePropMapper.HyperlinkTarget.fromFigma(value as MotiffApi.HyperlinkTarget),
                    })
                },
            }),
            getRangeFillStyleId: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeFillStyleId, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            setRangeFillStyleId: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.String,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeFillStyleId, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                },
            }),
            setRangeFillStyleIdAsync: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const { resolve, promise } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.String,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeFillStyleId, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            getRangeTextStyleId: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeTextStyleId, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            setRangeTextStyleId: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.String,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeTextStyleId, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                },
            }),
            setRangeTextStyleIdAsync: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const { resolve, promise } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.String,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeTextStyleId, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            getRangeListOptions: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeLineType, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(PrototypePropMapper.TextListOptions.fromMotiffLineType(ret.value!))
                },
            }),
            setRangeListOptions: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.TextListOptions,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeLineType, {
                        nodeId,
                        start,
                        end,
                        value: PrototypePropMapper.TextListOptions.fromFigmaOptions(value),
                    })
                },
            }),
            getRangeIndentation: r({
                func: function (start_: Handle, end_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const ret = ctx.unwrapCallBridge(PluginApiGetRangeIndentation, {
                        nodeId,
                        start,
                        end,
                    }).value
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            setRangeIndentation: r({
                func: function (start_: Handle, end_: Handle, value_: Handle) {
                    const nodeId = getNodeId(this)
                    const start = ctx.unwrapAndValidate({
                        handle: start_,
                        type: ZodTypes.PositiveInteger,
                        key: 'start',
                    })
                    const end = ctx.unwrapAndValidate({
                        handle: end_,
                        type: ZodTypes.PositiveInteger,
                        key: 'end',
                    })
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveInteger,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetRangeIndentation, {
                        nodeId,
                        start,
                        end,
                        value,
                    })
                },
            }),
            layoutMode: createMappedEnumProp('stackMode', 'LayoutMode', ZodTypes.LayoutMode),
            layoutWrap: createMappedEnumProp('stackWrap', 'LayoutWrap', ZodTypes.StackWrap),
            primaryAxisSizingMode: createMappedEnumProp('stackPrimarySizing', 'StackSize', ZodTypes.SizingMode),
            counterAxisSizingMode: createMappedEnumProp('stackCounterSizing', 'StackSize', ZodTypes.SizingMode),
            primaryAxisAlignItems: createMappedEnumProp(
                'stackPrimaryAlignItems',
                'StackJustify',
                ZodTypes.StackJustify
            ),
            counterAxisAlignContent: createMappedEnumProp(
                'stackCounterAlignContent',
                'CounterAxisAlignContent',
                ZodTypes.StackCounterAlignContent
            ),
            counterAxisAlignItems: createMappedEnumProp('stackCounterAlignItems', 'StackAlign', ZodTypes.StackAlign),

            paddingLeft: createSimpleProp('stackHorizontalPadding', ZodTypes.PositiveFloat),
            paddingRight: createSimpleProp('stackPaddingRight', ZodTypes.PositiveFloat),
            paddingTop: createSimpleProp('stackVerticalPadding', ZodTypes.PositiveFloat),
            paddingBottom: createSimpleProp('stackPaddingBottom', ZodTypes.PositiveFloat),
            itemSpacing: createSimpleProp('stackSpacing', z.number()),
            counterAxisSpacing: createSimpleProp('stackCounterSpacing', ZodTypes.PositiveFloat.nullable()),
            itemReverseZIndex: createSimpleProp('stackReverseZIndex', z.boolean()),
            horizontalPadding: createSimpleProp('stackHorizontalPadding', ZodTypes.PositiveFloat),
            verticalPadding: createSimpleProp('stackVerticalPadding', ZodTypes.PositiveFloat),

            layoutAlign: createMappedEnumProp('stackChildAlignSelf', 'StackCounterAlign', ZodTypes.LayoutAlign),
            layoutPositioning: createMappedEnumProp('stackPositioning', 'StackPositioning', ZodTypes.LayoutPositioning),
            layoutGrow: createSimpleProp('stackChildPrimaryGrow', z.number()),
            appendChild: r({
                func: function (child_: Handle) {
                    const nodeId = getNodeId(this)
                    const childId = getNodeId(child_)
                    ctx.unwrapCallBridge(PluginApiAppendChild, {
                        parentNodeId: nodeId,
                        childNodeId: childId,
                    })
                },
            }),
            insertChild: r({
                func: function (index_: Handle, child_: Handle) {
                    const nodeId = getNodeId(this)
                    const childId = getNodeId(child_)
                    const index = ctx.unwrapAndValidate({
                        handle: index_,
                        type: ZodTypes.PositiveInteger,
                        key: 'index',
                    })
                    ctx.unwrapCallBridge(PluginApiAppendChild, {
                        parentNodeId: nodeId,
                        childNodeId: childId,
                        insertIndex: index,
                    })
                },
            }),
            findAll: r({
                func: function (callback_: Handle) {
                    const nodeId = getNodeId(this)
                    const ids =
                        ctx.unwrapCallBridge(PluginApiFindAll, {
                            currentNodeId: nodeId,
                            skipInvisibleInstanceChildren: ctx.skipInvisibleInstanceChildren,
                        }).value ?? []
                    const ret = vm.newArray()
                    let i = 0
                    ids.map((id_) => self.createNodeHandle(id_)).forEach((nodeHandle) => {
                        if (vm.isUndefined(callback_) || vm.isNull(callback_)) {
                            vm.setProp(ret, i.toString(), nodeHandle)
                            i++
                            return
                        }
                        if (!vm.isFunction(callback_)) {
                            throw new Error('callback is not a function')
                        }
                        const callResult = vm.callFunction(callback_, vm.null, nodeHandle)
                        if (callResult.type === 'FAILURE') {
                            throw Error(`"findAll" callback crashed: ${callResult.error.message}`)
                        }
                        if (vm.toBoolean(callResult.handle)) {
                            vm.setProp(ret, i.toString(), nodeHandle)
                            i++
                        }
                    })
                    return ret
                },
            }),
            findOne: r({
                func: function (callback_: Handle) {
                    const nodeId = getNodeId(this)
                    const ids =
                        ctx.unwrapCallBridge(PluginApiFindAll, {
                            currentNodeId: nodeId,
                            skipInvisibleInstanceChildren: ctx.skipInvisibleInstanceChildren,
                        }).value ?? []
                    for (const childId of ids) {
                        const node = self.createNodeHandle(childId)
                        const callResult = vm.callFunction(callback_, vm.null, node)
                        if (callResult.type === 'FAILURE') {
                            throw Error(`"findOne" callback crashed: ${callResult.error.message}`)
                        }
                        if (vm.toBoolean(callResult.handle)) {
                            return node
                        }
                    }
                    return vm.null
                },
            }),
            findChildren: r({
                func: function (callback_: Handle) {
                    const nodeId = getNodeId(this)
                    const ids =
                        ctx.unwrapCallBridge(PluginApiFindChildren, {
                            currentNodeId: nodeId,
                            skipInvisibleInstanceChildren: ctx.skipInvisibleInstanceChildren,
                        }).value ?? []
                    const ret = vm.newArray()
                    let i = 0
                    ids.map((id_) => self.createNodeHandle(id_)).forEach((nodeHandle) => {
                        if (vm.isUndefined(callback_) || vm.isNull(callback_)) {
                            vm.setProp(ret, i.toString(), nodeHandle)
                            i++
                            return
                        }
                        if (!vm.isFunction(callback_)) {
                            throw new Error('callback is not a function')
                        }
                        const callResult = vm.callFunction(callback_, vm.null, nodeHandle)
                        if (callResult.type === 'FAILURE') {
                            throw Error(`"findChildren" callback crashed: ${callResult.error.message}`)
                        }
                        if (vm.toBoolean(callResult.handle)) {
                            vm.setProp(ret, i.toString(), nodeHandle)
                            i++
                        }
                    })
                    return ret
                },
            }),
            findChild: r({
                func: function (callback_: Handle) {
                    const nodeId = getNodeId(this)
                    const ids =
                        ctx.unwrapCallBridge(PluginApiFindChildren, {
                            currentNodeId: nodeId,
                            skipInvisibleInstanceChildren: ctx.skipInvisibleInstanceChildren,
                        }).value ?? []
                    for (const childId of ids) {
                        const node = self.createNodeHandle(childId)
                        const callResult = vm.callFunction(callback_, vm.null, node)
                        if (callResult.type === 'FAILURE') {
                            throw Error(`"findChild" callback crashed: ${callResult.error.message}`)
                        }
                        if (vm.toBoolean(callResult.handle)) {
                            return node
                        }
                    }
                    return vm.null
                },
            }),
            findAllWithCriteria: r({
                func: function (criteria_: Handle) {
                    const nodeId = getNodeId(this)
                    const criteria = ctx.unwrapAndValidate({
                        handle: criteria_,
                        type: ZodTypes.FindCriteriaWithPluginDataSchema,
                        key: 'criteria',
                    })
                    const ids =
                        ctx.unwrapCallBridge(PluginApiFindAllCriteria, {
                            currentNodeId: nodeId,
                            hasTypes: !!criteria.types,
                            hasPluginDataFilter: !!criteria.pluginData,
                            hasSharedPluginDataFilter: !!criteria.sharedPluginData,
                            types: criteria.types
                                ? criteria.types.map((t) => PrototypePropMapper.NodeType.fromFigma(t))
                                : [],
                            pluginDataFilter: criteria.pluginData
                                ? { keys: criteria.pluginData.keys ?? [] }
                                : undefined,
                            sharedPluginDataFilter: criteria.sharedPluginData
                                ? {
                                      nameSpace: criteria.sharedPluginData.namespace,
                                      keys: criteria.sharedPluginData.keys ?? [],
                                  }
                                : undefined,
                            pluginId: ctx.hostService.getPluginId(),
                        }).value ?? []
                    const ret = vm.newArray()
                    for (let i = 0; i < ids.length; i++) {
                        vm.setProp(ret, i.toString(), self.createNodeHandle(ids[i]))
                    }
                    return ret
                },
            }),
            createInstance: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiCreateInstance, {
                        currentNodeId: nodeId,
                    }).value!
                    return self.createNodeHandle(ret)
                },
            }),
            defaultVariant: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiDefaultVariant, {
                        value: nodeId,
                    })
                    return self.createNodeHandle(ret.value!)
                },
            }),
            aiPoweredNodeId: createSimpleProp('aiPoweredNodeId', z.string()),
            aiPoweredReason: createSimpleProp('aiPoweredReason', z.number()),
            rectToFrame: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.callBridge(PluginApiRectToFrame, {
                        nodeId,
                    })
                    return self.createNodeHandle(ret.value!)
                },
            }),
            visible: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.callBridge(PluginApiGetVisibleCommand, {
                        value: nodeId,
                    })
                    return vm.deepWrapHandle(ret.value!)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.boolean(),
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetVisibleCommand, {
                        nodeId,
                        value,
                    })
                },
            }),
            remove: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    ctx.callBridge(PluginApiRemoveNode, {
                        nodeId,
                    })
                },
            }),
            clone: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiClone, {
                        nodeId,
                    })
                    return self.createNodeHandle(ret.nodeId!)
                },
            }),
            setNameAutoRename: r({
                func: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetNameAutoRename, {
                        nodeId,
                        name: value,
                    })
                },
            }),
            clipsContent: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.callBridge(PluginApiGetClipsContent, {
                        nodeId,
                    })
                    return vm.deepWrapHandle(ret.value!)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.boolean(),
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetClipsContent, {
                        nodeId,
                        value,
                    })
                },
            }),
            constraints: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.callBridge(PluginApiGetConstraintsCommand, {
                        nodeId,
                    })
                    return vm.deepWrapHandle({
                        horizontal: PrototypePropMapper.ConstraintType.fromMotiff(ret.horizontal!),
                        vertical: PrototypePropMapper.ConstraintType.fromMotiff(ret.vertical!),
                    })
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Constraints,
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetConstraintsCommand, {
                        nodeId,
                        value: Wukong.DocumentProto.Constraints.create({
                            horizontal: PrototypePropMapper.ConstraintType.fromFigma(value.horizontal),
                            vertical: PrototypePropMapper.ConstraintType.fromFigma(value.vertical),
                        }),
                    })
                },
            }),
            opacity: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.callBridge(PluginApiGetOpacityCommand, {
                        value: nodeId,
                    })
                    return vm.deepWrapHandle(ret.value!)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.ZeroToOne,
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetOpacityCommand, {
                        nodeId,
                        value: value,
                    })
                },
            }),
            locked: createSimpleProp('locked', z.boolean()),
            removed: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['removed'],
                    }).value?.removed
                    return vm.deepWrapHandle(result)
                },
            }),
            guides: r({
                get: function () {
                    const nodeId = vm.getStringValue(this, 'id')
                    const result = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['guides'],
                    }).value?.guides
                    return vm.deepWrapHandle(
                        result.map((val) => {
                            return {
                                axis: PrototypePropMapper.GuideAxis.fromMotiff(val.axis!),
                                offset: val.offset,
                            }
                        })
                    )
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Guides,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            guides: value.map((val) => {
                                return {
                                    axis: PrototypePropMapper.GuideAxis.fromFigma(val.axis),
                                    offset: val.offset,
                                }
                            }),
                        }),
                        targetPropName: 'guides',
                    })
                },
            }),
            absoluteRenderBounds: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['absoluteRenderBounds'],
                    }).value?.absoluteRenderBounds
                    return vm.deepWrapHandle(ret)
                },
            }),
            absoluteTransform: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['absoluteTransform'],
                    }).value?.absoluteTransform
                    type ResultType = DeepRequired<typeof ret>
                    return vm.deepWrapHandle(PrototypePropMapper.Transform.fromMotiff(ret as ResultType))
                },
            }),
            absoluteBoundingBox: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['absoluteBoundingBox'],
                    }).value?.absoluteBoundingBox
                    return vm.deepWrapHandle(ret)
                },
            }),
            booleanOperation: createMappedEnumProp('booleanOperation', 'BooleanOperation', ZodTypes.BooleanOperation),
            dashPattern: createSimpleProp('dashPattern', z.array(ZodTypes.PositiveFloat)),
            strokeWeight: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['strokeWeight'],
                    }).value?.strokeWeight
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(ret.value!)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            strokeWeight: {
                                mixed: false,
                                value: value,
                            },
                        }),
                        targetPropName: 'strokeWeight',
                    })
                },
            }),
            strokeTopWeight: createSimpleProp('strokeTopWeight', ZodTypes.PositiveFloat),
            strokeRightWeight: createSimpleProp('strokeRightWeight', ZodTypes.PositiveFloat),
            strokeBottomWeight: createSimpleProp('strokeBottomWeight', ZodTypes.PositiveFloat),
            strokeLeftWeight: createSimpleProp('strokeLeftWeight', ZodTypes.PositiveFloat),
            strokeCap: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['strokeCap'],
                    }).value?.strokeCap
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    } else if (ret.value !== undefined) {
                        return vm.newString(PrototypePropMapper.StrokeCap.fromMotiff(ret.value!))
                    } else {
                        return vm.newString('NONE')
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.StrokeCap,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            strokeCap: {
                                mixed: false,
                                value: PrototypePropMapper.StrokeCap.fromFigma(value),
                            },
                        }),
                        targetPropName: 'strokeCap',
                    })
                },
            }),
            strokeAlign: createMappedEnumProp('strokeAlign', 'StrokeAlign', ZodTypes.StrokeAlign),
            strokeJoin: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['strokeJoin'],
                    }).value?.strokeJoin
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    } else if (ret.value !== undefined) {
                        return vm.newString(PrototypePropMapper.StrokeJoin.fromMotiff(ret.value!))
                    } else {
                        return vm.null
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.StrokeJoin,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            strokeJoin: {
                                mixed: false,
                                value: PrototypePropMapper.StrokeJoin.fromFigma(value),
                            },
                        }),
                        targetPropName: 'strokeJoin',
                    })
                },
            }),
            strokeStyleId: createSimpleProp('strokeStyleId', z.string()),
            backgrounds: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['backgrounds'],
                    }).value?.backgrounds
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Paints,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            backgrounds: mapper.fromFigma(value as any),
                        }),
                        targetPropName: 'backgrounds',
                    })
                },
            }),
            strokes: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['strokes'],
                    }).value?.strokes
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Paints
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Paints,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            strokes: mapper.fromFigma(value as any),
                        }),
                        targetPropName: 'strokes',
                    })
                },
            }),
            layoutGrids: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.LayoutGrids
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['layoutGrids'],
                    }).value?.layoutGrids
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.LayoutGrids
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.LayoutGrids,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            layoutGrids: mapper.fromFigma(value),
                        }),
                        targetPropName: 'layoutGrids',
                    })
                },
            }),
            gridStyleId: createSimpleProp('gridStyleId', z.string()),
            rotation: createSimpleProp('rotation', z.number().finite()),
            fills: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fills'],
                    }).value?.fills
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    }
                    return vm.deepWrapHandle(PrototypePropMapper.Paints.fromMotiff(ret.paints as Motiff.Paint[]))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Paints,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fills: {
                                mixed: false,
                                paints: PrototypePropMapper.Paints.fromFigma(value as any),
                            },
                        }),
                        targetPropName: 'fills',
                    })
                },
            }),
            fillStyleId: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['fillStyleId'],
                    }).value?.fillStyleId
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    } else {
                        return vm.deepWrapHandle(ret.styleId)
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fillStyleId: {
                                mixed: false,
                                styleId: value,
                            },
                        }),
                        targetPropName: 'fillStyleId',
                    })
                },
            }),
            setFillStyleIdAsync: r({
                func: function (value_: Handle) {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            fillStyleId: {
                                mixed: false,
                                styleId: value,
                            },
                        }),
                        targetPropName: 'fillStyleId',
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            setStrokeStyleIdAsync: r({
                func: function (value_: Handle) {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            strokeStyleId: value,
                        }),
                        targetPropName: 'strokeStyleId',
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            expanded: createSimpleProp('expanded', z.boolean()),
            constrainProportions: createSimpleProp('constrainProportions', z.boolean()),
            effects: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Effects
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['effects'],
                    }).value?.effects
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.Effects
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Effects,
                        key: 'effects',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            effects: mapper.fromFigma(value),
                        }),
                        targetPropName: 'effects',
                    })
                },
            }),
            effectStyleId: createSimpleProp('effectStyleId', z.string()),
            vectorNetwork: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.VectorNetwork
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['vectorNetwork'],
                    }).value?.vectorNetwork
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.VectorNetwork
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.VectorNetwork,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            vectorNetwork: mapper.fromFigma(value as any),
                        }),
                        targetPropName: 'vectorNetwork',
                    })
                },
            }),
            vectorPaths: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.VectorPaths
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['vectorPaths'],
                    }).value?.vectorPaths
                    return vm.deepWrapHandle(mapper.fromMotiff(ret as any))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const mapper = PrototypePropMapper.VectorPaths
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.VectorPaths,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            vectorPaths: mapper.fromFigma(value),
                        }),
                        targetPropName: 'vectorPaths',
                    })
                },
            }),
            mainComponent: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['mainComponentId'],
                    }).value?.mainComponentId
                    if (!ret) {
                        return vm.null
                    }
                    return self.createNodeHandle(ret)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const componentNodeId = getNodeId(value_)
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            mainComponentId: componentNodeId,
                        }),
                        targetPropName: 'mainComponentId',
                    })
                },
            }),
            overrides: r({
                get: function () {
                    const motiffVal = ctx.unwrapCallBridge(PluginApiSymbolOverrides, {
                        value: getNodeId(this),
                    })
                    return vm.deepWrapHandle(
                        motiffVal.overrides.map((override) => {
                            return {
                                id: override.id!,
                                overriddenFields: override.fields.map((field) => {
                                    return property2String[field]
                                }),
                            }
                        })
                    )
                },
            }),
            resetOverrides: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    ctx.unwrapCallBridge(PluginApiResetSymbolOverrides, {
                        value: nodeId,
                    })
                },
            }),
            getMainComponentAsync: r({
                func: function () {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['mainComponentId'],
                    }).value?.mainComponentId
                    if (!ret) {
                        return vm.null
                    }
                    resolve(self.createNodeHandle(ret))
                    return promise
                },
            }),
            swapComponent: r({
                func: function (componentNode: Handle) {
                    const nodeId = getNodeId(this)
                    ctx.unwrapCallBridge(PluginApiSwapMainComponent, {
                        instanceNodeId: nodeId,
                        newMainComponentNodeId: getNodeId(componentNode),
                    })
                },
            }),
            detachInstance: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiDetachInstance, {
                        nodeId,
                    })
                    if (!ret.nodeId) {
                        return vm.null
                    }
                    return self.createNodeHandle(ret.nodeId)
                },
            }),
            key: createSimpleProp('key', z.string()),
            description: createSimpleProp('description', z.string()),
            minWidth: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['minWidth'],
                    }).value?.minWidth
                    if (ret) {
                        return vm.newNumber(ret)
                    } else {
                        return vm.null
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat.nullable(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            minWidth: value || 0,
                        }),
                        targetPropName: 'minWidth',
                    })
                },
            }),
            maxWidth: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['maxWidth'],
                    }).value?.maxWidth
                    if (ret) {
                        return vm.newNumber(ret)
                    } else {
                        return vm.null
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat.nullable(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            maxWidth: value || 0,
                        }),
                        targetPropName: 'maxWidth',
                    })
                },
            }),
            minHeight: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['minHeight'],
                    }).value?.minHeight
                    if (ret) {
                        return vm.newNumber(ret)
                    } else {
                        return vm.null
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat.nullable(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            minHeight: value || 0,
                        }),
                        targetPropName: 'minHeight',
                    })
                },
            }),
            maxHeight: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['maxHeight'],
                    }).value?.maxHeight
                    if (ret) {
                        return vm.newNumber(ret)
                    } else {
                        return vm.null
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat.nullable(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            maxHeight: value || 0,
                        }),
                        targetPropName: 'maxHeight',
                    })
                },
            }),
            setRelaunchData: r({
                func: function (_: Handle) {},
            }),
            handleMirroring: r({
                get: function () {
                    const motiffVal = ctx.callBridge(PluginApiGetHandleMirroring, { value: getNodeId(this) })
                    return motiffVal.isMixed
                        ? ctx.mixedSymbol
                        : vm.deepWrapHandle(PrototypePropMapper.HandleMirroring.fromMotiff(motiffVal.value!))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.HandleMirroring,
                        key: 'value',
                    })
                    ctx.callBridge(PluginApiSetHandleMirroring, {
                        nodeId,
                        value: PrototypePropMapper.HandleMirroring.fromFigma(value),
                    })
                },
            }),
            setVectorNetworkAsync: r({
                func: function (value_: Handle) {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.VectorNetwork,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            vectorNetwork: PrototypePropMapper.VectorNetwork.fromFigma(value as any),
                        }),
                        targetPropName: 'vectorNetwork',
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            setGridStyleIdAsync: r({
                func: function (value_: Handle) {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            gridStyleId: value,
                        }),
                        targetPropName: 'gridStyleId',
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            setEffectStyleIdAsync: r({
                func: function (value_: Handle) {
                    const { promise, resolve } = vm.newPromise()
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.string(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            effectStyleId: value,
                        }),
                        targetPropName: 'effectStyleId',
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
            pointCount: createSimpleProp('pointCount', z.number().min(3).int().finite()),
            innerRadius: createSimpleProp('innerRadius', ZodTypes.ZeroToOne),
            selectedTextRange: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['selectedTextRange'],
                    }).value?.selectedTextRange

                    if (isNil(ret)) {
                        return vm.null
                    }
                    const res = vm.newObject()
                    vm.setProp(res, 'node', self.createNodeHandle(ret.nodeId!))
                    vm.setProp(res, 'start', vm.newNumber(ret.start!))
                    vm.setProp(res, 'end', vm.newNumber(ret.end!))
                    return res
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.SelectedText,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            selectedTextRange: PrototypePropMapper.SelectedTextRange.fromFigma(value),
                        }),
                        targetPropName: 'selectedTextRange',
                    })
                },
            }),
            arcData: createSimpleProp('arcData', ZodTypes.ArcData),
            cornerRadius: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['cornerRadius'],
                    }).value?.cornerRadius
                    if (ret.mixed) {
                        return ctx.mixedSymbol
                    } else {
                        return vm.newNumber(ret.value!)
                    }
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.PositiveFloat,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            cornerRadius: { mixed: false, value: value },
                        }),
                        targetPropName: 'cornerRadius',
                    })
                },
            }),
            cornerSmoothing: createSimpleProp('cornerSmoothing', ZodTypes.ZeroToOne),
            topLeftRadius: createSimpleProp('topLeftRadius', ZodTypes.PositiveFloat),
            topRightRadius: createSimpleProp('topRightRadius', ZodTypes.PositiveFloat),
            bottomLeftRadius: createSimpleProp('bottomLeftRadius', ZodTypes.PositiveFloat),
            bottomRightRadius: createSimpleProp('bottomRightRadius', ZodTypes.PositiveFloat),
            rescale: r({
                func: function (scale: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: scale,
                        type: ZodTypes.NonZeroPositiveFloat,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiRescaleCommand, {
                        scaleFactor: value,
                        nodeId,
                    })
                },
            }),
            scaleFactor: createSimpleProp('scaleFactor', ZodTypes.NonZeroPositiveFloat),
            overflowDirection: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, {
                        nodeId,
                        props: ['scrollDirection'],
                    }).value?.scrollDirection
                    return vm.deepWrapHandle(PrototypePropMapper.ScrollDirection.fromMotiff(ret))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.OverflowDirection,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        props: Wukong.DocumentProto.ApiProps.create({
                            scrollDirection: PrototypePropMapper.ScrollDirection.fromFigma(value),
                        }),
                        targetPropName: 'scrollDirection',
                    })
                },
            }),
            getPublishStatusAsync: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const { promise, resolve } = vm.newPromise()
                    const ret = ctx.callBridge(PluginApiGetPublishStatusCommand, { nodeId })
                    switch (ret.status) {
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_UNPUBLISHED:
                            resolve(vm.newString('UNPUBLISHED'))
                            return promise
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_CURRENT:
                            resolve(vm.newString('CURRENT'))
                            return promise
                        case Wukong.DocumentProto.PublishStatus.PUBLISH_STATUS_CHANGED:
                            resolve(vm.newString('CHANGED'))
                            return promise
                        default:
                            throw new Error('return type error')
                    }
                },
            }),
            getInstancesAsync: r({
                func: function () {
                    const nodeId = getNodeId(this)
                    const ids = ctx.unwrapCallBridge(PluginApiGetInstancesAsync, { value: nodeId }).value ?? []
                    const { promise, resolve } = vm.newPromise()
                    const arr = vm.newArray()
                    for (let i = 0; i < ids.length; i++) {
                        vm.setProp(arr, i.toString(), self.createNodeHandle(ids[i]))
                    }
                    resolve(arr)
                    return promise
                },
            }),
            instances: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ids = ctx.unwrapCallBridge(PluginApiGetInstancesAsync, { value: nodeId }).value ?? []
                    const arr = vm.newArray()
                    for (let i = 0; i < ids.length; i++) {
                        vm.setProp(arr, i.toString(), self.createNodeHandle(ids[i]))
                    }
                    return arr
                },
            }),
            isExposedInstance: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetIsExposedInstance, { value: nodeId })
                    return vm.newBoolean(ret.value)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: z.boolean(),
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetIsExposedInstance, {
                        nodeId,
                        value,
                    })
                },
            }),
            exposedInstances: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ids = ctx.unwrapCallBridge(PluginApiGetExposedInstances, { value: nodeId }).value ?? []
                    const arr = vm.newArray()
                    for (let i = 0; i < ids.length; i++) {
                        vm.setProp(arr, i.toString(), self.createNodeHandle(ids[i]))
                    }
                    return arr
                },
            }),
            componentProperties: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetComponentPropertiesOfInstance, { value: nodeId })
                    return vm.deepWrapHandle(getComponentPropertiesV2(ret.properties))
                },
            }),
            setProperties: r({
                func: function (properties_: Handle) {
                    const nodeId = getNodeId(this)
                    const properties = ctx.unwrapAndValidate({
                        handle: properties_,
                        type: z.record(z.union([z.boolean(), z.string(), ZodTypes.VariableAlias])),
                        key: 'value',
                    })
                    const formatProperties: { [k: string]: Wukong.DocumentProto.IPropertyValueOfInstance } = {}
                    Object.entries(properties).forEach(([key, value]) => {
                        if (typeof value === 'string') {
                            formatProperties[key] = { stringValue: value }
                        } else if (typeof value === 'boolean') {
                            formatProperties[key] = { booleanValue: value }
                        }
                    })
                    ctx.unwrapCallBridge(PluginApiSetComponentPropertiesOfInstance, {
                        instanceId: nodeId,
                        properties: formatProperties,
                    })
                },
            }),
            componentPropertyReferences: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetComponentPropertyReferences, { value: nodeId })
                    return vm.deepWrapHandle(getComponentPropertyReferencesV2(ret))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.ComponentPropertyReferences,
                        key: 'value',
                    })
                    ctx.unwrapCallBridge(PluginApiSetComponentPropertyReferences, {
                        nodeId,
                        references: value,
                    })
                },
            }),
            componentPropertyDefinitions: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiComponentPropertyDefinitions, { value: nodeId })
                    return vm.deepWrapHandle(getComponentPropertyDefinitionsV2(ret))
                },
            }),
            addComponentProperty: r({
                func: function (propertyName_: Handle, type_: Handle, defaultValue_: Handle, options_: Handle) {
                    const nodeId = getNodeId(this)
                    const propertyName = ctx.unwrapAndValidate({
                        handle: propertyName_,
                        type: z.string(),
                        key: 'propertyName',
                    })
                    const type = ctx.unwrapAndValidate({
                        handle: type_,
                        type: ZodTypes.ComponentPropertyType,
                        key: 'type',
                    })
                    const defaultValue = ctx.unwrapAndValidate({
                        handle: defaultValue_,
                        type: ZodTypes.ComponentPropertyValue,
                        key: 'defaultValue',
                    })
                    const options = ctx.unwrapAndValidate({
                        handle: options_,
                        type: ZodTypes.ComponentPropertyOptions,
                        key: 'options',
                    })
                    const convertVComponentPropType = function (
                        inputType: typeof type
                    ): Wukong.DocumentProto.VComponentPropType {
                        switch (inputType) {
                            case 'BOOLEAN':
                                return Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_BOOL
                            case 'TEXT':
                                return Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_TEXT
                            case 'INSTANCE_SWAP':
                                return Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_INSTANCE_SWAP
                            case 'VARIANT':
                                return Wukong.DocumentProto.VComponentPropType.V_COMPONENT_PROP_TYPE_VARIANT
                        }
                    }
                    const convertDefaultValue = function (inputDefaultValue: typeof defaultValue) {
                        return typeof inputDefaultValue === 'boolean' ? inputDefaultValue.toString() : inputDefaultValue
                    }
                    const convertPreferredValues = function (
                        inputPreferredValues: MotiffApi.InstanceSwapPreferredValue[]
                    ) {
                        return inputPreferredValues.map(function (val) {
                            return {
                                type:
                                    val.type === 'COMPONENT'
                                        ? Wukong.DocumentProto.InstanceSwapPreferredValueType
                                              .INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT
                                        : Wukong.DocumentProto.InstanceSwapPreferredValueType
                                              .INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP,
                                key: val.key,
                            }
                        })
                    }
                    if (type === 'BOOLEAN' && typeof defaultValue !== 'boolean') {
                        throw new Error('Property value is incompatible with component property type')
                    } else if (type !== 'BOOLEAN' && typeof defaultValue !== 'string') {
                        throw new Error('Property value is incompatible with component property type')
                    }
                    const ret = ctx.unwrapCallBridge(PluginApiAddComponentProperty, {
                        id: nodeId,
                        propertyName,
                        type: convertVComponentPropType(type),
                        defaultValue: convertDefaultValue(defaultValue),
                        preferredValues: convertPreferredValues(options?.preferredValues ?? []),
                    })
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            editComponentProperty: r({
                func: function (propertyName_: Handle, newValue_: Handle) {
                    const nodeId = getNodeId(this)
                    const propertyName = ctx.unwrapAndValidate({
                        handle: propertyName_,
                        type: z.string(),
                        key: 'propertyName',
                    })
                    const newValue = ctx.unwrapAndValidate({
                        handle: newValue_,
                        type: ZodTypes.ComponentPropertyDefinitionSchema,
                        key: 'newValue',
                    })
                    const convertDefaultValue = function (inputDefaultValue: typeof newValue.defaultValue) {
                        return typeof inputDefaultValue === 'boolean' ? inputDefaultValue.toString() : inputDefaultValue
                    }
                    const convertPreferredValues = function (
                        inputPreferredValues: MotiffApi.InstanceSwapPreferredValue[]
                    ) {
                        return inputPreferredValues.map(function (val) {
                            return {
                                type:
                                    val.type === 'COMPONENT'
                                        ? Wukong.DocumentProto.InstanceSwapPreferredValueType
                                              .INSTANCE_SWAP_PREFERRED_VALUE_TYPE_COMPONENT
                                        : Wukong.DocumentProto.InstanceSwapPreferredValueType
                                              .INSTANCE_SWAP_PREFERRED_VALUE_TYPE_STATE_GROUP,
                                key: val.key,
                            }
                        })
                    }
                    const ret = ctx.unwrapCallBridge(PluginApiEditComponentProperty, {
                        id: nodeId,
                        propertyName,
                        newValue: {
                            name: newValue.name,
                            defaultValue: convertDefaultValue(newValue.defaultValue),
                            preferredValues: convertPreferredValues(newValue.preferredValues ?? []),
                            preferredValuesChanged: newValue.preferredValues !== undefined,
                        },
                    })
                    return vm.deepWrapHandle(ret.value!)
                },
            }),
            deleteComponentProperty: r({
                func: function (propertyName_: Handle) {
                    const nodeId = getNodeId(this)
                    const propertyName = ctx.unwrapAndValidate({
                        handle: propertyName_,
                        type: z.string(),
                        key: 'propertyName',
                    })
                    ctx.unwrapCallBridge(PluginApiDeleteComponentProperty, {
                        id: nodeId,
                        propertyName,
                    })
                },
            }),
            exportAsync: r({
                func: function (settings_: Handle) {
                    if (featureSwitchManager.isEnabled('feat-plugin-api-export-async-useabsolutebounds')) {
                        const nodeId = getNodeId(this)
                        const setting = ctx.unwrapAndValidate({
                            handle: settings_,
                            type: ZodTypes.ExportAsyncSetting.optional(),
                            key: 'exportAsync',
                        })

                        if (setting?.contentsOnly !== undefined && !setting.contentsOnly) {
                            console.warn('contentsOnly field support true only')
                        }

                        if (
                            setting?.useAbsoluteBounds !== undefined &&
                            (setting.format === 'SVG' || setting.format === 'SVG_STRING' || setting.format === 'PDF')
                        ) {
                            console.warn('SVG, SVG_STRING and PDF do not support useAbsoluteBounds for now')
                        }

                        // default arg
                        let exportArg: Wukong.DocumentProto.IArg_PluginApiExportSetting = {
                            id: nodeId,
                            format: 'PNG',
                            contentsOnly: true,
                            useAbsoluteBounds: false,
                            colorProfile: 'DOCUMENT',
                            constraint: { type: 'SCALE', value: 1 },
                            suffix: '',
                        }

                        // merge with default setting
                        exportArg = {
                            ...exportArg,
                            ...setting,
                        }

                        if (exportArg.colorProfile == 'DOCUMENT') {
                            const colorProfile = ctx.callBridge(GetDocumentColorProfileCommand).colorProfile
                            exportArg.colorProfile =
                                colorProfile ==
                                Wukong.DocumentProto.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3
                                    ? 'DISPLAY_P3_V4'
                                    : 'SRGB'
                        }

                        const { promise, resolve, reject } = vm.newPromise()

                        ctx.vm.registerPromise(ctx.hostService.exportAsyncV2(exportArg)).then(
                            (imageBlob) =>
                                imageBlob?.arrayBuffer().then((buffer) => {
                                    if (setting?.format === 'SVG_STRING') {
                                        resolve(vm.newString(new TextDecoder().decode(buffer)))
                                    } else {
                                        resolve(vm.deepWrapHandle(new Uint8Array(buffer)))
                                    }
                                }),
                            (_) => {
                                reject(vm.newString('Failed to export'))
                            }
                        )

                        return promise
                    } else {
                        const nodeId = getNodeId(this)
                        const setting = ctx.unwrapAndValidate({
                            handle: settings_,
                            type: ZodTypes.ExportAsyncSetting.optional(),
                            key: 'exportAsync',
                        })

                        if (setting?.contentsOnly !== undefined && !setting.contentsOnly) {
                            console.warn('contentsOnly field support true only')
                        }

                        if (setting?.useAbsoluteBounds !== undefined) {
                            console.warn('useAbsoluteBounds field is not supported')
                        }

                        // default arg
                        let exportArg: Wukong.DocumentProto.IArg_PluginApiExportSetting = {
                            id: nodeId,
                            format: 'PNG',
                            contentsOnly: true,
                            useAbsoluteBounds: false,
                            colorProfile: 'DOCUMENT',
                            constraint: { type: 'SCALE', value: 1 },
                            suffix: '',
                        }

                        // merge with default setting
                        exportArg = {
                            ...exportArg,
                            ...setting,
                        }

                        const promiseId = ctx.callBridge(PluginApiExportAsync, exportArg)

                        const { promise, resolve, reject } = vm.newPromise()

                        ctx.vm
                            .registerPromise(
                                ctx.legacyPromiseStore.getExternalPromiseById(promiseId.value!) as Promise<
                                    Uint8Array | string
                                >
                            )
                            .then(
                                (result) => {
                                    if (setting?.format === 'SVG_STRING') {
                                        resolve(vm.newString(new TextDecoder().decode(result as Uint8Array)))
                                    } else {
                                        resolve(vm.deepWrapHandle(result))
                                    }
                                },
                                (_) => {
                                    reject(vm.newString('Failed to export'))
                                }
                            )

                        return promise
                    }
                },
            }),
            exportSettings: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const motiffVal =
                        ctx.unwrapCallBridge(PluginApiGetProps, {
                            nodeId,
                            props: ['exportSettings'],
                        }).value?.exportSettings ?? []
                    // 与 exportSettingAsync 保持一致, 即 exportSettings 可作为 exportSettingAsync 的参数
                    const figmaVal: ReadonlyArray<MotiffApi.ExportSettings> = motiffVal
                        .filter((item) => {
                            return (
                                item.constraint?.type! in
                                [
                                    Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                                    Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH,
                                    Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT,
                                ]
                            )
                        })
                        .map((item) => {
                            const format = PrototypePropMapper.ExportSettingsFormat.fromMotiff(item.format!)

                            const isTextNode: boolean = getNodeType(this) === 'TEXT'

                            switch (format) {
                                case 'WEBP':
                                case 'AVIF':
                                case 'JPG':
                                case 'PNG': {
                                    return {
                                        constraint: PrototypePropMapper.ExportSettingConstraint.fromMotiff(
                                            item.constraint!
                                        ),
                                        contentsOnly: true,
                                        format,
                                        colorProfile: 'DOCUMENT',
                                        suffix: item.suffix,
                                        ...(isTextNode ? { useAbsoluteBounds: false } : {}),
                                    } as MotiffApi.ExportSettingsImage
                                }
                                case 'SVG': {
                                    return {
                                        constraint: PrototypePropMapper.ExportSettingConstraint.fromMotiff(
                                            item.constraint!
                                        ),
                                        contentsOnly: true,
                                        format,
                                        colorProfile: 'DOCUMENT',
                                        suffix: item.suffix,
                                        ...(isTextNode ? { useAbsoluteBounds: false } : {}),
                                    } as MotiffApi.ExportSettingsSVG
                                }
                                case 'PDF': {
                                    return {
                                        contentsOnly: true,
                                        format,
                                        colorProfile: 'DOCUMENT',
                                        suffix: item.suffix,
                                        ...(isTextNode ? { useAbsoluteBounds: false } : {}),
                                    } as MotiffApi.ExportSettingsPDF
                                }
                                default:
                                    throw new Error(`Unknown export format type: ${item.format}`)
                            }
                        })

                    return vm.deepWrapHandle(figmaVal)
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.ExportSettings,
                        key: 'value',
                    })

                    // TODO: 设置 exportSettings

                    const settingsForWCC = value.map((setting) => {
                        // has property "constraint"
                        if (setting.format !== 'SVG' && setting.format !== 'PDF') {
                            return Wukong.DocumentProto.ExportSettings.create({
                                format: PrototypePropMapper.ExportSettingsFormat.fromFigma(setting.format),
                                // TODO(jiangjk): only support contentsOnly to be true for now
                                contentsOnly: true,
                                suffix: setting.suffix,
                                ...(setting.constraint
                                    ? {
                                          constraint: PrototypePropMapper.ExportSettingConstraint.fromFigma(
                                              setting.constraint
                                          ),
                                      }
                                    : {
                                          // default value
                                          constraint: {
                                              type: Wukong.DocumentProto.ExportConstraintType
                                                  .EXPORT_CONSTRAINT_TYPE_SCALE,
                                              value: 1,
                                          },
                                      }),
                                // TODO(jiangjk): only support useAbsoluteBounds to be false for now
                                useAbsoluteBounds: false,
                            })
                        } else {
                            return Wukong.DocumentProto.ExportSettings.create({
                                format: PrototypePropMapper.ExportSettingsFormat.fromFigma(setting.format),
                                // TODO(jiangjk): only support contentsOnly to be true for now
                                contentsOnly: true,
                                suffix: setting.suffix,
                                // TODO(jiangjk): only support useAbsoluteBounds to be false for now
                                useAbsoluteBounds: false,
                            })
                        }
                    })

                    ctx.callBridge(UpdateExportSettingsByIdsCommand, {
                        idRefs: [nodeId],
                        settings: settingsForWCC,
                    })
                },
            }),
            remote: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiIsRemoteLibraryNode, { value: nodeId })
                    return vm.newBoolean(ret.value)
                },
            }),
            reactions: r({
                get: function () {
                    const nodeId = getNodeId(this)
                    const ret = ctx.unwrapCallBridge(PluginApiGetProps, { nodeId, props: ['reactions'] })
                    return vm.deepWrapHandle(PrototypePropMapper.Reactions.fromMotiff(ret.value?.reactions))
                },
                set: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Reactions,
                        key: 'value',
                    })
                    type ResultType = DeepRequired<typeof value>

                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        targetPropName: 'reactions',
                        props: Wukong.DocumentProto.ApiProps.create({
                            reactions: PrototypePropMapper.Reactions.fromFigma(value as ResultType),
                        }),
                    })
                },
            }),
            setReactionsAsync: r({
                func: function (value_: Handle) {
                    const nodeId = getNodeId(this)
                    const { promise, resolve } = vm.newPromise()
                    const value = ctx.unwrapAndValidate({
                        handle: value_,
                        type: ZodTypes.Reactions,
                        key: 'value',
                    })
                    type ResultType = DeepRequired<typeof value>

                    ctx.unwrapCallBridge(PluginApiSetProps, {
                        nodeId,
                        targetPropName: 'reactions',
                        props: Wukong.DocumentProto.ApiProps.create({
                            reactions: PrototypePropMapper.Reactions.fromFigma(value as ResultType),
                        }),
                    })
                    resolve(vm.undefined)
                    return promise
                },
            }),
        } as const
    }
}
