import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { isNil } from 'lodash-es'
import { CSSProperties } from 'react'
import {
    MonoIconMenuColorVariable12,
    MonoIconMenuNumberVariable12,
    MonoIconPanelVariable16,
} from '../../../../../ui-lib/src'
import { EffectStyleIconType, LayoutGridStyleIconType } from '../../../kernel/interface/component-style'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { EffectStyleIcon } from '../../../ui/component/design/effects/effect-style/effect-style-icon'
import { LayoutGridStyleIcon } from '../../../ui/component/design/styles/style-layout-grid/style-thumbnail'
import { LibraryTestId } from '../../../window'
import classes from './library-thumbnail-image.module.less'
import {
    LibraryThumbnailImageProps,
    buildThumbnailDataFromVO,
    useLocalLibraryThumbnailImage,
    useRemoteLibraryThumbnailImage,
} from './use-library-thumbnail-image-v2'

export { buildThumbnailDataFromVO }
export type { LibraryThumbnailImageProps }

/** 缺失字体 icon */
const FontMissIcon = (props: { className?: string; style?: CSSProperties }) => (
    <svg
        data-testid="library-thumbnail-font-miss-icon"
        className={props.className}
        style={props.style}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
    >
        <rect x="0.160645" width="16" height="16" rx="2" fill="#FFE702" />
        <path
            d="M1.79736 12H2.90479L5.38916 4.91016H5.72314V3.54492H4.91455L1.79736 12ZM3.20947 9.58008H7.68604L7.35791 8.68359H3.5376L3.20947 9.58008ZM7.96729 12H9.07471L5.95752 3.54492H5.48291V4.91016L7.96729 12ZM11.0786 9.38672H12.104V9.00586C12.104 8.79883 12.1313 8.61914 12.186 8.4668C12.2446 8.31445 12.3423 8.17188 12.479 8.03906C12.6196 7.90625 12.813 7.76758 13.0591 7.62305C13.4341 7.39648 13.731 7.11914 13.9497 6.79102C14.1724 6.45898 14.2837 6.06445 14.2837 5.60742V5.5957C14.2837 5.19336 14.1782 4.83398 13.9673 4.51758C13.7603 4.19727 13.4692 3.94531 13.0942 3.76172C12.7231 3.57422 12.2915 3.48047 11.7993 3.48047C11.2524 3.48047 10.7935 3.58203 10.4224 3.78516C10.0513 3.98828 9.76807 4.26172 9.57275 4.60547C9.37744 4.94922 9.26807 5.33008 9.24463 5.74805V5.75977L10.2407 5.75391L10.2524 5.74805C10.272 5.4707 10.3442 5.23047 10.4692 5.02734C10.5981 4.82422 10.772 4.66797 10.9907 4.55859C11.2095 4.44922 11.4653 4.39453 11.7583 4.39453C12.0513 4.39453 12.3052 4.44922 12.52 4.55859C12.7349 4.66797 12.9009 4.81641 13.0181 5.00391C13.1353 5.19141 13.1938 5.40625 13.1938 5.64844V5.66016C13.1938 5.86719 13.1626 6.05078 13.1001 6.21094C13.0376 6.37109 12.936 6.51953 12.7954 6.65625C12.6587 6.78906 12.4771 6.92578 12.2505 7.06641C11.9849 7.22656 11.7642 7.39453 11.5884 7.57031C11.4126 7.74609 11.2837 7.93945 11.2017 8.15039C11.1196 8.35742 11.0786 8.58984 11.0786 8.84766V9.38672ZM11.6704 12.1055C11.9087 12.1055 12.1021 12.0293 12.2505 11.877C12.4028 11.7246 12.479 11.5293 12.479 11.291C12.479 11.0488 12.4028 10.8516 12.2505 10.6992C12.1021 10.5469 11.9087 10.4707 11.6704 10.4707C11.4321 10.4707 11.2368 10.5469 11.0845 10.6992C10.9321 10.8516 10.856 11.0488 10.856 11.291C10.856 11.5293 10.9321 11.7246 11.0845 11.877C11.2368 12.0293 11.4321 12.1055 11.6704 12.1055Z"
            fill="#1B1F26"
        />
    </svg>
)

export function LibraryThumbnailImage(props: LibraryThumbnailImageProps) {
    if (
        props.thumbnailData?.isLocal ||
        props.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_EFFECT_STYLE ||
        props.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE ||
        props.thumbnailData?.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET
    ) {
        return <LocalLibraryThumbnailImage {...props} />
    } else {
        return <RemoteLibraryThumbnailImage {...props} />
    }
}

function LocalLibraryThumbnailImage(props: LibraryThumbnailImageProps) {
    const { fakeIntersectionDomRef, ...innerProps } = useLocalLibraryThumbnailImage(props)

    return (
        <>
            <span ref={fakeIntersectionDomRef} data-testid={LibraryTestId.ThumbnailImage}></span>
            <InnerLibraryThumbnailImage fontMissing={props.fontMissing} {...innerProps} />
        </>
    )
}

function RemoteLibraryThumbnailImage(props: LibraryThumbnailImageProps) {
    const { fakeIntersectionDomRef, ...innerProps } = useRemoteLibraryThumbnailImage(props)

    return (
        <>
            <span ref={fakeIntersectionDomRef} data-testid={LibraryTestId.ThumbnailImage}></span>
            <InnerLibraryThumbnailImage fontMissing={props.fontMissing} {...innerProps} />
        </>
    )
}

function InnerLibraryThumbnailImage(
    props: (
        | Omit<ReturnType<typeof useRemoteLibraryThumbnailImage>, 'fakeIntersectionDomRef'>
        | Omit<ReturnType<typeof useLocalLibraryThumbnailImage>, 'fakeIntersectionDomRef'>
    ) &
        Pick<LibraryThumbnailImageProps, 'className' | 'fontMissing'>
) {
    if (isNil(props.thumbnailData)) {
        return <span className={props.className} />
    }

    if (
        !props.thumbnailData.data &&
        props.thumbnailData.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE_SET
    ) {
        return <MonoIconPanelVariable16 className={classNames(props.className, 'color-$wk-v2-label-color-gray-11')} />
    }

    if (!props.thumbnailData.data && props.thumbnailData.type === Wukong.DocumentProto.NodeType.NODE_TYPE_VARIABLE) {
        if (featureSwitchManager.isEnabled('float-variable')) {
            switch (props.thumbnailData.variableIconType) {
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                    return (
                        <MonoIconMenuColorVariable12
                            className={classNames(props.className, 'color-$wk-v2-label-color-gray-11')}
                            data-testid="library-thumbnail-variable-color"
                        />
                    )
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                    return (
                        <MonoIconMenuNumberVariable12
                            className={classNames(props.className, 'color-$wk-v2-label-color-gray-11')}
                            data-testid="library-thumbnail-variable-number"
                        />
                    )
                default:
                    return null
            }
        } else {
            return (
                <MonoIconMenuColorVariable12
                    className={classNames(props.className, 'color-$wk-v2-label-color-gray-11')}
                />
            )
        }
    }

    if (!props.thumbnailData.data && !isNil(props.thumbnailData.effectStyleIconType)) {
        return (
            <EffectStyleIcon
                className={props.className}
                iconType={props.thumbnailData.effectStyleIconType as unknown as EffectStyleIconType}
            />
        )
    }

    if (!props.thumbnailData.data && !isNil(props.thumbnailData.layoutGridStyleIconType)) {
        return (
            <LayoutGridStyleIcon
                className={props.className}
                iconType={props.thumbnailData.layoutGridStyleIconType as unknown as LayoutGridStyleIconType}
            />
        )
    }

    if (props.fontMissing) {
        return (
            <FontMissIcon
                style={props.imageStyle.style}
                className={classNames(props.className, props.imageStyle.className)}
            />
        )
    }

    const renderImageWithContainer = () => {
        return (
            <div
                className={classNames(classes.imageViewer, classes.minContent, props.className)}
                style={{ ...props.imageStyle.style, opacity: props.isLoaded ? undefined : 0 }}
            >
                <img
                    style={props.imageStyle.style}
                    className={classNames(classes.imageViewer, props.className, props.imageStyle.className)}
                    src={props.imageSrc}
                    alt=""
                    draggable={false}
                    onError={'onImageLoadError' in props ? props.onImageLoadError : undefined}
                    onLoad={'onImageLoad' in props ? props.onImageLoad : undefined}
                />
            </div>
        )
    }

    if (props.imageStyle.withBorder) {
        return (
            <div className={classes.imageContainer} style={{ opacity: props.isLoaded ? undefined : 0 }}>
                {renderImageWithContainer()}
            </div>
        )
    }

    return renderImageWithContainer()
}
