/* eslint-disable import/namespace */
import { DELETE, GET, POST, PUT } from '@tutor/network-core'
import { WkCLog } from '../clog/wukong/instance'
import { FeatureGuideScene, PreferenceEmail, UserVOV2 } from '../interface/account'
import { OnboardingResourceVO, OnboardingScene, OnboardingStatus } from '../interface/onboarding'
import { OrgID, ResourceType, UserBriefVO, UserPreference } from '../interface/type'
import { setCurrentUserEmail } from '../recording'
import { Sentry } from '../sentry'
import { omitByValue } from '../util/omit-by-value'
import { BaseCommonRequest } from './base-request'
import { requestDidFailedHandler } from './error-handler'

// 获取用户配置信息
@GET
export class GetUserConfigRequest extends BaseCommonRequest<UserPreference> {
    constructor(private readonly docId: string, private readonly authStr: string) {
        super()
    }
    public override requestUrl() {
        return `users/v2/doc/preference?docIds=global&docIds=${this.docId}&docIds=${this.authStr}`
    }
}

@PUT
export class UpdateUserConfig extends BaseCommonRequest<UserPreference> {
    constructor(private readonly preference: UserPreference) {
        super()
    }

    public override requestUrl() {
        return `users/v2/doc/preference`
    }

    public override requestBody() {
        return {
            ...this.preference,
        }
    }
}

// 获取一个用户的信息
@GET
export class GetUserRequest extends BaseCommonRequest<UserVOV2> {
    public override requestUrl() {
        return `users/v2`
    }

    public override requestDidFailed(error: Error): boolean {
        super.requestDidFailed(error)
        Sentry.setUser(null)
        return false
    }

    public override requestDidSuccess(): void {
        super.requestDidSuccess()
        Sentry.setUser({
            id: `${this.responseObject?.userId}`,
            email: `${this.responseObject?.userBrief.email}`,
            username: `${this.responseObject?.userBrief.nickname}`,
        })
        WkCLog.setUser(`${this.responseObject?.userId}`)
        setCurrentUserEmail(this.responseObject?.userBrief.email || '')
    }
}

// 更新用户名或头像
@PUT
export class UpdateUserProfile extends BaseCommonRequest<UserVOV2> {
    constructor(private readonly userProfile: { nickname?: string; avatarId?: string }) {
        super()
    }

    public override requestUrl() {
        return `users/v2/profiles`
    }

    public override requestBody() {
        return {
            ...this.userProfile,
        }
    }

    public override requestDidFailed(err: any): boolean {
        return requestDidFailedHandler(err, this.responseStatusCode)
    }
}

// 根据条件查询用户信息(目前的匹配规则:邮箱精准匹配任何人或者用户名/邮箱前缀模糊搜索认识的人)
@GET
export class GetUsers extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly queryString: string, private readonly orgId?: string) {
        super()
    }

    public override requestUrl() {
        return `users/v2/user-brief`
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId })
    }
}

// 模糊查询用户，用于邀请场景，会额外校验用户权限，并返回用户在当前资源下的席位
@GET
export class GetUsersWithSeat extends BaseCommonRequest<UserBriefVO[]> {
    constructor(
        private readonly queryString: string,
        private readonly resourceType: ResourceType,
        private readonly resourceId: string
    ) {
        super()
    }

    public override requestUrl() {
        return `users/v2/user-brief-with-seat`
    }

    public override requestArgument() {
        return omitByValue({
            queryString: this.queryString,
            resourceType: this.resourceType,
            resourceId: this.resourceId,
        })
    }
}

// 根据邮箱精确查找一个任意注册的用户
@GET
export class GetUsersByEmail extends BaseCommonRequest<UserBriefVO[]> {
    constructor(private readonly queryString: string, private readonly orgId?: string) {
        super()
    }

    public override requestUrl() {
        return `users/v2/user-brief/by-email`
    }

    public override requestArgument() {
        return omitByValue({ queryString: this.queryString, orgId: this.orgId })
    }
}

// 更新onBoarding状态
@POST
export class UpdateOnboardingStatus extends BaseCommonRequest {
    constructor(private readonly scene: OnboardingScene, private readonly status: OnboardingStatus) {
        super()
    }

    public override requestUrl() {
        return `users/v2/onboarding/status`
    }

    public override requestBody() {
        return { scene: this.scene, status: this.status }
    }
}

// 批量更新onBoarding状态
@POST
export class BatchUpdateOnboardingStatus extends BaseCommonRequest {
    constructor(
        private readonly scenes: Array<OnboardingScene | FeatureGuideScene>,
        private readonly status: OnboardingStatus
    ) {
        super()
    }

    public override requestUrl() {
        return `users/v2/onboarding/status`
    }

    public override requestBody() {
        return { scenes: this.scenes, status: this.status }
    }
}

// 查询用户在当前组织下指定onBoarding场景预先创建的资源信息，如果资源不存在则创建
@PUT
export class QueryOnboarding extends BaseCommonRequest<OnboardingResourceVO> {
    constructor(private readonly scene: OnboardingScene, private readonly orgId: OrgID) {
        super()
    }

    public override requestUrl() {
        return `users/v2/onboarding`
    }

    public override requestBody() {
        return { scene: this.scene, orgId: this.orgId }
    }
}

@PUT
export class UpdateEmailPreference extends BaseCommonRequest<void> {
    constructor(private readonly params: PreferenceEmail) {
        super()
    }

    public override requestUrl() {
        return `users/v2/email/preference`
    }

    public override requestBody() {
        return this.params
    }
}

export interface SimpleAccessToken {
    accessToken: string
}

export interface AccessToken extends SimpleAccessToken {
    id: string
    name: string
    expireTime: number
    lastUsedTime: number
    createTime: number
}

// 删除用户访问令牌
@DELETE
export class DeleteAccessToken extends BaseCommonRequest<void> {
    constructor(private readonly id: string) {
        super()
    }

    public override requestUrl() {
        return `users/v2/access-tokens/${this.id}`
    }
}

// 查询用户所有的访问令牌
@GET
export class GetAccessTokens extends BaseCommonRequest<Array<AccessToken>> {
    public override requestUrl() {
        return `users/v2/access-tokens`
    }
}

// 创建用户个人访问令牌
@POST
export class CreateAccessToken extends BaseCommonRequest<SimpleAccessToken> {
    public override requestUrl() {
        return `users/v2/access-tokens`
    }

    public override requestBody() {
        return {
            name: 'default',
        }
    }
}
