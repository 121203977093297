import { throttle } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconLoading16, Select } from '../../../../../../../../ui-lib/src'
import { FontInfo, FontName } from '../../../../../../document/node/node'
import { CustomAxesStyleInfo } from '../../use-font-style-option-list'
import { FontMissIcon } from '../font-miss-icon/font-miss-icon'

interface FontStyleSelectBaseProps {
    availableFontInfo: FontInfo
    isFontNameMixed: boolean
    styleValue: CustomAxesStyleInfo
    disabled: boolean
    missFontStyle: boolean
    fontStyleOptionList: React.ReactNode
    styleLabel: string | undefined
    fontStyleLoading: boolean
}

interface FontStyleSelectProps extends FontStyleSelectBaseProps {
    onChangeFontStyle: (value: FontStyleSelectInfo) => void
    onSelectVariableSetting: () => void
    onPreselectFontStyle: (value: FontStyleSelectInfo) => void
}

export interface FontStyleSelectInfo {
    fontName: FontName
    customAxesStyle: string
    isMissFontCustomStyle: boolean
}

function createFontStyleSelectInfo(
    info: string | CustomAxesStyleInfo,
    availableFontInfo: FontInfo
): FontStyleSelectInfo | null {
    if (info === undefined) {
        return null
    }

    const style = typeof info === 'string' ? info : info.style
    const customAxesStyle = typeof info === 'string' ? '' : info.customAxesStyle.name
    const isMissFontCustomStyle = typeof info === 'string' ? false : info.isMissFontCustomStyle
    const fontName = availableFontInfo.styles.find((item) => item.style === style)

    if (!fontName && !isMissFontCustomStyle) {
        return null
    }

    const selectedFontName =
        fontName ||
        ({
            family: availableFontInfo.family,
            style,
        } as FontName)

    return {
        fontName: selectedFontName,
        customAxesStyle,
        isMissFontCustomStyle,
    }
}

export function FontStyleSelect({
    availableFontInfo,
    isFontNameMixed,
    styleValue,
    disabled,
    missFontStyle,
    fontStyleOptionList,
    styleLabel,
    fontStyleLoading,
    onChangeFontStyle,
    onSelectVariableSetting,
    onPreselectFontStyle,
}: FontStyleSelectProps) {
    const callbacksRef = useRef({
        onPreselectFontStyle,
    })

    useEffect(() => {
        callbacksRef.current.onPreselectFontStyle = onPreselectFontStyle
    }, [onPreselectFontStyle])

    const throttledFunctions = useMemo(() => {
        return {
            presetFontStyle: throttle(
                (info: FontStyleSelectInfo) => {
                    callbacksRef.current.onPreselectFontStyle(info)
                },
                300,
                { leading: true, trailing: true }
            ),
        }
    }, [])

    useEffect(() => {
        return () => {
            throttledFunctions.presetFontStyle.cancel()
        }
    }, [throttledFunctions])

    const cancelPendingPreselect = useCallback(() => {
        throttledFunctions.presetFontStyle.cancel()
    }, [throttledFunctions])

    const onChangeFontStyleOrVFSetting = (info: string | CustomAxesStyleInfo) => {
        cancelPendingPreselect()
        if (info === 'variable-setting') {
            onSelectVariableSetting()
            return
        }

        const fontStyleSelectInfo = createFontStyleSelectInfo(info, availableFontInfo)
        if (!fontStyleSelectInfo) {
            return
        }

        onChangeFontStyle(fontStyleSelectInfo)
    }

    const [propSnapshot, setPropSnapshot] = useState<FontStyleSelectBaseProps | null>(null)

    const onPreselectFontStyleOrVFSetting = (
        info: string | CustomAxesStyleInfo | undefined,
        _: { action: 'mouse' | 'keyboard' | 'open' | 'close' }
    ) => {
        if (info === 'variable-setting' || propSnapshot === null) {
            return
        }

        if (info === undefined) {
            const fontStyleSelectInfo = createFontStyleSelectInfo(
                propSnapshot.styleValue,
                propSnapshot.availableFontInfo
            )
            if (!fontStyleSelectInfo) {
                return
            }

            throttledFunctions.presetFontStyle(fontStyleSelectInfo)
            return
        }

        const fontStyleSelectInfo = createFontStyleSelectInfo(info, propSnapshot.availableFontInfo)
        if (!fontStyleSelectInfo) {
            return
        }

        throttledFunctions.presetFontStyle(fontStyleSelectInfo)
    }

    const onOpenOptionList = () => {
        // 打开列表时，将当前的 prop 保存下来，同时列表不再更新
        setPropSnapshot({
            availableFontInfo,
            isFontNameMixed,
            styleValue,
            disabled,
            missFontStyle,
            fontStyleOptionList,
            styleLabel,
            fontStyleLoading,
        })
    }

    const onCloseOptionList = () => {
        cancelPendingPreselect()
        setPropSnapshot(null)
    }

    return (
        <Select.NormalSingleLevel
            isMixed={propSnapshot?.isFontNameMixed ?? isFontNameMixed}
            value={propSnapshot?.styleValue ?? styleValue}
            disabled={propSnapshot?.disabled ?? disabled}
            onChange={onChangeFontStyleOrVFSetting}
            onChangePreselect={onPreselectFontStyleOrVFSetting}
            onOpen={onOpenOptionList}
            onClose={onCloseOptionList}
            icon={
                propSnapshot?.missFontStyle ?? missFontStyle ? (
                    <FontMissIcon testId="miss-style" />
                ) : propSnapshot?.fontStyleLoading ?? fontStyleLoading ? (
                    <IconLoading16 width="16" height="16" className="animate-spin"></IconLoading16>
                ) : null
            }
            label={styleLabel}
            dataTestIds={{
                triggerFocus: 'font-style-select-trigger-focus',
                container: 'font-style-select-container',
            }}
            maxWidth={216}
        >
            {propSnapshot?.fontStyleOptionList ?? fontStyleOptionList}
        </Select.NormalSingleLevel>
    )
}
