import { WKIconButton } from '../../../../../../../ui-lib/src'
import { MonoIconCommonNumberVariable16, MonoIconPanelLink16 } from '../../../../../../../ui-lib/src/icons-v2'

interface VariablePreviewProps {
    variableName: string
    onDetach: () => void
}

export const FloatVariablePreview = ({ variableName, onDetach }: VariablePreviewProps) => {
    return (
        <div className="flex overflow-hidden pl-4px items-center justify-between gap-x-4px bg-gray-1">
            <div className="flex h-20px px-4px items-center gap-4px bg-white overflow-hidden border border-$wk-v2-stroke-color-gray-3 rounded-2px">
                <MonoIconCommonNumberVariable16 className="shrink-0 w-12px h-12px" />
                <div className="overflow-hidden text-ellipsis whitespace-nowrap">{variableName}</div>
            </div>
            <WKIconButton className="flex-none" icon={<MonoIconPanelLink16 />} onClick={onDetach} />
        </div>
    )
}
