import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'ai-gen-custom-config',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.PM,
            },
        },
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.RD,
            },
        },
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['0ec51799b1b14e549a41d5922e57a2f58623e7df', 'eff45a3f83f3209c6223ed22924154d913aa48ce', '848154b842d852a02cd56aae93f06e95617a3f5b'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
