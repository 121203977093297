import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'vector-edit-gradient',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.ALL_USER,
            },
        },
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: { type: SwitchStrategyType.SPECIFIC_SUFFIX, suffix: ['@kanyun.com'] },
        },
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.CYPRESS,
            },
            env: SwitchEnvironmentScopeType.ALL,
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
