import { translation } from './help-account.translation'
/* eslint-disable no-restricted-imports */
import { ToggleShowShortcutPanelWasmCall } from '@wukong/bridge-proto'
import { WKItemType, WKMenuConfig, WKMenuItem } from '../../../../../../../ui-lib/src'
import { useSyncUser, useUserInfoContext } from '../../../../../auth'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { environment } from '../../../../../environment'
import { OnboardingScene } from '../../../../../kernel/interface/onboarding'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { MetricCollector } from '../../../../../kernel/metric-collector'
import { LogoutRequest } from '../../../../../kernel/request/account'
import { onboardingService } from '../../../../../main/service/onboarding-service'
import { useViewState } from '../../../../../view-state-bridge'
import { SessionStorageKey } from '../../../../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../../../../web-storage/session-storage/storage'
import { useMenuContextState } from '../context/menu-context'
import { useFeatureSwitch } from '../../../../../kernel/switch'

export const useDesignModeMenuHelpAccount = (command: CommandInvoker): WKMenuConfig => {
    const enableAIOnboarding = useFeatureSwitch('ai-gen-onboarding-design-system') && environment.isAbroad
    const { setIsOpenReportObstacle, setIsOpenAccountManager, setIsOpenFeedback } = useMenuContextState()
    const { logout } = useUserInfoContext()
    const { syncLogout } = useSyncUser()

    const handleLogout = async () => {
        try {
            MetricCollector.disable()
            await new LogoutRequest().start()
            syncLogout(1)
            enhancedSessionStorage.setItem(SessionStorageKey.UserTriggerLogout, '1')
            logout()
            window.localBridge?.editorLogout()
        } catch (e) {
        } finally {
            MetricCollector.enable()
        }
    }

    if (window.localBridge) {
        window.localBridge?.bindShowAccountManager(() => {
            setIsOpenAccountManager(true)
        })
        window.localBridge?.bindLogout(handleLogout)
    }

    const menus: WKMenuItem[] = [
        {
            name: translation('HelpAndAccount'),
            position: 'right top',
            testId: 'main-menu-help',
            children: [
                {
                    name: translation('HelpPage'),
                    shortCut: '',
                    disable: false,
                    handler: () => window.open(`${environment.host}/help/docs`),
                },
                ...(!environment.isAbroad
                    ? [
                          {
                              name: translation('VideoTutorials'),
                              shortCut: '',
                              disable: false,
                              handler: () => window.open(`${environment.host}/help/videos`),
                          },
                      ]
                    : []),
                {
                    name: translation('ReleaseNotes'),
                    shortCut: '',
                    disable: false,
                    handler: () => window.open(`${environment.host}/release-notes`),
                },
                {
                    name: translation('KeyboardShortcuts'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShortcutGuide],
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall),
                },
                {
                    name: translation('ResetOnboarding'),
                    shortCut: '',
                    disable: false,
                    handler: () => onboardingService.resetTour(OnboardingScene.Editor),
                },
                { type: WKItemType.Divider },
                ...(enableAIOnboarding
                    ? [
                          {
                              name: 'Join Motiff Community',
                              shortCut: '',
                              disable: false,
                              handler: () => {
                                  window.open(
                                      'https://join.slack.com/t/motiffcommunity/shared_invite/zt-31koqta1d-vwx0RmEfWC3~~iI6QWUUYA',
                                      '_blank'
                                  )
                              },
                          },
                      ]
                    : []),
                {
                    name: translation('SendFeedback'),
                    shortCut: '',
                    disable: false,
                    handler: () => setIsOpenFeedback(true),
                },
                {
                    name: `${translation('ReportAnIssue')}...`,
                    shortCut: shortcutLabelMap[ShortcutKey.ReportObstacle],
                    disable: false,
                    handler: () => setIsOpenReportObstacle(true),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('TermsOfService'),
                    shortCut: '',
                    disable: false,
                    handler: () => window.open(`${environment.host}/terms`),
                },
                {
                    name: translation('PrivacyPolicy'),
                    shortCut: '',
                    disable: false,
                    handler: () => window.open(`${environment.host}/privacy`),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('AccountSettings'),
                    shortCut: '',
                    disable: false,
                    handler: () => setIsOpenAccountManager(true),
                },
                {
                    name: translation('LogOut'),
                    shortCut: '',
                    disable: false,
                    handler: handleLogout,
                },
            ],
        },
    ].filter((v) => !!v) as WKMenuItem[]

    return menus
}

export const useDevModeMenuHelpAccount = (command: CommandInvoker): WKMenuConfig => {
    const showShortcutPanel = useViewState('showShortcutPanel')
    const { setIsOpenReportObstacle, setIsOpenAccountManager, setIsOpenFeedback } = useMenuContextState()
    const { logout } = useUserInfoContext()
    const { syncLogout } = useSyncUser()

    const handleLogout = async () => {
        try {
            MetricCollector.disable()
            await new LogoutRequest().start()
            syncLogout(1)
            enhancedSessionStorage.setItem(SessionStorageKey.UserTriggerLogout, '1')
            logout()
            window.localBridge?.editorLogout()
        } catch (e) {
        } finally {
            MetricCollector.enable()
        }
    }

    if (window.localBridge) {
        window.localBridge?.bindShowAccountManager(() => {
            setIsOpenAccountManager(true)
        })
        window.localBridge?.bindLogout(handleLogout)
    }

    const menus: WKMenuItem[] = [
        {
            name: translation('HelpAndAccount'),
            position: 'right top',
            testId: 'main-menu-help',
            children: [
                {
                    name: translation('HelpPage'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: () => window.open(`${environment.host}/help/docs`),
                },
                ...(!environment.isAbroad
                    ? [
                          {
                              name: translation('VideoTutorials'),
                              shortCut: '',
                              activity: false,
                              disable: false,
                              handler: () => window.open(`${environment.host}/help/videos`),
                          },
                      ]
                    : []),
                {
                    name: translation('KeyboardShortcuts'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShortcutGuide],
                    activity: showShortcutPanel,
                    disable: false,
                    handler: () => command.DEPRECATED_invokeBridge(ToggleShowShortcutPanelWasmCall),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('SendFeedback'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: () => setIsOpenFeedback(true),
                },
                {
                    name: `${translation('ReportAnIssue')}...`,
                    shortCut: shortcutLabelMap[ShortcutKey.ReportObstacle],
                    activity: false,
                    disable: false,
                    handler: () => setIsOpenReportObstacle(true),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('TermsOfService'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: () => window.open(`${environment.host}/terms`),
                },
                {
                    name: translation('PrivacyPolicy'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: () => window.open(`${environment.host}/privacy`),
                },
                { type: WKItemType.Divider },
                {
                    name: translation('AccountSettings'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: () => setIsOpenAccountManager(true),
                },
                {
                    name: translation('LogOut'),
                    shortCut: '',
                    activity: false,
                    disable: false,
                    handler: handleLogout,
                },
            ],
        },
    ].filter((v) => !!v) as WKMenuItem[]

    return menus
}
