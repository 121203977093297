import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    GoTo: '跳转至组件母版',
} as const

export const enTranslation = {
    GoTo: 'Go to main component',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
