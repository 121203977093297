import { Wukong } from '@wukong/bridge-proto'
import { forwardRef } from 'react'
import Tooltip from '../../../../../../ui-lib/src/components/tooltip/tooltip'
import { WKIconButton } from '../../../../../../ui-lib/src/components/wk-button/icon-button'
import { MonoIconCommonNumberVariable16, MonoIconPanelLink16 } from '../../../../../../ui-lib/src/icons-v2/mono/16'
import styles from './float-variable-alias-preview.module.less'
import { translation } from './float-variable-alias-preview.translation'

// 数值变量在绑定了 alias 之后的展示和解绑组件
function _FloatVariablePreviewPure(
    {
        variable,
        modeId,
        onClickTag,
        onDetach,
    }: {
        variable: Wukong.DocumentProto.ILocalVariable
        modeId: string
        onClickTag: (e: React.MouseEvent<HTMLDivElement>) => void
        onDetach: () => void
    },
    ref: React.Ref<HTMLDivElement>
) {
    const name = variable.dataValues[modeId].aliasName
    const reversedName = name.split('').reverse().join('')
    return (
        <div className={styles['float-variable-preview']}>
            <Tooltip title={name}>
                <div
                    className={styles['float-variable-tag']}
                    onClick={onClickTag}
                    ref={ref}
                    data-testid="float-variable-tag"
                >
                    <MonoIconCommonNumberVariable16 className="shrink-0 w-12px h-12px" />
                    <div className={styles['float-variable-tag-name']}>{reversedName}</div>
                </div>
            </Tooltip>
            <Tooltip title={translation('分离变量')}>
                <WKIconButton
                    icon={<MonoIconPanelLink16 />}
                    className={styles['float-variable-detach-icon']}
                    onClick={onDetach}
                    data-testid="float-variable-detach-icon"
                />
            </Tooltip>
        </div>
    )
}
export const FloatVariablePreviewPure = forwardRef(_FloatVariablePreviewPure)
