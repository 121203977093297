import { throttle } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Select, SelectArrowSingleLevelProps } from '../../../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../../../kernel/switch'
import { InputOptionsForUndoSquash } from '../../../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumber } from '../../../../atom/inputs/scrubbable-input-number'
import { formateToFixed2 } from '../../../../atom/inputs/utils/format'

const fontSizeList = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 48, 64, 96, 128]

interface FontSizeSelectBaseProps {
    isMixed: SelectArrowSingleLevelProps['isMixed']
    disabled: SelectArrowSingleLevelProps['disabled']
    value: SelectArrowSingleLevelProps['value']
    optionWidth: 88 | 100
    dataTestIds?: {
        fontSizeSelect?: string
        fontSizeSelectAddOption?: string
        fontSizeInput?: string
    }
}

export interface FontSizeSelectProps extends FontSizeSelectBaseProps {
    onChange: (v: number | string, options?: InputOptionsForUndoSquash) => void
    onPreselectFontSize?: (v: number | string) => void
}

export function FontSizeSelect(props: FontSizeSelectProps) {
    const preSelectEnabled = featureSwitchManager.isEnabled('font-size-select')

    const callbacksRef = useRef({
        onPreselectFontSize: props.onPreselectFontSize,
    })

    const timeoutRef = useRef<NodeJS.Timeout | undefined>()

    useEffect(() => {
        callbacksRef.current.onPreselectFontSize = props.onPreselectFontSize
    }, [props.onPreselectFontSize])

    const clearPendingTimeout = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = undefined
        }
    }, [])

    const throttledFunctions = useMemo(() => {
        return {
            presetFontSize: throttle(
                (value: number | string) => {
                    callbacksRef.current.onPreselectFontSize?.(value)
                },
                100,
                { leading: false, trailing: true }
            ),
        }
    }, [])

    const cancelPendingPreselect = useCallback(() => {
        throttledFunctions.presetFontSize.cancel()
        clearPendingTimeout()
    }, [throttledFunctions, clearPendingTimeout])

    useEffect(() => {
        return () => {
            cancelPendingPreselect()
        }
    }, [cancelPendingPreselect])

    const [propSnapshot, setPropSnapshot] = useState<FontSizeSelectBaseProps | null>(null)

    const needAddFontSizeOption = useMemo(() => {
        const currentValue = propSnapshot?.value ?? props.value
        const currentIsMixed = propSnapshot?.isMixed ?? props.isMixed
        return !currentIsMixed && !fontSizeList.includes(currentValue) && typeof currentValue === 'number'
    }, [props.isMixed, props.value, propSnapshot])

    const onPreselectFontSize = (
        value: number | string | undefined,
        _: { action: 'mouse' | 'keyboard' | 'open' | 'close' }
    ) => {
        if (!preSelectEnabled) {
            return
        }

        if (propSnapshot === null) {
            return
        }

        clearPendingTimeout()
        if (value === undefined) {
            if (!propSnapshot.isMixed) {
                // 在连续的 option 之间切换时，中间会收到一个 undefined， 如果后续还有值，希望忽略这个 undefined
                // 依赖下一个 onPreselectFontSize 时   clearPendingTimeout()
                timeoutRef.current = setTimeout(() => {
                    callbacksRef.current.onPreselectFontSize?.(propSnapshot.value)
                    timeoutRef.current = undefined
                }, 150)
            }
            return
        }

        throttledFunctions.presetFontSize(value)
    }

    const onOpenOptionList = () => {
        if (!preSelectEnabled) {
            return
        }

        setPropSnapshot({
            isMixed: props.isMixed,
            disabled: props.disabled,
            value: props.value,
            optionWidth: props.optionWidth,
            dataTestIds: props.dataTestIds,
        })
    }

    const onCloseOptionList = () => {
        if (!preSelectEnabled) {
            return
        }

        cancelPendingPreselect()

        if (propSnapshot && !propSnapshot.isMixed) {
            callbacksRef.current.onPreselectFontSize?.(propSnapshot.value)
        }

        setPropSnapshot(null)
    }

    return (
        <Select.ArrowSingleLevel
            isMixed={propSnapshot?.isMixed ?? props.isMixed}
            disabled={propSnapshot?.disabled ?? props.disabled}
            value={propSnapshot?.value ?? props.value}
            onChange={(v) => props.onChange(v)}
            onChangePreselect={onPreselectFontSize}
            onOpen={onOpenOptionList}
            onClose={onCloseOptionList}
            label={
                <ScrubbableInputNumber
                    isMixed={propSnapshot?.isMixed ?? props.isMixed}
                    disabled={propSnapshot?.disabled ?? props.disabled}
                    value={propSnapshot?.value ?? props.value}
                    onChange={(v, options) => props.onChange?.(v as number, options)}
                    min={1}
                    scrubbingDisabled={propSnapshot?.isMixed ?? props.isMixed}
                    testId={props.dataTestIds?.fontSizeInput}
                />
            }
            minWidth={props.optionWidth}
            dataTestIds={{ triggerFocus: props.dataTestIds?.fontSizeSelect }}
        >
            {needAddFontSizeOption ? (
                <Select.ArrowSingleLevel.Option
                    key={propSnapshot?.value ?? props.value}
                    value={propSnapshot?.value ?? props.value}
                    backwardIcon={''}
                    splitLineBottom
                    data-testid={
                        propSnapshot?.dataTestIds?.fontSizeSelectAddOption ?? props.dataTestIds?.fontSizeSelectAddOption
                    }
                >
                    {formateToFixed2(propSnapshot?.value ?? props.value)}
                </Select.ArrowSingleLevel.Option>
            ) : null}
            {fontSizeList.map((size) => (
                <Select.ArrowSingleLevel.Option key={size} value={size} backwardIcon={''}>
                    {size}
                </Select.ArrowSingleLevel.Option>
            ))}
        </Select.ArrowSingleLevel>
    )
}
