import { translation } from './export-panel.translation'
/* eslint-disable no-restricted-imports */
import { AddExportSettingsToSelectionCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC } from 'react'
import {
    IconAdd16,
    MonoIconLayerComponent16,
    MonoIconPanelMainComponentOut16,
    Tooltip,
    WKIconButton,
} from '../../../../../../ui-lib/src'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { IconButton } from '../../atom/button/icon-button'
import { useComponentInstanceCommand } from '../../design-panel-v2/component-instance-panel/commands'
import { LayerPanelItemIcon } from '../../layer-panel/render-item-icon'
import BatchExportModalTrigger from './batch-export-modal-trigger'
import { Export } from './export'
import style from './export.module.less'

export const ExportPanelTestIds = {
    addBtn: 'export-panel-add-btn',
    goToMainBtn: 'export-panel-go-to-main-btn',
    goToRemoteBtn: 'export-panel-go-to-remote-btn',
}

export const ExportPanel: FC = () => {
    const hasSelection = useViewState('hasSelection')
    const docReadonly = useViewState('docReadonly')

    return hasSelection ? (
        <Export renderAddItem={<SelectionInfoPanel />} />
    ) : docReadonly ? (
        <BatchExportModalTrigger />
    ) : null
}

export const SelectionInfoPanel: FC<{ showAddButton?: boolean; noShadow?: boolean }> = ({
    showAddButton = true,
    noShadow = false,
}) => {
    const command = useCommand()
    const instanceCommand = useComponentInstanceCommand()

    const handleAddClick = () => {
        command.DEPRECATED_invokeBridge(AddExportSettingsToSelectionCommand)
    }

    const exportInfo = useViewState('exportInfo')
    const instanceState = useViewState('componentPanelState')?.instanceState

    return (
        <div className={classNames(style['render-add-item'], noShadow && style['no-shadow'])}>
            <div className={style['name-icon-container']}>
                {exportInfo?.nodeIcon && exportInfo?.nodeId && (
                    <div style={{ marginRight: '8px' }}>
                        <LayerPanelItemIcon
                            id={exportInfo.nodeId}
                            nodeIcon={exportInfo.nodeIcon as Wukong.DocumentProto.NodeIconData}
                            isSelected={false}
                            isPurple={false}
                            isFontBold={true}
                            visibleStatus={
                                Wukong.DocumentProto.VLayerPanelVisibleStatus.V_LAYER_PANEL_VISIBLE_STATUS_VISIBLE
                            }
                        />
                    </div>
                )}
                <div className={style.name}>{exportInfo?.displayName}</div>
            </div>
            {showAddButton && (
                <div>
                    <IconButton
                        dataTestId={ExportPanelTestIds.addBtn}
                        icon={<IconAdd16 />}
                        selected={false}
                        onClick={handleAddClick}
                    />
                </div>
            )}
            {!showAddButton && instanceState && !instanceState.disableGoToMainComponent && (
                <Tooltip title={translation('GoTo')}>
                    <WKIconButton
                        className="color-$wk-v2-label-color-gray-13"
                        icon={
                            instanceState.isRemote ? <MonoIconPanelMainComponentOut16 /> : <MonoIconLayerComponent16 />
                        }
                        onClick={instanceCommand.goToMainComp}
                        data-testid={
                            instanceState.isRemote ? ExportPanelTestIds.goToRemoteBtn : ExportPanelTestIds.goToMainBtn
                        }
                    />
                </Tooltip>
            )}
        </div>
    )
}
