import { memo, useMemo } from 'react'
import {
    InputV2,
    MonoIconCommonaddPicture16,
    MonoIconCommonError16,
    Tooltip,
    WKIconButton,
    MonoIconCommonLoading16,
    WKAlert,
} from '../../../../../../ui-lib/src'
import { useChatbotInputInContext } from './use-chat-input'
import { imgFileUrl, getFileInfo } from '../../ai/ai-gen-ui/ai-gen-file-info'

const UploadedImageContent = memo(function UploadedImageContent({
    imageFile,
    uploadedStatus,
}: {
    imageFile: File
    uploadedStatus: string | null
}) {
    if (imageFile) {
        switch (uploadedStatus) {
            case 'success':
            case 'over-size':
                return (
                    <img
                        src={imgFileUrl(imageFile)}
                        alt={imageFile.name}
                        data-testid="Image uploaded for AI generation"
                        className="w-full h-full object-contain bg-transparent"
                    />
                )
            case 'pending':
                return (
                    <div className="flex justify-center items-center w-full h-full">
                        <MonoIconCommonLoading16 className="animate-spin color-[var(--wk-v2-label-color-gray-8)]" />
                    </div>
                )
            default:
                return <></>
        }
    }
    return null
})

function UploadedImage() {
    const { imageFile, triggerRemoveImage, uploadedStatus } = useChatbotInputInContext()

    const fileInfo = useMemo(() => {
        return imageFile ? getFileInfo(imageFile, uploadedStatus) : ''
    }, [imageFile, uploadedStatus])

    if (imageFile) {
        return (
            <div
                style={{ boxShadow: '0 0 0 1px var(--wk-v2-gray-3)' }}
                className="w-auto h-auto rounded flex flex-row gap-2 mt-2 p-2"
            >
                <div
                    style={{
                        backgroundColor:
                            uploadedStatus === 'failed' ||
                            uploadedStatus === 'over-size' ||
                            uploadedStatus === 'file-format-restricted'
                                ? '#FFF3F0'
                                : '#F4F5F5',
                    }}
                    className="w-13.5 h-13.5 shrink-0 rounded-0.75"
                >
                    <UploadedImageContent imageFile={imageFile} uploadedStatus={uploadedStatus} />
                </div>
                <div className="flex flex-col w-full p-1.75 overflow-hidden">
                    <span data-testid="Image name" className="wk-font-regular line-clamp-1 break-after-all">
                        {imageFile.name}
                    </span>
                    <span
                        data-testid="Image size"
                        className={`${
                            uploadedStatus === 'success' || uploadedStatus === 'pending'
                                ? 'text-gray'
                                : 'text-[var(--wk-v2-red-8)]'
                        }`}
                    >
                        {fileInfo}
                    </span>
                </div>
                <div className="flex flex-col justify-center items-center shrink-0">
                    <div data-testid="Remove Image" onClick={triggerRemoveImage}>
                        <MonoIconCommonError16 />
                    </div>
                </div>
            </div>
        )
    }
    return null
}

function UploadImageButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    return (
        <Tooltip title="upload image">
            <WKIconButton
                data-testid="upload image"
                type="secondary"
                className="w-6 h-6 p-1 shrink-0 mr-1"
                icon={
                    <MonoIconCommonaddPicture16
                        className={disabled ? '!color-[var(--wk-v2-gray-6)]' : 'color-[var(--wk-v2-gray-11)]'}
                    />
                }
                onClick={onClick}
            />
        </Tooltip>
    )
}

export function ChatbotInput() {
    const {
        prompt,
        shouldRetry,
        updatePrompt,
        MAX_PROMPT_LENGTH,
        triggerPasteImage,
        triggerDragImage,
        triggerUploadImage,
        disableUploadImage,
    } = useChatbotInputInContext()

    return (
        <div className="flex flex-col gap-2" onPasteCapture={triggerPasteImage} onDragOver={triggerDragImage}>
            <InputV2.Textarea
                placeholder={'placeholder'}
                autoFocus={true}
                minHeight={148}
                maxHeight={512}
                count={{
                    show: prompt.length >= MAX_PROMPT_LENGTH,
                    max: MAX_PROMPT_LENGTH,
                }}
                autoHeight={true}
                value={prompt}
                onInput={updatePrompt}
            />
            <UploadedImage />
            {shouldRetry ? (
                <div className="w-full">
                    <WKAlert.WithoutTitle rounded color={'error'}>
                        X
                    </WKAlert.WithoutTitle>
                </div>
            ) : (
                <></>
            )}
            <div className="flex flex-row">
                <UploadImageButton onClick={triggerUploadImage} disabled={disableUploadImage} />
            </div>
        </div>
    )
}
