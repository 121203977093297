import { translation } from './font-select-input.translation'
/* eslint-disable no-restricted-imports */
import { ChangeTextSearchCommand } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { throttle } from 'lodash-es'
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUnmount, useUpdateEffect } from 'react-use'
import { IconArrowDown12, IconLoading16 } from '../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { ToKeyCode } from '../../../../../document/util/keycode'
import type { FontInfoExt } from '../../../../../main/font/interface'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { WKFrogTask } from '../../../../../share/wk-frog-task'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useFontLocalizedFamily } from '../../common/use-font-localized-family'
import { FontMissIcon } from './font-miss-icon/font-miss-icon'
import type { IFontSelectContext } from './font-select-context'
import { FontSelectContextProvider } from './font-select-context'
import classes from './font-select-input.module.less'
import { FontTab, FontTabKeys } from './font-tab/font-tab'
import { useFontSelectInput } from './use-font-select-input'

interface FontSelectInputProps {
    className?: string
    selectedFamily: string
    selectedLocalizedFamily: string
    /**
     * 是否展示字体缺失图标
     */
    missIcon?: boolean
    recentFonts: FontInfoExt[]
    disabled?: boolean
    searchStringBelongTo: string
    onChange: (fontInfo: FontInfoExt) => void
    onPreselectFont?: (fontInfo: FontInfoExt) => void
    /**
     * 是否展示字体加载图标
     */
    loadingIcon?: boolean
    onChangeExpand?: (expend: boolean) => void
    /**
     * 展示那些字体选择器
     */
    enabledTabs?: FontTabKeys[] | null
    defaultSelectedTab?: FontTabKeys
    customStyle?: CSSProperties
    extraInputClass?: string
}

export function FontSelectInput({
    selectedFamily,
    selectedLocalizedFamily,
    recentFonts,
    searchStringBelongTo,
    onChange,
    onPreselectFont,
    className,
    disabled,
    missIcon = false,
    loadingIcon = false,
    onChangeExpand,
    enabledTabs,
    defaultSelectedTab,
    customStyle,
    extraInputClass = '',
}: FontSelectInputProps) {
    const {
        tabPosition,
        divRef: inputDivRef,
        selectMaxHeight,
        expand,
        setExpand,
        toggleExpand,
    } = useFontSelectInput({ onChangeExpand })

    const realLocalizedFamily = useFontLocalizedFamily(selectedFamily, selectedLocalizedFamily)

    const textSearch = useViewState('textSearch', { value: '', id: '' })
    const command = useCommand()

    const [initFamilyState, setInitFamilyState] = useState<{
        family: string
        localizedFamily: string
    } | null>(null)

    const callbacksRef = useRef({
        onPreselectFont,
    })

    useEffect(() => {
        callbacksRef.current.onPreselectFont = onPreselectFont
    }, [onPreselectFont])

    const throttledFunctions = useMemo(() => {
        return {
            preselectFont: throttle(
                (fontInfo: FontInfoExt) => {
                    callbacksRef.current.onPreselectFont?.(fontInfo)
                },
                300,
                { leading: true, trailing: true }
            ),
        }
    }, [])

    useEffect(() => {
        return () => {
            throttledFunctions.preselectFont.cancel()
        }
    }, [throttledFunctions])

    const cancelPendingPreselect = useCallback(() => {
        throttledFunctions.preselectFont.cancel()
    }, [throttledFunctions])

    const resetPreSelect = useCallback(() => {
        if (initFamilyState) {
            onPreselectFont?.({ ...initFamilyState, styles: [] })
        }
        setInitFamilyState(null)
    }, [setInitFamilyState, onPreselectFont, initFamilyState])

    const textSearchString = useMemo(() => {
        return searchStringBelongTo === textSearch.id ? textSearch.value : ''
    }, [searchStringBelongTo, textSearch.id, textSearch.value])

    const context: IFontSelectContext = useMemo(() => {
        return {
            recentFontInfos: recentFonts,
            currentSelectedFamily: initFamilyState ? initFamilyState.family : selectedFamily,
            onFontSelectChange: (fontInfo) => {
                setInitFamilyState(null)
                cancelPendingPreselect()

                setExpand(false)
                onChange(fontInfo)
            },
            onPreselectFont: (fontInfo) => {
                if (fontInfo) {
                    if (!initFamilyState) {
                        setInitFamilyState({
                            family: selectedFamily,
                            localizedFamily: selectedLocalizedFamily,
                        })
                    }

                    throttledFunctions.preselectFont(fontInfo)
                } else {
                    // 重置为初始值
                    resetPreSelect()
                    cancelPendingPreselect()
                }
            },
            expend: expand,
            setExpend: setExpand,
            searchStringBelongTo,
        }
    }, [
        recentFonts,
        initFamilyState,
        selectedFamily,
        expand,
        searchStringBelongTo,
        onChange,
        setExpand,
        cancelPendingPreselect,
        selectedLocalizedFamily,
        resetPreSelect,
        throttledFunctions,
    ])

    const _onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.keyCode === 13) {
                e.preventDefault()
                e.stopPropagation()
                toggleExpand()
                return false
            }
            return true
        },
        [toggleExpand]
    )

    const onClick = useCallback(() => {
        toggleExpand()
        WKFrogTask.fontSelector.click()
    }, [toggleExpand])

    const isKeepAliveShow = useMemo(() => {
        // 有查询内容时关闭打开文字选择器需要保持状态
        return expand ? expand : !!textSearchString
    }, [expand, textSearchString])

    const isKeepAliveHideStyle = useMemo((): React.CSSProperties => {
        return expand ? {} : { display: 'none', pointerEvents: 'none', visibility: 'hidden' }
    }, [expand])

    const useUnmountLog = useCallback(() => {
        if (expand) {
            WKFrogTask.fontSelector.panelDuration(true)
        }
    }, [expand])

    useUpdateEffect(() => {
        if (expand) {
            WKFrogTask.fontSelector.enter()
        } else {
            resetPreSelect()
            WKFrogTask.fontSelector.panelDuration()
        }
    }, [expand])

    useUnmount(useUnmountLog)

    useEffect(() => {
        if (expand && searchStringBelongTo !== textSearch.id) {
            command.invokeBridge(CommitType.Noop, ChangeTextSearchCommand, { value: '', id: '' })
        }
    }, [command, expand, searchStringBelongTo, textSearch.id])

    return (
        <div
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            style={customStyle}
            className={`${classes.root} ${className ?? ''}`}
        >
            <KeyboardReceiver keyCode={ToKeyCode.Enter} onKeydown={_onKeyDown} triggerOnlyByPropagation>
                <div
                    tabIndex={0}
                    className={`${classes.input} ${disabled ? classes.disabled : ''} ${
                        expand ? classes.focus : ''
                    } ${extraInputClass}`}
                    onClick={onClick}
                    ref={inputDivRef}
                    data-testid="font-family-select-input"
                >
                    {loadingIcon ? (
                        <IconLoading16 className={classnames(classes.loadingIcon, 'animate-spin')}></IconLoading16>
                    ) : missIcon ? (
                        <FontMissIcon className={classnames(classes.missIcon)} testId="font-miss-icon" />
                    ) : null}
                    <div className={`${classes.label} ${disabled ? classes.disabled : ''}`}>
                        {realLocalizedFamily || translation('SelectFont')}
                    </div>
                    <div className={`${classes.icon} ${disabled ? classes.disabled : ''}`}>
                        <IconArrowDown12 />
                    </div>
                </div>
            </KeyboardReceiver>
            {isKeepAliveShow ? (
                <FontSelectContextProvider value={context}>
                    <div
                        className={classes.tab}
                        style={{
                            top: tabPosition + 'px',
                            ...isKeepAliveHideStyle,
                        }}
                        data-testid="font-family-select-tab"
                        // 避免触发可拖拽弹窗的拖拽
                        data-disabled-drag-move={'true'}
                    >
                        <FontTab
                            selectMaxHeight={selectMaxHeight}
                            enabledTabs={enabledTabs}
                            defaultSelectedTab={defaultSelectedTab}
                        ></FontTab>
                    </div>
                    <div
                        className={classes.mask}
                        style={isKeepAliveHideStyle}
                        onClick={() => setExpand(false)}
                        data-testid="font-family-select-tab-mask"
                    ></div>
                </FontSelectContextProvider>
            ) : null}
        </div>
    )
}
