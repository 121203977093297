import { ScrollContainer } from '../../../../../../ui-lib/src'
import { AntDesignThemeColors } from './ant-design-theme-colors'
import { DesignSystemMap } from './constants'
import { SampleAndComponents } from './design-system-sample-and-component'
import { RemixDesignSystemPrimaryColor } from './remix-design-system-color-pallette'
import { RemixDesignSystemContrast } from './remix-design-system-contrast'
import { RemixDesignSystemCornerSize } from './remix-design-system-corner-size'
import { StylingAntDesignSize } from './remix-design-system-custom-size'
import { RemixDesignSystemFont } from './remix-design-system-font'
import { RemixDesignSystemMode } from './remix-design-system-mode'
import { RemixDesignSystemName } from './remix-design-system-name'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'

// Layout wrapper component
function DesignSystemLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="w-full flex flex-row h-130">
            <ScrollContainer className="h-full shrink-0 border-0 w-320px border-solid border-r border-[var(--wk-v2-stroke-color-gray-2)]">
                <div className="flex flex-col gap-4.5 py-5 px-6 w-272px">
                    <RemixDesignSystemName />
                    <RemixDesignSystemMode />
                    {children}
                </div>
            </ScrollContainer>
            <SampleAndComponents />
        </div>
    )
}

// Design system specific components
function StylingAutoRemix() {
    return (
        <DesignSystemLayout>
            <RemixDesignSystemPrimaryColor />
            <RemixDesignSystemCornerSize />
            <RemixDesignSystemFont />
        </DesignSystemLayout>
    )
}

function StylingMaterialDesign() {
    return (
        <DesignSystemLayout>
            <RemixDesignSystemContrast />
            <RemixDesignSystemPrimaryColor />
            <RemixDesignSystemFont />
        </DesignSystemLayout>
    )
}

function StylingModeColorFont() {
    return (
        <DesignSystemLayout>
            <RemixDesignSystemPrimaryColor />
            <RemixDesignSystemFont />
        </DesignSystemLayout>
    )
}

function StylingAntDesign() {
    return (
        <DesignSystemLayout>
            <div className="flex flex-col gap-4">
                <RemixDesignSystemPrimaryColor />
                <AntDesignThemeColors />
            </div>
            <StylingAntDesignSize />
            <RemixDesignSystemFont />
        </DesignSystemLayout>
    )
}

export function DesignSystemEditor() {
    const { tempRemixDesignSystem } = usePlatformAndDesignSystemInContext()

    switch (tempRemixDesignSystem) {
        case DesignSystemMap.custom:
        case DesignSystemMap.shadcn:
            return <StylingAutoRemix />
        case DesignSystemMap.md3:
            return <StylingMaterialDesign />
        case DesignSystemMap.apple:
            return <StylingModeColorFont />
        case DesignSystemMap.antd:
            return <StylingAntDesign />
        default:
            return null
    }
}
