/* eslint-disable no-restricted-imports */
import { useVirtualizer } from '@tanstack/react-virtual'
import { GetComputedColorVariableCommand, GetSelectionNodeIdsCommandForWasm, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import {
    DropdownDefaultCustomNode,
    DropdownV2,
    InputV2,
    MonoIconPanelComponentLibrary16,
    MonoIconPanelGrid16,
    MonoIconPanelList16,
    Position,
    RectContainer,
    safeCall,
    Scrollbar,
    ScrollbarRef,
    Select,
    Tooltip,
    useEllipsisTooltip,
    WKButton,
    WKIconButton,
} from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { CommitType } from '../../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import {
    cmdChangeAttrPanelStyleEditorState,
    cmdDeleteStyleNode,
} from '../../../../../document/command/node-props-command'
import { PaintStyleNode } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { IN_JEST_TEST } from '../../../../../environment'
import { StyleWithoutDocIdGetVO } from '../../../../../kernel/interface/component-style'
import { featureSwitchManager } from '../../../../../kernel/switch'
import { useCommand, useComponentService, useLibraryComponentService } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { useUserConfigStateV2 } from '../../../../../main/user-config/user-config-hook'
import { LibraryResourceOssClientType } from '../../../../../share/component-style-library/service/library-resource-downloader'
import { useViewState } from '../../../../../view-state-bridge/use-view-state'
import { LibraryStyleTestId } from '../../../../../window'
import { LibraryAll, LibraryLocal, useEditorContext } from '../../../../context/editor-context'
import { useRenderColorSpace } from '../../../color-profile'
import { ColorSpace } from '../../blend/color-picker/utils/color-translate'
import { CommonStyleInfo } from '../../styles/get-style-nodes-info-map'
import { modifiedMultiPopup } from '../../styles/modified-multi-popup'
import { RemoteStyleColorGridItemV2 } from '../../styles/style-color/remote-style/remote-style-color-grid-item-v2'
import { RemoteStyleColorListItemV2 } from '../../styles/style-color/remote-style/remote-style-color-list-item-v2'
import { UnknowStyleColorGridItemV2 } from '../../styles/style-color/remote-style/unknow-style-color-grid-item-v2'
import { UnknowStyleColorListItemV2 } from '../../styles/style-color/remote-style/unknow-style-color-list-item-v2'
import { StyleColorGridItemV2, StyleColorGridItemV2Props } from '../../styles/style-color/style-color-grid-item-v2'
import { StyleColorListItemPropsV2, StyleColorListItemV2 } from '../../styles/style-color/style-color-list-item-v2'
import { ColorInteractionFrom, ColorLibrariesProps } from '../type'
import classes from './color-libraries.module.less'
import { translation } from './color-libraries.translation'
import { ColorVariableGridItemV2 } from './color-variable-grid-v2'
import { ColorVariableListItemV2 } from './color-variable-list-v2'
import {
    createListChunks,
    formateLocalColorCollections,
    formateLocalStyleCollections,
    formateRemoteColorLibraries,
    formateRemoteStyleLibraries,
    LocalColorVariableItem,
    LocalStyleCollectionItem,
    RemoteColorVariableItem,
    splitColorVariableName,
    uidGenerator,
} from './utils'

const ColorLibraryAll = LibraryAll
const ColorLibraryLocal = LibraryLocal

function LibraryNameItem(props: { name: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    return (
        <Tooltip title={props.name} inactivation={inactivation}>
            <div className={classes.libraryName} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                {props.name}
            </div>
        </Tooltip>
    )
}

function CollectionNameItem(props: { name: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    return (
        <Tooltip title={props.name} inactivation={inactivation}>
            <div
                className={classes.collectionName}
                data-testid="collection-name"
                ref={ellipsisRef}
                onMouseEnter={onmouseenter}
            >
                {props.name}
            </div>
        </Tooltip>
    )
}

function GroupNameItem(props: { name: string }) {
    const { inactivation, ellipsisRef, onmouseenter } = useEllipsisTooltip<HTMLDivElement>()
    return (
        <Tooltip title={props.name} inactivation={inactivation}>
            <div className={classes.groupName} ref={ellipsisRef} onMouseEnter={onmouseenter}>
                {props.name}
            </div>
        </Tooltip>
    )
}

interface VirturalColorRef {
    scrollToSelectedOption: () => void
    nextOption: () => void
    prevOption: () => void
    onSubmit: () => void
}

interface BaseSelectableListItem {
    optionIndex: number
    isSelected: boolean
}

interface BaseColorListItem extends BaseSelectableListItem {
    onClick: () => void
    colorSpace: ColorSpace
}

interface LocalColorListItem extends BaseColorListItem {
    type: 'local-color'
    data: LocalColorVariableItem
}

interface RemoteColorListItem extends BaseColorListItem {
    type: 'remote-color'
    data: RemoteColorVariableItem
}

interface UnknownColorListItem extends BaseColorListItem {
    type: 'unknown-color'
    data: Wukong.DocumentProto.IRet_GetComputedColorVariable & {
        name: string
    }
}

interface BaseStyleListItem extends BaseSelectableListItem {
    openStyleId?: string
    dataTestId?: string
    isRightClickStyle: boolean
}

interface LocalStyleListItem extends BaseStyleListItem {
    type: 'local-style'
    data: LocalStyleCollectionItem
    onSelectItem: (item: { id: string }) => void
    onClickHoverIcon: (item: { id: string }, containerDomRect: DOMRect) => void
    onContextMenu: (item: { id: string }, e: any) => void
}

interface RemoteStyleListItem extends BaseStyleListItem {
    type: 'remote-style'
    docId: string
    data: StyleWithoutDocIdGetVO
    onSelectItem: (item: { id: string }) => void
    onClickHoverIcon: (item: { id: string }, containerDomRect: DOMRect) => void
    onContextMenu: (item: { id: string }, e: any) => void
}

interface UnknownStyleListItem extends BaseStyleListItem {
    type: 'unknown-style'
    docId: string
    data: Omit<CommonStyleInfo, 'groupName' | 'styleName'> &
        Pick<PaintStyleNode, 'publishFile' | 'fromFig' | 'publishId' | 'paints'>
    onSelectItem: (item: { id: string }) => void
    onClickHoverIcon: (item: { id: string }, containerDomRect: DOMRect) => void
    onContextMenu: (item: { id: string }, e: any) => void
}

type SelectableItem =
    | LocalColorListItem
    | UnknownColorListItem
    | RemoteColorListItem
    | LocalStyleListItem
    | RemoteStyleListItem
    | UnknownStyleListItem

type VirtualColorListItem =
    | SelectableItem
    | { type: 'collection'; name: string }
    | { type: 'header'; name: string }
    | { type: 'library-name'; name: string }
    | { type: 'group'; name: string }
    | { type: 'holder' }
    | { type: 'divider' }

const isSelectableItem = (item: VirtualColorListItem): item is SelectableItem => {
    return (
        item.type === 'local-color' ||
        item.type === 'remote-color' ||
        item.type === 'unknown-color' ||
        item.type === 'local-style' ||
        item.type === 'remote-style' ||
        item.type === 'unknown-style'
    )
}

interface VirtualColorListProps {
    items: VirtualColorListItem[]
}

function VirtualColorListInternal({ items }: VirtualColorListProps, ref: React.ForwardedRef<VirturalColorRef>) {
    const parentRef = useRef<ScrollbarRef>(null)
    const rowVirtualizer = useVirtualizer({
        count: items.length,
        overscan: IN_JEST_TEST ? 200 : undefined,
        getScrollElement: () => {
            if (parentRef.current) {
                return parentRef.current.getContainerElement()
            }
            return null
        },
        estimateSize: (i) => {
            const item = items[i]
            switch (item.type) {
                case 'unknown-style':
                case 'unknown-color':
                case 'local-color':
                case 'remote-color':
                case 'local-style':
                case 'remote-style':
                    return 32
                case 'collection':
                case 'group':
                    return 28
                case 'header':
                case 'library-name':
                    return 32
                case 'holder':
                    return 8
                case 'divider':
                    return 16
            }
        },
    })

    const [preselectIndex, setPreselectIndex] = useState<number>(0)

    const scrollIndex = useCallback(
        (optionIndex: number) => {
            for (let i = 0; i < items.length; ++i) {
                const item = items[i]
                if (isSelectableItem(item) && item.optionIndex === optionIndex) {
                    rowVirtualizer.scrollToIndex(i)
                    return
                }
            }
        },
        [items, rowVirtualizer]
    )
    const optionCount = useMemo(() => items.filter(isSelectableItem).length, [items])
    const selectedOptionIndex = useMemo(() => {
        const find = items.filter(isSelectableItem).find((item) => item.isSelected)
        return find ? find.optionIndex : -1
    }, [items])

    const [needScrollToSelected, setNeedScrollToSeleted] = useState<boolean>(true)
    useEffect(() => {
        if (!needScrollToSelected) {
            return
        }

        if (selectedOptionIndex >= 0) {
            setPreselectIndex(selectedOptionIndex)
            scrollIndex(selectedOptionIndex)
            setNeedScrollToSeleted(false)
        } else {
            setPreselectIndex(0)
            scrollIndex(0)
            setNeedScrollToSeleted(false)
        }
    }, [needScrollToSelected, scrollIndex, selectedOptionIndex])

    const nextOption = useCallback(() => {
        const nextIndex = preselectIndex + 1 < optionCount ? preselectIndex + 1 : 0
        setPreselectIndex(nextIndex)
        scrollIndex(nextIndex)
    }, [preselectIndex, optionCount, scrollIndex])

    const prevOption = useCallback(() => {
        const nextIndex = preselectIndex - 1 >= 0 ? preselectIndex - 1 : optionCount - 1
        setPreselectIndex(nextIndex)
        scrollIndex(nextIndex)
    }, [preselectIndex, optionCount, scrollIndex])

    const enterCallbackRef = useRef<Map<number, () => void>>(new Map())
    const recordEnterCallback = useCallback((index: number, callback: () => void) => {
        enterCallbackRef.current.set(index, () => safeCall(callback))
    }, [])

    const onSubmit = useCallback(() => {
        enterCallbackRef.current.get(preselectIndex)?.()
    }, [preselectIndex])

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
            e.stopPropagation()
            e.preventDefault()
            nextOption()
            return false
        } else if (e.key === 'ArrowUp') {
            e.stopPropagation()
            e.preventDefault()
            prevOption()
            return false
        } else if (e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            onSubmit()
            return false
        }

        return true
    }

    useImperativeHandle(ref, () => ({
        scrollToSelectedOption: () => {
            setNeedScrollToSeleted(true)
        },
        nextOption,
        prevOption,
        onSubmit,
    }))

    const renderItem = (index: number) => {
        const item = items[index]

        switch (item.type) {
            case 'local-color':
                return (
                    <ColorVariableListItemV2
                        key={item.data.originData.nodeId}
                        className={classes.variable}
                        name={item.data.name}
                        description={item.data.description}
                        color={item.data.color}
                        colorSpace={item.colorSpace}
                        onClick={item.onClick}
                        isSelected={item.isSelected}
                        isPreselect={preselectIndex === item.optionIndex}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'unknown-color':
                return (
                    <ColorVariableListItemV2
                        key={item.data.nodeId}
                        name={item.data.name}
                        description={item.data.description}
                        color={item.data.color}
                        colorSpace={item.colorSpace}
                        onClick={item.onClick}
                        isPreselect={preselectIndex === item.optionIndex}
                        isSelected={item.isSelected}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'remote-color':
                return (
                    <ColorVariableListItemV2
                        key={item.data.originData.id}
                        className={classes.variable}
                        name={item.data.name}
                        description={item.data.description}
                        color={item.data.color}
                        colorSpace={item.colorSpace}
                        onClick={item.onClick}
                        isPreselect={preselectIndex === item.optionIndex}
                        isSelected={item.isSelected}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'local-style':
                return (
                    <StyleColorListItemV2
                        key={item.data.id}
                        item={item.data as StyleColorListItemPropsV2['item']}
                        openStyleId={item.openStyleId}
                        onContextMenu={item.onContextMenu}
                        dataTestId={item.dataTestId}
                        onSelectItem={item.onSelectItem}
                        onClickHoverIcon={item.onClickHoverIcon}
                        isRightClickStyle={item.isRightClickStyle}
                        isPreselect={preselectIndex === item.optionIndex}
                        isSelected={item.isSelected}
                        onMouseMove={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'remote-style':
                return (
                    <RemoteStyleColorListItemV2
                        key={item.data.id}
                        docId={item.docId}
                        item={item.data}
                        dataTestId={item.dataTestId}
                        openStyleId={item.openStyleId}
                        onContextMenu={item.onContextMenu}
                        onSelectItem={item.onSelectItem}
                        onClickHoverIcon={item.onClickHoverIcon}
                        isRightClickStyle={item.isRightClickStyle}
                        isSelected={item.isSelected}
                        isPreselect={preselectIndex === item.optionIndex}
                        onMouseMove={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'unknown-style':
                return (
                    <UnknowStyleColorListItemV2
                        key={item.data.id}
                        docId={item.docId}
                        item={item.data}
                        openStyleId={item.openStyleId}
                        onContextMenu={item.onContextMenu}
                        onSelectItem={item.onSelectItem}
                        onClickHoverIcon={item.onClickHoverIcon}
                        isRightClickStyle={item.isRightClickStyle}
                        isSelected={item.isSelected}
                        isPreselect={preselectIndex === item.optionIndex}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        index={item.optionIndex}
                        recordEnterCallback={recordEnterCallback}
                    />
                )
            case 'collection':
                return <CollectionNameItem key={index} name={item.name} />
            case 'holder':
                return <div key={index} className={classes.placeholderNode}></div>
            case 'library-name':
                return <LibraryNameItem key={index} name={item.name} />
            case 'header':
                return (
                    <div key={index} className={classnames(classes.collectionName, classes.defaultStyleGroupName)}>
                        {item.name}
                    </div>
                )
            case 'group':
                return <GroupNameItem key={index} name={item.name} />
            case 'divider':
                return <div key={index} className={classes.divider} />
        }
    }

    return (
        <Scrollbar
            hideHorizontalScrollbar
            ref={parentRef}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={528}
            data-testid="color-library-list"
        >
            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={onKeyDown} className={classes.itemsContainer}>
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <div
                            key={virtualItem.key}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            {renderItem(virtualItem.index)}
                        </div>
                    ))}
                </div>
            </KeyboardReceiver>
        </Scrollbar>
    )
}

const VirtualColorList = forwardRef<VirturalColorRef, VirtualColorListProps>(VirtualColorListInternal)

type VirtualColorGridItem =
    | { type: 'items'; items: SelectableItem[] }
    | { type: 'collection'; name: string }
    | { type: 'header'; name: string }
    | { type: 'library-name'; name: string }
    | { type: 'group'; name: string }
    | { type: 'holder' }
    | { type: 'divider' }

interface VirtualColorGridProps {
    items: VirtualColorGridItem[]
}

function VirtualColorGridInternal({ items }: VirtualColorGridProps, ref: React.ForwardedRef<VirturalColorRef>) {
    const parentRef = useRef<ScrollbarRef>(null)
    const rowVirtualizer = useVirtualizer({
        count: items.length,
        overscan: IN_JEST_TEST ? 200 : undefined,
        getScrollElement: () => {
            if (parentRef.current) {
                return parentRef.current.getContainerElement()
            }
            return null
        },
        estimateSize: (i) => {
            const item = items[i]
            switch (item.type) {
                case 'items':
                    return 36
                case 'collection':
                case 'group':
                    return 28
                case 'header':
                case 'library-name':
                    return 32
                case 'holder':
                    return 8
                case 'divider':
                    return 16
            }
        },
    })

    const scrollIndex = useCallback(
        (optionIndex: number) => {
            for (let i = 0; i < items.length; ++i) {
                const item = items[i]
                if (item.type === 'items' && item.items.find((v) => v.optionIndex === optionIndex)) {
                    // 滚动到目标item的所在行
                    rowVirtualizer.scrollToIndex(i)
                    return
                }
            }
        },
        [items, rowVirtualizer]
    )
    const selectedOptionIndex = useMemo(() => {
        for (const item of items) {
            if (item.type === 'items') {
                const find = item.items.find((v) => v.isSelected)
                if (find) {
                    return find.optionIndex
                }
            }
        }
        return -1
    }, [items])

    const [needScrollToSelected, setNeedScrollToSeleted] = useState<boolean>(true)
    useEffect(() => {
        if (!needScrollToSelected) {
            return
        }

        if (selectedOptionIndex >= 0) {
            scrollIndex(selectedOptionIndex)
            setNeedScrollToSeleted(false)
        } else {
            scrollIndex(0)
            setNeedScrollToSeleted(false)
        }
    }, [needScrollToSelected, scrollIndex, selectedOptionIndex])

    useImperativeHandle(ref, () => ({
        scrollToSelectedOption: () => {
            setNeedScrollToSeleted(true)
        },
        nextOption: () => {},
        prevOption: () => {},
        onSubmit: () => {},
    }))

    const renderRowItems = (rowItems: SelectableItem[], index: number) => {
        const renderRowItem = (item: SelectableItem) => {
            switch (item.type) {
                case 'local-color':
                    return (
                        <ColorVariableGridItemV2
                            key={item.data.originData.nodeId}
                            className={classes.variable}
                            name={item.data.name}
                            description={item.data.description}
                            color={item.data.color}
                            colorSpace={item.colorSpace}
                            isSelected={item.isSelected}
                            onClick={item.onClick}
                        />
                    )
                case 'unknown-color':
                    return (
                        <ColorVariableGridItemV2
                            key={item.data.nodeId}
                            className={classes.variable}
                            name={item.data.name}
                            description={item.data.description}
                            color={item.data.color}
                            colorSpace={item.colorSpace}
                            onClick={item.onClick}
                            isSelected={item.isSelected}
                        />
                    )
                case 'remote-color':
                    return (
                        <ColorVariableGridItemV2
                            key={item.data.originData.id}
                            className={classes.variable}
                            name={item.data.name}
                            description={item.data.description}
                            color={item.data.color}
                            colorSpace={item.colorSpace}
                            onClick={item.onClick}
                            isSelected={item.isSelected}
                        />
                    )
                case 'local-style':
                    return (
                        <StyleColorGridItemV2
                            key={item.data.id}
                            className={classes.gridStyleSize}
                            item={item.data as StyleColorGridItemV2Props['item']}
                            onContextMenu={item.onContextMenu}
                            onSelectItem={item.onSelectItem}
                            isRightClickStyle={item.isRightClickStyle}
                            isSelected={item.isSelected}
                        />
                    )
                case 'remote-style':
                    return (
                        <RemoteStyleColorGridItemV2
                            key={item.data.id}
                            className={classes.gridStyleSize}
                            imageClassName={classes.gridStyleSize}
                            docId={item.docId}
                            item={item.data}
                            onContextMenu={item.onContextMenu}
                            onSelectItem={item.onSelectItem}
                            isRightClickStyle={item.isRightClickStyle}
                            isSelected={item.isSelected}
                        />
                    )
                case 'unknown-style':
                    return (
                        <UnknowStyleColorGridItemV2
                            key={item.data.id}
                            className={classes.gridStyleSize}
                            imageSize={28}
                            docId={item.docId}
                            item={item.data}
                            onContextMenu={item.onContextMenu}
                            onSelectItem={item.onSelectItem}
                            isRightClickStyle={item.isRightClickStyle}
                            isSelected={item.isSelected}
                        />
                    )
            }
        }

        return (
            <div key={index} className={classes.gridItemGroup}>
                {rowItems.map((item) => renderRowItem(item))}
            </div>
        )
    }
    const renderItem = (index: number) => {
        const item = items[index]

        switch (item.type) {
            case 'items':
                return renderRowItems(item.items, index)
            case 'collection':
                return <CollectionNameItem key={index} name={item.name} />
            case 'holder':
                return <div key={index} className={classes.placeholderNode}></div>
            case 'library-name':
                return <LibraryNameItem key={index} name={item.name} />
            case 'header':
                return (
                    <div key={index} className={classnames(classes.collectionName, classes.defaultStyleGroupName)}>
                        {item.name}
                    </div>
                )
            case 'group':
                return <GroupNameItem key={index} name={item.name} />
            case 'divider':
                return <div key={index} className={classes.divider} />
        }
    }

    return (
        <Scrollbar
            hideHorizontalScrollbar
            ref={parentRef}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={528}
            data-testid="color-library-list"
        >
            <div className={classes.itemsContainer}>
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <div
                            key={virtualItem.key}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            {renderItem(virtualItem.index)}
                        </div>
                    ))}
                </div>
            </div>
        </Scrollbar>
    )
}

const VirtualColorGrid = forwardRef<VirturalColorRef, VirtualColorGridProps>(VirtualColorGridInternal)

export function ColorLibraries(props: ColorLibrariesProps): JSX.Element {
    const {
        selectStyleId,
        selectStyleNodeKey,
        selectColorVariableNodeId,
        onChangeStyle,
        onClickEditStyle,
        onChangeColorVar,
    } = props
    const popupState = useViewState('popupState')
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryPaintStyleState',
        {
            localStyleGroups: [],
            inUseUnknownStyleNodeItems: [],
            subscribedRemoteItems: [],
            inUseOtherRemoteItems: [],
            fakeFieldToForceUpdate: true,
        }
    )
    const librariesColorVariable = useViewState('librariesColorVariable', {
        localKnownColorCollections: [],
        localUnknownColorCollections: [],
        remoteColorLibraries: [],
    })
    const selectionColorScope = useViewState('selectionColorScope', {
        hasText: false,
        hasShape: false,
        hasFrame: false,
    })
    const colorSpace = useRenderColorSpace()
    const command = useCommand()
    const { colorLibraryPreselected, setColorLibraryPreselected } = useEditorContext()
    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
        libraryNodeDataService,
    } = useLibraryComponentService()
    const componentService = useComponentService()
    const [isList, setIsList] = useUserConfigStateV2('stylePickerListLayout')

    const [contextMenuInfo, setContextMenuInfo] = useState<{
        rightClickStyleKey: string
        styleNodeId: string
        rectContainer: RectContainer
    } | null>(null)

    const [searchStr, setSearchStr] = useState<string>('')
    const [isRemoteStyle, setIsRemoteStyle] = useState<boolean>(false)
    const [remoteStyleInfo, setRemoteStyleInfo] = useState<{
        styleId?: string
        docId?: string
        fromFig?: boolean
        name?: string
    }>()

    const noSubscribeButUsedRemoteItems = useMemo(() => {
        const _noSubscribeButUsedRemoteItems = selectStyleNodeKey
            ? inUseOtherRemoteItems.filter((v) =>
                  v.groups.some((group) => group.items.some((item) => item.id === selectStyleNodeKey))
              )
            : []
        return _noSubscribeButUsedRemoteItems
    }, [selectStyleNodeKey, inUseOtherRemoteItems])

    const allowSelectColorStyle = useMemo(() => {
        switch (props.from) {
            case ColorInteractionFrom.FILL:
            case ColorInteractionFrom.STROKE:
            case ColorInteractionFrom.FILL_PAINT:
            case ColorInteractionFrom.STROKE_PAINT:
            case ColorInteractionFrom.SELECTION_COLOR:
            case ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE:
                return true
            case ColorInteractionFrom.BACKGROUND:
            case ColorInteractionFrom.GRID:
            case ColorInteractionFrom.EFFECT:
            case ColorInteractionFrom.STYLE_PAINT:
            case ColorInteractionFrom.COLOR_STOP:
            case ColorInteractionFrom.LOCAL_VARIABLE_EDIT:
            case ColorInteractionFrom.LOCAL_VARIABLE_CREATE_ALIAS:
                return false
        }
    }, [props.from])

    const allowSwitchLibraries = useMemo(() => {
        let showLocalLibrary = librariesColorVariable.localKnownColorCollections.length > 0
        const remoteLibraryMap: Map<string, { id: string; name: string; show: boolean }> = new Map()
        librariesColorVariable.remoteColorLibraries.map((v) => {
            remoteLibraryMap.set(v.id, {
                id: v.id,
                name: v.name,
                show: v.collections.length > 0,
            })
        })
        if (allowSelectColorStyle) {
            showLocalLibrary = showLocalLibrary || localStyleGroups.some((group) => group.items.length)
            ;[...subscribedRemoteItems, ...noSubscribeButUsedRemoteItems].forEach((v) => {
                const library = remoteLibraryMap.get(v.libraryId)
                if (library) {
                    if (!library.show) {
                        library.show = v.groups.some((group) => group.items.length > 0)
                    }
                } else {
                    remoteLibraryMap.set(v.libraryId, {
                        id: v.libraryId,
                        name: v.libraryName,
                        show: v.groups.some((group) => group.items.length > 0),
                    })
                }
            })
        }
        const showRemoteLibraryItems = [...remoteLibraryMap.values()].filter((v) => v.show)
        return { remoteLibraryMap, showLocalLibrary, showRemoteLibraryItems }
    }, [
        librariesColorVariable.localKnownColorCollections.length,
        librariesColorVariable.remoteColorLibraries,
        allowSelectColorStyle,
        localStyleGroups,
        subscribedRemoteItems,
        noSubscribeButUsedRemoteItems,
    ])

    const getColorLibraryPreselect = useCallback(() => {
        if (!colorLibraryPreselected || colorLibraryPreselected === ColorLibraryAll) {
            return ColorLibraryAll
        }
        if (!!selectStyleId || !!selectStyleNodeKey) {
            const isInRemoteLibrary =
                selectStyleNodeKey &&
                (subscribedRemoteItems.some(
                    (v) =>
                        v.libraryId === colorLibraryPreselected &&
                        v.groups.some((group) => group.items.some((item) => item.id === selectStyleNodeKey))
                ) ||
                    noSubscribeButUsedRemoteItems.some(
                        (v) =>
                            v.libraryId === colorLibraryPreselected &&
                            v.groups.some((group) => group.items.some((item) => item.id === selectStyleNodeKey))
                    ))
            if (colorLibraryPreselected === ColorLibraryLocal) {
                if (
                    !isInRemoteLibrary &&
                    localStyleGroups.some((group) => group.items.some((item) => item.id === selectStyleId))
                ) {
                    return ColorLibraryLocal
                }
            } else if (isInRemoteLibrary) {
                if (allowSwitchLibraries.showRemoteLibraryItems.some((v) => v.id === colorLibraryPreselected)) {
                    return colorLibraryPreselected
                }
            }
            return ColorLibraryAll
        }
        if (selectColorVariableNodeId) {
            if (colorLibraryPreselected === ColorLibraryLocal) {
                return ColorLibraryLocal
            }
            if (allowSwitchLibraries.showRemoteLibraryItems.find((v) => v.id === colorLibraryPreselected)) {
                return colorLibraryPreselected
            }
            return ColorLibraryAll
        }
        return ColorLibraryAll
    }, [
        allowSwitchLibraries.showRemoteLibraryItems,
        colorLibraryPreselected,
        noSubscribeButUsedRemoteItems,
        localStyleGroups,
        selectColorVariableNodeId,
        selectStyleId,
        selectStyleNodeKey,
        subscribedRemoteItems,
    ])

    const [colorLibrarySelected, setColorLibrarySelected] = useState<string>(getColorLibraryPreselect)

    const variablePassed = useMemo(() => {
        if (!selectStyleId && !selectStyleNodeKey && selectColorVariableNodeId) {
            const res = command.invokeBridge(CommitType.Noop, GetComputedColorVariableCommand, {
                nodeId: selectColorVariableNodeId,
            })
            return Object.assign(res, {
                name: splitColorVariableName(res.name).variableName,
            })
        }
    }, [command, selectColorVariableNodeId, selectStyleId, selectStyleNodeKey])

    const localColorCollections = useMemo(() => {
        if (colorLibrarySelected !== ColorLibraryLocal && colorLibrarySelected !== ColorLibraryAll) {
            return []
        }
        const _localColorCollections = formateLocalColorCollections(librariesColorVariable.localKnownColorCollections, {
            from: props.from,
            scope: selectionColorScope,
            searchText: searchStr,
        })
        return _localColorCollections
    }, [
        colorLibrarySelected,
        librariesColorVariable.localKnownColorCollections,
        props.from,
        selectionColorScope,
        searchStr,
    ])

    const remoteColorLibraries = useMemo(() => {
        if (colorLibrarySelected === ColorLibraryLocal) {
            return []
        }
        if (colorLibrarySelected === ColorLibraryAll) {
            return formateRemoteColorLibraries(librariesColorVariable.remoteColorLibraries, {
                from: props.from,
                scope: selectionColorScope,
                searchText: searchStr,
            })
        }
        const library = librariesColorVariable.remoteColorLibraries.find((v) => v.id === colorLibrarySelected)
        const _remoteColorLibraries = formateRemoteColorLibraries(library ? [library] : [], {
            from: props.from,
            scope: selectionColorScope,
            searchText: searchStr,
        })
        return _remoteColorLibraries
    }, [librariesColorVariable.remoteColorLibraries, colorLibrarySelected, props.from, searchStr, selectionColorScope])

    const unknownColorVariable = useMemo(() => {
        // 判断入口处的变量在当前选择范围是不是一个未知变量
        if (!variablePassed) {
            return
        }
        const isExsitLocal = () => {
            const isExist = localColorCollections.some(({ freeVariables, groups }) => {
                return (
                    freeVariables.some((freeVariable) => freeVariable.originData.nodeId === variablePassed.nodeId) ||
                    groups.some((group) =>
                        group.variables.some((variable) => variable.originData.nodeId === variablePassed.nodeId)
                    )
                )
            })
            return isExist
        }
        const isExsitRemoteLibrary = (libraries: ReturnType<typeof formateRemoteColorLibraries>) => {
            const isExist = libraries.some((library) => {
                const collection = library.collections.find((v) => v.collection.id === variablePassed.collectionKey)
                return (
                    collection?.freeVariables.some((v) => v.originData.id === variablePassed.key) ||
                    collection?.groups.some((group) =>
                        group.variables.some((variable) => variable.originData.id === variablePassed.key)
                    )
                )
            })
            return isExist
        }
        if (colorLibrarySelected === ColorLibraryAll) {
            if (variablePassed.isLocalVisible) {
                return isExsitLocal() ? undefined : variablePassed
            }
            if (!variablePassed.isLocal) {
                return isExsitRemoteLibrary(remoteColorLibraries) ? undefined : variablePassed
            }
        } else if (colorLibrarySelected === ColorLibraryLocal) {
            if (variablePassed.isLocalVisible) {
                return isExsitLocal() ? undefined : variablePassed
            }
        } else if (!variablePassed.isLocal) {
            const libraries = remoteColorLibraries.filter((v) => v.id === colorLibrarySelected)
            return isExsitRemoteLibrary(libraries) ? undefined : variablePassed
        }
        return variablePassed
    }, [variablePassed, colorLibrarySelected, remoteColorLibraries, localColorCollections])

    const unknownStyle = useMemo(() => {
        // 保持没有变量前的逻辑
        if (!allowSelectColorStyle) {
            return undefined
        }
        for (const value of inUseUnknownStyleNodeItems) {
            if (value.id !== selectStyleId) continue
            return {
                docId: value.publishFile ?? '',
                value,
            }
        }
    }, [allowSelectColorStyle, inUseUnknownStyleNodeItems, selectStyleId])

    const localStyleCollections = useMemo(() => {
        if (
            (colorLibrarySelected !== ColorLibraryLocal && colorLibrarySelected !== ColorLibraryAll) ||
            !allowSelectColorStyle
        ) {
            return []
        }
        return formateLocalStyleCollections(localStyleGroups, {
            searchText: searchStr,
        })
    }, [allowSelectColorStyle, colorLibrarySelected, localStyleGroups, searchStr])

    const remoteStyleLibraries = useMemo(() => {
        if (colorLibrarySelected === ColorLibraryLocal || !allowSelectColorStyle) {
            return []
        }
        const subscribedRemoteItemsScope =
            colorLibrarySelected === ColorLibraryAll
                ? subscribedRemoteItems
                : subscribedRemoteItems.filter((libraryContent) => libraryContent.libraryId === colorLibrarySelected)
        const remoteLibs = formateRemoteStyleLibraries(subscribedRemoteItemsScope, { searchText: searchStr })
        const noSubscribeButUsedRemoteItemsScope =
            colorLibrarySelected === ColorLibraryAll
                ? noSubscribeButUsedRemoteItems
                : noSubscribeButUsedRemoteItems.filter(
                      (libraryContent) => libraryContent.libraryId === colorLibrarySelected
                  )
        const inUseLibs = formateRemoteStyleLibraries(noSubscribeButUsedRemoteItemsScope, {
            searchText: searchStr,
            remoteStyleKey: selectStyleNodeKey,
        })

        return sortArrayByKey([...remoteLibs, ...inUseLibs], 'libraryName')
    }, [
        colorLibrarySelected,
        allowSelectColorStyle,
        subscribedRemoteItems,
        noSubscribeButUsedRemoteItems,
        searchStr,
        selectStyleNodeKey,
    ])

    const openStyleEditor = useMemo(() => {
        const isOpen = popupState?.multiPopup.some(
            (v) => v.type === Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR
        )
        return isOpen && !!attrPanelStyleEditorState?.editingStyleId
    }, [popupState?.multiPopup, attrPanelStyleEditorState?.editingStyleId])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                openPopup
                    ? {
                          type: Wukong.DocumentProto.PopupStateType.POPUP_STATE_TYPE_PAINT_STYLE_EDITOR,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState]
    )

    const onCloseEditor = useCallback(() => {
        setDocumentPopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
            openFromModule: attrPanelStyleEditorState?.openFromModule,
        })
    }, [attrPanelStyleEditorState?.openFromModule, command, setDocumentPopupState])

    const onOpenEditor = useCallback(
        (editingStyleId: string, isCreate?: boolean) => {
            const openFromNodeId = command.invokeBridge(CommitType.Noop, GetSelectionNodeIdsCommandForWasm).value ?? []
            const openFromModule =
                props.from === ColorInteractionFrom.FILL || props.from === ColorInteractionFrom.FILL_PAINT
                    ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_FILL
                    : props.from === ColorInteractionFrom.STROKE || props.from === ColorInteractionFrom.STYLE_PAINT
                    ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_STROKE
                    : props.from === ColorInteractionFrom.SELECTION_COLOR ||
                      props.from === ColorInteractionFrom.SELECTION_COLOR_FAKE_STYLE
                    ? Wukong.DocumentProto.EditorStateFromModule.EDITOR_STATE_FROM_MODULE_SELECT_COLOR
                    : undefined
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                editingStyleId,
                openFromModule,
                openFromNodeId,
                isCreate,
            })
            setDocumentPopupState(true)
        },
        [command, props.from, setDocumentPopupState]
    )

    const onClickApplyStyle = useCallback(
        ({ id }: { id: string }) => {
            onCloseEditor()
            onChangeStyle?.(id)
        },
        [onChangeStyle, onCloseEditor]
    )

    const onClickHoverIcon = useCallback(
        (
            { id }: { id: string },
            containerDomRect: any,
            remoteStyleId?: string,
            docId?: string,
            name?: string,
            fromFig?: boolean
        ) => {
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            attrPanelStyleEditorState?.editingStyleId === id ? onCloseEditor() : onOpenEditor(id)
            onClickEditStyle(
                { left: containerDomRect.left, top: containerDomRect.top },
                { styleId: remoteStyleId, docId, name, fromFig }
            )
        },
        [attrPanelStyleEditorState?.editingStyleId, onCloseEditor, onOpenEditor, onClickEditStyle]
    )
    const onContextMenu = useCallback(
        (
            rightClickStyleKey: string,
            { id }: { id: string },
            e: any,
            remoteStyleId?: string,
            docId?: string,
            name?: string,
            fromFig?: boolean
        ) => {
            e.stopPropagation()
            e.preventDefault()
            const { clientX, clientY } = e
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            setContextMenuInfo({
                rightClickStyleKey,
                styleNodeId: id,
                rectContainer: {
                    top: clientY,
                    bottom: clientY,
                    right: clientX,
                    left: clientX,
                },
            })
        },
        []
    )

    const editStyle = useCallback(
        (styleId: string, position: Position) => {
            setContextMenuInfo(null)
            onOpenEditor(styleId)
            onClickEditStyle(position, remoteStyleInfo)
        },
        [onOpenEditor, onClickEditStyle, remoteStyleInfo]
    )

    const deleteStyle = useCallback(
        (styleId: string) => {
            setContextMenuInfo(null)
            command.invoke(cmdDeleteStyleNode, styleId)
            command.commitUndo()
        },
        [command]
    )

    const jumpToOrigin = useCallback(() => {
        componentService.jumpToOriginDocument({
            docId: remoteStyleInfo?.docId,
            nodeId: remoteStyleInfo?.styleId,
            name: remoteStyleInfo?.name,
            fromFig: remoteStyleInfo?.fromFig,
        })
    }, [
        componentService,
        remoteStyleInfo?.docId,
        remoteStyleInfo?.styleId,
        remoteStyleInfo?.name,
        remoteStyleInfo?.fromFig,
    ])

    const noLibrariesData = useMemo(() => {
        const hasUnknown = !!(unknownColorVariable || (allowSelectColorStyle ? unknownStyle : false))
        return (
            !hasUnknown && !allowSwitchLibraries.showLocalLibrary && !allowSwitchLibraries.showRemoteLibraryItems.length
        )
    }, [
        allowSelectColorStyle,
        allowSwitchLibraries.showLocalLibrary,
        allowSwitchLibraries.showRemoteLibraryItems.length,
        unknownColorVariable,
        unknownStyle,
    ])

    const noSearchResult = useMemo(() => {
        const hasUnknown = !!(unknownColorVariable || (allowSelectColorStyle ? unknownStyle : false))
        if (!searchStr || hasUnknown) {
            return false
        }
        const noColorVariable = localColorCollections.length === 0 && !remoteColorLibraries?.length
        if (allowSelectColorStyle) {
            const noColorStyle =
                localStyleCollections.length === 0 &&
                !remoteStyleLibraries?.filter((v) => v.groups.length > 0).length &&
                !unknownStyle
            return noColorVariable && noColorStyle
        } else {
            return noColorVariable
        }
    }, [
        searchStr,
        unknownColorVariable,
        localColorCollections.length,
        remoteColorLibraries?.length,
        allowSelectColorStyle,
        localStyleCollections.length,
        remoteStyleLibraries,
        unknownStyle,
    ])

    const getSelectOptionName = (value: string) => {
        if (value === ColorLibraryAll) return translation('AllLibraries')
        if (value === ColorLibraryLocal) return translation('LocalLibrary')
        return allowSwitchLibraries.remoteLibraryMap.get(value)?.name
    }

    const onClickLocalColorVariable = useCallback(
        (nodeId: string) => {
            onChangeColorVar({
                dataType: featureSwitchManager.isEnabled('color-variable-apply-type')
                    ? Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS
                    : Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_COLOR,
                resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR,
                value: { alias: nodeId },
            })
        },
        [onChangeColorVar]
    )

    const onClickRemoteColorVariable = useCallback(
        async (data: Wukong.DocumentProto.IRemoteColorVariable) => {
            const newNodeId = await libraryNodeDataService.createRemoteVariableNode({
                isLocal: false,
                localNodeId: null,
                ossClientType: LibraryResourceOssClientType.Variable,
                remoteDocId: data.documentId,
                remoteNodeId: data.nodeId,
                nodeDataPath: data.nodeDataPath ?? '',
                key: data.id,
            })
            if (newNodeId) {
                onClickLocalColorVariable(newNodeId)
            }
        },
        [libraryNodeDataService, onClickLocalColorVariable]
    )

    const virtualColorRef = useRef<VirturalColorRef>(null)

    const onSearchKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.nativeEvent.isComposing) {
            return
        }

        if (e.key === 'ArrowDown') {
            e.stopPropagation()
            e.preventDefault()
            virtualColorRef.current?.nextOption()
        } else if (e.key === 'ArrowUp') {
            e.stopPropagation()
            e.preventDefault()
            virtualColorRef.current?.prevOption()
        } else if (e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            virtualColorRef.current?.onSubmit()
        }
    }, [])

    const onChangeColorLibrarySelected = useCallback(
        (v: string) => {
            setColorLibraryPreselected(v)
            setColorLibrarySelected(v)
            virtualColorRef.current?.scrollToSelectedOption()
        },
        [setColorLibraryPreselected]
    )

    const onSearch = useCallback(
        (str: string) => {
            if (str === searchStr) {
                return
            }
            setSearchStr(str)
            virtualColorRef.current?.scrollToSelectedOption()
        },
        [searchStr]
    )

    const buildRenderListItems = () => {
        const renderListItems: VirtualColorListItem[] = []
        const nextOptionIndex = uidGenerator()

        let prevSectionType: 'noop' | 'unknown' | 'local' | string = 'noop'
        const insertDividerIfNeed = (type: 'noop' | 'unknown' | 'local' | string) => {
            if (prevSectionType !== 'noop' && prevSectionType !== type) {
                renderListItems.push({ type: 'divider' })
            }
            prevSectionType = type
        }

        if (unknownColorVariable) {
            renderListItems.push({
                type: 'unknown-color',
                optionIndex: nextOptionIndex(),
                isSelected: selectColorVariableNodeId === unknownColorVariable.nodeId,
                data: unknownColorVariable,
                onClick: () => onClickLocalColorVariable(unknownColorVariable.nodeId),
                colorSpace: colorSpace,
            })
            prevSectionType = 'unknown'
        } else if (unknownStyle) {
            renderListItems.push({
                type: 'unknown-style',
                optionIndex: nextOptionIndex(),
                isSelected: selectStyleId === unknownStyle.value.id,
                docId: unknownStyle.docId,
                data: unknownStyle.value,
                onContextMenu: (...args) => {
                    onContextMenu(`${unknownStyle.value.id}-unknown`, ...args)
                },
                onSelectItem: onClickApplyStyle,
                onClickHoverIcon: onClickHoverIcon,
                isRightClickStyle: contextMenuInfo?.rightClickStyleKey === `${unknownStyle.value.id}-unknown`,
                openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
            })
            prevSectionType = 'unknown'
        } else {
            renderListItems.push({ type: 'holder' })
            prevSectionType = 'noop'
        }

        localColorCollections.forEach((collection) => {
            insertDividerIfNeed('local')

            renderListItems.push({ type: 'collection', name: collection.collection.name })

            collection.freeVariables.forEach((variable) => {
                renderListItems.push({
                    type: 'local-color',
                    optionIndex: nextOptionIndex(),
                    isSelected: variablePassed?.nodeId === variable.originData.nodeId,
                    onClick: () => onClickLocalColorVariable(variable.originData.nodeId),
                    colorSpace: colorSpace,
                    data: variable,
                })
            })

            collection.groups.forEach((group) => {
                renderListItems.push({ type: 'group', name: group.name })

                group.variables.forEach((variable) => {
                    renderListItems.push({
                        type: 'local-color',
                        optionIndex: nextOptionIndex(),
                        isSelected: variablePassed?.nodeId === variable.originData.nodeId,
                        onClick: () => onClickLocalColorVariable(variable.originData.nodeId),
                        colorSpace: colorSpace,
                        data: variable,
                    })
                })
            })
        })

        localStyleCollections.forEach(({ name, items }, index) => {
            insertDividerIfNeed('local')

            if (index === 0) {
                renderListItems.push({ type: 'header', name: translation('Styles') })
            }

            if (name) {
                renderListItems.push({ type: 'group', name })
            }

            items.forEach((item, index2) => {
                renderListItems.push({
                    type: 'local-style',
                    data: item,
                    optionIndex: nextOptionIndex(),
                    isSelected: selectStyleId === item.id,
                    openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                    onContextMenu: (...args) => {
                        onContextMenu(`${item.id}-show-${index}-${index2}`, ...args)
                    },
                    dataTestId: `${LibraryStyleTestId.LocalPaintStyleItem}-${item.id}`,
                    onSelectItem: onClickApplyStyle,
                    onClickHoverIcon: onClickHoverIcon,
                    isRightClickStyle: contextMenuInfo?.rightClickStyleKey === `${item.id}-show-${index}-${index2}`,
                })
            })
        })

        remoteColorLibraries.forEach((library) => {
            insertDividerIfNeed(library.id)

            if (colorLibrarySelected === ColorLibraryAll) {
                renderListItems.push({ type: 'library-name', name: library.name })
            }

            library.collections.forEach((collection) => {
                renderListItems.push({ type: 'collection', name: collection.collection.name })

                collection.freeVariables.forEach((variable) => {
                    renderListItems.push({
                        type: 'remote-color',
                        optionIndex: nextOptionIndex(),
                        isSelected: variablePassed?.key === variable.originData.id,
                        data: variable,
                        onClick: () => onClickRemoteColorVariable(variable.originData),
                        colorSpace: colorSpace,
                    })
                })

                collection.groups.forEach((group) => {
                    renderListItems.push({ type: 'group', name: group.name })

                    group.variables.forEach((variable) => {
                        renderListItems.push({
                            type: 'remote-color',
                            optionIndex: nextOptionIndex(),
                            isSelected: variablePassed?.key === variable.originData.id,
                            data: variable,
                            onClick: () => onClickRemoteColorVariable(variable.originData),
                            colorSpace: colorSpace,
                        })
                    })
                })
            })

            const remoteStyleLibrary = remoteStyleLibraries.find((v) => v.libraryId === library.id)
            if (remoteStyleLibrary) {
                remoteStyleLibrary.groups.forEach(({ name, items }, index2) => {
                    if (index2 === 0) {
                        renderListItems.push({ type: 'header', name: translation('Styles') })
                    }

                    if (name) {
                        renderListItems.push({ type: 'group', name })
                    }

                    items.forEach((item, index3) => {
                        renderListItems.push({
                            type: 'remote-style',
                            data: item,
                            docId: library.documentId,
                            optionIndex: nextOptionIndex(),
                            isSelected: selectStyleNodeKey === item.id,
                            dataTestId: `${LibraryStyleTestId.RemotePaintStyleItem}-${library.name}-${index3}`,
                            openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                            onContextMenu: (...args) => {
                                onContextMenu(`${library.id}-${item.id}-${index2}-${index3}`, ...args)
                            },
                            onSelectItem: onClickApplyStyle,
                            onClickHoverIcon: onClickHoverIcon,
                            isRightClickStyle:
                                contextMenuInfo?.rightClickStyleKey === `${library.id}-${item.id}-${index2}-${index3}`,
                        })
                    })
                })
            }
        })

        remoteStyleLibraries.forEach((library) => {
            if (library.groups.length > 0 && !remoteColorLibraries.some((v) => v.id === library.libraryId)) {
                insertDividerIfNeed(library.libraryId)

                if (colorLibrarySelected === ColorLibraryAll) {
                    renderListItems.push({ type: 'library-name', name: library.libraryName })
                }

                library.groups.forEach(({ name, items }, index2) => {
                    if (index2 === 0) {
                        renderListItems.push({ type: 'header', name: translation('Styles') })
                    }

                    if (name) {
                        renderListItems.push({ type: 'group', name })
                    }

                    items.forEach((item, index3) => {
                        renderListItems.push({
                            type: 'remote-style',
                            data: item,
                            docId: library.docId,
                            optionIndex: nextOptionIndex(),
                            isSelected: selectStyleNodeKey === item.id,
                            dataTestId: `${LibraryStyleTestId.RemotePaintStyleItem}-${library.libraryName}-${index3}`,
                            openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                            onContextMenu: (...args) => {
                                onContextMenu(`${item.id}-${item.id}-${index2}-${index3}`, ...args)
                            },
                            onSelectItem: onClickApplyStyle,
                            onClickHoverIcon: onClickHoverIcon,
                            isRightClickStyle:
                                contextMenuInfo?.rightClickStyleKey === `${item.id}-${item.id}-${index2}-${index3}`,
                        })
                    })
                })
            }
        })

        return renderListItems
    }

    const buildRenderGridItems = () => {
        const renderListItems: VirtualColorGridItem[] = []
        const nextOptionIndex = uidGenerator()

        let prevSectionType: 'noop' | 'unknown' | 'local' | string = 'noop'
        const insertDividerIfNeed = (type: 'noop' | 'unknown' | 'local' | string) => {
            if (prevSectionType !== 'noop' && prevSectionType !== type) {
                renderListItems.push({ type: 'divider' })
            }
            prevSectionType = type
        }

        if (unknownColorVariable) {
            renderListItems.push({
                type: 'items',
                items: [
                    {
                        type: 'unknown-color',
                        optionIndex: nextOptionIndex(),
                        isSelected: selectColorVariableNodeId === unknownColorVariable.nodeId,
                        data: unknownColorVariable,
                        onClick: () => onClickLocalColorVariable(unknownColorVariable.nodeId),
                        colorSpace: colorSpace,
                    },
                ],
            })
            prevSectionType = 'unknown'
        } else if (unknownStyle) {
            renderListItems.push({
                type: 'items',
                items: [
                    {
                        type: 'unknown-style',
                        optionIndex: nextOptionIndex(),
                        isSelected: selectStyleId === unknownStyle.value.id,
                        docId: unknownStyle.docId,
                        data: unknownStyle.value,
                        onContextMenu: (...args) => {
                            onContextMenu(`${unknownStyle.value.id}-unknown`, ...args)
                        },
                        onSelectItem: onClickApplyStyle,
                        onClickHoverIcon: onClickHoverIcon,
                        isRightClickStyle: contextMenuInfo?.rightClickStyleKey === `${unknownStyle.value.id}-unknown`,
                        openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                    },
                ],
            })
            prevSectionType = 'unknown'
        } else {
            renderListItems.push({ type: 'holder' })
            prevSectionType = 'noop'
        }

        localColorCollections.forEach((collection) => {
            insertDividerIfNeed('local')

            renderListItems.push({ type: 'collection', name: collection.collection.name })

            createListChunks(
                collection.freeVariables,
                6,
                (variable): SelectableItem => ({
                    type: 'local-color',
                    optionIndex: nextOptionIndex(),
                    isSelected: variablePassed?.nodeId === variable.originData.nodeId,
                    onClick: () => onClickLocalColorVariable(variable.originData.nodeId),
                    colorSpace: colorSpace,
                    data: variable,
                })
            ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))

            collection.groups.forEach((group) => {
                renderListItems.push({ type: 'group', name: group.name })
                createListChunks(
                    group.variables,
                    6,
                    (variable): SelectableItem => ({
                        type: 'local-color',
                        optionIndex: nextOptionIndex(),
                        isSelected: variablePassed?.nodeId === variable.originData.nodeId,
                        onClick: () => onClickLocalColorVariable(variable.originData.nodeId),
                        colorSpace: colorSpace,
                        data: variable,
                    })
                ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))
            })
        })

        localStyleCollections.forEach(({ name, items }, index) => {
            insertDividerIfNeed('local')

            if (index === 0) {
                renderListItems.push({ type: 'header', name: translation('Styles') })
            }

            if (name) {
                renderListItems.push({ type: 'group', name })
            }

            createListChunks(
                items,
                6,
                (item, index2): SelectableItem => ({
                    type: 'local-style',
                    data: item,
                    optionIndex: nextOptionIndex(),
                    isSelected: selectStyleId === item.id,
                    openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                    onContextMenu: (...args) => {
                        onContextMenu(`${item.id}-show-${index}-${index2}`, ...args)
                    },
                    dataTestId: `${LibraryStyleTestId.LocalPaintStyleItem}-${item.id}`,
                    onSelectItem: onClickApplyStyle,
                    onClickHoverIcon: onClickHoverIcon,
                    isRightClickStyle: contextMenuInfo?.rightClickStyleKey === `${item.id}-show-${index}-${index2}`,
                })
            ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))
        })

        remoteColorLibraries.forEach((library) => {
            insertDividerIfNeed(library.id)

            if (colorLibrarySelected === ColorLibraryAll) {
                renderListItems.push({ type: 'library-name', name: library.name })
            }

            library.collections.forEach((collection) => {
                renderListItems.push({ type: 'collection', name: collection.collection.name })

                createListChunks(
                    collection.freeVariables,
                    6,
                    (variable): SelectableItem => ({
                        type: 'remote-color',
                        optionIndex: nextOptionIndex(),
                        isSelected: variablePassed?.key === variable.originData.id,
                        data: variable,
                        onClick: () => onClickRemoteColorVariable(variable.originData),
                        colorSpace: colorSpace,
                    })
                ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))

                collection.groups.forEach((group) => {
                    renderListItems.push({ type: 'group', name: group.name })

                    createListChunks(
                        group.variables,
                        6,
                        (variable): SelectableItem => ({
                            type: 'remote-color',
                            optionIndex: nextOptionIndex(),
                            isSelected: variablePassed?.key === variable.originData.id,
                            data: variable,
                            onClick: () => onClickRemoteColorVariable(variable.originData),
                            colorSpace: colorSpace,
                        })
                    ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))
                })
            })

            const remoteStyleLibrary = remoteStyleLibraries.find((v) => v.libraryId === library.id)
            if (remoteStyleLibrary) {
                remoteStyleLibrary.groups.forEach(({ name, items }, index2) => {
                    if (index2 === 0) {
                        renderListItems.push({ type: 'header', name: translation('Styles') })
                    }

                    if (name) {
                        renderListItems.push({ type: 'group', name })
                    }

                    createListChunks(
                        items,
                        6,
                        (item, index3): SelectableItem => ({
                            type: 'remote-style',
                            data: item,
                            docId: library.documentId,
                            optionIndex: nextOptionIndex(),
                            isSelected: selectStyleNodeKey === item.id,
                            dataTestId: `${LibraryStyleTestId.RemotePaintStyleItem}-${library.name}-${index3}`,
                            openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                            onContextMenu: (...args) => {
                                onContextMenu(`${library.id}-${item.id}-${index2}-${index3}`, ...args)
                            },
                            onSelectItem: onClickApplyStyle,
                            onClickHoverIcon: onClickHoverIcon,
                            isRightClickStyle:
                                contextMenuInfo?.rightClickStyleKey === `${library.id}-${item.id}-${index2}-${index3}`,
                        })
                    ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))
                })
            }
        })

        remoteStyleLibraries.forEach((library) => {
            if (library.groups.length > 0 && !remoteColorLibraries.some((v) => v.id === library.libraryId)) {
                insertDividerIfNeed(library.libraryId)

                if (colorLibrarySelected === ColorLibraryAll) {
                    renderListItems.push({ type: 'library-name', name: library.libraryName })
                }

                library.groups.forEach(({ name, items }, index2) => {
                    if (index2 === 0) {
                        renderListItems.push({ type: 'header', name: translation('Styles') })
                    }

                    if (name) {
                        renderListItems.push({ type: 'group', name })
                    }

                    createListChunks(
                        items,
                        6,
                        (item, index3): SelectableItem => ({
                            type: 'remote-style',
                            data: item,
                            docId: library.docId,
                            optionIndex: nextOptionIndex(),
                            isSelected: selectStyleNodeKey === item.id,
                            dataTestId: `${LibraryStyleTestId.RemotePaintStyleItem}-${library.libraryName}-${index3}`,
                            openStyleId: openStyleEditor ? attrPanelStyleEditorState?.editingStyleId : undefined,
                            onContextMenu: (...args) => {
                                onContextMenu(`${item.id}-${item.id}-${index2}-${index3}`, ...args)
                            },
                            onSelectItem: onClickApplyStyle,
                            onClickHoverIcon: onClickHoverIcon,
                            isRightClickStyle:
                                contextMenuInfo?.rightClickStyleKey === `${item.id}-${item.id}-${index2}-${index3}`,
                        })
                    ).forEach((chunk) => renderListItems.push({ type: 'items', items: chunk }))
                })
            }
        })

        return renderListItems
    }

    return (
        <div className={classes.colorLibraries}>
            <InputV2.Search
                rootClassName={classes.search}
                onSearch={onSearch}
                autoFocus
                onKeyDown={onSearchKeyDown}
                placeholder={translation('Search')}
                skipComposing
            />
            <div className={classes.header}>
                <Select.MinimalSingleLevel
                    onChange={onChangeColorLibrarySelected}
                    value={colorLibrarySelected}
                    label={getSelectOptionName(colorLibrarySelected)}
                    dataTestIds={{
                        triggerFocus: 'color-library-select-trigger',
                        container: 'color-library-select-container',
                    }}
                    className={classes.select}
                    maxWidth={460}
                >
                    <Select.MinimalSingleLevel.Option value={ColorLibraryAll}>
                        {getSelectOptionName(ColorLibraryAll)}
                    </Select.MinimalSingleLevel.Option>
                    {allowSwitchLibraries.showLocalLibrary ? (
                        <Select.MinimalSingleLevel.Option value={ColorLibraryLocal} splitLineTop>
                            {getSelectOptionName(ColorLibraryLocal)}
                        </Select.MinimalSingleLevel.Option>
                    ) : null}
                    {allowSwitchLibraries.showRemoteLibraryItems.map((v, index) => (
                        <Select.MinimalSingleLevel.Option
                            value={v.id}
                            key={v.id}
                            splitLineTop={index === 0}
                            tooltipTitle={v.name}
                        >
                            {v.name}
                        </Select.MinimalSingleLevel.Option>
                    ))}
                </Select.MinimalSingleLevel>
                <WKIconButton
                    data-testid="layout"
                    icon={isList ? <MonoIconPanelGrid16 /> : <MonoIconPanelList16 />}
                    onClick={() => setIsList((v) => !v)}
                    preventFocus
                />
            </div>
            {noLibrariesData ? (
                <div className={classes.emptyStyle}>
                    <div className={classes.emptyStyle_text} data-testid="empty-style-text">
                        {translation(allowSelectColorStyle ? 'NoColorStylesVariables' : 'NoColorVariables')}
                    </div>
                    <div className={classes.emptyStyle_button} data-testid="empty-style-button">
                        <WKButton
                            type="secondary"
                            className={classes.button}
                            onClick={goToRemoteLibraryHome}
                            icon={<MonoIconPanelComponentLibrary16 />}
                        >
                            {translation('BrowseLibraries')}
                        </WKButton>
                    </div>
                </div>
            ) : noSearchResult ? (
                <div className={classes.emptyStyle}>
                    <div className={classes.emptyStyle_text}>
                        {translation(allowSelectColorStyle ? 'NoMatchesFound2' : 'NoMatchesFound1')}
                    </div>
                </div>
            ) : isList ? (
                <VirtualColorList items={buildRenderListItems()} ref={virtualColorRef} />
            ) : (
                <VirtualColorGrid items={buildRenderGridItems()} ref={virtualColorRef} />
            )}
            <DropdownV2.NoTriggerMultiLevel<
                DropdownDefaultCustomNode & { handler?: (_styleId: string, _positon: Position) => void }
            >
                isOpenState={!!contextMenuInfo}
                triggerRect={() => contextMenuInfo?.rectContainer!}
                onClose={() => setContextMenuInfo(null)}
                onChange={(v) => v.customNode?.handler?.(contextMenuInfo!.styleNodeId, contextMenuInfo!.rectContainer)}
                pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                onKeyboard={(e) => e.key === 'Escape' && e.stopPropagation()}
                items={[
                    {
                        name: translation('GoToStyle'),
                        hidden: !isRemoteStyle,
                        customNode: {
                            handler: jumpToOrigin,
                        },
                    },
                    {
                        name: translation('EditStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: editStyle,
                        },
                    },
                    {
                        name: translation('DeleteStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: deleteStyle,
                        },
                    },
                ]}
            />
        </div>
    )
}
