import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'prototype-tlframe-offset',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.ALL_USER,
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
