import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'do-not-update-editor-type-in-storage-on-bootstrap',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.ALL,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.MOTIFF_GROUP },
            // config: {
            //     type: SwitchStrategyType.SPECIFIC_SUFFIX,
            //     suffix: ['@kanyun.com'],
            // },
            // config: {
            //     type: SwitchStrategyType.ALL_USER,
            // },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
