import {
    MonoIconCommonClose16,
    MonoIconCommonSuccessLine48,
    WKButton,
    WKDialog,
    WKIconButton,
    WKTextButton,
} from '../../../../../../../ui-lib/src'
import { PluginDevStatus, usePluginDevelopment } from '../use-plugin-development'
import { translation } from './plugin-dev-publish-success.translation'

export function PluginDevelopmentPublishSuccess() {
    const { setPluginDevStatus, publishSuccessPlugin, currentOrganization, openEditorPermissionDialog } =
        usePluginDevelopment()

    return (
        <WKDialog
            visible={true}
            showTitle={false}
            footer={null}
            width={400}
            onCancel={() => {
                setPluginDevStatus(PluginDevStatus.Main)
            }}
            bodyStyle={{ padding: 0 }}
            testId="plugin-dev-publish-success-dialog"
        >
            <div className="relative px-60px py-51px flex flex-col justify-center items-center">
                <WKIconButton
                    className="absolute top-18px right-18px"
                    size="small"
                    icon={<MonoIconCommonClose16 className="color-$wk-v2-label-color-gray-8" />}
                    onClick={() => {
                        setPluginDevStatus(PluginDevStatus.Main)
                    }}
                />
                <MonoIconCommonSuccessLine48 className="color-$wk-green-7" />

                <div className="mt-3 wk-text-14 wk-font-medium color-$wk-v2-label-color-gray-13">
                    {translation('PublishSuccess')}
                </div>

                <div className="mt-3 text-center wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-11">
                    {translation('AllMembersCanUse', {
                        orgName: currentOrganization?.name ?? '',
                        pluginName: publishSuccessPlugin?.name ?? '',
                    })}
                    <WKTextButton
                        type="primary"
                        size={12}
                        onClick={() => {
                            openEditorPermissionDialog(publishSuccessPlugin)
                        }}
                    >
                        {translation('ManagePermission')}
                    </WKTextButton>
                    {translation('InviteMembers')}
                </div>

                <WKButton
                    className="mt-8 w-100px"
                    onClick={() => {
                        setPluginDevStatus(PluginDevStatus.Main)
                    }}
                    type="primary"
                    dataTestId="publish-success-done-btn"
                >
                    {translation('Done')}
                </WKButton>
            </div>
        </WKDialog>
    )
}
