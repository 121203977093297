import { IconAdd16, MonoIconPanelResetInstance16, Position, SimpleDrag, Tooltip } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import React, { useCallback, useRef } from 'react'
import { usePrototypeInteractionContext } from '../../../context/prototype-interaction-context'
import { IconButton } from '../../atom/button/icon-button'
import { Title } from '../../atom/title/title'
import { translation } from './index.translation'
import { InteractionItem } from './interaction-item'

export function PrototypeInteraction() {
    const {
        isShow,
        isEmpty,
        disabledDragging,
        isShowResetButton,
        isPresetMobile,
        showTriggers,
        triggersConflictList,
        allTargetNodes,
        selectedIndexList,
        interactionsRef,
        onClickTitle,
        onClickAdd,
        onClickRemove,
        onMouseEnterItem,
        onMouseLeaveItem,
        onSelectItem,
        onDragDone,
        resetInstancePrototypeInteractions,
    } = usePrototypeInteractionContext()
    const titleRef = useRef<HTMLDivElement>(null)

    const _onClickTitle = useCallback(
        (e: React.MouseEvent, type: 'title' | 'add') => {
            let pos: Position | undefined
            if (titleRef.current) {
                const rect = titleRef.current.getBoundingClientRect()
                pos = { top: rect.top + 32, left: rect.left }
            }
            if (type === 'add') {
                e.stopPropagation()
                onClickAdd(pos)
            } else {
                onClickTitle(pos)
            }
        },
        [onClickTitle, onClickAdd]
    )

    if (!isShow) {
        return null
    }

    return (
        <div
            className={classNames('b-b border-color-$wk-v2-stroke-color-gray-3', isEmpty ? 'py-2' : 'pt-2 pb-3')}
            data-testid={'prototype-interaction'}
        >
            <Title grayTheme={isEmpty} onClick={(e) => _onClickTitle(e, 'title')} ref={titleRef}>
                <Title.Left>{translation('Interactions')}</Title.Left>
                <Title.Right>
                    {isShowResetButton && (
                        <Tooltip title={translation('ResetInteractions')}>
                            <IconButton
                                icon={<MonoIconPanelResetInstance16 />}
                                data-testid="reset-interaction"
                                onClick={resetInstancePrototypeInteractions}
                                selected={false}
                            />
                        </Tooltip>
                    )}
                    <IconButton
                        icon={<IconAdd16 />}
                        data-testid="add-interaction"
                        onClick={(e) => _onClickTitle(e, 'add')}
                        selected={false}
                    />
                </Title.Right>
            </Title>
            <div ref={interactionsRef}>
                <SimpleDrag
                    selectedIndexList={selectedIndexList}
                    items={showTriggers}
                    onSelectChange={onSelectItem}
                    onDragDone={onDragDone}
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    {showTriggers.map((trigger, idx) => (
                        <SimpleDrag.Item
                            key={idx}
                            itemIndex={idx}
                            disabledDragging={disabledDragging}
                            dataTestIds={{
                                item: `interaction-drag-item-${idx}`,
                                dragIcon: 'interaction-drag-icon',
                            }}
                            onMouseEnter={() => onMouseEnterItem(trigger)}
                            onMouseLeave={onMouseLeaveItem}
                        >
                            <InteractionItem
                                key={idx}
                                index={idx}
                                data={trigger}
                                selected={selectedIndexList.includes(idx)}
                                dragSelected={false}
                                allTargetNodes={allTargetNodes}
                                isPresetMobile={isPresetMobile}
                                isConflict={triggersConflictList[idx]}
                                onClickRemove={onClickRemove}
                            />
                        </SimpleDrag.Item>
                    ))}
                </SimpleDrag>
            </div>
        </div>
    )
}
