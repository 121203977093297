import { useUpdateEffect } from 'react-use'
import type { RGB } from '../../../../../document/node/node'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { useEyeDropper } from '../../../eye-dropper/use-eye-dropper'
import { ColorPicker } from '../color-picker/color-picker'
import { ColorSpace } from '../color-picker/utils/color-translate'

export interface BlendColorProps {
    color: RGB
    colorSpace: ColorSpace
    opacity: number
    onChangeColor?: (value: RGB, options?: InputOptionsForUndoSquash) => void
    onChangeOpacity?: (value: number, options?: InputOptionsForUndoSquash) => void
}
export function BlendColor(props: BlendColorProps) {
    const { color, colorSpace, opacity, onChangeOpacity, onChangeColor } = props
    const { eyeDropperState, onChangeEyeDropper, applyPickColor } = useEyeDropper(onChangeColor)

    useUpdateEffect(() => {
        applyPickColor(onChangeColor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eyeDropperState?.isExecutePickColor])

    return (
        <div data-testid="blend-color">
            <ColorPicker
                color={color}
                colorSpace={colorSpace}
                opacity={opacity}
                onChangeColor={onChangeColor}
                onChangeOpacity={onChangeOpacity}
                onChangeEyeDropper={onChangeEyeDropper}
                selectEyedropper={eyeDropperState?.isDisplay}
            />
            {/* <DocumentColor /> */}
        </div>
    )
}
