import { Wukong } from '@wukong/bridge-proto'
import type { Position } from '../../../../../ui-lib/src'
import { LocalFeatureSwitch } from '../../../local-feature-switch/config'
import type { FontInfoExt } from '../../../main/font/interface'
import { DEFAULT_LEFT_PANEL_WIDTH, DEV_MODE_RIGHT_PANEL_WIDTH } from '../../../main/layout/layout-default-value'
import {
    defaultDevModeInspectCodeConfig,
    defaultDevModeInspectExportUserConfig,
} from '../../../main/user-config/user-config-default-value'
import { FolderSortState } from '../../../space/app/folder/types'
import {
    DocListPageSortKey,
    OrganizationManageMemberPageSortKey,
    OrganizationManageTeamPageSortKey,
    ShowType,
    SortOrder,
    TeamFoldersPageSortKey,
    TeamMembersPageSortKey,
} from '../../../space/util/types'
import { ComponentPickerViewMode as ComponentPickerModalViewMode } from '../../../ui/component/design-panel-v2/component-instance-panel/components/component-picker/type'
import { FrameTemplateGroupNameCode } from '../../../ui/component/design/frame-template/template-data'
import { DocStyleFolderCollapse } from '../../../ui/component/design/style/types'
import { LocalPlugin } from '../../../ui/component/plugin/plugin-development/template/type'
import { PluginEnableConfig } from '../../../ui/component/plugin/plugin-management/types'
import { PluginsPosition } from '../../../ui/component/plugin/type'
import { UserActiveRecordMap } from '../../../ui/hooks/type'

enum ColorModeType {
    HEX = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_E_X,
    RGB = Wukong.DocumentProto.ColorMode.COLOR_MODE_R_G_B,
    CSS = Wukong.DocumentProto.ColorMode.COLOR_MODE_C_S_S,
    HSL = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_S_L,
    HSB = Wukong.DocumentProto.ColorMode.COLOR_MODE_H_S_B,
}

const EditorType = Wukong.DocumentProto.EditorType

/**
 * [how to add localStorage key: 1/3]
 * LocalStorage key
 */
export enum LocalStorageKey {
    // ------------- For Test ------------- //
    Test = 'test',
    TestCanRemove = 'testCanRemove',

    // ------------- Wasm ------------- //
    // eslint-disable-next-line @typescript-eslint/no-shadow
    EditorType = 'editorType',
    DisableWebGPU = 'disable-webgpu',

    // ----------- Prototype ----------- //
    PresentLeftFlowPanelWidth = 'presentLeftFlowPanelWidth',

    // ----------- Variable ----------- //
    LastSelectedVariableSetId = 'lastSelectedVariableSetId',
    CollectionCollapsedGroupKeys = 'collectionCollapsedGroupKeys',

    // ------------- Web -------------- //
    LocalEnableFeatures = 'LOCAL_ENABLED_FEATURES',
    SidebarOpenState = 'sidebar-open-state',
    LocalDisabledFeatures = 'LOCAL_DISABLED_FEATURES',
    UserSelectOrgId = 'user-select-orgId',
    MirrorVisited = 'wk-mirror-visited',
    Language = 'language',
    NewCodeTip = 'new-code-tip',
    CurrentOrgId = 'currOrgId',
    DevModeInspectCodeConfig = 'devModeInspectCodeConfig',
    LandingPagePrompt = 'landingPagePrompt',
    RecentEmoji = 'recentEmoji',
    WebGLSupport = 'webGLSupport',
    CompTriggerCount = '__wk_comp_trigger_count',
    ScaleSpeedEvalFunction = 'scaleSpeedEvalFunction',
    FontHistory = 'font.history',
    PostscriptName = 'postscriptName-family-style-upload-at',
    CollapsedStyleFolders = 'collapsed-style-folders',
    CloseDevModeRemainder = 'CLOSE_DEV_MODE_REMINDER_OF',
    UserPreference = '',
    DocConfigUpdate = 'docConfigUpdate',
    AuthConfigUpdate = 'authConfigUpdate',
    ListShowType = 'list-show-type',
    DocListSort = 'doc-list-sort',
    TeamFolderList = 'team-folder-list',
    TeamMembers = 'team-members',
    OrganizationMember = 'organization-member',
    OrganizationTeam = 'organization-team',
    GoogleUtmInfo = 'google_utm_info',
    EnablePixelGrid = 'enable-pixel-grid',
    SnapToPixel = 'snap-to-pixel',
    LeftPanelWidth = 'left-panel-width',
    StylePickerListLayout = 'style-picker-list-layout',
    ColorProfile = 'colorProfile',
    AIGenUIModalPos = 'AIGenUIModalPos',
    ShowDevModeShareTips = 'show-dev-mode-share-tips',
    InverseZoomDirection = 'inverseZoomDirection',
    prototypingVisibility = 'prototyping-visibility',
    inspectCodeType = 'inspect-code-type',
    devModeInspectType = 'devModeInspectType',
    devModeInspectCodeType = 'devModeInspectCodeType',
    devModeInspectExportUserConfig = 'devModeInspectExportUserConfig',
    LooperPluginData = 'looper-plugin-data',
    AeExportImage = 'ae-export-image',
    ShowSlice = 'showSlice',
    SmallNudgeAmount = 'smallNudgeAmount',
    BigNudgeAmount = 'bigNudgeAmount',
    FrameTemplateExpandItem = 'frame-template-expand-item',
    BenchResult = 'bench-result',
    ShowSidebar = 'show-sidebar',
    DevModeRightPanelWidth = 'devModeRightPanelWidth',
    ShowRuler = 'show-ruler',
    SSOAuthSessionId = 'sso-authSessionId',
    SSORedirectUrl = 'sso-redirect-url',
    CtrlClickTriggerContextMenu = 'ctrlClickTriggerContextMenu',
    AlwaysOpenInApp = 'alwaysOpenInApp',
    HideNavDesktopPopup = 'hideNavDesktopPopup',
    UserActiveRecord = 'userActiveRecord',
    ComponentPickerViewMode = 'componentPickerViewMode',
    LastLoginedUserSha = 'lastLoginedUserSha',
    PluginDevLocalPlugins = 'pluginDevLocalPlugins',
    pluginEnableConfig = 'pluginEnableConfig',
    pluginDevUseSandbox = 'pluginDevUseSandbox',
    pluginDevUseHotReload = 'pluginDevUseHotReload',
    CollectionPreselected = 'collectionPreselected',
    LocalFeatureSwitchConfig = 'localFeatureSwitchConfig',
    ChatbotDebugSetting = 'chatbot-debug-setting',
    StayOnProd = 'stay-on-prod',
    MotiffScopeConfig = 'motiffScopeConfig',
    MotiffScopePropsSetting = 'motiffScopePropsSetting',
    MotiffScopeWasmCallMonitorBlackList = 'motiffScopeWasmCallMonitorBlackList',
    MirrorViewportScaling = 'mirrorViewportScaling',
    ColorMode = 'color-mode',
    FontStyle = 'default_text_style_v2',
    pluginsPosition = 'plugins-position',
    OrgAdminLastSelectedWorkspace = 'org-admin-last-selected-workspace',
    MotiffScopeWasmCallMonitorRunningState = 'motiffScopeWasmCallMonitorRunningState',
    Theme = 'theme',
    AIGenDropdownSelectedConfig = 'ai-gen-dropdown-selected-config',
    AIGenWebSiteConfig = 'landingPageOption',
    LastSelectedWorkspace = 'last-selected-workspace',
}

/**
 * [how to add localStorage key: 2/3]
 * 维护 LocalStorage value 的 schema 定义
 */
export interface LocalStorageKey2Schema {
    [LocalStorageKey.Test]: number
    [LocalStorageKey.TestCanRemove]: { a: number }
    [LocalStorageKey.EditorType]: Wukong.DocumentProto.EditorType
    [LocalStorageKey.LocalEnableFeatures]: string
    [LocalStorageKey.LocalDisabledFeatures]: string
    [LocalStorageKey.UserSelectOrgId]: string
    [LocalStorageKey.SSOAuthSessionId]: string
    [LocalStorageKey.MirrorVisited]: boolean
    [LocalStorageKey.Language]: 'en' | 'zh'
    [LocalStorageKey.NewCodeTip]: boolean
    [LocalStorageKey.CurrentOrgId]: string
    [LocalStorageKey.DevModeInspectCodeConfig]: Wukong.DocumentProto.IDevModeInspectCodeConfig
    [LocalStorageKey.LandingPagePrompt]: string
    [LocalStorageKey.RecentEmoji]: string[]
    [LocalStorageKey.WebGLSupport]: '0' | '1'
    [LocalStorageKey.CompTriggerCount]: number
    [LocalStorageKey.ScaleSpeedEvalFunction]: string
    [LocalStorageKey.FontHistory]: FontInfoExt[]
    [LocalStorageKey.PostscriptName]: number
    [LocalStorageKey.CollapsedStyleFolders]: Record<string, DocStyleFolderCollapse>
    [LocalStorageKey.CloseDevModeRemainder]: boolean
    [LocalStorageKey.UserPreference]: any
    [LocalStorageKey.DocConfigUpdate]: string
    [LocalStorageKey.AuthConfigUpdate]: string
    [LocalStorageKey.ListShowType]: { showType: ShowType }
    [LocalStorageKey.DocListSort]: { sortKey: DocListPageSortKey; sortOrder: SortOrder }
    [LocalStorageKey.TeamFolderList]: FolderSortState
    [LocalStorageKey.TeamMembers]: {
        sortKey: TeamMembersPageSortKey
        sortOrder: SortOrder
    }
    [LocalStorageKey.OrganizationMember]: {
        sortKey: OrganizationManageMemberPageSortKey
        sortOrder: SortOrder
    }
    [LocalStorageKey.OrganizationTeam]: {
        sortKey: OrganizationManageTeamPageSortKey
        sortOrder: SortOrder
    }
    [LocalStorageKey.GoogleUtmInfo]: {
        utm_source: string
        utm_medium: string
        utm_campaign: string
        utm_content: string
        utm_term: string
    }
    [LocalStorageKey.EnablePixelGrid]: boolean
    [LocalStorageKey.SnapToPixel]: boolean
    [LocalStorageKey.LeftPanelWidth]: number
    [LocalStorageKey.StylePickerListLayout]: boolean
    [LocalStorageKey.ColorProfile]: number
    [LocalStorageKey.AIGenUIModalPos]?: Position
    [LocalStorageKey.ShowDevModeShareTips]: boolean
    [LocalStorageKey.InverseZoomDirection]: boolean
    [LocalStorageKey.LooperPluginData]: Wukong.DocumentProto.IPluginLooperData
    [LocalStorageKey.AeExportImage]: boolean
    [LocalStorageKey.ShowSlice]: boolean
    [LocalStorageKey.prototypingVisibility]: boolean
    [LocalStorageKey.PresentLeftFlowPanelWidth]: number
    [LocalStorageKey.devModeInspectCodeType]: Wukong.DocumentProto.DevModeInspectCodeType
    [LocalStorageKey.inspectCodeType]: Wukong.DocumentProto.CodeType
    [LocalStorageKey.devModeInspectType]: Wukong.DocumentProto.DevModeInspectType
    [LocalStorageKey.devModeInspectExportUserConfig]: Wukong.DocumentProto.IDevModeInspectExportUserConfig
    [LocalStorageKey.SmallNudgeAmount]: number
    [LocalStorageKey.BigNudgeAmount]: number
    [LocalStorageKey.FrameTemplateExpandItem]: string
    [LocalStorageKey.BenchResult]: Wukong.DocumentProto.BenchResult
    [LocalStorageKey.ShowSidebar]: boolean
    [LocalStorageKey.DevModeRightPanelWidth]: number
    [LocalStorageKey.ShowRuler]: boolean
    [LocalStorageKey.CtrlClickTriggerContextMenu]: boolean
    [LocalStorageKey.AlwaysOpenInApp]: boolean
    [LocalStorageKey.HideNavDesktopPopup]: boolean
    [LocalStorageKey.UserActiveRecord]: UserActiveRecordMap
    [LocalStorageKey.ComponentPickerViewMode]: ComponentPickerModalViewMode
    [LocalStorageKey.LastLoginedUserSha]: string
    [LocalStorageKey.PluginDevLocalPlugins]: Record<string, LocalPlugin[]>
    [LocalStorageKey.pluginEnableConfig]: PluginEnableConfig
    [LocalStorageKey.pluginDevUseSandbox]: boolean
    [LocalStorageKey.pluginDevUseHotReload]: boolean
    [LocalStorageKey.LastSelectedVariableSetId]: string
    [LocalStorageKey.CollectionPreselected]: string
    [LocalStorageKey.LocalFeatureSwitchConfig]: Record<LocalFeatureSwitch, { enabled: boolean; gray: number }>
    [LocalStorageKey.CollectionCollapsedGroupKeys]: Record<string, string[]>
    [LocalStorageKey.ChatbotDebugSetting]: string
    [LocalStorageKey.StayOnProd]: boolean
    [LocalStorageKey.MotiffScopeConfig]: { followSelect: boolean; autoShowCommon: boolean; showMainPanel: boolean }
    [LocalStorageKey.MotiffScopePropsSetting]: Record<string, string[]>
    [LocalStorageKey.MotiffScopeWasmCallMonitorBlackList]: string[]
    [LocalStorageKey.MirrorViewportScaling]: Wukong.DocumentProto.MirrorViewportScaling
    [LocalStorageKey.SSORedirectUrl]: string
    [LocalStorageKey.ColorMode]: ColorModeType
    [LocalStorageKey.SidebarOpenState]: boolean
    [LocalStorageKey.FontStyle]: Wukong.DocumentProto.TextNodeStyle
    [LocalStorageKey.pluginsPosition]: PluginsPosition
    [LocalStorageKey.OrgAdminLastSelectedWorkspace]: string
    [LocalStorageKey.MotiffScopeWasmCallMonitorRunningState]: boolean
    [LocalStorageKey.Theme]: 'light' | 'dark'
    [LocalStorageKey.AIGenDropdownSelectedConfig]: { id: number; name: string | null }
    [LocalStorageKey.AIGenWebSiteConfig]: {
        styleConfigID?: number
        styleConfigName?: string
        image?: string
        imageMetadata?: {
            type: string
            name: string
            size: number
        }
    }
    [LocalStorageKey.LastSelectedWorkspace]: string
    [LocalStorageKey.DisableWebGPU]: string
}

type LocalStorageKey2ConfigType<T extends LocalStorageKey> = {
    [K in T]: {
        // 在超限时是否可以优先删除
        canRemove: boolean
        // 在 get 时会做输入校验，要求返回符合类型规范的默认值。如果输入不合法时，会返回默认值
        validator: (value: unknown) => boolean
        // 当外部输入解析失败后，fallback 的默认值
        fallbackValue: LocalStorageKey2Schema[K] | null
    }
}

/**
 * [how to add localStorage key: 3/3]
 * localStorage 的存储配置
 * 标记当前写入数据的额外信息
 */
export const LocalStorageKey2Config: LocalStorageKey2ConfigType<LocalStorageKey> = {
    // owner: hulh01@kanyun.com
    [LocalStorageKey.Test]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: 1,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.TestCanRemove]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'object' && !!value && 'a' in value,
        fallbackValue: { a: 1 },
    },
    // owner: chenyn@kanyun.com
    [LocalStorageKey.EditorType]: {
        canRemove: false,
        validator: (value: unknown) => value === EditorType.EDITOR_TYPE_DESIGN || value === EditorType.EDITOR_TYPE_DEV,
        fallbackValue: EditorType.EDITOR_TYPE_DESIGN,
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.PresentLeftFlowPanelWidth]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: DEFAULT_LEFT_PANEL_WIDTH,
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.devModeInspectType]: {
        canRemove: false,
        validator: (value: unknown) =>
            [
                Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE,
                Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_PROPERTY,
            ].includes(value as Wukong.DocumentProto.DevModeInspectType),
        fallbackValue: Wukong.DocumentProto.DevModeInspectType.DEV_MODE_INSPECT_TYPE_CODE,
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.devModeInspectExportUserConfig]: {
        canRemove: false,
        validator: (value: unknown) =>
            !!value &&
            typeof value === 'object' &&
            'cssConfig' in value &&
            'iosConfig' in value &&
            'androidConfig' in value,
        fallbackValue: defaultDevModeInspectExportUserConfig,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.devModeInspectCodeType]: {
        canRemove: false,
        validator: (value: unknown) =>
            [
                Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
                Wukong.DocumentProto.DevModeInspectCodeType.iOS_Swift_UI,
                Wukong.DocumentProto.DevModeInspectCodeType.iOS_UI_Kit,
                Wukong.DocumentProto.DevModeInspectCodeType.Android_Compose,
                Wukong.DocumentProto.DevModeInspectCodeType.Android_XML,
            ].includes(value as Wukong.DocumentProto.DevModeInspectCodeType),
        fallbackValue: Wukong.DocumentProto.DevModeInspectCodeType.DEV_MODE_INSPECT_CODE_TYPE_CSS,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.inspectCodeType]: {
        canRemove: false,
        validator: (value: unknown) =>
            [
                Wukong.DocumentProto.CodeType.CODE_TYPE_ANDROID,
                Wukong.DocumentProto.CodeType.CODE_TYPE_CSS,
                Wukong.DocumentProto.CodeType.CODE_TYPE_I_O_S,
            ].includes(value as Wukong.DocumentProto.CodeType),
        fallbackValue: Wukong.DocumentProto.CodeType.CODE_TYPE_CSS,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.prototypingVisibility]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: guanzhengzhi@kanyun.com
    [LocalStorageKey.LocalEnableFeatures]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: guanzhengzhi@kanyun.com
    [LocalStorageKey.LocalDisabledFeatures]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.SSOAuthSessionId]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.UserSelectOrgId]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: jiangzg@kanyun.com
    [LocalStorageKey.MirrorVisited]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.Language]: {
        canRemove: false,
        validator: (value: unknown) => value === 'en' || value === 'zh',
        fallbackValue: null,
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.CurrentOrgId]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.DevModeInspectCodeConfig]: {
        canRemove: false,
        validator: (value: unknown) => !!value && typeof value === 'object' && 'css' in value,
        fallbackValue: defaultDevModeInspectCodeConfig,
    },
    // owner: chaibowen@kanyun.com
    [LocalStorageKey.LandingPagePrompt]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.RecentEmoji]: {
        canRemove: false,
        validator: (value: unknown) => Array.isArray(value),
        fallbackValue: [],
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.WebGLSupport]: {
        canRemove: false,
        validator: (value: unknown) => value === '0' || value === '1',
        fallbackValue: null,
    },
    // owner: huangling@kanyun.com
    [LocalStorageKey.CompTriggerCount]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: 0,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.ScaleSpeedEvalFunction]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue:
            'const absDeltaY = Math.abs(y)\nif (!Number.isInteger(y)) {\nreturn Math.min(1.2, absDeltaY * (dp == 2 ? 0.00625 : 0.0125) + 1);\n} else {\nif (absDeltaY < 10) {\nreturn absDeltaY / 400 + 1;\n}\nreturn Math.min(1.2, absDeltaY * (dp == 2 ? 0.0025 : 0.005) + 1);\n}',
    },
    // owner: jiangzg@kanyun.com
    [LocalStorageKey.FontHistory]: {
        canRemove: false,
        validator: (value: unknown) => Array.isArray(value),
        fallbackValue: [],
    },
    // owner: jiangzg@kanyun.com
    [LocalStorageKey.PostscriptName]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: 0,
    },
    // owner: jiangzg@kanyun.com
    [LocalStorageKey.CollapsedStyleFolders]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'object' && !!value,
        fallbackValue: {},
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.CloseDevModeRemainder]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: null,
    },
    // owner: yangming@kanyun.com
    [LocalStorageKey.UserPreference]: {
        canRemove: false,
        validator: () => true,
        fallbackValue: null,
    },
    // owner: yangming@kanyun.com
    [LocalStorageKey.DocConfigUpdate]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: yangming@kanyun.com
    [LocalStorageKey.AuthConfigUpdate]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.ListShowType]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'object' && !!value && 'showType' in value,
        fallbackValue: { showType: ShowType.Grid },
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.DocListSort]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && 'sortKey' in value && 'sortOrder' in value,
        fallbackValue: {
            sortKey: DocListPageSortKey.Visited,
            sortOrder: SortOrder.Ascending,
        },
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.TeamFolderList]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && 'sortKey' in value && 'sortOrder' in value,
        fallbackValue: {
            sortKey: TeamFoldersPageSortKey.UpdateTime,
            sortOrder: SortOrder.Ascending,
        },
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.TeamMembers]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && 'sortKey' in value && 'sortOrder' in value,
        fallbackValue: {
            sortKey: TeamMembersPageSortKey.Name,
            sortOrder: SortOrder.Ascending,
        },
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.OrganizationMember]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && 'sortKey' in value && 'sortOrder' in value,
        fallbackValue: {
            sortKey: OrganizationManageMemberPageSortKey.Name,
            sortOrder: SortOrder.Ascending,
        },
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.OrganizationTeam]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && 'sortKey' in value && 'sortOrder' in value,
        fallbackValue: {
            sortKey: OrganizationManageTeamPageSortKey.TeamName,
            sortOrder: SortOrder.Ascending,
        },
    },
    // owner: wangshuleibj@kanyun.com
    [LocalStorageKey.GoogleUtmInfo]: {
        canRemove: false,
        validator: (value: unknown) => {
            if (typeof value !== 'object' || !value) {
                return false
            }
            const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = value as Record<string, string>
            return (
                typeof utm_source === 'string' &&
                typeof utm_medium === 'string' &&
                typeof utm_campaign === 'string' &&
                typeof utm_content === 'string' &&
                typeof utm_term === 'string'
            )
        },
        fallbackValue: null,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.EnablePixelGrid]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: null,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.SnapToPixel]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: null,
    },
    // owner: chenxiang@kanyun.com
    [LocalStorageKey.NewCodeTip]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: null,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.LeftPanelWidth]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: null,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.StylePickerListLayout]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: null,
    },
    // owner: chenpeihao@kanyun.com
    [LocalStorageKey.ColorProfile]: {
        canRemove: false,
        validator: (value: unknown) =>
            typeof value == 'number' && !Number.isNaN(value) && Number.isFinite(value) && value >= 0,
        fallbackValue: 0,
    },
    // owner: chaibowen@kanyun.com
    [LocalStorageKey.AIGenUIModalPos]: {
        canRemove: true,
        validator: (value): value is Position | undefined => {
            return (
                typeof value == 'undefined' ||
                (typeof value === 'object' &&
                    value !== null &&
                    Object.hasOwn(value, 'top') &&
                    Object.hasOwn(value, 'left') &&
                    typeof (value as Position).top === 'number' &&
                    typeof (value as Position).left === 'number')
            )
        },
        fallbackValue: undefined,
    },
    // owner: guanzhenzhi@kanyun.com
    [LocalStorageKey.ShowDevModeShareTips]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: true,
    },
    // owner: jiangjieke@kanyun.com
    [LocalStorageKey.InverseZoomDirection]: {
        canRemove: true,
        validator: (value) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.LooperPluginData]: {
        canRemove: true,
        validator: (value: unknown) => !!value && typeof value === 'object' && 'repeatTime' in value,
        fallbackValue: null,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.AeExportImage]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.ShowSlice]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: true,
    },
    // owner: loushuangzhanbj@kanyun.com
    [LocalStorageKey.SmallNudgeAmount]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: 1,
    },
    // owner: loushuangzhanbj@kanyun.com
    [LocalStorageKey.BigNudgeAmount]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'number',
        fallbackValue: 10,
    },
    // owner: loushuangzhanbj@kanyun.com
    [LocalStorageKey.FrameTemplateExpandItem]: {
        canRemove: false,
        validator: (value: unknown) => {
            switch (value as FrameTemplateGroupNameCode) {
                case FrameTemplateGroupNameCode.IPad:
                case FrameTemplateGroupNameCode.Phone:
                case FrameTemplateGroupNameCode.PC:
                case FrameTemplateGroupNameCode.SmartWatch:
                case FrameTemplateGroupNameCode.SocialMedia:
                case FrameTemplateGroupNameCode.Paper:
                case FrameTemplateGroupNameCode.Other:
                    return true
            }
            return false
        },
        fallbackValue: '',
    },
    // owner: liangyoubj@kanyun.com
    [LocalStorageKey.BenchResult]: {
        canRemove: true,
        validator: (value: unknown) => !!value && typeof value === 'object' && 'version' in value,
        fallbackValue: null,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.ShowSidebar]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: true,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.Theme]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string' && (value === 'light' || value === 'dark'),
        fallbackValue: 'light',
    },
    // owner: chenyn01@kanyun.com
    [LocalStorageKey.DevModeRightPanelWidth]: {
        canRemove: true,
        validator: (value) => typeof value === 'number',
        fallbackValue: DEV_MODE_RIGHT_PANEL_WIDTH,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.ShowRuler]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.CtrlClickTriggerContextMenu]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.AlwaysOpenInApp]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.HideNavDesktopPopup]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: wangshuleibj@kanyun.com
    [LocalStorageKey.UserActiveRecord]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'object' && !!value,
        fallbackValue: {},
    },
    // owner: zhengyihui@kanyun.com
    [LocalStorageKey.ComponentPickerViewMode]: {
        canRemove: true,
        validator: (value: unknown) => value === 'grid' || value === 'list',
        fallbackValue: 'grid',
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.LastLoginedUserSha]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.PluginDevLocalPlugins]: {
        canRemove: true,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && Object.keys(value).every((key) => typeof key === 'string'),
        fallbackValue: {},
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.pluginEnableConfig]: {
        canRemove: true,
        validator: (value: unknown) =>
            !!value && typeof value === 'object' && Object.keys(value).every((key) => typeof key === 'string'),
        fallbackValue: {},
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.pluginDevUseSandbox]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.pluginDevUseHotReload]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: chenyn01@kanyun.com
    [LocalStorageKey.LastSelectedVariableSetId]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: loushuangzhanbj@kanyun.com
    [LocalStorageKey.CollectionPreselected]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: hulh01@kanyun.com
    [LocalStorageKey.LocalFeatureSwitchConfig]: {
        canRemove: false,
        validator: (value: unknown) => typeof value === 'object' && !!value,
        // @ts-expect-error
        fallbackValue: {},
    },
    // owner: chenyn01@kanyun.com
    [LocalStorageKey.CollectionCollapsedGroupKeys]: {
        canRemove: true,
        validator: (value: unknown) =>
            typeof value === 'object' && !!value && Object.keys(value).every((key) => typeof key === 'string'),
        fallbackValue: {},
    },
    // owner: jiangjieke@kanyun.com
    [LocalStorageKey.ChatbotDebugSetting]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: zhengyihui@kanyun.com
    [LocalStorageKey.StayOnProd]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.MotiffScopeConfig]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'object' && !!value,
        fallbackValue: { followSelect: false, autoShowCommon: false, showMainPanel: false },
    },
    // owner: zangbingjie@kanyun.com
    [LocalStorageKey.MotiffScopePropsSetting]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'object' && !!value,
        fallbackValue: {},
    },
    // owner: guanzhenzhi@kanyun.com
    [LocalStorageKey.MotiffScopeWasmCallMonitorBlackList]: {
        canRemove: true,
        validator: (value: unknown) => Array.isArray(value),
        fallbackValue: [],
    },
    // owner: lizhaohuibj01@kanyun.com
    [LocalStorageKey.MirrorViewportScaling]: {
        canRemove: false,
        validator: (value: unknown) =>
            [
                Wukong.DocumentProto.MirrorViewportScaling.MIN_ZOOM,
                Wukong.DocumentProto.MirrorViewportScaling.SCALE_DOWN_WIDTH,
            ].includes(value as Wukong.DocumentProto.MirrorViewportScaling),
        fallbackValue: Wukong.DocumentProto.MirrorViewportScaling.SCALE_DOWN_WIDTH,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.SSORedirectUrl]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.ColorMode]: {
        canRemove: true,
        validator: (value: unknown) =>
            typeof value === 'number' && Object.values(ColorModeType).includes(value as ColorModeType),
        fallbackValue: ColorModeType.HEX,
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.FontStyle]: {
        canRemove: true,
        validator: (value) =>
            !!value &&
            typeof value === 'object' &&
            'style' in value &&
            'textAlignHorizontal' in value &&
            'textAlignVertical' in value &&
            'textAutoResize' in value &&
            'paragraphIndent' in value &&
            'paragraphSpacing' in value &&
            'textStyleId' in value,
        fallbackValue: Wukong.DocumentProto.TextNodeStyle.create(),
    },
    // owner: yangmingbj03@kanyun.com
    [LocalStorageKey.pluginsPosition]: {
        canRemove: true,
        validator: (value) =>
            !!value && typeof value === 'object' && Object.keys(value).every((key) => typeof key === 'string'),
        fallbackValue: {},
    },
    // owner: zhengyihui@kanyun.com
    [LocalStorageKey.OrgAdminLastSelectedWorkspace]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: null,
    },
    // owner: chenxiangbj@kanyun.com
    [LocalStorageKey.SidebarOpenState]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: true,
    },
    // owner: guanzhenzhi@kanyun.com
    [LocalStorageKey.MotiffScopeWasmCallMonitorRunningState]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'boolean',
        fallbackValue: false,
    },
    // owner: chaibowen@kanyun.com
    [LocalStorageKey.AIGenDropdownSelectedConfig]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'object' && value !== null && 'id' in value && 'name' in value,
        fallbackValue: { id: -1, name: null },
    },
    // owner: chaibowen@kanyun.com
    [LocalStorageKey.AIGenWebSiteConfig]: {
        canRemove: true,
        validator: (value: unknown) =>
            (typeof value === 'object' &&
                value !== null &&
                (('styleConfigID' in value && 'styleConfigName' in value) ||
                    ('image' in value && 'imageMetadata' in value))) ||
            value === null,
        fallbackValue: null,
    },
    [LocalStorageKey.LastSelectedWorkspace]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
    // owner: chenpeihao@kanyun.com
    [LocalStorageKey.DisableWebGPU]: {
        canRemove: true,
        validator: (value: unknown) => typeof value === 'string',
        fallbackValue: '',
    },
}
