import {
    ReplayCanvasEvent,
    SetDevicePixelRatioCommand,
    SetDevModeDisplayPanelsWasmCall,
    SetDisplayPanelsWasmCall,
    SetOperateSystemWasmCall,
    UpdateDocReadonlyCommand,
    UpdateWindowInnerHeightWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { command } from 'ccstate'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../../../util/src/abort-controller/traceable-transaction'
import { traceable } from '../../../../../../util/src/ccstate-helper/traceable'
import { domLocation } from '../../../../../../util/src/dom-env'
import { CommitType } from '../../../../document/command/commit-type'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { featureSwitchManager } from '../../../../kernel/switch'
import { getOS } from '../../../../kernel/util/ua'
import { ClipboardService } from '../../../../main/clipboard/clipboard-service/clipboard-service'
import { createFileManager, isCreatingFile$ } from '../../../../main/create-file-manager'
import { registerHandler } from '../../../../main/register-handler'
import { focusViewManager } from '../../../../main/service/focus-view/focus-view-manager'
import { shouldPreventThisEventByDefault } from '../../../../main/service/focus-view/keyboard-event-prevent-or-pass'
import { registerEditorWindowEventListener } from '../../../../main/service/focus-view/register-event-listener'
import { isDocReadonly$ } from '../../../atoms/doc-readonly'
import { editorContext$, setupDocumentLoaded$ } from '../../../atoms/editor-context'

import DisplayPanelType = Wukong.DocumentProto.DisplayPanelType

export const afterOnDocument$ = traceable(
    'hulh01@kanyun.com',
    command(async ({ get, set }, signal: TraceableAbortSignal) => {
        const editorContext = get(editorContext$)!

        const isDocReadonly = (await get(isDocReadonly$))!

        signal.throwIfAborted()

        const { act } = transaction(signal)

        act('register handler', () => {
            const { canvas, commandInvoker, handlerProvider, mouseOnCanvas, viewStateBridge } = editorContext
            const destroy = registerHandler(
                canvas,
                commandInvoker,
                handlerProvider,
                mouseOnCanvas,
                viewStateBridge,
                signal
            ).destroy

            return () => {
                destroy()
            }
        })

        act('set document color profile meta', () => {
            editorContext.colorProfileService.afterEditorBootstrap()
        })

        act('sync usable fontinfos', () => {
            editorContext.fontManagerService.startSyncUsableFontInfos()
        })

        act('init some status to wasm', () => {
            const commandInvoker = editorContext.commandInvoker

            commandInvoker.invokeBridge(
                CommitType.Noop,
                UpdateWindowInnerHeightWasmCall,
                Wukong.DocumentProto.BridgeProtoInt.create({
                    value: window.innerHeight,
                })
            )

            commandInvoker.invokeBridge(
                CommitType.Noop,
                UpdateDocReadonlyCommand,
                Wukong.DocumentProto.BridgeProtoBoolean.create({
                    value: isDocReadonly,
                })
            )

            commandInvoker.invokeBridge(
                CommitType.Noop,
                SetOperateSystemWasmCall,
                Wukong.DocumentProto.Args_SetOperateSystemCommand.create({
                    value: getOS(),
                })
            )

            const editorDisplayPanels = featureSwitchManager.isEnabled('ai2')
                ? [
                      {
                          type: DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN,
                          isSelected: true,
                      },
                      {
                          type: DisplayPanelType.DISPLAY_PANEL_TYPE_PROTOTYPE,
                          isSelected: false,
                      },
                      {
                          type: DisplayPanelType.DISPLAY_PANEL_TYPE_AI,
                          isSelected: false,
                      },
                  ]
                : [
                      {
                          type: DisplayPanelType.DISPLAY_PANEL_TYPE_DESIGN,
                          isSelected: true,
                      },
                      {
                          type: DisplayPanelType.DISPLAY_PANEL_TYPE_PROTOTYPE,
                          isSelected: false,
                      },
                  ]

            commandInvoker.invokeBridge(
                CommitType.Noop,
                SetDisplayPanelsWasmCall,
                Wukong.DocumentProto.Args_SetDisplayPanelsCommand.create({
                    value: isDocReadonly
                        ? [
                              {
                                  type: DisplayPanelType.DISPLAY_PANEL_TYPE_COMMENT,
                                  isSelected: true,
                              },
                              {
                                  type: DisplayPanelType.DISPLAY_PANEL_TYPE_INSPECT,
                                  isSelected: false,
                              },
                              {
                                  type: DisplayPanelType.DISPLAY_PANEL_TYPE_EXPORT,
                                  isSelected: false,
                              },
                          ]
                        : editorDisplayPanels,
                })
            )

            commandInvoker.invokeBridge(
                CommitType.Noop,
                SetDevModeDisplayPanelsWasmCall,
                Wukong.DocumentProto.Args_SetDisplayPanelsCommand.create({
                    value: [
                        {
                            type: DisplayPanelType.DISPLAY_PANEL_TYPE_DEV_MODE_INSPECT,
                            isSelected: true,
                        },
                    ],
                })
            )
        })

        act('init electron plugin menu', () => {
            window.localBridge?.updatePluginMenu?.()
        })

        act('init user config service', () => {
            editorContext.userConfigService.provideDocument(editorContext.commandInvoker)
        })

        act('init clipboard service', () => {
            ;(editorContext.clipboardService as ClipboardService).registerHandlers()
        })

        act('bidn replayCanvasEvent', () => {
            const bridge = editorContext.bridge

            bridge.bind(ReplayCanvasEvent, editorContext.mouseOnCanvas.replayLastMoveEventOnCanvas)

            return () => {
                bridge.unbind(ReplayCanvasEvent)
            }
        })

        act('inject create file callback with init libraryComponentService', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.libraryComponentService.init()
                })
            } else {
                editorContext.libraryComponentService.init()
            }
        })

        act('init comment service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.commentService.init()
                    editorContext.commentService.startRequestComment()
                })
            } else {
                editorContext.commentService.init()
                editorContext.commentService.startRequestComment()
            }
        })

        act('init dev mode hover node service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.devModeHoverNodeLabelService.init()
                })
            } else {
                editorContext.devModeHoverNodeLabelService.init()
            }
        })

        act('init cooperation service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.cooperationService.init()
                })
            } else {
                editorContext.cooperationService.init()
            }
        })

        act('init history service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.historyService.init()
                })
            } else {
                editorContext.historyService.init()
            }
        })

        act('init synergy sync status service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    editorContext.synergySyncStatusService.init()
                })
            } else {
                editorContext.synergySyncStatusService.init()
            }
        })

        act('init viewport menu service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    return editorContext.viewMenuService.init()
                })
            } else {
                return editorContext.viewMenuService.init()
            }
        })

        act('init client service', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    return editorContext.clientService.init()
                })
            } else {
                return editorContext.clientService.init()
            }
        })

        act('init userModeService', () => {
            if (get(isCreatingFile$)) {
                createFileManager.injectCreateFileCallBack(() => {
                    return editorContext.planAndUserStateService.initModeStatus()
                })
            } else {
                return editorContext.planAndUserStateService.initModeStatus()
            }
        })

        act('init cooperationService', () => {
            // 预渲染阶段不向服务端通知用户处于活跃状态
            if (!domLocation().href.includes('isAdvanceRender')) {
                editorContext.cooperationService.init()
            }
        })

        act('update initial dpr', () => {
            editorContext.commandInvoker.invokeBridge(CommitType.Noop, SetDevicePixelRatioCommand, {
                value: window.devicePixelRatio,
            })
        })

        act('setup focus view manager', () => {
            // [devMode 下启用 focusView] 始终注入 commandInvoker
            focusViewManager.setupEventHandlers(signal)

            // 在焦点系统关闭时，仍然全局捕获 keyboard 事件处理 preventDefault
            const keyboardEventHandler = (e: KeyboardEvent) => {
                if (focusViewManager.isFocusViewEnabled) {
                    return
                }
                shouldPreventThisEventByDefault(e)
            }

            const unregisterKeyDownEvent = registerEditorWindowEventListener<KeyboardEvent>(
                'keydown',
                keyboardEventHandler,
                true
            )
            const unregisterKeyUpEvent = registerEditorWindowEventListener<KeyboardEvent>(
                'keyup',
                keyboardEventHandler,
                true
            )
            return () => {
                unregisterKeyDownEvent()
                unregisterKeyUpEvent()
            }
        })

        act('init DevModeAvailabilityVerificationService', () => {
            editorContext.devModeAvailabilityVerificationService.enable()
        })

        act('metric documentloaded', () => {
            MetricCollector.pushMetric(MetricName.DOC_READY, performance.now())
        })

        act('mark documentloaded', () => {
            set(setupDocumentLoaded$, true)

            return () => {
                set(setupDocumentLoaded$, false)
            }
        })
    })
)
