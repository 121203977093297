import {
    AppendInuseOtherDocStyleNodes2StyleContainerCommand,
    ApplyAIComponentRecognizeResultCommand,
    cmdGetDocumentNodeId,
    DetachSelectedInstanceRecurCommand,
    DocumentGetNodeAttrCommand,
    DocumentGetNodeCommand,
    ExportCanvasPngToBase64,
    ExportCanvasPngToDataWasmCall,
    ExportComponentLibraryDataWasmCall,
    ExportFigJsonCommand,
    ExportPngToData,
    ExportStyleLibraryDataWasmCall,
    ExportVariableCollectionLibraryDataWasmCall,
    ExportVariableLibraryDataWasmCall,
    GetComputedFills,
    GetComputedStrokes,
    GetFullStyledTextSegments,
    GetMarkInfoCommand,
    GetNotLoadedImageCountCommand,
    GetPagesCountCommand,
    GetPageWorldBoundsCommand,
    GetSelectionNodeIdsCommandForWasm,
    GetUndoStatus,
    GetUsedMemoryCommand,
    PasteProtoAsNode,
    PrintCurrentTreeRenderObject,
    PrintImageDiagnosisCommand,
    RegenerateContentHashCommand,
    ResetGeometryCommand,
    RewritePublishPropertiesCommand,
    SetViewportCommandForJs,
    ShowMarkInfoWasmCall,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { DeepRequired, downloadBlob, filterForNonNullableArray, uuidv4 } from '../../../../util/src'
import { IN_JEST_TEST } from '../../environment'
import { Bridge } from '../../kernel/bridge/bridge'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { debugLog } from '../../kernel/debug'
import { ReplayDBPrefix } from '../../kernel/interface/replay'
import { downloadBridgeRecording } from '../../kernel/recording/upload'
import { CanvasStateType } from '../../kernel/service/canvas-types'
import { featureSwitchManager } from '../../kernel/switch/core'
import { WK } from '../../window'
import { BaseNode, ContainerNode, DocumentNode, NodeId, NodeType, PageNode } from '../node/node'
import { decodeNodeJson, NodeProperties, NodeProps } from '../node/node-property'
import { readFromFile, saveToFile } from '../util/file'
import { getAbsoluteRectByNode, getAbsoluteTransformByNode } from '../util/transform'
export interface IDocumentRoot {
    getNodeById: <T extends BaseNode>(nodeId: NodeId) => T | null
    getChildren: (nodeId: NodeId) => ReadonlyArray<Readonly<BaseNode>>
    currentDocument: () => DocumentNode | null
    currentPage: () => PageNode | null
    destroy: () => void
    aiUserId: string // ai 魔法棒 debug 用，之后会删掉
}

/**
 * @deprecated
 */
export type ReadonlyDocumentRoot = IDocumentRoot
/**
 * @deprecated
 */
export type ExecutableDocumentRoot = IDocumentRoot

export class DocumentRoot implements IDocumentRoot {
    public aiUserId = '40'

    private currentDocumentNode_: DocumentNode | null = null

    private loseContextExtension: WEBGL_lose_context | null = null

    constructor(private readonly bridge: Bridge) {
        WK.getNode = (nodeId: NodeId) => this.getNodeById(nodeId)
        WK.getChildren = (nodeId: NodeId) => this.getChildren(nodeId)
        WK.currentDocument = () => this.currentDocument()
        WK.getPagesCount = () => this.getPagesCount()
        WK.printCurrentTreeRenderObject = () => this.printCurrentTreeRenderObject()
        WK.canvasScreenshot = (fileName?: string) => this.canvasScreenshot(fileName)
        WK.canvasScreenshotAsBase64 = () => this.canvasScreenshotAsBase64()
        WK.canvasNodeScreenshot = (nodeId: string, fileName?: string) => this.canvasNodeScreenshot(nodeId, fileName)
        WK.canvasNodeScreenshotAsBlob = (nodeId: string) => this.canvasNodeScreenshotAsBlob(nodeId)
        WK.currentPage = () => this.currentPage()
        WK.currentPageChildren = () => (this.currentPage()?.getChildren() as any[]) ?? []
        WK.currentSelection = () => this.currentSelection()
        WK.getUndoStatus = () => {
            return structuredClone(bridge.call(GetUndoStatus))
        }
        WK.detachAllSelectedInstance = () => {
            this.detachAllSelectedInstance()
        }
        WK.pasteProto = (proto: Uint8Array | Wukong.DocumentProto.ISerializedExportedDocument) => {
            this.pasteProtoToCanvas(proto)
        }
        WK.getEditorMode = () => structuredClone(this.currentDocument()?.editorMode)
        WK.resetGeometry = (remove = false) => bridge.call(ResetGeometryCommand, { value: remove })
        WK.appendStyleNodes2Container = () => bridge.call(AppendInuseOtherDocStyleNodes2StyleContainerCommand)
        WK.getUsedMemory = () => {
            return bridge.call(GetUsedMemoryCommand).value!
        }
        WK.exportFigJson = (nodeId?: string) => {
            const exportedJson = bridge.call(ExportFigJsonCommand, { value: nodeId })
            return JSON.parse(exportedJson.value ?? '{}')
        }
        WK.exportFigJsonToFile = (nodeId?: string) => {
            const exportedJson = bridge.call(ExportFigJsonCommand, { value: nodeId })
            saveToFile(exportedJson.value ?? 'error', `export-fig-${new Date().toLocaleString()}.json`)
        }
        WK.showMarkInfo = (postFix: string) => {
            bridge.call(ShowMarkInfoWasmCall, { postfix: postFix })
        }
        WK.downloadMarkInfo = async () => {
            const infos = bridge.call(GetMarkInfoCommand)
            const JSZip = (await import('jszip')).default
            const zip = new JSZip()
            const folders: { [key: string]: typeof JSZip } = {}

            infos.infos!.forEach((i) => {
                if (!folders[i.category!]) {
                    folders[i.category!] = zip.folder(i.category!)!
                }

                folders[i.category!].file(`${i.nodeId!}.png`, i.imageData!, { base64: true })
            })
            const content = await zip.generateAsync({ type: 'blob' })
            const blobUrl = URL.createObjectURL(content)

            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = blobUrl
            link.download = 'output.zip'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
        WK.setViewport = (viewport: Wukong.DocumentProto.IViewport) => {
            bridge.call(SetViewportCommandForJs, viewport)
        }
        WK.getPageWorldBounds = () => {
            return bridge.call(GetPageWorldBoundsCommand)
        }
        WK.getNotLoadedImageCount = () => {
            return bridge.call(GetNotLoadedImageCountCommand).value ?? 0
        }

        WK.importAiRecognizeResult = async (hideLowUsage = false, layoutInOnePage = false) => {
            const jsonContent = await readFromFile()
            bridge.call(ApplyAIComponentRecognizeResultCommand, {
                encodedData: jsonContent,
                isBuildIndex: featureSwitchManager.isEnabled('ai-recognize-debug'),
                hideLowUsage,
                layoutInOnePage,
                templateFileData: '',
            })
        }

        WK.setAIUserId = (id: string) => {
            this.aiUserId = `${id}`
        }

        WK.downloadBridgeRecording = (downloadAs?: string) => {
            downloadBridgeRecording(`${ReplayDBPrefix}${bridge.clientId}`, downloadAs)
        }

        WK.loseContext = () => {
            if (bridge instanceof EmBridge) {
                const state = bridge.currentEditorService.getCanvasState()
                if (state?.type == CanvasStateType.WebGL) {
                    // 必须存储 extension，否则 restore 会失败
                    this.loseContextExtension = state.context.getExtension('WEBGL_lose_context')
                    if (this.loseContextExtension) {
                        this.loseContextExtension.loseContext()
                    }
                } else if (state?.type == CanvasStateType.WebGPU) {
                    // TODO(liangyou): 现在没有正确的办法模拟 WebGPU lost https://github.com/gpuweb/gpuweb/issues/4177
                }
            }
        }

        WK.restoreContext = () => {
            if (bridge instanceof EmBridge) {
                const state = bridge.currentEditorService.getCanvasState()
                if (state?.type == CanvasStateType.WebGL) {
                    if (this.loseContextExtension) {
                        this.loseContextExtension.restoreContext()
                        this.loseContextExtension = null
                    }
                }
            }
        }

        WK.printImageDiagnosis = (id: string) => {
            if (!id || typeof id !== 'string') {
                return
            }
            this.bridge.call(PrintImageDiagnosisCommand, { id })
        }

        WK.rewritePublishProperties = (id: string, publishFile: string | null, publishId: string | null) => {
            publishFile ??= ''
            publishId ??= ''

            if (!id || typeof id !== 'string') {
                return
            }
            if (typeof publishFile !== 'string') {
                return
            }
            if (typeof publishId !== 'string') {
                return
            }
            this.bridge.call(RewritePublishPropertiesCommand, {
                nodeId: id,
                publishFile,
                publishId,
            })
        }

        WK.regenerateContentHash = (id: string) => {
            if (!id || typeof id !== 'string') {
                return
            }
            this.bridge.call(RegenerateContentHashCommand, {
                id,
            })
        }

        if (IN_JEST_TEST) {
            WK.exportLibraryDataByNodeId = (
                nodeId: string,
                type: 'component' | 'style' | 'variable' | 'variableCollection'
            ) => {
                switch (type) {
                    case 'component':
                        return this.bridge.call(ExportComponentLibraryDataWasmCall, {
                            id: nodeId,
                        })?.value
                    case 'style':
                        return this.bridge.call(ExportStyleLibraryDataWasmCall, {
                            id: nodeId,
                        })?.value
                    case 'variable':
                        return this.bridge.call(ExportVariableLibraryDataWasmCall, {
                            id: nodeId,
                        })?.value
                    case 'variableCollection':
                        return this.bridge.call(ExportVariableCollectionLibraryDataWasmCall, {
                            id: nodeId,
                        })?.value
                    default:
                        return undefined
                }
            }
        }
    }

    public destroy = () => {
        delete WK.getNode
        delete WK.getChildren
        delete WK.currentDocument
        delete WK.getPagesCount
        delete WK.currentPage
        delete WK.currentPageChildren
        delete WK.currentSelection
        delete WK.getUndoStatus
        delete WK.getEditorMode
        delete WK.clearRefNode
        delete WK.getUsedMemory
        delete WK.resetGeometry
        delete WK.exportFigJson
        delete WK.exportFigJsonToFile
        delete WK.printCurrentTreeRenderObject
        delete WK.canvasScreenshot
        delete WK.canvasScreenshotAsBase64
        delete WK.canvasNodeScreenshot
        delete WK.canvasNodeScreenshotAsBlob
        delete WK.showMarkInfo
        delete WK.downloadMarkInfo
        delete WK.setViewport
        delete WK.getPageWorldBounds
        delete WK.aiCopilot
        delete WK.getNotLoadedImageCount
        delete WK.importAiRecognizeResult
        delete WK.setAIUserId
        delete WK.downloadBridgeRecording
        delete WK.appendStyleNodes2Container
        delete WK.pasteProto
        delete WK.loseContext
        delete WK.restoreContext
        delete WK.printImageDiagnosis
        delete WK.rewritePublishProperties
        delete WK.regenerateContentHash
        delete WK.detachAllSelectedInstance
        delete WK.aiGenUIJob
        delete WK.uploadNodeAsHTML
        delete WK.openNodePrototypeHTMLInIFrame
        if (IN_JEST_TEST) {
            delete WK.exportLibraryDataByNodeId
        }
        this.currentDocumentNode_ = null
    }

    private createEmNodeProxy = <T extends BaseNode>(nodeId: NodeId, bridge: Bridge): T => {
        const proxyTarget = {
            id: nodeId,
            getChildren: (): ReadonlyArray<BaseNode> => {
                return this.getChildren(nodeId)
            },
            getParent: (): ContainerNode | null => {
                return this.getParent(nodeId)
            },
            getParentId: (): NodeId | null => {
                return this.getParentId(nodeId)
            },
            // 计算在世界坐标下的 transform
            getAbsoluteTransform: () => {
                return getAbsoluteTransformByNode(this.getNodeById(nodeId))
            },
            // 计算在世界坐标下的包围盒
            getAbsoluteRect: () => {
                return getAbsoluteRectByNode(this.getNodeById(nodeId))
            },
            getFullStyledTextSegments: (): Array<Wukong.DocumentProto.IStyledTextSegment> => {
                return Wukong.DocumentProto.Ret_getFullStyledTextSegments.toObject(
                    this.bridge.call(GetFullStyledTextSegments, {
                        value: nodeId,
                    }) as Wukong.DocumentProto.Ret_getFullStyledTextSegments
                ).segments
            },
            getComputedFills: (): Array<Wukong.DocumentProto.IPaint> => {
                return Wukong.DocumentProto.Ret_getComputedFills.toObject(
                    this.bridge.call(GetComputedFills, {
                        value: nodeId,
                    }) as Wukong.DocumentProto.Ret_getComputedFills
                ).fills
            },
            getComputedStrokes: (): Array<Wukong.DocumentProto.IPaint> => {
                return Wukong.DocumentProto.Ret_getComputedStrokes.toObject(
                    this.bridge.call(GetComputedStrokes, {
                        value: nodeId,
                    }) as Wukong.DocumentProto.Ret_getComputedStrokes
                ).strokes
            },
        } as any

        return new Proxy<T>(proxyTarget, {
            get: (t: T, attr: NodeProperties) => {
                // eslint-disable-next-line no-prototype-builtins
                if (proxyTarget.hasOwnProperty(attr)) {
                    return proxyTarget[attr]
                }

                if (!attr || !Object.keys(NodeProps).includes(attr)) {
                    debugLog(`正在尝试访问非法的 NodeProps: nodeId = "${nodeId}", attr = "${String(attr)}"`)
                    return
                }

                const jsonRet = bridge.call(DocumentGetNodeAttrCommand, {
                    nodeId,
                    prop: NodeProps[attr],
                }).value
                if (!jsonRet) {
                    return undefined
                }
                const nodeRet = decodeNodeJson(jsonRet)
                return nodeRet[attr]
            },
            set: (t: T, attr: NodeProperties, _value: any) => {
                // eslint-disable-next-line no-prototype-builtins
                if (proxyTarget.hasOwnProperty(attr)) {
                    return true
                }
                throw new Error('禁止从 js 直接更新属性 ' + NodeProps[attr])
            },
        })
    }

    /**
     * 获取当前的 DocumentNode
     */
    public currentDocument(): DocumentNode | null {
        if (this.currentDocumentNode_ == null) {
            const documentNodeId = this.bridge.call(cmdGetDocumentNodeId)?.value
            if (!documentNodeId) {
                return null
            }
            this.currentDocumentNode_ = this.getNodeById(documentNodeId)
        }
        return this.currentDocumentNode_
    }

    /**
     * 获取当前的 DocumentNode
     */
    public getPagesCount(): number {
        return this.bridge.call(GetPagesCountCommand).value ?? 0
    }

    public printCurrentTreeRenderObject() {
        this.bridge.call(PrintCurrentTreeRenderObject)
    }

    public canvasScreenshot(fileName = 'motiff.png'): void {
        this.bridge.call(ExportCanvasPngToDataWasmCall, { name: fileName })
    }

    public canvasScreenshotAsBase64(): string {
        return this.bridge.call(ExportCanvasPngToBase64).value ?? ''
    }

    public canvasNodeScreenshot(nodeId: string, fileName = 'motiff-node.png') {
        const ret = this.bridge.call(ExportPngToData, {
            nodeIds: [nodeId],
            constraint: {
                type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                value: 1,
            },
            forceClip: false,
            ancestorClip: true,
            useAbsoluteBounds: false,
        })

        if (!ret.dataBase64) {
            return
        }

        const buffer = Buffer.from(ret.dataBase64, 'base64')
        const blob = new Blob([buffer], {
            type: 'application/octet-stream',
        })

        downloadBlob(blob, fileName)
    }

    public async canvasNodeScreenshotAsBlob(nodeId: string) {
        const ret = this.bridge.call(ExportPngToData, {
            nodeIds: [nodeId],
            constraint: {
                type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                value: 1,
            },
            forceClip: false,
            ancestorClip: true,
            useAbsoluteBounds: false,
        })

        if (!ret.dataBase64) {
            return undefined
        }

        const buffer = Buffer.from(ret.dataBase64, 'base64')
        const blob = new Blob([buffer], {
            type: 'application/octet-stream',
        })
        return blob
    }

    /**
     * 获取当前的 PageNode
     */
    public currentPage(): PageNode | null {
        const documentNode = this.currentDocument()
        if (!documentNode) {
            return null
        }

        const currentPageId = documentNode.currentPageId
        if (!currentPageId) {
            return null
        }

        return this.getNodeById(currentPageId)
    }

    /**
     * 获得当前选区
     */
    public currentSelection(): BaseNode[] {
        return filterForNonNullableArray(
            (this.bridge.call(GetSelectionNodeIdsCommandForWasm).value ?? []).map((id) => this.getNodeById(id))
        )
    }

    /**
     * 获得一个 Node
     * @description 无论 store 是否存在 nodeId 所对应的数据，都可以拿到一个 proxy，并可以访问这个 proxy 的 setter 以修改数据
     */
    public getNodeById<T extends BaseNode>(nodeId: NodeId): T | null {
        const jsonRet = this.bridge.call(DocumentGetNodeCommand, {
            currentNodeId: nodeId,
            skipInvisibleInstanceChildren: motiff.skipInvisibleInstanceChildren,
        }).value
        if (!jsonRet) {
            return null
        }
        return this.createEmNodeProxy<T>(nodeId, this.bridge)
    }

    /**
     * 获得一个 Node 的 Parent
     * @param nodeId
     */
    public getParent(nodeId: NodeId): ContainerNode | null {
        const parentId = this.getNodeById(nodeId)?.parentInfo?.parentId
        return parentId ? (this.getNodeById<ContainerNode>(parentId) as ContainerNode) : null
    }

    /**
     * 获得一个 Node 的 ParentId
     * @param nodeId
     */
    public getParentId(nodeId: NodeId): NodeId | null {
        const parent = this.getParent(nodeId)
        if (parent) {
            return parent.id
        }
        return null
    }

    /**
     * 获得一个 Node 的 AncestorNodeIds，顺序为从自身开始直到 PageNode 为止
     * @param nodeId
     */
    public getAncestorsPath(nodeId: NodeId): ReadonlyArray<NodeId> {
        const ancestors: NodeId[] = []

        let currentNode = this.getNodeById(nodeId)
        while (currentNode && currentNode.type !== NodeType.Document) {
            ancestors.push(currentNode.id)
            currentNode = currentNode.parentInfo?.parentId ? this.getNodeById(currentNode.parentInfo!.parentId) : null
        }

        return ancestors
    }

    /**
     * 获得一个 Node 的 ChildrenNode
     * @param nodeId
     */
    public getChildren(nodeId: NodeId): ReadonlyArray<Readonly<BaseNode>> {
        return filterForNonNullableArray(
            // TODO: 修改 node 定义
            // @ts-expect-error
            (this.getNodeById(nodeId)?.childrenIds ?? []).map((childId) => this.getNodeById(childId))
        ) as ReadonlyArray<Readonly<BaseNode>>
    }

    /**
     * 分离当前选择Node的所有属性
     */
    private detachAllSelectedInstance() {
        this.bridge.call(DetachSelectedInstanceRecurCommand)
    }

    private pasteProtoToCanvas(proto: Uint8Array | Wukong.DocumentProto.ISerializedExportedDocument) {
        try {
            let serializedDoc: Wukong.DocumentProto.ISerializedExportedDocument = {}
            if (proto instanceof Uint8Array) {
                const doc = Wukong.DocumentProto.SynergyDocument.decode(
                    proto
                ) as DeepRequired<Wukong.DocumentProto.SynergyDocument>
                if (doc) {
                    serializedDoc.docId = `motiff-ai-gen-${uuidv4()}`
                    serializedDoc.allNodes = doc.nodes.map((node) => {
                        const nodeProps: Partial<DeepRequired<typeof serializedDoc.allNodes>[number]['nodeProps']> = {
                            ...node.partialNode,
                            id: node.nodeId,
                        }
                        if (!isNil(node.partialNode.parentInfo) && node.partialNode.parentInfo.parentId === '') {
                            nodeProps.parentInfo = undefined
                        }
                        return { nodeProps }
                    })
                    serializedDoc.schemaVersion = doc.schemaVersion
                    serializedDoc.blobs = doc.blobs
                }
            } else {
                serializedDoc = proto
            }
            this.bridge.call(PasteProtoAsNode, { doc: serializedDoc })
        } catch (e) {
            console.error(e)
        }
    }
}
