import { isEqual, isNumber } from 'lodash-es'
import { WKToast } from '../../../../../ui-lib/src'
import { docTitleValidator, domLocation, RouteToken } from '../../../../../util/src'
import { BusinessStatusCode } from '../../../kernel/interface/request-error-code'
import {
    ContractSpecification,
    DocID,
    DocPrototypeVO,
    DocWithAuthorityVO,
    FolderID,
    TeamID,
} from '../../../kernel/interface/type'
import {
    BatchDuplicateDocRequest,
    BatchPermanentlyRemoveDocRequest,
    BatchRecoverDocRequest,
    BatchTrashDocRequest,
    MoveDocRequest,
    QueryDocFromFolderRequest,
    QueryTrashedDocRequest,
    UpdateDocRequest,
} from '../../../kernel/request/document'
import { contentAuditError, RequestResponseErrorHandler } from '../../../kernel/request/error-handler'
import {
    batchAddFavoriteDocs,
    batchRemoveFavoriteDocs,
    GetUserFavoriteDocRequest,
    GetUserFavoritePrototypeRequest,
} from '../../../kernel/request/favorite'
import {
    BatchDeletePrototypeOpenRecordRequest,
    BatchRemoveOpenRecordRequest,
    GetUserRecentPrototypeRecordRequest,
    GetUserRecentRecordRequest,
} from '../../../kernel/request/recent'
import { featureSwitchManager } from '../../../kernel/switch'
import { handleBusinessStatusInMoveDocs } from '../../../share/payment/pay-upgrade-handle-business-status'
import { createDocInFolder } from '../../util/create-doc'
import { FlatDoc } from '../../util/types'
import { translation } from './doc-list-page-state.translation'
import { useDocListSelectedState } from './doc-list-selected-state'
import { SpaceNotifyFlow } from './notify-flow'
import { useSpaceStore } from './space-state'
import { DocListPageStore, getSpaceStore, setSpaceStore, SpaceStore, SpaceStoreType } from './types'

const findDocAndIdx = (docList: ReadonlyArray<FlatDoc>, id: string) => {
    const idx = docList.findIndex((o) => o.id == id)
    if (idx !== -1) {
        const doc = docList[idx]
        return { idx, doc }
    }
    return {}
}

function transformDocPrototypeVO2FlatDoc(
    docPrototypeVO: Readonly<DocPrototypeVO>,
    properties?: Partial<FlatDoc>
): FlatDoc {
    return {
        // docPrototypeVO 属性
        ...docPrototypeVO.document,
        id: docPrototypeVO.id, // 把docId换成原型文件的id保持id的唯一性
        favorites: docPrototypeVO.isFavorite,
        thumbnailUrl: docPrototypeVO.thumbnailUrl,
        createdTime: docPrototypeVO.createdTime,
        canvasBackgroundColor: docPrototypeVO.canvasBackgroundColor,
        name: docPrototypeVO.pageName
            ? `${docPrototypeVO.document.name}-${docPrototypeVO.pageName}`
            : docPrototypeVO.document.name,
        // 其他
        prototype: docPrototypeVO,
        ...properties,
    }
}

// 这个文件里面只存 文件列表页需要的信息 比如docList folderInfo。非文件列表页下的组件要使用folderInfo不应该从这里获取
export const generateDocListPageState = (set: setSpaceStore, get: getSpaceStore): DocListPageStore => {
    const categorizeDocIds = (docIds: string[]) => {
        const designDocIds: string[] = []
        const prototypeIds: string[] = []
        const docList = get().docListPageStore.docList
        for (const docId of docIds) {
            const doc = docList.find((v) => v.id === docId)
            if (doc?.prototype) {
                prototypeIds.push(doc.prototype.id)
            } else {
                designDocIds.push(docId)
            }
        }
        return { designDocIds, prototypeIds }
    }
    return {
        routeInfo: undefined,
        loading: true,
        onDocOpenProcess: false,
        docList: [],
        renameingDocId: undefined,
        setOnDocOpenProcess: (onDocOpenProcess) => {
            set((state) => {
                state.docListPageStore.onDocOpenProcess = onDocOpenProcess
            })
        },
        setRenameingDocId: (id) => {
            get().docListPageStore.setSelected()
            set((state) => {
                state.docListPageStore.renameingDocId = id
            })
        },
        clearRenameingDocId: () => {
            set((state) => {
                state.docListPageStore.renameingDocId = undefined
            })
        },
        _changeDocThumbnailUrl: ({ id, thumbnailUrl }) => {
            const { idx } = findDocAndIdx(get().docListPageStore.docList, id)
            if (isNumber(idx)) {
                set((state) => {
                    const targetDoc = state.docListPageStore.docList[idx]
                    targetDoc.thumbnailUrl = thumbnailUrl
                    if (targetDoc.prototype) {
                        targetDoc.prototype.thumbnailUrl = thumbnailUrl
                    }
                })
            }
        },
        _changeDocThumbnailId: ({ id, thumbnailNodeId }) => {
            const { idx } = findDocAndIdx(get().docListPageStore.docList, id)
            if (isNumber(idx)) {
                set((state) => {
                    state.docListPageStore.docList[idx].thumbnailNodeId = thumbnailNodeId
                })
            }
        },
        _changeDocThumbnailBgColor: ({ id, canvasBackgroundColor }) => {
            const { idx } = findDocAndIdx(get().docListPageStore.docList, id)
            if (isNumber(idx)) {
                set((state) => {
                    const targetDoc = state.docListPageStore.docList[idx]
                    targetDoc.canvasBackgroundColor = canvasBackgroundColor
                    if (targetDoc.prototype) {
                        targetDoc.prototype.canvasBackgroundColor = canvasBackgroundColor
                    }
                })
            }
        },
        _refreshData: async () => {
            const routeInfo = get().docListPageStore.routeInfo
            if (!routeInfo) {
                return false
            }
            const { id, draftFolderId } = useSpaceStore.getState().organizationStore.organization
            const folderId = routeInfo.folderId
            let fetchFn: undefined | (() => Promise<FlatDoc[]>)
            switch (routeInfo.routeToken) {
                case RouteToken.Recent:
                    fetchFn = () => {
                        const designFileRequest = new GetUserRecentRecordRequest(id).start().then((list) =>
                            list.map((o) => ({
                                ...o.document,
                                visitedTime: o.visitedTime,
                            }))
                        )
                        const prototypeFileRequest = new GetUserRecentPrototypeRecordRequest(id)
                            .start()
                            .then((prototypeFileList) =>
                                prototypeFileList.map(({ visitedTime, prototype }) =>
                                    transformDocPrototypeVO2FlatDoc(prototype, { visitedTime })
                                )
                            )
                        // TODO: prototype 服务端还没实现原型文件的访问接口,因此catch错误
                        return Promise.all([designFileRequest, prototypeFileRequest.catch(() => [])]).then((files) =>
                            files.flat()
                        )
                        return designFileRequest
                    }
                    break
                case RouteToken.Drafts:
                    fetchFn = () => new QueryDocFromFolderRequest(draftFolderId).start()
                    break
                case RouteToken.Trash:
                    fetchFn = () => new QueryTrashedDocRequest(id).start()
                    break
                case RouteToken.Favorites:
                    fetchFn = () => {
                        const designFileRequest = new GetUserFavoriteDocRequest(id).start().then((list) =>
                            list.map((o) => ({
                                ...o.document,
                                favoriteTime: o.favoriteTime,
                            }))
                        )
                        const prototypeFileRequest = new GetUserFavoritePrototypeRequest(id)
                            .start()
                            .then((prototypeFileList) =>
                                prototypeFileList.map(({ favoriteTime, prototype }) =>
                                    transformDocPrototypeVO2FlatDoc(prototype, { favoriteTime })
                                )
                            )
                        // TODO: prototype 服务端还没实现原型文件的访问接口,因此catch错误
                        return Promise.all([designFileRequest, prototypeFileRequest.catch(() => [])]).then((files) =>
                            files.flat()
                        )
                    }
                    break
                case RouteToken.Project:
                    fetchFn = () => new QueryDocFromFolderRequest(folderId!).start()
                    break
                default:
                    break
            }
            if (!fetchFn) {
                return false
            }
            const docList = await fetchFn().catch((error) => {
                if (RequestResponseErrorHandler(error).businessStatus === 8888) {
                    throw error
                } else {
                    return []
                }
            })
            const currentRouteInfo = get().docListPageStore.routeInfo
            // 最后 setState 的时候, 判断一下新旧routeInfo是否一致，如果不一致就跳过 setState
            if (!isEqual(currentRouteInfo, routeInfo)) {
                return false
            }
            set((state) => {
                state.docListPageStore.docList = docList
            })
            return true
        },
        _changeDocFavorite: ({ id, type }) => {
            const { idx } = findDocAndIdx(get().docListPageStore.docList, id)
            if (isNumber(idx)) {
                set((state) => {
                    const targetDoc = state.docListPageStore.docList[idx]
                    targetDoc.favorites = type === 'add'
                    if (targetDoc.prototype) {
                        targetDoc.prototype.isFavorite = type === 'add'
                    }
                })
            }
        },
        _changeDocName: ({ id, name }) => {
            set((state) => {
                for (const targetDoc of state.docListPageStore.docList) {
                    if (targetDoc.prototype) {
                        if (targetDoc.prototype.docId === id) {
                            targetDoc.prototype.document.name = name
                            targetDoc.name = targetDoc.prototype.pageName
                                ? `${name}-${targetDoc.prototype.pageName}`
                                : name
                        }
                    } else if (targetDoc.id === id) {
                        targetDoc.name = name
                    }
                }
            })
        },
        _changePrototypePageName: ({ id, name }) => {
            const { idx } = findDocAndIdx(get().docListPageStore.docList, id)
            if (isNumber(idx)) {
                set((state) => {
                    const targetDoc = state.docListPageStore.docList[idx]
                    if (targetDoc.prototype) {
                        targetDoc.prototype.pageName = name
                        targetDoc.name = name
                            ? `${targetDoc.prototype.document.name}-${name}`
                            : targetDoc.prototype.document.name
                    }
                })
            }
        },
        permanentlyDelete: async (docIds) => {
            await new BatchPermanentlyRemoveDocRequest(docIds).start()
            set((state) => {
                state.docListPageStore.docList = state.docListPageStore.docList.filter(({ id }) => !docIds.includes(id))
            })
            WKToast.show(
                docIds.length > 1
                    ? `${docIds.length} ${translation('FilesDeletedForever')}`
                    : translation('FileDeletedForever')
            )
            get().docListPageStore.setSelected()
        },
        removeFromRecent: async (docIds) => {
            get().docListPageStore.setSelected()
            const res = categorizeDocIds(docIds)
            await Promise.all([
                res.designDocIds.length ? new BatchRemoveOpenRecordRequest(res.designDocIds).start() : undefined,
                res.prototypeIds.length
                    ? new BatchDeletePrototypeOpenRecordRequest(res.prototypeIds).start().catch(() => {}) // TODO: prototype 服务端实现这个接口后删除catch
                    : undefined,
            ])

            set((state) => {
                state.docListPageStore.docList = state.docListPageStore.docList.filter(({ id }) => !docIds.includes(id))
            })
            WKToast.show(
                docIds.length > 1
                    ? `${docIds.length} ${translation('FilesRemovedFrom')}`
                    : translation('FileRemovedFrom')
            )
        },
        createCopy: async (docIds) => {
            if (featureSwitchManager.isEnabled('refactor-create-copy-file')) {
                const toastId = WKToast.loading(
                    translation('DuplicatingFiles', { count: docIds.length, suffix: docIds.length === 1 ? '' : 's' }),
                    {
                        duration: -1,
                        delay: 500,
                    }
                )
                let docs: DocWithAuthorityVO[]
                try {
                    docs = await new BatchDuplicateDocRequest(docIds).start()
                } catch (e) {
                    WKToast.close(toastId)
                    if (RequestResponseErrorHandler(e).businessStatus === BusinessStatusCode.ProhibitCopy) {
                        WKToast.show(translation('ProhibitDuplicate'))
                    }
                    return []
                }
                await get().docListPageStore.getDocListSilent()
                get().docListPageStore.setSelected(docs.map((doc) => doc.id))
                WKToast.close(toastId)
                return docs
            }

            get().docListPageStore.setSelected([])
            let docs: DocWithAuthorityVO[]
            try {
                docs = await new BatchDuplicateDocRequest(docIds).start()
            } catch (e) {
                const msg = RequestResponseErrorHandler(e)
                if (msg.businessStatus === BusinessStatusCode.ProhibitCopy) {
                    WKToast.show(translation('ProhibitDuplicate'))
                }
                return []
            }
            // 只有在当前项目夹下才会显示 https://wkong.atlassian.net/browse/WK-15814 判断较为复杂 所以直接改成用接口更新
            await get().docListPageStore.getDocList()
            set((state) => {
                docs.forEach((doc) => {
                    const idx = findDocAndIdx(state.docListPageStore.docList, doc.id).idx
                    if (isNumber(idx)) {
                        useDocListSelectedState.getState().add(doc)
                    }
                })
            })
            return docs
        },
        favoriteDoc: async (docIds, isFavorite) => {
            const { id } = useSpaceStore.getState().organizationStore.organization
            const res = categorizeDocIds(docIds)
            const docIdsWithType = docIds.map((docId) => ({
                id: docId,
                isPrototype: res.prototypeIds.includes(docId),
            }))
            if (isFavorite) {
                await batchRemoveFavoriteDocs(docIdsWithType, id)
            } else {
                await batchAddFavoriteDocs(docIdsWithType, id)
            }
            if (isFavorite) {
                WKToast.show(
                    docIds.length > 1
                        ? `${docIds.length} ${translation('FilesRemovedFrom_synonyms1')}`
                        : translation('FileRemovedFrom_synonyms1')
                )
            } else {
                WKToast.show(
                    docIds.length > 1 ? `${docIds.length} ${translation('FilesAddedTo')}` : translation('FileAddedTo')
                )
            }
            get().docListPageStore.setSelected([])
            set((state) => {
                docIds.forEach((docId) => {
                    const { doc, idx } = findDocAndIdx(state.docListPageStore.docList, docId)
                    if (doc) {
                        const targetDoc = state.docListPageStore.docList[idx]
                        targetDoc.favorites = !isFavorite
                        if (targetDoc.prototype) {
                            targetDoc.prototype.isFavorite = !isFavorite
                        }
                    }
                })
            })
        },
        setSelected: (docIds = []) => {
            const docs = get().docListPageStore.docList.filter((doc) => docIds.includes(doc.id))
            useDocListSelectedState.getState().set(docs)
        },
        changeName: async (docId, name) => {
            get().docListPageStore.clearRenameingDocId()
            const doc = get().docListPageStore.docList.find((d) => d.id == docId)
            if (!doc) {
                return
            }
            const oldName = doc.name
            try {
                // 先修改本地状态，如果失败了再重置为原状态
                set((state) => {
                    state.docListPageStore.docList.find((d) => d.id == docId)!.name = name
                })
                await docTitleValidator(name)
                await new UpdateDocRequest(docId, {
                    name,
                }).start()
            } catch (e: any) {
                set((state) => {
                    state.docListPageStore.docList.find((d) => d.id == docId)!.name = oldName
                })
                const msg = e?.req ? contentAuditError(e) : e?.message
                if (msg) {
                    WKToast.error(msg)
                }
            }
        },
        moveDocToFolder: async (
            docIds: DocID[],
            toFolderId: FolderID,
            moveInTeamId: TeamID,
            initUpgradePayment?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
        ) => {
            await new MoveDocRequest(docIds, toFolderId)
                .start()
                .then(() => {
                    WKToast.show(
                        docIds.length > 1
                            ? translation('FilesMoved', { count: docIds.length })
                            : translation('FileMoved')
                    )
                })
                .catch((e) => {
                    handleBusinessStatusInMoveDocs(
                        RequestResponseErrorHandler(e).businessStatus,
                        moveInTeamId,
                        initUpgradePayment
                    )
                })
            // 不在手动触发更新 通过协同触发
        },
        moveDocToTrash: async (docIds: DocID[], checkRecoverDoc: () => Promise<boolean>) => {
            await new BatchTrashDocRequest(docIds).start()
            set((state) => {
                state.docListPageStore.docList = state.docListPageStore.docList.filter((o) => !docIds.includes(o.id))
            })
            WKToast.show(
                docIds.length > 1 ? translation('DeletedCount', { count: docIds.length }) : translation('Deleted'),
                {
                    firstButton: {
                        type: 'button',
                        text: translation('Undo'),
                        onClick: async () => {
                            const canRecoverDoc = await checkRecoverDoc()
                            if (!canRecoverDoc) {
                                return
                            }
                            const docs = await new BatchRecoverDocRequest(docIds).start()
                            set((state) => {
                                state.docListPageStore.docList = [...state.docListPageStore.docList, ...docs]
                            })
                        },
                    },
                }
            )
        },
        moveDocToDraft: async (docIds: DocID[]) => {
            const { draftFolderId } = useSpaceStore.getState().organizationStore.organization
            if (draftFolderId) {
                await new MoveDocRequest(docIds, draftFolderId).start()
                // 不在手动触发更新 通过协同触发
                // get().docListPageStore.getDocList()
                WKToast.show(
                    docIds.length > 1 ? translation('FilesMoved', { count: docIds.length }) : translation('FileMoved')
                )
            }
        },
        createDoc: async () => {
            const { draftFolderId, id } = useSpaceStore.getState().organizationStore.organization
            const folderId = get().docListPageStore.routeInfo?.folderId
            return createDocInFolder(folderId ?? draftFolderId, id, {
                onSuccess: (newDoc) => {
                    set((state) => {
                        state.docListPageStore.docList = [newDoc, ...state.docListPageStore.docList]
                    })
                },
            })
        },
        getDocList: async () => {
            const routeInfo = get().docListPageStore.routeInfo
            get().docOnlineUserStore.clearDocOnlineUserVO()
            if (!routeInfo) {
                set((state) => {
                    state.docListPageStore.docList = []
                    state.docListPageStore.loading = true
                })
                return
            }
            try {
                set((state) => {
                    state.docListPageStore.docList = []
                    state.docListPageStore.loading = true
                })

                // fix: [WK-39016] setDocList success 之后再设置 loading 为 false
                const isSuccess = await get().docListPageStore._refreshData()
                if (isSuccess) {
                    set((state) => {
                        state.docListPageStore.loading = false
                    })
                }
            } catch (e) {
                set((state) => {
                    state.docListPageStore.docList = []
                    state.docListPageStore.loading = true
                })
            }
        },
        getDocListSilent: async () => {
            try {
                const routeInfo = get().docListPageStore.routeInfo
                if (routeInfo) {
                    await get().docListPageStore._refreshData()
                }
            } catch (e) {}
        },
        setRouteInfo: (token, folderId) => {
            if (!token) {
                set((state) => {
                    state.docListPageStore.routeInfo = undefined
                })
            } else {
                set((state) => {
                    state.docListPageStore.routeInfo = { routeToken: token, folderId }
                })
            }
            get().docListPageStore.getDocList()
        },
    }
}

export const docId2DocSelector = (state: SpaceStore) => {
    const map: Map<string, DocWithAuthorityVO> = new Map()
    state.docListPageStore.docList.forEach((doc) => {
        map.set(doc.id, doc)
    })
    return map
}

const isInDocListPage = () => {
    const pathname = domLocation().pathname
    return (
        pathname.includes(RouteToken.Recent) ||
        pathname.includes(RouteToken.Drafts) ||
        pathname.includes(RouteToken.Trash) ||
        pathname.includes(RouteToken.Favorites) ||
        pathname.includes(RouteToken.Project)
    )
}

export const syncDocListPageState = (store: SpaceStoreType, notifyFlow: SpaceNotifyFlow) => {
    // 协同
    notifyFlow.addDocNameChangeCallback((msg) => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._changeDocName(msg)
        }
    })
    notifyFlow.addPrototypePageNameChangeCallback((msg) => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._changePrototypePageName(msg)
        }
    })
    notifyFlow.addFavoriteDocChangeCallback(({ id, type }) => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._changeDocFavorite({ id, type })
        }
    })
    notifyFlow.addPrototypeFavoriteChangeCallback(({ id, type }) => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._changeDocFavorite({ id, type })
        }
    })
    // 当收到用户纬度的收藏文件的消息，如果在收藏页面，就要刷新一下接口
    notifyFlow.addFavoriteDocChangeCallback(() => {
        if (domLocation().pathname.includes(RouteToken.Favorites)) {
            store.getState().docListPageStore._refreshData()
        }
    })
    notifyFlow.addPrototypeFavoriteChangeCallback(() => {
        if (domLocation().pathname.includes(RouteToken.Favorites)) {
            store.getState().docListPageStore._refreshData()
        }
    })
    notifyFlow.addDocRelationChangeCallback(() => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._refreshData()
        }
    })

    notifyFlow.addPrototypeRelationChangeCallback(() => {
        if (isInDocListPage()) {
            store.getState().docListPageStore._refreshData()
        }
    })
    notifyFlow.addDocThumbnailBgColorChangeCallback(store.getState().docListPageStore._changeDocThumbnailBgColor)
    notifyFlow.addDocThumbnailIdChangeCallback(store.getState().docListPageStore._changeDocThumbnailId)
    notifyFlow.addDocThumbnailUrlChangeCallback(store.getState().docListPageStore._changeDocThumbnailUrl)
    notifyFlow.addPrototypeThumbnailBgColorChangeCallback(store.getState().docListPageStore._changeDocThumbnailBgColor)
    notifyFlow.addPrototypeThumbnailUrlChangeCallback(store.getState().docListPageStore._changeDocThumbnailUrl)
    // 轮训
    notifyFlow.addSpaceIntervalCallback(store.getState().docListPageStore._refreshData)
}
