import { Wukong } from '@wukong/bridge-proto'

const propEnum2Name = new Map(Object.entries(Wukong.DocumentProto.NodeProps).map(([k, v]) => [v, k.replace('NP_', '')]))

const variableDataTypeMapper = new Map(
    Object.entries(Wukong.DocumentProto.VariableDataType).map(([k, v]) => [v, k.replace('VARIABLE_DATA_TYPE_', '')])
)

const variableResolvedDataTypeMapper = new Map(
    Object.entries(Wukong.DocumentProto.VariableResolvedDataType).map(([k, v]) => [
        v,
        k.replace('VARIABLE_RESOLVED_DATA_TYPE_', ''),
    ])
)

const paintTypeMapper = new Map(
    Object.entries(Wukong.DocumentProto.PaintType).map(([k, v]) => [v, k.replace('PAINT_TYPE_', '')])
)
const blendModeMapper = new Map(
    Object.entries(Wukong.DocumentProto.BlendMode).map(([k, v]) => [v, k.replace('BLEND_MODE_', '')])
)

const symbolData = (value: any) => {
    const overrides = value.symbolOverrides
    const newOverrides: Record<string, any> = {}
    Object.entries(overrides).forEach(([key, props]) => {
        const partialNode: Record<string, any> = {}
        ;(props as any).partialNode?.forEach((prop: any[]) => {
            if (prop.length == 2) {
                const mappedName = propEnum2Name.get(prop[0] as number)
                if (mappedName) {
                    partialNode[`NP_${mappedName}`] = prettifyPropValue(mappedName, prop[1])
                }
            }
        })
        ;(props as any).partialNode = partialNode
        if ((props as any).nodeId) {
            ;(props as any).nodeId = `$_${(props as any).nodeId}`
        }
        newOverrides[`$_${key}`] = props
    })
    value.mainComponentId = `$_${value.mainComponentId}`
    value.symbolOverrides = newOverrides
    return value
}

const derivedSymbolData = (value: any) => {
    const overrides = value.data
    const newOverrides: Record<string, any> = {}
    Object.entries(overrides).forEach(([key, props]) => {
        const partialNode: Record<string, any> = {}
        ;(props as any).partialNode?.forEach((prop: any[]) => {
            if (prop.length == 2) {
                const mappedName = propEnum2Name.get(prop[0] as number)
                if (mappedName) {
                    partialNode[`NP_${mappedName}`] = prettifyPropValue(mappedName, prop[1])
                }
            }
        })
        ;(props as any).partialNode = partialNode

        newOverrides[`$_${key}`] = props
    })
    value.data = newOverrides
    return value
}

const editedProps = (value: any) => {
    return value.map((prop: any) => propEnum2Name.get(prop as number))
}

const componentPropDefs = (value: any) => {
    const typeMapper = new Map(
        Object.entries(Wukong.DocumentProto.ComponentPropType).map(([k, v]) => [
            v,
            k.replace('COMPONENT_PROP_TYPE_', ''),
        ])
    )
    return value.map((def: any) => {
        def.type = typeMapper.get(def.type)
        def.varValue.dataType = variableDataTypeMapper.get(def.varValue.dataType)
        def.varValue.resolvedDataType = variableResolvedDataTypeMapper.get(def.varValue.resolvedDataType)
        return def
    })
}

const componentPropRefs = (value: any) => {
    const typeMapper = new Map(
        Object.entries(Wukong.DocumentProto.ComponentPropNodeField).map(([k, v]) => [
            v,
            k.replace('COMPONENT_PROP_NODE_FIELD_', ''),
        ])
    )
    return value.map((ref: any) => {
        ref.nodeField = typeMapper.get(ref.nodeField)
        return ref
    })
}

const componentPropAssignments = (value: any) => {
    return value.map((assignment: any) => {
        assignment.varValue.dataType = variableDataTypeMapper.get(assignment.varValue.dataType)
        assignment.varValue.resolvedDataType = variableResolvedDataTypeMapper.get(assignment.varValue.resolvedDataType)
        return assignment
    })
}

const prettifyComponentInstanceRelevantProps = (prop: string, value: any) => {
    switch (prop) {
        case 'symbolData':
            return symbolData(value)
        case 'derivedSymbolData':
            return derivedSymbolData(value)
        case 'editedProps':
            return editedProps(value)
        case 'componentPropDefs':
            return componentPropDefs(value)
        case 'componentPropRefs':
            return componentPropRefs(value)
        case 'componentPropAssignments':
            return componentPropAssignments(value)
        case 'mainComponentId':
            if (value) {
                return `$_${value}`
            }
    }
    return null
}

const variableResolvedType = (value: any) => {
    return variableResolvedDataTypeMapper.get(value)
}

const variableDataValues = (value: any) => {
    return value.map((v: any) => {
        v.variableData.dataType = variableDataTypeMapper.get(v.variableData.dataType)
        v.variableData.resolvedDataType = variableResolvedDataTypeMapper.get(v.variableData.resolvedDataType)
        return v
    })
}

const variableConsumptionMap = (value: any) => {
    const variableFieldMapper = new Map(
        Object.entries(Wukong.DocumentProto.VariableField).map(([k, v]) => [v, k.replace('VARIABLE_FIELD_', '')])
    )
    return value.map((v: any) => {
        v.variableField = variableFieldMapper.get(v.variableField)
        if (v.variableData) {
            v.variableData.dataType = variableDataTypeMapper.get(v.variableData.dataType)
            v.variableData.resolvedDataType = variableResolvedDataTypeMapper.get(v.variableData.resolvedDataType)
        }
        return v
    })
}

const variableScopes = (value: any) => {
    const variableScopeMapper = new Map(
        Object.entries(Wukong.DocumentProto.VariableScope).map(([k, v]) => [v, k.replace('VARIABLE_SCOPE_', '')])
    )
    return value.map((v: any) => {
        return variableScopeMapper.get(v)
    })
}

const variableCodeSyntax = (value: any) => {
    const variableCodeSyntaxMapper = new Map(
        Object.entries(Wukong.DocumentProto.VariableCodeSyntax).map(([k, v]) => [
            v,
            k.replace('VARIABLE_CODE_SYNTAX_', ''),
        ])
    )
    return value.map((v: any) => {
        v.platform = variableCodeSyntaxMapper.get(v.platform)
        return v
    })
}

const prettifyVariableRelevantProps = (prop: string, value: any) => {
    switch (prop) {
        case 'variableResolvedType':
            return variableResolvedType(value)
        case 'variableDataValues':
            return variableDataValues(value)
        case 'variableConsumptionMap':
            return variableConsumptionMap(value)
        case 'variableScopes':
            return variableScopes(value)
        case 'variableCodeSyntax':
            return variableCodeSyntax(value)
    }
    return null
}

const paints = (value: any) => {
    return value.map((v: any) => {
        v.type = paintTypeMapper.get(v.type)
        v.blendMode = blendModeMapper.get(v.blendMode)
        if (v.colorVar) {
            v.colorVar.dataType = variableDataTypeMapper.get(v.colorVar.dataType)
            v.colorVar.resolvedDataType = variableResolvedDataTypeMapper.get(v.colorVar.resolvedDataType)
        }
        if (v.gradientStops) {
            v.gradientStops = v.gradientStops.map((stop: any) => {
                if (stop.colorVar) {
                    stop.colorVar.dataType = variableDataTypeMapper.get(stop.colorVar.dataType)
                    stop.colorVar.resolvedDataType = variableResolvedDataTypeMapper.get(stop.colorVar.resolvedDataType)
                }
                return stop
            })
        }
        return v
    })
}
const prettifyPaintRelatedProps = (prop: string, value: any) => {
    switch (prop) {
        case 'fills':
            return paints(value)
        case 'strokes':
            return paints(value)
    }
    return null
}

export const prettifyPropValue = (prop: string, value: any) => {
    if (!value) {
        return null
    }
    try {
        return (
            prettifyComponentInstanceRelevantProps(prop, value) ??
            prettifyVariableRelevantProps(prop, value) ??
            prettifyPaintRelatedProps(prop, value) ??
            value
        )
    } catch (e) {
        return '属性解析失败'
    }
}
