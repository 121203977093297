import { createRoot, Root } from 'react-dom/client'
import { MonoIconCommonClose16, WKButton, WKIconButton, WKTextButton, WKTypography } from '../../../../../ui-lib/src'
import { WKDialog } from '../../../../../ui-lib/src/components/wk-dialog'
import { WKTour } from '../../../../../ui-lib/src/components/wk-tour'
import { domLocation, RouteToken } from '../../../../../util/src'
import { IN_JEST_TEST } from '../../../environment'
import { FeatureGuideScene } from '../../../kernel/interface/account'
import { OnboardingPopupStatus, OnboardingStatus } from '../../../kernel/interface/onboarding'
import { BatchUpdateOnboardingStatus, GetUserRequest } from '../../../kernel/request/user'
import { onboardingService } from '../onboarding-service'
import { DialogConfig, FeatureGuideConfig, TourConfig } from './data'

// [how to add a new feature guide 1]
class FeatureGuideService {
    private currentScene: FeatureGuideScene | null = null
    private div: HTMLDivElement | null = null
    private root: Root | null = null
    private infos: {
        orgId?: string
    } = {}
    constructor() {
        onboardingService.useZustandStore.subscribe((state) => {
            if (state.popupStatus !== OnboardingPopupStatus.Hide) {
                this.clearDom()
            }
        })
    }
    public setInfos(infos: { orgId?: string }) {
        this.infos = infos
    }
    private clearDom = () => {
        this.currentScene = null
        if (IN_JEST_TEST) {
            this.root?.unmount()
            this.div?.remove()
        } else {
            setTimeout(() => {
                this.root?.unmount()
                this.div?.remove()
            })
        }
    }

    private finish(scene: FeatureGuideScene) {
        new BatchUpdateOnboardingStatus([scene], OnboardingStatus.Finished).start()
    }

    private finishAndClearDom(scene: FeatureGuideScene) {
        this.finish(scene)
        this.clearDom()
    }

    private renderTour(tourProps: TourConfig['tourProps'], scene: FeatureGuideScene) {
        if (tourProps.stepsFn) {
            const calculateTargets = tourProps.stepsFn()
            tourProps.steps = calculateTargets
        }
        if (tourProps.hideProgressFn) {
            tourProps.hideProgress = tourProps.hideProgressFn()
        }
        this.div = document.createElement('div')
        document.body.append(this.div)
        this.root = createRoot(this.div)

        let current = 0
        const onFinish = () => {
            this.finishAndClearDom(scene)
        }
        const onClose = () => {
            this.finishAndClearDom(scene)
        }

        const update = () => {
            current = current + 1
            this.root?.render(
                <WKTour
                    {...tourProps}
                    open={true}
                    onFinish={onFinish}
                    current={current}
                    onChange={update}
                    onClose={onClose}
                />
            )
        }
        this.root?.render(
            <WKTour
                {...tourProps}
                open={true}
                current={current}
                onChange={update}
                onFinish={onFinish}
                onClose={onClose}
            />
        )
    }
    private renderDialog(dialogProps: DialogConfig['dialogProps'], scene: FeatureGuideScene) {
        /**
         * 点击蒙层 = 不关闭弹窗
         * 页面刷新后，弹窗不再展示（约等于关闭）
         * 所有弹窗都必须有 X 按钮
         * 点击 X 关闭弹窗
         */
        this.finish(scene)
        const onClose = () => {
            this.clearDom()
        }
        this.div = document.createElement('div')
        document.body.append(this.div)
        this.root = createRoot(this.div)
        this.root?.render(
            <WKDialog
                containerClassName="overflow-hidden gap-0"
                centered
                hideTitleLine={true}
                width={672}
                titleRender={null}
                footer={null}
                bodyStyle={{ padding: 0 }}
                visible={true}
            >
                <div className="relative h-70">
                    <video className="w-full h-full" src={dialogProps.video} autoPlay loop muted></video>
                    <WKIconButton
                        className="absolute top-13px right-4"
                        icon={<MonoIconCommonClose16 />}
                        onClick={onClose}
                    ></WKIconButton>
                </div>
                <div className="flex flex-col gap-10px p-8">
                    <div className="flex flex-col gap-3">
                        <WKTypography.Text weight="semibold" size={22}>
                            {dialogProps.title}
                        </WKTypography.Text>
                        <WKTypography.Text color="secondary" size={14}>
                            {dialogProps.description}
                        </WKTypography.Text>
                    </div>
                    <div className="flex justify-between">
                        <WKTextButton type={'primary'} onClick={dialogProps.secondaryButton.onClick} size={14}>
                            {dialogProps.secondaryButton.text}
                        </WKTextButton>
                        <WKButton
                            type={'primary'}
                            onClick={dialogProps.primaryButton.onClick}
                            className="wk-text-14! h-10! px-6!"
                        >
                            {dialogProps.primaryButton.text}
                        </WKButton>
                    </div>
                </div>
            </WKDialog>
        )
    }

    private getUser() {
        return new GetUserRequest().start()
    }

    public tryStart(scene: FeatureGuideScene) {
        let flag = false
        const featureGuide = FeatureGuideConfig(this.infos)[scene]
        // 如果当前有正在正式的引导，则跳过
        if (this.currentScene) {
            return
        }
        // 如果这个引导没有配置，则跳过
        if (!featureGuide) {
            return
        }
        // 如果当前时间不在引导的开始和结束时间之间，则跳过
        const now = new Date()
        if (now <= featureGuide.startTime || now >= featureGuide.endTime) {
            return
        }
        this.getUser().then((user) => {
            if (flag) {
                return
            }
            // 如果这个引导已经走完了，则跳过
            // 服务端还没加
            if (user.featureGuideScene2Status?.[scene] !== OnboardingStatus.Init) {
                return
            }
            // 如果用户的 onboarding 没有走完，功能指引也不会走
            const inEditor = domLocation().hostname.includes(`/${RouteToken.File}/`)
            const inOrg = this.infos.orgId !== '-1'
            if (inEditor) {
                if (
                    user.onBoardingScene2Status?.editor === OnboardingStatus.Init ||
                    user.onBoardingScene2Status?.editor === OnboardingStatus.Started
                ) {
                    return
                }
            } else {
                if (inOrg) {
                    if (
                        user.onBoardingScene2Status?.orgBenchMember === OnboardingStatus.Init ||
                        user.onBoardingScene2Status?.orgBenchMember === OnboardingStatus.Started
                    ) {
                        return
                    }
                } else {
                    if (
                        user.onBoardingScene2Status?.workBench === OnboardingStatus.Init ||
                        user.onBoardingScene2Status?.workBench === OnboardingStatus.Started
                    ) {
                        return
                    }
                }
            }

            let userAvailable = false
            if (featureGuide.userType === 'all') {
                // 如果用户类型是全部，则执行
                userAvailable = true
            } else {
                const { type, time } = featureGuide.userType
                if (type === 'old') {
                    // 如果用户类型是旧用户，则判断用户创建时间是否在引导开始时间之前
                    userAvailable = new Date(user.createdTime) <= time
                } else {
                    // 如果用户类型是新用户，则判断用户创建时间是否在引导开始时间之后
                    userAvailable = new Date(user.createdTime) >= time
                }
            }
            if (!userAvailable) {
                return
            }
            this.currentScene = scene
            if (featureGuide.config.type === 'tour') {
                this.renderTour(featureGuide.config.tourProps, scene)
            } else {
                this.renderDialog(featureGuide.config.dialogProps, scene)
            }
        })
        return () => {
            flag = true
            this.clearDom()
        }
    }

    public tryRemove(scene: FeatureGuideScene) {
        const featureGuide = FeatureGuideConfig(this.infos)[scene]
        if (featureGuide && this.currentScene === scene) {
            this.clearDom()
        }
    }
}

export const featureGuideService = new FeatureGuideService()
