import { Wukong } from '@wukong/bridge-proto'
import { translation } from './utils.translation'

export enum SelectedVariablesEditorActiveKey {
    DETAIL = 'detail',
    SCOPE = 'scope',
}

export enum VariableCodeSyntaxKey {
    Web = 'Web',
    iOS = 'iOS',
    Android = 'Android',
}

export const VariableCodeSyntaxKey2Platform: Record<VariableCodeSyntaxKey, Wukong.DocumentProto.VariableCodePlatform> =
    {
        [VariableCodeSyntaxKey.Web]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_W_E_B,
        [VariableCodeSyntaxKey.iOS]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_I_O_S,
        [VariableCodeSyntaxKey.Android]: Wukong.DocumentProto.VariableCodePlatform.VARIABLE_CODE_PLATFORM_A_N_D_R_O_I_D,
    }

// [how_to_add_new_variable_scope]
export enum VariableScopeCheckboxKey {
    AllForColor = 'AllForColor',
    AllFill = 'AllFill',
    FrameFill = 'FrameFill',
    ShapeFill = 'ShapeFill',
    TextFill = 'TextFill',
    StrokeFill = 'StrokeFill',
    EffectFill = 'EffectFill',
    AllForFloat = 'AllForFloat',
    CornerRadius = 'CornerRadius',
    Size = 'Size', // width, height
    AutoLayout = 'AutoLayout', // gap, padding
    StrokeForFloat = 'StrokeForFloat',
    EffectForFloat = 'EffectForFloat',
}
function getVariableScopeCheckboxKeyOnForColor() {
    return [
        VariableScopeCheckboxKey.AllForColor,
        VariableScopeCheckboxKey.AllFill,
        VariableScopeCheckboxKey.FrameFill,
        VariableScopeCheckboxKey.ShapeFill,
        VariableScopeCheckboxKey.TextFill,
        VariableScopeCheckboxKey.StrokeFill,
        VariableScopeCheckboxKey.EffectFill,
    ]
}

// [how_to_add_new_variable_scope]
export const VariableScopeCheckboxKeyDepth: Record<VariableScopeCheckboxKey, number> = {
    [VariableScopeCheckboxKey.AllForColor]: 0,
    [VariableScopeCheckboxKey.AllFill]: 1,
    [VariableScopeCheckboxKey.FrameFill]: 2,
    [VariableScopeCheckboxKey.ShapeFill]: 2,
    [VariableScopeCheckboxKey.TextFill]: 2,
    [VariableScopeCheckboxKey.StrokeFill]: 1,
    [VariableScopeCheckboxKey.EffectFill]: 1,
    [VariableScopeCheckboxKey.AllForFloat]: 0,
    [VariableScopeCheckboxKey.CornerRadius]: 1,
    [VariableScopeCheckboxKey.Size]: 1,
    [VariableScopeCheckboxKey.AutoLayout]: 1,
    [VariableScopeCheckboxKey.StrokeForFloat]: 1,
    [VariableScopeCheckboxKey.EffectForFloat]: 1,
}

// [how_to_add_new_variable_scope]
export const VariableScopeCheckboxKey2Label: Record<VariableScopeCheckboxKey, string> = {
    [VariableScopeCheckboxKey.AllForColor]: translation('AllForColor'),
    [VariableScopeCheckboxKey.AllFill]: translation('AllFill'),
    [VariableScopeCheckboxKey.FrameFill]: translation('FrameFill'),
    [VariableScopeCheckboxKey.ShapeFill]: translation('ShapeFill'),
    [VariableScopeCheckboxKey.TextFill]: translation('TextFill'),
    [VariableScopeCheckboxKey.StrokeFill]: translation('StrokeFill'),
    [VariableScopeCheckboxKey.EffectFill]: translation('EffectFill'),
    [VariableScopeCheckboxKey.AllForFloat]: translation('AllForFloat'),
    [VariableScopeCheckboxKey.CornerRadius]: translation('CornerRadius'),
    [VariableScopeCheckboxKey.Size]: translation('Size'),
    [VariableScopeCheckboxKey.AutoLayout]: translation('AutoLayout'),
    [VariableScopeCheckboxKey.StrokeForFloat]: translation('StrokeForFloat'),
    [VariableScopeCheckboxKey.EffectForFloat]: translation('EffectForFloat'),
}

// [how_to_add_new_variable_scope]
const VariableScopeCheckboxKey2Scope: Record<VariableScopeCheckboxKey, Wukong.DocumentProto.VariableScope> = {
    [VariableScopeCheckboxKey.AllForColor]: Wukong.DocumentProto.VariableScope.ALL_SCOPES,
    [VariableScopeCheckboxKey.AllFill]: Wukong.DocumentProto.VariableScope.ALL_FILLS,
    [VariableScopeCheckboxKey.FrameFill]: Wukong.DocumentProto.VariableScope.FRAME_FILL,
    [VariableScopeCheckboxKey.ShapeFill]: Wukong.DocumentProto.VariableScope.SHAPE_FILL,
    [VariableScopeCheckboxKey.TextFill]: Wukong.DocumentProto.VariableScope.TEXT_FILL,
    [VariableScopeCheckboxKey.StrokeFill]: Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E,
    [VariableScopeCheckboxKey.EffectFill]: Wukong.DocumentProto.VariableScope.EFFECT_COLOR,
    [VariableScopeCheckboxKey.AllForFloat]: Wukong.DocumentProto.VariableScope.ALL_SCOPES,
    [VariableScopeCheckboxKey.CornerRadius]: Wukong.DocumentProto.VariableScope.CORNER_RADIUS,
    [VariableScopeCheckboxKey.Size]: Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
    [VariableScopeCheckboxKey.AutoLayout]: Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_G_A_P,
    [VariableScopeCheckboxKey.StrokeForFloat]: Wukong.DocumentProto.VariableScope.STROKE_FLOAT,
    [VariableScopeCheckboxKey.EffectForFloat]: Wukong.DocumentProto.VariableScope.EFFECT_FLOAT,
}

// 按固定的 type 顺序展示 checkbox
// [how_to_support_new_type_of_variable]
const VariableTypeToScopeOrder = [
    Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR,
    Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
]

// 根据变量类型返回对应的 scope 选项，用户装填完整的 checkbox 列表
// [how_to_support_new_type_of_variable]
// [how_to_add_new_variable_scope]
function getVariableScopeCheckboxKeyByType(
    type: Wukong.DocumentProto.VariableResolvedDataType
): VariableScopeCheckboxKey[] {
    switch (type) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return [
                VariableScopeCheckboxKey.AllForColor,
                VariableScopeCheckboxKey.AllFill,
                VariableScopeCheckboxKey.FrameFill,
                VariableScopeCheckboxKey.ShapeFill,
                VariableScopeCheckboxKey.TextFill,
                VariableScopeCheckboxKey.StrokeFill,
                VariableScopeCheckboxKey.EffectFill,
            ]
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return [
                VariableScopeCheckboxKey.AllForFloat,
                VariableScopeCheckboxKey.CornerRadius,
                VariableScopeCheckboxKey.Size,
                VariableScopeCheckboxKey.AutoLayout,
                VariableScopeCheckboxKey.StrokeForFloat,
                VariableScopeCheckboxKey.EffectForFloat,
            ]
        default:
            return []
    }
}

// v1
export interface VariableScopeCheckboxValue {
    key: VariableScopeCheckboxKey
    mixed: boolean
    checked: boolean
}

// v2
export type VariableScopeCheckboxValueList = Array<{
    type: Wukong.DocumentProto.VariableResolvedDataType
    values: VariableScopeCheckboxValue[]
}>

export interface VariablePublishHiddenValue {
    value: boolean
    mixed: boolean
}

export function getVariablePublishHiddenValue(variables: Wukong.DocumentProto.ILocalVariable[]) {
    let val: VariablePublishHiddenValue | undefined
    for (const variable of variables) {
        if (!val) {
            val = {
                value: !variable.isPublishable,
                mixed: false,
            }
        } else if (val.value !== !variable.isPublishable) {
            val.mixed = true
            break
        }
    }
    return val
}

export function getVariableScopeCheckboxValues(
    variables: Wukong.DocumentProto.ILocalVariable[]
): VariableScopeCheckboxValue[] {
    const scopesList = variables.map((v) => v.scopes)
    let scopeCheckboxValuesMap = {} as Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>

    for (const [index, scopes] of scopesList.entries()) {
        if (index === 0) {
            scopeCheckboxValuesMap = generateScopeCheckboxValuesMap(scopes)
        } else if (calcScopeCheckboxValuesMap(scopeCheckboxValuesMap, generateScopeCheckboxValuesMap(scopes))) {
            break
        }
    }

    return getVariableScopeCheckboxKeyOnForColor().map((key) => scopeCheckboxValuesMap[key])
}

// 各个变量类型中 scope 到 checkbox key 的映射关系
// [how_to_support_new_type_of_variable]
// [how_to_add_new_variable_scope]
function mapVariableScopeToCheckboxKey(
    type: Wukong.DocumentProto.VariableResolvedDataType,
    scopes: Wukong.DocumentProto.VariableScope[]
): Set<VariableScopeCheckboxKey> {
    const keySet = new Set<VariableScopeCheckboxKey>()
    const scopeSet = new Set<Wukong.DocumentProto.VariableScope>(scopes)
    switch (type) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR: {
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.ALL_SCOPES)) {
                keySet.add(VariableScopeCheckboxKey.AllForColor)
                keySet.add(VariableScopeCheckboxKey.AllFill)
                keySet.add(VariableScopeCheckboxKey.FrameFill)
                keySet.add(VariableScopeCheckboxKey.ShapeFill)
                keySet.add(VariableScopeCheckboxKey.TextFill)
                keySet.add(VariableScopeCheckboxKey.StrokeFill)
                keySet.add(VariableScopeCheckboxKey.EffectFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.ALL_FILLS)) {
                keySet.add(VariableScopeCheckboxKey.AllFill)
                keySet.add(VariableScopeCheckboxKey.FrameFill)
                keySet.add(VariableScopeCheckboxKey.ShapeFill)
                keySet.add(VariableScopeCheckboxKey.TextFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.FRAME_FILL)) {
                keySet.add(VariableScopeCheckboxKey.FrameFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.SHAPE_FILL)) {
                keySet.add(VariableScopeCheckboxKey.ShapeFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.TEXT_FILL)) {
                keySet.add(VariableScopeCheckboxKey.TextFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E)) {
                keySet.add(VariableScopeCheckboxKey.StrokeFill)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.EFFECT_COLOR)) {
                keySet.add(VariableScopeCheckboxKey.EffectFill)
            }
            break
        }
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT: {
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.ALL_SCOPES)) {
                keySet.add(VariableScopeCheckboxKey.AllForFloat)
                keySet.add(VariableScopeCheckboxKey.CornerRadius)
                keySet.add(VariableScopeCheckboxKey.Size)
                keySet.add(VariableScopeCheckboxKey.AutoLayout)
                keySet.add(VariableScopeCheckboxKey.StrokeForFloat)
                keySet.add(VariableScopeCheckboxKey.EffectForFloat)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.CORNER_RADIUS)) {
                keySet.add(VariableScopeCheckboxKey.CornerRadius)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT)) {
                keySet.add(VariableScopeCheckboxKey.Size)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_G_A_P)) {
                keySet.add(VariableScopeCheckboxKey.AutoLayout)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.STROKE_FLOAT)) {
                keySet.add(VariableScopeCheckboxKey.StrokeForFloat)
            }
            if (scopeSet.has(Wukong.DocumentProto.VariableScope.EFFECT_FLOAT)) {
                keySet.add(VariableScopeCheckboxKey.EffectForFloat)
            }
            break
        }
        default:
            break
    }
    return keySet
}

export function getVariableScopeCheckboxValuesV2(
    variables: Wukong.DocumentProto.ILocalVariable[]
): VariableScopeCheckboxValueList {
    const dataList: Array<{
        keysValue: VariableScopeCheckboxValue[]
        // 每个 type 对应一组 var，每个 var 的 scopes 组成一个 set，所以是两层 set
        scopeSets: Set<Set<VariableScopeCheckboxKey>>
    }> = VariableTypeToScopeOrder.map((type) => ({
        keysValue: getVariableScopeCheckboxKeyByType(type).map((key) => ({
            key,
            mixed: false, // 默认值
            checked: false, // 默认值
        })),
        scopeSets: new Set(),
    }))
    // 为每个 type 装填对应类型的变量，并将变量的 scopes 各自转化为 keys set
    variables.forEach((v) => {
        dataList[VariableTypeToScopeOrder.indexOf(v.dataType)].scopeSets.add(
            mapVariableScopeToCheckboxKey(v.dataType, v.scopes)
        )
    })
    // 根据每组 type 中的 keys set 计算每个 key 的 mixed 和 checked 状态
    for (const data of dataList) {
        const { keysValue, scopeSets } = data
        for (const keyValue of keysValue) {
            const hasOrNotSet = new Set(scopeSets.entries().map(([set]) => set.has(keyValue.key)))
            // 只有一种状态为 true 时，checked 为 true
            keyValue.checked = hasOrNotSet.size === 1 && hasOrNotSet.has(true)
            // 有多种状态时，mixed 为 true
            keyValue.mixed = hasOrNotSet.size > 1
        }
    }

    return dataList
        .map((data, index) => {
            if (!data.scopeSets.size) {
                return null // 没有说明没有对应类型的变量
            }
            return {
                type: VariableTypeToScopeOrder[index], // 根据 index 拿到对应的 type
                values: data.keysValue,
            }
        })
        .filter((data) => data !== null)
}

export function updateVariableScopes(
    scopes: Wukong.DocumentProto.VariableScope[],
    checkboxKey: VariableScopeCheckboxKey,
    checked: boolean
) {
    const checkboxValuesMap = generateScopeCheckboxValuesMap(scopes)
    const isAllChecked = checkboxValuesMap[VariableScopeCheckboxKey.AllForColor].checked
    const isFillChecked = checkboxValuesMap[VariableScopeCheckboxKey.AllFill].checked
    const newScopes: Wukong.DocumentProto.VariableScope[] = []

    switch (checkboxKey) {
        case VariableScopeCheckboxKey.AllForColor:
            if (checked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            }
            break
        case VariableScopeCheckboxKey.AllFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                }
                ;[VariableScopeCheckboxKey.StrokeFill, VariableScopeCheckboxKey.EffectFill].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.FrameFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.FRAME_FILL)
                    }
                    ;[VariableScopeCheckboxKey.ShapeFill, VariableScopeCheckboxKey.TextFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.EffectFill, VariableScopeCheckboxKey.StrokeFill].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.ShapeFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.SHAPE_FILL)
                    }
                    ;[VariableScopeCheckboxKey.FrameFill, VariableScopeCheckboxKey.TextFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.EffectFill, VariableScopeCheckboxKey.StrokeFill].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.TextFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked && isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    if (checked) {
                        newScopes.push(Wukong.DocumentProto.VariableScope.TEXT_FILL)
                    }
                    ;[VariableScopeCheckboxKey.FrameFill, VariableScopeCheckboxKey.ShapeFill].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                ;[VariableScopeCheckboxKey.EffectFill, VariableScopeCheckboxKey.StrokeFill].forEach((key) => {
                    checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                })
            }
            break
        case VariableScopeCheckboxKey.StrokeFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E)
                }
                if (isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    ;[
                        VariableScopeCheckboxKey.FrameFill,
                        VariableScopeCheckboxKey.ShapeFill,
                        VariableScopeCheckboxKey.TextFill,
                    ].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                checkboxValuesMap[VariableScopeCheckboxKey.EffectFill].checked &&
                    newScopes.push(VariableScopeCheckboxKey2Scope[VariableScopeCheckboxKey.EffectFill])
            }
            break
        case VariableScopeCheckboxKey.EffectFill:
            if (checked && isAllChecked) {
                newScopes.push(Wukong.DocumentProto.VariableScope.ALL_SCOPES)
            } else {
                if (checked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.EFFECT_COLOR)
                }
                if (isFillChecked) {
                    newScopes.push(Wukong.DocumentProto.VariableScope.ALL_FILLS)
                } else {
                    ;[
                        VariableScopeCheckboxKey.FrameFill,
                        VariableScopeCheckboxKey.ShapeFill,
                        VariableScopeCheckboxKey.TextFill,
                    ].forEach((key) => {
                        checkboxValuesMap[key].checked && newScopes.push(VariableScopeCheckboxKey2Scope[key])
                    })
                }
                checkboxValuesMap[VariableScopeCheckboxKey.StrokeFill].checked &&
                    newScopes.push(VariableScopeCheckboxKey2Scope[VariableScopeCheckboxKey.StrokeFill])
            }
            break
        default:
            break
    }
    return newScopes
}

// 更新 key 的 checked 状态时计算全量 scope 的更新
// [how_to_support_new_type_of_variable]
// [how_to_add_new_variable_scope]
export function updateVariableScopesV2(
    type: Wukong.DocumentProto.VariableResolvedDataType,
    scopes: Wukong.DocumentProto.VariableScope[],
    checkboxKey: VariableScopeCheckboxKey,
    checked: boolean
) {
    // 1. 将 scope 转化为 keySet（一对多）
    // 2. 对于 key 之前和之后的值一样，则不变
    // 3. 根据 key 的层级：删除上级和下级的全部 key、根据 checked 设置自己
    // 4. 将剩余的 keySet 转化为 code（一对一）
    const keySet = mapVariableScopeToCheckboxKey(type, scopes)
    const hasChanged = (keySet.has(checkboxKey) && !checked) || (!keySet.has(checkboxKey) && checked)
    if (hasChanged) {
        switch (type) {
            case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR: {
                switch (checkboxKey) {
                    // 全部
                    case VariableScopeCheckboxKey.AllForColor: {
                        keySet.clear()
                        if (checked) {
                            keySet.add(checkboxKey)
                        }
                        break
                    }
                    // 一级不含二级
                    case VariableScopeCheckboxKey.StrokeFill:
                    case VariableScopeCheckboxKey.EffectFill: {
                        keySet.delete(VariableScopeCheckboxKey.AllForColor)
                        if (checked) {
                            keySet.add(checkboxKey)
                        } else {
                            keySet.delete(checkboxKey)
                        }
                        break
                    }
                    // 一级含二级
                    case VariableScopeCheckboxKey.AllFill: {
                        keySet.delete(VariableScopeCheckboxKey.AllForColor)
                        if (checked) {
                            keySet.add(checkboxKey)
                        } else {
                            keySet.delete(checkboxKey)
                        }
                        keySet.delete(VariableScopeCheckboxKey.FrameFill)
                        keySet.delete(VariableScopeCheckboxKey.ShapeFill)
                        keySet.delete(VariableScopeCheckboxKey.TextFill)
                        break
                    }
                    // 二级
                    case VariableScopeCheckboxKey.FrameFill:
                    case VariableScopeCheckboxKey.ShapeFill:
                    case VariableScopeCheckboxKey.TextFill: {
                        keySet.delete(VariableScopeCheckboxKey.AllForColor)
                        keySet.delete(VariableScopeCheckboxKey.AllFill)
                        const scope = checkboxKey
                        if (checked) {
                            keySet.add(scope)
                        } else {
                            keySet.delete(scope)
                        }
                        break
                    }
                    default:
                        break
                }
                break
            }
            case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT: {
                switch (checkboxKey) {
                    // 全部
                    case VariableScopeCheckboxKey.AllForFloat: {
                        keySet.clear()
                        if (checked) {
                            keySet.add(checkboxKey)
                        }
                        break
                    }
                    // 一级
                    case VariableScopeCheckboxKey.CornerRadius:
                    case VariableScopeCheckboxKey.Size:
                    case VariableScopeCheckboxKey.AutoLayout:
                    case VariableScopeCheckboxKey.StrokeForFloat:
                    case VariableScopeCheckboxKey.EffectForFloat: {
                        keySet.delete(VariableScopeCheckboxKey.AllForFloat)
                        if (checked) {
                            keySet.add(checkboxKey)
                        } else {
                            keySet.delete(checkboxKey)
                        }
                        break
                    }
                    default:
                        break
                }
                break
            }
            default:
                break
        }
    }
    return Array.from(keySet).map((key) => VariableScopeCheckboxKey2Scope[key])
}

function generateScopeCheckboxValuesMap(scopes: Wukong.DocumentProto.VariableScope[]) {
    const scopeCheckboxValuesMap = {} as Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>
    getVariableScopeCheckboxKeyOnForColor().forEach((key) => {
        scopeCheckboxValuesMap[key] = { key, mixed: false, checked: false }
    })
    scopes.forEach((scope) => {
        switch (scope) {
            case Wukong.DocumentProto.VariableScope.ALL_SCOPES:
                getVariableScopeCheckboxKeyOnForColor().forEach((key) => {
                    scopeCheckboxValuesMap[key].checked = true
                })
                break
            case Wukong.DocumentProto.VariableScope.ALL_FILLS:
                ;[
                    VariableScopeCheckboxKey.AllFill,
                    VariableScopeCheckboxKey.FrameFill,
                    VariableScopeCheckboxKey.ShapeFill,
                    VariableScopeCheckboxKey.TextFill,
                ].forEach((key) => {
                    scopeCheckboxValuesMap[key].checked = true
                })
                break
            case Wukong.DocumentProto.VariableScope.FRAME_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.FrameFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.SHAPE_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.ShapeFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.TEXT_FILL:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.TextFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_S_T_R_O_K_E:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.StrokeFill].checked = true
                break
            case Wukong.DocumentProto.VariableScope.EFFECT_COLOR:
                scopeCheckboxValuesMap[VariableScopeCheckboxKey.EffectFill].checked = true
                break
            default:
                break
        }
    })

    return scopeCheckboxValuesMap
}

function calcScopeCheckboxValuesMap(
    resMap: Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>,
    map: Record<VariableScopeCheckboxKey, VariableScopeCheckboxValue>
) {
    let isAllMixed = true
    getVariableScopeCheckboxKeyOnForColor().forEach((key) => {
        if (!resMap[key].mixed && resMap[key].checked !== map[key].checked) {
            resMap[key].mixed = true
        }
        if (!resMap[key].mixed) {
            isAllMixed = false
        }
    })
    return isAllMixed
}
