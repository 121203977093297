import { translation } from './left-side-panel.translation'
/* eslint-disable no-restricted-imports */
import { SelectSidebarPanelCommand, UpdateWindowInnerHeightWasmCall, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { ReactElement, ReactNode, useCallback, useMemo } from 'react'
import useEvent from 'react-use/lib/useEvent'
import { IconSearch, Tooltip } from '../../../../../ui-lib/src'
import { CommitType } from '../../../document/command/commit-type'
import { ToKeyCode } from '../../../document/util/keycode'
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'
import { KeyboardReceiver } from '../../../main/keyboard-receiver/component'
import { useLeftPanelWidth, useSetLeftPanelWidth } from '../../../main/layout/layout-context'
import { useViewState } from '../../../view-state-bridge'
import { EditorDataTestId } from '../../../window'
import { useCommand } from '../../context/document-context'
import { IconButton } from '../atom/button/icon-button'
import { InlineTabs } from '../atom/inline-tabs/inline-tabs'
import { CanvasSearchPanel } from '../canvas-search-panel'
import { useCanvasSearchParamModel } from '../canvas-search-panel/use-canvas-search-model'
import {
    LibraryAssetPanel,
    LibraryAssetPanelTitle,
} from '../component-style-library-v2/library-assets/library-assets-panel'
import { PagePanelContainer } from '../page-panel-container'
import { LeftSidePanelResizeLine } from './left-side-panel-resize-line'
import style from './left-side-panel.module.less'
import SidebarPanelType = Wukong.DocumentProto.SidebarPanelType

interface Item {
    type: SidebarPanelType
    title: ReactNode
    comp: ReactElement
    testId?: string
}

export function LeftSidePanel() {
    const command = useCommand()

    const sidebarPanels = useViewState('sidebarPanels', { sidebarPanels: [] }).sidebarPanels

    const docReadonly = useViewState('docReadonly')
    const canvasSearchParamModel = useCanvasSearchParamModel()

    const selectedPanel = useMemo(() => {
        return sidebarPanels.find(({ isSelected }) => isSelected)!
    }, [sidebarPanels])

    const computedWidth = useLeftPanelWidth()

    const setWidth = useSetLeftPanelWidth()

    // 监听 option 1 / 2 变化 tab
    const handleKeydown = (event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => {
        // 编辑时不响应
        if (docReadonly) {
            return true
        }
        // 修改 tab
        if (event.altKey && (event.code === 'Digit1' || event.code === 'Digit2')) {
            event.preventDefault()
            event.stopPropagation()
            selectPanel(
                event.code === 'Digit1'
                    ? SidebarPanelType.SIDEBAR_PANEL_TYPE_LAYER
                    : SidebarPanelType.SIDEBAR_PANEL_TYPE_ASSETS
            )
            return false
        }
        return true
    }

    const itemConfig: Item[] = [
        {
            type: SidebarPanelType.SIDEBAR_PANEL_TYPE_LAYER,
            title: translation('Layers'),
            comp: <PagePanelContainer />,
            testId: EditorDataTestId.PagePanelSwitch,
        },
        {
            type: SidebarPanelType.SIDEBAR_PANEL_TYPE_ASSETS,
            title: <LibraryAssetPanelTitle />,
            comp: <LibraryAssetPanel handleKeydown={handleKeydown} />,
            testId: EditorDataTestId.AssetPanelSwitch,
        },
    ]

    const selectPanel = (panelType: SidebarPanelType) => {
        command.invokeBridge(
            CommitType.Noop,
            SelectSidebarPanelCommand,
            Wukong.DocumentProto.Args_SelectSidebarPanelCommand.create({ value: panelType })
        )
    }

    const resetInnerHeight = useCallback(() => {
        command.invokeBridge(
            CommitType.Noop,
            UpdateWindowInnerHeightWasmCall,
            Wukong.DocumentProto.BridgeProtoInt.create({
                value: window.innerHeight,
            })
        )
    }, [command])

    useEvent('resize', resetInnerHeight)

    return (
        <>
            <div className={style.container} style={{ width: computedWidth + 'px' }}>
                {canvasSearchParamModel.modelState.show ? (
                    <CanvasSearchPanel></CanvasSearchPanel>
                ) : (
                    <>
                        {!docReadonly ? (
                            <div className={style.searchIcon}>
                                <Tooltip
                                    title={translation('Find…')}
                                    shortcut={shortcutLabelMap[ShortcutKey.CanvasSearch]}
                                >
                                    <IconButton
                                        dataTestId="canvas-search-button"
                                        icon={<IconSearch />}
                                        selected={false}
                                        onClick={canvasSearchParamModel.modelCommand.startSearch}
                                    />
                                </Tooltip>
                            </div>
                        ) : null}
                        <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={handleKeydown}>
                            {!docReadonly && (
                                <InlineTabs>
                                    {itemConfig.map((config) => (
                                        <InlineTabs.Item
                                            key={config.type}
                                            isActive={selectedPanel.type === config.type}
                                            onClick={() => selectPanel(config.type)}
                                            dataTestId={config.testId}
                                        >
                                            {config.title}
                                        </InlineTabs.Item>
                                    ))}
                                </InlineTabs>
                            )}
                        </KeyboardReceiver>
                        {itemConfig.map((config) => {
                            const classList = [style.tabContent]
                            if (selectedPanel.type === config.type) classList.push(style.show)
                            return (
                                <div key={config.type} className={classNames(...classList)}>
                                    {selectedPanel.type === config.type ? config.comp : null}
                                </div>
                            )
                        })}
                    </>
                )}
            </div>

            <LeftSidePanelResizeLine computedWidth={computedWidth} setWidth={setWidth} />
        </>
    )
}
