import { useVirtualizer } from '@tanstack/react-virtual'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Scrollbar, ScrollbarRef } from '../../../../../../../ui-lib/src/components/scrollbars'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { IN_JEST_TEST } from '../../../../../environment'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { isSelectableVariableItem, PrimitiveVariableVirtualItem, SelectableVariableItem } from '../common'
import { CollectionNameItem } from './collection-name-item'
import { GroupNameItem } from './group-name-item'
import { LibraryNameItem } from './library-name-item'
import { LocalFloatVariableItem } from './local-float-variable-item'
import { RemoteFloatVariableItem } from './remote-float-variable-item'
import { UnknownSingleVariableItem } from './unkown-single-variable-item'

export interface PrimitiveVariableListRef {
    scrollToSelectedOption: () => void
    nextOption: () => void
    prevOption: () => void
    onSubmit: () => void
}

interface VirtualPrimitiveVariableListProps {
    items: PrimitiveVariableVirtualItem[]
    onItemSelected: (item: SelectableVariableItem) => void
    hideVariableValue?: boolean
}

const VirtualPrimitiveVariableListInternal = (
    props: VirtualPrimitiveVariableListProps,
    ref: React.Ref<PrimitiveVariableListRef>
) => {
    const { items, onItemSelected, hideVariableValue } = props
    const parentRef = useRef<ScrollbarRef>(null)

    const rowVirtualizer = useVirtualizer({
        count: items.length,
        overscan: IN_JEST_TEST ? 200 : undefined,
        getScrollElement: () => {
            if (parentRef.current) {
                return parentRef.current.getContainerElement()
            }
            return null
        },
        estimateSize: (i) => {
            const item = items[i]
            switch (item.type) {
                case 'collection':
                    return 28
                case 'group':
                    return 28
                case 'library-name':
                    return 28
                case 'local-variable':
                    return 32
                case 'remote-variable':
                    return 32
                case 'unkown-single-variable':
                    return 32
                case 'divider':
                    return 16
            }
        },
    })

    const [preselectIndex, setPreselectIndex] = useState<number>(0)
    const scrollIndex = useCallback(
        (optionIndex: number) => {
            for (let i = 0; i < items.length; ++i) {
                const item = items[i]
                if (isSelectableVariableItem(item) && item.optionIndex === optionIndex) {
                    rowVirtualizer.scrollToIndex(i)
                    return
                }
            }
        },
        [items, rowVirtualizer]
    )
    const optionCount = useMemo(() => items.filter(isSelectableVariableItem).length, [items])
    const selectedOptionIndex = useMemo(() => {
        const find = items.filter(isSelectableVariableItem).find((item) => item.isSelected)
        return find ? find.optionIndex : -1
    }, [items])

    const [needScrollToSelected, setNeedScrollToSeleted] = useState<boolean>(true)
    useEffect(() => {
        if (!needScrollToSelected) {
            return
        }

        if (selectedOptionIndex >= 0) {
            setPreselectIndex(selectedOptionIndex)
            scrollIndex(selectedOptionIndex)
            setNeedScrollToSeleted(false)
        } else {
            setPreselectIndex(0)
            scrollIndex(0)
            setNeedScrollToSeleted(false)
        }
    }, [needScrollToSelected, scrollIndex, selectedOptionIndex])

    const nextOption = useCallback(() => {
        const nextIndex = preselectIndex + 1 < optionCount ? preselectIndex + 1 : 0
        setPreselectIndex(nextIndex)
        scrollIndex(nextIndex)
    }, [preselectIndex, optionCount, scrollIndex])

    const prevOption = useCallback(() => {
        const nextIndex = preselectIndex - 1 >= 0 ? preselectIndex - 1 : optionCount - 1
        setPreselectIndex(nextIndex)
        scrollIndex(nextIndex)
    }, [preselectIndex, optionCount, scrollIndex])

    const onSubmit = useCallback(() => {
        const target = items.find((item) => isSelectableVariableItem(item) && item.optionIndex === preselectIndex)
        if (target && isSelectableVariableItem(target)) {
            onItemSelected(target)
        }
    }, [items, preselectIndex, onItemSelected])

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
            e.stopPropagation()
            e.preventDefault()
            nextOption()
            return false
        } else if (e.key === 'ArrowUp') {
            e.stopPropagation()
            e.preventDefault()
            prevOption()
            return false
        } else if (e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            onSubmit()
            return false
        }

        return true
    }

    useImperativeHandle(ref, () => ({
        scrollToSelectedOption: () => {
            setNeedScrollToSeleted(true)
        },
        nextOption,
        prevOption,
        onSubmit,
    }))

    const renderItem = (index: number) => {
        const item = items[index]
        switch (item.type) {
            case 'collection':
                return <CollectionNameItem name={item.name} />
            case 'group':
                return <GroupNameItem name={item.name} />
            case 'library-name':
                return <LibraryNameItem name={item.name} />
            case 'local-variable':
                return (
                    <LocalFloatVariableItem
                        data={item.data}
                        hideVariableValue={hideVariableValue}
                        isSelected={item.isSelected}
                        isPreselect={item.optionIndex === preselectIndex}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        onClick={() => onItemSelected(item)}
                    />
                )
            case 'remote-variable':
                return (
                    <RemoteFloatVariableItem
                        data={item.data}
                        hideVariableValue={hideVariableValue}
                        isSelected={item.isSelected}
                        isPreselect={item.optionIndex === preselectIndex}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        onClick={() => onItemSelected(item)}
                    />
                )
            case 'unkown-single-variable':
                return (
                    <UnknownSingleVariableItem
                        data={item.data}
                        hideVariableValue={hideVariableValue}
                        isSelected={item.isSelected}
                        isPreselect={item.optionIndex === preselectIndex}
                        onMouseEnter={() => setPreselectIndex(item.optionIndex)}
                        onMouseLeave={() => setPreselectIndex(-1)}
                        onClick={() => onItemSelected(item)}
                    />
                )
            case 'divider':
                return <div className="w-full box-border border-t border-$wk-v2-stroke-color-gray-2 mt-8px mb-7px" />
        }
    }

    return (
        <Scrollbar
            ref={parentRef}
            hideHorizontalScrollbar
            autoHeight
            autoHeightMin={0}
            autoHeightMax={528}
            data-testid="primitive-variable-list"
        >
            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={onKeyDown} className="pb-8px">
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <div
                            key={virtualItem.key}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`,
                            }}
                        >
                            {renderItem(virtualItem.index)}
                        </div>
                    ))}
                </div>
            </KeyboardReceiver>
        </Scrollbar>
    )
}

export const PrimitiveVariableList = forwardRef(VirtualPrimitiveVariableListInternal)
