import { BlockUnit } from './block-unit'

const DocBlock = () => {
    return <div className="bg-$wk-v2-gray-color-2 rounded-3px pt-3/5"></div>
}

const FoderBlock = () => {
    return (
        <div className="ring p-5 ring-1 ring-inset ring-$wk-v2-stroke-color-gray-2 rounded-4px">
            <div className="grid gap-3 grid-cols-2">
                <DocBlock />
                <DocBlock />
                <DocBlock />
                <DocBlock />
            </div>
            <BlockUnit className="w-140px h-16px mt-5" />
            <BlockUnit className="w-140px h-14px mt-3.5" />
        </div>
    )
}

export const Shared = () => {
    return (
        <div className="px-8 pt-3">
            <BlockUnit className="w-140px h-34px" />
            <BlockUnit className="w-140px h-14px mt-9" />
            <div
                style={{
                    gridTemplateColumns: 'repeat(auto-fill,minmax(256px,1fr))',
                    gridTemplateRows: 'max-content',
                }}
                className="mt-6 grid gap-8"
            >
                <FoderBlock />
                <FoderBlock />
                <FoderBlock />
                <FoderBlock />
            </div>
        </div>
    )
}
