import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    Title: '变量',
    Create: '创建变量',
} as const

export const enTranslation = {
    Title: 'Variables',
    Create: 'Create variable',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
