import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    TryExample: '尝试例子',
    Gen: '生成',
    Retry: '重试',
    InGen: '生成中...',
    UploadImage: '上传单张图片（最大 5MB）',
    randomPrompt: '切换示例',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    TryExample: 'Try example',
    Gen: 'Generate',
    Retry: 'Retry',
    InGen: 'Generating...',
    UploadImage: 'Upload one image (max 5MB)',
    randomPrompt: 'Random prompt',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
