import { Wukong } from '@wukong/bridge-proto'
import { useRef } from 'react'
import {
    DraggablePopupV2,
    InputV2,
    MonoIconCanvasLink16,
    MonoIconCommonAdd16,
    MonoIconCommonRemove16,
    MonoIconPanelTextEditor16,
    Tooltip,
    WKIconButton,
    WKToast,
} from '../../../../../../ui-lib/src'
import { copyFlowLinkForEditor } from '../../../../prototype/context/present-url'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { PrototypeAddStartingPointProvider, usePrototypeAddStartingPoint } from './hook'
import { translation } from './prototype-add-starting-point.translation'

const Empty = () => {
    const { addStartingPoint } = usePrototypeAddStartingPoint()
    return (
        <div
            onClick={addStartingPoint}
            className="py-10px pl-4 pr-10px b-b border-color-$wk-v2-stroke-color-gray-3 flex justify-between items-center"
        >
            <span className="color-$wk-v2-label-color-gray-8 wk-font-semibold hover:color-$wk-v2-label-color-gray-13">
                {translation('FlowStartingPoint')}
            </span>
            <Tooltip title={translation('AddStartingPoint')}>
                <WKIconButton data-testid="batch-add-starting-ponit" icon={<MonoIconCommonAdd16 />} />
            </Tooltip>
        </div>
    )
}

const SingleFlow = () => {
    const state = useViewState('prototypeFlowListForSelection')
    const flow = state?.value!
    const { changeFlowName, updateDesc, deleteStartPoint, autoFocus, descVisible, closeDescDialog, openDescDialog } =
        usePrototypeAddStartingPoint()

    const ref = useRef<HTMLDivElement>(null)
    const position = ref.current?.getBoundingClientRect()
    const command = useCommand()

    const copy = () => {
        copyFlowLinkForEditor(command, flow.nodeId)
        WKToast.show(translation('LinkCopied'))
    }

    return (
        <>
            <div className="pt-2 pb-3 b-b border-color-$wk-v2-stroke-color-gray-3">
                <div className="flex py-2px pl-4 pr-10px justify-between items-center group">
                    <span className="color-$wk-v2-label-color-gray-11 wk-font-semibold">
                        {translation('FlowStartingPoint')}
                    </span>
                    <div className="flex items-center">
                        <Tooltip title={translation('CopyLink')}>
                            <WKIconButton
                                className="hidden group-hover:block"
                                data-testid="add-starting-point-copy-flow-link"
                                onClick={copy}
                                icon={<MonoIconCanvasLink16 />}
                            />
                        </Tooltip>
                        <Tooltip title={translation('RemoveStartingPoint')}>
                            <WKIconButton
                                data-testid="delete-start-point"
                                className="!ml-10px"
                                onClick={deleteStartPoint}
                                icon={<MonoIconCommonRemove16 />}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div ref={ref} className="py-1 pl-4 pr-10px flex items-center">
                    <InputV2
                        value={flow.name}
                        dataTestIds={{ input: 'flow-name-input' }}
                        tabIndex={-1}
                        onKeyDown={(e) => e.stopPropagation()}
                        key={Math.random()}
                        autoFocus={autoFocus}
                        size="small"
                        rootClassName="flex-1"
                        onBlur={(e) => changeFlowName(e.target.value)}
                    />
                    <Tooltip title={translation('EditDescription')}>
                        <WKIconButton
                            onClick={openDescDialog}
                            data-testid="edit-desc"
                            type={descVisible ? 'deepBlue' : 'secondary'}
                            className="ml-10px"
                            icon={<MonoIconPanelTextEditor16 />}
                        />
                    </Tooltip>
                </div>
            </div>
            <DraggablePopupV2
                positionRightBase
                testId="desc-dialog-popup"
                position={position}
                visible={descVisible}
                bodyClassName="p-4"
                tabIndex={-1}
                footer={null}
                styleType={'editor'}
                onCancel={closeDescDialog}
                header={translation('Description')}
                overflowVisible
            >
                <InputV2.Textarea
                    defaultValue={flow.description}
                    placeholder={translation('EnterADescriptionForTheFlow')}
                    autoFocus
                    autoHeight
                    dataTestIds={{ textarea: 'desc-textarea' }}
                    minHeight={84}
                    onBlur={(e) => updateDesc(e.target.value)}
                    maxHeight={316}
                    onKeyDown={(e) => e.code === 'Escape' && e.stopPropagation()}
                />
            </DraggablePopupV2>
        </>
    )
}

const MutilFlows = () => {
    const { deleteStartPoint } = usePrototypeAddStartingPoint()

    return (
        <div className="pt-2 pb-3 pl-4 pr-10px b-b border-color-$wk-v2-stroke-color-gray-3">
            <div className="flex py-2px justify-between items-center">
                <span className="color-$wk-v2-label-color-gray-11 wk-font-semibold">
                    {translation('FlowStartingPoint')}
                </span>
                <Tooltip title={translation('RemoveStartingPoint')}>
                    <WKIconButton
                        data-testid="batch-remove-starting-point"
                        onClick={deleteStartPoint}
                        icon={<MonoIconCommonRemove16 />}
                    />
                </Tooltip>
            </div>
            <div className="color-$wk-v2-label-color-gray-8 py-2">{translation('RemoveAll')}</div>
        </div>
    )
}

export const PrototypeAddStartingPoint = () => {
    const state = useViewState('prototypeFlowListForSelection')
    const show = state?.show
    const showType = state?.showType!
    if (!show) {
        return <></>
    } else {
        return (
            <PrototypeAddStartingPointProvider>
                {showType ===
                    Wukong.DocumentProto.FlowListForSelectionShowType.FLOW_LIST_FOR_SELECTION_SHOW_TYPE_ADD && (
                    <Empty />
                )}
                {showType ===
                    Wukong.DocumentProto.FlowListForSelectionShowType.FLOW_LIST_FOR_SELECTION_SHOW_TYPE_EDIT && (
                    <SingleFlow />
                )}
                {showType ===
                    Wukong.DocumentProto.FlowListForSelectionShowType.FLOW_LIST_FOR_SELECTION_SHOW_TYPE_REMOVE && (
                    <MutilFlows />
                )}
            </PrototypeAddStartingPointProvider>
        )
    }
}
