import { EndLibraryPublishModalPublishCheckedWasmCall, PublishLibrary, Wukong } from '@wukong/bridge-proto'
import { delay } from 'signal-timers'
import { WKToast } from '../../../../../../ui-lib/src'
import { TraceableAbortSignal } from '../../../../../../util/src/abort-controller/traceable-abort-controller'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { CommitType } from '../../../../document/command/commit-type'
import { benchmarkService } from '../../../../kernel/benchmark'
import { Bridge } from '../../../../kernel/bridge/bridge'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { LibraryId } from '../../../../kernel/interface/component-style-library-id'
import { LibraryContentVO } from '../../../../kernel/interface/library'
import { CrashFrogType } from '../../../../kernel/interface/performance'
import { BusinessStatusCode } from '../../../../kernel/interface/request-error-code'
import { MetricCollector, MetricName } from '../../../../kernel/metric-collector'
import { RequestResponseErrorHandler } from '../../../../kernel/request/error-handler'
import {
    GetLibraryIdAndNameByDocIdRequest,
    PostLibrary,
    PutLibrary,
    UpdateLibraryContent,
} from '../../../../kernel/request/library'
import { ServiceClass } from '../../../../kernel/util/service-class'
import { ViewStateBridge } from '../../../../view-state-bridge'
import { HistoryService } from '../../history-file/history-service/history-service'
import { isLibraryHasPublished } from '../util/status'
import { LibraryNotifySyncService } from './library-notify-sync-service'
import { translation } from './publish-components-executor-v2.translation'

// 组件库发布执行器
export class PublishComponentsExecutorV2 extends ServiceClass {
    private progressToastId: string | null = null
    private isPublished = false

    constructor(
        private readonly docId: string,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge,
        protected override readonly viewStateBridge: ViewStateBridge,
        private readonly libraryNotifySyncService: LibraryNotifySyncService,
        private readonly historyService: HistoryService,
        private readonly signal: TraceableAbortSignal
    ) {
        super(bridge, viewStateBridge)

        this.bridge.bind(
            PublishLibrary,
            async (arg) => {
                await this.run(arg)
                this.commandInvoker.invokeBridge(CommitType.Noop, EndLibraryPublishModalPublishCheckedWasmCall)
            },
            { signal: this.signal }
        )
    }

    public override destroy(): void {
        super.destroy()
        this.closeProgressToast()
    }

    private run = async (arg: Wukong.DocumentProto.IArg_publishLibraryManifest) => {
        if (this.viewStateBridge.getDefaultValue('publishModal')?.publishing) {
            return WKToast.show(`${translation('PublishingLibrary')}`)
        }
        const oldLibraryContent = await this.libraryNotifySyncService.getCurrentRemoteLibraryContentPromise()

        this.isPublished = !!oldLibraryContent?.library.shared && isLibraryHasPublished(oldLibraryContent.library)

        try {
            this.showProgressToast()

            const start = performance.now()
            const libraryId = await this.createOrUpdateLibrary(arg.shareScope, oldLibraryContent)
            await this.updateContent(libraryId, arg)
            MetricCollector.pushMetric(MetricName.LIBRARY_PUBLISH, performance.now() - start)

            this.closeProgressToast()
            WKToast.show(
                this.isPublished
                    ? translation('SuccessfullyPublishedLibraryUpdates')
                    : translation('SuccessfullyPublishedLibrary')
            )

            this.historyService.requestCreateUpdateLibraryVersion(arg.description ?? '')

            benchmarkService.benchmarkCrash(CrashFrogType.PublishOrUpdateLibrary, false, {
                tag: this.isPublished ? 'publish' : 'update',
            })
        } catch (e) {
            try {
                this.closeProgressToast()

                const { businessStatus } = RequestResponseErrorHandler(e)
                if (businessStatus === BusinessStatusCode.LibraryDocumentInDraftFolderCanNotPublish) {
                    return WKToast.show(translation('HDcKoP'))
                } else if (businessStatus === BusinessStatusCode.LibrarySharedScopeOverLimit) {
                    return WKToast.show(translation('TeamsAreNot'))
                } else {
                    WkCLog.log('[library] publish library uncaught businessStatus', {
                        businessStatus,
                    })
                }
            } catch {
                WkCLog.log('[library] publish library unknown error', {
                    errorMsg: `${e}`,
                })
            }

            benchmarkService.benchmarkCrash(CrashFrogType.PublishOrUpdateLibrary, true, {
                tag: this.isPublished ? 'publish' : 'update',
            })

            return WKToast.error(
                this.isPublished ? translation('FailedToPublish') : translation('FailedToPublish_synonyms1')
            )
        }
    }

    private showProgressToast = () => {
        this.progressToastId = WKToast.show(
            this.isPublished ? translation('PublishingLibrary_synonyms1') : translation('PublishingLibrary'),
            {
                duration: -1,
            }
        )
    }

    private closeProgressToast = () => {
        const toastId = this.progressToastId
        if (toastId) {
            this.progressToastId = null
            WKToast.close(toastId)
        }
    }

    private createOrUpdateLibrary = async (
        shareScope: string | null | undefined,
        oldLibraryContent: LibraryContentVO | null
    ): Promise<LibraryId> => {
        if (oldLibraryContent) {
            if (shareScope && oldLibraryContent?.library.shared && oldLibraryContent.library.shareScope == shareScope) {
                return oldLibraryContent.library.id
            }

            await new PutLibrary(oldLibraryContent.library.id, {
                id: oldLibraryContent.library.id,
                shared: true,
                shareScope,
            }).start()

            return oldLibraryContent.library.id
        }

        const existLibraryInfoVO = await new GetLibraryIdAndNameByDocIdRequest(this.docId, this.docId).start()
        if (existLibraryInfoVO) {
            await new PutLibrary(existLibraryInfoVO.id, {
                id: existLibraryInfoVO.id,
                shared: true,
                shareScope,
            }).start()

            return existLibraryInfoVO.id
        }

        return (
            await new PostLibrary({
                docId: this.docId,
                shareScope: shareScope,
            }).start()
        ).id
    }

    private updateContent = async (libraryId: LibraryId, arg: Wukong.DocumentProto.IArg_publishLibraryManifest) => {
        const maxRetryAttempts = 2
        const scalingDuration = 6000
        for (let i = 0; i < maxRetryAttempts; i++) {
            try {
                return await new UpdateLibraryContent(libraryId, arg).startWithSignal(this.signal)
            } catch (e) {
                if (this.signal.aborted || i === maxRetryAttempts - 1) {
                    throw e
                } else {
                    await delay(scalingDuration * i, { signal: this.signal })
                }
            }
        }
    }
}
