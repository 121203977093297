export const DEFAULT_LEFT_PANEL_WIDTH = 248
export const DEFAULT_TOPBAR_HEIGHT = 48
export const DEFAULT_TOP_BANNER_MIDDLE_HEIGHT = 44
export const DEFAULT_TOP_BANNER_HEIGHT = 78
export const DEFAULT_BOTTOM_BANNER_HEIGHT = 256

export const DEFAULT_RIGHT_PANEL_WIDTH = 248
export const DEV_MODE_RIGHT_PANEL_WIDTH = 300

// TODO(jiangzg): UI 确认最终宽度
export const AI_RIGHT_PANEL_WIDTH = 400
