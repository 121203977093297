import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DraggablePopupV2 } from '../../../../../../ui-lib/src/components/draggable-popup'
import { PureBlock } from '../../atom/color-block/pure'
import { HexInput } from '../../atom/inputs/hex-input'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { useRenderColorSpace } from '../../color-profile'
import { ColorPicker } from '../../design/blend/color-picker/color-picker'
import { hex2rgb, rgb2hex } from '../../design/blend/color-picker/utils/color-translate'
import style from './ant-design-theme-colors.module.less'
import { translation } from './ant-design-theme-colors.translation'
import { antdColorNameWithIndex } from './constants'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'

function ColorSwatch({
    name,
    hex,
    opacity = 1,
    onChange,
    onOpacityChange,
    disabled = false,
    onOpenColorPicker,
}: {
    name: string
    hex: string
    opacity?: number
    onChange: (newHex: string) => void
    onOpacityChange: (newOpacity: number) => void
    disabled?: boolean
    onOpenColorPicker: (
        buttonRef: React.RefObject<HTMLDivElement>,
        initialColor: string,
        initialOpacity: number
    ) => void
}) {
    const buttonRef = useRef<HTMLDivElement>(null)
    const colorSpace = useRenderColorSpace()

    const handleOpenColorPicker = useCallback(() => {
        onOpenColorPicker(buttonRef, hex, opacity)
    }, [onOpenColorPicker, hex, opacity])

    return (
        <div key={name} className="flex flex-col w-full h-15 gap-1">
            <span className="text-gray w-full wk-text-12">{name}</span>
            <div className="flex flex-row bg-[var(--wk-gray-1)] h-8 rounded-0.75 items-center">
                <PureBlock
                    dataTestId={`ant-design-pure-block-${hex}`}
                    ref={buttonRef}
                    rgb={hex2rgb(hex)}
                    colorSpace={colorSpace}
                    size={16}
                    opacity={opacity}
                    className={`${style['ant-design-color-swatch']} shrink-0 bg-transparent cursor-default hover:bg-[var(--wk-gray-13-4)] rounded-0.5 border-0 outline-none p-0 flex justify-center items-center`}
                    onClick={handleOpenColorPicker}
                />
                <HexInput
                    data-testid={`custom-color-swatch-${name}-hex`}
                    className="w-15! h-7! rounded-1.5 bg-transparent hover:bg-[var(--wk-v2-gray-color-2)]"
                    value={hex.replace('#', '')}
                    onChange={onChange}
                    disabled={disabled}
                />
                {opacity !== 1 ? (
                    <ScrubbableInputPercent
                        data-testid={`custom-color-swatch-${name}-opacity`}
                        inputClassName="px-2!"
                        className="w-fit bg-transparent w-10 h-7! rounded-1.5"
                        value={Number.parseFloat(opacity.toFixed(2))}
                        onChange={(value) => {
                            if (typeof value === 'number') {
                                onOpacityChange(value / 100)
                            }
                        }}
                        disabled={disabled}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}

export function AntDesignThemeColors() {
    const {
        antdDesignPalette,
        setCustomizedDerivedColor,
        openAntdCustomDerivedColorPicker,
        openedColorPicker,
        closeAntdCustomDerivedColorPicker,
    } = usePlatformAndDesignSystemInContext()
    const [activeButtonRef, setActiveButtonRef] = useState<React.RefObject<HTMLDivElement> | null>(null)
    const [activeColorIndex, setActiveColorIndex] = useState<keyof typeof antdDesignPalette | null>(null)
    const [selectedCustomColor, setSelectedCustomColor] = useState<string>('')
    const [colorPickerOpacity, setColorPickerOpacity] = useState(1)
    const colorSpace = useRenderColorSpace()

    // Helper function to convert opacity to hex alpha value
    const opacityToHexAlpha = useCallback((opacity: number) => {
        return Math.round(opacity * 255)
            .toString(16)
            .padStart(2, '0')
    }, [])

    // Helper function to update color with new hex and opacity
    const updateColorWithHexAndOpacity = useCallback(
        (index: keyof typeof antdDesignPalette, hexColor: string, opacity: number) => {
            setCustomizedDerivedColor((prev) => {
                const newMap = new Map(prev)
                const alpha = opacityToHexAlpha(opacity)
                newMap.set(index, '#' + hexColor.replace('#', '').substring(0, 6) + alpha)
                return newMap
            })
        },
        [setCustomizedDerivedColor, opacityToHexAlpha]
    )

    const colorPickerPosition = useMemo(() => {
        if (activeButtonRef?.current) {
            const rect = activeButtonRef.current.getBoundingClientRect()
            return { top: rect.bottom, left: rect.left }
        }
        return { top: 0, left: 0 }
    }, [activeButtonRef])

    const handleOpenColorPicker = useCallback(
        (
            buttonRef: React.RefObject<HTMLDivElement>,
            initialColor: string,
            initialOpacity: number,
            index: keyof typeof antdDesignPalette
        ) => {
            setActiveButtonRef(buttonRef)
            setSelectedCustomColor(initialColor)
            setColorPickerOpacity(initialOpacity)
            setActiveColorIndex(index)
            openAntdCustomDerivedColorPicker()
        },
        [openAntdCustomDerivedColorPicker]
    )

    const handleCloseColorPicker = useCallback(() => {
        closeAntdCustomDerivedColorPicker()
        setActiveButtonRef(null)
        setSelectedCustomColor('')
        setColorPickerOpacity(1)
        setActiveColorIndex(null)
    }, [closeAntdCustomDerivedColorPicker])

    const handleColorChange = useCallback(
        (rgb: { r: number; g: number; b: number }) => {
            const newHex = rgb2hex(rgb.r, rgb.g, rgb.b)
            setSelectedCustomColor(newHex)

            if (activeColorIndex) {
                updateColorWithHexAndOpacity(activeColorIndex, newHex, colorPickerOpacity)
            }
        },
        [activeColorIndex, updateColorWithHexAndOpacity, colorPickerOpacity]
    )

    const handleOpacityChange = useCallback(
        (newOpacity: number) => {
            setColorPickerOpacity(newOpacity)

            if (activeColorIndex) {
                const currentColor = selectedCustomColor || '#000000'
                updateColorWithHexAndOpacity(activeColorIndex, currentColor, newOpacity)
            }
        },
        [activeColorIndex, updateColorWithHexAndOpacity, selectedCustomColor]
    )

    const handleEscape = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleCloseColorPicker()
            }
        },
        [handleCloseColorPicker]
    )

    useEffect(() => {
        document.addEventListener('keydown', handleEscape)
        return () => {
            document.removeEventListener('keydown', handleEscape)
        }
    }, [handleEscape])

    const handleHexChange = useCallback(
        (index: keyof typeof antdDesignPalette, value: string) => {
            const currentColor = antdDesignPalette[index] || '#000000'
            const newAlphaHex = currentColor.length > 7 ? currentColor.substring(7, 9) : 'FF'
            updateColorWithHexAndOpacity(index, value, parseInt(newAlphaHex, 16) / 255)
        },
        [antdDesignPalette, updateColorWithHexAndOpacity]
    )

    const handleSwatchOpacityChange = useCallback(
        (index: keyof typeof antdDesignPalette, newOpacity: number) => {
            const currentColor = antdDesignPalette[index] || '#000000'
            const rgbPart = currentColor.replace('#', '').substring(0, 6)
            updateColorWithHexAndOpacity(index, '#' + rgbPart, newOpacity)
        },
        [antdDesignPalette, updateColorWithHexAndOpacity]
    )

    return (
        <div className="mb-0.5">
            <div className="grid grid-cols-2 gap-x-2 gap-y-3 w-full">
                {antdColorNameWithIndex.map(({ name, index }) => {
                    const colorWithAlpha = antdDesignPalette[index] ?? '#000000'
                    const hex = colorWithAlpha.substring(0, 7)
                    const alphaHex = colorWithAlpha.length > 7 ? colorWithAlpha.substring(7, 9) : 'FF'
                    const opacity = parseInt(alphaHex, 16) / 255

                    return (
                        <ColorSwatch
                            key={name}
                            name={name}
                            hex={hex}
                            opacity={opacity}
                            onChange={(value) => handleHexChange(index, value)}
                            onOpacityChange={(newOpacity) => handleSwatchOpacityChange(index, newOpacity)}
                            onOpenColorPicker={(buttonRef, initialColor, initialOpacity) =>
                                handleOpenColorPicker(buttonRef, initialColor, initialOpacity, index)
                            }
                        />
                    )
                })}
            </div>
            <DraggablePopupV2
                data-testid="antd-color-picker"
                bodyClassName="p-0"
                width={240}
                visible={openedColorPicker === 'antd-custom-derived'}
                position={colorPickerPosition}
                onCancel={handleCloseColorPicker}
                header={
                    <div className="p-0 m-0 h-10 flex flex-row justify-start items-center">
                        <span className="wk-text-12 wk-font-medium px-4">{translation('color')}</span>
                    </div>
                }
                closeClassName="right-2.5"
                className="z-2000"
                mask
                closable={true}
                draggable={false}
                footer={<></>}
            >
                <ColorPicker
                    color={hex2rgb(selectedCustomColor ?? '#000')}
                    colorSpace={colorSpace}
                    opacity={colorPickerOpacity}
                    onChangeColor={handleColorChange}
                    onChangeOpacity={handleOpacityChange}
                />
            </DraggablePopupV2>
        </div>
    )
}
