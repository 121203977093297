import { ChatbotInput } from './chat-input'
import { ChatbotInputProvider } from './use-chat-input'

export const ChatBotAI = () => {
    return (
        <ChatbotInputProvider>
            <div className="w-full h-full flex flex-col box-border p-10px">
                <div className="flex-1 overflow-x-hidden overflow-y-auto">Hello</div>
                <div className="shrink-0">
                    <ChatbotInput />
                </div>
            </div>
        </ChatbotInputProvider>
    )
}
