import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    deleteConfirmation: '确认删除当前风格?',
    deleteConfirmationDescription: '删除风格后将无法恢复。',
    delete: '删除',
    editDescription: '你可以直接编辑当前设计系统的关键参数后保存为新风格，以便后续重复使用',
    default: '（默认）',
    stylingPresets: '风格预设',
    mobile: '移动端',
    web: '网页端',
    styling: '设置风格',
    mobileAuto: '移动端 - 自由风格',
    webAuto: '网页端 - 自由风格',
    maxiummTip: '最多支持创建 5 个风格',
    remixStyling: '改编风格',
    editStyling: '编辑风格',
    autoStyling: '自由风格',
} as const

export const enTranslation: Record<keyof typeof zhTranslation, string> = {
    deleteConfirmation: 'Are you sure you want to delete the current styling?',
    deleteConfirmationDescription: 'Once the current styling is deleted, it cannot be recovered.',
    delete: 'Delete',
    editDescription: 'You can directly edit the default styling and remix it as a new styling for future use.',
    default: '(Default)',
    stylingPresets: 'Styling Presets',
    mobile: 'Mobile',
    web: 'Web',
    styling: 'Styling',
    mobileAuto: 'Mobile Auto',
    webAuto: 'Web Auto',
    maxiummTip: 'A maximum of 5 stylings can be remixed.',
    remixStyling: 'Remix Styling',
    editStyling: 'Edit Styling',
    autoStyling: 'Auto Styling',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
