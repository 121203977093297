/* eslint-disable no-restricted-imports */
import { UpdateWindowInnerHeightWasmCall, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import useEvent from 'react-use/lib/useEvent'
import { CommitType } from '../../../../document/command/commit-type'
import { useDevModeAvailabilityVerificationService } from '../../../../main/app-context'
import { useLeftPanelWidth, useSetLeftPanelWidth } from '../../../../main/layout/layout-context'
import { useCommand } from '../../../context/document-context'
import { CanvasSearchPanel } from '../../canvas-search-panel'
import { useCanvasSearchParamModel } from '../../canvas-search-panel/use-canvas-search-model'
import style from './dev-mode-left-side-panel.module.less'
import { DevModePagePanelContainer } from './dev-mode-page-panel-container'
import { LeftSidePanelResizeLine } from './left-side-panel-resize-line'

export function DevModeLeftSidePanel() {
    const command = useCommand()

    const canvasSearchParamModel = useCanvasSearchParamModel()

    const computedWidth = useLeftPanelWidth()

    const setWidth = useSetLeftPanelWidth()

    const resetInnerHeight = useCallback(() => {
        command.invokeBridge(
            CommitType.Noop,
            UpdateWindowInnerHeightWasmCall,
            Wukong.DocumentProto.BridgeProtoInt.create({
                value: window.innerHeight,
            })
        )
    }, [command])

    useEvent('resize', resetInnerHeight)

    const devModeAvailablieyVerificationService = useDevModeAvailabilityVerificationService()
    const isDevModeAccessible = devModeAvailablieyVerificationService.useZustandStore.use.isDevModeAvailable()

    return (
        <>
            <div className={style.container} style={{ width: computedWidth + 'px' }}>
                {canvasSearchParamModel.modelState.show && isDevModeAccessible ? (
                    <CanvasSearchPanel></CanvasSearchPanel>
                ) : (
                    <DevModePagePanelContainer />
                )}
            </div>
            <LeftSidePanelResizeLine computedWidth={computedWidth} setWidth={setWidth} />
        </>
    )
}
