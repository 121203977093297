import {
    BatchUpdateBaseAttributeCommand,
    BatchUpdateMinSizeAndMaxSizeWasmCall,
    SelectionAttachMinMaxWidthHeightFloatVarCommand,
    SelectionAttachWidthHeightFloatVarCommand,
    SelectionDetachMinMaxWidthHeightFloatVarCommand,
    SelectionDetachWidthHeightFloatVarCommand,
    UpdateSelectionBaseAttributeCommand,
    UpdateSelectionConstrainProportionsCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useMemo, useRef } from 'react'
import { safeCall } from '../../../../../../ui-lib/src'
import { CommitType } from '../../../../document/command/commit-type'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { ScrubbableInputNumberRef } from '../../atom/inputs/scrubbable-input-number'
import { Value } from '../../atom/inputs/utils/type'
import { useFloatVariablePanel } from '../primitive-variable/use-float-variable-panel'
import { getIsShowMinMaxSizeDropdown, MinAndMaxSizeOption, useMinMaxSizeDropdownInputProps } from './min-max-size'

export function useAttrAboutWidthHeight() {
    const command = useCommand()
    const constrainProportions = useViewState('constrainProportions')
    const state = useViewState('baseAttributeV2')
    const variableConsumptionMapState = useViewState('variableConsumptionMapState')
    const minMaxSizeSelectInputProps = useMinMaxSizeDropdownInputProps()

    const onChangeConstrainProportions = (v: boolean) => {
        command.invokeBridge(CommitType.CommitUndo, UpdateSelectionConstrainProportionsCommand, { value: !!v })
    }
    /** 宽度 start */
    const onChangeWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            command.invokeBridge(CommitType.Noop, SelectionDetachWidthHeightFloatVarCommand, { width: true })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, UpdateSelectionBaseAttributeCommand, {
                w: value,
            })
        }
    }
    const onChangeMixedWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        if (state?.w.values) {
            const res: Record<string, number> = {}
            Object.entries(state.w.values).forEach(([name, value]) => {
                res[name] = safeCall(parse, value) ?? value
            })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateBaseAttributeCommand, {
                type: 'w',
                values: res,
            })
        }
    }
    const onScrubChangeAllMixedWidth = (values: Map<string, Value>) => {
        command.invokeBridge(CommitType.Noop, BatchUpdateBaseAttributeCommand, {
            type: 'w',
            values: Object.fromEntries(values) as Record<string, number>,
        })
    }
    const widthInputRef = useRef<ScrubbableInputNumberRef>(null)
    const widthFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.VARIABLE_FIELD_W_I_D_T_H
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const isShowWidthMinMaxSizeDropdown = useMemo(() => getIsShowMinMaxSizeDropdown(state?.minW.editType), [state])
    const widthFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: widthFloatVariable,
        selectedVariableFallbackFloatValue: state?.w.value,
        createEnable: true,
        defaultCreateValue: state?.w.value,
        hideIconBindUnbind: isShowWidthMinMaxSizeDropdown,
        onVariableSelected: (id) =>
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachWidthHeightFloatVarCommand, { widthVarId: id }),
        onVariableDetach: () =>
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachWidthHeightFloatVarCommand, { width: true }),
    })
    const onSelectWidth = (v: MinAndMaxSizeOption) => {
        if (v === MinAndMaxSizeOption.applyVariable) {
            const rect = widthInputRef.current?.getRootElement().getBoundingClientRect()
            if (rect) {
                widthFloatVariablePicker.openPicker({ top: rect.bottom + 8, left: rect.left })
            }
        } else if (v === MinAndMaxSizeOption.unbindVariable) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachWidthHeightFloatVarCommand, { width: true })
        }
        minMaxSizeSelectInputProps.onMinMaxSizeOptionChange(v)
    }
    /** 宽度 end */
    /** 高度 start */
    const onChangeHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        if (typeof value === 'number') {
            command.invokeBridge(CommitType.Noop, SelectionDetachWidthHeightFloatVarCommand, { height: true })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, UpdateSelectionBaseAttributeCommand, {
                h: value,
            })
        }
    }
    const onChangeMixedHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        if (state?.h.values) {
            const res: Record<string, number> = {}
            Object.entries(state.h.values).forEach(([name, value]) => {
                res[name] = safeCall(parse, value) ?? value
            })
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateBaseAttributeCommand, {
                type: 'h',
                values: res,
            })
        }
    }
    const onScrubChangeAllMixedHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateBaseAttributeCommand, {
            type: 'h',
            values: Object.fromEntries(values) as Record<string, number>,
        })
    }
    const heightInputRef = useRef<ScrubbableInputNumberRef>(null)
    const heightFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.VARIABLE_FIELD_H_E_I_G_H_T
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const isShowHeightMinMaxSizeDropdown = useMemo(() => getIsShowMinMaxSizeDropdown(state?.minH.editType), [state])
    const heightFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: heightFloatVariable,
        selectedVariableFallbackFloatValue: state?.h.value,
        createEnable: true,
        defaultCreateValue: state?.h.value,
        hideIconBindUnbind: isShowHeightMinMaxSizeDropdown,
        onVariableSelected: (id) =>
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachWidthHeightFloatVarCommand, {
                heightVarId: id,
            }),
        onVariableDetach: () =>
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachWidthHeightFloatVarCommand, { height: true }),
    })
    const onSelectHeight = (v: MinAndMaxSizeOption) => {
        if (v === MinAndMaxSizeOption.applyVariable) {
            const rect = heightInputRef.current?.getRootElement().getBoundingClientRect()
            if (rect) {
                heightFloatVariablePicker.openPicker({ top: rect.bottom + 8, left: rect.left })
            }
        } else if (v === MinAndMaxSizeOption.unbindVariable) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachWidthHeightFloatVarCommand, { height: true })
        }
        minMaxSizeSelectInputProps.onMinMaxSizeOptionChange(v)
    }
    /* 高度 end */
    /** 最小宽度 start */
    const onChangeMinWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (typeof value === 'number' && state?.minW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.minW.values).map((nodeId) => ({
                    nodeId,
                    value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const onChangeMixedMinWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (state?.minW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.minW.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMinWidth = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
            })),
        })
    }
    const minWidthFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.MIN_WIDTH
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const onAttachMinWidthFloatVariable = (id: string) => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (state?.minW.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.minW.values).map((nodeId) => ({
                    nodeId,
                    varId: id,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const onDetachMinWidthFloatVariable = () => {
        minMaxSizeSelectInputProps.markForceShowMinWidth()
        if (state?.minW.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.minW.values).map((nodeId) => ({
                    nodeId,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                })),
            })
        }
    }
    const minWidthFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: minWidthFloatVariable,
        selectedVariableFallbackFloatValue: state?.minW.value,
        createEnable: true,
        defaultCreateValue: state?.minW.value,
        onVariableSelected: onAttachMinWidthFloatVariable,
        onVariableDetach: onDetachMinWidthFloatVariable,
    })
    /** 最小宽度 end */
    /** 最小高度 start */
    const onChangeMinHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (typeof value === 'number' && state?.minH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.minH.values).map((nodeId) => ({
                    nodeId,
                    value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const onChangeMixedMinHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (state?.minH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.minH.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMinHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
            })),
        })
    }
    const minHeightFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.MIN_HEIGHT
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const onAttachMinHeightFloatVariable = (id: string) => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (state?.minH.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.minH.values).map((nodeId) => ({
                    nodeId,
                    varId: id,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const onDetachMinHeightFloatVariable = () => {
        minMaxSizeSelectInputProps.markForceShowMinHeight()
        if (state?.minH.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.minH.values).map((nodeId) => ({
                    nodeId,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                })),
            })
        }
    }
    const minHeightFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: minHeightFloatVariable,
        selectedVariableFallbackFloatValue: state?.minH.value,
        createEnable: true,
        defaultCreateValue: state?.minH.value,
        onVariableSelected: onAttachMinHeightFloatVariable,
        onVariableDetach: onDetachMinHeightFloatVariable,
    })
    /** 最小高度 end */
    /** 最大宽度 start */
    const onChangeMaxWidth = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (typeof value === 'number' && state?.maxW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.maxW.values).map((nodeId) => ({
                    nodeId,
                    value: value || NaN,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const onChangeMixedMaxWidth = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (state?.maxW.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.maxW.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMaxWidth = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value: value || NaN,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
            })),
        })
    }
    const maxWidthFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.MAX_WIDTH
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const onAttachMaxWidthFloatVariable = (id: string) => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (state?.maxW.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.maxW.values).map((nodeId) => ({
                    nodeId,
                    varId: id,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const onDetachMaxWidthFloatVariable = () => {
        minMaxSizeSelectInputProps.markForceShowMaxWidth()
        if (state?.maxW.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.maxW.values).map((nodeId) => ({
                    nodeId,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                })),
            })
        }
    }
    const maxWidthFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: maxWidthFloatVariable,
        selectedVariableFallbackFloatValue: state?.maxW.value,
        createEnable: true,
        defaultCreateValue: state?.maxW.value,
        onVariableSelected: onAttachMaxWidthFloatVariable,
        onVariableDetach: onDetachMaxWidthFloatVariable,
    })
    /** 最大宽度 end */
    /** 最大高度 start */
    const onChangeMaxHeight = (value: Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (typeof value === 'number' && state?.maxH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.keys(state?.maxH.values).map((nodeId) => ({
                    nodeId,
                    value: value || NaN,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const onChangeMixedMaxHeight = (parse: (value: Value) => Value, options?: InputOptionsForUndoSquash) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (state?.maxH.values) {
            command.invokeBridge(options?.commitType ?? CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
                params: Object.entries(state?.maxH.values).map(([nodeId, value]) => ({
                    nodeId,
                    value: safeCall(parse, value) ?? value,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const onScrubChangeAllMixedMaxHeight = (values: Map<string, Value>) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        command.invokeBridge(CommitType.Noop, BatchUpdateMinSizeAndMaxSizeWasmCall, {
            params: (Object.entries(values) as [string, number][]).map(([id, value]) => ({
                id,
                value: value || NaN,
                type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                    .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
            })),
        })
    }
    const maxHeightFloatVariable = useMemo(() => {
        const witthItem = variableConsumptionMapState?.items.find(
            (item) => item.field === Wukong.DocumentProto.VariableField.MAX_HEIGHT
        )
        const floatVariable = variableConsumptionMapState?.floatVariables.find(
            (item) => item.id === witthItem?.variableData?.value.alias
        )
        return floatVariable
    }, [variableConsumptionMapState])
    const onAttachMaxHeightFloatVariable = (id: string) => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (state?.maxH.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionAttachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.maxH.values).map((nodeId) => ({
                    nodeId,
                    varId: id,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const onDetachMaxHeightFloatVariable = () => {
        minMaxSizeSelectInputProps.markForceShowMaxHeight()
        if (state?.maxH.values) {
            command.invokeBridge(CommitType.CommitUndo, SelectionDetachMinMaxWidthHeightFloatVarCommand, {
                params: Object.keys(state.maxH.values).map((nodeId) => ({
                    nodeId,
                    type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                })),
            })
        }
    }
    const maxHeightFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.WIDTH_HEIGHT,
        ],
        selectedVariable: maxHeightFloatVariable,
        selectedVariableFallbackFloatValue: state?.maxH.value,
        createEnable: true,
        defaultCreateValue: state?.maxH.value,
        onVariableSelected: onAttachMaxHeightFloatVariable,
        onVariableDetach: onDetachMaxHeightFloatVariable,
    })
    /** 最大高度 end */

    return {
        constrainProportions,
        onChangeConstrainProportions,
        state,
        minMaxSizeSelectInputProps,
        onChangeWidth,
        onChangeHeight,
        onChangeMinWidth,
        onChangeMaxWidth,
        onChangeMinHeight,
        onChangeMaxHeight,
        onChangeMixedWidth,
        onChangeMixedHeight,
        onChangeMixedMinWidth,
        onChangeMixedMinHeight,
        onChangeMixedMaxWidth,
        onChangeMixedMaxHeight,
        onScrubChangeAllMixedWidth,
        onScrubChangeAllMixedHeight,
        onScrubChangeAllMixedMinWidth,
        onScrubChangeAllMixedMaxWidth,
        onScrubChangeAllMixedMinHeight,
        onScrubChangeAllMixedMaxHeight,
        widthInputRef,
        widthFloatVariablePicker,
        isShowWidthMinMaxSizeDropdown,
        onSelectWidth,
        heightInputRef,
        heightFloatVariablePicker,
        isShowHeightMinMaxSizeDropdown,
        onSelectHeight,
        minWidthFloatVariablePicker,
        minHeightFloatVariablePicker,
        maxWidthFloatVariablePicker,
        maxHeightFloatVariablePicker,
    }
}
