import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'library-publish',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['187f4103b95a657d2e5cd7845bbbc84b4f577a62', '2ce4665f28bf253877c3c2193651c43cab63066a'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
