import { Wukong } from '@wukong/bridge-proto'
import { useViewState } from '../../../view-state-bridge/use-view-state'

// 本地变量编辑状态
export function useEditingDetailState() {
    const editingState = useViewState('localVariableEditorEditingState')
    return editingState?.type ===
        Wukong.DocumentProto.LocalVariableEditorEditingType.LOCAL_VARIABLE_EDITOR_EDITING_TYPE_DETAIL
        ? editingState.position
        : null
}
