import { Wukong } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { featureSwitchManager } from '../../../../kernel/switch'
import { useViewState } from '../../../../view-state-bridge'
import { isLocalPrimitiveVariableScopeMatch, isRemotePrimitiveVariableScopeMatch } from './common'

export const useRequiredVariable = ({
    requiredScopes,
    requiredTypes,
}: {
    requiredScopes: Wukong.DocumentProto.VariableScope[]
    requiredTypes: Wukong.DocumentProto.PrimitiveVariableType[]
}) => {
    const { localCollections, remoteLibraries } = useViewState('librariesPrimitiveVariable', {
        localCollections: [],
        remoteLibraries: [],
    })

    const hasRequiredVariable = useMemo(() => {
        if (!featureSwitchManager.isEnabled('float-variable')) {
            return false
        }

        return (
            localCollections.some((collection) => {
                return collection.variables.some((variable) => {
                    return (
                        requiredTypes.includes(variable.data.type) &&
                        isLocalPrimitiveVariableScopeMatch(variable, requiredScopes)
                    )
                })
            }) ||
            remoteLibraries.some((library) => {
                return library.collections.some((collection) => {
                    return collection.variables.some((variable) => {
                        return (
                            requiredTypes.includes(variable.data.type) &&
                            isRemotePrimitiveVariableScopeMatch(variable, requiredScopes)
                        )
                    })
                })
            })
        )
    }, [localCollections, remoteLibraries, requiredScopes, requiredTypes])

    return { hasRequiredVariable } as const
}
