import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    OK: '我知道了',
    GoToMain: '跳转组件母版',
    pbpmng: '无法跳转，该实例（样式）为 Figma 导入，在当前团队内未查找到组件母版，请前往 Figma 下载组件母版所在文件并迁入到 {{name}}。',
    FigmaLibrary: '该文件引用的 Figma 组件库',
    NotImported: '未导入',
    ViewFile: '查看文件',
    // V2
    UnableGoToMain: '无法跳转至组件母版',
    UnableGoToMainDesc: '无法找到组件母版，你可以在本文件中恢复组件母版。恢复后，相关实例将关联恢复的本地组件母版。',
    ComponentMigrationTip1: '该实例来自 Figma。使用',
    ComponentMigration: '「组件迁移」',
    ComponentMigrationTip2: '功能可还原组件库和其他设计文件在 Figma 中的关联关系。',
    RestoreMainComponent: '恢复组件母版',
    Cancel: '取消',
} as const

export const enTranslation = {
    OK: 'OK',
    GoToMain: 'Go to main component',
    pbpmng: 'Unable to redirect, the instance (style) was imported from Figma. The main component cannot be found in the current team, you can download it from Figma and import it to {{name}}.',
    FigmaLibrary: 'The figma library used in this file',
    NotImported: 'Not imported',
    ViewFile: 'View file',
    // V2
    UnableGoToMain: 'Unable to go to main component',
    UnableGoToMainDesc:
        'The main component cannot be found. You can restore the main component within this file. After restoring, the related instances will be associated with the restored local component.',
    ComponentMigrationTip1: 'This instance is from Figma. ',
    ComponentMigration: 'Import library',
    ComponentMigrationTip2:
        ' feature can restore the relationships between the libraries and other design files in Figma.',
    RestoreMainComponent: 'Restore main component',
    Cancel: 'Cancel',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
