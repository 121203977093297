import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'font-size-select',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.ALL_USER,
            },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                group: UserGroupType.MOTIFF_GROUP,
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
