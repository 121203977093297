import { isNil } from 'lodash-es'
import { UserBriefAndErrorVO } from '../../../../ui-lib/src'
import { randomNumber } from '../../../../util/src'
import {
    ContractSpecification,
    DocPageCountVO,
    DocWithAuthorityVO,
    FolderID,
    OrganizationVO,
    OrgID,
    PayPlanType,
    PlanAndUserStateVO,
    ResourceType,
    RoleStatus,
    SeatType,
    TeamID,
    TeamResourceStateVO,
    TeamWithAuthorityVO,
    UserBriefVO,
    UserID,
} from '../../kernel/interface/type'
import { compareRole } from '../../kernel/interface/user-roles'
import { GetDocPageCount } from '../../kernel/request/document'
import { GetFolderRequest } from '../../kernel/request/folder'
import { GetTeamResourceState, QueryTeamAndUserState } from '../../kernel/request/team'
import { GetUserRequest } from '../../kernel/request/user'
import { GetBatchInviteValidateInfo } from '../../kernel/request/user-roles'
import { featureSwitchManager } from '../../kernel/switch'
import {
    checkHasSeatByUserSeatStatus,
    checkUsageExceedInFreeTeam,
    checkUserIsAdminInPayment,
    getPayUpgradeDialogFrom,
    getPlanAndUserState,
    getUserRole,
    getUserSeatStatus,
    UserSeatStatus,
} from '../../utils/payment'
import {
    isActiveOrgPlan,
    isActiveProPlan,
    isLockedOrgPlan,
    isLockedProPlan,
    isStarterPlan,
} from '../../utils/plan-status-checker'
import { UpgradePopupType, WKFrogTask } from '../wk-frog-task'
import { PayPlanLockedDialog } from './pay-lock-dialog'
import { translation } from './pay-pre-check.translation'
import { PayApplySeatOfCreateDocPromise } from './pay-seat-apply-create-doc'
import { PayApplySeatOfImportDocPromise } from './pay-seat-apply-import-doc'
import { PayApplySeatOfMoveDocsPromise } from './pay-seat-apply-move-doc'
import { PayApplySeatOfShareDocPromise } from './pay-seat-apply-share-doc'
import { PayUpgradeDialog } from './pay-upgrade-dialog/pay-upgrade-dialog'
import { PayUpgradeDialogContainerProps } from './pay-upgrade-dialog/pay-upgrade-dialog-struct/pay-upgrade-dialog-container/pay-upgrade-dialog-container'
import {
    PayUpgradeDialogFrom,
    PayUpgradeDialogType,
    PriceComparisonProfessionalProps,
} from './pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import {
    PayUpgradeCopyFileFileLimitSceneDialog,
    PayUpgradeCopyFilePageLimitSceneDialog,
    PayUpgradeCreateMoreFile,
    PayUpgradeMoveFileFileLimitSceneDiadlog,
    PayUpgradeMoveFilePageLimitSceneDiadlog,
    PayUpgradeRecoverFileFileLimitSceneDiadlog,
    PayUpgradeRecoverFilePageLimitSceneDiadlog,
} from './pay-upgrade-scene-modal'
import { QueryOrgAndUserState } from '../../kernel/request/organizations'

export enum CheckImportDocCode {
    None,
    NotFound, // 没找到相关的信息
    PageLimit, // page 数超出
    DocLimit, // doc 数超出
}
class PayPreCheckClass {
    public getTeamResourceState = async (teamId: string) => {
        return new GetTeamResourceState(teamId).start()
    }

    public getTeamResourceUsage = async (teamId: string) => {
        return this.getTeamResourceState(teamId).then((res) => {
            return new Map(Object.entries(res.folderId2DocId2PageCount))
        })
    }

    public getFolderResourceUsage = async (teamId: string, folderId: string) => {
        const moveOutTeamInfo = await this.getTeamResourceUsage(teamId)
        const folderInfo = moveOutTeamInfo?.get(folderId)
        if (!folderInfo) {
            return undefined
        }
        const docId2PageCount = new Map(Object.entries(folderInfo))
        return docId2PageCount
    }

    public getDocResourceUsage = async (teamId: string, folderId: string, docId: string) => {
        const docId2PageCount = await this.getFolderResourceUsage(teamId, folderId)
        if (!docId2PageCount) {
            return undefined
        }
        return docId2PageCount.get(docId)
    }

    /**
     * @description 检测创建文件的版本限制与席位限制
     */
    public checkCreateDoc = async (params: {
        orgId: string | undefined
        teamId: string | undefined
        folderId: string
        inDraft: boolean
        from: PayUpgradeDialogFrom
        checkPlanIsUpdatingByFolderId?: (folderId: string) => Promise<boolean>
        initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: string) => Promise<void>
        sync?: {
            // cmd + click 创建的时候不能等网络请求回来后处理，因为会丢失浏览器默认的新建tab不跳转的行为。因为服务端也有拦截，所以即使数据的实时性没那么强也无碍
            getPlanAndUserState: () => Awaited<ReturnType<typeof getPlanAndUserState>>
            getTeamResourceState: () => Awaited<Pick<TeamResourceStateVO, 'docCount'>>
            getUserId: () => UserBriefVO['id']
            getUserRole: () => {
                organizationVO: OrganizationVO
                teamWithAuthorityVO: TeamWithAuthorityVO | undefined
            }
        }
    }) => {
        const checkPassed = { pass: true }
        const checkFailed = { pass: false }

        const { orgId, teamId, inDraft, folderId, checkPlanIsUpdatingByFolderId, initUpgradePlan, sync } = params

        if (checkPlanIsUpdatingByFolderId && (await checkPlanIsUpdatingByFolderId(folderId))) {
            return checkFailed
        }

        const planAndUserState = sync ? sync.getPlanAndUserState?.() : await getPlanAndUserState(orgId ?? '', teamId)

        if (planAndUserState && (isLockedProPlan(planAndUserState) || isLockedOrgPlan(planAndUserState))) {
            return checkFailed
        } else if (planAndUserState && isStarterPlan(planAndUserState)) {
            // 免费团队检查文件数目是否超出限制
            const teamResourceState = sync ? sync.getTeamResourceState?.() : await this.getTeamResourceState(teamId!)
            if (teamResourceState.docCount <= 2) {
                return checkPassed
            }
            PayUpgradeCreateMoreFile({
                teamId,
                initUpgradePlan,
            })
            return checkFailed
        } else if (planAndUserState && (isActiveProPlan(planAndUserState) || isActiveOrgPlan(planAndUserState))) {
            // 付费团队下检查是否有设计席位
            if (inDraft) {
                return checkPassed
            }
            const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.designer)
            const hasDesignSeat = checkHasSeatByUserSeatStatus(userSeatStatus)
            if (hasDesignSeat) {
                return checkPassed
            }
            const syncUserId = sync?.getUserId?.()
            const [userVO, userRole] = await Promise.all([
                syncUserId ? { userId: syncUserId } : new GetUserRequest().start(),
                getUserRole(orgId!, teamId ?? '', sync?.getUserRole()),
            ])
            return PayApplySeatOfCreateDocPromise({
                userRole,
                userSeatStatus,
                resourceType: planAndUserState?.resourceType!,
                resourceId: planAndUserState?.resourceId!,
                unresolvedSeatTypes: planAndUserState?.unresolvedApplicationSeatTypes ?? [],
                folderId,
                userId: userVO.userId,
                isEnterprise: planAndUserState?.planType === PayPlanType.enterprise,
                orgId: orgId!,
                isSomeWorkspaceAdmin: !!planAndUserState?.adminWorkspaceIds?.length,
            })
                .then(() => {
                    return checkPassed
                })
                .catch(() => {
                    return checkFailed
                })
        } else {
            return inDraft ? checkPassed : checkFailed
        }
    }

    /**
     * @description 在导入前，检测是否满足导入文件的席位限制
     */
    public checkStartImportDoc = async (params: {
        orgId: string
        teamId: string | undefined
        folderId: string
        checkFailedCallback?: () => void
    }) => {
        const checkPassed = true
        const checkFailed = false

        const { orgId, teamId, folderId, checkFailedCallback } = params
        const planAndUserState = await getPlanAndUserState(orgId, teamId)

        if (planAndUserState && (isLockedProPlan(planAndUserState) || isLockedOrgPlan(planAndUserState))) {
            return checkFailed
        } else if (planAndUserState && (isActiveProPlan(planAndUserState) || isActiveOrgPlan(planAndUserState))) {
            // 付费团队下检查是否有设计席位
            const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.designer)
            const hasDesignSeat = checkHasSeatByUserSeatStatus(userSeatStatus)
            if (hasDesignSeat) {
                return checkPassed
            }
            checkFailedCallback?.()
            const [userVO, userRole] = await Promise.all([
                new GetUserRequest().start(),
                getUserRole(orgId!, teamId ?? ''),
            ])
            return PayApplySeatOfImportDocPromise({
                userRole,
                userSeatStatus,
                resourceType: planAndUserState?.resourceType!,
                resourceId: planAndUserState?.resourceId!,
                unresolvedSeatTypes: planAndUserState?.unresolvedApplicationSeatTypes ?? [],
                folderId,
                userId: userVO.userId,
                isEnterprise: planAndUserState?.planType === PayPlanType.enterprise,
                orgId: orgId,
                isSomeWorkspaceAdmin: !!planAndUserState?.adminWorkspaceIds?.length,
            })
                .then(() => {
                    return checkPassed
                })
                .catch(() => {
                    return checkFailed
                })
        } else {
            return checkPassed
        }
    }

    /**
     * @description 在导入过程中，检测导入的文件数目、页面数目限制。 仅检测其他团队（orgId === '-1'）& 免费团队 & 导入文件
     */
    public checkImportingDoc = async (params: {
        orgId: string | undefined
        teamId: string | undefined
        folderId: string
        planType: PayPlanType | undefined
        getImportDocPageNum: () => Promise<number>
    }) => {
        const checkPassed = { pass: true, code: CheckImportDocCode.None }
        const checkFailed = { pass: false, code: CheckImportDocCode.None }

        const { orgId, teamId, planType, getImportDocPageNum } = params
        const checkInOtherOrgTeamResult = orgId === '-1' && !!teamId
        if (!checkInOtherOrgTeamResult) {
            return checkPassed
        }
        const checkAlreadyPayResult =
            planType === PayPlanType.organization ||
            planType === PayPlanType.professional ||
            (featureSwitchManager.isEnabled('organization-plus') && planType === PayPlanType.enterprise)
        if (checkAlreadyPayResult) {
            return checkPassed
        }
        // 检查移出项目是否符合免费条件
        const teamResourceState = await this.getTeamResourceState(teamId)
        if (teamResourceState.docCount <= 2) {
            const importDocPageNum = await getImportDocPageNum().catch(() => NaN)
            if (importDocPageNum <= 3) {
                return checkPassed
            } else {
                checkFailed.code = CheckImportDocCode.PageLimit
                return checkFailed
            }
        } else {
            checkFailed.code = CheckImportDocCode.DocLimit
            return checkFailed
        }
    }

    /**
     * @description 只处理在其他团队（orgId === '-1'）下支付相关的项目移动
     */
    public checkMoveFolder = async (params: {
        orgId: string | undefined
        folderId: string
        moveOutTeamId: string | undefined
        moveInTeamId: string | undefined
        moveInPlanType: PayPlanType | undefined
        moveInSubmitProps: PriceComparisonProfessionalProps['professionalProps']['submitProps']
    }) => {
        const checkPassed = true
        const checkFailed = false

        const { orgId, folderId, moveInPlanType, moveOutTeamId, moveInTeamId, moveInSubmitProps } = params
        if (moveOutTeamId === moveInTeamId) {
            return checkPassed
        }
        const checkInOtherOrgTeamResult = orgId === '-1' && moveInTeamId && moveOutTeamId
        if (!checkInOtherOrgTeamResult) {
            return checkPassed
        }
        const checkAlreadyPayResult =
            moveInPlanType === PayPlanType.organization ||
            moveInPlanType === PayPlanType.professional ||
            (featureSwitchManager.isEnabled('organization-plus') && moveInPlanType === PayPlanType.enterprise)
        if (checkAlreadyPayResult) {
            return checkPassed
        }
        // 检查移入团队是否符合免费条件
        const moveInTeamInfo = await this.getTeamResourceUsage(moveInTeamId)
        if (!moveInTeamInfo) {
            return checkFailed
        }
        if (moveInTeamInfo.size >= 1) {
            WKFrogTask.payment.UpgradePopupType(UpgradePopupType.MoveFolder)
            PayUpgradeDialog.show({
                type: PayUpgradeDialogType.Professional,
                title: translation('wm'),
                subtitle: translation('so'),
                starterProps: {
                    privileges: [
                        { name: translation('p'), enable: true },
                        { name: translation('f'), enable: true },
                        { name: translation('pef'), enable: true },
                    ],
                },
                professionalProps: {
                    privileges: [
                        { name: translation('up'), enable: true },
                        { name: translation('uf'), enable: true },
                        { name: translation('upd'), enable: true },
                    ],
                    submitProps: moveInSubmitProps,
                },
            })
            return checkFailed
        }
        // 检查移出项目是否符合免费条件
        const folderResourceUsage = await this.getFolderResourceUsage(moveOutTeamId, folderId)
        if (!folderResourceUsage) {
            return checkFailed
        }
        const isDocNumOverflow = folderResourceUsage.size > 3
        if (isDocNumOverflow) {
            WKFrogTask.payment.UpgradePopupType(UpgradePopupType.MoveFolder)
            PayUpgradeDialog.show({
                type: PayUpgradeDialogType.Professional,
                title: translation('wm'),
                subtitle: translation('mtf'),
                starterProps: {
                    privileges: [
                        { name: translation('f'), enable: true },
                        { name: translation('p'), enable: true },
                        { name: translation('pef'), enable: true },
                    ],
                },
                professionalProps: {
                    privileges: [
                        { name: translation('uf'), enable: true },
                        { name: translation('up'), enable: true },
                        { name: translation('upd'), enable: true },
                    ],
                    submitProps: moveInSubmitProps,
                },
            })
            return checkFailed
        }
        const isPageNumOverflow = [...folderResourceUsage.values()].some((pageCount) => pageCount > 3)
        if (isPageNumOverflow) {
            WKFrogTask.payment.UpgradePopupType(UpgradePopupType.MoveFolder)
            PayUpgradeDialog.show({
                type: PayUpgradeDialogType.Professional,
                title: translation('wm'),
                subtitle: translation('mtp'),
                starterProps: {
                    privileges: [
                        { name: translation('pef'), enable: true },
                        { name: translation('p'), enable: true },
                        { name: translation('f'), enable: true },
                    ],
                },
                professionalProps: {
                    privileges: [
                        { name: translation('upd'), enable: true },
                        { name: translation('up'), enable: true },
                        { name: translation('uf'), enable: true },
                    ],
                    submitProps: moveInSubmitProps,
                },
            })
            return checkFailed
        }
        return checkPassed
    }

    /**
     * @description 检测移动文件是否需要升级计划 / 申请席位
     */
    public checkMoveDocs = async (params: {
        moveInDraftOrTrash: boolean
        orgId: string
        moveInTeamId: TeamID
        moveInFolderId: FolderID
        docs: DocWithAuthorityVO[]
        initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: TeamID) => Promise<void>
        upgradePlanSubmitCallback?: () => void | (() => Promise<void>)
        dialogPropsInUpgrade?: Omit<PayUpgradeDialogContainerProps, 'hide'>
        dialogPropsInSeat?: Omit<PayUpgradeDialogContainerProps, 'hide'>
    }) => {
        const checkPassed = true
        const checkFailed = false
        const {
            moveInDraftOrTrash,
            orgId,
            moveInTeamId,
            moveInFolderId,
            docs,
            upgradePlanSubmitCallback,
            initUpgradePlan,
            dialogPropsInUpgrade,
            dialogPropsInSeat,
        } = params

        const docsNotInTargetFolder = docs.filter((doc) => doc.folderId !== moveInFolderId)
        if (!docsNotInTargetFolder.length) {
            return checkPassed
        }

        const moveInTeamPlan = await getPlanAndUserState(orgId, moveInTeamId)
        if (moveInTeamPlan && isStarterPlan(moveInTeamPlan)) {
            // 1. 免费团队: 检查移入的团队是否有文件限制
            const upperDocNumLimitCheck = await checkUsageExceedInFreeTeam(
                new Map([[moveInTeamId, docsNotInTargetFolder.length]])
            )
            if (upperDocNumLimitCheck.exceed) {
                PayUpgradeMoveFileFileLimitSceneDiadlog(
                    getPayUpgradeDialogFrom(),
                    moveInTeamId,
                    initUpgradePlan,
                    upgradePlanSubmitCallback,
                    dialogPropsInUpgrade
                )
                return
            }

            // 2. 免费团队: 检查被移入文件是否有页面限制

            const docId2PageCount: DocPageCountVO = await new GetDocPageCount(
                docsNotInTargetFolder.map((d) => d.id)
            ).start()
            const upperPageNumLimit = Object.values(docId2PageCount).some((count) => count > 3)

            if (upperPageNumLimit) {
                PayUpgradeMoveFilePageLimitSceneDiadlog(
                    getPayUpgradeDialogFrom(),
                    moveInTeamId,
                    initUpgradePlan,
                    upgradePlanSubmitCallback,
                    dialogPropsInUpgrade
                )
                return checkFailed
            }
            return checkPassed
        } else if (moveInTeamPlan && (isActiveProPlan(moveInTeamPlan) || isActiveOrgPlan(moveInTeamPlan))) {
            if (moveInDraftOrTrash) {
                return checkPassed
            }
            // 1. 付费团队: 检查用户是否有移入团队的设计席位
            const userSeatStatus = getUserSeatStatus(moveInTeamPlan!, SeatType.designer)
            if (checkHasSeatByUserSeatStatus(userSeatStatus)) {
                return checkPassed
            }

            const [userVO, userRole] = await Promise.all([
                new GetUserRequest().start(),
                getUserRole(orgId, moveInTeamId),
            ])

            return await PayApplySeatOfMoveDocsPromise({
                userRole,
                userSeatStatus: userSeatStatus,
                docs,
                targetTeamId: moveInTeamId,
                targetFolderId: moveInFolderId,
                userId: userVO.userId,
                unresolvedSeatTypes: moveInTeamPlan?.unresolvedApplicationSeatTypes ?? [],
                isEnterprise: moveInTeamPlan?.planType === PayPlanType.enterprise,
                isSomeWorkspaceAdmin: !!moveInTeamPlan?.adminWorkspaceIds?.length,
                onCancel: () => {
                    dialogPropsInSeat?.onCancel?.()
                },
                onOk: () => {
                    dialogPropsInSeat?.onOk?.()
                },
            })
                .then(() => {
                    return checkPassed
                })
                .catch(() => {
                    return checkFailed
                })
        } else if (moveInTeamPlan && isLockedProPlan(moveInTeamPlan)) {
            PayPlanLockedDialog(
                'MoveFile',
                moveInTeamPlan?.trial ? 'trialExpiry' : 'invoiceOverdue',
                checkUserIsAdminInPayment(moveInTeamPlan!),
                PayPlanType.professional
            )
            return checkFailed
        } else if (moveInTeamPlan && isLockedOrgPlan(moveInTeamPlan)) {
            PayPlanLockedDialog(
                'MoveFile',
                moveInTeamPlan?.trial ? 'trialExpiry' : 'invoiceOverdue',
                checkUserIsAdminInPayment(moveInTeamPlan!),
                PayPlanType.organization
            )
            return checkFailed
        } else {
            return checkPassed
        }
    }

    /**
     * @description 检查恢复免费团队的文档是否超出文档限制/页面限制
     */

    public checkRecoverDoc = async (params: {
        orgId: OrgID
        docs: DocWithAuthorityVO[]
        initUpgradePlan: (plan: ContractSpecification, selectedTeamId?: TeamID) => Promise<void>
        getPlan: (teamId: string, orgId: OrgID) => PlanAndUserStateVO | undefined
    }) => {
        const checkPassed = true
        const checkFailed = false

        const { orgId, docs, initUpgradePlan, getPlan } = params

        if (orgId != '-1') {
            return checkPassed
        }

        const freeTeam2DocsMap = new Map()
        const filterDocIds = []
        for (const doc of docs) {
            if (doc.teamId) {
                // 忽视草稿
                if (freeTeam2DocsMap.get(doc.teamId)) {
                    freeTeam2DocsMap.set(doc.teamId, freeTeam2DocsMap.get(doc.teamId) + 1)
                    filterDocIds.push(doc.id)
                } else {
                    const teamPlan = getPlan(doc.teamId, doc.orgId)
                    const isFreeTeam = teamPlan && isStarterPlan(teamPlan)
                    if (isFreeTeam) {
                        freeTeam2DocsMap.set(doc.teamId, 1)
                        filterDocIds.push(doc.id)
                    }
                }
            }
        }

        if (freeTeam2DocsMap.size === 0) {
            return checkPassed
        }

        // 检查是否有超出文档数量的限制
        const { exceed, teamId: exceedTeamId } = await checkUsageExceedInFreeTeam(freeTeam2DocsMap)
        if (exceed) {
            PayUpgradeRecoverFileFileLimitSceneDiadlog(PayUpgradeDialogFrom.Space, exceedTeamId, initUpgradePlan)
            return checkFailed
        }

        // 检查是否有超出页面数量的限制

        const docId2PageCount = await new GetDocPageCount(filterDocIds).start()
        const upperPageLimitDocId = Object.entries(docId2PageCount).find(([, count]) => count > 3)?.[0]

        if (!upperPageLimitDocId) {
            return checkPassed
        }

        const teamId = docs.find((doc) => doc.id === upperPageLimitDocId)?.teamId
        if (teamId) {
            PayUpgradeRecoverFilePageLimitSceneDiadlog(getPayUpgradeDialogFrom(), teamId, initUpgradePlan)
            return checkFailed
        }

        return checkPassed
    }

    /**
     *
     * @description 检查免费团队创建文档副本是否超出文档数量/页面数量限制
     */

    public checkDuplicateDocLimit = async (params: {
        orgId: string
        docs: DocWithAuthorityVO[]
        initUpgradePlan?: (plan: ContractSpecification, selectedTeamId?: TeamID) => Promise<void>
        getPlan: (teamId: string, orgId: OrgID) => PlanAndUserStateVO | undefined
    }) => {
        const checkPassed = true
        const checkFailed = false

        const { orgId, docs, initUpgradePlan, getPlan } = params

        if (orgId != '-1') {
            return checkPassed
        }

        const freeTeam2DocsMap = new Map()
        const folderIds: Set<string> = new Set()
        const filterDocIds = []
        for (const doc of docs) {
            if (doc.teamId) {
                // 忽视草稿
                if (freeTeam2DocsMap.get(doc.teamId)) {
                    freeTeam2DocsMap.set(doc.teamId, freeTeam2DocsMap.get(doc.teamId) + 1)
                    filterDocIds.push(doc.id)
                } else {
                    const teamPlan = getPlan(doc.teamId, orgId)
                    const isFreeTeam = teamPlan && isStarterPlan(teamPlan)

                    if (isFreeTeam) {
                        freeTeam2DocsMap.set(doc.teamId, 1)
                        filterDocIds.push(doc.id)
                    }
                }
            }
            folderIds.add(doc.folderId)
        }

        if (freeTeam2DocsMap.size === 0) {
            return checkPassed
        }
        // 1. 检查用户是否有项目的编辑权限; 全有则跳转 2; 否则直接复制到草稿,返回 checkPassed

        const folderVOs = await Promise.all(
            Array.from(folderIds).map((folderId) => new GetFolderRequest(folderId).start())
        )
        const checkSomeNoEditPermission = folderVOs.some(
            (folderVO) => compareRole(folderVO.roleEnum, RoleStatus.Editor) < 0
        )
        if (checkSomeNoEditPermission) {
            return checkPassed
        }

        // 2. 检查文档数目超出限制
        const { exceed, teamId: exceedTeamId } = await checkUsageExceedInFreeTeam(freeTeam2DocsMap)
        if (exceed) {
            PayUpgradeCopyFileFileLimitSceneDialog(getPayUpgradeDialogFrom(), exceedTeamId, initUpgradePlan)
            return checkFailed
        }

        // 3. 检查页面数目超出限制
        const docId2PageCount = await new GetDocPageCount(filterDocIds).start()
        const upperPageLimitDocId = Object.entries(docId2PageCount).find(([, count]) => count > 3)?.[0]

        if (!upperPageLimitDocId) {
            return checkPassed
        }

        const teamId = docs.find((doc) => doc.id === upperPageLimitDocId)?.teamId
        if (teamId) {
            PayUpgradeCopyFilePageLimitSceneDialog(getPayUpgradeDialogFrom(), teamId, initUpgradePlan)
            return checkFailed
        }

        return checkPassed
    }

    /**
     *
     * 检查是否有权限分享编辑权限
     */
    public checkShareDoc = async (params: {
        doc: DocWithAuthorityVO
        invitedUsers: UserBriefAndErrorVO[]
        userId: number
        draftFolderId: FolderID | undefined
        actions?: {
            setMoveModal: (v: boolean) => void
            setShareModal: (v: boolean) => void
        }
        refreshMembersList?: () => void
    }) => {
        const checkPassed = true
        const checkFailed = false
        const { doc, invitedUsers, userId, actions, refreshMembersList, draftFolderId } = params

        // 此处调用接口再次获取最新的校验状态，防止分享弹窗开启过程中过长时间，导致状态变更
        const {
            doc: lastDocInfo,
            planInfo,
            email2InviteeInfo,
        } = await new GetBatchInviteValidateInfo(
            ResourceType.Document,
            doc.id,
            invitedUsers.map((u) => u.email)
        ).start()

        if (isNil(lastDocInfo)) {
            return checkFailed
        }

        if (email2InviteeInfo) {
            const email2InviteeInfoKeys = Object.keys(email2InviteeInfo)
            invitedUsers.forEach((u) => {
                if (u.unregistered && !email2InviteeInfoKeys.includes(u.email)) {
                    email2InviteeInfo[u.email] = {
                        nickname: u.email,
                        id: randomNumber(),
                        waitRegister: true,
                        avatarBackgroundColor: '#f9f9fa',
                        email: u.email,
                        desensitizedPhone: '',
                        avatarId: '',
                    }
                }
            })
        }

        return PayApplySeatOfShareDocPromise({
            email2InviteeInfo,
            handles: {
                refreshMembersList,
                setShareModal: actions?.setShareModal,
                setMoveModal: actions?.setMoveModal,
            },
            planInfo,
            docInfo: lastDocInfo,
            userId,
            scene: 'shareDocEditAuth',
            draftFolderId,
        })
            .then(() => {
                return checkPassed
            })
            .catch(() => {
                return checkFailed
            })
    }

    /**
     *
     * 检查是否点击分享按钮
     */
    public checkShareDocBtn = async ({
        docInfo,
        userId,
        draftFolderId,
    }: {
        docInfo: DocWithAuthorityVO
        userId: UserID
        draftFolderId: FolderID | undefined
    }) => {
        let planInfo: PlanAndUserStateVO | undefined
        if (docInfo.orgId == '-1') {
            const teamsPlan = await new QueryTeamAndUserState().start()
            const teamPlan = teamsPlan.find(
                (p) => p.resourceId == docInfo.teamId && p.resourceType == ResourceType.Team
            )
            if (teamPlan) {
                planInfo = teamPlan
            }
        } else {
            planInfo = await new QueryOrgAndUserState(docInfo.orgId).start()
        }
        const checkPassed = true
        const checkFailed = false

        if (planInfo && (isLockedProPlan(planInfo) || isLockedOrgPlan(planInfo))) {
            PayPlanLockedDialog(
                'ShareFile',
                planInfo?.trial ? 'trialExpiry' : 'invoiceOverdue',
                checkUserIsAdminInPayment(planInfo!),
                isLockedProPlan(planInfo) ? PayPlanType.professional : PayPlanType.organization
            )
            return checkFailed
        } else if (planInfo && isActiveOrgPlan(planInfo)) {
            if (docInfo.draft) {
                return PayApplySeatOfShareDocPromise({
                    planInfo,
                    docInfo,
                    userId,
                    email2InviteeInfo: undefined,
                    scene: 'shareDoc',
                    draftFolderId,
                })
                    .then(() => {
                        return checkPassed
                    })
                    .catch(() => {
                        return checkFailed
                    })
            }
            return checkPassed
        } else {
            return checkPassed
        }
    }

    /**
     * 检查是否显示创建文件/导入文件的入口
     */

    public checkCreatOrImportDocAccessible = (params: {
        planAndUserState: PlanAndUserStateVO | undefined
        inDraft: boolean
    }) => {
        const checkPassed = true
        const checkFailed = false
        const { planAndUserState } = params

        if (planAndUserState && (isActiveProPlan(planAndUserState) || isActiveOrgPlan(planAndUserState))) {
            if (params.inDraft) {
                return checkPassed
            }
            // 付费企业/组织中(仅考虑团队)没有席位且不能自动升级,则隐藏入口
            const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.designer)
            const userRoleIsAdmin = checkUserIsAdminInPayment(planAndUserState!)
            if (
                !userRoleIsAdmin &&
                (userSeatStatus == UserSeatStatus.NeedReApply || userSeatStatus == UserSeatStatus.NeedWaitToBeApproved)
            ) {
                return checkFailed
            }
            return checkPassed
        } else if (planAndUserState && (isLockedProPlan(planAndUserState) || isLockedOrgPlan(planAndUserState))) {
            return checkFailed
        } else {
            return checkPassed
        }
    }
}

export const PayPreCheck = new PayPreCheckClass()
