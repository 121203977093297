import { useState } from 'react'
import { fileDialog, MonoIconCommonUpload16, MonoIconToolbarPencil24 } from '../../../../../../../ui-lib/src'
import { toast } from '../../../../../../../ui-lib/src/components/wk-toast/toast'
import { transImageFileToBase64 } from '../use-plugin-development'
import styles from './icon-image-uploader.module.less'
import { translation } from './icon-image-uploader.translation'

export interface IconImageUploaderProps {
    iconSrc?: string
    onChange?: (file: { imageData: string; format: string }) => void
    error?: {
        show?: boolean
        tipMessage?: string
    }
    dataTestIds?: {
        clickButton?: string
    }
}

export function IconImageUploader({ iconSrc, onChange, error, dataTestIds }: IconImageUploaderProps) {
    const selectIcon = async () => {
        const iconFile = await fileDialog({
            accept: '.png,.jpg,.jpeg',
            strict: true,
        })

        const img = new Image()
        img.src = URL.createObjectURL(iconFile)

        try {
            await img.decode()
            if (img.width === 128 && img.height === 128) {
                const { base64Data, format } = await transImageFileToBase64(iconFile)
                onChange?.({
                    imageData: base64Data,
                    format,
                })
            } else {
                toast.error(translation('ImageSizeNotMatch'))
            }
        } catch (e) {
            toast.error(translation('ImageSizeNotMatch'))
        } finally {
            URL.revokeObjectURL(img.src)
        }
    }

    return (
        <>
            <div className="flex flex-row justify-start">
                {iconSrc ? (
                    <UploadButtonWithImage
                        onClick={selectIcon}
                        iconSrc={iconSrc}
                        dataTestId={dataTestIds?.clickButton}
                    />
                ) : (
                    <>
                        <UploadButtonWithoutImage onClick={selectIcon} dataTestId={dataTestIds?.clickButton} />
                        <div className="ml-2 flex-1 h-auto flex justify-start items-center wk-text-12 wk-font-regular color-$wk-v2-label-color-gray-6">
                            {translation('ImageSizeTip')}
                        </div>
                    </>
                )}
            </div>
            {error?.show && <div className="mt-1 wk-text-12 wk-font-regular color-$wk-red-8">{error.tipMessage}</div>}
        </>
    )
}

const UploadButtonWithImage = ({
    iconSrc,
    onClick,
    dataTestId,
}: {
    iconSrc?: string
    onClick: () => void
    dataTestId?: string
}) => {
    const [isHover, setIsHover] = useState(false)

    return (
        <div
            className={styles.imageUploadButtonWithImage}
            onClick={onClick}
            onMouseEnter={() => {
                setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            data-testid={dataTestId}
        >
            {iconSrc && <img src={iconSrc} className={styles.uploadedImage} />}
            {isHover && (
                <div className={styles.hoverWithImage}>
                    <MonoIconToolbarPencil24 className="color-$wk-white" />
                </div>
            )}
        </div>
    )
}

const UploadButtonWithoutImage = ({ onClick, dataTestId }: { onClick: () => void; dataTestId?: string }) => {
    const [isHover, setIsHover] = useState(false)

    return (
        <div
            className={styles.imageUploadButtonWithoutImage}
            onClick={onClick}
            onMouseEnter={() => {
                setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            data-testid={dataTestId}
        >
            <MonoIconCommonUpload16 />
            {isHover && <div className={styles.hoverWithoutImage} />}
        </div>
    )
}
