/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import * as _ from 'lodash-es'
import { KeyboardEventHandler, useCallback, useMemo } from 'react'
import {
    IconMargin,
    IconMarginBottom,
    IconMarginHorizontal,
    IconMarginLeft,
    IconMarginRight,
    IconMarginTop,
    IconMarginVertical,
} from '../../../../../../../../../ui-lib/src'
import { featureSwitchManager } from '../../../../../../../kernel/switch'
import { useViewState } from '../../../../../../../view-state-bridge'
import { ScrubbableInput, ScrubbableInputProps } from '../../../../../atom/inputs/components/scrubbable-input'
import { formateToFixed2 } from '../../../../../atom/inputs/utils/format'
import { parseString } from '../../../../../atom/inputs/utils/parse-string'
import { valueFilterUserConfig } from '../../../../../atom/inputs/utils/value-filter'
import { getFieldConsumptionFloatVariable } from '../../../../../design/common/use-field-variable-consumption'
import { useFloatVariablePanel } from '../../../../../design/primitive-variable/use-float-variable-panel'
import { CommandType, useAutoLayoutCommand } from '../command'
import { AutoLayoutHoverItem, AutoLayoutState, PaddingKey, PaddingPair } from '../types'
import { mapForMap } from '../utils'

/**
 * 将数组修剪或者补齐到指定长度
 * 当处理四边距输入时，有如下好处：
 * 1. 输入单个值 a，循环填充后 lrtb 为 [a, a, a, a]，四边距变为统一值
 * 2. 输入两个值 a,b，循环填充后 lrtb 为 [a, b, a, b]，对称边距设置为统一值
 * 3. 输入三个值 a,b,c，循环填充为 [a, b, c, a]
 * 4. 输入四个值，则无需填充。
 * @param arr 如果为空，则返回 []
 * @param n
 * @returns
 */
export function padOrTrimEnd<T>(arr: T[], n: number) {
    if (arr.length >= n) {
        return arr.slice(0, n)
    }

    if (arr.length === 0) return arr

    // 使用原数组循环填充
    const retArr = [...arr]
    for (let i = 0; retArr.length < n; i = (i + 1) % arr.length) {
        retArr.push(arr[i])
    }

    return retArr
}

export function formatPadding(values: Array<number>) {
    const uniqs = _.uniq(values)

    // 全部相等
    const isAllEqual = uniqs.length === 1
    // 对称相等
    const isSymmetric = values.length === 4 && values[0] === values[2] && values[1] === values[3]

    if (isAllEqual || isSymmetric) {
        values = uniqs
    }

    return values.map((v) => formateToFixed2(v))
}
export function useDualPaddingInputProps(
    state: AutoLayoutState,
    type: PaddingInputType,
    keys: PaddingKey[],
    isFloat?: boolean
): ScrubbableInputProps {
    const command = useAutoLayoutCommand(state)
    const setPadding: CommandType['setMultiPadding'] = useCallback(
        (payload, __, options) => {
            command.setMultiPadding(payload, !isFloat, options)
        },
        [command, isFloat]
    )

    const paddings = new Map(keys.map((key) => [key, state.flex.padding[key]]))
    const isMixed = Array.from(paddings.values()).some((v) => v.isMixed)
    const keysCount = keys.length

    const commonValue = mapForMap(paddings, (k, v) => [k, v.value[0]]) // 非 mixed 值情况下的共有值
    const ids = state.autoLayoutParentIds
    const count = ids.length

    /**
     * 有多种可能的情况：
     * 1. 所有选中的节点的左右间距都相等，且这些节点的左右间距都相等
     * 2. 所有选中的节点的左右间距都相等，但是这些节点的左右间距不相等
     * 3. 部分节点的左右间距不相等
     * 4. 所有节点的左右间距都不相等
     */
    const mixedSpaces = mapForMap(paddings, (k, v) => {
        if (v.isMixed) {
            return [k, v.value]
        }

        return [k, new Array(count).fill(0).map(() => commonValue.get(k)!)]
    })

    return {
        value: Array.from(commonValue.values()),
        isMixed,
        onMouseOver() {
            // 画布区浮动输入框 hover 不作处理
            if (isFloat) {
                return
            }

            switch (type) {
                case 'top': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_TOP_PADDING)
                }
                case 'right': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_RIGHT_PADDING)
                }
                case 'bottom': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_BOTTOM_PADDING)
                }
                case 'left': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_LEFT_PADDING)
                }
                case 'vertical': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_PADDING)
                }
                case 'horizontal': {
                    return command.hoverAutoLayoutMenu(
                        AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_PADDING
                    )
                }
                case 'all': {
                    return
                }
            }
        },
        onMouseOut() {
            // 画布区浮动输入框 unhover 不作处理
            if (isFloat) {
                return
            }

            command.unhoverAutoLayoutMenu()
        },
        onChange(value, options) {
            // 用户直接输入的值，可能为 1 个或者 2 个数字
            const changedPaddings = keys.map((v, i) => [v, Array.isArray(value) ? value[i] : value] as PaddingPair)
            setPadding(changedPaddings, false, options)
        },
        mixedMathHandler: {
            onChangeMixed(parse, options) {
                // 处理用户输入 mixed 时的逻辑
                // 如果输入框本身为 mixed，则所有取值操作都是在这里发生
                // 计算维度为：nodeId => paddingKey => mixedValues
                const groupByPaddingKey: Map<PaddingKey, number[]> = new Map(keys.map((key) => [key, []]))
                ids.forEach((id, i) => {
                    const perKeyPaddings = keys.map((key) => mixedSpaces.get(key)![i])
                    const parsedPaddings = parse(perKeyPaddings) as number[]

                    parsedPaddings.forEach((padding, paddingIndex) => {
                        groupByPaddingKey.get(keys[paddingIndex])?.push(padding)
                    })
                })
                // const changedMixedValues = mapForMap(mixedSpaces, (k, v) => [k, parse(v)] as PaddingPair)
                const changedMixedValues = Array.from(groupByPaddingKey.entries())
                setPadding(changedMixedValues, false, options)
            },
            getScrubStartValueMixed() {
                // 获取 mixed 初始值
                // 结构： {key: [lefts, rights]}
                return mixedSpaces
            },
            onScrubChangeAllMixed(map) {
                // 处理拖动情况下的 mixed 变更，按 paddingKey 分组处理
                setPadding(Array.from(map.entries()) as PaddingPair[])
            },
        },
        formatter: {
            parse(input, oldValue) {
                let inputs = input
                    .split(',')
                    .map((s) => s.trim().toLocaleLowerCase())
                    .filter((v) => v.length > 0)

                if (inputs.length === 0) {
                    throw Error() // 抛出错误，代表应该走 mixed 逻辑，具体问 @loushuangzhan
                }

                // 确保输入的长度与 keys 长度一致
                inputs = padOrTrimEnd(inputs, keysCount)
                const oldValues = padOrTrimEnd(Array.isArray(oldValue) ? oldValue : [oldValue], keysCount)

                return inputs.map((v, i) => parseString(v, oldValues[i]))
            },
            format(value) {
                const values = Array.isArray(value) ? value : [value]
                return formatPadding(values).join(',')
            },
        },
        resolution: 1,
        valueFilter: valueFilterUserConfig,
    }
}

export type PaddingInputType = 'top' | 'right' | 'bottom' | 'left' | 'vertical' | 'horizontal' | 'all'

export const testIds = {
    input: 'autolayout-padding-input',
}

const paddingInputConfigs: Record<
    PaddingInputType,
    {
        keys: PaddingKey[]
        icon: JSX.Element
    }
> = {
    top: {
        keys: ['vertical'],
        icon: <IconMarginTop />,
    },
    right: {
        keys: ['right'],
        icon: <IconMarginRight />,
    },
    bottom: {
        keys: ['bottom'],
        icon: <IconMarginBottom />,
    },
    left: {
        keys: ['horizontal'],
        icon: <IconMarginLeft />,
    },
    vertical: {
        keys: ['vertical', 'bottom'],
        icon: <IconMarginVertical />,
    },
    horizontal: {
        keys: ['horizontal', 'right'],
        icon: <IconMarginHorizontal />,
    },
    all: {
        keys: ['vertical', 'right', 'bottom', 'horizontal'],
        icon: <IconMargin />,
    },
}

function PaddingInputV1(props: {
    state: AutoLayoutState
    type: PaddingInputType
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    const config = paddingInputConfigs[props.type]
    const { onMouseOver, onMouseOut, ...inputProps } = useDualPaddingInputProps(
        props.state,
        props.type,
        config.keys,
        props.isFloat
    )

    return (
        <div className="w-full h-full" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <ScrubbableInput
                icon={config.icon}
                onKeyDown={props.onKeyDown}
                {...inputProps}
                min={0}
                autoFocus={props.isFloat}
                testId={testIds.input}
            />
        </div>
    )
}

function useDualPaddingInputPropsV2(
    state: AutoLayoutState,
    type: PaddingInputType,
    keys: PaddingKey[],
    isFloat?: boolean
): ScrubbableInputProps & {
    paddingVarPanel: ReturnType<typeof useFloatVariablePanel>
} {
    const command = useAutoLayoutCommand(state)
    const setPadding: CommandType['setMultiPadding'] = useCallback(
        (payload, __, options) => {
            command.setMultiPadding(payload, !isFloat, options)
        },
        [command, isFloat]
    )

    const paddings = new Map(keys.map((key) => [key, state.flex.padding[key]]))
    const isMixed = Array.from(paddings.values()).some((v) => v.isMixed)
    const keysCount = keys.length

    const commonValue = mapForMap(paddings, (k, v) => [k, v.value[0]]) // 非 mixed 值情况下的共有值
    const ids = state.autoLayoutParentIds
    const count = ids.length

    /**
     * 有多种可能的情况：
     * 1. 所有选中的节点的左右间距都相等，且这些节点的左右间距都相等
     * 2. 所有选中的节点的左右间距都相等，但是这些节点的左右间距不相等
     * 3. 部分节点的左右间距不相等
     * 4. 所有节点的左右间距都不相等
     */
    const mixedSpaces = mapForMap(paddings, (k, v) => {
        if (v.isMixed) {
            return [k, v.value]
        }

        return [k, new Array(count).fill(0).map(() => commonValue.get(k)!)]
    })

    const variableConsumptionMapState = useViewState('variableConsumptionMapState')
    const selectedVariable = useMemo(() => {
        if (isMixed) {
            return null
        }

        switch (type) {
            case 'top':
                return getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_TOP,
                    variableConsumptionMapState
                )
            case 'right':
                return getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT,
                    variableConsumptionMapState
                )
            case 'bottom':
                return getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM,
                    variableConsumptionMapState
                )
            case 'left':
                return getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT,
                    variableConsumptionMapState
                )
            case 'vertical': {
                const top = getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_TOP,
                    variableConsumptionMapState
                )
                const bottom = getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM,
                    variableConsumptionMapState
                )
                // 绑定同一个变量才展示
                return top?.id === bottom?.id ? top : null
            }

            case 'horizontal': {
                const left = getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT,
                    variableConsumptionMapState
                )
                const right = getFieldConsumptionFloatVariable(
                    Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT,
                    variableConsumptionMapState
                )
                // 绑定同一个变量才展示
                return left?.id === right?.id ? left : null
            }

            case 'all':
                return null
        }
    }, [type, variableConsumptionMapState, isMixed])

    const variableValue = isMixed ? 0 : Array.from(commonValue.values())[0] ?? 0
    const paddingVarPanel = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.VARIABLE_SCOPE_G_A_P,
        ],
        selectedVariable: isMixed ? null : selectedVariable,
        selectedVariableFallbackFloatValue: variableValue,
        createEnable: true,
        defaultCreateValue: variableValue,
        onVariableSelected: (id) => {
            switch (type) {
                case 'top': {
                    command.setPaddingVar(id, [Wukong.DocumentProto.VariableField.STACK_PADDING_TOP])
                    break
                }
                case 'right': {
                    command.setPaddingVar(id, [Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT])
                    break
                }
                case 'bottom': {
                    command.setPaddingVar(id, [Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM])
                    break
                }
                case 'left': {
                    command.setPaddingVar(id, [Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT])
                    break
                }
                case 'vertical': {
                    command.setPaddingVar(id, [
                        Wukong.DocumentProto.VariableField.STACK_PADDING_TOP,
                        Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM,
                    ])
                    break
                }
                case 'horizontal': {
                    command.setPaddingVar(id, [
                        Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT,
                        Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT,
                    ])
                    break
                }
                case 'all':
                    // float input不支持变量
                    break
            }
        },
        onVariableDetach: () => {
            switch (type) {
                case 'top': {
                    command.detechPaddingVar([Wukong.DocumentProto.VariableField.STACK_PADDING_TOP])
                    break
                }
                case 'right': {
                    command.detechPaddingVar([Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT])
                    break
                }
                case 'bottom': {
                    command.detechPaddingVar([Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM])
                    break
                }
                case 'left': {
                    command.detechPaddingVar([Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT])
                    break
                }
                case 'vertical': {
                    command.detechPaddingVar([
                        Wukong.DocumentProto.VariableField.STACK_PADDING_TOP,
                        Wukong.DocumentProto.VariableField.STACK_PADDING_BOTTOM,
                    ])
                    break
                }
                case 'horizontal': {
                    command.detechPaddingVar([
                        Wukong.DocumentProto.VariableField.STACK_PADDING_LEFT,
                        Wukong.DocumentProto.VariableField.STACK_PADDING_RIGHT,
                    ])
                    break
                }
                case 'all':
                    // float input不支持变量
                    break
            }
        },
        onClose: () => {
            // NOTE: 关闭变量列表弹窗时，可能不会触发onMouseOut因此需要强制取消 hover 状态
            command.unhoverAutoLayoutMenu()
        },
    })

    return {
        value: Array.from(commonValue.values()),
        isMixed,
        paddingVarPanel,
        onMouseOver() {
            // 画布区浮动输入框 hover 不作处理
            if (isFloat) {
                return
            }

            switch (type) {
                case 'top': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_TOP_PADDING)
                }
                case 'right': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_RIGHT_PADDING)
                }
                case 'bottom': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_BOTTOM_PADDING)
                }
                case 'left': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_LEFT_PADDING)
                }
                case 'vertical': {
                    return command.hoverAutoLayoutMenu(AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_VERTICAL_PADDING)
                }
                case 'horizontal': {
                    return command.hoverAutoLayoutMenu(
                        AutoLayoutHoverItem.AUTO_LAYOUT_HOVER_MENU_ITEM_HORIZONTAL_PADDING
                    )
                }
                case 'all': {
                    return
                }
            }
        },
        onMouseOut() {
            // 画布区浮动输入框 unhover 不作处理
            if (isFloat) {
                return
            }

            command.unhoverAutoLayoutMenu()
        },
        onChange(value, options) {
            // 用户直接输入的值，可能为 1 个或者 2 个数字
            const changedPaddings = keys.map((v, i) => [v, Array.isArray(value) ? value[i] : value] as PaddingPair)
            setPadding(changedPaddings, false, options)
        },
        mixedMathHandler: {
            onChangeMixed(parse, options) {
                // 处理用户输入 mixed 时的逻辑
                // 如果输入框本身为 mixed，则所有取值操作都是在这里发生
                // 计算维度为：nodeId => paddingKey => mixedValues
                const groupByPaddingKey: Map<PaddingKey, number[]> = new Map(keys.map((key) => [key, []]))
                ids.forEach((id, i) => {
                    const perKeyPaddings = keys.map((key) => mixedSpaces.get(key)![i])
                    const parsedPaddings = parse(perKeyPaddings) as number[]

                    parsedPaddings.forEach((padding, paddingIndex) => {
                        groupByPaddingKey.get(keys[paddingIndex])?.push(padding)
                    })
                })
                // const changedMixedValues = mapForMap(mixedSpaces, (k, v) => [k, parse(v)] as PaddingPair)
                const changedMixedValues = Array.from(groupByPaddingKey.entries())
                setPadding(changedMixedValues, false, options)
            },
            getScrubStartValueMixed() {
                // 获取 mixed 初始值
                // 结构： {key: [lefts, rights]}
                return mixedSpaces
            },
            onScrubChangeAllMixed(map) {
                // 处理拖动情况下的 mixed 变更，按 paddingKey 分组处理
                setPadding(Array.from(map.entries()) as PaddingPair[])
            },
        },
        formatter: {
            parse(input, oldValue) {
                let inputs = input
                    .split(',')
                    .map((s) => s.trim().toLocaleLowerCase())
                    .filter((v) => v.length > 0)

                if (inputs.length === 0) {
                    throw Error() // 抛出错误，代表应该走 mixed 逻辑，具体问 @loushuangzhan
                }

                // 确保输入的长度与 keys 长度一致
                inputs = padOrTrimEnd(inputs, keysCount)
                const oldValues = padOrTrimEnd(Array.isArray(oldValue) ? oldValue : [oldValue], keysCount)

                return inputs.map((v, i) => parseString(v, oldValues[i]))
            },
            format(value) {
                const values = Array.isArray(value) ? value : [value]
                return formatPadding(values).join(',')
            },
        },
        resolution: 1,
        valueFilter: valueFilterUserConfig,
    }
}

function PaddingInputV2(props: {
    state: AutoLayoutState
    type: PaddingInputType
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    const config = paddingInputConfigs[props.type]
    const { onMouseOver, onMouseOut, paddingVarPanel, ...inputProps } = useDualPaddingInputPropsV2(
        props.state,
        props.type,
        config.keys,
        props.isFloat
    )

    return (
        <div
            className="w-full h-full"
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            data-testid={testIds.input + '-' + props.type}
        >
            <ScrubbableInput
                icon={config.icon}
                onKeyDown={props.onKeyDown}
                {...inputProps}
                min={0}
                autoFocus={props.isFloat}
                testId={testIds.input}
                useVariable={props.isFloat ? undefined : paddingVarPanel.useVariable}
            />
            {props.isFloat ? null : paddingVarPanel.renderPanel()}
        </div>
    )
}

export function PaddingInput(props: {
    state: AutoLayoutState
    type: PaddingInputType
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    isFloat?: boolean
}) {
    if (featureSwitchManager.isEnabled('float-variable')) {
        return <PaddingInputV2 {...props} />
    } else {
        return <PaddingInputV1 {...props} />
    }
}
