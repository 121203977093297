import {
    BatchUpdateVariablePublishHiddenCommand,
    BatchUpdateVariableScopesCommand,
    GenerateNewVariableNameForCollectionAndTypeCommand,
    LocalVariableEditor_AbortRenameVariableCommand,
    LocalVariableEditor_AddSelectedVariablesIntoGroupCommand,
    LocalVariableEditor_ClearGroupSelectionCommand,
    LocalVariableEditor_ClearVariableSelectionCommand,
    LocalVariableEditor_CloseVariableEditingPopupCommand,
    LocalVariableEditor_CollapseGroupCommand,
    LocalVariableEditor_CreateCollectionCommand,
    LocalVariableEditor_CreateCollectionModeCommand,
    LocalVariableEditor_CreateVariableCommand,
    LocalVariableEditor_DeleteCollectionCommand,
    LocalVariableEditor_DeleteCollectionModeCommand,
    LocalVariableEditor_DeleteSelectedGroupsCommand,
    LocalVariableEditor_DeleteSelectedVariablesCommand,
    LocalVariableEditor_DirectRenameVariableCommand,
    LocalVariableEditor_DragGroupCommand,
    LocalVariableEditor_DragSelectedVariablesCommand,
    LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
    LocalVariableEditor_DuplicateCollectionModeCommand,
    LocalVariableEditor_DuplicateSelectedGroupsCommand,
    LocalVariableEditor_DuplicateSelectedVariablesCommand,
    LocalVariableEditor_ExpandGroupCommand,
    LocalVariableEditor_ForceSelectGroupCommand,
    LocalVariableEditor_ForceSelectVariableCommand,
    LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand,
    LocalVariableEditor_GetLatestSelectedCollectionCommand,
    LocalVariableEditor_GetSelectionScrollIndexInTableCommand,
    LocalVariableEditor_MultiSelectGroupCommand,
    LocalVariableEditor_MultiSelectVariableCommand,
    LocalVariableEditor_RangeSelectGroupCommand,
    LocalVariableEditor_RangeSelectVariableCommand,
    LocalVariableEditor_RenameCollectionModeCommand,
    LocalVariableEditor_RenameGroupCommand,
    LocalVariableEditor_SafeSelectGroupCommand,
    LocalVariableEditor_SafeSelectVariableCommand,
    LocalVariableEditor_SelectCollectionCommand,
    LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand,
    LocalVariableEditor_SetLatestSelectedCollectionCommand,
    LocalVariableEditor_StartRenameCollectionCommand,
    LocalVariableEditor_StartRenameVariableCommand,
    LocalVariableEditor_SubmitRenameCollectionCommand,
    LocalVariableEditor_SubmitRenameVariableCommand,
    LocalVariableEditor_TogglePanelCommand,
    LocalVariableEditor_ToggleVariableDetailPopupCommand,
    LocalVariableEditor_ToggleVariableValuePopupCommand,
    LocalVariableEditor_UngroupSelectedGroupsCommand,
    RemoveVariableCodeSyntaxCommand,
    SetAliasForVariableCommand,
    UpdateColorVariableValueCommand,
    UpdateFloatVariableValueCommand,
    UpdateVariableCodeSyntaxCommand,
    UpdateVariableDescriptionCommand,
    Wukong,
} from '@wukong/bridge-proto'
// eslint-disable-next-line import/no-deprecated
import { WKToast } from '../../../../ui-lib/src'
import { TraceableAbortSignal } from '../../../../util/src/abort-controller/traceable-abort-controller'
import { transaction } from '../../../../util/src/abort-controller/traceable-transaction'
import { CommandInvoker } from '../../document/command/command-invoker'
import { CommitType } from '../../document/command/commit-type'
import { Bridge } from '../../kernel/bridge/bridge'
import { featureSwitchManager } from '../../kernel/switch/core'
import { isWindows } from '../../kernel/util/ua'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { WK } from '../../window'
import { translation } from './local-variable-editor-service.translation'

export const ALL_GROUP_LABEL = ''

export class LocalVariableEditorService {
    private _scrollToIndexCallback: ((index: number) => void) | null = null
    registerScrollToIndexCallback = (callback: (index: number) => void) => {
        this._scrollToIndexCallback = callback
        return () => {
            this._scrollToIndexCallback = null
        }
    }

    private _currentSelectionScrollIntoView = () => {
        if (!this._scrollToIndexCallback) {
            return
        }
        const index = this.commandInvoker.invokeBridge(
            CommitType.Noop,
            LocalVariableEditor_GetSelectionScrollIndexInTableCommand
        ).value
        if (index == null || index < 0) {
            return
        }
        this._scrollToIndexCallback(index)
    }

    constructor(signal: TraceableAbortSignal, bridge: Bridge, private readonly commandInvoker: CommandInvoker) {
        const { act } = transaction(signal)

        act('[LocalVariableEditorService] register wukong object', () => {
            WK.variableLocalEditor = this
            return () => {
                delete WK.variableLocalEditor
            }
        })

        act('[LocalVariableEditorService] init bridge bindings', () => {
            bridge.bind(
                LocalVariableEditor_SetLatestSelectedCollectionCommand,
                (value) => {
                    enhancedLocalStorage.setSerializedItem(LocalStorageKey.LastSelectedVariableSetId, value.value ?? '')
                },
                {
                    signal,
                }
            )
            bridge.bind(
                LocalVariableEditor_GetLatestSelectedCollectionCommand,
                () => {
                    return {
                        value: enhancedLocalStorage.getSerializedItem(LocalStorageKey.LastSelectedVariableSetId),
                    }
                },
                {
                    signal,
                }
            )
            bridge.bind(
                LocalVariableEditor_SetCollectionCollapsedGroupKeysCommand,
                (param) => {
                    const currentCache = enhancedLocalStorage.getSerializedItem(
                        LocalStorageKey.CollectionCollapsedGroupKeys
                    )
                    const nextCache = {
                        ...currentCache,
                        [param.collectionId]: param.collapsedGroupKeys,
                    }
                    enhancedLocalStorage.setSerializedItem(LocalStorageKey.CollectionCollapsedGroupKeys, nextCache)
                },
                {
                    signal,
                }
            )
            bridge.bind(
                LocalVariableEditor_GetCollectionCollapsedGroupKeysCommand,
                (param) => {
                    const currentCache = enhancedLocalStorage.getSerializedItem(
                        LocalStorageKey.CollectionCollapsedGroupKeys
                    )
                    return {
                        collectionId: param.value!,
                        collapsedGroupKeys: currentCache?.[param.value!] ?? [],
                    }
                },
                {
                    signal,
                }
            )
        })
    }

    // 打开关闭弹框
    popupToggle = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_TogglePanelCommand)
    }

    // 重置弹框的打开状态
    clearPopupState = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
    }
    // 选择变量合集
    selectCollection = (variableSetId: string | null) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SelectCollectionCommand, {
            value: variableSetId,
        })
    }

    // 选择分组

    // 连选
    private _rangeSelectGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_RangeSelectGroupCommand, {
            value: groupKey,
        })
    }
    // 多选
    private _multiSelectGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_MultiSelectGroupCommand, {
            value: groupKey,
        })
    }
    // 单选，但已经选中时不修改选区
    private _safeSelectGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SafeSelectGroupCommand, {
            value: groupKey,
        })
    }
    // 单选，只保留当前选中
    private _forceSelectGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ForceSelectGroupCommand, {
            value: groupKey,
        })
    }
    mouseDownToSelectGroup = (groupKey: string, e: React.MouseEvent) => {
        if (groupKey === ALL_GROUP_LABEL) {
            this.clearGroupSelection()
            return
        }
        if (e.shiftKey) {
            this._rangeSelectGroup(groupKey)
        } else if (e.metaKey || (isWindows() && e.ctrlKey)) {
            this._multiSelectGroup(groupKey)
        } else {
            this._safeSelectGroup(groupKey)
        }
        // 切换分组后，清空选中的变量
        this.clearVariableSelection()
    }
    clickToSelectGroup = (groupKey: string, e: React.MouseEvent) => {
        if (e.shiftKey || e.metaKey || (isWindows() && e.ctrlKey)) {
            return
        }
        this._forceSelectGroup(groupKey)
    }

    // 清空分组选中
    clearGroupSelection = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ClearGroupSelectionCommand)
    }

    // 选择变量

    // 连选
    private _rangeSelectTo = (variableId: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_RangeSelectVariableCommand, {
            value: variableId,
        })
    }
    // 多选
    private _multiSelect = (variableId: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_MultiSelectVariableCommand, {
            value: variableId,
        })
    }
    // 单选，但已经选中时不修改选区
    private _safeSingleSelect = (variableId: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_SafeSelectVariableCommand, {
            value: variableId,
        })
    }
    // 单选，只保留当前选中
    private _forceSelectVariable = (variableId: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ForceSelectVariableCommand, {
            value: variableId,
        })
    }
    mouseDownToSelectVariable = (variableId: string, e: React.MouseEvent) => {
        if (e.shiftKey) {
            this._rangeSelectTo(variableId)
        } else if (e.metaKey || (isWindows() && e.ctrlKey)) {
            this._multiSelect(variableId)
        } else {
            this._safeSingleSelect(variableId)
        }
    }
    clickToSelectVariable = (variableId: string, e: React.MouseEvent) => {
        if (e.shiftKey || e.metaKey || (isWindows() && e.ctrlKey)) {
            return
        }
        this._forceSelectVariable(variableId)
    }

    // 清空变量选中
    clearVariableSelection = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ClearVariableSelectionCommand)
    }

    // 创建变量合集
    createCollection = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateCollectionCommand)
    }

    // 删除变量合集
    removeCollection = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteCollectionCommand)
    }

    // 重命名变量合集
    renameCollection = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_StartRenameCollectionCommand)
    }

    // 重命名变量合集提交
    renameCollectionSubmit = (newName: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_SubmitRenameCollectionCommand, {
            value: newName,
        })
    }

    // 获取合集中的新变量名
    getNewVariableNameInCollectionAndType = (
        collectionId: string,
        type: Wukong.DocumentProto.VariableResolvedDataType
    ) => {
        return this.commandInvoker.invokeBridge(CommitType.Noop, GenerateNewVariableNameForCollectionAndTypeCommand, {
            collectionId,
            type,
        }).value!
    }

    // 创建变量
    createVariable = (type: Wukong.DocumentProto.VariableResolvedDataType) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateVariableCommand, {
            type,
        })
        this._currentSelectionScrollIntoView()
    }

    // 打开编辑变量弹窗
    startEditVariable = (variableId: string, position: { left: number; top: number }) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ToggleVariableDetailPopupCommand, {
            position: { left: position.left, top: position.top },
            editingVariableId: variableId,
        })
    }

    // 关闭编辑变量弹窗
    endEditVariable = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
    }

    // 编辑变量弹窗修改名称
    editorChangeName = (variable: Wukong.DocumentProto.ILocalVariable, newName: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DirectRenameVariableCommand, {
            idOrKey: variable.id,
            newName,
        })
    }

    // 编辑变量弹窗修改描述
    editorChangeDescription = (variable: Wukong.DocumentProto.ILocalVariable, value: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateVariableDescriptionCommand, {
            variableId: variable.id,
            description: value,
        })
    }

    // 编辑变量弹窗修改代码语法
    editorChangeCodeSyntax = (
        variableId: string,
        platform: Wukong.DocumentProto.VariableCodePlatform,
        value: string
    ) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateVariableCodeSyntaxCommand, {
            variableId,
            platform,
            value,
        })
    }

    // 编辑变量弹窗删除代码语法
    editorRemoveCodeSyntax = (variableId: string, platform: Wukong.DocumentProto.VariableCodePlatform) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, RemoveVariableCodeSyntaxCommand, {
            variableId,
            platform,
        })
    }

    // 编辑变量弹窗修改是否发布时隐藏
    editorChangePublishHidden = (params: Wukong.DocumentProto.IArg_UpdateVariablePublishHiddenCommandParam[]) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, BatchUpdateVariablePublishHiddenCommand, {
            params,
        })
    }

    // 编辑变量弹窗修改 scopes
    editorChangeScopes = (params: Wukong.DocumentProto.IArg_UpdateVariableScopesCommandParam[]) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, BatchUpdateVariableScopesCommand, {
            params,
        })
    }

    // 打开关闭值编辑弹框
    toggleValueEditingPopup = (
        type: Wukong.DocumentProto.LocalVariableEditorEditingType,
        variableId: string,
        modeId: string,
        position: { left: number; top: number }
    ) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ToggleVariableValuePopupCommand, {
            type,
            position,
            editingVariableId: variableId,
            editingModeId: modeId,
        })
    }

    closeVariableEditingPopup = () => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CloseVariableEditingPopupCommand)
    }

    // 更新变量纯色值
    updateColorVariableValue = (
        variableId: string,
        variableSetModeId: string,
        rgba: Wukong.DocumentProto.IRGBA,
        commitType: CommitType = CommitType.CommitUndo
    ) => {
        return this.commandInvoker.invokeBridge(commitType, UpdateColorVariableValueCommand, {
            variableId,
            variableSetModeId,
            colorRGBAValue: rgba,
        })
    }

    // 更新数值变量值
    updateFloatVariableValue = (variableId: string, variableSetModeId: string, floatValue: number) => {
        return this.commandInvoker.invokeBridge(CommitType.CommitUndo, UpdateFloatVariableValueCommand, {
            variableId,
            variableSetModeId,
            floatValue,
        })
    }

    // 绑定变量引用
    setAliasForVariable = (variableId: string, variableSetModeId: string, aliasVariableId: string) => {
        const ret = this.commandInvoker.invokeBridge(CommitType.CommitUndo, SetAliasForVariableCommand, {
            variableId,
            variableSetModeId,
            aliasVariableId,
        })
        // TODO(variable): 可以把返回 code 改为 wasm 直接调用 toast error
        switch (ret.code) {
            case Wukong.DocumentProto.RetCode_SetAliasForVariableCommand
                .RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_DUE_TO_CIRCULAR_DEPENDENCIES:
                WKToast.error(translation('变量不能被循环引用'))
                return
            case Wukong.DocumentProto.RetCode_SetAliasForVariableCommand
                .RET_CODE__SET_ALIAS_FOR_VARIABLE_COMMAND_CANNOT_SET_ALIAS_FOR_SELF:
                WKToast.error(translation('变量不能引用自身'))
                return
            default:
                console.warn(Wukong.DocumentProto.RetCode_SetAliasForVariableCommand[ret.code])
        }
    }

    // 解除变量引用
    detachAliasForVariable = (variable: Wukong.DocumentProto.ILocalVariable, variableSetModeId: string) => {
        // TODO(variable): 这里可以改成 wcc 实现，而不是在 js 组合调用
        if (featureSwitchManager.isEnabled('float-variable')) {
            switch (variable.dataType) {
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
                    this.updateColorVariableValue(
                        variable.id,
                        variableSetModeId,
                        variable.dataValues[variableSetModeId].resolvedValue.colorValue
                    )
                    break
                case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
                    this.updateFloatVariableValue(
                        variable.id,
                        variableSetModeId,
                        variable.dataValues[variableSetModeId].resolvedValue.floatValue
                    )
                    break
                default:
                    break
            }
        } else {
            this.updateColorVariableValue(
                variable.id,
                variableSetModeId,
                variable.dataValues[variableSetModeId].resolvedValue.colorValue
            )
        }
    }

    // 复制变量
    duplicateSelectedVariables = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DuplicateSelectedVariablesCommand)
        this._currentSelectionScrollIntoView()
    }

    // 重命名变量
    renameVariable = (variableId: string | null) => {
        if (variableId) {
            this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_StartRenameVariableCommand, {
                value: variableId,
            })
            this._currentSelectionScrollIntoView()
        } else {
            this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_AbortRenameVariableCommand)
        }
    }

    // 重命名变量提交
    renameVariableSubmit = (newName: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_SubmitRenameVariableCommand, {
            value: newName,
        })
    }

    // 删除变量
    removeSelectedVariables = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteSelectedVariablesCommand)
    }

    // 新增合集 mode
    addCollectionMode = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_CreateCollectionModeCommand)
    }

    // 复制合集 mode
    duplicateCollectionMode = (setMode: Wukong.DocumentProto.IVariableSetMode) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DuplicateCollectionModeCommand, {
            value: setMode.modeId!,
        })
    }

    // 重命名合集 mode
    renameCollectionMode = (mode: Wukong.DocumentProto.IVariableSetMode, newName: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_RenameCollectionModeCommand, {
            idOrKey: mode.modeId!,
            newName,
        })
    }

    // 删除合集 mode
    deleteCollectionMode = (variableSetModeId: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteCollectionModeCommand, {
            value: variableSetModeId,
        })
    }

    // 新建分组
    addSelectedVariablesIntoGroup = () => {
        this.commandInvoker.invokeBridge(
            CommitType.CommitUndo,
            LocalVariableEditor_AddSelectedVariablesIntoGroupCommand
        )
    }

    // 重命名分组
    renameGroup = (group: Wukong.DocumentProto.ILocalVariableGroupItem, newName: string) => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_RenameGroupCommand, {
            idOrKey: group.key,
            newName,
        })
    }

    // 复制分组
    duplicateSelectedGroups = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DuplicateSelectedGroupsCommand)
    }

    // 取消分组。如选中组 a/b/c，取消分组 c，即是 c 下的所有变量移动到组 a/b 下，即组名只保留前两位
    ungroupSelectedGroups = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_UngroupSelectedGroupsCommand)
    }

    // 删除分组
    deleteSelectedGroups = () => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DeleteSelectedGroupsCommand)
    }

    // 在表格中拖拽变量
    dragSelectedVariablesInTable = (variable: Wukong.DocumentProto.ILocalVariable, position: 'before' | 'after') => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DragSelectedVariablesCommand, {
            targetVariableId: variable.id,
            position: {
                before: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_BEFORE,
                after: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_AFTER,
            }[position],
        })
    }

    // 将表格中的变量拖拽到侧边栏
    dragSelectedVariablesToSidebarGroup = (groupName: string) => {
        this.commandInvoker.invokeBridge(
            CommitType.CommitUndo,
            LocalVariableEditor_DragSelectedVariablesIntoGroupCommand,
            {
                value: groupName,
            }
        )
    }

    // 拖拽侧边栏分组
    dragSelectedGroups = (groupKey: string, position: 'before' | 'after' | 'into') => {
        this.commandInvoker.invokeBridge(CommitType.CommitUndo, LocalVariableEditor_DragGroupCommand, {
            groupKey,
            position: {
                before: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_BEFORE,
                after: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_AFTER,
                into: Wukong.DocumentProto.LocalVariableDragPosition.LOCAL_VARIABLE_DRAG_POSITION_INTO,
            }[position],
        })
    }

    // 侧边栏分组折叠
    collapseGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_CollapseGroupCommand, {
            value: groupKey,
        })
    }

    // 侧边栏分组展开
    expandGroup = (groupKey: string) => {
        this.commandInvoker.invokeBridge(CommitType.Noop, LocalVariableEditor_ExpandGroupCommand, {
            value: groupKey,
        })
    }
}
