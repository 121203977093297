import constate from 'constate'
import { useAIGenUIInputs } from '../../ai/ai-gen-ui/use-ai-gen-inputs'

function useChatbotInput() {
    const images = useAIGenUIInputs({})
    return {
        ...images,
    }
}

export const [ChatbotInputProvider, useChatbotInputInContext] = constate(useChatbotInput)
