import { AIDesignLintUpdateAndZoomToSelectionCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { MonoIconCommonQuestion16, Tooltip } from '../../../../../../../ui-lib/src'
import { NodeId } from '../../../../../document/node/node'
import { useColorProfileService, useRawMemAccessService } from '../../../../../main/app-context'
import { useCanvasRenderBridge, useCommand } from '../../../../context/document-context'
import { nodeToBlob } from '../../../design/export/util'
import { AIComponentItem } from '../ai-design-lint-request'
import { Card, CardInnerNavigation, useCardInnerNavigation } from './card'
import classes from './card.module.less'
import { translation } from './component-card-list.translation'
import { EmptyPrompt } from './empty-prompt'
import { ExclusiveList } from './exclusive-list'

function useNodesSVGText(nodeIds: NodeId[]) {
    const command = useCommand()
    const canvasRenderBridge = useCanvasRenderBridge()
    const rawMemAcc = useRawMemAccessService()
    const service = useColorProfileService()
    const colorProfile = service.states.use.colorProfileState()

    const [svgText, setSvgText] = useState<string>('')

    const getNodesSVGText = useCallback(async () => {
        const blob = await nodeToBlob(
            command,
            canvasRenderBridge,
            rawMemAcc,
            nodeIds,
            Wukong.DocumentProto.ExportFormatType.EXPORT_FORMAT_TYPE_SVG,
            {
                type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                value: 1,
            },
            colorProfile,
            false,
            false
        )

        if (blob) {
            return await blob.text()
        } else {
            throw Error('getNodesSVGText blob is null')
        }
    }, [canvasRenderBridge, colorProfile, command, nodeIds, rawMemAcc])

    useEffect(() => {
        getNodesSVGText().then((text) => {
            setSvgText(text)
        })
    }, [getNodesSVGText, nodeIds])

    return svgText
}

function ComponentThumbnail(prop: { nodeIds: NodeId[]; testId?: string }) {
    const svgText = useNodesSVGText(prop.nodeIds)

    return (
        <div
            data-testid={prop.testId}
            className="w-full h-full flex justify-center items-center"
            dangerouslySetInnerHTML={{ __html: svgText }}
        ></div>
    )
}

interface ComponentCardProp {
    type: string
    expanded: boolean
    components: NodeId[][]
}

function ComponentCard(prop: ComponentCardProp) {
    const command = useCommand()

    const { expanded, type, components } = prop

    const [currentComponent, setCurrentComponent] = useState<string[] | null>(null)

    const total = components.length

    useEffect(() => {
        if (expanded) {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                // 展开时, 选中所有的候选图层
                nodeIds: components.flat(),
            })

            command.commitUndo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded])

    const { curIdx, handleArrowDownClick, handleArrowUpClick, showCurrentIdx } = useCardInnerNavigation(
        total,
        expanded,
        (curIdx_) => {
            command.DEPRECATED_invokeBridge(AIDesignLintUpdateAndZoomToSelectionCommand, {
                nodeIds: components[curIdx_],
            })
        }
    )

    useEffect(() => {
        setCurrentComponent(components[curIdx])
    }, [components, curIdx])

    return (
        <div>
            <Card expanded={expanded}>
                <>
                    <div
                        data-testid={'component-card-item-' + type}
                        className={classes.card_container_item}
                        style={{
                            height: 'auto',
                        }}
                    >
                        {type}
                        <CardInnerNavigation
                            curIdx={curIdx}
                            showCurrentIdx={showCurrentIdx}
                            expanded={expanded}
                            total={total}
                            handleArrowDownClick={handleArrowDownClick}
                            handleArrowUpClick={handleArrowUpClick}
                        ></CardInnerNavigation>
                    </div>

                    {expanded && currentComponent && (
                        <div className={classNames(classes.component_thumbnail_background, 'px-4')}>
                            {/* 缩略图  */}
                            <ComponentThumbnail
                                testId={'component-thumbnail-image-' + type + '-' + currentComponent?.join('-')}
                                nodeIds={currentComponent}
                            ></ComponentThumbnail>
                        </div>
                    )}
                </>
            </Card>
        </div>
    )
}

export function ComponentCardList(prop: { cardInfoList: AIComponentItem[] }) {
    const [expandedId, setExpandedId] = useState<number | null>(null)

    // 类型排序优先级
    const type2ordinal = {
        Button: 0,
        Tag: 1,
        Checkbox: 2,
        Dialog: 3,
        Toast: 4,
        Tabs: 5,
        'Navigation Bar': 6,
        'Tab bar': 7,
    }
    const type2ordinalMap = new Map(Object.entries(type2ordinal))

    let cardInfoList = prop.cardInfoList
        .slice()
        .filter((item) => type2ordinalMap.has(item.componentType))
        .map((cardInfo) => {
            return { ...cardInfo, title: cardInfo.componentType } as AIComponentItem & { title: string }
        })

    cardInfoList.sort((lhs, rhs) => {
        if (lhs.componentType == rhs.componentType) {
            // 相同类型, 按数量降序排列
            return (rhs.repeats?.length || 0) - (lhs.repeats?.length || 0)
        } else {
            // 按类型优先级排列
            return (
                (type2ordinalMap.get(lhs.title) ?? type2ordinalMap.size) -
                (type2ordinalMap.get(rhs.title) ?? type2ordinalMap.size)
            )
        }
    })

    // 编号 1-indexed
    {
        let type = ''
        let num = 0
        cardInfoList = cardInfoList.map((item) => {
            if (item.title == type) {
                num += 1
            } else {
                type = item.title
                num = 1
            }
            item.componentType = item.componentType + ' ' + num

            return item
        })
    }

    const renderItem = useCallback(
        (index: number, expanded: boolean) => {
            const cardInfo = cardInfoList[index]

            let components = cardInfo.repeats?.map((item) => item.nodeIds) || []
            components = [cardInfo.component.nodeIds.slice(), ...components]

            return (
                cardInfo && (
                    <>
                        <ComponentCard
                            type={cardInfo.componentType}
                            expanded={expanded}
                            components={components}
                        ></ComponentCard>
                    </>
                )
            )
        },
        [cardInfoList]
    )

    return (
        <>
            {cardInfoList.length > 0 ? (
                <>
                    <div className={classes.result_tips}>
                        <div>
                            <div className="color-$wk-v2-label-color-gray-13 wk-text-12 wk-font-medium">
                                {translation('Tip1')}
                            </div>
                            <Tooltip title={translation('Tip1Extra')} firstDelay={100}>
                                <MonoIconCommonQuestion16 data-testid="ai-design-lint-same-style-tooltip" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={classes.card_list_container_container}>
                        <ExclusiveList
                            renderItem={renderItem}
                            expandedId={expandedId}
                            setExpandedId={setExpandedId}
                            indexes={[...Array(cardInfoList.length).keys()]}
                        ></ExclusiveList>
                    </div>
                </>
            ) : (
                <EmptyPrompt texts={[translation('EmptyPrompt')]} />
            )}
        </>
    )
}
