import { useCallback, useEffect, useState } from 'react'
import {
    DraggablePopupV2,
    MonoIconCommonArrowRight16,
    Scrollbar,
    WKAlert,
    WKButton,
    wkDialogConfirm,
    WKToast,
} from '../../../../../../ui-lib/src'
import { useAIDesignLintService, useCommand } from '../../../../main/app-context'
import { NetworkStatus, View } from './ai-design-lint-service'

// eslint-disable-next-line wukong/restrict-translation-import
import { translation } from './translation/home-page.translation'

import { ExportDocumentJsonCommand, ExportPngToData, GetCurrentPageIdCommand, Wukong } from '@wukong/bridge-proto'
import { useEvent } from 'react-use'
import { isEnglishLanguage, uuidv4 } from '../../../../../../util/src'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { NodeId } from '../../../../document/node/node'
import { WkCLog } from '../../../../kernel/clog/wukong/instance'
import { WKFrog } from '../../../../kernel/frog'
import { DocWithAuthorityVO } from '../../../../kernel/interface/type'
import { getUploadHeader } from '../../../../kernel/request/upload'
import { ExtractionFailedStatus } from '../../../../main/ai-recognize/requests'
import classes from './ai-design-lint-modal.module.less'
import {
    GetOSSUploadURLForDocJson,
    GetOSSUploadURLForImg,
    SubmitAIDesignLintTaskV2,
    UpdateAIDesignLintLibraryConfig,
} from './ai-design-lint-request'
import { PendingWrapper } from './common/pending-wrapper'
import { LibrarySettingPage } from './library-setting-page'
import { PendingContainer } from './pending-page'
import { ResultPage } from './result-page'
import { RuleSettingPage } from './rule-setting-page'

function Title() {
    return (
        <div className={classes.header}>
            <span className={classes.title_text}>{translation('Title')}</span>
        </div>
    )
}

function DescriptionContainer() {
    const aiDesignLintService = useAIDesignLintService()
    const setCurrentView = aiDesignLintService.setCurrentView

    const onClick = useCallback(() => {
        setCurrentView(View.SettingRule)
    }, [setCurrentView])

    return (
        <div className={classes.description}>
            <span className={classes.description_text}>
                {translation('Desc')}
                <a className="cursor-default" onClick={onClick}>
                    {(isEnglishLanguage() ? ' ' : '') + translation('Preference')}
                </a>
            </span>
        </div>
    )
}
interface ExportNode {
    id: string
    type: string
    children?: Array<ExportNode>
}

const uploadImg = async (docRawJSON: string, pageId: string, command: CommandInvoker) => {
    const docStruct = JSON.parse(docRawJSON) as { document: ExportNode }
    const targetPage = docStruct.document.children?.filter((node) => node.id === pageId)[0]

    if (targetPage?.children) {
        const nodeId2ResourceId = new Map<string, string>()

        await Promise.all(
            targetPage.children.map(async (node) => {
                // oss url
                const { ossUrl, contentType, method, resourceId } = await new GetOSSUploadURLForImg(uuidv4()).start()

                // record
                nodeId2ResourceId.set(node.id, resourceId)

                // img data
                const { dataBase64 } = command.DEPRECATED_invokeBridge(ExportPngToData, {
                    constraint: {
                        type: Wukong.DocumentProto.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                        value: 1,
                    },
                    nodeIds: [node.id],
                    forceClip: true,
                    ancestorClip: false,
                    useAbsoluteBounds: false,
                })

                // upload
                await fetch(ossUrl, {
                    method: method,
                    headers: getUploadHeader({ contentType: contentType }),
                    body: Buffer.from(dataBase64 as string, 'base64'),
                })
            })
        )

        return nodeId2ResourceId
    } else {
        return null
    }
}

const uploadDocJSON = async (docRawJSON: string) => {
    const { ossUrl, contentType, method, resourceId } = await new GetOSSUploadURLForDocJson(uuidv4()).start()

    await fetch(ossUrl, {
        method: method,
        headers: getUploadHeader({ contentType: contentType }),
        body: docRawJSON,
    })

    return resourceId
}

const exportCurrentPageAsJSON = (command: CommandInvoker, pageId: NodeId) => {
    const nodeRawJSON = command.DEPRECATED_invokeBridge(ExportDocumentJsonCommand, { nodeId: pageId }).json as string
    const nodeStruct = JSON.parse(nodeRawJSON)

    const docStruct = {
        document: {
            id: '0:0',
            name: 'Document',
            type: 'DOCUMENT',
            children: [nodeStruct.document] as ExportNode[],
        },
    }

    return JSON.stringify(docStruct)
}

function useTaskSubmissionHandler() {
    const aiDesignLintService = useAIDesignLintService()
    const command = useCommand()

    const submit = useCallback(() => {
        // TODO(jiangjieke): 现直接进入 pending 页, 待之后将计算密集的任务转移至 worker 再优化
        aiDesignLintService.setCurrentView(View.Pending)

        // setTimeout 避免 setCurrentView 被以下的 WCC 阻塞
        setTimeout(() => {
            WKFrog.addFrogRecord({
                url: '/click/AIConsistencyChecker/beginConsistencyCheck',
                eventId: 26797,
                eventAction: 'click',
                eventName: 'beginConsistencyCheck',
            })

            const { value: pageId } = command.DEPRECATED_invokeBridge(GetCurrentPageIdCommand)
            const docId = aiDesignLintService.docID

            if (pageId && docId) {
                aiDesignLintService.updateTaskID(undefined)

                const docRawJSON = exportCurrentPageAsJSON(command, pageId)

                Promise.all([uploadDocJSON(docRawJSON), uploadImg(docRawJSON, pageId, command)])
                    .then(([docJSONURL, imgURLs]) => {
                        return new SubmitAIDesignLintTaskV2(
                            docId,
                            pageId,
                            aiDesignLintService.states.getState().latestLibraryConfigState.value ?? [],
                            docJSONURL,
                            imgURLs ? Object.fromEntries(imgURLs) : {}
                        )
                            .start()
                            .then((taskID) => {
                                aiDesignLintService.updateTaskID(taskID)
                            })
                            .catch((e) => {
                                if (e == ExtractionFailedStatus.DocSchemaVersionNotSame) {
                                    WKToast.error(translation('DocSchemaVersionNotSame'))
                                    WkCLog.log('AIDesignLint', {
                                        traceEventName: 'INFO_SCHEMA_VERSION_NOT_MATCH_WHEN_DESERIALIZE',
                                        traceEventKey: 1386768,
                                        scenario: 'AIDesignLint',
                                        docId: docId,
                                        pageId: pageId,
                                        docJSONURL: docJSONURL,
                                    })
                                } else if (e == ExtractionFailedStatus.TooManyRunningQuestOneUser) {
                                    aiDesignLintService.setCurrentView(View.Home)
                                    aiDesignLintService.updateHasUnfinishedTask(true)
                                } else {
                                    throw e
                                }
                            })
                    })
                    .catch((_) => {
                        aiDesignLintService.updateNetworkStatus(NetworkStatus.Error)
                    })
            }
        }, 0)
    }, [aiDesignLintService, command])

    return { submit }
}

function SubmissionContainer() {
    const aiDesignLintService = useAIDesignLintService()
    const latestLibraryConfig = aiDesignLintService.states.use.latestLibraryConfigState()

    const { hasUnfinishedTask } = usePendingTaskAlert()

    const notAllowToSubmit = !latestLibraryConfig.value?.length || hasUnfinishedTask

    const { submit } = useTaskSubmissionHandler()

    return (
        <div className={classes.submission}>
            <WKButton disabled={notAllowToSubmit} onClick={submit} type="primary">
                {translation('Start')}
            </WKButton>
            <WKButton onClick={aiDesignLintService.closeModal} type="secondary">
                {translation('Cancel')}
            </WKButton>
        </div>
    )
}

function LibraryEnabledItem(prop: { name: string; styleCount: number; componentCount: number }) {
    return (
        <>
            <div className={classes.library_enabled_item_container}>
                <div>{prop.name}</div>
                <div>
                    {prop.componentCount +
                        (prop.componentCount == 1 ? translation('SingleComponent') : translation('PluralComponent'))}
                    {translation('Comma')}
                    {prop.styleCount + (prop.styleCount == 1 ? translation('SingleStyle') : translation('PluralStyle'))}
                </div>
            </div>
        </>
    )
}

function useInitLibraryConfig() {
    const aiDesignLintService = useAIDesignLintService()
    const docId = aiDesignLintService.docID

    const subscribedLibraryIds = aiDesignLintService.states.use.subscribedLibraryIdsAndPublishedLocalLibraryIdsState()
    const configInitialized = aiDesignLintService.states.use.configHasInitializedState()

    // 文档首次使用 "AI 一致性检查时"，默认将当前已订阅的组件库用作于检查
    useEffect(() => {
        if (configInitialized === false) {
            if (docId && subscribedLibraryIds) {
                new UpdateAIDesignLintLibraryConfig(docId, subscribedLibraryIds)
                    .start()
                    .then(() => {
                        aiDesignLintService.refresh()
                    })
                    .catch(() => {
                        aiDesignLintService.updateNetworkStatus(NetworkStatus.Error)
                    })
            }
        }
    }, [aiDesignLintService, configInitialized, docId, subscribedLibraryIds])

    return { configInitialized }
}

function LibraryInUseList() {
    const aiDesignLintService = useAIDesignLintService()
    const setCurrentView = aiDesignLintService.setCurrentView
    const handleSettingLibrary = useCallback(() => {
        setCurrentView(View.SettingLibrary)
    }, [setCurrentView])

    const { configInitialized } = useInitLibraryConfig()

    const libConfig = aiDesignLintService.states.use.libConfigState()

    const empty = libConfig ? libConfig.localLib.length + libConfig.remoteLibs.length === 0 : null

    const needToWait = !libConfig || !configInitialized

    return (
        <PendingWrapper pending={needToWait}>
            <>
                {empty ? (
                    <>
                        <div className={classes.empty_tips_container}>
                            <span className={classes.empty_tips_first}>{translation('EmptyTip')}</span>
                            <div style={{ display: 'flex', justifyContent: 'center', height: '32px' }}>
                                <WKButton onClick={handleSettingLibrary} type="secondary">
                                    {translation('Select')}
                                </WKButton>
                            </div>
                        </div>
                    </>
                ) : (
                    !needToWait && (
                        <>
                            <div className={classes.manager_bar}>
                                <div style={{ color: 'var(--wk-v2-label-color-gray-8)' }}>
                                    {translation('DesignSystem')}
                                </div>
                                <a className="cursor-default" onClick={handleSettingLibrary}>
                                    {translation('Manage')}
                                    <MonoIconCommonArrowRight16 />
                                </a>
                            </div>
                            <Scrollbar style={{ flex: '1' }}>
                                {libConfig!.localLib.length > 0 && (
                                    <LibraryEnabledItem
                                        name={translation('LocalLibrary')}
                                        componentCount={libConfig!.localLib[0].componentCount || 0}
                                        styleCount={libConfig!.localLib[0].styleCount || 0}
                                    />
                                )}
                                <div>
                                    {libConfig!.remoteLibs.map((library) => (
                                        <div key={library.id}>
                                            <LibraryEnabledItem
                                                name={(library.document as DocWithAuthorityVO).name}
                                                componentCount={library.componentCount || 0}
                                                styleCount={library.styleCount || 0}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Scrollbar>
                        </>
                    )
                )}
            </>
        </PendingWrapper>
    )
}

export function usePendingTaskAlert() {
    const aiDesignLintService = useAIDesignLintService()
    const hasUnfinishedTask = aiDesignLintService.states.use.hasUnfinishedTaskState()

    const AlertBanner = () => (
        <WKAlert.WithoutTitle
            color="warning"
            closeable
            closeButtonTestId="ai-design-lint-alert-close-btn"
            onClose={() => {
                aiDesignLintService.updateHasUnfinishedTask(false)
            }}
        >
            {translation('PendingTaskAlert')}
        </WKAlert.WithoutTitle>
    )

    return { AlertBanner, hasUnfinishedTask }
}

function useBeforeCloseHandler() {
    const aiDesignLintService = useAIDesignLintService()
    const currentView = aiDesignLintService.states.use.currentViewTypeState()
    const [hidePopup, setHidePopup] = useState(false)

    useEffect(() => {
        if (currentView === View.Result) {
            aiDesignLintService.updateBeforeCloseCallback(async () => {
                setHidePopup(true)

                return new Promise((resolve) => {
                    wkDialogConfirm.show({
                        title: translation('CloseDialogTitle'),
                        content: translation('CloseDialogText'),
                        onOk() {
                            resolve(true)
                            setHidePopup(false)
                        },
                        onClose() {
                            resolve(false)
                            setHidePopup(false)
                        },
                    })
                })
            })
        } else {
            aiDesignLintService.resetBeforeCloseCallback()
        }
    }, [aiDesignLintService, currentView])

    return { hidePopup }
}

function useAdjustHeightToBroserSize() {
    // 同 library-home-modal 窗口自适应实现

    const getPopupHeight = () => {
        const winHeight = window.innerHeight
        let height = 600
        if (winHeight < 48 + 8 * 2 + 600) {
            height = Math.max(winHeight - 48 - 8 * 2, 150 + 55 + 60)
        }
        return height
    }
    const [popupHeight, setPopupHeight] = useState<number>(getPopupHeight())

    // 视窗变化时，动态设置 height
    useEvent('resize', () => {
        setPopupHeight(getPopupHeight())
    })

    return { popupHeight }
}

export function AIDesignLintPopupModalImpl() {
    const aiDesignLintService = useAIDesignLintService()
    const currentView = aiDesignLintService.states.use.currentViewTypeState()
    const setCurrentView = aiDesignLintService.setCurrentView
    const serviceState = aiDesignLintService.states.use.aiDesignLintServiceState()
    const positionState = aiDesignLintService.states.use.positionState()

    useBeforeCloseHandler()

    useEffect(() => {
        if (!serviceState.isOpen) {
            setCurrentView(View.Home)
        }
    }, [serviceState.isOpen, setCurrentView])

    const { AlertBanner, hasUnfinishedTask } = usePendingTaskAlert()

    const { popupHeight } = useAdjustHeightToBroserSize()

    return serviceState.isOpen ? (
        <DraggablePopupV2
            testId={'ai-design-lint-modal'}
            position={positionState}
            visible={true}
            width={serviceState.width}
            height={popupHeight}
            header={<Title />}
            bodyClassName="p-0"
            footer={null}
            onCancel={aiDesignLintService.closeModal}
            closeTestId="ai-design-lint-modal-close"
            onMove={(position) => {
                aiDesignLintService.updateCurrentPosition(position)
            }}
        >
            <div
                className={classes.whole_page}
                style={{ height: `${popupHeight - 54}px` }}
                // 阻止 DraggablePopupV2 的拖动
                onMouseDown={(e) => {
                    e.stopPropagation()
                }}
                onPointerDown={(e) => {
                    e.stopPropagation()
                }}
            >
                {currentView === View.Home && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            {hasUnfinishedTask && (
                                <div style={{ padding: '16px 24px' }}>
                                    <AlertBanner />
                                </div>
                            )}
                            <DescriptionContainer />
                            <LibraryInUseList />
                            <SubmissionContainer />
                        </div>
                    </>
                )}

                {currentView === View.Pending && <PendingContainer />}
                {currentView === View.Result && <ResultPage />}
                {currentView === View.SettingLibrary && <LibrarySettingPage />}
                {currentView === View.SettingRule && <RuleSettingPage />}
            </div>
        </DraggablePopupV2>
    ) : (
        <></>
    )
}
