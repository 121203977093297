/* eslint-disable no-restricted-imports */
import {
    AttachStrokeWeightFloatVarCommand,
    DetachStrokeWeightFloatVarCommand,
    SetStrokeWeightForSelectionCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useSelectionState } from '../../../../document/selection/use-selection-state'
import { InputOptionsForUndoSquash } from '../../../atom/inputs/components/formatted-input'
import { Value } from '../../../atom/inputs/utils/type'
import { getFieldFloatVariable } from '../../../variable/float-variable/utils'
import { useFloatVariablePanel } from '../../primitive-variable/use-float-variable-panel'

export function useStrokeFourIndependentWeight() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const { changeSelectionInfo } = useSelectionState()
    const typeState = selectionStroke?.weightState.typeState
    const topBorderState = selectionStroke?.weightState.topBorderState
    const rightBorderState = selectionStroke?.weightState.rightBorderState
    const bottomBorderState = selectionStroke?.weightState.bottomBorderState
    const leftBorderState = selectionStroke?.weightState.leftBorderState

    const [showInputGroup, setShowInputGroup] = useState(false)

    useEffect(() => {
        // 自定义描边类型自动展开
        setShowInputGroup(
            typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeSelectionInfo])

    useEffect(() => {
        // 经过矢量编辑的矩形，passIds 不变，但 typeState 值变为 empty
        if (typeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY) {
            setShowInputGroup(false)
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM) {
            // 自定义描边类型自动展开
            setShowInputGroup(true)
        }
    }, [typeState?.type, typeState?.value])

    const onChangeTopWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, { borderTopWeight: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const onChangeRightWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, { borderRightWeight: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const onChangeBottomWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, { borderBottomWeight: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    const onChangeLeftWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            command.DEPRECATED_invokeBridge(SetStrokeWeightForSelectionCommand, { borderLeftWeight: value })
            command.commitUndo(options?.commitType)
        },
        [command]
    )

    return {
        onChangeTopWeight,
        onChangeRightWeight,
        onChangeBottomWeight,
        onChangeLeftWeight,
        topBorderState,
        rightBorderState,
        bottomBorderState,
        leftBorderState,
        showInputGroup,
        setShowInputGroup,
    }
}

export function useStrokeFourIndependentWeightV2() {
    const command = useCommand()
    const selectionStroke = useViewState('selectionStrokeV2')
    const { changeSelectionInfo } = useSelectionState()
    const typeState = selectionStroke?.weightState.typeState
    const topBorderState = selectionStroke?.weightState.topBorderState
    const rightBorderState = selectionStroke?.weightState.rightBorderState
    const bottomBorderState = selectionStroke?.weightState.bottomBorderState
    const leftBorderState = selectionStroke?.weightState.leftBorderState
    const variableConsumptionMapState = useViewState('variableConsumptionMapState')

    const [showInputGroup, setShowInputGroup] = useState(false)

    const topBorderVariable = useMemo(() => {
        if (topBorderState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED) {
            return
        }
        return getFieldFloatVariable(Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT, variableConsumptionMapState)
    }, [variableConsumptionMapState, topBorderState?.type])
    const rightBorderVariable = useMemo(() => {
        if (rightBorderState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED) {
            return
        }
        return getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT,
            variableConsumptionMapState
        )
    }, [variableConsumptionMapState, rightBorderState?.type])
    const bottomBorderVariable = useMemo(() => {
        if (bottomBorderState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED) {
            return
        }
        return getFieldFloatVariable(
            Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT,
            variableConsumptionMapState
        )
    }, [variableConsumptionMapState, bottomBorderState?.type])
    const leftBorderVariable = useMemo(() => {
        if (leftBorderState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED) {
            return
        }
        return getFieldFloatVariable(Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT, variableConsumptionMapState)
    }, [variableConsumptionMapState, leftBorderState?.type])
    const topBorderVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.STROKE_FLOAT],
        selectedVariable: topBorderVariable,
        selectedVariableFallbackFloatValue: topBorderState?.value ?? 0,
        createEnable: true,
        defaultCreateValue: topBorderState?.value ?? 0,
        onVariableSelected: (id: string) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.BORDER_TOP_WEIGHT)
        },
    })
    const rightBorderVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.STROKE_FLOAT],
        selectedVariable: rightBorderVariable,
        selectedVariableFallbackFloatValue: rightBorderState?.value ?? 0,
        createEnable: true,
        defaultCreateValue: rightBorderState?.value ?? 0,
        onVariableSelected: (id: string) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.BORDER_RIGHT_WEIGHT)
        },
    })
    const bottomBorderVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.STROKE_FLOAT],
        selectedVariable: bottomBorderVariable,
        selectedVariableFallbackFloatValue: bottomBorderState?.value ?? 0,
        createEnable: true,
        defaultCreateValue: bottomBorderState?.value ?? 0,
        onVariableSelected: (id: string) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.BORDER_BOTTOM_WEIGHT)
        },
    })
    const leftBorderVariablePicker = useFloatVariablePanel({
        requiredScopes: [Wukong.DocumentProto.VariableScope.STROKE_FLOAT],
        selectedVariable: leftBorderVariable,
        selectedVariableFallbackFloatValue: leftBorderState?.value ?? 0,
        createEnable: true,
        defaultCreateValue: leftBorderState?.value ?? 0,
        onVariableSelected: (id: string) => {
            onAttachFloatVar(id, Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT)
        },
        onVariableDetach: () => {
            onDetachFloatVar(Wukong.DocumentProto.VariableField.BORDER_LEFT_WEIGHT)
        },
    })

    useEffect(() => {
        // 自定义描边类型自动展开
        setShowInputGroup(
            typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeSelectionInfo])

    useEffect(() => {
        // 经过矢量编辑的矩形，passIds 不变，但 typeState 值变为 empty
        if (typeState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_EMPTY) {
            setShowInputGroup(false)
        } else if (typeState?.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM) {
            // 自定义描边类型自动展开
            setShowInputGroup(true)
        }
    }, [typeState?.type, typeState?.value])

    const onChangeTopWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            const commitType = options?.commitType ?? CommitType.CommitUndo
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, { borderTopWeight: value })
        },
        [command]
    )

    const onChangeRightWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            const commitType = options?.commitType ?? CommitType.CommitUndo
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, { borderRightWeight: value })
        },
        [command]
    )

    const onChangeBottomWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            const commitType = options?.commitType ?? CommitType.CommitUndo
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, { borderBottomWeight: value })
        },
        [command]
    )

    const onChangeLeftWeight = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value != 'number' || value < 0) {
                return
            }
            const commitType = options?.commitType ?? CommitType.CommitUndo
            command.invokeBridge(commitType, SetStrokeWeightForSelectionCommand, { borderLeftWeight: value })
        },
        [command]
    )

    const onAttachFloatVar = (id: string, field: Wukong.DocumentProto.VariableField) => {
        const floatVar: Wukong.DocumentProto.IVariableAliasData = {
            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
            resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
            value: { alias: id },
        }
        command.invokeBridge(CommitType.CommitUndo, AttachStrokeWeightFloatVarCommand, {
            fields: [field],
            variableData: floatVar,
        })
    }

    const onDetachFloatVar = (field: Wukong.DocumentProto.VariableField) => {
        command.invokeBridge(CommitType.CommitUndo, DetachStrokeWeightFloatVarCommand, {
            fields: [field],
        })
    }

    return {
        onChangeTopWeight,
        onChangeRightWeight,
        onChangeBottomWeight,
        onChangeLeftWeight,
        topBorderState,
        rightBorderState,
        bottomBorderState,
        leftBorderState,
        showInputGroup,
        setShowInputGroup,
        topBorderVariablePicker,
        rightBorderVariablePicker,
        bottomBorderVariablePicker,
        leftBorderVariablePicker,
    }
}
