import { Wukong } from '@wukong/bridge-proto'
import constate from 'constate'
import { useRef, useState } from 'react'
import { useEvent } from 'react-use'
import { useAppContext } from '../../../main/app-context'
import { ALL_GROUP_LABEL } from '../../../main/service/local-variable-editor-service'
import { useViewState } from '../../../view-state-bridge/use-view-state'

const MOVE_MIN_PIXEL_THRESHOLD = 4

function _useLocalVariableDragContext() {
    const service = useAppContext().variableService.localVariableEditorService
    // 鼠标按下的起点，同时表达是否处于拖拽状态
    const dragStartState = useRef<{ x: number; y: number } | null>(null)
    // 鼠标进入的行对应的变量
    const dragTargetVariable = useRef<{
        variable: Wukong.DocumentProto.ILocalVariable
        index: number
    } | null>(null)
    // 鼠标进入的行元素
    const dragTargetVariableRef = useRef<HTMLDivElement | null>(null)
    // 鼠标进入的位置对应行的前后
    const dragTargetVariablePosition = useRef<'before' | 'after' | null>(null)
    // 鼠标进入的分组
    const dragTargetGroup = useRef<string | null>(null)
    // 拖拽时显示的线的位置
    const [dragLine, setDragLine] = useState<
        | {
              show: false
          }
        | { show: true; index: number; position: 'before' | 'after' }
    >({
        show: false,
    })
    // 拖拽时显示的高亮组
    const [dragToGroupHighlight, setDragToGroupHighlight] = useState<string | null>(null)

    const dragStart = (
        e: React.MouseEvent,
        variable: Wukong.DocumentProto.ILocalVariable,
        index: number,
        ref: HTMLDivElement
    ) => {
        // 记录起点位置
        dragStartState.current = { x: e.clientX, y: e.clientY }
        // 记录起始点对应的变量
        dragTargetVariable.current = { variable, index }
        // 记录起始点对应的行元素
        dragTargetVariableRef.current = ref
        // 但只是点下，不展示线
    }
    const dragEnterRow = (
        e: React.MouseEvent,
        variable: Wukong.DocumentProto.ILocalVariable,
        index: number,
        ref: HTMLDivElement
    ) => {
        if (!dragStartState.current) {
            return
        }
        // 记录起始点对应的变量
        dragTargetVariable.current = { variable, index }
        // 记录起始点对应的行元素
        dragTargetVariableRef.current = ref
        // 清除分组
        dragTargetGroup.current = null
    }
    const dragLeaveRow = () => {
        if (!dragStartState.current) {
            return
        }
        // 离开行，但是不清除状态，仍展示线，松手还是可以完成移动
        dragTargetVariableRef.current = null
    }
    const dragVariableEnterGroup = (groupKey: string) => {
        if (!dragStartState.current) {
            return
        }
        // 记录进入的分组
        dragTargetGroup.current = groupKey
        // 清除行
        dragTargetVariable.current = null
        dragTargetVariableRef.current = null
        dragTargetVariablePosition.current = null
        // 自动展开分组
        service.expandGroup(groupKey)
    }
    const dragVariableLeaveGroup = () => {
        if (!dragStartState.current) {
            return
        }
        // 离开分组，但是不清除状态，仍展示高亮，松手还是可以完成移动
    }
    const dragging = (e: React.MouseEvent) => {
        if (!dragStartState.current) {
            return
        }
        const deltaY = e.clientY - dragStartState.current.y
        // 纵向移动超过阈值，计算线位置
        if (Math.abs(deltaY) > MOVE_MIN_PIXEL_THRESHOLD) {
            updateDragTarget(e.clientY)
        }
    }
    const dragEnd = () => {
        if (!dragStartState.current) {
            return
        }
        if (dragTargetVariable.current && dragTargetVariablePosition.current) {
            service.dragSelectedVariablesInTable(
                dragTargetVariable.current.variable,
                dragTargetVariablePosition.current
            )
        }
        if (dragTargetGroup.current != null) {
            service.dragSelectedVariablesToSidebarGroup(dragTargetGroup.current)
        }
        dragStartState.current = null
        dragTargetVariableRef.current = null
        dragTargetVariable.current = null
        dragTargetVariablePosition.current = null
        dragTargetGroup.current = null
        setDragLine({ show: false })
        setDragToGroupHighlight(null)
    }

    const updateDragTarget = (y: number) => {
        // 优先考虑分组，即便离开了行
        if (dragTargetGroup.current != null) {
            setDragToGroupHighlight(dragTargetGroup.current)
            setDragLine({ show: false })
        }
        // 行间显示的线
        if (dragTargetVariableRef.current && dragTargetVariable.current) {
            const rect = dragTargetVariableRef.current.getBoundingClientRect()
            if (y >= rect.top - MOVE_MIN_PIXEL_THRESHOLD && y < rect.bottom - rect.height / 2) {
                // 在行上方
                dragTargetVariablePosition.current = 'before'
                setDragLine({
                    show: true,
                    index: dragTargetVariable.current.index,
                    position: 'before',
                })
            } else if (y <= rect.bottom + MOVE_MIN_PIXEL_THRESHOLD && y >= rect.bottom - rect.height / 2) {
                // 在行下方
                dragTargetVariablePosition.current = 'after'
                setDragLine({
                    show: true,
                    index: dragTargetVariable.current.index,
                    position: 'after',
                })
            } else {
                setDragLine({ show: false })
            }
            setDragToGroupHighlight(null)
        }
    }

    // 移动和松手事件在全屏订阅
    useEvent('mousemove', dragging)
    useEvent('mouseup', dragEnd, window, { capture: true })

    return {
        dragLine,
        dragToGroupHighlight,
        dragStart,
        dragEnterRow,
        dragLeaveRow,
        dragVariableEnterGroup,
        dragVariableLeaveGroup,
    }
}

export const [LocalVariableDragProvider, useLocalVariableDragContext] = constate(_useLocalVariableDragContext)

/////

function _useLocalVariableGroupDragContext() {
    const service = useAppContext().variableService.localVariableEditorService
    const groupList = useViewState('localVariableGroup')?.groups ?? []
    // 鼠标按下的起点，同时表达是否处于拖拽状态
    const dragStartState = useRef<{ x: number; y: number } | null>(null)
    // 鼠标进入的行对应的变量
    const dragTargetGroup = useRef<string | null>(null)
    // 鼠标进入的行元素
    const dragTargetGroupRef = useRef<HTMLDivElement | null>(null)
    // 鼠标进入的位置对应行的前后
    const dragTargetGroupPosition = useRef<'before' | 'after' | 'into' | null>(null)
    // 拖拽时显示的线的位置
    const [dragTarget, setDragTarget] = useState<
        | {
              display: null
          }
        | { display: 'line'; top: number; left: number }
        | { display: 'group'; groupKey: string }
    >({
        display: null,
    })

    const dragStart = (e: React.MouseEvent, groupKey: string, ref: HTMLDivElement) => {
        // 记录起点位置
        dragStartState.current = { x: e.clientX, y: e.clientY }
        // 记录起始点对应的分组
        dragTargetGroup.current = groupKey
        // 记录起始点对应的行元素
        dragTargetGroupRef.current = ref
        // 但只是点下，不展示线
    }
    const dragEnterGroup = (groupKey: string, ref: HTMLDivElement) => {
        if (!dragStartState.current) {
            return
        }
        dragTargetGroup.current = groupKey
        dragTargetGroupRef.current = ref
    }
    const dragLeaveGroup = () => {
        if (!dragStartState.current) {
            return
        }
        // 离开分组
        dragTargetGroupRef.current = null
    }
    const dragging = (e: React.MouseEvent) => {
        if (!dragStartState.current) {
            return
        }
        const deltaY = e.clientY - dragStartState.current.y
        // 纵向移动超过阈值，计算线位置
        if (Math.abs(deltaY) > MOVE_MIN_PIXEL_THRESHOLD) {
            updateDragTarget(e.clientY)
        }
    }
    const dragEnd = () => {
        if (!dragStartState.current) {
            return
        }
        if (dragTargetGroup.current != null && dragTargetGroupPosition.current) {
            service.dragSelectedGroups(dragTargetGroup.current, dragTargetGroupPosition.current)
        }
        dragStartState.current = null
        dragTargetGroupRef.current = null
        dragTargetGroup.current = null
        dragTargetGroupPosition.current = null
        setDragTarget({ display: null })
    }
    const updateDragTarget = (y: number) => {
        if (dragTargetGroup.current != null && dragTargetGroupRef.current) {
            if (dragTargetGroup.current === ALL_GROUP_LABEL) {
                setDragTarget({ display: 'group', groupKey: ALL_GROUP_LABEL })
                return
            }
            const group = groupList.find((g) => g.key === dragTargetGroup.current)
            if (!group) {
                return
            }
            const rect = dragTargetGroupRef.current.getBoundingClientRect()
            if (y >= rect.top - MOVE_MIN_PIXEL_THRESHOLD && y < rect.top + rect.height / 3) {
                // 在行上方
                dragTargetGroupPosition.current = 'before'
                setDragTarget({
                    display: 'line',
                    top: dragTargetGroupRef.current.offsetTop,
                    left: group.indent * 24 + 4,
                })
            } else if (y <= rect.bottom + MOVE_MIN_PIXEL_THRESHOLD && y > rect.bottom - rect.height / 3) {
                // 在行下方
                dragTargetGroupPosition.current = 'after'
                setDragTarget({
                    display: 'line',
                    top: dragTargetGroupRef.current.offsetTop + rect.height,
                    left: group.hasSubGroups && !group.collapsed ? (group.indent + 1) * 24 + 4 : group.indent * 24 + 4,
                })
            } else if (y > rect.top && y < rect.bottom) {
                dragTargetGroupPosition.current = 'into'
                setDragTarget({ display: 'group', groupKey: dragTargetGroup.current })
            } else {
                setDragTarget({ display: null })
            }
        }
    }

    // 移动和松手事件在全屏订阅
    useEvent('mousemove', dragging)
    useEvent('mouseup', dragEnd, window, { capture: true })

    return {
        dragTarget,
        dragStart,
        dragEnterGroup,
        dragLeaveGroup,
    }
}

export const [LocalVariableGroupDragProvider, useLocalVariableGroupDragContext] = constate(
    _useLocalVariableGroupDragContext
)
