import { translation } from './stroke-independent-type-icon.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { HTMLAttributes } from 'react'
import {
    BicolorIconPanelStrokeBottom16,
    BicolorIconPanelStrokeLeft16,
    BicolorIconPanelStrokeLeftBottom16,
    BicolorIconPanelStrokeLeftRight16,
    BicolorIconPanelStrokeLeftRightBotttom16,
    BicolorIconPanelStrokeLeftTop16,
    BicolorIconPanelStrokeLeftTopBottom16,
    BicolorIconPanelStrokeLeftTopRight16,
    BicolorIconPanelStrokeRight16,
    BicolorIconPanelStrokeRightBottom16,
    BicolorIconPanelStrokeTop16,
    BicolorIconPanelStrokeTopBottom16,
    BicolorIconPanelStrokeTopRight16,
    BicolorIconPanelStrokeTopRightBottom16,
    MonoIconPanelAdjust16,
    MonoIconPanelIndependentPadding16,
    MonoIconPanelMultipleStroke16,
} from '../../../../../../../ui-lib/src'
import { EditorDataTestId } from '../../../../../window'

// 切换独立描边类型
export function StrokeIndependentTypeIcon({
    mixed,
    type,
    existStatus,
    ...props
}: {
    mixed: boolean
    type: Wukong.DocumentProto.IndependentStrokeType | undefined
    existStatus: Wukong.DocumentProto.ISelectionStrokeIndependentStatus | undefined
} & HTMLAttributes<HTMLOrSVGElement>) {
    if (mixed) {
        return (
            <MonoIconPanelMultipleStroke16
                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('mixed')}
                {...props}
            />
        )
    }
    switch (type) {
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL:
            return (
                <MonoIconPanelIndependentPadding16
                    data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('all')}
                    {...props}
                />
            )
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP:
            return (
                <BicolorIconPanelStrokeTop16
                    data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('top')}
                    {...props}
                />
            )
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT:
            return (
                <BicolorIconPanelStrokeRight16
                    data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('right')}
                    {...props}
                />
            )
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM:
            return (
                <BicolorIconPanelStrokeBottom16
                    data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('bottom')}
                    {...props}
                />
            )
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT:
            return (
                <BicolorIconPanelStrokeLeft16
                    data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left')}
                    {...props}
                />
            )
        case Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM:
            {
                const exsitList = [
                    existStatus?.topExist,
                    existStatus?.rightExist,
                    existStatus?.bottomExist,
                    existStatus?.leftExist,
                ]
                const noEmptyIndexList = exsitList.reduce((res: number[], value, index) => {
                    if (value) {
                        res.push(index)
                    }
                    return res
                }, [])

                if (noEmptyIndexList.length == 2) {
                    if ([0, 1].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeTopRight16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('top-right')}
                                {...props}
                            />
                        )
                    }
                    if ([1, 2].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeRightBottom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('right-bottom')}
                                {...props}
                            />
                        )
                    }
                    if ([2, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftBottom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-bottom')}
                                {...props}
                            />
                        )
                    }
                    if ([0, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftTop16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-top')}
                                {...props}
                            />
                        )
                    }

                    if ([0, 2].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeTopBottom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('top-bottom')}
                                {...props}
                            />
                        )
                    }

                    if ([1, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftRight16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-right')}
                                {...props}
                            />
                        )
                    }
                }

                if (noEmptyIndexList.length == 3) {
                    if ([0, 1, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftTopRight16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-top-right')}
                                {...props}
                            />
                        )
                    }
                    if ([0, 1, 2].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeTopRightBottom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('top-right-bottom')}
                                {...props}
                            />
                        )
                    }
                    if ([1, 2, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftRightBotttom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-right-bottom')}
                                {...props}
                            />
                        )
                    }
                    if ([0, 2, 3].every((value, index) => noEmptyIndexList[index] === value)) {
                        return (
                            <BicolorIconPanelStrokeLeftTopBottom16
                                data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('left-top-bottom')}
                                {...props}
                            />
                        )
                    }
                }

                if (noEmptyIndexList.length == 4) {
                    return (
                        <MonoIconPanelIndependentPadding16
                            data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('all')}
                            {...props}
                        />
                    )
                }
            }
            break
        default:
            break
    }

    return <></>
}

export const StrokeIndependentOptionMap = new Map<
    Wukong.DocumentProto.IndependentStrokeType,
    { label: string; icon: JSX.Element }
>([
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL,
        {
            label: translation('All'),
            icon: (
                <StrokeIndependentTypeIcon
                    mixed={false}
                    type={Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL}
                    existStatus={undefined}
                />
            ),
        },
    ],
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP,
        {
            label: translation('Top'),
            icon: (
                <StrokeIndependentTypeIcon
                    mixed={false}
                    type={Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_TOP}
                    existStatus={undefined}
                />
            ),
        },
    ],
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM,
        {
            label: translation('Bottom'),
            icon: (
                <StrokeIndependentTypeIcon
                    mixed={false}
                    type={Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_BOTTOM}
                    existStatus={undefined}
                />
            ),
        },
    ],
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT,
        {
            label: translation('Left'),
            icon: (
                <StrokeIndependentTypeIcon
                    mixed={false}
                    type={Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_LEFT}
                    existStatus={undefined}
                />
            ),
        },
    ],
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT,
        {
            label: translation('Right'),
            icon: (
                <StrokeIndependentTypeIcon
                    mixed={false}
                    type={Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_RIGHT}
                    existStatus={undefined}
                />
            ),
        },
    ],
    [
        Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_CUSTOM,
        {
            label: translation('Custom'),
            icon: (
                <MonoIconPanelAdjust16 data-testid={EditorDataTestId.StrokeAttr.IndependentTypeSelectIcon('custom')} />
            ),
        },
    ],
])
