import { LayerPanelExpandClickCommand, LayerPanelOptionExpandClickCommand, Wukong } from '@wukong/bridge-proto'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { MonoIconCommonEnterDown16, MonoIconCommonEnterRight16 } from '../../../../../../../../ui-lib/src'
import { NodeId } from '../../../../../../document/node/node'
import { useCommand } from '../../../../../context/document-context'
import styles from './index.module.less'

import VLayerPanelNodeExpandStauts = Wukong.DocumentProto.VLayerPanelNodeExpandStauts

interface DevModeLayerPanelExpandProps {
    id: NodeId
    expandStatus: VLayerPanelNodeExpandStauts
}

export const DevModeLayerPanelExpand: FC<DevModeLayerPanelExpandProps> = ({ expandStatus, id }) => {
    const command = useCommand()

    const getExpandIcon = useCallback(() => {
        switch (expandStatus) {
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_NONE: {
                return null
            }
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_EXPANDED: {
                return <MonoIconCommonEnterDown16 className="color-$wk-v2-label-color-gray-6" />
            }
            case VLayerPanelNodeExpandStauts.V_LAYER_PANEL_NODE_EXPAND_STAUTS_UN_EXPANDED: {
                return <MonoIconCommonEnterRight16 className="color-$wk-v2-label-color-gray-6" />
            }
        }
    }, [expandStatus])

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            event.stopPropagation()
            if (event.altKey) {
                // Option 展开
                command.DEPRECATED_invokeBridge(
                    LayerPanelOptionExpandClickCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: id,
                    })
                )
                return
            }
            // 普通展开
            command.DEPRECATED_invokeBridge(
                LayerPanelExpandClickCommand,
                Wukong.DocumentProto.BridgeProtoString.create({
                    value: id,
                })
            )
            return
        },
        [command, id]
    )

    return useMemo(
        () => (
            <div
                data-testid="dev-mode-layer-panel-expand"
                className={styles.renderItemExpand}
                onMouseDown={handleMouseDown}
            >
                {getExpandIcon()}
            </div>
        ),
        [getExpandIcon, handleMouseDown]
    )
}
