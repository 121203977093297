import { translation } from './library-replace-detail.translation'

import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { MonoIconCommonArrowLeft16, Scrollbar, Select, WKButton, WKDivider } from '../../../../../../ui-lib/src'
import { WKLoading } from '../../../../../../ui-lib/src/components/wk-loading/wk-loading'
import { LibraryComponentListLayout } from '../../../../share/component-style-library/library-component-viewer/library-component-viewer'
import commonClasses from '../../../../share/component-style-library/library-control-list-item-detail/library-control-list-item-detail.module.less'
import { LibraryTestId } from '../../../../window'
import { useMenu } from '../../top-area/menu/menu-v2/use-menu'
import { transformMenuConfig } from '../../top-area/menu/menu-v2/utils'
import { LibraryReplaceDisplayItem } from '../library-replace-display-item/library-replace-display-item'
import classes from './library-replace-detai.module.less'
import { useLibraryReplaceDetail } from './use-library-replace-detail'
import { useSwitchTargetLibrary, UseSwitchTargetLibraryProps } from './use-switch-target-library'

// 组件库替换面板
export function LibraryReplaceDetail(
    props: Wukong.DocumentProto.ILibraryIdInfo & { docName?: string; className?: string }
) {
    const {
        goBack,
        containerRef,
        libraryReplaceModalViewState,
        startReplace,
        onAnimationEnd,
        selectedTargetName,
        setSelectedTargetName,
    } = useLibraryReplaceDetail(props)

    return (
        <div
            className={classnames(commonClasses.container, props.className)}
            data-testid={LibraryTestId.ReplaceModal.Body}
            onAnimationEnd={onAnimationEnd}
        >
            <div className={commonClasses.topContainer}>
                <div className="flex items-center" onClick={goBack} data-testid={LibraryTestId.ReplaceModal.BackBtn}>
                    <MonoIconCommonArrowLeft16 className="color-$wk-v2-label-color-gray-8" />
                    <span className={commonClasses.docNameContainer}>{translation('SwapLibrary')}</span>
                </div>
            </div>
            <WKDivider className={commonClasses.divider} />
            <div className={classes.libraryNameContainer}>
                <span
                    className={classnames(
                        classes.originlibraryName,
                        'wk-text-14 color-$wk-v2-label-color-gray-13 truncate'
                    )}
                >
                    {props.docName || translation('Unknown')}
                </span>
                <SwitchTargetLibrary
                    libraryId={props.libraryId}
                    documentId={props.documentId}
                    selectedTargetName={selectedTargetName}
                    setSelectedTargetName={setSelectedTargetName}
                />
            </div>
            <WKDivider className={commonClasses.divider} />
            <Scrollbar className={classnames(commonClasses.scrollWrapper, classes.scrollWrapper)} ref={containerRef}>
                <LibraryComponentListLayout
                    maxChildrenLength={undefined}
                    className="relative"
                    style={{ height: libraryReplaceModalViewState?.containerHeight }}
                >
                    {libraryReplaceModalViewState?.displayList.map((displayItem) => (
                        <LibraryReplaceDisplayItem
                            key={displayItem.displayType + '-' + displayItem.publishId + '-' + displayItem.remoteId}
                            displayItem={displayItem}
                            selectTargetLibrary={!!selectedTargetName}
                        />
                    ))}
                </LibraryComponentListLayout>
            </Scrollbar>
            <div
                className={classnames(
                    commonClasses.footerWrapper,
                    classes.footerWrapper,
                    'flex',
                    'justify-end',
                    'items-center'
                )}
            >
                <WKButton
                    type="secondary"
                    disabled={!libraryReplaceModalViewState?.enableSubmit}
                    onClick={startReplace}
                    data-testid={LibraryTestId.ReplaceModal.SubmitBtn}
                >
                    {translation('SwapLibrary_synonyms1')}
                </WKButton>
            </div>
        </div>
    )
}

// 切换替换目标库
function SwitchTargetLibrary(props: UseSwitchTargetLibraryProps & { selectedTargetName: string | undefined }) {
    const { menuConfig, loading, disabled } = useSwitchTargetLibrary(props)

    const { onChange } = useMenu()
    return loading ? (
        <div>
            <WKLoading noText />
        </div>
    ) : (
        <Select.MinimalMultiLevel
            placement="bottom left"
            label={
                <span
                    className={classnames(
                        'wk-text-14 truncate',
                        disabled ? 'color-$wk-v2-label-color-gray-6' : 'color-$wk-v2-label-color-gray-13'
                    )}
                >
                    {props.selectedTargetName || translation('ChooseLibrary')}
                </span>
            }
            items={transformMenuConfig(menuConfig)}
            onChange={onChange}
            dataTestIds={{
                triggerFocus: LibraryTestId.ReplaceModal.TargetSwitchBtn,
            }}
        />
    )
}
