import { useEffect } from 'react'
import style from './auto-web-ui-samples.module.less'
import { translation } from './auto-web-ui-samples.translation'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'
import { generateColorConfig, generateCornerSize, ThemeProps } from './util'

export function AutoWebUISample() {
    const { tempRemixConfig, getFontFace } = usePlatformAndDesignSystemInContext()

    const primaryColor = tempRemixConfig?.get('primaryColor') ?? '#1677FF'
    const mode = tempRemixConfig?.get('mode') as 'light' | 'dark' | undefined
    const themeConfig = generateColorConfig(primaryColor, mode)

    const font = tempRemixConfig?.get('font') ?? 'Roboto'
    const cornerSize = tempRemixConfig?.get('cornerSize') ?? '4px'
    const cornerSizeConfig = generateCornerSize(cornerSize)

    useEffect(() => {
        getFontFace()
            .then((fontFace) => {
                if (fontFace) {
                    document.fonts.add(fontFace)
                }
            })
            .catch(() => {
                // Ignore Font Load Error
            })
    }, [getFontFace])

    return (
        <div
            style={
                {
                    pointerEvents: 'none',
                    transform: 'scale(0.3875)',
                    '--wk-auto-web-ui-font-family': font,
                    '--wk-auto-web-ui-corner-size-full': `${cornerSizeConfig.full}px`,
                    '--wk-auto-web-ui-corner-size-2xl': `${cornerSizeConfig['2xl']}px`,
                    '--wk-auto-web-ui-corner-size-xl': `${cornerSizeConfig.xl}px`,
                    '--wk-auto-web-ui-corner-size-lg': `${cornerSizeConfig.lg}px`,
                    '--wk-auto-web-ui-corner-size-md': `${cornerSizeConfig.md}px`,
                    '--wk-auto-web-ui-corner-size-sm': `${cornerSizeConfig.sm}px`,
                    '--wk-auto-web-ui-corner-size-xs': `${cornerSizeConfig.xs}px`,
                } as any
            }
            className={style['auto-web-ui-sample']}
        >
            <AutoWebUISampleImpl {...themeConfig} />
        </div>
    )
}

function AutoWebUISampleImpl({
    primaryColor = '#1677FF',
    onPrimaryColor = 'white',
    backgroundColor = 'white',
    surfaceColor = 'white',
    textPrimaryColor = '#1A1A1A',
    textSecondaryColor = '#666666',
    dividerColor = '#f3f3f3',
}: ThemeProps = {}) {
    const theme = {
        primary: primaryColor,
        onPrimary: onPrimaryColor,
        background: backgroundColor,
        surface: surfaceColor,
        textPrimary: textPrimaryColor,
        textSecondary: textSecondaryColor,
        divider: dividerColor,
    }

    return (
        <div
            id="root"
            style={{
                borderRadius: '10.5px',
                boxShadow: '0 0 0 5.26px var(--wk-gray-13-8)',
                overflow: 'hidden',
            }}
        >
            <div className="w-[1440px] min-h-[900px] mx-auto bg-white" style={{ backgroundColor: theme.background }}>
                <header className="h-16 px-6 border-b" style={{ borderColor: theme.divider }}>
                    <div className="h-full flex items-center justify-between">
                        <div className="flex items-center space-x-8">
                            <div className="flex items-center space-x-2">
                                <img
                                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=32&amp;h=32&amp;fit=crop"
                                    alt="Profile"
                                    className="w-8 h-8 rounded-full"
                                />
                                <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                    {translation('Olivia Martin')}
                                </span>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="48"
                                        d="M112 184l144 144 144-144"
                                    ></path>
                                </svg>
                            </div>
                            <nav className="flex items-center space-x-6">
                                <a href="#" style={{ color: theme.primary }} className="font-medium">
                                    {translation('Overview')}
                                </a>
                                <a href="#" style={{ color: theme.textSecondary }}>
                                    {translation('Customers')}
                                </a>
                                <a href="#" style={{ color: theme.textSecondary }}>
                                    {translation('Products')}
                                </a>
                                <a href="#" style={{ color: theme.textSecondary }}>
                                    {translation('Settings')}
                                </a>
                            </nav>
                        </div>
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder={translation('Search...')}
                                    className="b-0 w-64 h-9 pl-9 pr-4 rounded-[var(--wk-auto-web-ui-corner-size-md)]"
                                    style={{
                                        backgroundColor: theme.background === 'white' ? '#f3f3f3' : theme.surface,
                                    }}
                                />
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                                    ></path>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                        d="M338.29 338.29L448 448"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="p-8">
                    <div className="flex items-center justify-between mb-8">
                        <h1 className="text-2xl font-bold" style={{ color: theme.textPrimary }}>
                            {translation('Dashboard')}
                        </h1>
                        <div className="flex items-center space-x-4">
                            <button
                                className="flex items-center space-x-2 px-4 py-2 border-0 rounded-[var(--wk-auto-web-ui-corner-size-md)]"
                                style={{
                                    background: backgroundColor,
                                    border: '1px solid',
                                    borderColor: theme.divider,
                                    color: theme.textPrimary,
                                }}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="416"
                                        height="384"
                                        x="48"
                                        y="80"
                                        fill="none"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        rx="48"
                                    ></rect>
                                    <circle cx="296" cy="232" r="24"></circle>
                                    <circle cx="376" cy="232" r="24"></circle>
                                    <circle cx="296" cy="312" r="24"></circle>
                                    <circle cx="376" cy="312" r="24"></circle>
                                    <circle cx="136" cy="312" r="24"></circle>
                                    <circle cx="216" cy="312" r="24"></circle>
                                    <circle cx="136" cy="392" r="24"></circle>
                                    <circle cx="216" cy="392" r="24"></circle>
                                    <circle cx="296" cy="392" r="24"></circle>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M128 48v32m256-32v32"
                                    ></path>
                                    <path fill="none" strokeLinejoin="round" strokeWidth="32" d="M464 160H48"></path>
                                </svg>
                                <span>{translation('Jan 20, 2023 - Feb 09, 2023')}</span>
                            </button>
                            <button
                                className="flex items-center space-x-2 px-4 py-2 rounded-[var(--wk-auto-web-ui-corner-size-md)] border-0"
                                style={{ backgroundColor: theme.primary, color: theme.onPrimary }}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40"
                                    ></path>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M176 272l80 80 80-80M256 48v288"
                                    ></path>
                                </svg>
                                <span>{translation('Download')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex space-x-1 mb-8">
                        <button
                            className="px-4 py-2 rounded-[var(--wk-auto-web-ui-corner-size-md)] font-medium border-0"
                            style={{
                                backgroundColor: `${theme.primary}0D`,
                                color: theme.primary,
                            }}
                        >
                            {translation('Overview')}
                        </button>
                        <button
                            className="px-4 py-2 border-0"
                            style={{
                                backgroundColor: 'transparent',
                                color: theme.textPrimary,
                            }}
                        >
                            {translation('Analytics')}
                        </button>
                        <button
                            className="px-4 py-2 border-0"
                            style={{
                                backgroundColor: 'transparent',
                                color: theme.textPrimary,
                            }}
                        >
                            {translation('Reports')}
                        </button>
                        <button
                            className="px-4 py-2 border-0"
                            style={{
                                backgroundColor: 'transparent',
                                color: theme.textPrimary,
                            }}
                        >
                            {translation('Notifications')}
                        </button>
                    </div>
                    <div className="grid grid-cols-4 gap-6 mb-8">
                        <div
                            className="p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="m-0 text-sm font-medium" style={{ color: theme.textSecondary }}>
                                    {translation('Total Revenue')}
                                </h3>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="448"
                                        height="256"
                                        x="32"
                                        y="80"
                                        fill="none"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        rx="16"
                                        ry="16"
                                        transform="rotate(180 256 208)"
                                    ></rect>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M64 384h384M96 432h320"
                                    ></path>
                                    <circle
                                        cx="256"
                                        cy="208"
                                        r="80"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></circle>
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M480 160a80 80 0 01-80-80M32 160a80 80 0 0080-80m368 176a80 80 0 00-80 80M32 256a80 80 0 0180 80"
                                    ></path>
                                </svg>
                            </div>
                            <div className="space-y-1">
                                <p className="m-0 text-2xl font-bold" style={{ color: theme.textPrimary }}>
                                    {translation('$45,231.89')}
                                </p>
                                <p className="m-0 text-sm text-green-600">{translation('+20.1% from last month')}</p>
                            </div>
                        </div>
                        <div
                            className="p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="m-0 text-sm font-medium" style={{ color: theme.textSecondary }}>
                                    {translation('Subscriptions')}
                                </h3>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                    ></path>
                                    <path
                                        fill="none"
                                        strokeMiterlimit="10"
                                        strokeWidth="32"
                                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                    ></path>
                                </svg>
                            </div>
                            <div className="space-y-1">
                                <p className="m-0 text-2xl font-bold" style={{ color: theme.textPrimary }}>
                                    +2350
                                </p>
                                <p className="m-0 text-sm text-green-600">{translation('+180.1% from last month')}</p>
                            </div>
                        </div>
                        <div
                            className="p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="m-0 text-sm font-medium" style={{ color: theme.textSecondary }}>
                                    {translation('Sales')}
                                </h3>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="416"
                                        height="320"
                                        x="48"
                                        y="96"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        rx="56"
                                        ry="56"
                                    ></rect>
                                    <path
                                        fill="none"
                                        strokeLinejoin="round"
                                        strokeWidth="60"
                                        d="M48 192h416M128 300h48v20h-48z"
                                    ></path>
                                </svg>
                            </div>
                            <div className="space-y-1">
                                <p className="m-0 text-2xl font-bold" style={{ color: theme.textPrimary }}>
                                    +12,234
                                </p>
                                <p className="m-0 text-sm text-green-600">{translation('+19% from last month')}</p>
                            </div>
                        </div>
                        <div
                            className="p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="m-0 text-sm font-medium" style={{ color: theme.textSecondary }}>
                                    {translation('Active Now')}
                                </h3>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-4 h-4"
                                    style={{ color: theme.textSecondary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                        d="M48 320h64l64-256 64 384 64-224 32 96h64"
                                    ></path>
                                    <circle
                                        cx="432"
                                        cy="320"
                                        r="32"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="32"
                                    ></circle>
                                </svg>
                            </div>
                            <div className="space-y-1">
                                <p className="m-0 text-2xl font-bold" style={{ color: theme.textPrimary }}>
                                    +573
                                </p>
                                <p className="m-0 text-sm text-green-600">{translation('+201 since last hour')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-6">
                        <div
                            className="col-span-2 p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <h3 className="text-lg font-semibold mb-6" style={{ color: theme.textPrimary }}>
                                {translation('Overview')}
                            </h3>
                            <div className="h-[300px]">
                                <div className="relative h-full">
                                    <div className="absolute inset-0">
                                        <div
                                            className="absolute left-0 top-0 h-full flex flex-col justify-between text-sm"
                                            style={{ color: theme.textSecondary }}
                                        >
                                            <span>$6000</span>
                                            <span>$4500</span>
                                            <span>$3000</span>
                                            <span>$1500</span>
                                            <span>$0</span>
                                        </div>
                                        <div className="absolute left-16 right-0 top-0 h-full flex items-end">
                                            <div className="flex-1 mx-1" style={{ height: '75%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '25%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '46.666666666666664%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '16.666666666666664%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '25%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '80%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '70%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '20%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '53.333333333333336%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '33.33333333333333%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '58.333333333333336%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                            <div className="flex-1 mx-1" style={{ height: '30%' }}>
                                                <div
                                                    className="h-full"
                                                    style={{ backgroundColor: theme.primary }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div
                                            className="absolute left-16 right-0 bottom-[-1.5rem] flex text-sm"
                                            style={{ color: theme.textSecondary }}
                                        >
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Jan')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Feb')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Mar')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Apr')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('May')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Jun')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Jul')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Aug')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Sep')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Oct')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Nov')}</span>
                                            </div>
                                            <div className="flex-1 mx-1 text-center">
                                                <span>{translation('Dec')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="p-6 rounded-[var(--wk-auto-web-ui-corner-size-lg)]"
                            style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: theme.divider }}
                        >
                            <h3 className="text-lg font-semibold mb-2" style={{ color: theme.textPrimary }}>
                                {translation('Recent Sales')}
                            </h3>
                            <p className="text-sm mb-6" style={{ color: theme.textSecondary }}>
                                {translation('You made 265 sales this month.')}
                            </p>
                            <div className="space-y-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=32&amp;h=32&amp;fit=crop"
                                            alt="Olivia Martin"
                                            className="w-8 h-8 rounded-full"
                                        />
                                        <div>
                                            <p className="m-0 font-medium" style={{ color: theme.textPrimary }}>
                                                {translation('Olivia Martin')}
                                            </p>
                                            <p className="m-0 text-sm" style={{ color: theme.textSecondary }}>
                                                {translation('olivia.martin@email.com')}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                        {translation('+$1,999.00')}
                                    </span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src="https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=32&amp;h=32&amp;fit=crop"
                                            alt="Jackson Lee"
                                            className="w-8 h-8 rounded-full"
                                        />
                                        <div>
                                            <p className="m-0 font-medium" style={{ color: theme.textPrimary }}>
                                                {translation('Jackson Lee')}
                                            </p>
                                            <p className="m-0 text-sm" style={{ color: theme.textSecondary }}>
                                                {translation('jackson.lee@email.com')}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                        {translation('+$39.00')}
                                    </span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?w=32&amp;h=32&amp;fit=crop"
                                            alt="Isabella Nguyen"
                                            className="w-8 h-8 rounded-full"
                                        />
                                        <div>
                                            <p className="m-0 font-medium" style={{ color: theme.textPrimary }}>
                                                {translation('Isabella Nguyen')}
                                            </p>
                                            <p className="m-0 text-sm" style={{ color: theme.textSecondary }}>
                                                {translation('isabella.nguyen@email.com')}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                        {translation('+$299.00')}
                                    </span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=32&amp;h=32&amp;fit=crop"
                                            alt="William Kim"
                                            className="w-8 h-8 rounded-full"
                                        />
                                        <div>
                                            <p className="m-0 font-medium" style={{ color: theme.textPrimary }}>
                                                {translation('William Kim')}
                                            </p>
                                            <p className="m-0 text-sm" style={{ color: theme.textSecondary }}>
                                                {translation('will@email.com')}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                        {translation('+$99.00')}
                                    </span>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <img
                                            src="https://images.unsplash.com/photo-1580489944761-15a19d654956?w=32&amp;h=32&amp;fit=crop"
                                            alt="Sofia Davis"
                                            className="w-8 h-8 rounded-full"
                                        />
                                        <div>
                                            <p className="m-0 font-medium" style={{ color: theme.textPrimary }}>
                                                {translation('Sofia Davis')}
                                            </p>
                                            <p className="m-0 text-sm" style={{ color: theme.textSecondary }}>
                                                {translation('sofia.davis@email.com')}
                                            </p>
                                        </div>
                                    </div>
                                    <span className="font-semibold" style={{ color: theme.textPrimary }}>
                                        {translation('+$39.00')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
