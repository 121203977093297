import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'ai2',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['fa41bf23ad54135e8310a19dcc012fbfb70d29f2', '8924f0f3c2562122c6dce85e95a7e585a82ba76c', '4ec1663a76757c645d04b63c612a84e2a6c4e38b'],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
