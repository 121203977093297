import { useEffect } from 'react'
import { MaterialDesignTheme, materialDesignThemes } from './material-design-themes'
import style from './material-ui-samples.module.less'
import { translation } from './material-ui-samples.translation'
import { usePlatformAndDesignSystemInContext } from './use-platform-and-design-system-context'

export function MaterialUISample() {
    const { currentMaterialDesignTheme, tempRemixConfig, getFontFace } = usePlatformAndDesignSystemInContext()

    const font = tempRemixConfig?.get('font') ?? 'Roboto'

    useEffect(() => {
        getFontFace()
            .then((fontFace) => {
                if (fontFace) {
                    document.fonts.add(fontFace)
                }
            })
            .catch(() => {
                // Ignore Font Load Error
            })
    }, [getFontFace])

    return (
        <div style={{ transform: 'scale(0.46)' }}>
            <MaterialUIComponents
                theme={currentMaterialDesignTheme() ?? materialDesignThemes['#B33B15'].light}
                fontName={font}
            />
        </div>
    )
}

export function MaterialUIComponents({ theme, fontName }: { theme: MaterialDesignTheme; fontName: string }) {
    return (
        <div
            id="root"
            className={style['material-ui-sample']}
            style={
                {
                    '--wk-material-ui-font-family': fontName,
                    pointerEvents: 'none',
                    borderRadius: '24px',
                    border: '4px solid rgba(26, 29, 37, 0.08)',
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                } as any
            }
        >
            <div id="root">
                <div className={`w-[390px] min-h-[844px]`} style={{ backgroundColor: theme.background }}>
                    <div
                        className="h-11 px-5 flex items-center justify-between"
                        style={{ backgroundColor: theme.background }}
                    >
                        <span className="text-sm font-medium" style={{ color: theme.onBackground }}>
                            {translation('9:41')}
                        </span>
                        <div className="flex items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <defs>
                                    <clipPath id="clipPath1934745087a">
                                        <path
                                            transform="matrix(1 0 0 1 0 0)"
                                            d="M0 0L16 0L16 16L0 16L0 0Z"
                                            fillRule="nonzero"
                                        />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#clipPath1934745087)">
                                    <defs>
                                        <clipPath id="clipPath1207315726a">
                                            <path
                                                transform="matrix(1 0 0 1 0 0)"
                                                d="M0 0L16 0L16 16L0 16L0 0Z"
                                                fillRule="nonzero"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g clipPath="url(#clipPath1207315726)">
                                        <path
                                            transform="matrix(1 0 0 1 1.33333 1.33333)"
                                            d="M0 13.3333L13.3333 13.3333L13.3333 0L0 13.3333Z"
                                            fillRule="nonzero"
                                            fill="rgb(30, 26, 32)"
                                        />
                                    </g>
                                </g>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <defs>
                                    <clipPath id="clipPath1212270331a">
                                        <path
                                            transform="matrix(1 0 0 1 0 0)"
                                            d="M0 0L16 0L16 16L0 16L0 0Z"
                                            fillRule="nonzero"
                                        />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#clipPath1212270331)">
                                    <defs>
                                        <clipPath id="clipPath5595679699a">
                                            <path
                                                transform="matrix(1 0 0 1 0 0)"
                                                d="M0 0L16 0L16 16L0 16L0 0Z"
                                                fillRule="nonzero"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g clipPath="url(#clipPath5595679699)">
                                        <path
                                            transform="matrix(1 0 0 1 0.666667 2.965)"
                                            d="M0 3.035L1.33333 4.36833C4.64667 1.055 10.02 1.055 13.3333 4.36833L14.6667 3.035C10.62 -1.01167 4.05333 -1.01167 0 3.035ZM5.33333 8.36833L7.33333 10.3683L9.33333 8.36833C8.78148 7.81501 8.11481 7.53834 7.33333 7.53835C6.55185 7.53834 5.88519 7.81501 5.33333 8.36833ZM2.66667 5.70167L4 7.035C4.9207 6.11506 6.03181 5.6551 7.33333 5.6551C8.63486 5.6551 9.74597 6.11506 10.6667 7.035L12 5.70167C9.42667 3.12833 5.24667 3.12833 2.66667 5.70167Z"
                                            fillRule="nonzero"
                                            fill="rgb(30, 26, 32)"
                                        />
                                    </g>
                                </g>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <defs>
                                    <clipPath id="clipPath1810107091a">
                                        <path
                                            transform="matrix(1 0 0 1 0 0)"
                                            d="M0 0L16 0L16 16L0 16L0 0Z"
                                            fillRule="nonzero"
                                        />
                                    </clipPath>
                                </defs>
                                <g clipPath="url(#clipPath1810107091)">
                                    <defs>
                                        <clipPath id="clipPath0344990231a">
                                            <path
                                                transform="matrix(1 0 0 1 0 0)"
                                                d="M0 0L16 0L16 16L0 16L0 0Z"
                                                fillRule="nonzero"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g clipPath="url(#clipPath0344990231)">
                                        <path
                                            transform="matrix(1 0 0 1 4.66667 1.33333)"
                                            d="M6.66667 2.22C6.66667 1.73333 6.26667 1.33333 5.78 1.33333L4.66667 1.33333L4.66667 0L2 0L2 1.33333L0.886667 1.33333C0.4 1.33333 0 1.73333 0 2.22L0 4L6.66667 4L6.66667 2.22Z"
                                            fillRule="nonzero"
                                            fill="rgb(30, 26, 32)"
                                            fillOpacity="0.3"
                                        />
                                        <path
                                            transform="matrix(1 0 0 1 4.66667 5.33333)"
                                            d="M0 0L0 8.44667C0 8.93333 0.4 9.33333 0.886667 9.33333L5.77333 9.33333C6.26667 9.33333 6.66667 8.93333 6.66667 8.44667L6.66667 0L0 0Z"
                                            fillRule="nonzero"
                                            fill="rgb(30, 26, 32)"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="h-[758px] overflow-hidden">
                        <div className="px-5 pt-4">
                            <h1 className="text-[28px] font-medium" style={{ color: theme.onBackground }}>
                                {translation('Good Morning')}
                            </h1>
                            <div className="flex items-center gap-2 mt-1">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-5 h-5"
                                    style={{ color: theme.primary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"></path>
                                </svg>
                                <span className="text-base font-regular" style={{ color: theme.primary }}>
                                    {translation('Sunny, 68°F')}
                                </span>
                            </div>
                        </div>
                        <div
                            className="mx-5 mt-6 p-5 rounded-2xl flex justify-between gap-2"
                            style={{ backgroundColor: theme.surfaceContainer }}
                        >
                            <div className="flex-1">
                                <h2 className="text-2xl font-medium m-0" style={{ color: theme.onBackground }}>
                                    {translation('Your Morning Mix')}
                                </h2>
                                <p className="text-base font-regular m-0" style={{ color: theme.onSurfaceVariant }}>
                                    {translation('Energetic tunes to start your day')}
                                </p>
                                <button
                                    className="mt-4 px-6 py-2 rounded-full flex items-center gap-2 border-0"
                                    style={{ backgroundColor: theme.primary, color: theme.onPrimary }}
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 24 24"
                                        className="w-5 h-5"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M8 5v14l11-7z"></path>
                                    </svg>
                                    <span className="font-medium">{translation('Play Now')}</span>
                                </button>
                            </div>
                            <div className="w-24 h-24 rounded-xl overflow-hidden">
                                <img
                                    src="https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=200&amp;h=200"
                                    alt="Concert"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                        <div className="mt-8 px-5">
                            <h2 className="text-xl font-medium" style={{ color: theme.onBackground }}>
                                {translation('Recently Played')}
                            </h2>
                            <div className="mt-4 flex gap-4 overflow-hidden no-scrollbar">
                                <div className="w-[120px] flex-shrink-0">
                                    <div className="w-[120px] h-[120px] rounded-xl overflow-hidden">
                                        <img
                                            src="https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?w=150&amp;h=150"
                                            alt="Morning Vibes"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3
                                        className="m-0 text-sm font-medium truncate"
                                        style={{ color: theme.onBackground }}
                                    >
                                        {translation('Morning Vibes')}
                                    </h3>
                                    <p className="text-xs font-regular m-0" style={{ color: theme.onSurfaceVariant }}>
                                        {translation('Various Artists')}
                                    </p>
                                </div>
                                <div className="w-[120px] flex-shrink-0">
                                    <div className="w-[120px] h-[120px] rounded-xl overflow-hidden">
                                        <img
                                            src="https://images.unsplash.com/photo-1506157786151-b8491531f063?w=150&amp;h=150"
                                            alt="Sunrise Session"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3
                                        className="m-0 text-sm font-medium truncate"
                                        style={{ color: theme.onBackground }}
                                    >
                                        {translation('Sunrise Session')}
                                    </h3>
                                    <p className="text-xs font-regular m-0" style={{ color: theme.onSurfaceVariant }}>
                                        {translation('Chill Mood')}
                                    </p>
                                </div>
                                <div className="w-[120px] flex-shrink-0">
                                    <div className="w-[120px] h-[120px] rounded-xl overflow-hidden">
                                        <img
                                            src="https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?w=150&amp;h=150"
                                            alt="Wake Up Mix"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3
                                        className="m-0 text-sm font-medium truncate"
                                        style={{ color: theme.onBackground }}
                                    >
                                        {translation('Wake Up Mix')}
                                    </h3>
                                    <p className="text-xs font-regular m-0" style={{ color: theme.onSurfaceVariant }}>
                                        {translation('Energy Mix')}
                                    </p>
                                </div>
                                <div className="w-[120px] flex-shrink-0">
                                    <div className="w-[120px] h-[120px] rounded-xl overflow-hidden">
                                        <img
                                            src="https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=150&amp;h=150"
                                            alt="Morning Acoustic"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <h3
                                        className="m-0 text-sm font-medium truncate"
                                        style={{ color: theme.onBackground }}
                                    >
                                        {translation('Morning Acoustic')}
                                    </h3>
                                    <p className="text-xs font-regular m-0" style={{ color: theme.onSurfaceVariant }}>
                                        {translation('Acoustic Vibes')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="px-5 mt-8">
                            <h2 className="text-xl font-medium" style={{ color: theme.onBackground }}>
                                {translation('Recommended for Morning')}
                            </h2>
                            <div className="mt-4 space-y-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div className="w-14 h-14 rounded-xl overflow-hidden">
                                            <img
                                                src="https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=150&amp;h=150"
                                                alt="Morning Energy Boost"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div>
                                            <h3
                                                className="m-0 text-base font-medium"
                                                style={{ color: theme.onBackground }}
                                            >
                                                {translation('Morning Energy Boost')}
                                            </h3>
                                            <p
                                                className="m-0 text-sm font-regular"
                                                style={{ color: theme.onSurfaceVariant }}
                                            >
                                                {translation('Workout Mix')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span
                                            className="text-sm font-regular"
                                            style={{ color: theme.onSurfaceVariant }}
                                        >
                                            3:45
                                        </span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="w-8 h-8"
                                            style={{ color: theme.primary }}
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5-7 4.5z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div className="w-14 h-14 rounded-xl overflow-hidden">
                                            <img
                                                src="https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?w=150&amp;h=150"
                                                alt="Peaceful Awakening"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div>
                                            <h3
                                                className="m-0 text-base font-medium"
                                                style={{ color: theme.onBackground }}
                                            >
                                                {translation('Peaceful Awakening')}
                                            </h3>
                                            <p
                                                className="m-0 text-sm font-regular"
                                                style={{ color: theme.onSurfaceVariant }}
                                            >
                                                {translation('Meditation Sounds')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span
                                            className="text-sm font-regular"
                                            style={{ color: theme.onSurfaceVariant }}
                                        >
                                            4:20
                                        </span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="w-8 h-8"
                                            style={{ color: theme.primary }}
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5-7 4.5z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div className="w-14 h-14 rounded-xl overflow-hidden">
                                            <img
                                                src="https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?w=150&amp;h=150"
                                                alt="Sunrise Acoustic"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                        <div>
                                            <h3
                                                className="m-0 text-base font-medium"
                                                style={{ color: theme.onBackground }}
                                            >
                                                {translation('Sunrise Acoustic')}
                                            </h3>
                                            <p
                                                className="m-0 text-sm font-regular"
                                                style={{ color: theme.onSurfaceVariant }}
                                            >
                                                {translation('Morning Playlist')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span
                                            className="text-sm font-regular"
                                            style={{ color: theme.onSurfaceVariant }}
                                        >
                                            3:55
                                        </span>
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 24 24"
                                            className="w-8 h-8"
                                            style={{ color: theme.primary }}
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.5 16.5v-9l7 4.5-7 4.5z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="h-16 border-0"
                        style={{ backgroundColor: theme.surfaceContainer, borderColor: theme.outlineVariant }}
                    >
                        <div className="h-full flex items-center justify-around px-4">
                            <button className="flex flex-col items-center gap-1 bg-transparent border-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6"
                                    style={{ color: theme.primary }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
                                </svg>
                                <span className="text-xs font-regular" style={{ color: theme.primary }}>
                                    {translation('Home')}
                                </span>
                            </button>
                            <button className="flex flex-col items-center gap-1 bg-transparent border-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6"
                                    style={{ color: theme.onSurfaceVariant }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>
                                <span className="text-xs font-regular" style={{ color: theme.onSurfaceVariant }}>
                                    {translation('Search')}
                                </span>
                            </button>
                            <button className="flex flex-col items-center gap-1 bg-transparent border-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6"
                                    style={{ color: theme.onSurfaceVariant }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 5h-3v5.5a2.5 2.5 0 01-5 0 2.5 2.5 0 012.5-2.5c.57 0 1.08.19 1.5.51V5h4v2zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6z"></path>
                                </svg>
                                <span className="text-xs font-regular" style={{ color: theme.onSurfaceVariant }}>
                                    {translation('Library')}
                                </span>
                            </button>
                            <button className="flex flex-col items-center gap-1 bg-transparent border-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6"
                                    style={{ color: theme.onSurfaceVariant }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                                </svg>
                                <span className="text-xs font-regular" style={{ color: theme.onSurfaceVariant }}>
                                    {translation('Profile')}
                                </span>
                            </button>
                            <button className="flex flex-col items-center gap-1 bg-transparent border-0">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    className="w-6 h-6"
                                    style={{ color: theme.onSurfaceVariant }}
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                    <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32a.488.488 0 00-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"></path>
                                </svg>
                                <span className="text-xs font-regular" style={{ color: theme.onSurfaceVariant }}>
                                    {translation('Settings')}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div
                        className="h-[34px] w-full flex items-center justify-center"
                        style={{ backgroundColor: theme.surfaceContainer }}
                    >
                        <div
                            className="w-[134px] h-[5px] rounded-full"
                            style={{ backgroundColor: theme.onBackground }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
