import { isNil } from 'lodash-es'
import { WKToast } from '../../../ui-lib/src'
import { domLocation, RouteToken } from '../../../util/src'
import { environment } from '../environment'
import {
    compareSeatType,
    DocWithAuthorityVO,
    OrganizationVO,
    type OrgID,
    PayPlanType,
    PlanAndUserStateVO,
    ResourceType,
    RoleStatus,
    SeatType,
    SeatUpgradeType,
    TeamID,
    TeamWithAuthorityVO,
} from '../kernel/interface/type'
import { compareRole } from '../kernel/interface/user-roles'
import { GetOrganizations, QueryOrgAndUserState } from '../kernel/request/organizations'
import { GetTeamRequestV2, GetTeamResourceState, QueryTeamAndUserState } from '../kernel/request/team'
import { featureSwitchManager } from '../kernel/switch'
import { openIndependentWindow } from '../kernel/util/open-window'
import { PayUpgradeDialogFrom } from '../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { translation } from './payment.translation'
import { isActiveOrgPlan, isActiveProPlan } from './plan-status-checker'
export { UserSeatStatus }

enum UserSeatStatus {
    InGracePeriod = 'inGracePeriod', // 宽限期
    InAutoUpgrade = 'inAutoUpgrade', // 自动升级
    InPermanentSeat = 'inPermanentSeat', // 长期席位
    CanApplyGracePeriod = 'canApplyGracePeriod', // 可申请宽限期
    CanApplyAutoUpgrade = 'canApplyAutoUpgrade', // 可申请自动升级
    NeedWaitToBeApproved = 'needWaitToBeApproved', // 需要等待审批
    NeedReApply = 'needReApply', // 需要重新申请
}

/**
 * 获得用户在当前的付费状态下，与期望的席位之间的关系
 * @param {planAndUserState} 用户的付费以及席位信息
 * @param {expectedSeatType} 业务期望的席位
 * @returns
 */

export function getUserSeatStatus(planAndUserState: PlanAndUserStateVO, expectedSeatType: SeatType) {
    if (compareSeatType(planAndUserState.userSeatType, expectedSeatType) >= 0) {
        switch (planAndUserState.userSeatUpgradeType) {
            case SeatUpgradeType.Unknown: {
                return UserSeatStatus.InPermanentSeat // 用户是 expectedSeatType 的长期席位
            }
            case SeatUpgradeType.NeedApprove: {
                return UserSeatStatus.InGracePeriod // 用户是 expectedSeatType 的宽限期席位
            }
            case SeatUpgradeType.AutoUpgrade: {
                return UserSeatStatus.InAutoUpgrade // 用户是 expectedSeatType 的自动升级席位（可能在宽限期也可能宽限期已过）
            }
        }
    } else {
        if (planAndUserState.gracePeriod) {
            return planAndUserState.seatUpgradeType == SeatUpgradeType.NeedApprove
                ? UserSeatStatus.CanApplyGracePeriod // 企业配置了需要审批，用户有宽限期
                : UserSeatStatus.CanApplyAutoUpgrade //  企业配置了自动升级，用户有宽限期
        } else {
            // 特殊case: 用户在申请（研发席位）的宽限期内，再次申请（设计席位）,此时可申请，可继续使用宽限期
            if (
                expectedSeatType === SeatType.designer &&
                Number(new Date()) < Number(planAndUserState.userSeatGracePeriodEndTime)
            ) {
                return planAndUserState.seatUpgradeType == SeatUpgradeType.NeedApprove
                    ? UserSeatStatus.CanApplyGracePeriod
                    : UserSeatStatus.CanApplyAutoUpgrade
            }

            if (planAndUserState.userSeatUpgradeType == SeatUpgradeType.NeedApprove) {
                return UserSeatStatus.NeedWaitToBeApproved // 用户没有宽限期并且需要等待审批同意
            } else {
                const hasApplyed = planAndUserState.unresolvedApplicationSeatTypes.filter(
                    (seatType) => compareSeatType(seatType, expectedSeatType) >= 0
                ).length
                return hasApplyed
                    ? UserSeatStatus.NeedWaitToBeApproved // 用户被降级/拒绝后再次申请需要等待审批同意
                    : UserSeatStatus.NeedReApply // 用户没有宽限期，想要申请 expectedSeatType 就需要重新申请
            }
        }
    }
}
/**
 *
 * @param userSeatStatus 用户的席位状态
 * @returns 用户拥有该席位
 */

export function checkHasSeatByUserSeatStatus(userSeatStatus: UserSeatStatus): boolean {
    switch (userSeatStatus) {
        case UserSeatStatus.InGracePeriod:
        case UserSeatStatus.InAutoUpgrade:
        case UserSeatStatus.InPermanentSeat:
            return true
        case UserSeatStatus.CanApplyGracePeriod:
        case UserSeatStatus.CanApplyAutoUpgrade:
        case UserSeatStatus.NeedWaitToBeApproved:
        case UserSeatStatus.NeedReApply:
            return false
    }
}

/**
 * 获取用户在当前席位下能否正常使用研发模式：付费团队/企业下的研发席位用户可以正常使用研发模式
 * @param userSeatStatus 用户的席位
 * @returns
 */

export function getDevModeAccessibleByUserStatus(planAndUserState: PlanAndUserStateVO | undefined): boolean {
    const inActiveResource =
        planAndUserState && (isActiveOrgPlan(planAndUserState) || isActiveProPlan(planAndUserState))
    if (inActiveResource) {
        const userSeatStatus = getUserSeatStatus(planAndUserState!, SeatType.developer)
        return checkHasSeatByUserSeatStatus(userSeatStatus)
    } else {
        return false
    }
}

/**
 *
 * @param docId 文件 id
 * @param targetTeamId 目标团队 id
 * @returns
 */

export async function getPlanAndUserState(orgId: string, targetTeamId: string | undefined) {
    let planAndUserState: PlanAndUserStateVO | undefined

    if (orgId !== '-1') {
        planAndUserState = await new QueryOrgAndUserState(orgId).start()
    } else if (!isNil(targetTeamId)) {
        const plaAndUserStates = await new QueryTeamAndUserState().start()
        planAndUserState = plaAndUserStates.find(
            (plan) => plan.resourceType === ResourceType.Team && plan.resourceId === targetTeamId
        )
    }
    return planAndUserState
}
/**
 * 判断当前用户是否为「组织版的管理员」 / 「团队版下团队的管理员 / 所有者」 ? 成员 ？ 访客
 * @param orgId 组织 ID
 * @param teamId 团队 ID
 * @returns
 */
export enum UserRole {
    Admin = 'Admin',
    Member = 'Member',
    Guest = 'Guest',
}

export async function getUserRole(
    orgId: string,
    teamId: string,
    sync?: { organizationVO: OrganizationVO | undefined; teamWithAuthorityVO: TeamWithAuthorityVO | undefined }
) {
    if (orgId == '-1' && teamId != '') {
        const teamVO = sync?.teamWithAuthorityVO ?? (await new GetTeamRequestV2(teamId).start())
        return compareRole(teamVO.role, RoleStatus.Admin) >= 0 ? UserRole.Admin : UserRole.Member
    } else {
        // 获取用户在企业内的信息
        const orgVO =
            sync?.organizationVO ??
            (await new GetOrganizations().start().then((orgs) => orgs.find((org) => org.id === orgId)))
        if (isNil(orgVO)) {
            return UserRole.Guest
        }
        return orgVO?.role == RoleStatus.Admin ? UserRole.Admin : !orgVO?.guest ? UserRole.Member : UserRole.Guest
    }
}

export function checkUserIsAdminInPayment(planAndUserState: PlanAndUserStateVO) {
    const userRole = planAndUserState.userRoleInResource
    if (planAndUserState.resourceType == ResourceType.Organization) {
        return userRole == RoleStatus.Admin
    } else {
        return compareRole(userRole, RoleStatus.Admin) >= 0
    }
}

/**
 *
 * @param docs 移动的文件
 * @returns 若移动的文件来自同一文件夹，返回 FolderId，否则 ''
 */

export function getFolderIdOfMoveDocs(docs: DocWithAuthorityVO[]) {
    if (!docs.length) {
        const folderId = docs[0].folderId
        for (let i = 1; i < docs.length; i++) {
            if (docs[i].folderId != folderId) {
                return ''
            }
        }
    }
    return ''
}

export async function checkPaymentIsUpdatingByDocs(teamIds: string[]) {
    const plans = await new QueryTeamAndUserState().start()
    return plans.some((plan) => teamIds.includes(plan.resourceId) && plan.planUpdating)
}

export function planUpdatingToast(teamName: string) {
    WKToast.show(translation('AdminIsUpdating', { teamName }))
}

export async function checkUsageExceedInFreeTeam(freeTeamToDocCount: Map<string, number>) {
    let exceedLimitTeamId: string | undefined = undefined

    for (const [teamId, teamDocs] of freeTeamToDocCount) {
        const resourceState = await new GetTeamResourceState(teamId).start()
        if (teamDocs + resourceState.docCount > 3) {
            if (isNil(exceedLimitTeamId)) {
                exceedLimitTeamId = teamId
            } else {
                return { exceed: true, teamId: undefined }
            }
        }
    }
    return { exceed: !isNil(exceedLimitTeamId), teamId: exceedLimitTeamId }
}

export function getPayUpgradeDialogFrom() {
    return domLocation().pathname.includes(`/${RouteToken.OrganizationWithoutOrgId}`)
        ? PayUpgradeDialogFrom.Space
        : PayUpgradeDialogFrom.Editor
}

export function navigateToPricing() {
    openIndependentWindow(`${environment.host}/pricing`)
}

const DAYS_7 = 7 * 24 * 60 * 60 * 1000 // 7 天

// 检查试用是否即将到期
export function checkTrialNearlyExpire(props: {
    orgId: OrgID
    teamId: TeamID | undefined
    planState: Pick<PlanAndUserStateVO, 'trial' | 'trialEndTime' | 'planType'> | undefined
}): boolean {
    // 到期前 7 天内保持显示
    const trialRemainingTime = props.planState?.trialEndTime ? props.planState.trialEndTime - Date.now() : 0
    return (
        !!props.planState?.trial &&
        trialRemainingTime > 0 &&
        trialRemainingTime <= DAYS_7 &&
        ((props.planState.planType === PayPlanType.professional && !!props.teamId && props.orgId === '-1') ||
            ((props.planState.planType === PayPlanType.organization ||
                (featureSwitchManager.isEnabled('organization-plus') &&
                    props.planState.planType === PayPlanType.enterprise)) &&
                props.orgId !== '-1' &&
                !props.teamId))
    )
}
