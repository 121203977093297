/* eslint-disable no-restricted-imports */
import { ReplayServiceReplay } from '@wukong/bridge-proto'
import { createRoot } from 'react-dom/client'
import { domLocation, generateRouterPath, isCypress, sleep } from '../../../../../util/src'
import { ClassWithEffect, EffectController } from '../../../../../util/src/effect-controller'
import { environment } from '../../../environment'
import { generateUniqId } from '../../../fake-server/fake-http-handlers/utils/id'
import { CrashData } from '../../../kernel/bridge/bridge'
import { EmBridge } from '../../../kernel/bridge/em-bridge'
import { WkCLog } from '../../../kernel/clog/wukong/instance'
import { getReplayDownloadUrl } from '../../../kernel/interface/replay'
import { memoryReportService } from '../../../kernel/memory-report'
import { MetricCollector } from '../../../kernel/metric-collector'
import { markRecordingAsUserReported } from '../../../kernel/recording/upload'
import { disableSentry, Sentry } from '../../../kernel/sentry'
import { featureSwitchManager } from '../../../kernel/switch/core'
import { CrashType, CrashType2Label } from '../../../kernel/util/crash'
import { GPUService, ShouldReloadResult } from '../../../main/gpu/gpu-service'
import { LocalStorageKey } from '../../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../../web-storage/local-storage/storage'
import { SessionStorageKey } from '../../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../../web-storage/session-storage/storage'
import { showMemoryAlertDialog } from '../memory-usage/show-memory-alert-dialog'
import { CrashReportOption } from './types'
import { CrashPanel } from './ui/crash-panel'

export class ReplayService extends ClassWithEffect {
    private crashSubscription?: () => void

    constructor(private readonly bridge: EmBridge, controller: EffectController) {
        super(controller)
        bridge.bind(ReplayServiceReplay, this.replay.bind(this))
        this.crashSubscription = bridge.states.subscribe((states) => states.crashed, this.handleWasmCrash.bind(this))
    }

    /**
     * wasm crash 后的回调
     * @param crashData
     * @returns
     */
    private async handleWasmCrash(crashData: CrashData | null): Promise<void> {
        if (!crashData) {
            return
        }

        // 如果是导入打开的文件，导入失败时向 iframe 容器发送消息
        this.reportImportFailedIfNeeded()

        // 尝试重启 webql，如果重启成功则回收错误
        if (this.tryReloadIfGpuLost()) {
            return
        }

        // 用于表示此次 crash 的唯一 id
        const crashId = generateUniqId()
        const { error, recordingName } = crashData
        const crashType = CrashType2Label[crashData.crashType]
        const OOM = crashData.crashType === CrashType.OUT_OF_MEMORY

        const reportMetricPromise = MetricCollector.onCrash()

        let memoryReportPromise

        // 内存溢出时，上报一次消息，用于图表化用户的内存使用信息
        if (OOM) {
            memoryReportPromise = WkCLog.log(
                'motiff_memory_report',
                memoryReportService.getReport().map((report) => ({ ...report, crashId }))
            )
            memoryReportService.clear()
        } else {
            memoryReportPromise = Promise.resolve(true)
        }

        // 在弹窗告知用户 crash 前上报 sentry
        const sentryEventId = Sentry.captureException(error, {
            extra: {
                replayDownloadUrl: recordingName ? getReplayDownloadUrl(recordingName) : undefined,
                memoryReportUrl: OOM
                    ? `https://octopus.zhenguanyu.com/#/dashboard/detail?duration=7d&env=${
                          environment.isProduction ? 'online' : 'test'
                      }&filters-map={"id":1555,"map":{"crashId":["${crashId}"]}}&id=1555`
                    : undefined,
            },
            tags: {
                crashType,
                wasmCrash: true,
                isMaintainer: featureSwitchManager.isCurrentUserMaintainer(),
            },
        })

        // crash 后不再上报 sentry
        disableSentry(0)

        if (OOM) {
            await Promise.all([reportMetricPromise, memoryReportPromise, sleep(3000)])

            showMemoryAlertDialog()
        } else {
            // 打开 crash 弹窗
            showCrashReportPanel({
                disableReload: true,
                // 给 sentry 一些上传 crash 的时间
                waitPromise: Promise.all([reportMetricPromise, memoryReportPromise, sleep(3000)]),
                onSendReport: async (userDesc) => {
                    try {
                        await markRecordingAsUserReported(recordingName, {
                            description: userDesc,
                            sentryEventId,
                            crashType,
                        })
                        // 后面的步骤是刷新窗口，从 db 里读出这里打的两个标，做对应的上传操作
                    } catch (e) {
                        Sentry.captureException(e)
                        console.error('failed to handle onSendReport', e)
                    }
                },
            })
        }
    }

    /**
     * 如果在导入 iframe 下，则向容器发送打开失败的消息
     */
    private reportImportFailedIfNeeded() {
        if (window.name === 'advance_render') {
            window.parent.postMessage({
                type: 'advance_render',
                success: false,
            })
        }
    }

    public replay() {
        const url = generateRouterPath(`workbench/recording-loader?recording=${this.bridge.currentRecordingName}`)
        if (isCypress()) {
            domLocation().href = `/${url}`
        } else {
            window.open(`/${url}`, '_blank')
        }
    }

    public destroy() {
        this.bridge.unbind(ReplayServiceReplay)
        this.crashSubscription?.()
    }

    public tryReloadIfGpuLost() {
        try {
            if (this.bridge.isGpuLost()) {
                // 不再上报 sentry
                disableSentry(0)
                const now = new Date().getTime()
                switch (GPUService.shouldReload(this.bridge.currentEditorService, now)) {
                    case ShouldReloadResult.ReloadByTime:
                        enhancedSessionStorage.setItem(SessionStorageKey.ReloadTimeKey, now.toString())
                        window.location.reload()
                        return true
                    case ShouldReloadResult.ReloadByWebGPU:
                        enhancedLocalStorage.setSerializedItem(LocalStorageKey.DisableWebGPU, `${Date.now()}`)
                        window.location.reload()
                        return true
                    case ShouldReloadResult.DoNotReload:
                        return false
                }
            }
        } catch (e) {
            console.error('failed to reload if WebGLContext Lost', e)
        }
        return false
    }
}

const CRASH_DOM_ID = 'wukong-crash-id'

function createDom(id: string) {
    const targetDom = document.createElement('div')
    targetDom.id = id
    document.body.appendChild(targetDom)

    return targetDom
}

/**
 * 唤起报障弹窗
 * @param option
 */
function showCrashReportPanel(option: CrashReportOption = {}) {
    const dom = createDom(CRASH_DOM_ID)
    const root = createRoot(dom)
    root.render(<CrashPanel {...option} />)

    const oldCb = window.onpopstate
    window.onpopstate = () => {
        root.unmount()
        window.onpopstate = oldCb
    }
}
