import {
    ExportImagePixels,
    ExportImageToCanvas,
    ExportPngToData,
    ExportToPDF,
    ExportToSvg,
    PreExportCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { base64ToBlob } from '../../../../../../util/src'
import { CanvasRenderBridge } from '../../../../document/bridge/canvas-render-bridge'
import { CommandInvoker } from '../../../../document/command/command-invoker'
import { ExportSettings, NodeId } from '../../../../document/node/node'
import { encodeAvif, encodeCompressPng } from '../../../../image-lib'
import { getImageLibContext, toColorSpace } from '../../../../image-lib/adapter'
import { getFirefoxVersion, getSafariVersion } from '../../../../kernel/util/ua'
import { PreExportService } from '../../../../main/service/pre-export-service'
import { RawMemAccessService } from '../../../../main/service/raw-mem-access-service'

import ExportSettingsConstraintsType = Wukong.DocumentProto.ExportConstraintType
import ExportFormatType = Wukong.DocumentProto.ExportFormatType
import ImageFormat = Wukong.DocumentProto.ImageFormat
import ExportImageArg = Wukong.DocumentProto.IArg_exportImage
import ExportConstraintByFloat = Wukong.DocumentProto.IExportConstraintByFloat
import DocumentColorProfile = Wukong.DocumentProto.DocumentColorProfile

export const constraintValueScale = 1000

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
export function isExportWebP(): boolean {
    if (getSafariVersion()) {
        return false
    }

    const firefoxVersion = getFirefoxVersion()
    if (firefoxVersion && firefoxVersion < 96) {
        return false
    }

    return true
}

export const exportTypeToShow = (function () {
    return new Map<ExportFormatType, string>([
        [ExportFormatType.EXPORT_FORMAT_TYPE_PNG, 'PNG'],
        [ExportFormatType.EXPORT_FORMAT_TYPE_JPG, 'JPG'],
        ...(isExportWebP()
            ? ([[ExportFormatType.EXPORT_FORMAT_TYPE_WEBP, 'WebP']] as Array<[ExportFormatType, string]>)
            : []),
        [ExportFormatType.EXPORT_FORMAT_TYPE_AVIF, 'AVIF'],
        [ExportFormatType.EXPORT_FORMAT_TYPE_SVG, 'SVG'],
        [ExportFormatType.EXPORT_FORMAT_TYPE_PDF, 'PDF'],
    ])
})()

export interface ExportConfig {
    node: string[]
    name: string
    fileName: string
    duplicate?: boolean
    format: ExportFormatType
    type: ExportSettingsConstraintsType
    value: number
    base64?: boolean
    isCompress?: boolean
    useAbsoluteBounds?: boolean
}

export type ExportConfigs = ExportConfig[]

type ExportSettingsImage = ExportSettings & {
    format: ExportFormatType.EXPORT_FORMAT_TYPE_JPG | ExportFormatType.EXPORT_FORMAT_TYPE_PNG
}

export function isExportSettingsImage(exportSettings: ExportSettings): exportSettings is ExportSettingsImage {
    return (
        exportSettings.format === ExportFormatType.EXPORT_FORMAT_TYPE_JPG ||
        exportSettings.format === ExportFormatType.EXPORT_FORMAT_TYPE_PNG
    )
}

// 去掉文件名前面的点
export const removeDotPrefix = (fileName: string) => fileName.replace(/^\.+/, '')

export const getFileName = (name: string, setting: ExportSettings) => removeDotPrefix(name) + setting.suffix

export type PreviewImageSize = number

// 432 means 216 (width of preview panel) × 2 = 432, 32 means (width of batch export modal preview panel) × 2 = 64
// 注意base64图片大小不能太大，否则WCC会出错
export function getNodesPreviewBase64ImageWithFixedSize(
    command: CommandInvoker,
    ids: NodeId[] | NodeId,
    size: PreviewImageSize
): string {
    const constraint = {
        type: ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_LARGER_SIDE,
        value: size,
    } as ExportConstraintByFloat

    const nodeIds = Array.isArray(ids) ? ids : [ids]

    return getNodesPreviewBase64Image(command, nodeIds, constraint)
}

export function getNodesPreviewBase64Image(
    command: CommandInvoker,
    nodeIds: NodeId[],
    constraint: ExportConstraintByFloat
): string {
    const img =
        command.DEPRECATED_invokeBridge(ExportPngToData, {
            nodeIds: nodeIds,
            constraint: constraint,
            forceClip: false,
            ancestorClip: true,
            useAbsoluteBounds: false,
        }).dataBase64 ?? ''

    return `data:image/png;base64,${img}`
}

type ImageFormatType =
    | ExportFormatType.EXPORT_FORMAT_TYPE_PNG
    | ExportFormatType.EXPORT_FORMAT_TYPE_JPG
    | ExportFormatType.EXPORT_FORMAT_TYPE_WEBP
    | ExportFormatType.EXPORT_FORMAT_TYPE_AVIF

export function getImageFormat(formatFormatType: ImageFormatType): ImageFormat {
    switch (formatFormatType) {
        case ExportFormatType.EXPORT_FORMAT_TYPE_PNG:
            return ImageFormat.IMAGE_FORMAT_PNG
        case ExportFormatType.EXPORT_FORMAT_TYPE_JPG:
            return ImageFormat.IMAGE_FORMAT_JPG
        case ExportFormatType.EXPORT_FORMAT_TYPE_WEBP:
            return ImageFormat.IMAGE_FORMAT_WEBP
        case ExportFormatType.EXPORT_FORMAT_TYPE_AVIF:
            return ImageFormat.IMAGE_FORMAT_AVIF
    }
}

export async function nodeToCompressPNGBlob(
    command: CommandInvoker,
    nodeIds: NodeId[],
    constraint: ExportConstraintByFloat,
    colorProfile: DocumentColorProfile
): Promise<Blob | null> {
    const { width, height, data } = command.DEPRECATED_invokeBridge(ExportImagePixels, {
        nodeIds,
        constraint,
    })
    if (!data) {
        return null
    }
    const pngData = await encodeCompressPng(
        getImageLibContext(),
        Buffer.from(data!),
        width!,
        height!,
        toColorSpace(colorProfile)
    )
    return new Blob([pngData])
}

async function nodeToAvifBlob(
    command: CommandInvoker,
    nodeIds: NodeId[],
    constraint: ExportConstraintByFloat,
    colorProfile: DocumentColorProfile
): Promise<Blob | null> {
    const { width, height, data } = command.DEPRECATED_invokeBridge(ExportImagePixels, {
        nodeIds,
        constraint,
    })
    if (!data) {
        return null
    }
    const pngData = await encodeAvif(
        getImageLibContext(),
        Buffer.from(data!),
        width!,
        height!,
        toColorSpace(colorProfile)
    )
    return pngData ? new Blob([pngData]) : null
}

async function nodeToImageBlob(
    command: CommandInvoker,
    canvasRenderBridge: CanvasRenderBridge,
    nodeIds: NodeId[],
    imageFormat: ImageFormat,
    constraint: ExportConstraintByFloat,
    colorProfile: DocumentColorProfile,
    isCompress: boolean,
    useAbsoluteBounds: boolean
): Promise<Blob | null> {
    if (isCompress && imageFormat == ImageFormat.IMAGE_FORMAT_PNG) {
        return nodeToCompressPNGBlob(command, nodeIds, constraint, colorProfile)
    }

    if (imageFormat == ImageFormat.IMAGE_FORMAT_AVIF) {
        return nodeToAvifBlob(command, nodeIds, constraint, colorProfile)
    }

    const arg: ExportImageArg = {
        nodeIds,
        constraint,
        imageFormat,
        isCompress,
        colorProfile,
        useAbsoluteBounds,
    }

    const { id } = command.DEPRECATED_invokeBridge(ExportImageToCanvas, arg)
    if (id) {
        return await canvasRenderBridge.fetchImageBlob(id)
    }

    return null
}

export function nodeToSVGBlob(
    command: CommandInvoker,
    rawMemAccessService: RawMemAccessService,
    nodeIds: NodeId[],
    colorProfile: DocumentColorProfile
): Blob | null {
    const arg = { nodeIds, colorProfile }
    const { blobID, dataBase64 } = command.DEPRECATED_invokeBridge(ExportToSvg, arg)
    if (!isNil(blobID)) {
        return rawMemAccessService.getBlobAndDelete(blobID)
    }
    return base64ToBlob(dataBase64)
}

export function nodeToPDFBlob(
    command: CommandInvoker,
    rawMemAccessService: RawMemAccessService,
    nodeIds: NodeId[],
    colorProfile: DocumentColorProfile
): Blob | null {
    const arg = { nodeIds, colorProfile }
    const { blobID, dataBase64 } = command.DEPRECATED_invokeBridge(ExportToPDF, arg)
    if (!isNil(blobID)) {
        return rawMemAccessService.getBlobAndDelete(blobID)
    }
    return base64ToBlob(dataBase64)
}

export async function nodeToBlob(
    command: CommandInvoker,
    canvasRenderBridge: CanvasRenderBridge,
    rawMemAccessService: RawMemAccessService,
    nodeIds: NodeId[],
    format: ExportFormatType,
    constraint: ExportConstraintByFloat,
    colorProfile: DocumentColorProfile,
    isCompresImage: boolean,
    useAbsoluteBounds: boolean
): Promise<Blob | null> {
    switch (format) {
        case ExportFormatType.EXPORT_FORMAT_TYPE_PNG:
        case ExportFormatType.EXPORT_FORMAT_TYPE_JPG:
        case ExportFormatType.EXPORT_FORMAT_TYPE_AVIF:
        case ExportFormatType.EXPORT_FORMAT_TYPE_WEBP: {
            return nodeToImageBlob(
                command,
                canvasRenderBridge,
                nodeIds,
                getImageFormat(format),
                constraint,
                colorProfile,
                isCompresImage,
                useAbsoluteBounds
            )
        }
        case ExportFormatType.EXPORT_FORMAT_TYPE_PDF: {
            return nodeToPDFBlob(command, rawMemAccessService, nodeIds, colorProfile)
        }
        case ExportFormatType.EXPORT_FORMAT_TYPE_SVG: {
            return nodeToSVGBlob(command, rawMemAccessService, nodeIds, colorProfile)
        }
    }
}

export function getOriginImage(
    preExportService: PreExportService,
    command: CommandInvoker,
    nodeIds?: string[]
): Promise<void> {
    return new Promise((resolve) => {
        preExportService.setFn(() => resolve())
        command.DEPRECATED_invokeBridge(PreExportCommand, nodeIds !== undefined ? { ids: nodeIds } : undefined)
    })
}

export function appendFileType(name: string, format: ExportFormatType): string {
    const fileType = exportTypeToShow.get(format) ?? ''
    return `${name}.${fileType.toLowerCase()}`
}

export function getNodeFileName(
    nameCount: Map<string, number>,
    name: string,
    setting: ExportSettings
): readonly [string, boolean] {
    const format = setting.format ?? ExportFormatType.EXPORT_FORMAT_TYPE_PNG
    const nameWithoutSuffix = removeDotPrefix(`${name}${setting.suffix ?? ''}`)
        .substring(0, 100)
        .replace(/[\\/:*?"<>|]/g, '_')
    const duplicate = nameCount.has(nameWithoutSuffix)
    const count = duplicate ? nameCount.get(nameWithoutSuffix)! + 1 : 0
    nameCount.set(nameWithoutSuffix, count)
    let fileNameWithoutSuffix = nameWithoutSuffix
    if (duplicate) {
        fileNameWithoutSuffix += `(${count})`
    }
    const fileName = appendFileType(fileNameWithoutSuffix, format)
    return [fileName, duplicate] as const
}

export function singleNodeMultiExportConfig(
    nameCount: Map<string, number>,
    node: string[],
    name: string,
    exportSettings: ExportSettings[]
): ExportConfig[] {
    const configs = []
    for (const setting of exportSettings) {
        const [fileName, duplicate] = getNodeFileName(nameCount, name, setting)
        const item = {
            fileName,
            node,
            name,
            duplicate,
            isBase64: isBase64(setting.format),
            format: setting.format ?? ExportFormatType.EXPORT_FORMAT_TYPE_PNG,
            type: setting?.constraint?.type ?? ExportSettingsConstraintsType.EXPORT_CONSTRAINT_TYPE_SCALE,
            value: (setting?.constraint?.value ?? constraintValueScale) / constraintValueScale,
        }
        configs.push(item)
    }
    return configs
}

export function multiNodesOneExportSettings(
    nameCount: Map<string, number>,
    nodeCollection: string[][],
    names: string[],
    exportSettings: ExportSettings[]
): ExportConfig[] {
    let configs: ExportConfig[] = []
    for (let i = 0; i < nodeCollection.length; i += 1) {
        const node = nodeCollection[i]
        const name = names[i]
        configs = configs.concat(singleNodeMultiExportConfig(nameCount, node, name, exportSettings))
    }
    return configs
}

export function multiNodeWithOwnExportSettings(
    nameCount: Map<string, number>,
    nodeCollection: string[][],
    names: string[],
    exportSettings: ExportSettings[][]
): ExportConfig[] {
    let configs: ExportConfig[] = []
    for (let i = 0; i < nodeCollection.length; i += 1) {
        const node = nodeCollection[i]
        const name = names[i]
        const exportSetting = exportSettings[i]
        configs = configs.concat(singleNodeMultiExportConfig(nameCount, node, name, exportSetting))
    }
    return configs
}

// 导出的format不是Svg或者不是PDF
export function isBase64(format: ExportFormatType | null | undefined): boolean {
    const notSvgEitherPDF =
        format !== ExportFormatType.EXPORT_FORMAT_TYPE_PDF && format !== ExportFormatType.EXPORT_FORMAT_TYPE_SVG
    return notSvgEitherPDF
}
