import { BatchUpdateMinSizeAndMaxSizeWasmCall, UpdateSizeHoverMenuItemWasmCall, Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react'
import {
    DropdownV2,
    MonoIconCommonDelete16,
    MonoIconPanelLink16,
    MonoIconPanelVariable16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import type { DropdownArrowSingleLevelProps } from '../../../../../../ui-lib/src/components/dropdowns/dropdown/dropdown-arrow-single-level'
import { DeepRequired } from '../../../../../../util/src'
import { featureSwitchManager } from '../../../../kernel/switch'
import { useCommand, useViewStateBridge } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../window'
import { useSelectionState } from '../../../document/selection/use-selection-state'
import { SingleGrid } from '../../atom/grid/single-grid'
import type { ScrubbableInputProps } from '../../atom/inputs/components/scrubbable-input'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { formateToFixed2 } from '../../atom/inputs/utils/format'
import { parseString } from '../../atom/inputs/utils/parse-string'
import type { Value } from '../../atom/inputs/utils/type'
import { translation } from './min-max-size.translation'

export enum MinAndMaxSizeOption {
    addMinWidth,
    removeMinWidth,
    addMaxWidth,
    removeMaxWidth,
    addMinHeight,
    removeMinHeight,
    addMaxHeight,
    removeMaxHeight,
    applyVariable,
    unbindVariable,
}

export function useMinMaxSizeDropdownInputProps() {
    const command = useCommand()
    const { changeSelectionInfo } = useSelectionState()
    const viewStateBridge = useViewStateBridge()
    const state = useViewState('baseAttributeV2')
    const [forceShowMinWidth, setForceShowMinWidth] = useState(false)
    const [forceShowMaxWidth, setForceShowMaxWidth] = useState(false)
    const [forceShowMinHeight, setForceShowMinHeight] = useState(false)
    const [forceShowMaxHeight, setForceShowMaxHeight] = useState(false)

    // 选区变更时，重置极值输入框状态
    useEffect(() => {
        setForceShowMinWidth(false)
        setForceShowMaxWidth(false)
        setForceShowMinHeight(false)
        setForceShowMaxHeight(false)
    }, [changeSelectionInfo])

    // viewState 变更时，重置极值输入框状态
    useEffect(() => {
        if (!state) {
            return
        }
        !getIsShowMinMaxSizeDropdown(state.minW.editType) && setForceShowMinWidth(false)
        !getIsShowMinMaxSizeDropdown(state.maxW.editType) && setForceShowMaxWidth(false)
        !getIsShowMinMaxSizeDropdown(state.minH.editType) && setForceShowMinHeight(false)
        !getIsShowMinMaxSizeDropdown(state.maxH.editType) && setForceShowMaxHeight(false)
    }, [state])

    const onMinMaxSizeOptionChange = useCallback(
        (option: MinAndMaxSizeOption) => {
            switch (option) {
                case MinAndMaxSizeOption.addMinWidth: {
                    return setForceShowMinWidth(true)
                }
                case MinAndMaxSizeOption.addMaxWidth: {
                    return setForceShowMaxWidth(true)
                }
                case MinAndMaxSizeOption.addMinHeight: {
                    return setForceShowMinHeight(true)
                }
                case MinAndMaxSizeOption.addMaxHeight: {
                    return setForceShowMaxHeight(true)
                }
                case MinAndMaxSizeOption.removeMinWidth: {
                    setForceShowMinWidth(false)
                    const currentState = viewStateBridge.getDefaultValue('baseAttributeV2')
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.keys(currentState?.minW.values ?? {}).map((nodeId) => ({
                            nodeId,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH,
                        })),
                    })
                }
                case MinAndMaxSizeOption.removeMaxWidth: {
                    setForceShowMaxWidth(false)
                    const currentState = viewStateBridge.getDefaultValue('baseAttributeV2')
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.keys(currentState?.maxW.values ?? {}).map((nodeId) => ({
                            nodeId,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH,
                        })),
                    })
                }
                case MinAndMaxSizeOption.removeMinHeight: {
                    setForceShowMinHeight(false)
                    const currentState = viewStateBridge.getDefaultValue('baseAttributeV2')
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.keys(currentState?.minH.values ?? {}).map((nodeId) => ({
                            nodeId,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT,
                        })),
                    })
                }
                case MinAndMaxSizeOption.removeMaxHeight: {
                    setForceShowMaxHeight(false)
                    const currentState = viewStateBridge.getDefaultValue('baseAttributeV2')
                    return command.DEPRECATED_invokeBridge(BatchUpdateMinSizeAndMaxSizeWasmCall, {
                        params: Object.keys(currentState?.maxH.values ?? {}).map((nodeId) => ({
                            nodeId,
                            type: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT,
                        })),
                    })
                }
                case MinAndMaxSizeOption.applyVariable:
                case MinAndMaxSizeOption.unbindVariable:
                    return
            }
        },
        [command, viewStateBridge]
    )

    return {
        onMinMaxSizeOptionChange,

        hasMinMaxWidth: hasMinMaxValue(state?.minW) || hasMinMaxValue(state?.maxW),
        hasMinMaxHeight: hasMinMaxValue(state?.minH) || hasMinMaxValue(state?.maxH),

        showMinWidth: forceShowMinWidth || hasMinMaxValue(state?.minW),
        showMaxWidth: forceShowMaxWidth || hasMinMaxValue(state?.maxW),
        showMinHeight: forceShowMinHeight || hasMinMaxValue(state?.minH),
        showMaxHeight: forceShowMaxHeight || hasMinMaxValue(state?.maxH),

        markForceShowMinWidth: () => setForceShowMinWidth(true),
        markForceShowMaxWidth: () => setForceShowMaxWidth(true),
        markForceShowMinHeight: () => setForceShowMinHeight(true),
        markForceShowMaxHeight: () => setForceShowMaxHeight(true),
    }
}

// 控制 w、h、极值输入框 hover/unhover 状态向 wasm 同步
function useHoverSizeMenuItem(props: { hoverId: Wukong.DocumentProto.SizeHoverMenuItem; hideMenu: boolean }) {
    const command = useCommand()
    const isHoverMenu = useRef<boolean>(false)

    const unHoverMenu = useCallback(() => {
        isHoverMenu.current = false
        command.DEPRECATED_invokeBridge(UpdateSizeHoverMenuItemWasmCall, {
            value: Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_NONE,
        })
    }, [command])

    const hoverMenu = useCallback(() => {
        isHoverMenu.current = true
        command.DEPRECATED_invokeBridge(UpdateSizeHoverMenuItemWasmCall, {
            value: props.hoverId,
        })
    }, [command, props.hoverId])

    useEffect(() => {
        // 如果组件隐藏，取消 hover 状态
        props.hideMenu && isHoverMenu.current && unHoverMenu()
    }, [props.hideMenu, unHoverMenu])

    useEffect(() => {
        // 组件卸载时，取消 hover 状态
        return () => {
            isHoverMenu.current && unHoverMenu()
        }
    }, [unHoverMenu])

    return {
        hoverMenu,
        unHoverMenu,
    }
}

// 宽/高下拉选择 增/删极值
export function MinMaxSizeDropdown(props: {
    hoverId: Wukong.DocumentProto.SizeHoverMenuItem
    inputLabel: JSX.Element
    disabled: boolean
    onChange: (option: MinAndMaxSizeOption) => void
    min: {
        value: DeepRequired<Wukong.DocumentProto.IBaseAttributeProp>
        add: { icon: JSX.Element; text: string; value: MinAndMaxSizeOption }
        remove: { text: string; value: MinAndMaxSizeOption }
    }
    max: {
        value: DeepRequired<Wukong.DocumentProto.IBaseAttributeProp>
        add: { icon: JSX.Element; text: string; value: MinAndMaxSizeOption }
        remove: { text: string; value: MinAndMaxSizeOption }
    }
    dropdownTriggerTestId: string
    placement: DropdownArrowSingleLevelProps['placement']
    useVariable?: ScrubbableInputProps['useVariable']
}) {
    const isShowMinMaxSizeDropdown = useMemo(
        () => getIsShowMinMaxSizeDropdown(props.min.value.editType),
        [props.min.value.editType]
    )

    const { hoverMenu, unHoverMenu } = useHoverSizeMenuItem({
        hoverId: props.hoverId,
        hideMenu: !isShowMinMaxSizeDropdown,
    })

    const getVariableOption = useCallback(() => {
        if (!props.useVariable) {
            return
        }
        if (props.useVariable?.notAvailable) {
            if (props.useVariable.variable) {
                return [
                    {
                        value: MinAndMaxSizeOption.unbindVariable,
                        icon: <MonoIconPanelLink16 data-testid="unbind-variable-icon" />,
                        text: translation('UnbindVariable'),
                        splitLineTop: true,
                        dataTestId: 'unbind-variable-option',
                    },
                ]
            }
        } else {
            if (props.useVariable.variable) {
                return [
                    {
                        value: MinAndMaxSizeOption.applyVariable,
                        icon: <MonoIconPanelVariable16 data-testid="bind-variable-icon" />,
                        text: translation('ApplyVariable'),
                        splitLineTop: true,
                        dataTestId: 'apply-variable-option',
                    },
                    {
                        value: MinAndMaxSizeOption.unbindVariable,
                        icon: <MonoIconPanelLink16 data-testid="unbind-variable-icon" />,
                        text: translation('UnbindVariable'),
                        dataTestId: 'unbind-variable-option',
                    },
                ]
            } else {
                return [
                    {
                        value: MinAndMaxSizeOption.applyVariable,
                        icon: <MonoIconPanelVariable16 data-testid="bind-variable-icon" />,
                        text: translation('ApplyVariable'),
                        splitLineTop: true,
                        dataTestId: 'apply-variable-option',
                    },
                ]
            }
        }
    }, [props.useVariable])

    return isShowMinMaxSizeDropdown ? (
        <DropdownV2.ArrowSingleLevel
            disabled={props.disabled}
            onChange={props.onChange}
            label={
                <div className="w-full h-full" onMouseEnter={hoverMenu} onMouseLeave={unHoverMenu}>
                    {props.inputLabel}
                </div>
            }
            dataTestIds={{ triggerFocus: props.dropdownTriggerTestId }}
            isSmallArrow
            placement={props.placement}
        >
            {props.min.value.value ? (
                <DropdownV2.ArrowSingleLevel.Option
                    key={props.min.remove.value}
                    value={props.min.remove.value}
                    forwardChildren={<MonoIconCommonDelete16 className="color-$wk-v2-label-color-gray-13" />}
                    data-testid={EditorDataTestId.BaseAttribute.MinMaxSizeDropdownOption(props.min.remove.value)}
                >
                    {props.min.remove.text}
                    {props.min.value.valueType ===
                        Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_NORMAL &&
                        `${translation('Colon')}${formateToFixed2(props.min.value.value)}`}
                </DropdownV2.ArrowSingleLevel.Option>
            ) : (
                <DropdownV2.ArrowSingleLevel.Option
                    key={props.min.add.value}
                    value={props.min.add.value}
                    forwardChildren={props.min.add.icon}
                    data-testid={EditorDataTestId.BaseAttribute.MinMaxSizeDropdownOption(props.min.add.value)}
                >
                    {props.min.add.text}
                </DropdownV2.ArrowSingleLevel.Option>
            )}
            {props.max.value.value ? (
                <DropdownV2.ArrowSingleLevel.Option
                    key={props.max.remove.value}
                    value={props.max.remove.value}
                    forwardChildren={<MonoIconCommonDelete16 className="color-$wk-v2-label-color-gray-13" />}
                    data-testid={EditorDataTestId.BaseAttribute.MinMaxSizeDropdownOption(props.max.remove.value)}
                >
                    {props.max.remove.text}
                    {props.max.value.valueType ===
                        Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_NORMAL &&
                        `${translation('Colon')}${formateToFixed2(props.max.value.value)}`}
                </DropdownV2.ArrowSingleLevel.Option>
            ) : (
                <DropdownV2.ArrowSingleLevel.Option
                    key={props.max.add.value}
                    value={props.max.add.value}
                    forwardChildren={props.max.add.icon}
                    data-testid={EditorDataTestId.BaseAttribute.MinMaxSizeDropdownOption(props.max.add.value)}
                >
                    {props.max.add.text}
                </DropdownV2.ArrowSingleLevel.Option>
            )}
            {getVariableOption()?.map((v) => {
                return (
                    <DropdownV2.ArrowSingleLevel.Option
                        key={v.text}
                        value={v.value}
                        forwardChildren={v.icon}
                        splitLineTop={v.splitLineTop}
                        data-testid={v.dataTestId}
                    >
                        {v.text}
                    </DropdownV2.ArrowSingleLevel.Option>
                )
            })}
        </DropdownV2.ArrowSingleLevel>
    ) : (
        props.inputLabel
    )
}

// 极值输入框
export function MinMaxSizeInput(
    props: {
        hoverId: Wukong.DocumentProto.SizeHoverMenuItem
        tooltipTitle: string
        propType: Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
        propValue: DeepRequired<Wukong.DocumentProto.IBaseAttributeProp>
    } & Required<Pick<ScrubbableInputProps, 'onChange' | 'mixedMathHandler' | 'labelTestId' | 'icon' | 'placeholder'>> &
        Pick<ScrubbableInputProps, 'useVariable'>
) {
    const { hoverMenu, unHoverMenu } = useHoverSizeMenuItem({ hoverId: props.hoverId, hideMenu: false })
    const isMixed =
        props.propValue.valueType ===
        Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED

    return (
        <Tooltip placement="bottom" title={props.tooltipTitle}>
            <div
                className={featureSwitchManager.isEnabled('float-variable') ? 'h-full' : 'h-full w-full'}
                onMouseEnter={hoverMenu}
                onMouseLeave={unHoverMenu}
            >
                <ScrubbableInputNumber
                    min={0}
                    isMixed={isMixed}
                    value={props.propValue.value}
                    labelTestId={props.labelTestId}
                    testId="min-max-size-inner-input"
                    icon={props.icon}
                    onChange={props.onChange}
                    mixedMathHandler={props.mixedMathHandler}
                    placeholder={props.placeholder}
                    defaultShowEmpty={!isMixed && !props.propValue.value}
                    autoFocus={!isMixed && !props.propValue.value}
                    formatter={{
                        parse(inputValue: string, oldValue?: Value) {
                            if (!inputValue) {
                                return 0
                            }

                            if (Array.isArray(oldValue)) {
                                const inputValues = inputValue.split(',')
                                return oldValue.map((v, index) => {
                                    return parseString(inputValues[index] ?? inputValues[0], v)
                                })
                            } else {
                                return parseString(inputValue, oldValue)
                            }
                        },
                        format: formateToFixed2,
                    }}
                    useVariable={props.useVariable}
                />
            </div>
        </Tooltip>
    )
}

// 根据 https://kanyun.motiff.cn/file/mWAOIqukeoOOgStaHO6n72m?nodeId=3211%3A1956 的规则对四个极值输入框排版
export function MinMaxSizeInputLayout(
    props: { inputMinW: ReactNode; inputMaxW: ReactNode; inputMinH: ReactNode; inputMaxH: ReactNode } & Pick<
        ReturnType<typeof useMinMaxSizeDropdownInputProps>,
        'showMinWidth' | 'showMaxWidth' | 'showMinHeight' | 'showMaxHeight'
    >
) {
    const formatNodes = () => {
        if (!props.showMinWidth && props.showMaxWidth && props.showMinHeight && props.showMaxHeight) {
            return [
                [<></>, props.inputMaxW],
                [props.inputMinH, props.inputMaxH],
            ]
        }

        if (props.showMinWidth && props.showMaxWidth && !props.showMinHeight && props.showMaxHeight) {
            return [
                [props.inputMinW, props.inputMaxW],
                [<></>, props.inputMaxH],
            ]
        }

        return [
            [props.showMinWidth ? props.inputMinW : null, props.showMaxWidth ? props.inputMaxW : null].filter(
                (node) => !!node
            ),
            [props.showMinHeight ? props.inputMinH : null, props.showMaxHeight ? props.inputMaxH : null].filter(
                (node) => !!node
            ),
        ]
    }

    const nodes = formatNodes()

    if (!nodes[0].length && !nodes[1].length) {
        return <></>
    }

    if (nodes[0].length > 1 || nodes[1].length > 1) {
        return (
            <>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={22}>
                        {nodes[0][0]}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={29} span={22}>
                        {nodes[1][0]}
                    </SingleGrid.Item>
                </SingleGrid>
                <SingleGrid>
                    <SingleGrid.Item start={5} span={22}>
                        {nodes[0][1]}
                    </SingleGrid.Item>
                    <SingleGrid.Item start={29} span={22}>
                        {nodes[1][1]}
                    </SingleGrid.Item>
                </SingleGrid>
            </>
        )
    }

    return (
        <SingleGrid>
            <SingleGrid.Item start={5} span={22}>
                {nodes[0][0]}
            </SingleGrid.Item>
            <SingleGrid.Item start={29} span={22}>
                {nodes[1][0]}
            </SingleGrid.Item>
        </SingleGrid>
    )
}

function hasMinMaxValue(propValue?: DeepRequired<Wukong.DocumentProto.IBaseAttributeProp>) {
    if (!propValue) {
        return false
    }
    if (!getIsShowMinMaxSizeDropdown(propValue.editType)) {
        return false
    }
    if (propValue.valueType === Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED) {
        return true
    }
    if (
        propValue.valueType === Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_NORMAL &&
        !!propValue.value
    ) {
        return true
    }
    return false
}

export function getIsShowMinMaxSizeDropdown(propEditType?: Wukong.DocumentProto.BaseAttributePropEditType) {
    return (
        !isNil(propEditType) &&
        [
            Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_ENABLE,
            Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY,
        ].includes(propEditType)
    )
}
