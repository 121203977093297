/**
 * @owner: chenxiangbj@kanyun.com
 */
import classNames from 'classnames'
import { FC, forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react'
// eslint-disable-next-line import/no-restricted-paths
import { getFullAvatarImageUrl } from '../../../../app/src/kernel/request/upload'
import { splitGrapheme } from '../../../../util/src'
import { MonoIconToolbarPencil24 } from '../../icons-v2'

export interface WKAvatarProps extends HTMLAttributes<HTMLDivElement> {
    type: 'square' | 'rounded'
    backgroundColor: string
    name: string
    forceFullName?: boolean // 在展示名称的时候，强制展示全名
    size: 14 | 16 | 24 | 32 | 40 | 48 | 60 | 72
    border?: boolean
    borderColor?: string
    imageId?: string
    innerContent?: ReactNode
    editable?: boolean
    onClickEdit?: () => void
}

const getBorderRadius = (type: WKAvatarProps['type'], size: WKAvatarProps['size']) => {
    if (type == 'rounded') {
        return '100%'
    } else {
        switch (size) {
            case 14:
            case 16:
            case 24:
                return 2
            case 32:
            case 40:
                return 3
            case 48:
            case 60:
            case 72:
                return 4
        }
    }
}
const getFontSize = (size: WKAvatarProps['size'], nameLength: number) => {
    if (nameLength == 1) {
        switch (size) {
            case 14:
            case 16:
                return 'wk-text-9'
            case 24:
                return 'wk-text-12'
            case 32:
            case 40:
                return 'wk-text-16'
            case 48:
                return 'wk-text-24'
            case 60:
            case 72:
                return 'wk-text-30'
        }
    } else {
        switch (size) {
            case 14:
            case 16:
                return 'wk-text-8'
            case 24:
                return 'wk-text-11'
            case 32:
            case 40:
                return 'wk-text-14'
            case 48:
                return 'wk-text-22'
            case 60:
            case 72:
                return 'wk-text-28'
        }
    }
}

const getBoxShadow = (border: boolean, borderColor: string | undefined) => {
    if (border) {
        return `0 0px 0px 1px ${borderColor ?? 'white'}`
    }
    return undefined
}

const getWH = (size: WKAvatarProps['size']) => {
    return { width: size, height: size }
}

export const getDisplayName = (name: string, forceFullName?: boolean) => {
    if (forceFullName) {
        return name
    }
    // 首字为中文，展示中文， 如果是纯单词，展示首单词和尾单词的首字母
    if (name && /^[a-zA-Z\s]*$/.test(name)) {
        const nicknameArr = name.trim().split(' ')
        const first = nicknameArr[0][0]
        let last = ''
        if (nicknameArr.length > 1) {
            last = nicknameArr[nicknameArr.length - 1][0]
        }
        return `${first}${last}`
    }
    // 为了支持 emoji
    const segments = splitGrapheme(name)
    return segments?.[0]?.segment ?? ''
}

export type WKAvatarRef = React.Ref<HTMLDivElement>

export const WKAvatar = forwardRef(function Component(props: WKAvatarProps, ref?: WKAvatarRef) {
    const {
        type,
        editable,
        size,
        imageId,
        style,
        className,
        innerContent,
        name,
        forceFullName,
        onClickEdit,
        border,
        borderColor,
        backgroundColor,
        ...rest
    } = props
    const borderRadius = useMemo(() => getBorderRadius(type, size), [size, type])
    const boxShadow = useMemo(() => getBoxShadow(!!border, borderColor), [border, borderColor])
    const wh = getWH(size)
    const displayName = useMemo(() => getDisplayName(name, forceFullName), [name, forceFullName])
    const font = getFontSize(size, displayName.length)
    const imageUrl = getFullAvatarImageUrl(imageId!, { minify: true, width: size })
    const backgroundImage = `url(${imageId ? imageUrl : ''})`
    return (
        <div
            ref={ref}
            {...rest}
            style={{
                ...wh,
                borderRadius,
                boxShadow,
                backgroundImage,
                backgroundColor: imageId ? '' : backgroundColor,
                ...style,
            }}
            className={classNames(
                `cursor-default inline-block relative select-none overflow-hidden wk-font-medium flex items-center
                justify-center shrink-0 color-white case-upper group bg-cover`,
                className,
                font
            )}
        >
            {!imageId && (
                <>
                    {innerContent ? (
                        innerContent
                    ) : (
                        <span data-testid="avator-char" className="select-none">
                            {displayName}
                        </span>
                    )}
                </>
            )}
            {editable && (
                <div
                    onClick={onClickEdit}
                    className="absolute items-center justify-center color-white inset-0 m-auto bg-$wk-gray-13-60 hidden
                        group-hover:flex"
                >
                    <MonoIconToolbarPencil24 />
                </div>
            )}
        </div>
    )
})

export interface WKAvatarGroupProps {
    list: Omit<WKAvatarProps, 'size' | 'type' | 'border' | 'borderColor'>[]
    displayLength?: number
    gap?: 8 | 4 | -2
}

export const WKAvatarGroup: FC<WKAvatarGroupProps> = ({ gap = -2, list, displayLength = 2 }) => {
    const displayList = useMemo(() => list.slice(0, displayLength), [displayLength, list])
    const showMore = useMemo(() => displayLength < list.length, [displayLength, list.length])
    const moreString = useMemo(() => {
        const num = list.length - displayLength
        if (num > 999) {
            return '999+'
        }
        return `${num}`
    }, [displayLength, list.length])
    const moreStringClassName = useMemo(() => {
        switch (moreString.length) {
            case 4:
                return '!wk-text-9'
            case 3:
            case 2:
                return '!wk-text-11'
            case 1:
                return '!wk-text-12'
        }
    }, [moreString.length])
    return (
        <div className="inline-flex">
            {displayList.map((data, idx) => (
                <WKAvatar
                    style={{
                        zIndex: displayLength - idx,
                        marginRight: gap,
                    }}
                    border
                    {...data}
                    size={24}
                    type="rounded"
                    key={JSON.stringify(data) + idx}
                />
            ))}
            {showMore && (
                <WKAvatar
                    border
                    imageId=""
                    className={classNames('!color-$wk-v2-label-color-gray-11 !wk-font-regular', moreStringClassName)}
                    backgroundColor="var(--wk-gray-3)"
                    name={moreString}
                    size={24}
                    type="rounded"
                />
            )}
        </div>
    )
}
